/* eslint-disable sonarjs/no-duplicate-string */
import locale from 'config/locale';
import { NEW_FORK } from 'config/abTest';
import { featureOn } from 'lib/abTestHelper';

import {
  ABOUT_PROPERTY_PATH,
  CONTACT_DETAILS_PATH,
  PROPERTY_RENT_PATH,
  BANKING_PREFERENCES_PATH,
  SALE_REPORT_PATH,
  CASH_FLOW_REPORT_PATH,
  EXISTING_PROPERTY_PATH,
  DASHBOARD_BASE_PATH,
  LOGIN_CAPTURE_PATH,
  APPLY_BASE_PATH,
  PREFERRED_LENDERS_PATH,
  PREFERRED_LOAN_OPTIONS_PATH,
  LOAN_AMOUNT_PATH,
  LOAN_STRUCTURES_PATH,
  SERVICEABILITY_PATH,
  PRIVACY_POLICY_PATH,
} from 'shared/constants/paths';

import {
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_INVEST,
  BUYER_SCENARIO_COMPARE,
  BUYER_SCENARIO_FHB,
  FORK_DIY_RESEARCH,
  FORK_FAST_APPROVAL,
} from 'shared/constants/loanScenarios';

import { PROFILE_SECTIONS } from 'constants/profileSections';

export const toForkOrSignup = () => {
  return featureOn(NEW_FORK)
    ? {
        path: CONTACT_DETAILS_PATH,
        animation: [
          '/alright',
          '/lets-get-you-that-loan',
          '/profile-signup-grid',
          '/create-your-profile',
        ],
      }
    : {
        path: CONTACT_DETAILS_PATH,
        animation: [
          '/alright',
          '/lets-get-you-that-loan',
          '/create-your-profile',
          '/profile-signup-grid',
        ],
      };
};

export const TO_ABOUT_THE_PROPERTY = {
  path: ABOUT_PROPERTY_PATH,
  animation: ['/ok', '/prospective-property'],
};
export const TO_RENT_PROPERTY = {
  path: PROPERTY_RENT_PATH,
  animation: ['/alright', '/quick-look-at-cash-flow'],
};
export const TO_CONTACT_DETAILS_FROM_CALCULATOR = {
  path: CONTACT_DETAILS_PATH,
  animation: [
    '/alright',
    '/profile-signup-grid',
    '/create-your-profile-from-calculator',
  ],
};

export const afterExistingProperty = (name) => {
  switch (name) {
    case BUYER_SCENARIO_MOVE:
      return {
        path: SALE_REPORT_PATH,
        animation: ['/great', '/calculate-proceeds-of-sale'],
      };
    case BUYER_SCENARIO_INVEST:
      return {
        path: CASH_FLOW_REPORT_PATH,
        animation: ['/great', '/investment-cashflow'],
      };
    default:
      return { path: BANKING_PREFERENCES_PATH };
  }
};

export const TO_LOGIN_FROM_SHARED = {
  path: LOGIN_CAPTURE_PATH,
  animation: ['/kindly-login'],
};

export const TO_LOGIN_FROM_SHARED_EXISTING = {
  path: LOGIN_CAPTURE_PATH,
  animation: ['/welcome-back-from-shared'],
};

export const afterBuyerScenario = (name) => {
  switch (name) {
    case BUYER_SCENARIO_FHB:
      return {
        path: ABOUT_PROPERTY_PATH,
        animation: ['/thats-exciting', '/first-how-much'],
      };
    case BUYER_SCENARIO_MOVE:
      return {
        path: EXISTING_PROPERTY_PATH,
        animation: ['/thats-exciting', '/first-how-much'],
      };
    case BUYER_SCENARIO_INVEST:
      return {
        path: EXISTING_PROPERTY_PATH,
        animation: ['/thats-exciting', '/rent-and-buy-new'],
      };
    case BUYER_SCENARIO_COMPARE:
    default:
      return { path: ABOUT_PROPERTY_PATH };
  }
};

export const afterForkChoices = (name, isCustomerCare) => {
  switch (name) {
    case FORK_DIY_RESEARCH: {
      const introduceRoute = isCustomerCare
        ? '/we-have-experts'
        : '/introduce-broker';
      return {
        path: DASHBOARD_BASE_PATH,
        animation: ['/ok-emphasis', '/compare-rates', introduceRoute],
      };
    }
    case FORK_FAST_APPROVAL: {
      const introduceRoute = isCustomerCare
        ? '/we-have-experts-handhold-start'
        : '/introduce-broker-handhold-start';
      return {
        path: DASHBOARD_BASE_PATH,
        animation: ['/ok-emphasis', '/fast-pre-approval', introduceRoute],
      };
    }
    default:
      return {
        path: DASHBOARD_BASE_PATH,
        animation: [
          '/great',
          '/access-top-rates-grid',
          '/complete-profile-pre-approval',
          '/easy-ten-minutes',
        ],
      };
  }
};

export const BACK_TO_DASHBOARD_LOGIN = {
  path: DASHBOARD_BASE_PATH,
  animation: ['/welcome-back'],
};
export const toRedirectPath = (redirectUrl) => ({
  path: redirectUrl.split('/#')[1],
  animation: ['/welcome-back'],
});

export const BACK_TO_DASHBOARD_NON_HANDHOLD = {
  path: DASHBOARD_BASE_PATH,
  animation: [
    '/welcome-to',
    '/complete-as-much-as-you-can',
    '/make-sure-existing-info-good',
  ],
};
export const BACK_TO_DASHBOARD_HANDHOLD = {
  path: DASHBOARD_BASE_PATH,
  animation: [
    '/welcome-to',
    '/we-just-need-more-information',
    '/apply-handhold',
  ],
};

export const SIGN_UP_ANIMATIONS = [
  '/applying-online',
  '/welcome-to',
  '/complete-profile',
  '/fill-in-your-details',
];
export const SIGN_UP_ANIMATIONS_FORK = ['/welcome-to', '/fork-choices'];

export const FROM_EMAIL_VERIFICATION_TO_DASHBOARD = {
  path: DASHBOARD_BASE_PATH,
  animation: ['/email-verification-success'],
};

export const applyPage = (applicationId) =>
  `${APPLY_BASE_PATH}/${applicationId}`;

export const fromLoanToApply = (applicationId) => {
  return {
    path: applyPage(applicationId),
    animation: ['/nice-choice', '/adding-loan'],
  };
};

export const afterExpenseBankConnectFetched = ({ path, isFullReport }) => {
  return {
    path,
    animation: isFullReport
      ? [
          `/securely-closed-connection`,
          '/also-statements',
          `/check-transactions-from-other-banks`,
        ]
      : [`/got-your-statements`, '/want-another-bank'],
  };
};

export const toLoginOnSignout = () => {
  return LOGIN_CAPTURE_PATH;
};

export const fromPrivacyPolicyToNextPath = (nextPath, nextAnimation = []) => ({
  path: nextPath,
  animation: ['/got-it', ...nextAnimation],
});

export const toPrivacyPolicy = (animation) => ({
  path: PRIVACY_POLICY_PATH,
  animation,
});

export const isApplyOnline = () =>
  window.location.hash.includes(APPLY_BASE_PATH);

export const fromPreferredLendersToNextPath = (loanAppId) => {
  const path = isApplyOnline()
    ? `${APPLY_BASE_PATH}/${loanAppId}?section=${PROFILE_SECTIONS.LENDERS}`
    : `/${loanAppId}${PREFERRED_LOAN_OPTIONS_PATH}`;
  return {
    path,
    animation: ['/nice'],
  };
};

export const fromCurrentToPreferredLenders = (loanAppId) => {
  const path = isApplyOnline()
    ? `${APPLY_BASE_PATH}/${loanAppId}${PREFERRED_LENDERS_PATH}`
    : `/${loanAppId}${PREFERRED_LENDERS_PATH}`;
  return {
    path,
    animation: ['/got-it'],
  };
};

export const fromGoalSetterGoalsToDashboard = (loanAppId) => ({
  path: `/${loanAppId}${DASHBOARD_BASE_PATH}`,
  animation: ['/got-it'],
});

export const toGoalDashboard = (loanAppId, section) => {
  if (isApplyOnline()) {
    return `${APPLY_BASE_PATH}/${loanAppId}?section=${section}`;
  }
  return `/${loanAppId}${DASHBOARD_BASE_PATH}`;
};
export const toGoalLoanAmount = (loanAppId) =>
  `/${loanAppId}${LOAN_AMOUNT_PATH}`;
export const toGoalLoanStructures = (loanAppId) =>
  `/${loanAppId}${LOAN_STRUCTURES_PATH}`;
export const toGoalServiceability = ({ loanAppId, contactId }) => ({
  path: `/${loanAppId}${SERVICEABILITY_PATH}/${contactId}`,
  animation: [`/ok`, `/calculating-family-can-borrow?contactId=${contactId}`],
});

export const toMyCRM = () =>
  `${window.LM_CONFIG[locale.isNZ ? 'MYCRM_DOMAIN_NZ' : 'MYCRM_DOMAIN_AU']}`;

export const toMyCRMLogin = (redirectUrl) =>
  `${
    window.LM_CONFIG[locale.isNZ ? 'MYCRM_DOMAIN_NZ' : 'MYCRM_DOMAIN_AU']
  }/app/login?path=${encodeURIComponent(redirectUrl)}`;

export const toMyCRMGamePlan = (familyId, loanAppId) =>
  `${
    window.LM_CONFIG[locale.isNZ ? 'MYCRM_DOMAIN_NZ' : 'MYCRM_DOMAIN_AU']
  }/#/app/loan-application/${familyId}/${loanAppId}?isGamePlanCQP=true`;

export const toLoginFromPasswordRecovery = () => ({
  path: LOGIN_CAPTURE_PATH,
  animation: ['/got-it-excited', '/password-recovery-instructions'],
});
