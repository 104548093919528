import _ from 'lodash';

// Purpose
export const PURPOSE_FIRST_HOME = 1;
export const PURPOSE_NEXT_HOME = 2;
export const PURPOSE_INVESTMENT = 3;
export const PURPOSE_COMMERCIAL = 4;
export const PURPOSE_INDUSTRIAL = 5;
export const PURPOSE_BETTER_DEAL = 6;
export const PURPOSE_RENOVATE = 7;
export const PURPOSE_CONSOLIDATE_DEBT = 8;
export const PURPOSE_REVERSE_MORTGAGE = 9;
export const PURPOSE_EXTRA_FUNDS = 10;
export const PURPOSE_REFINANCE = 11;
export const PURPOSE_PERSONAL_LOAN = 28;

// nz
export const PURPOSE_FIRST_HOME_NZ = 12;
export const PURPOSE_NEXT_HOME_NZ = 30;
export const PURPOSE_INVESTMENT_NZ = 29;
export const PURPOSE_HOLIDAY_HOME = 31;
export const PURPOSE_NEW_BUILDING = 33;
export const PURPOSE_RESTRUCTURE_LOAN = 19;
export const PURPOSE_TOP_UP = 18;
export const PURPOSE_ASSET_FINANCE = 24;
export const PURPOSE_CAR_FINANCE = 27;
export const PURPOSE_BRIDGING_FINANCE = 32;
export const PURPOSE_REFINANCING = 34;
export const PURPOSE_DEBT_CONSOLIDATION = 35;
export const PURPOSE_BUSINESS_FINANCE = 36;
export const PURPOSE_PERSONAL_FINANCE = 37;

// Features
export const FEATURE_VARIABLE_INTEREST_RATE = 1;
export const FEATURE_FIXED_INTEREST_RATE = 2;
export const FEATURE_INTRODUCTORY_RATE = 3;
export const FEATURE_PROFESSIONAL_PACK = 4;
export const FEATURE_CONSTRUCTION_FACILITY = 5;
export const FEATURE_EXTRA_REPAYMENTS = 6;
export const FEATURE_NO_ONGOING_FEES = 7;
export const FEATURE_REDRAW_FACILITY = 8;
export const FEATURE_FORTNIGHTLY_REPAYMENTS = 9;
export const FEATURE_WEEKLY_REPAYMENTS = 10;
export const FEATURE_INTEREST_ONLY_FACILITY = 11;
export const FEATURE_NO_EARLY_REPAY_PENALTY = 12;
export const FEATURE_OFFSET_ACCOUNT = 13;
export const FEATURE_LINE_OF_CREDIT = 14;
export const FEATURE_OTHER = 15;

const messages = {
  AU: {
    goalOptionsPurchase: {
      [PURPOSE_FIRST_HOME]: 'First home',
      [PURPOSE_NEXT_HOME]: 'Next home',
      [PURPOSE_INVESTMENT]: 'Investment',
      [PURPOSE_COMMERCIAL]: 'Commercial',
      [PURPOSE_INDUSTRIAL]: 'Industrial',
    },
    goalOptionsRefinance: {
      [PURPOSE_BETTER_DEAL]: 'Get a better deal',
      [PURPOSE_RENOVATE]: 'Renovate',
      [PURPOSE_CONSOLIDATE_DEBT]: 'Consolidate debt',
      [PURPOSE_EXTRA_FUNDS]: 'Borrow extra funds',
      [PURPOSE_REVERSE_MORTGAGE]: 'Reverse mortgage',
      [PURPOSE_PERSONAL_LOAN]: 'Personal loan',
    },
  },
  NZ: {
    goalOptionsPurchase: {
      [PURPOSE_FIRST_HOME_NZ]: 'My First Home',
      [PURPOSE_NEXT_HOME_NZ]: 'My Next Home',
      [PURPOSE_NEW_BUILDING]: 'A New Build',
      [PURPOSE_INVESTMENT_NZ]: 'An Investment',
      [PURPOSE_HOLIDAY_HOME]: 'A Holiday Home',
    },
    goalOptionsRefinance: {
      [PURPOSE_BRIDGING_FINANCE]: 'Bridging Finance',
      [PURPOSE_TOP_UP]: 'Top-up',
      [PURPOSE_REFINANCING]: 'Refinancing',
      [PURPOSE_RESTRUCTURE_LOAN]: 'Restructuring',
      [PURPOSE_DEBT_CONSOLIDATION]: 'Debt Consolidation',
      [PURPOSE_BUSINESS_FINANCE]: 'Business Finance',
      [PURPOSE_ASSET_FINANCE]: 'Asset Finance',
      [PURPOSE_CAR_FINANCE]: 'Car Finance',
      [PURPOSE_PERSONAL_FINANCE]: 'Personal Finance',
    },
  },
};

const prefixes = {
  goalOptionsPurchase: {
    [PURPOSE_FIRST_HOME]: 'your ',
    [PURPOSE_NEXT_HOME]: 'your ',
    [PURPOSE_FIRST_HOME_NZ]: ' ',
    [PURPOSE_NEXT_HOME_NZ]: ' ',
    [PURPOSE_INVESTMENT]: 'an ',
    [PURPOSE_INVESTMENT_NZ]: ' ',
    [PURPOSE_COMMERCIAL]: 'a ',
    [PURPOSE_INDUSTRIAL]: 'an ',
    [PURPOSE_HOLIDAY_HOME]: ' ',
    [PURPOSE_NEW_BUILDING]: ' ',
  },
  goalOptionsRefinance: {
    [PURPOSE_BETTER_DEAL]: 'to ',
    [PURPOSE_RENOVATE]: 'to ',
    [PURPOSE_CONSOLIDATE_DEBT]: 'to ',
    [PURPOSE_EXTRA_FUNDS]: 'to ',
    [PURPOSE_REVERSE_MORTGAGE]: 'to ',
    [PURPOSE_PERSONAL_LOAN]: 'to get a ',
    [PURPOSE_RESTRUCTURE_LOAN]: 'to get a ',
    [PURPOSE_TOP_UP]: 'to ',
    [PURPOSE_ASSET_FINANCE]: 'an ',
    [PURPOSE_CAR_FINANCE]: 'a ',
    [PURPOSE_BRIDGING_FINANCE]: 'a ',
    [PURPOSE_REFINANCING]: 'to get a ',
    [PURPOSE_DEBT_CONSOLIDATION]: 'a ',
    [PURPOSE_PERSONAL_FINANCE]: 'a ',
    [PURPOSE_BUSINESS_FINANCE]: 'a ',
  },
};

const sectionTextMessages = {
  NZ: {
    goalOptionsPurchase: {
      [PURPOSE_FIRST_HOME_NZ]: 'your first home',
      [PURPOSE_NEXT_HOME_NZ]: 'your next home',
    },
  },
};

const sorting = {
  NZ: {
    goalOptionsPurchase: [
      PURPOSE_FIRST_HOME_NZ,
      PURPOSE_NEXT_HOME_NZ,
      PURPOSE_NEW_BUILDING,
      PURPOSE_INVESTMENT_NZ,
      PURPOSE_HOLIDAY_HOME,
    ],
    goalOptionsRefinance: [
      PURPOSE_BRIDGING_FINANCE,
      PURPOSE_TOP_UP,
      PURPOSE_REFINANCING,
      PURPOSE_RESTRUCTURE_LOAN,
      PURPOSE_DEBT_CONSOLIDATION,
      PURPOSE_BUSINESS_FINANCE,
      PURPOSE_ASSET_FINANCE,
      PURPOSE_CAR_FINANCE,
      PURPOSE_PERSONAL_FINANCE,
    ],
  },
};

const optionsFromMessages = (locale, keyOnMessages) => {
  const message = messages[locale.countryCode][keyOnMessages];

  const options = Object.keys(message).map((k) => {
    const sectionText =
      sectionTextMessages[locale.countryCode] &&
      sectionTextMessages[locale.countryCode][keyOnMessages] &&
      sectionTextMessages[locale.countryCode][keyOnMessages][k];
    return {
      value: k,
      key: k,
      name: messages[locale.countryCode][keyOnMessages][k],
      label: messages[locale.countryCode][keyOnMessages][k],
      prefix: prefixes[keyOnMessages][k],
      sectionText,
    };
  });

  if (locale.isNZ) {
    return _.sortBy(options, (o) =>
      sorting[locale.countryCode][keyOnMessages].indexOf(Number(o.key)),
    );
  }

  return options;
};

export const GOAL_OPTIONS_REFINANCE = (locale) =>
  _.partial(optionsFromMessages, locale, 'goalOptionsRefinance');
export const GOAL_OPTIONS_PURCHASE = (locale) =>
  _.partial(optionsFromMessages, locale, 'goalOptionsPurchase');
