import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import {
  LOAD_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  CLEAR_WORKING_CONTACT,
} from 'actions/contactActionTypes';
import {
  SET_CONTACT_IS_COAPPLICANT,
  SET_CONTACT_NZBN,
} from 'actions/individualContactActionTypes';
import contactActions from 'actions/contactActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import addressActions from 'actions/addressActions';
import clientActions from 'actions/clientActions';
import employmentActions from 'actions/employmentActions';

import { PERSON_CONTACT_TYPES, GUARANTOR } from 'constants/options';
import { NZBN_NO_ERROR, NZBN_INVALID, isValidNZBN } from 'lib/nzbnHelpers';

import * as contactSelectors from 'selectors/contactSelectors';
import {
  workingApplication,
  getPrimaryBrokerId,
} from 'selectors/applicationSelectors';
import { putClient, deleteAsCoapplicant } from 'services/clientApi';
import { validateNZBN } from 'services/employmentApi';
import {
  loadClientAddresses,
  createOrUpdateAddresses,
  clearClientAddresses,
} from 'sagas/clientSagas';

import { updateCompany, deleteCompany } from 'services/companyApi';

import {
  postApplicant,
  postCompanyApplicant,
} from 'services/loanApplicationApi';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { isGoalSetter } from 'lib/utils/browserUtils';

const contactIsPerson = (contact) =>
  PERSON_CONTACT_TYPES.includes(contact.type);

export function* createContact({ payload }) {
  // TODO: change UI to load client/company directly instead of faking everything to contact...
  let myCrmContact;
  const application = yield select(workingApplication);
  const brokerId = yield select(getPrimaryBrokerId);
  const newContact = {
    ...payload,
    loanApplicationId: application.id,
    brokerId,
  };
  const isPerson = contactIsPerson(newContact);
  if (isPerson) {
    const client = yield call(postApplicant, newContact);
    myCrmContact = { ...client, id: client.contactId, clientId: client.id };
    if (isGoalSetter()) {
      yield put(clientActions.setNewClient(client));
    }
  } else {
    const company = yield call(postCompanyApplicant, newContact);
    myCrmContact = { ...company, id: company.contactId, companyId: company.id };
    if (isGoalSetter()) {
      yield put(clientActions.setNewClient(company));
    }
  }
  yield put(contactActions.setNewContact(myCrmContact));
  const clientId = contactSelectors.contactClientId(myCrmContact);
  yield call(createOrUpdateAddresses, clientId, true, !isPerson);
  if (myCrmContact.isCoapplicant) {
    yield put(
      clientActions.setNewOrMergeClient({
        ...myCrmContact,
        id: myCrmContact.clientId,
        primaryApplicant: false,
      }),
    );
  }
  yield put(
    loanApplicationActions.createContactOnApplication({
      loanApplicationId: newContact.loanApplicationId,
      contactId: myCrmContact.id,
      role: myCrmContact.role, // TODO remove me??? have a thorough check if this is being used someplace else -__-
      clientId: myCrmContact.clientId,
      companyId: myCrmContact.companyId,
      isGuarantor: myCrmContact.role === GUARANTOR,
    }),
  );
  yield put(
    loanApplicationActions.loadApplication(newContact.loanApplicationId),
  );
}

export function* updateContact({ payload }) {
  const application = yield select(workingApplication);
  const updatedContact = { ...payload, loanApplicationId: application.id };
  const isPerson = contactIsPerson(payload);
  yield isPerson
    ? putClient(payload.clientId, updatedContact)
    : updateCompany(updatedContact.companyId, updatedContact);
  yield put(contactActions.setContact(updatedContact));
  const clientId = contactSelectors.contactClientId(updatedContact);
  yield call(createOrUpdateAddresses, clientId, false, !isPerson);
}

export function* deleteContact({ payload: contactId }) {
  const contactSelector = yield select(contactSelectors.contact);
  const application = yield select(workingApplication);
  const contact = contactSelector(contactId);
  const clientId = contactSelectors.contactClientId(contact);

  yield call(clearClientAddresses, { payload: clientId });

  yield contactIsPerson(contact)
    ? call(deleteAsCoapplicant, contact, application.id)
    : call(deleteCompany, contact.companyId, application.id);
  yield put(contactActions.removeContact(contactId));
  yield put(
    loanApplicationActions.deleteContactOnApplication({
      loanApplicationId: application.id,
      contactId,
    }),
  );
}

export function* loadContactAddresses({ payload: contactId }) {
  const contactSelector = yield select(contactSelectors.contact);
  const contact = contactSelector(contactId);
  const clientId = contactSelectors.contactClientId(contact);
  yield call(loadClientAddresses, { payload: clientId, contact });
}

export function* loadNewContactAddresses() {
  yield put(addressActions.loadNewCurrentAddress(['new']));
  yield put(addressActions.loadNewMailAddress(['new']));
}

export function* clearContactAddresses({ payload: contactId }) {
  let clientId;
  if (contactId === 'new') {
    clientId = 'new';
  } else {
    const contactSelector = yield select(contactSelectors.contact);
    const contact = contactSelector(contactId);
    if (!contact) {
      return;
    } // contact is already deleted
    clientId = contactSelectors.contactClientId(contact);
  }
  yield call(clearClientAddresses, { payload: clientId });
}

export function* updateNZBN({ payload }) {
  try {
    if (isValidNZBN(payload.value)) {
      yield put(employmentActions.setNzbnLoading(true));
      yield validateNZBN(payload.value);
      yield put(contactActions.setContactError(payload.id)(NZBN_NO_ERROR));
      yield put(employmentActions.setNzbnLoading(false));
    }
  } catch (error) {
    yield put(contactActions.setContactError(payload.id)(NZBN_INVALID));
    yield put(employmentActions.setNzbnLoading(false));
    throw error;
  }
}

export default function* contactSagas() {
  yield all([
    monitorAsyncRequest(takeEvery, CREATE_CONTACT, createContact),
    monitorAsyncRequest(takeEvery, UPDATE_CONTACT, updateContact),
    monitorAsyncRequest(takeEvery, DELETE_CONTACT, deleteContact),
    takeEvery(LOAD_CONTACT, loadContactAddresses),
    takeEvery(
      SET_CONTACT_IS_COAPPLICANT,
      loadNewContactAddresses,
    ) /* equivalent to load new coapplicant */,
    takeEvery(CLEAR_WORKING_CONTACT, clearContactAddresses),
    takeEvery(SET_CONTACT_NZBN, updateNZBN),
  ]);
}
