import React from 'react';
import PropTypes from 'prop-types';
import styles from './Rotation.css';

function Rotation(props) {
  const { children, width, height, ...rest } = props;

  return (
    <div
      className={styles.rotation}
      style={{ width: props.width, height: props.height }}
      {...rest}
    >
      {props.children}
    </div>
  );
}

Rotation.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

export default Rotation;
