import axios from 'axios';
import { BANK_CONNECT_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export function getMogoBankList(mogoProviderVersion) {
  return axios
    .get(`${BANK_CONNECT_API_PATH}/mogo-bank-list`, {
      params: { mogoProviderVersion },
    })
    .then(returnJSON);
}

export function mogoBankLogin(bankId, mogoProviderVersion) {
  return axios
    .post(
      `${BANK_CONNECT_API_PATH}/mogo-login/${bankId}`,
      {},
      { params: { mogoProviderVersion } },
    )
    .then(returnJSON);
}

export function getTransactionStatus(accessId, mogoProviderVersion) {
  return axios
    .get(`${BANK_CONNECT_API_PATH}/status/${accessId}`, {
      params: { mogoProviderVersion },
    })
    .then(returnJSON);
}

export function pollBankConnectCollection(requestId, mogoProviderVersion) {
  return axios
    .get(`${BANK_CONNECT_API_PATH}/poll-collections/${requestId}`, {
      params: { mogoProviderVersion },
    })
    .then(returnJSON);
}

export function postCollectionByAccessId(
  { accessId, contactId, fullReport, advisorId, loanAppId },
  mogoProviderVersion,
) {
  return axiosWithAuth
    .post(
      `${BANK_CONNECT_API_PATH}/collections/${accessId}`,
      { contactId, fullReport, advisorId, loanAppId },
      { params: { mogoProviderVersion } },
    )
    .then(returnJSON);
}
