export const CLEAR_WORKING_ASSET = 'clearWorkingAsset';
export const LOAD_ASSET = 'loadAsset';
export const SET_ASSET = 'setAsset';
export const REMOVE_ASSET = 'removeAsset';
export const SET_NEW_ASSET = 'setNewAsset';
export const SET_NEW_ASSETS = 'setNewAssets';
export const INSERT_NEW_ASSETS = 'insertNewAssets';

export const CREATE_ASSET = 'createAsset';
export const UPDATE_ASSET = 'updateAsset';
export const DELETE_ASSET = 'deleteAsset';

export const CONFIRM_ASSETS = 'confirmAssets';
