import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import { FUTURE } from 'shared/constants/goalDashboard';

import goalLoanApplicationActions from 'actions/goal/goalLoanApplicationActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import * as clientSelectors from 'selectors/clientSelectors';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';

import { getSection } from 'lib/goalFutureHelper';
import { getSectionFromUrl } from 'lib/utils/browserUtils';

import GoalFutureApplyRoutes from './GoalFutureApplyRoutes';
import GoalFutureRoutes from './GoalFutureRoutes';
export function GoalFuture(props) {
  const {
    match,
    client,
    nextClient,
    family,
    clients,
    active,
    updateHasVisited,
    questions,
    familyId,
    fetchQuestionsAndAnswers,
  } = props;
  const {
    params: { section },
    url,
  } = match;
  const sectionProps = getSection({
    section,
    ...(!client ? { family, client: clients[0] } : { client }),
    nextClient,
    active,
  });
  const sectionName = getSectionFromUrl(url);

  useEffect(() => {
    updateHasVisited(FUTURE);
    if (Object.keys(questions).length === 0) {
      fetchQuestionsAndAnswers({ familyId, loanAppId: active });
    }
  }, []);

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => clearTimeout(scrollTimeout);
  }, [section]);

  return (
    <ContentLayout
      sectionName={sectionName}
      title={sectionProps.title}
      theme='noContentBg'
      showBackButton={false}
    >
      {match.path.includes('apply') ? (
        <GoalFutureApplyRoutes />
      ) : (
        <GoalFutureRoutes active={active} />
      )}
    </ContentLayout>
  );
}

GoalFuture.propTypes = {
  match: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  goTo: PropTypes.func,
  client: PropTypes.object,
  nextClient: PropTypes.object,
  family: PropTypes.object,
  clients: PropTypes.arrayOf(PropTypes.object),
  active: PropTypes.number,
  updateHasVisited: PropTypes.func,
  questions: PropTypes.object,
  familyId: PropTypes.number,
  fetchQuestionsAndAnswers: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const clientId = Number(props.match.params.clientId);
  const familyId = Number(props.match.params.familyId);

  return {
    ...(clientId && {
      client: clientSelectors.client(state)(clientId),
    }),
    ...(!clientId && {
      family: clientSelectors.family(state)(familyId),
      clients: clientSelectors.clientsOnContact(state)(familyId) || [],
    }),
    nextClient: clientSelectors.nextClient(state)(familyId, clientId),
    questions: goalLoanAppInfoSelectors.allQuestions(state),
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    familyId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateHasVisited: goalLoanApplicationActions.updateHasVisited,
      fetchQuestionsAndAnswers:
        loanApplicationActions.requestGoalSetterQuestionsAndAnswers,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalFuture);
