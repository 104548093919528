import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { intlShape, injectIntl } from 'react-intl';
import locale from 'config/locale';

import { lenderPdpBannerUrl } from 'lib/utils/imageUtils';
import { formatProductName } from 'lib/utils/stringUtils';
import Button from 'components/Button/Button';
import productDetailMessages from 'constants/productDetailFormatters';
import getLender from 'lib/lenderHelper';

import styles from './HeaderAndPropertiesSection.css';

export const HeaderAndPropertiesSection = (props) => {
  const {
    product,
    isLocked,
    sectionStyles,
    onSelectProduct,
    isLoggedIn,
    isSelectedProduct,
    hideSelectButton,
    intl: { formatMessage },
  } = props;
  const [lenderImageErrorIds, setLenderImageErrorIds] = useState([]);
  const { key } = getLender(product.lender.id) || {};
  const bannerUrl = lenderPdpBannerUrl(
    product.lender.id,
    locale.data.countryCode,
  );
  const hideSelect = hideSelectButton || isLocked;
  const rootStyle = classNames(sectionStyles, {
    [styles.isSelectedProduct]: isSelectedProduct,
    [styles.hideSelectButton]: hideSelect,
  });
  const buttonStyle = classNames(styles[`button${key}`], 'height-4');
  let callToAction = 'Select';
  if (isSelectedProduct) {
    callToAction = isLoggedIn ? 'Select a different loan' : 'Apply';
  }
  const hasBanner = !lenderImageErrorIds.includes(product.lender.id);
  const handleImageError = () =>
    setLenderImageErrorIds([...lenderImageErrorIds, product.lender.id]);

  return (
    <section className={rootStyle}>
      {isSelectedProduct && (
        <div id='selected' className={styles.badge}>
          {formatMessage(productDetailMessages.selected)}
        </div>
      )}
      <div id='banner' className={styles.banner}>
        {hasBanner && (
          <img
            className={styles.logo}
            src={bannerUrl}
            onError={handleImageError}
            alt={product.lender.name}
          />
        )}
      </div>
      <div className={styles.bodyWrapper}>
        <div className={styles.titleAndProductDescription}>
          <div className={styles.productInfo}>
            <h2>{formatProductName(product.name)}</h2>
            <div className={styles.productName}>{product.name}</div>
          </div>
        </div>
        {!hideSelect && (
          <div className={styles.productInfoAndButtonGroup}>
            <Button
              className={buttonStyle}
              icon='mi-arrow-with-circle-right'
              onClick={onSelectProduct}
            >
              {callToAction}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

HeaderAndPropertiesSection.propTypes = {
  product: PropTypes.object.isRequired,
  onSelectProduct: PropTypes.func,
  intl: intlShape.isRequired,
  isSelectedProduct: PropTypes.bool,
  hideSelectButton: PropTypes.bool,
  isLocked: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  sectionStyles: PropTypes.string,
};

export default injectIntl(HeaderAndPropertiesSection);
