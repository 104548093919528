import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';
import delay from 'hocs/delay';

class WelcomeBack extends Component {
  static propTypes = {
    next: PropTypes.func,
  };

  nextRoute() {
    this.props.next();
  }

  render() {
    return (
      <AnimationWrapper key='welcomeBack' id='welcomeBack'>
        <AnimatedHeading onFinish={() => this.nextRoute()}>
          <h1 data-duration={1200}>Great to see you again!</h1>
          <h1 data-duration={1600}>Here’s a summary of where you left off.</h1>
        </AnimatedHeading>
      </AnimationWrapper>
    );
  }
}

export default delay(WelcomeBack);
