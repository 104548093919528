import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as myPropTypes from 'types/customPropTypes';
import { reduxMirrorPropTypes } from '@loanmarket/react-redux-mirror';

import Questions from 'lib/Questions';
import { REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO } from 'constants/validators';

import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';

import Form from 'components/Form/Form';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

const questionSet = (props) => {
  const questions = new Questions();
  if (props.id !== PROSPECTIVE_PROPERTY_ID) {
    questions.addBranch(['value', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO]);
  }
  questions.addBranchOrAdd(['percent', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO]);
  return questions.arrayOfQuestions();
};

class InterestOnlyRepaymentForm extends Component {
  static propTypes = {
    value: myPropTypes.primitiveType,
    percent: myPropTypes.primitiveType,
    setValue: PropTypes.func.isRequired,
    setPercent: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
    ...reduxMirrorPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  handleUpdate = () => {
    const { sync, onSubmit } = this.props;
    sync();
    onSubmit();
  };

  handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && this.formCompleted) {
      this.handleUpdate();
    }
  };

  render() {
    const {
      value,
      percent,
      setValue,
      setPercent,
      questions,
      setCurrentQuestionTo,
      formCompleted,
    } = this.props;
    return (
      <Form
        formCompleted={formCompleted}
        onSubmit={this.handleUpdate}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <Question
          {...questions.value}
          className='fullWidthInput'
          label='Outstanding mortgage'
        >
          <CurrencyInput
            id='id'
            maxLength={9}
            action={setValue}
            value={value}
            onKeyDown={this.handleOnKeyDown}
          />
        </Question>
        <Question
          {...questions.percent}
          className='fullWidthInput'
          label='Interest rate'
        >
          <InputWithSpan
            id='id'
            spanValue='%'
            type='number'
            maxLength={9}
            action={setPercent}
            value={percent}
            onKeyDown={this.handleOnKeyDown}
          />
        </Question>
        <Button
          disabled={!formCompleted}
          id='PopupButton'
          className='brandColor__button margin-0'
          onClick={this.handleUpdate}
        >
          Update
        </Button>
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

export default manageQuestions(InterestOnlyRepaymentForm);
