import _ from 'lodash';

import * as docAU from 'shared/constants/myCRMTypes/documentsAU';
import * as docNZ from 'shared/constants/myCRMTypes/documentsNZ';

export const DOCUMENT_UPLOADED_STATUS_ID = 2;

// DOCUMENT_TYPE_LOOKUP
export const documentTypeLookup = (locale) => {
  if (locale.isAU) {
    return _.keyBy(docAU.DOCUMENT_TYPES, 'id');
  }
  if (locale.isNZ) {
    return _.keyBy(docNZ.DOCUMENT_TYPES, 'id');
  }
};

// DOCUMENT_TYPES_PER_SUB_CATEGORY_KEY
export const documentTypesPerSubCategoryKey = (countryCode) => {
  if (countryCode === 'AU') {
    return _.groupBy(docAU.DOCUMENT_TYPES, 'subCategory');
  }
  if (countryCode === 'NZ') {
    return _.groupBy(docNZ.DOCUMENT_TYPES, 'subCategory');
  }
};

// DOCUMENT_SUB_CATEGORY_LOOKUP
export const documentSubCategoryLookup = (countryCode) => {
  if (countryCode === 'AU') {
    return _.keyBy(docAU.DOCUMENT_SUB_CATEGORIES, 'key');
  }
  if (countryCode === 'NZ') {
    return _.keyBy(docNZ.DOCUMENT_SUB_CATEGORIES, 'key');
  }
};

// DOCUMENT_SUB_CATEGORIES_PER_CATEGORY_KEY
export const documentSubCategoriesPerCategoryKey = (locale) => {
  if (locale.isAU) {
    return _.groupBy(docAU.DOCUMENT_SUB_CATEGORIES, 'category');
  }
  if (locale.isNZ) {
    return _.groupBy(docNZ.DOCUMENT_SUB_CATEGORIES, 'category');
  }
};

// DOCUMENT_TYPES
export const documentTypes = (countryCode) => {
  if (countryCode === 'AU') {
    return docAU.DOCUMENT_TYPES;
  }
  if (countryCode === 'NZ') {
    return docNZ.DOCUMENT_TYPES;
  }
};
