import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { push, goBack } from '@loan_market/react-router-redux-multi';

import { getSection } from 'lib/goalFutureHelper';
import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';

import { LONG_TERM_GOALS } from 'shared/constants/goalLoanAppInfo';

import UIActions from 'actions/UIActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';

import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

export function GoalFutureLongTerm(props) {
  const {
    questions,
    answers,
    family,
    goTo,
    getLoanAppInfoQuestions,
    clients,
    applicationId,
    getNextClient,
    goToPathWithAnimation,
    saveLoanAppInfoAnswers,
    back,
    trackEvent,
  } = props;
  const familyId = family.contactId;
  const [savedAnswers, updateSavedAnswers] = useState({});
  const [savedFormAnswers, updateSavedFormAnswers] = useState({});

  const setAnswer = (questionId, value, goalTypeId) => {
    updateSavedAnswers({
      ...savedAnswers,
      [questionId]: { value, goalTypeId },
    });
    updateSavedFormAnswers({ ...savedFormAnswers, [questionId]: value });
  };

  const onSave = (isBack) => {
    const path = getSection({
      section: LONG_TERM_GOALS,
      client: clients[0],
      family,
      nextClient: getNextClient(familyId, clients[0].id),
      active: applicationId,
    }).nextPath;
    if (Object.keys(savedAnswers).length) {
      saveLoanAppInfoAnswers({
        section: LONG_TERM_GOALS,
        loanAppId: applicationId,
        familyId,
        answers: savedAnswers,
      });
      if (!isBack) {
        goToPathWithAnimation({ path, animation: ['/got-it'] });
      }
      return;
    }
    if (!isBack) {
      goTo(path);
    }
  };

  useEffect(() => {
    if (familyId) {
      getLoanAppInfoQuestions({ familyId });
    }
  }, [familyId]);

  const formProps = {
    formName: LONG_TERM_GOALS,
    action: setAnswer,
    questions,
    answers: { ...(answers || {}), ...savedFormAnswers },
    displayName: family.familyFullName,
    submitButtonProps: {
      text: 'Save and Next',
      action: onSave,
      theme: 'buttonNext',
    },
    back,
    trackEvent,
  };

  return <GoalSetterForm {...formProps} />;
}

GoalFutureLongTerm.propTypes = {
  goTo: PropTypes.func,
  goToPathWithAnimation: PropTypes.func,
  match: PropTypes.object,
  family: PropTypes.object,
  clients: PropTypes.arrayOf(PropTypes.object),
  questions: PropTypes.object,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  applicationId: PropTypes.number,
  getNextClient: PropTypes.func,
  getLoanAppInfoQuestions: PropTypes.func,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const familyId = Number(props.match.params.familyId);
  return {
    applicationId: goalLoanApplicationSelectors.loanApplicationId(state),
    family: clientSelectors.family(state)(familyId),
    clients: clientSelectors.clientsOnContact(state)(familyId),
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(
      LONG_TERM_GOALS,
    ),
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      familyId,
      LONG_TERM_GOALS,
    ),
    getNextClient: clientSelectors.nextClient(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      back: goBack,
      trackEvent: UIActions.trackEvent,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      getLoanAppInfoQuestions: goalLoanAppInfoActions.getLoanAppInfoQuestions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalFutureLongTerm);
