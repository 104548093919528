export default (app) => {
  if (!window.Intl) {
    require.ensure(
      [
        'intl',
        'intl/locale-data/jsonp/en.js',
        'intl/locale-data/jsonp/en-AU.js',
        'intl/locale-data/jsonp/zh-Hans-CN.js',
      ],
      function (require) {
        require('intl');
        require('intl/locale-data/jsonp/en');
        require('intl/locale-data/jsonp/en-AU');
        require('intl/locale-data/jsonp/zh-Hans-CN');
        app();
      },
    );
  } else {
    app();
  }
};
