import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import Button from 'components/Button/Button';
import ReactVivus from 'components/AnimationSequences/ReactVivus';
import CrossroadsSvg from 'assets/inbetweeners/crossroads.svg';
import {
  FORK_CHOICE_FAST_APPROVAL,
  FORK_CHOICE_DIY_RESEARCH,
} from 'constants/loanScenariosUI';
import {
  FORK_DIY_RESEARCH,
  FORK_FAST_APPROVAL,
} from 'shared/constants/loanScenarios';
import { afterForkChoices } from 'lib/pathHelper';
import UIActions from 'actions/UIActions';
import scenarioActions from 'actions/scenarioActions';
import * as applicationSelectors from 'selectors/applicationSelectors';
import { optimizeActivate } from 'lib/utils/browserUtils';
import styles from './ForkChoices.css';

class ForkChoices extends Component {
  static propTypes = {
    saveForkChoice: PropTypes.func.isRequired,
    goToPathWithAnimation: PropTypes.func.isRequired,
    isCustomerCare: PropTypes.bool,
  };

  componentDidMount() {
    optimizeActivate('fork', 'on');
  }

  /**
   * Sets the fork choice and starts the animation.
   * @param {string} value - The fork choice.
   */
  onForkChoiceSet = (value) => {
    const {
      saveForkChoice,
      goToPathWithAnimation,
      isCustomerCare,
    } = this.props;
    saveForkChoice(value);
    goToPathWithAnimation(afterForkChoices(value, isCustomerCare));
    optimizeActivate('fork', 'off');
  };

  render() {
    const imageContainerClassName = classNames({
      [styles.imageContainer]: true,
      [styles.image]: true,
    });

    return (
      <>
        <AnimationWrapper key='ForkChoices' id='ForkChoices'>
          <ReactVivus
            svg={CrossroadsSvg}
            duration={50}
            className={imageContainerClassName}
            name='ForkChoices'
          />
        </AnimationWrapper>
        <h1 className={styles.heading}>What would you like to do today?</h1>
        <Button
          id={`ForkChoices--${FORK_FAST_APPROVAL}`}
          onClick={() => this.onForkChoiceSet(FORK_CHOICE_FAST_APPROVAL.value)}
          theme='transparent'
          className={styles.button}
        >
          {FORK_CHOICE_FAST_APPROVAL.label}
        </Button>
        <h2 className={styles.smallHeading}>or</h2>
        <Button
          id={`ForkChoices--${FORK_DIY_RESEARCH}`}
          onClick={() => this.onForkChoiceSet(FORK_CHOICE_DIY_RESEARCH.value)}
          theme='transparent'
          className={styles.button}
        >
          {FORK_CHOICE_DIY_RESEARCH.label}
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveForkChoice: scenarioActions.saveForkChoice,
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForkChoices);
