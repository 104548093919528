import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/aggregatorActionTypes';
import lenders from 'shared/lib/lenderHelper';
export const initialState = {
  errors: {},
  aggregator: null,
  loading: false,
};

const aggregatorReducer = handleActions(
  {
    [actionTypes.REQUEST_AGGREGATOR](state) {
      return { ...state, loading: true };
    },
    [actionTypes.SET_AGGREGATOR](state, action) {
      lenders.data = { _all: lenders.all, _aggregatorId: action.payload.id };
      return { ...state, loading: false, aggregator: action.payload };
    },
  },
  initialState,
);

export default aggregatorReducer;
