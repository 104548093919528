export const REQUEST_PRODUCTS = 'requestProducts';
export const REQUEST_MORE_PRODUCTS = 'requestMoreProducts';
export const REQUEST_PRODUCT_DETAIL = 'requestProductDetail';
export const REQUEST_SORTED_PRODUCTS = 'requestSortedProducts';
export const REQUEST_SELECT_PRODUCT = 'requestSelectProduct';
export const REQUEST_LENDER_PRODUCTS = 'requestLenderProducts';
export const REQUEST_FEATURED_PRODUCTS = 'requestFeaturedProducts';
export const REQUEST_REFINANCE_PRODUCTS = 'requestRefinanceProducts';

export const SET_PRODUCTS = 'setProducts';
export const APPEND_PRODUCTS = 'appendProducts';
export const SET_PRODUCTS_LOADING_MORE = 'setProductsLoadingMore';
export const SET_PRODUCT_DETAIL = 'setProductDetail';
export const CLEAR_PRODUCT_DETAIL = 'clearProductDetail';
export const SET_FEATURED_PRODUCTS = 'setFeaturedProducts';
export const SET_INTEREST_PRODUCTS = 'setInterestProducts';
