import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as actionTypes from './employmentActionTypes';
import * as individualActionTypes from './individualEmploymentActionTypes';

export const employmentActions = createActions(
  {},
  ...Object.values(actionTypes),
);

export default {
  ...employmentActions,
  ...createIndividualisedActions(individualActionTypes),
};
