import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/incomeTypeActions';

export const initialState = {
  incomeTypes: { categories: [] },
};

const incomeTypeReducer = handleActions(
  {
    [actionTypes.SET_INCOME_TYPES](state, action) {
      return { ...state, incomeTypes: action.payload };
    },
  },
  initialState,
);

export default incomeTypeReducer;
