import React, { Component } from 'react';
import PropTypes from 'prop-types';
import visibilityHOC from 'hocs/visibility';

function delay(WrappedComponent) {
  const nameOfWrappedComponent =
    WrappedComponent.displayName || WrappedComponent.name;
  let ComponentToRender = WrappedComponent;
  if (
    !(
      nameOfWrappedComponent && nameOfWrappedComponent === 'VisibilityComponent'
    )
  ) {
    ComponentToRender = visibilityHOC(WrappedComponent);
  }

  return class Delayed extends Component {
    static propTypes = {
      delay: PropTypes.number.isRequired,
    };

    static defaultProps = {
      delay: 0,
    };

    constructor(props) {
      super(props);
      this.state = {
        visible: false,
      };
    }

    componentDidMount() {
      if (this._timeOutID) {
        clearTimeout(this._timeOutID);
      }
      const { delay: delayMs } = this.props;
      this._timeoutStart = new Date().getTime();
      this.setupDelay(delayMs);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.delay !== this.props.delay) {
        // Trigger new timing
        const currentElapsed = new Date().getTime() - this._timeoutStart;
        const remainingNewTime = nextProps.delay - currentElapsed;
        clearTimeout(this._timeOutID);
        this.setupDelay(remainingNewTime > 0 ? remainingNewTime : 0);
      }
    }

    setupDelay(waitMs) {
      this._timeOutID = setTimeout(() => {
        this.setState({ visible: true });
        this._timeOutID = null;
      }, waitMs);
    }

    render() {
      return (
        <ComponentToRender visibility={this.state.visible} {...this.props} />
      );
    }
  };
}

export default delay;
