import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import classNames from 'classnames/bind';
import locale from 'config/locale';

import { generateUniqueID, formatProductName } from 'lib/utils/stringUtils';
import { lenderLogoUrl, lenderReversedLogoUrl } from 'lib/utils/imageUtils';

import Accordion from 'components/Accordion/Accordion';
import ProductSummary from 'components/ProductSummary/ProductSummary';
import TextSummary from 'components/TextSummary/TextSummary';
import Button from 'components/Button/Button';

import getLender from 'lib/lenderHelper';

import styles from './ProductRecord.css';

const messages = defineMessages({
  selectButtonText: {
    id: 'ProductRecord.selectButton',
    defaultMessage: 'Select this loan',
  },
  goBackToApply: {
    id: 'ProductRecord.goBackToApply',
    defaultMessage: 'Go back to apply',
  },
  viewDetailText: {
    id: 'ProductRecord.viewDetail',
    defaultMessage: 'View details',
  },
  backToComparePage: {
    id: 'ProductRecord.backToComparePage',
    defaultMessage: 'Select a different loan',
  },
  viewMoreProductOfLender: {
    id: 'ProductRecord.viewMore',
    defaultMessage: 'View all {totalQueryCount} loans',
  },
});

class ProductRecord extends React.PureComponent {
  state = { hasLogo: true, showButtons: false, showButtonsTimeout: null };

  static propTypes = {
    intl: intlShape,
    isLocked: PropTypes.bool,
    onSelectProduct: PropTypes.func,
    repaymentFrequency: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    product: PropTypes.object.isRequired,
    loanTerm: PropTypes.number,
    repaymentType: PropTypes.string,
    requestLenderProductsAction: PropTypes.func,
    forceFocus: PropTypes.bool,
    isGrouping: PropTypes.bool,
    isSelectedLender: PropTypes.bool,
    isSelectedProduct: PropTypes.bool,
    hideSelectButton: PropTypes.bool,
    sortingDescription: PropTypes.string,
  };

  static renderExpandIcon(isCollapsed) {
    const iconClass = classNames(styles.expandIcon, {
      'mi-arrow-with-circle-down': isCollapsed,
      'mi-arrow-with-circle-up': !isCollapsed,
    });
    return <i className={iconClass} />;
  }

  static renderTitle(title) {
    const render = (name, i, self) => {
      const titleStyle = classNames(styles.titleWrapper, {
        [styles.right]: self && i === self.length - 1,
      });
      return (
        <div key={`title${i}`} className={titleStyle}>
          <span className={styles.title}>{name}</span>
        </div>
      );
    };
    return typeof title === 'string' ? render(title, 0) : title.map(render);
  }

  handleImageError = () => {
    this.setState({ hasLogo: false });
  };

  static renderLogo(lender) {
    return (
      <div className={styles.logo}>
        <img
          src={lenderLogoUrl(lender.key, locale.data.countryCode)}
          alt={lender.lenderName}
          title={lender.lenderName}
          onError={this.handleImageError}
        />
      </div>
    );
  }

  componentDidMount() {
    const { forceFocus } = this.props;
    if (forceFocus && this.accordionEl) {
      window.scrollTo(0, this.accordionEl.offsetTop);
    }
  }

  componentWillUnmount() {
    if (this.state.showButtonsTimeout) {
      clearTimeout(this.state.showButtonsTimeout);
    }
  }

  shouldShowSelected = (isGroupProduct) => {
    const { isSelectedLender, isSelectedProduct } = this.props;
    return (
      (isGroupProduct && isSelectedLender) ||
      (!isGroupProduct && isSelectedProduct)
    );
  };

  renderSortingDescription(lender) {
    const { sortingDescription } = this.props;
    const borderStyle = styles[`sortingDescriptionBorder${lender.key}`];
    return (
      <div className={classNames(styles.descriptionWrapper, borderStyle)}>
        {sortingDescription}
      </div>
    );
  }

  renderTitleSection({ lender, isCollapsed, isGroupProduct, toggle }) {
    const {
      product: { name },
    } = this.props;
    const titleSectionStyle = classNames(styles.titleSection, {
      overflowHidden: isCollapsed,
      [styles.roundedBorder]: !isGroupProduct,
    });
    return (
      <div
        className={titleSectionStyle}
        onClick={isCollapsed ? undefined : toggle}
      >
        {isGroupProduct
          ? this.renderSortingDescription(lender)
          : ProductRecord.renderLogo(lender)}
        {ProductRecord.renderTitle(formatProductName(name))}
        {ProductRecord.renderExpandIcon(isCollapsed)}
      </div>
    );
  }

  renderHeader(lender) {
    const {
      intl: { formatMessage },
      product: { totalQueryCount },
      requestLenderProductsAction,
    } = this.props;
    const logoUrl = lenderReversedLogoUrl(
      lender.lenderId,
      locale.data.countryCode,
      lender.mogoId,
    );
    return (
      <div
        className={classNames(styles.header)}
        onClick={
          () => requestLenderProductsAction({ lenderId: lender.lenderId })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      >
        <div className={classNames(styles.slibing, styles[lender.key])}>
          {logoUrl && this.state.hasLogo ? (
            <div className={styles.logo}>
              <img
                src={logoUrl}
                alt={lender.lenderName}
                title={lender.lenderName}
                onError={this.handleImageError}
              />
            </div>
          ) : (
            <h4 className={styles.lenderName}>{lender.lenderCode}</h4>
          )}
        </div>
        <div className={styles.viewMore}>
          <Button
            key={generateUniqueID('ViewMore', 1)}
            className={classNames(styles.viewMoreButton, styles[lender.key])}
          >
            {formatMessage(messages.viewMoreProductOfLender, {
              totalQueryCount,
            })}
          </Button>
        </div>
      </div>
    );
  }

  onSelection = () => {
    const { onSelectProduct, product } = this.props;
    if (onSelectProduct) {
      onSelectProduct(product);
    }
  };

  renderAccordionContent = ({ contentRef, toggle, isCollapsed }) => {
    const {
      intl: { formatMessage },
      isLocked,
      loanTerm,
      repaymentType,
      repaymentFrequency,
      product,
      isGrouping,
      isSelectedProduct,
      hideSelectButton,
    } = this.props;

    const lender = getLender(product.lender.id);

    const shouldShowSelected = this.shouldShowSelected(isGrouping);

    const contentStyle = classNames(styles.content, {
      [styles.collapsedContent]: isCollapsed,
      [styles.contentBorder]: !isGrouping || !isCollapsed,
      [styles.topBorder]: !isGrouping,
      [styles.roundedBorder]: !isGrouping,
      [styles.borderColor]: !isGrouping,
      [styles[`productRecord${lender.key}`]]: isGrouping && isCollapsed,
    });
    const rootStyle = classNames(styles.root, {
      [styles.collapsed]: isCollapsed,
      [styles.shouldShowSelected]: shouldShowSelected,
    });

    this.showButtonsTimeout = setTimeout(() => {
      this.setState({ showButtons: !isCollapsed });
    }, 300);

    return (
      <section
        ref={(ref) => {
          this.accordionEl = ref;
        }}
        className={rootStyle}
      >
        {shouldShowSelected && (
          <div className={styles.badge}>
            {isSelectedProduct ? 'Selected' : 'Selected Lender'}
          </div>
        )}

        {isGrouping && this.renderHeader(lender)}
        <div
          className={contentStyle}
          onClick={isCollapsed ? toggle : undefined}
        >
          {this.renderTitleSection({
            lender,
            isCollapsed,
            isGroupProduct: isGrouping,
            toggle,
          })}
          <div className={classNames(styles.primaryContent, styles.topBorder)}>
            <ProductSummary
              {...product}
              loanTerm={loanTerm}
              repaymentFrequency={repaymentFrequency}
              repaymentType={repaymentType}
            />
          </div>
          <div ref={contentRef} className={styles.secondaryContent}>
            <div className={styles.collapsibleContent}>
              <TextSummary
                previewLength={150}
                className={styles.lenderDescription}
              >
                {lender.lenderDescription}
              </TextSummary>
              <div className={styles.buttonWrapper}>
                {this.state.showButtons && (
                  <>
                    {!hideSelectButton && (!isLocked || isSelectedProduct) && (
                      <Button
                        theme='productAction'
                        className={styles[`button${lender.key}`]}
                        onClick={this.onSelection}
                        icon='mi-arrow-with-circle-right'
                      >
                        {formatMessage(
                          messages[
                            isSelectedProduct
                              ? 'goBackToApply'
                              : 'selectButtonText'
                          ],
                        )}
                      </Button>
                    )}
                    <Button
                      url={`/product-detail/${product.id}`}
                      theme='productAction'
                      className={styles[`buttonWhite${lender.key}`]}
                    >
                      {formatMessage(messages.viewDetailText)}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  render = () => (
    <Accordion expandByDefault={this.props.forceFocus}>
      {this.renderAccordionContent}
    </Accordion>
  );
}

export default injectIntl(ProductRecord);
