import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import View from 'components/View/View';
import Spinner from 'components/Spinner/Spinner';

import * as UISelectors from 'selectors/UISelectors';

const ParentView = (props) => {
  const { children, isSpinnerLoading, viewProps } = props;
  return (
    <View {...viewProps}>
      <Spinner loading={isSpinnerLoading}>{children}</Spinner>
    </View>
  );
};

ParentView.propTypes = {
  children: PropTypes.node,
  isSpinnerLoading: PropTypes.bool,
  viewProps: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
});

export default connect(mapStateToProps, null)(ParentView);
