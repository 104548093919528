/* eslint-disable no-unused-vars */
import { takeEvery, all, put } from 'redux-saga/effects';

import structureActions, {
  SELECT_PRODUCT,
  ADD_SPLIT,
  REMOVE_SPLIT,
  EDIT_SPLITS,
} from 'actions/structureActions';
import UIActions from 'actions/UIActions';
import { monitorAsyncRequest } from 'lib/sagaHelpers.js';

import { CONTACT_DETAILS_PATH } from 'shared/constants/paths';

export function* selectProduct({ payload }) {
  yield put(
    structureActions.updateWorkingStructure({
      productId: payload.id,
      lenderId: payload.lender.id,
    }),
  );
  yield put(UIActions.setNextPath(CONTACT_DETAILS_PATH));
  yield put(UIActions.startAnimationSequence(['/welcome-from-compare']));
}

export function* addSplit({ payload }) {
  const { newSplit, adjustedSplits } = payload;
  yield put(structureActions.setNewStructure(newSplit));

  for (const split of adjustedSplits) {
    yield put(structureActions.setStructure(split));
  }
}

export function* removeSplit({ payload }) {
  const { id, adjustedSplits } = payload;
  yield put(structureActions.removeStructure(id));

  for (const split of adjustedSplits) {
    yield put(structureActions.setStructure(split));
  }
}

export function* editSplits({ payload: splits }) {
  for (const split of splits) {
    yield put(structureActions.setStructure(split));
  }
}

export default function* fundingSagas() {
  yield all([
    monitorAsyncRequest(takeEvery, SELECT_PRODUCT, selectProduct),
    takeEvery(ADD_SPLIT, addSplit),
    takeEvery(REMOVE_SPLIT, removeSplit),
    takeEvery(EDIT_SPLITS, editSplits),
  ]);
}
