import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { bindActionCreators } from 'redux';
import { goBack, push } from '@loan_market/react-router-redux-multi';

import UIActions from 'actions/UIActions';
import Button from 'components/Button/Button';
import { BACK_BUTTON_EVENT } from 'constants/GTMEvents';

import styles from './ContentLayout.css';

function ContentLayout(props) {
  const {
    children,
    subtitle,
    theme,
    title,
    inverse,
    showLightbox,
    back,
    showBackButton,
    backPath,
    goTo,
    sectionName,
    trackEvent,
    saveAction,
  } = props;
  const renderHeader = () => {
    const className = classNames.bind(styles)('headingWrapper', {
      inverseHeader: inverse,
    });
    return (
      <div className={className}>
        <div className={styles.heading}>
          {subtitle && <h6>{subtitle}</h6>}
          <h1>{title}</h1>
        </div>
      </div>
    );
  };

  const onBack = () => {
    if (saveAction) {
      saveAction();
    }
    trackEvent({ event: BACK_BUTTON_EVENT, section: sectionName });
    return backPath ? goTo(backPath) : back();
  };

  const renderBackButton = () => {
    return (
      <div className={styles.navigation}>
        <Button
          id='backButton'
          icon='mi-arrow-left'
          onClick={onBack}
          theme='linkButtonTheme'
        >
          Back
        </Button>
      </div>
    );
  };

  const sectionStyle = classNames.bind(styles)('root', theme.split(/\s/g));
  const lightboxClasses = classNames(styles.lightbox, {
    [styles.show]: showLightbox,
  });

  return (
    <section className={sectionStyle}>
      <div className={lightboxClasses} />
      <div className={styles.content}>
        {title && renderHeader()}
        {showBackButton && renderBackButton()}
        <div className={styles.children}>{children}</div>
      </div>
    </section>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string,
  inverse: PropTypes.bool,
  showLightbox: PropTypes.bool,
  back: PropTypes.func,
  showBackButton: PropTypes.bool,
  backPath: PropTypes.string,
  goTo: PropTypes.func,
  trackEvent: PropTypes.func,
  sectionName: PropTypes.string,
  saveAction: PropTypes.func,
};

ContentLayout.defaultProps = {
  showBackButton: true,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      back: goBack,
      goTo: push,
      trackEvent: UIActions.trackEvent,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ContentLayout);
