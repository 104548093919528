import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { intlShape, defineMessages } from 'react-intl';

import { formatCurrency } from 'lib/intlFormatters';

import styles from './InfoSection.css';

export const messages = defineMessages({
  applyToConfirm: {
    id: 'InfoSection.applyToConfirm',
    defaultMessage: 'Apply To Confirm',
  },
});

export const InfoSection = ({
  intl,
  intl: { formatMessage },
  heading,
  headingInfoItems,
  value,
  valueHeading,
  valueSubtitle,
}) => (
  <div className={styles.infoSection}>
    <div className={styles.maxBorrowLabel}>
      <span className={styles.label}>{heading}</span>
      <span className={styles.hint}>
        {headingInfoItems.map((infoText) => (
          <span key={Math.random()}>{infoText}</span>
        ))}
      </span>
    </div>
    <div className={styles.assessmentAndAmount}>
      <div className={styles.initialAssessement}>
        <span className={styles.label}>{valueHeading}</span>
        <span className={classNames(styles.hint, styles.uppercase)}>
          {formatMessage(messages.applyToConfirm)}
        </span>
      </div>
      <div className={styles.maxBorrowAmount}>
        <span className={styles.label}>{formatCurrency(intl)(value)}</span>
        <span className={classNames(styles.hint, styles.uppercase)}>
          {valueSubtitle}
        </span>
      </div>
    </div>
  </div>
);

InfoSection.propTypes = {
  intl: intlShape.isRequired,
  heading: PropTypes.string.isRequired,
  headingInfoItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.number.isRequired,
  valueHeading: PropTypes.string.isRequired,
  valueSubtitle: PropTypes.string.isRequired,
};
InfoSection.displayName = 'InfoSection';

export default InfoSection;
