import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import renderHTML from 'react-render-html';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UIActions from 'actions/UIActions';

import { generateUniqueID } from 'lib/utils/stringUtils';
import { getSectionFromUrl } from 'lib/utils/browserUtils';
import visibilityHOC from 'hocs/visibility';
import { VALIDATION_EVENT } from 'constants/GTMEvents';

import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Tooltip from 'components/Tooltip/Tooltip';

import QuestionHint from './QuestionHint';

import styles from './Question.css';

const Question = (props) => {
  const {
    id,
    tooltip,
    className,
    hint,
    inactive,
    direction,
    forceTopStyle,
    hideTopLine,
    theme,
    showLabel,
    subtitle,
    label,
    labelIcon,
    forceFocus,
    setCurrentQuestion,
    labelJsx,
    setCurrentQuestionToNextQuestion,
    showError,
    error,
    children,
    trackEvent,
    subtitleHeader,
    notes,
    footNote,
  } = props;

  useEffect(() => {
    if (showError && !!error) {
      const section = getSectionFromUrl();
      trackEvent({
        event: VALIDATION_EVENT,
        section,
        field: label,
        validation_message: error,
      });
    }
  }, [showError, error]);

  const getRootStyle = () => {
    const formatToUse = React.Children.only(children).type.displayName;
    return classNames(
      Question.detectDirectionAndFormat(formatToUse, direction),
      styles[className],
      styles.root,
      {
        [styles.hasHint]: hint,
        [styles.inactive]: inactive,
        [styles.forceTop]: forceTopStyle,
        [styles.hideTopLine]: hideTopLine,
        [styles.noLabel]: !showLabel,
        [styles[theme]]: !!styles[theme],
      },
    );
  };

  const renderTooltip = () => {
    return (
      <Tooltip
        id={`${id}_Tooltip`}
        tooltip={tooltip}
        dataPlace='right'
        dataOffset='right'
      />
    );
  };

  const renderLabelIcon = () => (
    <span className={_.partial(classNames, styles.labelIcon)(labelIcon)} />
  );

  const renderSubtitle = () => {
    return (
      <div id={`${id}_Subtitle`} className={styles.subtitle}>
        {subtitle}
      </div>
    );
  };

  const renderSubtitleHeader = () => {
    return (
      <div id={`${id}_SubtitleHeader`} className={styles.subtitleHeader}>
        {subtitleHeader}
      </div>
    );
  };

  const renderHint = () => {
    if (hint) {
      return <QuestionHint id={id} hint={hint} />;
    }

    return null;
  };

  const renderFootNote = () => {
    if (footNote) {
      return <QuestionHint id={id} hint={footNote} fullWidth />;
    }

    return null;
  };

  const renderNotes = () => {
    return (
      <div id={`${id}_Notes`} className={styles.notes}>
        {notes}
      </div>
    );
  };

  const renderLabel = (labelFor) => {
    return (
      <div className={styles.labelWrapper}>
        <label
          className={classNames(styles.label, {
            [styles.withIcon]: !!labelIcon,
          })}
          htmlFor={labelFor}
        >
          {labelIcon && renderLabelIcon()}
          {label && renderHTML(label)}
          {labelJsx && labelJsx()}
        </label>
        {subtitleHeader && renderSubtitleHeader()}
        {subtitle && renderSubtitle()}
        {notes && renderNotes()}
        {tooltip && renderTooltip()}
        {direction === 'column' && renderHint()}
      </div>
    );
  };

  const renderChildren = (childId) => {
    const defaultChildProps = {
      id: childId,
      direction,
      forceFocus,
      onFocus: setCurrentQuestion,
      onClickDelayed: setCurrentQuestionToNextQuestion /* for RadioButtonList */,
      onChange: setCurrentQuestionToNextQuestion /* for Selection */,
      onSuggestionSelected: setCurrentQuestionToNextQuestion /* for AutocompleteInput */,
    };
    const child = React.Children.only(children);
    const mergedProps = _.merge(defaultChildProps, child.props);
    return React.cloneElement(child, mergedProps);
  };

  const renderError = () => {
    return error && showError ? (
      <ErrorMessage className={styles.error} message={error} />
    ) : null;
  };

  const questionId = generateUniqueID('Question', id);
  const childId = generateUniqueID('Answer', id);
  const rootStyle = getRootStyle();

  return (
    !!id && (
      <section id={questionId} className={rootStyle}>
        {showLabel && renderLabel(childId)}
        <div className={styles.answer}>
          {renderChildren(childId)}
          {direction !== 'column' && renderHint()}
        </div>
        {renderFootNote()}
        {renderError()}
      </section>
    )
  );
};

Question.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  error: PropTypes.string,
  direction: PropTypes.string,
  showError: PropTypes.bool,
  forceFocus: PropTypes.bool,
  inactive: PropTypes.bool,
  showLabel: PropTypes.bool,
  labelIcon: PropTypes.string,
  labelJsx: PropTypes.func,
  children: PropTypes.node,
  setCurrentQuestion: PropTypes.func,
  setCurrentQuestionToNextQuestion: PropTypes.func,
  forceTopStyle: PropTypes.bool,
  hideTopLine: PropTypes.bool,
  theme: PropTypes.string,
  subtitle: PropTypes.string,
  subtitleHeader: PropTypes.string,
  notes: PropTypes.string,
  footNote: PropTypes.string,
  trackEvent: PropTypes.func,
};

Question.defaultProps = {
  inactive: false,
  showLabel: true,
  options: {},
  forceTopStyle: false,
  hideTopLine: false,
};

Question.animationProps = {
  className: styles.row,
  component: 'div',
  transitionName: 'fadeInAndOpen',
  transitionEnterTimeout: 320,
  transitionLeaveTimeout: 320,
};

Question.detectDirectionAndFormat = (format, direction) => {
  return `Question__${format}${direction ? `--${direction}` : ''}`;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackEvent: UIActions.trackEvent,
    },
    dispatch,
  );

export default visibilityHOC(
  connect(null, mapDispatchToProps)(Question),
  Question.animationProps,
);
