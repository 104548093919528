import update from 'immutability-helper';
import moment from 'moment';
import {
  LOAN_PURPOSE_RESIDENTIAL,
  LOAN_PURPOSE_INVESTMENT,
  LOAN_PURPOSE_REFINANCE,
} from './loanPurposes';
import {
  BUYER_SCENARIO_FHB,
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_INVEST,
  BUYER_SCENARIO_COMPARE,
  INVESTMENT_SCENARIO_DEFAULT,
  REFINANCE_SCENARIO_DEFAULT,
} from './loanScenarios';
import { ORDER_REPAYMENTS_LOWEST } from './productOrderOptions';

import { ALL_PRODUCT_TYPES } from './productTypes';
import { ALL_FINANCIALS } from './financials';
import {
  INTEREST_ONLY_TERM_ALL_VALUE,
  REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE,
} from './repaymentTypes';
import { FIXED_RATE_TERM_ALL_VALUE } from './interestType';

export const DEFAULT_LOAN_AMOUNT = 350000;
export const DEFAULT_LOAN_YEAR = 30;
export const DEFAULT_LVR_VALUE = 0.8;
export const DEFAULT_EXISTING_PROPERTY_ID = 'existing-property';
export const PROSPECTIVE_PROPERTY_ID = 'prospective';
export const DEFAULT_CURRENT_INTEREST_RATE = 5;
export const MOVING_COST = 1500;
export const RENOVATION_COST = 0;
export const OTHER_COST = 0;
export const CONVEYANCER_COST = 1000;
export const COMMISSION_PERCENTAGE = 0.025;
export const MANAGEMENT_FEE_PERCENTAGE = 0.05;
export const VACANCY_FEE_PERCENTAGE = 0.02;
export const ANNUAL_REPAIR_FEE = 1200;
export const DEFAULT_REPAYMENT_FREQUENCY = 12;

export const INITIAL_SCENARIO_STATE = {
  loanType: 'all',
  errors: {},
  refinanceReasons: {},
  loanFeatures: {},
  properties: { [PROSPECTIVE_PROPERTY_ID]: {} },
  repaymentType: REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE,
  mobile: {},
  oktaData: {},
  returningUser: false,
};

export const DEFAULT_NEW_PROPERTY = {
  id: DEFAULT_EXISTING_PROPERTY_ID,
  errors: {},
};

export const DEFAULT_PROSPECTIVE_PROPERTY = {
  id: PROSPECTIVE_PROPERTY_ID,
  errors: {},
  otherCosts: [],
  FHOGEligibility: undefined,
  isPreapproved: true, // TODO: set to false if they indicate the exact address, currently we only ask the suburb
};

export const DEFAULT_RESIDENTIAL_SCENARIO_STATE = update(
  INITIAL_SCENARIO_STATE,
  {
    $merge: {
      loanPurpose: LOAN_PURPOSE_RESIDENTIAL,
      name: undefined,
    },
    properties: {
      [PROSPECTIVE_PROPERTY_ID]: {
        $set: update(DEFAULT_PROSPECTIVE_PROPERTY, {
          ownerOccupied: { $set: true },
        }),
      },
    },
  },
);

export const DEFAULT_BUYER_SCENARIO_FHB_STATE = update(
  DEFAULT_RESIDENTIAL_SCENARIO_STATE,
  { name: { $set: BUYER_SCENARIO_FHB } },
);

export const DEFAULT_BUYER_SCENARIO_MOVE_STATE = update(
  DEFAULT_RESIDENTIAL_SCENARIO_STATE,
  {
    name: { $set: BUYER_SCENARIO_MOVE },
    properties: {
      [DEFAULT_EXISTING_PROPERTY_ID]: {
        $set: update(DEFAULT_NEW_PROPERTY, {
          intendsToSell: { $set: true },
          ownerOccupied: { $set: true },
        }),
      },
    },
  },
);

export const DEFAULT_BUYER_SCENARIO_INVEST_STATE = update(
  DEFAULT_RESIDENTIAL_SCENARIO_STATE,
  {
    name: { $set: BUYER_SCENARIO_INVEST },
    properties: {
      [DEFAULT_EXISTING_PROPERTY_ID]: {
        $set: update(DEFAULT_NEW_PROPERTY, {
          intendsToSell: { $set: false },
          ownerOccupied: { $set: true },
        }),
      },
    },
  },
);

export const DEFAULT_BUYER_SCENARIO_COMPARE_STATE = update(
  DEFAULT_RESIDENTIAL_SCENARIO_STATE,
  { name: { $set: BUYER_SCENARIO_COMPARE } },
);

export const DEFAULT_INVESTMENT_SCENARIO_STATE = update(
  INITIAL_SCENARIO_STATE,
  {
    $merge: {
      loanPurpose: LOAN_PURPOSE_INVESTMENT,
      name: INVESTMENT_SCENARIO_DEFAULT,
    },
    properties: {
      [PROSPECTIVE_PROPERTY_ID]: {
        $set: update(DEFAULT_PROSPECTIVE_PROPERTY, {
          ownerOccupied: { $set: false },
        }),
      },
    },
  },
);

export const DEFAULT_REFINANCE_SCENARIO_STATE = update(INITIAL_SCENARIO_STATE, {
  $merge: {
    loanPurpose: LOAN_PURPOSE_REFINANCE,
    name: REFINANCE_SCENARIO_DEFAULT,
  },
  properties: {
    [DEFAULT_EXISTING_PROPERTY_ID]: {
      $set: update(DEFAULT_NEW_PROPERTY, {
        $merge: {
          ongoingFee: 250,
          ongoingFeeFrequency: 'annual',
          exitFee: 250,
        },
      }),
    },
  },
});

export const INITIAL_SCENARIO_STATE_WITH_PROPERTY = update(
  INITIAL_SCENARIO_STATE,
  {
    properties: {
      [DEFAULT_EXISTING_PROPERTY_ID]: {
        $set: DEFAULT_NEW_PROPERTY,
      },
    },
  },
);

export const DEFAULT_STRUCTURE_INITIAL_STATE = {
  errors: {},
  repaymentFrequency: DEFAULT_REPAYMENT_FREQUENCY,
  interestType: {},
  repaymentType: REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE,
  features: {},
  selectedLenderIds: [],
  lvr: 80,
  orderField: ORDER_REPAYMENTS_LOWEST,
  loanTerm: DEFAULT_LOAN_YEAR,
  productType: ALL_PRODUCT_TYPES,
  loanAmount: DEFAULT_LOAN_AMOUNT,
  initialPeriod: FIXED_RATE_TERM_ALL_VALUE,
  interestOnlyTerm: INTEREST_ONLY_TERM_ALL_VALUE,
  financials: ALL_FINANCIALS,
  page: 1,
  clientIds: [],
  name: '',
  isLocked: false,
};

export const DEFAULT_PRODUCT_INITIAL_STATE = {
  productDetail: {},
  featuredProducts: {},
  interestProducts: {},
};

export const DEFAULT_MIN_DATE = moment().subtract('120', 'years');
export const DEFAULT_DEPENDENT_MIN_DATE = moment().subtract('17', 'years');
export const DEFAULT_MAX_DATE = moment().add('120', 'years');
