import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/goal/goalLoanAppInfoActions';

export const initialState = {
  questions: {},
  answers: {},
};

const goalLoanAppInfoReducer = handleActions(
  {
    [actionTypes.SET_LOAN_APP_INFO_QUESTIONS](state, action) {
      return { ...state, questions: action.payload };
    },
    [actionTypes.SET_LOAN_APP_INFO_ANSWERS](state, action) {
      return { ...state, answers: action.payload };
    },
  },
  initialState,
);

export default goalLoanAppInfoReducer;
