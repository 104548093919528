import { createActions } from 'redux-actions';

export const FETCH_EXPENSE_CATEGORY_TYPES = 'fetchExpenseCategoryTypes';
export const SET_EXPENSE_CATEGORY_TYPES = 'setExpenseCategoryTypes';

export default createActions(
  {},
  FETCH_EXPENSE_CATEGORY_TYPES,
  SET_EXPENSE_CATEGORY_TYPES,
);
