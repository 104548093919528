let config;
try {
  config = window && window.LM_CONFIG;
} catch (error) {
  config = process.env;
}

export const LENDER_API_PATH = `${config.SERVER_DOMAIN}/lenders`;
export const USERS_API_PATH = `${config.SERVER_DOMAIN}/users`;
export const PRODUCTS_API_PATH = `${config.SERVER_DOMAIN}/products`;
export const SERVICEABILITY_API_PATH = `${config.SERVER_DOMAIN}/utils/serviceability`;
export const BANK_CONNECT_API_PATH = `${config.SERVER_DOMAIN}/bank-connect`;

// MyCRM integration
export const COMPANIES_API_PATH = `${config.SERVER_DOMAIN}/companies`;
export const CLIENTS_API_PATH = `${config.SERVER_DOMAIN}/clients`;
export const CONTACTS_API_PATH = `${config.SERVER_DOMAIN}/contacts`;
export const FUNDINGS_API_PATH = `${config.SERVER_DOMAIN}/fundings`;
export const EXPENSE_API_PATH = `${config.SERVER_DOMAIN}/expenses`;
export const ASSETS_API_PATH = `${config.SERVER_DOMAIN}/assets`;
export const INCOMES_API_PATH = `${config.SERVER_DOMAIN}/incomes`;
export const LIABILITIES_API_PATH = `${config.SERVER_DOMAIN}/liabilities`;
export const LOAN_APPLICATION_API_PATH = `${config.SERVER_DOMAIN}/loan-applications`;
export const PROPERTY_API_PATH = `${config.SERVER_DOMAIN}/properties`;
export const ADDRESS_API_PATH = `${config.SERVER_DOMAIN}/addresses`;
export const DOCUMENT_API_PATH = `${config.SERVER_DOMAIN}/documents`;
export const PAGE_API_PATH = `${config.SERVER_DOMAIN}/documents/pages`;
export const EMPLOYMENT_API_PATH = `${config.SERVER_DOMAIN}/employments`;
export const ADVISORS_API_PATH = `${config.SERVER_DOMAIN}/advisors`;
export const ADVISOR_ORGS_API_PATH = `${config.SERVER_DOMAIN}/advisor-orgs`;
export const UTILS_API_PATH = `${config.SERVER_DOMAIN}/utils`;
export const EXPENSE_CATEGORY_TYPES_API_PATH = `${config.SERVER_DOMAIN}/expense-types`;
export const GOAL_SETTER_API_PATH = {
  LOAN_APPLICATIONS: `${config.SERVER_DOMAIN}/goal-loan-apps`,
  CLIENTS: `${config.SERVER_DOMAIN}/goal-clients`,
  CONTACTS: `${config.SERVER_DOMAIN}/goal-contacts`,
  FUNDINGS: `${config.SERVER_DOMAIN}/goal-fundings`,
  PREFERENCES: `${config.SERVER_DOMAIN}/goal-preferences`,
  PROPERTIES: `${config.SERVER_DOMAIN}/goal-properties`,
  LOAN_APP_INFO: `${config.SERVER_DOMAIN}/goal-loan-app-info`,
};
export const AGGREGATOR_API_PATH = `${config.SERVER_DOMAIN}/aggregator`;
export const ACTION_TOKEN_API_PATH = `${config.SERVER_DOMAIN}/action-token`;
