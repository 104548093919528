import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';

import SectionFooter from 'components/SectionFooter/SectionFooter';
import Accordion from 'components/Accordion/Accordion';

import { nameValueShape } from 'types/customPropTypes';
import { convertToClassName } from 'lib/utils/browserUtils';

import styles from './Section.css';

class Section extends Component {
  static propTypes = {
    isOpenByDefault: PropTypes.bool,
    isAccordion: PropTypes.bool,
    header: nameValueShape.isRequired,
    icon: PropTypes.node,
    closedIcon: PropTypes.node,
    nameInfoLabel: PropTypes.string,
    valueInfoLabel: PropTypes.string,
    valueClassName: PropTypes.string,
    customValueClassName: PropTypes.string,
    colour: PropTypes.string,
    children: PropTypes.node,
    noHeading: PropTypes.bool,
    theme: PropTypes.string,
    onClickHeader: PropTypes.func,
    forceCollapsedStyle: PropTypes.bool,
    svg: PropTypes.string,
    count: PropTypes.number,
  };

  static defaultProps = {
    isOpenByDefault: true,
    noHeading: false,
    isAccordion: true,
    forceCollapsedStyle: false,
  };

  static renderItems(children, customStyle = null) {
    const itemStyle = classNames(styles.items, customStyle);

    return <ul className={itemStyle}>{children}</ul>;
  }

  constructor(props) {
    super();
    this.state = {
      isOpen: !!props.isOpenByDefault,
    };
  }

  openOrClose = (toggle) => () => {
    const { onClickHeader } = this.props;

    if (onClickHeader) {
      onClickHeader();
    } else {
      toggle();
    }
  };

  renderAccordionContent = ({ contentRef, toggle, isCollapsed }) => {
    const {
      header,
      children,
      icon: openIcon,
      closedIcon,
      valueClassName,
      customValueClassName,
      colour,
      nameInfoLabel,
      valueInfoLabel,
      noHeading,
      theme,
      forceCollapsedStyle,
      svg,
      count,
    } = this.props;

    const rootClass = classNames(styles.root, styles.accordionStyle);
    const valueClass = classNames(
      styles.valueWrapper,
      styles[valueClassName],
      customValueClassName,
    );
    const headerClass = classNames(styles.headerWrapper, {
      [styles.closeHeaderWrapper]: isCollapsed || forceCollapsedStyle,
      hidden: noHeading,
      [styles[theme]]: true,
    });
    const icon = closedIcon && isCollapsed ? closedIcon : openIcon;

    return (
      <div
        id={header.id || (header.name && convertToClassName(header.name))}
        className={rootClass}
        key={header.name}
      >
        <div
          className={headerClass}
          onClick={this.openOrClose(toggle)}
          style={{ color: colour }}
        >
          {icon && !svg && <span className={styles.icon}>{icon}</span>}
          {svg && <SVGInline className={styles.svg} svg={svg} />}
          {!svg && (
            <span className={styles.leftWrapper}>
              <span>{header.name}</span>
              {nameInfoLabel && <span>{nameInfoLabel}</span>}
            </span>
          )}
          <div className={valueClass}>
            <span>{header.value}</span>&nbsp;
            {valueInfoLabel && <span>{valueInfoLabel}</span>}
            {svg && (
              <i
                className={classNames(
                  'mi-arrow-with-circle-right',
                  styles.icon,
                )}
              />
            )}
          </div>
        </div>
        {count ? <div className={styles.count}>{count}</div> : null}
        <div ref={contentRef}>{children && Section.renderItems(children)}</div>
      </div>
    );
  };

  renderDefaultContent() {
    const {
      header,
      children,
      colour,
      valueInfoLabel,
      noHeading,
      theme,
    } = this.props;

    const rootClass = classNames(styles.root, styles.defaultStyle);
    const headerClass = classNames(styles.header, {
      [styles[theme]]: true,
      hidden: noHeading,
    });

    return (
      <div className={rootClass} key={header.name}>
        <div className={headerClass} style={{ color: colour }}>
          <span>{header.name}</span>
        </div>
        {children && Section.renderItems(children, styles.noMarginBottom)}
        <SectionFooter
          name={header.name}
          value={header.value}
          valueInfoLabel={valueInfoLabel}
        />
      </div>
    );
  }

  render() {
    const { isAccordion, isOpenByDefault } = this.props;
    let content;

    if (isAccordion) {
      content = (
        <Accordion expandByDefault={isOpenByDefault}>
          {this.renderAccordionContent}
        </Accordion>
      );
    } else {
      content = this.renderDefaultContent();
    }
    return content;
  }
}

export default Section;
