import { identity } from 'lodash';
import PropTypes from 'prop-types';

import { formatMobile, unformatPhoneNumber } from 'lib/utils/numberUtils';
import { formatNumber, unformatCurrency } from 'shared/lib/numbrero';

export const formatterPropType = PropTypes.shape({
  format: PropTypes.func.isRequired,
  unformat: PropTypes.func.isRequired,
});

export const noFormat = {
  format: identity,
  unformat: identity,
};

export const mobileFormatter = {
  format: formatMobile,
  unformat: unformatPhoneNumber,
};

export const currencyFormatter = {
  format: formatNumber,
  unformat: unformatCurrency,
};
