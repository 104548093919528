import PropTypes from 'prop-types';

export const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);
export const stringOrFunction = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
]);
export const primitiveType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
  PropTypes.bool,
]);

export const nameValueShape = PropTypes.shape({
  name: stringOrNumber,
  value: stringOrNumber,
});

export const arrayOfStringsObjectsNodes = PropTypes.arrayOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
);

export const accordionPropTypes = {
  isLocked: PropTypes.bool.isRequired,
  isShared: PropTypes.bool.isRequired,
};
