import { delay } from 'redux-saga';
import {
  takeLatest,
  takeEvery,
  all,
  put,
  select,
  call,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import {
  REQUEST_BANK_LIST,
  REQUEST_BANK_LOGIN,
  CHECK_BANK_LOGIN_COMPLETE,
} from 'actions/mogoConnectActionTypes';
import mogoConnectActions from 'actions/mogoConnectActions';
import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import bankConnectCollectionActions from 'actions/bankConnectCollectionActions';

import { fetchDocuments } from 'sagas/documentSagas';

import * as mogoConnectSelectors from 'selectors/mogoConnectSelectors';
import * as bankConnectCollectionSelectors from 'selectors/bankConnectCollectionSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import { ALL_DONE, ERROR } from 'shared/constants/bankConnect';
import { getErrorStatus } from 'lib/errorHelper';
import { logEvent, EVENTS } from 'lib/amplitude';

import {
  getMogoBankList,
  mogoBankLogin,
  getTransactionStatus,
  postCollectionByAccessId,
  pollBankConnectCollection,
} from 'services/bankConnectApi';

import {
  getContactBankConnectCollections,
  putContactBankCollection,
  getContactBankConnectCollectionRecords,
} from 'services/contactApi';

import { monitorSpinnerRequest } from 'lib/sagaHelpers.js';
import { featureFlags } from 'lib/rollout';
import * as _ from 'sagas/bankConnectSagas';

export const STATUS_CHECK_TIMEOUT_MS = 2000;
export const STATUS_CHECK_MAX_ATTEMPTS = 100;

export class MogoFetchError extends Error {
  constructor(...args) {
    super(...args);
    this.status = 500;
    this.name = 'MogoFetchError';
  }
}

export const getLivingExpenseVersion = () => {
  return featureFlags.livingExpenseV2.isEnabled() ? 2 : null;
};

export function* fetchBankList() {
  const bankListRaw = yield select(mogoConnectSelectors.bankListRaw);

  // eslint-disable-next-line unicorn/explicit-length-check
  if (!bankListRaw.length) {
    try {
      const bankList = yield call(getMogoBankList, _.getLivingExpenseVersion());
      yield put(mogoConnectActions.setBankList(bankList));
    } catch (error) {
      logEvent(EVENTS.CLOSE_CONNECTION_WITH_BANK);
      yield put(UIActions.setPageError({ status: 500, error }));
    }
  }
}

export function* fetchBankLogin({ payload }) {
  const { id: bankId } = payload;
  try {
    const loginDetails = yield call(
      mogoBankLogin,
      bankId,
      _.getLivingExpenseVersion(),
    );
    yield put(mogoConnectActions.requestBankLoginSuccess(loginDetails));
  } catch (error) {
    logEvent(EVENTS.CLOSE_CONNECTION_WITH_BANK);
    yield put(mogoConnectActions.requestBankLoginError(error));
  }
}

export function* fetchBankConnectCollections(contactId) {
  const collections = yield call(getContactBankConnectCollections, contactId);
  yield put(bankConnectCollectionActions.setCollections(collections));
  return collections;
}

export function* fetchBankConnectRecords(contactId, collections) {
  const collectionIds = collections.map((c) => c.id);
  const { accounts, recurringExpenseItems } = yield call(
    getContactBankConnectCollectionRecords,
    contactId,
    collectionIds,
  );
  yield put(bankConnectCollectionActions.setAccounts(accounts));
  yield put(
    bankConnectCollectionActions.setRecurringExpenseItems(
      recurringExpenseItems,
    ),
  );
}

export function* checkCollectionComplete(requestId) {
  try {
    let numChecks = 0;
    while (numChecks < STATUS_CHECK_MAX_ATTEMPTS) {
      yield call(delay, STATUS_CHECK_TIMEOUT_MS);
      const statusData = yield call(
        pollBankConnectCollection,
        requestId,
        _.getLivingExpenseVersion(),
      );
      if (statusData.status === ERROR) {
        throw new MogoFetchError({ message: 'unexpected error happened' });
      }
      if (statusData.status === ALL_DONE) {
        return statusData.collectionId;
      }
      numChecks++;
    }
    throw new MogoFetchError({
      message: `Mogo collection not ready after ${STATUS_CHECK_MAX_ATTEMPTS} attempts.`,
    });
  } catch (error) {
    logEvent(EVENTS.CLOSE_CONNECTION_WITH_BANK);
    Sentry.captureException(error, { extra: { requestId } });
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
    yield put(mogoConnectActions.clearBankConnectSession());
  }
}

export function* postCollection(fullReport = true) {
  const accessId = yield select(mogoConnectSelectors.checkedAccessId);
  const { contactId, advisorId } = yield select(
    clientSelectors.primaryApplicant,
  );
  const { id } = yield select(applicationSelectors.workingApplication);

  try {
    const requestId = yield call(
      postCollectionByAccessId,
      { accessId, contactId, fullReport, advisorId, loanAppId: id },
      _.getLivingExpenseVersion(),
    );
    const newCollectionId = yield call(checkCollectionComplete, requestId);
    if (fullReport) {
      /* non-full-report collections are inactive by default so there is no need to fetch them  */
      let collections = yield call(fetchBankConnectCollections, contactId);
      const duplicateCollectionSelector = yield select(
        bankConnectCollectionSelectors.duplicateCollection,
      );
      const duplicateCollection = duplicateCollectionSelector(newCollectionId);
      if (duplicateCollection) {
        yield call(putContactBankCollection, contactId, {
          ...duplicateCollection,
          isActive: false,
        });
        yield put(
          bankConnectCollectionActions.removeCollection(duplicateCollection.id),
        );
        collections = yield select(bankConnectCollectionSelectors.collections);
      }
      yield call(fetchBankConnectRecords, contactId, collections);
    }
    yield call(fetchDocuments);
    yield put(scenarioActions.trackBankDataDownload({ status: 'success' }));
  } catch (error) {
    logEvent(EVENTS.CLOSE_CONNECTION_WITH_BANK);
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
    yield put(scenarioActions.trackBankDataDownload({ status: 'failed' }));
  }
}

export function* checkTransactionStatus({ payload }) {
  try {
    const { accessId, fullReport } = payload;
    let isComplete = false;
    let numChecks = 0;

    /* TODO: open API for mogo to notify us when completed and listen to it */
    while (!isComplete && numChecks < STATUS_CHECK_MAX_ATTEMPTS) {
      yield call(delay, STATUS_CHECK_TIMEOUT_MS);

      const statusData = yield call(
        getTransactionStatus,
        accessId,
        _.getLivingExpenseVersion(),
      );
      if (statusData.error) {
        throw new MogoFetchError({ message: statusData.errorMessage });
      }

      isComplete = statusData.categorised;
      numChecks++;
    }

    if (isComplete) {
      yield put(mogoConnectActions.checkBankLoginCompleteSuccess(accessId));
      yield call(postCollection, fullReport);
    } else {
      throw new MogoFetchError({
        message: `Mogo report not ready after ${STATUS_CHECK_MAX_ATTEMPTS} attempts.`,
      });
    }
  } catch (error) {
    logEvent(EVENTS.CLOSE_CONNECTION_WITH_BANK);
    Sentry.captureException(error, { extra: { accessId: payload.accessId } });
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
    yield put(mogoConnectActions.clearBankConnectSession());
  }
}

export default function* bankConnectSagas() {
  yield all([
    monitorSpinnerRequest(takeLatest, REQUEST_BANK_LIST, fetchBankList),
    takeLatest(REQUEST_BANK_LOGIN, fetchBankLogin),
    takeEvery(CHECK_BANK_LOGIN_COMPLETE, checkTransactionStatus),
  ]);
}
