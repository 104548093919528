export const SET_ASSET_TYPE = 'setAssetType';
export const SET_ASSET_CATEGORY = 'setAssetCategory';
export const SET_ASSET_CLIENT_IDS = 'setAssetClientIds';
export const SET_ASSET_MAKE = 'setAssetMake';
export const SET_ASSET_MODEL = 'setAssetModel';
export const SET_ASSET_YEAR = 'setAssetYear';
export const SET_ASSET_BANK = 'setAssetBank';
export const SET_ASSET_VALUE = 'setAssetValue';
export const SET_ASSET_ADDRESS = 'setAssetAddress';
export const SET_ASSET_RENT = 'setAssetRent';
export const SET_ASSET_DESCRIPTION = 'setAssetDescription';

export const SET_ASSET_ERROR = 'setAssetError';
