/* eslint-disable sonarjs/no-duplicate-string */
import { formatCurrency, formatPercentage } from 'lib/intlFormatters';

import {
  interestOnlyRepayment,
  propertyValue,
  firstHomeOwnersGrant,
  stampDuty,
  lmi,
  conveyancerCost,
  movingCost,
  renovationCost,
  otherCost,
  existingPropertyEquity,
  mortgageAmount,
  deposit,
  lvr,
  existingPropertyRealEstateFee,
  propertyWeeklyRentalIncome,
  propertyManagementFee,
  propertyVacancyFee,
  propertyWeeklyRepairFee,
} from 'selectors/scenarioSelectors';

import {
  proceedsOfSaleDescription,
  FHOGDescription,
  stampDutyDescription,
  LMIDescription,
  purchasePriceDescription,
  legalFeeDescription,
  movingAndCleaningDescription,
  renovationDescription,
  LVRDescription,
  cashDepositDescription,
  agentFeeDescription,
  salePriceDescription,
  mortgageDescription,
  rentDescription,
  interestOnlyDescription,
  managementDescription,
  vacancyDescription,
  maintenanceDescription,
} from 'constants/popupDescriptions';

import {
  PropertyValueForm,
  LegalFeesForm,
  MovingCostsForm,
  RenovationCostsForm,
  ContributionsForm,
  LVRInfo,
  ConnectedStampdutyInfo as StampdutyInfo,
  ConnectedLMIInformation as LMIInfo,
  ConnectedProceedsOfSaleInfo as ProceedsOfSaleInfo,
} from 'components/PopupForms/LoanReportForms';
import OtherCostsPopup from 'components/Popups/OtherCostsPopup';
import {
  PURCHASE_PRICE,
  STAMP_DUTY_GOV_FEES,
  LEGAL_FEES,
  MOVING_AND_CLEANING,
  RENOVATIONS,
  LMI_ALLOWANCE,
  FHOG_TITLE,
} from 'constants/reportItemTitles';

import {
  MirroredRealEstateAgentFeesForm as RealEstateAgentFeesForm,
  OutstandingMortgageForm,
} from 'components/PopupForms/SalesReportForms';

import {
  PropertyRentalIncomeForm,
  ManagementFeeForm,
  VacancyFeeForm,
  RepairFeeForm,
  MirroredInterestOnlyRepaymentForm as InterestOnlyRepaymentForm,
} from 'components/PopupForms/CashFlowReportForms';

function matadataWithParamId(url) {
  return {
    url: (id) => `${url}/${id}`,
    path: `${url}/:id`,
  };
}

/*
 * META DATA SHARED BETWEEN REPORTS
 */

export const legalFeesMetadata = {
  name: LEGAL_FEES,
  icon: 'sl-custom-court',
  ...matadataWithParamId('/legal-fees'),
  value: conveyancerCost,
  formatValue: formatCurrency,
  body: LegalFeesForm,
  description: legalFeeDescription,
};

export const movingAndCleaningMetadata = {
  name: MOVING_AND_CLEANING,
  icon: 'sl-custom-truck-1',
  ...matadataWithParamId('/moving-costs'),
  value: movingCost,
  formatValue: formatCurrency,
  body: MovingCostsForm,
  description: movingAndCleaningDescription,
};

export const renovationsMetadata = {
  name: RENOVATIONS,
  icon: 'sl-custom-roller',
  ...matadataWithParamId('/renovations'),
  value: renovationCost,
  formatValue: formatCurrency,
  body: RenovationCostsForm,
  description: renovationDescription,
};

export const proceedsOfSaleMetadata = {
  name: 'Proceeds of sale',
  icon: 'sl-custom-receipt-4',
  url: '/proceeds-of-sale',
  value: (...args) => Math.abs(existingPropertyEquity(...args)),
  formatValue: formatCurrency,
  body: ProceedsOfSaleInfo,
  description: proceedsOfSaleDescription,
};

/*
 * META DATA FOR LOAN REPORT
 */

export const purchasePriceMetadata = {
  name: PURCHASE_PRICE,
  icon: 'sl-custom-dollar-currency-3',
  value: propertyValue,
  ...matadataWithParamId('/purchase-price'),
  formatValue: formatCurrency,
  body: PropertyValueForm,
  description: purchasePriceDescription,
};

export const costOfSaleMetadata = {
  name: 'Cost of sale',
  icon: 'sl-custom-receipt-4',
  value: (...args) => -existingPropertyEquity(...args),
  url: '/cost-of-sale',
  formatValue: formatCurrency,
  body: ProceedsOfSaleInfo,
  description: proceedsOfSaleDescription,
};

export const FHOGMetadata = {
  name: FHOG_TITLE,
  icon: 'sl-custom-receipt-4',
  url: '/first-home-owners-grant',
  value: firstHomeOwnersGrant,
  formatValue: formatCurrency,
  body: FHOGDescription,
};

export const stampDutyMetadata = {
  name: STAMP_DUTY_GOV_FEES,
  icon: 'sl-custom-receipt-4',
  url: '/stampduty',
  value: stampDuty,
  formatValue: formatCurrency,
  body: StampdutyInfo,
  description: stampDutyDescription,
};

export const LMIMetadata = {
  name: LMI_ALLOWANCE,
  icon: 'sl-custom-receipt-4',
  url: '/lmi',
  value: lmi,
  formatValue: formatCurrency,
  body: LMIInfo,
  description: LMIDescription,
};

export const otherCostMetadata = {
  name: 'Other costs',
  icon: 'sl-custom-list-2-1',
  value: otherCost,
  ...matadataWithParamId('/other-costs'),
  component: OtherCostsPopup,
};

export const contributionsMetadata = {
  name: 'Cash deposit',
  icon: 'sl-custom-dollar-currency-3',
  url: '/contributions',
  value: deposit,
  formatValue: formatCurrency,
  body: ContributionsForm,
  description: cashDepositDescription,
};

export const LVRMetadata = {
  name: 'Loan to Value Ratio (LVR)',
  icon: 'sl-custom-pie-chart-1',
  url: '/lvr',
  value: lvr,
  formatValue: formatPercentage,
  body: LVRInfo,
  description: LVRDescription,
  ignore: true,
};

export const loanReportPopups = [
  purchasePriceMetadata,
  costOfSaleMetadata,
  FHOGMetadata,
  stampDutyMetadata,
  LMIMetadata,
  legalFeesMetadata,
  movingAndCleaningMetadata,
  renovationsMetadata,
  otherCostMetadata,
  contributionsMetadata,
  LVRMetadata,
];

export const calculatorWidgetPopups = [
  { ...stampDutyMetadata, theme: 'dark' },
  { ...LMIMetadata, theme: 'dark' },
  { ...legalFeesMetadata, theme: 'dark' },
  { ...movingAndCleaningMetadata, theme: 'dark' },
  { ...renovationsMetadata, theme: 'dark' },
  { ...LVRMetadata, theme: 'dark' },
];

/*
 * META DATA FOR SALES REPORT
 */

export const salesPriceMetadata = {
  name: 'Sale price',
  icon: 'sl-custom-dollar-currency-3',
  ...matadataWithParamId('/sales-price'),
  value: propertyValue,
  formatValue: formatCurrency,
  body: PropertyValueForm,
  description: salePriceDescription,
};

export const outstandingMortgageMetadata = {
  name: 'Outstanding mortgage',
  icon: 'sl-custom-bank-2',
  url: '/outstanding-mortgage',
  value: mortgageAmount,
  formatValue: formatCurrency,
  body: OutstandingMortgageForm,
  description: mortgageDescription,
};
export const realestateFeeMetadata = {
  name: 'Real estate agent fees',
  icon: 'sl-custom-court',
  url: '/real-estate-agent-fees',
  value: existingPropertyRealEstateFee,
  formatValue: formatCurrency,
  body: RealEstateAgentFeesForm,
  description: agentFeeDescription,
};

export const saleReportPopups = [
  salesPriceMetadata,
  outstandingMortgageMetadata,
  realestateFeeMetadata,
  legalFeesMetadata,
  movingAndCleaningMetadata,
  renovationsMetadata,
  proceedsOfSaleMetadata,
];

/*
 * META DATA FOR CASHFLOW REPORT
 */

export const rentCollectedMetadata = {
  name: 'Rent collected',
  icon: 'sl-custom-dollar-currency-3',
  value: propertyWeeklyRentalIncome,
  formatValue: formatCurrency,
  ...matadataWithParamId('/rent-collected'),
  body: PropertyRentalIncomeForm,
  description: rentDescription,
};

export const interestOnlyRepaymentsMetadata = {
  name: 'Interest only repayments*',
  icon: 'sl-custom-dollar-bag',
  ...matadataWithParamId('/repayments'),
  value: interestOnlyRepayment,
  formatValue: formatCurrency,
  body: InterestOnlyRepaymentForm,
  description: interestOnlyDescription,
};
export const managementFeeMetadata = {
  name: 'Property management',
  icon: 'sl-custom-user-setting-2',
  ...matadataWithParamId('/management-fee'),
  formatValue: formatCurrency,
  value: propertyManagementFee,
  body: ManagementFeeForm,
  description: managementDescription,
};
export const vacancyAllowanceMetadata = {
  name: 'Vacancy allowance',
  icon: 'sl-custom-house-2',
  ...matadataWithParamId('/vacancy-allowance'),
  value: propertyVacancyFee,
  formatValue: formatCurrency,
  body: VacancyFeeForm,
  description: vacancyDescription,
};
export const repairFeeMetadata = {
  name: 'Repairs & maintenance',
  icon: 'sl-custom-hammer-1',
  ...matadataWithParamId('/repairs'),
  value: propertyWeeklyRepairFee,
  formatValue: formatCurrency,
  body: RepairFeeForm,
  description: maintenanceDescription,
};

export const cashFlowReportPopups = [
  rentCollectedMetadata,
  interestOnlyRepaymentsMetadata,
  managementFeeMetadata,
  vacancyAllowanceMetadata,
  repairFeeMetadata,
];
