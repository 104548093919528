// TODO: this should be autogenerated from an xls spreadsheet

export const INDEXATION = 1.18; // as of march 2017

// 6523.0 Household Income and Wealth, Australia, 2013–14
export const INCOME_QUINTILE_RANGES = [
  // as of 2013–14(a)
  { min: 0, max: 686 },
  { min: 686, max: 1203 },
  { min: 1203, max: 1931 },
  { min: 1931, max: 2969 },
  { min: 2969, max: undefined },
];

export const CURRENT_HOUSING_COSTS = {
  id: '01',
  name: 'Current housing costs (selected dwelling)',
};
export const WATER_AND_SEWERAGE = {
  id: '0101030101',
  name: 'Water and sewerage rates and charges (selected dwelling)',
};
export const REPAIRS_MATERIALS = {
  id: '010106',
  name: 'Repairs and maintenance (materials only)',
};
export const REPAIRS_CONTRACTORS = {
  id: '010105',
  name: 'Repairs and maintenance payments to contractors',
};
export const BODY_CORPORATE = {
  id: '0101070201',
  name: 'Body corporate payments (selected dwelling)',
};
export const RATES = {
  id: '010103',
  name: 'Rate payments',
};
export const HOUSE_AND_CONTENTS_INSURANCE = {
  id: '010104',
  name: 'House and contents insurance',
};
export const VEHICLE_INSURANCE = {
  id: '1001040102',
  name: 'Compulsory insurance of motor vehicle (other than motor cycle)',
};
export const DOMESTIC_FUEL_AND_POWER = {
  id: '02',
  name: 'Domestic fuel and power',
};
export const ELECTRICITY = {
  id: '0201010101',
  name: 'Electricity (selected dwelling)',
};
export const GAS = {
  id: '0201010201',
  name: 'Mains gas (selected dwelling)',
};
export const FOOD_AND_BEVERAGES = {
  id: '03',
  name: 'Food and non-alcoholic beverages',
};
export const ALCOHOL = {
  id: '04',
  name: 'Alcoholic beverages',
};
export const TOBACCO = {
  id: '05',
  name: 'Tobacco products',
};
export const CLOTHING_AND_FOOTWARE = {
  id: '06',
  name: 'Clothing and footwear',
};
export const HOUSEHOLD_FURNISHING = {
  id: '07',
  name: 'Household furnishings and equipment',
};
export const HOUSEHOLD_SERVICES = {
  id: '08',
  name: 'Household services and operation',
};
export const CHILD_CARE = {
  id: '080105',
  name: 'Child care services',
};
export const MOBILE = {
  id: '0801030102',
  name: 'Mobile telephone account',
};
export const MEDICAL_CARE_AND_HEALTH = {
  id: '09',
  name: 'Medical care and health expenses',
};
export const HEALTH_INSURANCE = {
  id: '0901',
  name: 'Accident and health insurance',
};
export const TRANSPORT = {
  id: '10',
  name: 'Transport',
};
export const VEHICLE_FUEL = {
  id: '100103',
  name: 'Motor vehicle fuel, lubricants and additives',
};
export const VEHICLE_MAINTENANCE = {
  id: '1001060201',
  name: 'Vehicle servicing (including parts and labour)',
};
export const VEHICLE_REGISTRATION = {
  id: '1001040101',
  name: 'Compulsory registration of motor vehicle (other than motor cycle)',
};
export const RECREATION = {
  id: '11',
  name: 'Recreation',
};
export const PETS = {
  id: '1104',
  name: 'Animal expenses',
};
export const HOLIDAYS = {
  id: '1103',
  name: 'Holidays',
};
export const CULTURAL_ACTIVITY = {
  id: '110205',
  name: 'Cultural fees and charges',
};
export const OTHER_RECREATION = {
  id: '110299',
  name:
    'Other recreational and educational services (excluding holiday expenses)',
};
export const SPORTS = {
  id: '110204',
  name: 'Sports fees and charges',
};
export const PAY_TV = {
  id: '1102999901',
  name: 'Pay TV fees',
};
export const INTERNET = {
  id: '1102999902',
  name: 'Internet charges (account)',
};
export const PERSONAL_CARE = {
  id: '12',
  name: 'Personal care',
};
export const MISC_GOODS_AND_SERVICES = {
  id: '13',
  name: 'Miscellaneous goods and services',
};
export const CHILD_MAINTENANCE = {
  id: '1302990101',
  name: 'Alimony or maintenance payments',
};
export const SCHOOL_FEES = {
  id: '130202',
  name: 'Education fees for primary and secondary schools',
};
export const DONATION_TO_CHARITY_NFD = {
  // note: nfd = not further defined
  id: '1302990200',
  name: 'Cash gifts, donations to charity nfd',
};
export const DONATION_TO_FAMILY = {
  id: '1302990201',
  name: 'Cash gifts, donations to family or friends',
};
export const DONATION_TO_CHURCHES = {
  id: '1302990202',
  name: 'Cash gifts, donations to churches, synagogues and related',
};
export const DONATION_TO_CHARITY_NEC = {
  // note: nec = not elsewhere classified
  id: '1302990299',
  name: 'Cash gifts, donations to charity nec',
};
export const SUPER_AND_LIFE_INSURANCE = {
  id: 'Superannuation and life insurance',
  name: 'Superannuation and life insurance',
};

export const HOUSEHOLD_EXPENDITURE_BY_CATEGORY = {
  [CURRENT_HOUSING_COSTS.id]: {
    name: CURRENT_HOUSING_COSTS.name,
    average: '223',
    coupleWithChildren: '304',
    singleParentWithChildren: '220',
    couple: '207',
    single: '153',
    quintile: ['116', '158', '229', '276', '336'],
  },
  [WATER_AND_SEWERAGE.id]: {
    name: WATER_AND_SEWERAGE.name,
    average: '8.19',
    quintile: ['4.89', '6.32', '7.97', '9.53', '12.26'],
  },
  [REPAIRS_MATERIALS.id]: {
    name: REPAIRS_MATERIALS.name,
    average: '10.48',
    quintile: ['4.91', '6.37', '*16.48', '10.97', '*13.67'],
  },
  [REPAIRS_CONTRACTORS.id]: {
    name: REPAIRS_CONTRACTORS.name,
    average: '12.56',
    quintile: ['8.68', '10.25', '10.24', '14.12', '19.51'],
  },
  [BODY_CORPORATE.id]: {
    name: BODY_CORPORATE.name,
    average: '2.4',
    quintile: ['3.43', '1.87', '2.12', '2.26', '2.32'],
  },
  [RATES.id]: {
    name: RATES.name,
    average: '25.8',
    quintile: ['18.71', '20.6', '23.65', '29.12', '36.92'],
  },
  [HOUSE_AND_CONTENTS_INSURANCE.id]: {
    name: HOUSE_AND_CONTENTS_INSURANCE.name,
    average: '11.33',
    quintile: ['7.81', '8.82', '10.49', '12.92', '16.61'],
  },
  [VEHICLE_INSURANCE.id]: {
    name: VEHICLE_INSURANCE.name,
    average: '2.63',
    quintile: ['1.53', '2.05', '2.46', '3.13', '3.99'],
  },
  [DOMESTIC_FUEL_AND_POWER.id]: {
    name: DOMESTIC_FUEL_AND_POWER.name,
    average: '33',
    coupleWithChildren: '44',
    singleParentWithChildren: '32',
    couple: '30',
    single: '20',
    quintile: ['22', '28', '31', '37', '44'],
  },
  [ELECTRICITY.id]: {
    name: ELECTRICITY.name,
    average: '24.23',
    quintile: ['16.83', '21', '23.28', '27.45', '32.6'],
  },
  [GAS.id]: {
    name: GAS.name,
    average: '6.58',
    quintile: ['4.13', '5.48', '6.65', '7.37', '9.25'],
  },
  [FOOD_AND_BEVERAGES.id]: {
    name: FOOD_AND_BEVERAGES.name,
    average: '204',
    coupleWithChildren: '292',
    singleParentWithChildren: '178',
    couple: '195',
    single: '94',
    quintile: ['102', '149', '196', '246', '328'],
  },
  [ALCOHOL.id]: {
    name: ALCOHOL.name,
    average: '32',
    coupleWithChildren: '35',
    singleParentWithChildren: '22',
    couple: '33',
    single: '17',
    quintile: ['11', '20', '31', '39', '61'],
  },
  [TOBACCO.id]: {
    name: TOBACCO.name,
    average: '13',
    coupleWithChildren: '12',
    singleParentWithChildren: '17',
    couple: '10',
    single: '8',
    quintile: ['8', '12', '15', '16', '12'],
  },
  [CLOTHING_AND_FOOTWARE.id]: {
    name: CLOTHING_AND_FOOTWARE.name,
    average: '44',
    coupleWithChildren: '69',
    singleParentWithChildren: '41',
    couple: '37',
    single: '18',
    quintile: ['15', '26', '39', '51', '91'],
  },
  [HOUSEHOLD_FURNISHING.id]: {
    name: HOUSEHOLD_FURNISHING.name,
    average: '59',
    coupleWithChildren: '78',
    singleParentWithChildren: '39',
    couple: '67',
    single: '30',
    quintile: ['27', '40', '49', '68', '109'],
  },
  [HOUSEHOLD_SERVICES.id]: {
    name: HOUSEHOLD_SERVICES.name,
    average: '68',
    coupleWithChildren: '105',
    singleParentWithChildren: '61',
    couple: '60',
    single: '35',
    quintile: ['37', '49', '62', '79', '112'],
  },
  [CHILD_CARE.id]: {
    name: CHILD_CARE.name,
    average: '8.8',
    quintile: ['*1.00', '*3.10', '5.97', '12.2', '21.73'],
  },
  [MOBILE.id]: {
    name: MOBILE.name,
    average: '12.17',
    quintile: ['4.17', '7.42', '13.23', '16.27', '19.8'],
  },
  [MEDICAL_CARE_AND_HEALTH.id]: {
    name: MEDICAL_CARE_AND_HEALTH.name,
    average: '66',
    coupleWithChildren: '85',
    singleParentWithChildren: '42',
    couple: '69',
    single: '35',
    quintile: ['38', '39', '68', '73', '109'],
  },
  [HEALTH_INSURANCE.id]: {
    name: HEALTH_INSURANCE.name,
    average: '26.54',
    quintile: ['11.6', '14.53', '22.39', '32.32', '51.86'],
  },
  [TRANSPORT.id]: {
    name: TRANSPORT.name,
    average: '193',
    coupleWithChildren: '280',
    singleParentWithChildren: '128',
    couple: '186',
    single: '89',
    quintile: ['69', '114', '170', '227', '384'],
  },
  [VEHICLE_FUEL.id]: {
    name: VEHICLE_FUEL.name,
    average: '51.02',
    quintile: ['20.69', '34.71', '51.28', '63.21', '85.3'],
  },
  [VEHICLE_MAINTENANCE.id]: {
    name: VEHICLE_MAINTENANCE.name,
    average: '15.05',
    quintile: ['*10.13', '*16.75', '12.57', '15.23', '20.6'],
  },
  [VEHICLE_REGISTRATION.id]: {
    name: VEHICLE_REGISTRATION.name,
    average: '3.33',
    quintile: ['1.06', '2.08', '3.27', '4.58', '5.64'],
  },
  [RECREATION.id]: {
    name: RECREATION.name,
    average: '161',
    coupleWithChildren: '217',
    singleParentWithChildren: '127',
    couple: '173',
    single: '80',
    quintile: ['67', '101', '147', '203', '290'],
  },
  [PETS.id]: {
    name: PETS.name,
    average: '10.93',
    quintile: ['6.41', '9.17', '9.32', '12.85', '16.93'],
  },
  [HOLIDAYS.id]: {
    name: HOLIDAYS.name,
    average: '52.48',
    quintile: ['22.28', '29.54', '47.32', '59.24', '104.08'],
  },
  [CULTURAL_ACTIVITY.id]: {
    name: CULTURAL_ACTIVITY.name,
    average: '7.04',
    quintile: ['1.86', '3.31', '6.5', '10.17', '13.37'],
  },
  [OTHER_RECREATION.id]: {
    name: OTHER_RECREATION.name,
    average: '19.86',
    quintile: ['8.26', '12.3', '19.64', '25.77', '33.36'],
  },
  [SPORTS.id]: {
    name: SPORTS.name,
    average: '9.55',
    quintile: ['2.58', '5.53', '8.29', '14.58', '16.77'],
  },
  [PAY_TV.id]: {
    name: [PAY_TV.name],
    average: '5.24',
    quintile: ['2.58', '3.81', '5.08', '6.37', '8.35'],
  },
  [INTERNET.id]: {
    name: INTERNET.name,
    average: '7.08',
    quintile: ['3.09', '5.6', '7.64', '8.9', '10.18'],
  },
  [PERSONAL_CARE.id]: {
    name: PERSONAL_CARE.name,
    average: '24',
    coupleWithChildren: '32',
    singleParentWithChildren: '21',
    couple: '25',
    single: '12',
    quintile: ['11', '16', '21', '30', '42'],
  },
  [MISC_GOODS_AND_SERVICES.id]: {
    name: MISC_GOODS_AND_SERVICES.name,
    average: '117',
    coupleWithChildren: '193',
    singleParentWithChildren: '76',
    couple: '104',
    single: '55',
    quintile: ['35', '62', '110', '136', '240'],
  },
  [CHILD_MAINTENANCE.id]: {
    name: CHILD_MAINTENANCE.name,
    average: '1.69',
    quintile: ['**0.03', '*0.82', '*1.49', '*3.04', '*3.07'],
  },
  [SCHOOL_FEES.id]: {
    name: SCHOOL_FEES.name,
    average: '19.4',
    quintile: ['*1.97', '*7.33', '15.61', '22.34', '49.78'],
  },
  [DONATION_TO_CHARITY_NFD.id]: {
    name: DONATION_TO_CHARITY_NFD.name,
    average: '0.86',
    quintile: ['*0.37', '*0.42', '*0.72', '*1.09', '1.71'],
  },
  [DONATION_TO_FAMILY.id]: {
    name: DONATION_TO_FAMILY.name,
    average: '**5.01',
    quintile: ['1.52', '**2.35', '**15.94', '*3.02', '*2.24'],
  },
  [DONATION_TO_CHURCHES.id]: {
    name: DONATION_TO_CHURCHES.name,
    average: '2.97',
    quintile: ['1.23', '*2.82', '1.56', '**4.82', '*4.44'],
  },
  [DONATION_TO_CHARITY_NEC.id]: {
    name: DONATION_TO_CHARITY_NEC.name,
    average: '*3.40',
    quintile: ['0.66', '1.17', '**4.55', '2.27', '*8.35'],
  },
  [SUPER_AND_LIFE_INSURANCE.id]: {
    name: SUPER_AND_LIFE_INSURANCE.name,
    average: '74',
    coupleWithChildren: '86',
    singleParentWithChildren: '18',
    couple: '102',
    single: '54',
    quintile: ['24', '9', '42', '92', '200'],
  },
};

export const DISCLIAMERS = {
  '*': '* This estimate has a relative standard error of 25% to 50%.',
  '**': '† This estimate has a relative standard error greater than 50%',
};

export const FOOTNOTE_MAPPING = {
  '*': '*',
  '**': '†',
};
