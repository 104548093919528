import { put, call, cancel } from 'redux-saga/effects';
import LocalStorageProxy from 'lib/localStorageProxy';
import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import { isNetworkOffline } from 'lib/errorHelper';
import { isNetworkError } from 'axios-retry';
import util from 'util';

export const CANCELED_MESSAGE = 'Operation canceled by the user';

function defaultIdResolver({ payload }) {
  if (payload && typeof payload !== 'object') {
    return payload;
  } else if (payload && payload.id) {
    return payload.id;
  }
  return 'new';
}

function* trackSessionExpiredEvent() {
  const clientId = LocalStorageProxy.currentClientId;
  const brokerFamilyId = LocalStorageProxy.brokerFamilyId;
  yield put(
    scenarioActions.trackOktaSessionExpired({ clientId, brokerFamilyId }),
  );
}

export function* checkIfNetworkError(error, cancelSaga) {
  if (isNetworkOffline(error) || isNetworkError(error)) {
    yield put(UIActions.setPageError({ status: 504, error }));
    if (cancelSaga) {
      yield cancel(cancelSaga);
    }
  }
}

export function monitorAsyncRequest(
  sagaTakeFn,
  type,
  fn,
  idResolver = defaultIdResolver,
) {
  function* asyncRequestWrapper(action) {
    const id = idResolver(action);
    yield put(UIActions.startAsyncRequest({ id, type }));
    try {
      yield fn(action);
      yield put(UIActions.endAsyncRequest({ id, type }));
    } catch (error) {
      if (error.errorCode === 'login_required') {
        yield call(trackSessionExpiredEvent);
      }
      if (error.message === CANCELED_MESSAGE) {
        yield put(UIActions.endAsyncRequest({ id, type }));
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.error(util.inspect(error));
        }
        yield put(UIActions.setAsyncRequestError({ id, type, error: error }));
      }
    }
  }
  return sagaTakeFn(type, asyncRequestWrapper);
}

export function monitorSpinnerRequest(sagaTakeFn, type, fn, err) {
  function* spinnerRequestWrapper(action) {
    const spinnerType = fn.name;
    yield put(UIActions.pushSpinner(spinnerType));

    try {
      yield call(fn, action);
    } catch (error) {
      if (error.errorCode === 'login_required') {
        yield call(trackSessionExpiredEvent);
      }
      if (err) {
        yield call(err, error);
      }
    }
    yield put(UIActions.popSpinner(spinnerType));
  }
  return sagaTakeFn(type, spinnerRequestWrapper);
}

// TODO: refactor monitorAsyncRequest
export function monitorSingleAsyncProgress(sagaFn, type, callFn) {
  function* func(action) {
    yield put({ type: `STARTED_${type}` });
    yield callFn(action);
    yield put({ type: `ENDED_${type}` });
  }
  return sagaFn(type, func);
}
