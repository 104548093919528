import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import documentActions from 'actions/documentActions';
import UIActions from 'actions/UIActions';
import * as documentSelectors from 'selectors/documentSelectors';
import * as UISelectors from 'selectors/UISelectors';
import { getIsLocked } from 'selectors/applicationSelectors';

import { formatSizeFromBytes } from 'lib/intlFormatters';
import { fileDisplayName } from 'shared/lib/utils';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import DocumentForm from 'components/PopupForms/DocumentForm';
import Button from 'components/Button/Button';
import styles from './DocumentPopup.css';
import { logEvent, EVENTS } from 'lib/amplitude';

const FACT_FIND_TYPE = 'Fact find';

const messages = defineMessages({
  confirmRemove: {
    id: 'DocumentPopup.confirmRemove',
    defaultMessage: 'Are you sure that you want to remove this file?',
  },
});

export class DocumentPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    subCategory: PropTypes.string,
    intl: intlShape.isRequired,
    setDocumentType: PropTypes.func.isRequired,
    setDocumentCategory: PropTypes.func.isRequired,
    setDocumentError: PropTypes.func.isRequired,
    actionableError: PropTypes.object,
    documentTypes: PropTypes.arrayOf(PropTypes.object),
  };

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      processing: props.requestIsProcessing,
      confirming: false,
      actionableErrorConfig: {},
    };
    this.itemType = 'document';
  }

  loadWorking = (id) => {
    const { item, load } = this.props;
    if (id !== 'new') {
      load(id);
      if (item.pages.length) {
        const firstPage = item.pages[0];
        if (!firstPage.thumbnailUri) {
          const { requestPageDetails } = this.props;
          requestPageDetails({ id: firstPage.id, documentId: item.id });
        }
      }
    }
  };

  isLoaded = () => {
    const { id, working } = this.props;

    return id === 'new' || (!!working && !!working.pages);
  };

  onClose = () => {
    const { id, clearWorking, working } = this.props;
    working && working.cancel && working.cancel();
    clearWorking(id);
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    const { item, requestPageDetails } = this.props;
    if (!item.id && nextProps.item.id && nextProps.item.pages.length) {
      requestPageDetails({
        id: nextProps.item.pages[0].id,
        documentId: nextProps.item.id,
      });
    }
  }

  confirmRemove = () => {
    const { working } = this.props;
    if (working && working.id === 'upload') {
      this.closePopup();
    } else {
      this.setState({
        confirming: true,
      });
    }
    setTimeout(() => {
      logEvent(EVENTS.REMOVE_DOCUMENTS, { type: FACT_FIND_TYPE });
    }, 1000);
  };

  cancelAction = () => {
    this.setState({
      confirming: false,
      action() {},
    });
  };

  title() {
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} document`;
    }
    return this.getFileName() || 'Loading document';
  }

  value = () => undefined;

  getFileName() {
    const { working } = this.props;
    return working && working.name && fileDisplayName(working.name);
  }

  renderConfirmation() {
    const { intl, working } = this.props;
    return (
      <div>
        <div>{intl.formatMessage(messages.confirmRemove)}</div>
        <div className={styles.confirming}>
          <Button
            className={styles.button}
            onClick={this.cancelAction}
            theme='inputButton'
          >
            No
          </Button>
          <Button
            selected
            className={styles.button}
            onClick={() => this.remove(working.id)}
            theme='inputButton'
          >
            Yes
          </Button>
        </div>
      </div>
    );
  }

  renderThumbnail() {
    const {
      working: { name, pages },
    } = this.props;
    const thumbnailUri = pages.length && pages[0].thumbnailUri;
    return (
      <div className={styles.thumbnail}>
        {thumbnailUri ? (
          <img src={thumbnailUri} alt={name} />
        ) : (
          <i className='sl-custom-document-1' />
        )}
      </div>
    );
  }

  renderUploadProgress() {
    const {
      working: { progress, size },
      intl,
    } = this.props;
    const progressStyle = { width: `${(progress || 0) * 100}%` };

    return (
      <div className={styles.reviewContent}>
        <div className={styles.fileDetail}>
          <div className={styles.name}>{this.getFileName()}</div>
          <div className={styles.size}>{formatSizeFromBytes(intl)(size)}</div>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progressBar}>
            <div className={styles.progress} style={progressStyle} />
          </div>
          <i
            className={`sl-custom-cross-1 ${styles.cancelIcon}`}
            onClick={this.onClose}
          />
        </div>
      </div>
    );
  }

  renderReview() {
    const { working } = this.props;

    return (
      <div key='review' className={styles.reviewContainer}>
        {working.id ? this.renderThumbnail() : this.renderUploadProgress()}
      </div>
    );
  }

  logEditEvent() {
    logEvent(EVENTS.EDIT_DOCUMENTS, { type: FACT_FIND_TYPE });
  }

  logSaveEvent() {
    logEvent(EVENTS.SAVE_DOCUMENTS, { type: FACT_FIND_TYPE });
  }

  renderDocumentDetails() {
    const {
      working,
      workingApplication,
      subType,
      setDocumentType,
      setDocumentCategory,
      setDocumentError,
      clearErrors,
      isLocked,
      documentTypes,
    } = this.props;

    return (
      <div onFocus={this.logEditEvent}>
        {this.renderReview()}
        {working.id && !working.progress && (
          <DocumentForm
            key={`incomePopupForm-${this.state.refreshKey}`}
            working={working}
            workingApplication={workingApplication}
            subType={subType}
            save={this.save}
            remove={this.confirmRemove}
            clearErrors={clearErrors}
            setDocumentType={setDocumentType}
            setDocumentCategory={setDocumentCategory}
            setDocumentError={setDocumentError}
            isLocked={isLocked}
            documentTypes={documentTypes}
          />
        )}
      </div>
    );
  }

  renderForm() {
    const { confirming } = this.state;

    return confirming
      ? this.renderConfirmation()
      : this.renderDocumentDetails();
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const { subCategory } = ownProps.location.query || {};
  const id = parseInt(paramId, 10) || paramId || 'new';
  const errors = UISelectors.requestErrors(state);

  return {
    id,
    subCategory,
    item: documentSelectors.entity(state, ownProps),
    working: documentSelectors.working(state)(id),
    workingApplication: state.application.working,
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors,
    isLocked: getIsLocked(state),
    showStatus: id !== 'new' || !!errors.length,
    actionableError: documentSelectors.actionableError(state)(id),
    documentTypes: documentSelectors.documentTypes(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return bindActionCreators(
    {
      requestPageDetails: documentActions.requestPageDetails,
      load: documentActions.loadDocument,
      update:
        id === 'upload'
          ? documentActions.uploadDocument
          : documentActions.updateDocument,
      delete: documentActions.deleteDocument,
      clearWorking: documentActions.clearWorkingDocument,
      clearErrors: UIActions.clearAsyncRequestErrors,
      setDocumentType: documentActions.setDocumentType(id),
      setDocumentCategory: documentActions.setDocumentCategory(id),
      setDocumentError: documentActions.setDocumentError(id),
      onActionableErrorConfirm: () => documentActions.setReselectFile(true),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DocumentPopup),
);
