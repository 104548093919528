export const CURRENT_BANK = 'CURRENT_BANK';
export const CURRENT_BANK_OTHER = 'CURRENT_BANK_OTHER';
export const OTHER_CURRENT_BANK = 'OTHER_CURRENT_BANK';
export const HAPPY_WITH_CURRENT_BANK = 'HAPPY_WITH_CURRENT_BANK';
export const CURRENT_BANK_UNHAPPINESS_REASON =
  'CURRENT_BANK_UNHAPPINESS_REASON';
export const PREFERRED_LENDERS = 'PREFERRED_LENDERS';
export const PREFERRED_LENDERS_REASON = 'PREFERRED_LENDERS_REASON';
export const PREFERRED_LENDERS_OTHER = 'PREFERRED_LENDERS_OTHER';
export const OTHER_PREFERRED_LENDER = 'OTHER_PREFERRED_LENDER';

export const RATE_PREFERENCES_YES_NO = 'RATE_PREFERENCES_YES_NO';
export const RATE_PREFERENCES = 'RATE_PREFERENCES';
export const FIXED_RATE_DURATION = 'FIXED_RATE_DURATION';
export const RATE_PREFERENCES_IMPORTANT = 'RATE_PREFERENCES_IMPORTANT';
export const RATE_PREFERENCES_IMPORTANT_OTHER =
  'RATE_PREFERENCES_IMPORTANT_OTHER';
export const RATE_PREFERENCES_RISKS = 'RATE_PREFERENCES_RISKS';

export const REPAYMENT_PREFERENCES_YES_NO = 'REPAYMENT_PREFERENCES_YES_NO';
export const REPAYMENT_PREFERENCES = 'REPAYMENT_PREFERENCES';
export const PRINCIPAL_AND_INTEREST = 'PRINCIPAL_AND_INTEREST';
export const PRINCIPAL_AND_INTEREST_REPAYMENT_FREQUENCY =
  'PRINCIPAL_AND_INTEREST_REPAYMENT_FREQUENCY';
export const PRINCIPAL_AND_INTEREST_IMPORTANT =
  'PRINCIPAL_AND_INTEREST_IMPORTANT';
export const PRINCIPAL_AND_INTEREST_IMPORTANT_OTHER =
  'PRINCIPAL_AND_INTEREST_IMPORTANT_OTHER';
export const INTEREST_ONLY = 'INTEREST_ONLY';
export const INTEREST_ONLY_REPAYMENT_FREQUENCY =
  'INTEREST_ONLY_REPAYMENT_FREQUENCY';
export const INTEREST_ONLY_PERIOD = 'INTEREST_ONLY_PERIOD';
export const INTEREST_ONLY_IMPORTANT = 'INTEREST_ONLY_IMPORTANT';
export const INTEREST_ONLY_IMPORTANT_OTHER = 'INTEREST_ONLY_IMPORTANT_OTHER';
export const LINE_OF_CREDIT = 'LINE_OF_CREDIT';
export const LINE_OF_CREDIT_REPAYMENT_FREQUENCY =
  'LINE_OF_CREDIT_REPAYMENT_FREQUENCY';
export const LINE_OF_CREDIT_IMPORTANT = 'LINE_OF_CREDIT_IMPORTANT';
export const LINE_OF_CREDIT_IMPORTANT_OTHER = 'LINE_OF_CREDIT_IMPORTANT_OTHER';
export const INTEREST_IN_ADVANCE = 'INTEREST_IN_ADVANCE';
export const INTEREST_IN_ADVANCE_IMPORTANT = 'INTEREST_IN_ADVANCE_IMPORTANT';
export const INTEREST_IN_ADVANCE_IMPORTANT_OTHER =
  'INTEREST_IN_ADVANCE_IMPORTANT_OTHER';
export const REPAYMENT_PREFERENCES_RISKS = 'REPAYMENT_PREFERENCES_RISKS';

export const FEATURE_PREFERENCES_YES_NO = 'FEATURE_PREFERENCES_YES_NO';
export const FEATURE_PREFERENCES = 'FEATURE_PREFERENCES';
export const FEATURE_PREFERENCES_IMPORTANT = 'FEATURE_PREFERENCES_IMPORTANT';
export const REDRAW_IMPORTANT = 'REDRAW_IMPORTANT';
export const REDRAW_IMPORTANT_OTHER = 'REDRAW_IMPORTANT_OTHER';
export const OFFSET_ACCOUNT_IMPORTANT = 'OFFSET_ACCOUNT_IMPORTANT';
export const OFFSET_ACCOUNT_IMPORTANT_OTHER = 'OFFSET_ACCOUNT_IMPORTANT_OTHER';
export const FEATURE_PREFERENCES_RISKS = 'FEATURE_PREFERENCES_RISKS';

// FUTURE
export const RETIREMENT_AGE = 'RETIREMENT_AGE';
export const RETIREMENT_TERM = 'RETIREMENT_TERM';
export const REPAY_LOAN_METHOD = 'REPAY_LOAN_METHOD';
export const REPAY_LOAN_METHOD_OTHER = 'REPAY_LOAN_METHOD_OTHER';
export const ANY_FINANCIAL_IMPACT = 'ANY_FINANCIAL_IMPACT';

export const ANTICIPATED_CHANGES = 'ANTICIPATED_CHANGES';

// TEAM
export const GENERAL_INSURANCE = 'GENERAL_INSURANCE';
export const NEW_GENERAL_INSURANCE = 'NEW_GENERAL_INSURANCE';
export const MOVING_SERVICES = 'MOVING_SERVICES';
export const LIFESTYLE_INSURANCE = 'LIFESTYLE_INSURANCE';
export const LIFESTYLE_INSURANCE_RISK = 'LIFESTYLE_INSURANCE_RISK';
export const LIFESTYLE_INSURANCE_OBLIGATION = 'LIFESTYLE_INSURANCE_OBLIGATION';
export const LIFESTYLE_INSURANCE_ALI = 'LIFESTYLE_INSURANCE_ALI';
export const LIFESTYLE_INSURANCE_ADVISER = 'LIFESTYLE_INSURANCE_ADVISER';
export const LIFESTYLE_INSURANCE_NOTES = 'LIFESTYLE_INSURANCE_NOTES';
export const HOME_NOW = 'HOME_NOW';
export const HOME_NOW_NOTES = 'HOME_NOW_NOTES';

export const LIFE_AND_HEALTH = 'LIFE_AND_HEALTH';
export const LIFE_AND_HEALTH_DETAILS = 'LIFE_AND_HEALTH_DETAILS';
export const LIFE_AND_HEALTH_REASON = 'LIFE_AND_HEALTH_REASON';
export const GENERAL_INSURANCE_NZ = 'GENERAL_INSURANCE_NZ';
export const GENERAL_INSURANCE_NZ_DETAILS = 'GENERAL_INSURANCE_NZ_DETAILS';
export const GENERAL_INSURANCE_NZ_REASON = 'GENERAL_INSURANCE_NZ_REASON';
export const UK_PENSION_TRANSFERS = 'UK_PENSION_TRANSFERS';
export const ASSET_FINANCE = 'ASSET_FINANCE';
export const CURRENCY_EXCHANGE = 'CURRENCY_EXCHANGE';
export const KIWI_SAVER = 'KIWI_SAVER';

export const NATURE_OF_CHANGE_OTHER = 'NATURE_OF_CHANGE_OTHER';
export const NATURE_OF_CHANGE_START_DATE = 'NATURE_OF_CHANGE_START_DATE';
export const NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION =
  'NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION';
export const NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION_UNIT =
  'NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION_UNIT';
export const NATURE_OF_CHANGE_FINANCIAL_IMPACT =
  'NATURE_OF_CHANGE_FINANCIAL_IMPACT';
export const NATURE_OF_CHANGE_OBJECTIVE_LIST =
  'NATURE_OF_CHANGE_OBJECTIVE_LIST';
export const NATURE_OF_CHANGE_DETAILS = 'NATURE_OF_CHANGE_DETAILS';

export const HAS_PREFERRED_LENDERS = 'HAS_PREFERRED_LENDERS';

export const FIXED_RATE = 'FIXED_RATE';
export const VARIABLE_RATE = 'VARIABLE_RATE';
export const SPLIT_RATE = 'SPLIT_RATE';

export const REDRAW = 'REDRAW';
export const OFFSET_ACCOUNT = 'OFFSET_ACCOUNT';

export const POSSIBLE_ADVERSE_CHANGES = 'POSSIBLE_ADVERSE_CHANGES';
export const OBJECTIVES = 'OBJECTIVES';
export const INSURANCE = 'INSURANCE';
export const INSURANCE_AND_REFERRALS = 'INSURANCE_AND_REFERRALS';
export const FIXED_AND_FLOATING_COMBO = 'FIXED_AND_FLOATING_COMBO';
export const REVOLVING_CREDIT_FACILITY = 'REVOLVING_CREDIT_FACILITY';

export const FLOATING_RATE = 'FLOATING_RATE';
export const PRINCIPAL_AND_INTEREST_REPAYMENT =
  'PRINCIPAL_AND_INTEREST_REPAYMENT';
export const INTEREST_ONLY_REPAYMENT = 'INTEREST_ONLY_REPAYMENT';
export const INTEREST_IN_ADVANCE_REPAYMENT = 'INTEREST_IN_ADVANCE_REPAYMENT';
export const LINE_OF_CREDIT_REPAYMENT = 'LINE_OF_CREDIT_REPAYMENT';
export const OFFSET_ACCOUNT_FEATURE = 'OFFSET_ACCOUNT_FEATURE';
export const REDRAW_FEATURE = 'REDRAW_FEATURE';

// [AU, NZ]
export const QUESTION_IDS = {
  // PREFERENCES
  [CURRENT_BANK]: [225, 225], // 226 for NZ
  [CURRENT_BANK_OTHER]: 46, // 95 for NZ
  [OTHER_CURRENT_BANK]: 46, // 95 for NZ
  [HAPPY_WITH_CURRENT_BANK]: [47, 47], // 96 for NZ
  [CURRENT_BANK_UNHAPPINESS_REASON]: [48, 48],

  [PREFERRED_LENDERS]: [7, 7], // 56 for NZ
  [PREFERRED_LENDERS_REASON]: [224, 224],
  [PREFERRED_LENDERS_OTHER]: 400,
  [OTHER_PREFERRED_LENDER]: 64,

  [RATE_PREFERENCES_YES_NO]: [135, 230],
  [RATE_PREFERENCES]: [136, 231],
  [FIXED_RATE_DURATION]: [137, 232],
  [RATE_PREFERENCES_IMPORTANT]: [138, 233],
  [RATE_PREFERENCES_IMPORTANT_OTHER]: [139, 234],
  [RATE_PREFERENCES_RISKS]: [184, 235],

  [REPAYMENT_PREFERENCES_YES_NO]: [150, 240],
  [REPAYMENT_PREFERENCES]: [151, 241],
  [PRINCIPAL_AND_INTEREST]: [190, 261],
  [PRINCIPAL_AND_INTEREST_REPAYMENT_FREQUENCY]: [191, 268],
  [PRINCIPAL_AND_INTEREST_IMPORTANT]: [197, 269],
  [PRINCIPAL_AND_INTEREST_IMPORTANT_OTHER]: [202, 276],
  [INTEREST_ONLY]: [192, 262],
  [INTEREST_ONLY_REPAYMENT_FREQUENCY]: [194, 270],
  [INTEREST_ONLY_PERIOD]: [195, 271],
  [INTEREST_ONLY_IMPORTANT]: [198, 272],
  [INTEREST_ONLY_IMPORTANT_OTHER]: [203, 277],
  [LINE_OF_CREDIT]: [193, 263],
  [LINE_OF_CREDIT_REPAYMENT_FREQUENCY]: [196, 274],
  [LINE_OF_CREDIT_IMPORTANT]: [199, 275],
  [LINE_OF_CREDIT_IMPORTANT_OTHER]: [204, 279],
  [INTEREST_IN_ADVANCE]: [200, 264],
  [INTEREST_IN_ADVANCE_IMPORTANT]: [201, 273],
  [INTEREST_IN_ADVANCE_IMPORTANT_OTHER]: [205, 278],
  [REPAYMENT_PREFERENCES_RISKS]: [171, 242],

  [FEATURE_PREFERENCES_YES_NO]: [146, 236],
  [FEATURE_PREFERENCES]: [147, 237],
  [FEATURE_PREFERENCES_IMPORTANT]: [148, 238],
  [REDRAW_IMPORTANT]: [188, 259],
  [REDRAW_IMPORTANT_OTHER]: [206, 265],
  [OFFSET_ACCOUNT_IMPORTANT]: [189, 260],
  [OFFSET_ACCOUNT_IMPORTANT_OTHER]: [207, 266],
  [FEATURE_PREFERENCES_RISKS]: [185, 239],

  // FUTURE
  [RETIREMENT_AGE]: [172, 248],
  [RETIREMENT_TERM]: [175, 249],
  [REPAY_LOAN_METHOD]: [176, 250],
  [REPAY_LOAN_METHOD_OTHER]: [208, 267],
  [ANY_FINANCIAL_IMPACT]: [177, 251],

  [ANTICIPATED_CHANGES]: [186, 252],

  // TEAM
  [NEW_GENERAL_INSURANCE]: 453,
  [GENERAL_INSURANCE]: 24,
  [MOVING_SERVICES]: 25,
  [LIFESTYLE_INSURANCE]: 102,
  [LIFESTYLE_INSURANCE_RISK]: 103,
  [LIFESTYLE_INSURANCE_OBLIGATION]: 104,
  [LIFESTYLE_INSURANCE_ALI]: 105,
  [LIFESTYLE_INSURANCE_ADVISER]: 106,
  [LIFESTYLE_INSURANCE_NOTES]: 107,
  [HOME_NOW]: 182,
  [HOME_NOW_NOTES]: 317,

  [LIFE_AND_HEALTH]: 297,
  [LIFE_AND_HEALTH_DETAILS]: 298,
  [LIFE_AND_HEALTH_REASON]: 299,
  [GENERAL_INSURANCE_NZ]: 300,
  [GENERAL_INSURANCE_NZ_DETAILS]: 301,
  [GENERAL_INSURANCE_NZ_REASON]: 302,
  [UK_PENSION_TRANSFERS]: 303,
  [ASSET_FINANCE]: 304,
  [CURRENCY_EXCHANGE]: 305,
  [KIWI_SAVER]: 306,

  [HAS_PREFERRED_LENDERS]: 'hasPreferredLenders',

  [FIXED_RATE]: 'fixed',
  [VARIABLE_RATE]: 'variable',
  [SPLIT_RATE]: 'split',

  [FLOATING_RATE]: 'floating',
  [FIXED_AND_FLOATING_COMBO]: 'fixed & floating combo',

  [REDRAW]: 'redraw',
  [OFFSET_ACCOUNT]: 'offsetAccount',

  [NATURE_OF_CHANGE_OTHER]: 'other',
  [NATURE_OF_CHANGE_START_DATE]: 'startDate',
  [NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION]: 'periodOfImpactDuration',
  [NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION_UNIT]: 'periodOfImpactUnit',
  [NATURE_OF_CHANGE_FINANCIAL_IMPACT]: 'financialImpactAmount',
  [NATURE_OF_CHANGE_OBJECTIVE_LIST]: 'objectiveListValue',
  [NATURE_OF_CHANGE_DETAILS]: 'details',
};

export const PREFERENCES = 'preferences';
export const TEAM = 'team';
export const CURRENT_BANKS = 'current-bank';
export const PREFERRED_BANKS = 'lenders';
export const LOAN_OPTIONS = 'loan-options';
export const LONG_TERM_GOALS = 'long-term-goals';
export const HORIZON = 'horizon';
export const RETIREMENT = 'retirement';
export const NATURE_OF_CHANGE = 'nature-of-change';

export const OTHER_ID = -1;
export const NO_PREFERRED_LENDERS = -2;
export const OPTIONAL_DETAILS_PLACEHOLDER = 'Details [optional]';
export const WEALTH_CREATION_GOAL_TYPE_ID = 5;
export const SUMMARY_GOAL_TYPE_ID = 7;

export const DISPLAY_OPTIONS = {
  YES_NO_ONLY: 1,
  TEXT_ONLY: 2,
  TEXTBOX_ON_YES: 3,
  TEXTBOX_ON_NO: 4,
  SINGLE_DROPDOWN: 5,
  MULTI_DROPDOWN: 6,
  SINGLE_DROPDOWN_NO_FRAME: 7,
  CHECK_BOX: 8,
  TEXTBOX_WITH_DROPDOWN: 9,
  TEXT_FLOAT: 10,
  RADIO_BOX_ON_YES: 11,
  RADIO_BOX_ON_NO: 12,
  RADIO_BOX: 13,
  SINGLE_DROPDOWN_STRING: 14,
  SINGLE_SELECT_POPUP: 15,
  MULTI_SELECT_POPUP: 16,
  TEXT_WITH_SPAN: 17,
  CURRENCY_WITH_SPAN: 18,
  FULL_DATE: 19,
  SINGLE_LINE_TEXT: 20,
  MULTI_SELECT_WITH_LOGO: 21,
  YES_NO_ONLY_BOOL: 22,
  SINGLE_CHECK_BOX: 23,
  FULL_DATE_REVERSED: 24,
};

export const YES_NO_OPTION_VALUES = {
  YES: '1',
  NO: '0',
};

export const YES_NO_OPTIONS = [
  { key: 'true', value: YES_NO_OPTION_VALUES.YES, label: 'Yes' },
  { key: 'false', value: YES_NO_OPTION_VALUES.NO, label: 'No' },
];

export const YES_NO_OPTIONS_TEAM_NZ = [
  { key: 'true', value: '1', label: 'Yes, I’d like to chat' },
  { key: 'false', value: '0', label: 'Not right now' },
];

export const NATURE_OF_CHANGE_QS = [
  {
    QuestionId: QUESTION_IDS[NATURE_OF_CHANGE_OTHER],
    SubQuestion: [],
    QuestionContent: 'Give Details',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.TEXT_ONLY,
  },
  {
    QuestionId: QUESTION_IDS[NATURE_OF_CHANGE_START_DATE],
    SubQuestion: [],
    QuestionContent: 'When will the change start?',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.FULL_DATE_REVERSED,
  },
  {
    QuestionId: QUESTION_IDS[NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION],
    SubQuestion: [],
    QuestionContent: 'How long will the change last?',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  },
  {
    QuestionId: QUESTION_IDS[NATURE_OF_CHANGE_FINANCIAL_IMPACT],
    SubQuestion: [],
    QuestionContent: 'What will the financial impact be?',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.CURRENCY_WITH_SPAN,
  },
  {
    QuestionId: QUESTION_IDS[NATURE_OF_CHANGE_OBJECTIVE_LIST],
    SubQuestion: [],
    QuestionContent:
      'How will client_name make loan repayments while this is happening?',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_DROPDOWN,
    Options: [
      {
        name: 'Additional Income Source',
        label: 'Additional Income Source',
        key: '1',
        value: '1',
      },
      {
        name: 'Reduce Expenditure',
        label: 'Reduce Expenditure',
        key: '2',
        value: '2',
      },
      { name: 'Sale of Assets', label: 'Sale of Assets', key: '3', value: '3' },
      {
        name: 'Savings and/or Superannuation',
        label: 'Savings and/or Superannuation',
        key: '4',
        value: '4',
      },
    ],
  },
  {
    QuestionId: QUESTION_IDS[NATURE_OF_CHANGE_DETAILS],
    SubQuestion: [],
    QuestionContent: '',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.TEXT_ONLY,
    isOtherField: true,
  },
];

const getValue = (o, key, idx) => {
  const value = o[key];
  if (Array.isArray(value) && value.length === 2) {
    return value[idx];
  }
  return value;
};

const questionsMapper = (obj) =>
  Object.keys(obj).reduce((map, key) => {
    map[getValue(QUESTION_IDS, key, 0)] = getValue(obj, key, 0);
    map[getValue(QUESTION_IDS, key, 1)] = getValue(obj, key, 1);
    return map;
  }, {});

const filteredQsMapper = (obj, idx) =>
  Object.keys(obj).reduce((map, key) => {
    const field =
      (Array.isArray(QUESTION_IDS[key])
        ? QUESTION_IDS[key][idx]
        : QUESTION_IDS[key]) || key;
    map[field] = obj[key].map((o) =>
      Array.isArray(QUESTION_IDS[o]) ? QUESTION_IDS[o][idx] : QUESTION_IDS[o],
    );
    return map;
  }, {});

export const LOAN_OPTIONS_YES_NO_QS = questionsMapper({
  [RATE_PREFERENCES]: QUESTION_IDS[RATE_PREFERENCES_YES_NO],
  [REPAYMENT_PREFERENCES]: QUESTION_IDS[REPAYMENT_PREFERENCES_YES_NO],
  [FEATURE_PREFERENCES]: QUESTION_IDS[FEATURE_PREFERENCES_YES_NO],
});

export const LOAN_OPTIONS_RISK_QS = questionsMapper({
  [RATE_PREFERENCES]: QUESTION_IDS[RATE_PREFERENCES_RISKS],
  [REPAYMENT_PREFERENCES]: QUESTION_IDS[REPAYMENT_PREFERENCES_RISKS],
  [FEATURE_PREFERENCES]: QUESTION_IDS[FEATURE_PREFERENCES_RISKS],
});

export const CUSTOM_OPTION_ICONS = questionsMapper({
  [ANTICIPATED_CHANGES]: {
    1: 'sl-custom-calendar-2',
    2: 'sl-custom-coins-2',
    3: 'sl-custom-briefcase-2',
    4: 'sl-custom-briefcase-2',
    5: 'sl-custom-chart-up',
    6: 'sl-custom-car-4',
    7: 'sl-custom-stethoscope',
    Other: 'sl-custom-edit-3',
  },
  [RATE_PREFERENCES]: {
    [QUESTION_IDS[FIXED_RATE]]: 'sl-custom-percent-2',
    [QUESTION_IDS[VARIABLE_RATE]]: 'sl-custom-business-chart-2',
    [QUESTION_IDS[SPLIT_RATE]]: 'sl-custom-pie-chart-1',
    [QUESTION_IDS[FLOATING_RATE]]: 'sl-custom-business-chart-2',
    [QUESTION_IDS[FIXED_AND_FLOATING_COMBO]]: 'sl-custom-pie-chart-1',
  },
  [REPAYMENT_PREFERENCES]: {
    1: 'sl-custom-dollar-bag',
    2: 'sl-custom-coins',
    3: 'sl-custom-money',
    4: 'sl-custom-hand-coin',
  },
  [FEATURE_PREFERENCES]: {
    1: 'sl-custom-piggy-bank',
    2: 'sl-custom-atm',
  },
});

const OFFSET_ACCOUNT_LABEL = 'Offset Account';
const PRINCIPAL_AND_INTEREST_LABEL = 'Principal and Interest';
const INTEREST_ONLY_LABEL = 'Interest Only Repayment';
const FIXED_AND_FLOATING = 'Fixed & Floating Combo';

export const CUSTOM_OPTION_LABELS = {
  ...questionsMapper({
    [ANTICIPATED_CHANGES]: {
      1: 'Unpaid leave',
      2: 'Reduced income',
      3: 'Loss of employment',
      4: 'Leaving employment',
      5: 'Existing debt increase',
      6: 'Large expense',
      7: 'Medical treatment',
      Other: 'Other',
    },
    [FEATURE_PREFERENCES]: {
      1: OFFSET_ACCOUNT_LABEL,
      2: 'Redraw',
    },
  }),
  [QUESTION_IDS[RATE_PREFERENCES][0]]: {
    [QUESTION_IDS[FIXED_RATE]]: 'Fixed',
    [QUESTION_IDS[VARIABLE_RATE]]: 'Variable',
    [QUESTION_IDS[SPLIT_RATE]]: 'Fixed & Variable (Split)',
  },
  [QUESTION_IDS[REPAYMENT_PREFERENCES][0]]: {
    1: PRINCIPAL_AND_INTEREST_LABEL,
    2: INTEREST_ONLY_LABEL,
    3: 'Interest in Advance',
    4: 'Line of Credit',
  },
  [QUESTION_IDS[RATE_PREFERENCES][1]]: {
    [QUESTION_IDS[FIXED_RATE]]: 'Fixed',
    [QUESTION_IDS[FLOATING_RATE]]: 'Floating',
    [QUESTION_IDS[FIXED_AND_FLOATING_COMBO]]: FIXED_AND_FLOATING,
  },
  [QUESTION_IDS[REPAYMENT_PREFERENCES][1]]: {
    1: PRINCIPAL_AND_INTEREST_LABEL,
    2: INTEREST_ONLY_LABEL,
    4: 'Revolving Credit Facility',
  },
};

export const CUSTOM_OPTION_DESCRIPTIONS = questionsMapper({
  [ANTICIPATED_CHANGES]: {
    1: '(Eg. parental)',
  },
});

export const INLINED_QS = {
  [QUESTION_IDS[NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION]]: {
    questionId: QUESTION_IDS[NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION_UNIT],
    subQuestions: [],
    content: 'How long will the change last?',
    displayTypeId: DISPLAY_OPTIONS.SINGLE_DROPDOWN,
    options: [
      { name: 'Weeks', label: 'Weeks', key: 'Weeks', value: 'Weeks' },
      {
        name: 'Months',
        label: 'Months',
        key: 'Months',
        value: 'Months',
        isDefault: true,
      },
      { name: 'Years', label: 'Years', key: 'Years', value: 'Years' },
    ],
  },
};

export const HIDDEN_SUBQS = [209, 253];

export const CUSTOM_DISPLAY_TYPE_IDS = questionsMapper({
  [PREFERRED_LENDERS]: DISPLAY_OPTIONS.MULTI_SELECT_WITH_LOGO,
  [HAPPY_WITH_CURRENT_BANK]: DISPLAY_OPTIONS.YES_NO_ONLY_BOOL,
  [OTHER_CURRENT_BANK]: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  [PREFERRED_LENDERS_OTHER]: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  [RETIREMENT_AGE]: DISPLAY_OPTIONS.TEXT_WITH_SPAN,
  [REPAY_LOAN_METHOD]: DISPLAY_OPTIONS.CHECK_BOX,
  [ANTICIPATED_CHANGES]: DISPLAY_OPTIONS.MULTI_SELECT_POPUP,
  [CURRENT_BANK]: DISPLAY_OPTIONS.MULTI_SELECT_WITH_LOGO,
  [HAS_PREFERRED_LENDERS]: DISPLAY_OPTIONS.YES_NO_ONLY_BOOL,
  [GENERAL_INSURANCE]: DISPLAY_OPTIONS.YES_NO_ONLY,
  [NEW_GENERAL_INSURANCE]: DISPLAY_OPTIONS.YES_NO_ONLY,
  [MOVING_SERVICES]: DISPLAY_OPTIONS.YES_NO_ONLY,
  [LIFESTYLE_INSURANCE_OBLIGATION]: DISPLAY_OPTIONS.YES_NO_ONLY,
  [LIFESTYLE_INSURANCE_ADVISER]: DISPLAY_OPTIONS.YES_NO_ONLY,
  [LIFESTYLE_INSURANCE_RISK]: DISPLAY_OPTIONS.SINGLE_CHECK_BOX,
  [HOME_NOW]: DISPLAY_OPTIONS.YES_NO_ONLY,
  [RATE_PREFERENCES]: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
  [REPAYMENT_PREFERENCES]: DISPLAY_OPTIONS.MULTI_SELECT_POPUP,
  [FEATURE_PREFERENCES]: DISPLAY_OPTIONS.MULTI_SELECT_POPUP,
  [RATE_PREFERENCES_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [FIXED_RATE_DURATION]: DISPLAY_OPTIONS.SINGLE_DROPDOWN,
  [PRINCIPAL_AND_INTEREST_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [INTEREST_ONLY_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [INTEREST_IN_ADVANCE_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [LINE_OF_CREDIT_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [REDRAW_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [OFFSET_ACCOUNT_IMPORTANT]: DISPLAY_OPTIONS.CHECK_BOX,
  [LIFE_AND_HEALTH_DETAILS]: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  [LIFE_AND_HEALTH_REASON]: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  [GENERAL_INSURANCE_NZ_DETAILS]: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  [GENERAL_INSURANCE_NZ_REASON]: DISPLAY_OPTIONS.SINGLE_LINE_TEXT,
  [HOME_NOW_NOTES]: DISPLAY_OPTIONS.TEXT_ONLY,
});

export const OTHER_QS = [...QUESTION_IDS[REPAY_LOAN_METHOD_OTHER]];

export const CUSTOM_TITLE = {
  [QUESTION_IDS[NEW_GENERAL_INSURANCE]]: 'Concierge Insurance',
  [QUESTION_IDS[GENERAL_INSURANCE]]: 'Concierge',
  [QUESTION_IDS[MOVING_SERVICES]]: 'Additional Concierge Services',
  [QUESTION_IDS[LIFESTYLE_INSURANCE_OBLIGATION]]:
    'Financial Advice & Lifestyle Insurance',
  [QUESTION_IDS[HOME_NOW]]: 'Home Now',
  [QUESTION_IDS[LIFE_AND_HEALTH]]: 'Life & Health',
  [QUESTION_IDS[GENERAL_INSURANCE_NZ]]: 'General Insurance',
  [QUESTION_IDS[UK_PENSION_TRANSFERS]]: 'UK Pension Transfers',
  [QUESTION_IDS[ASSET_FINANCE]]: 'Asset Finance',
  [QUESTION_IDS[CURRENCY_EXCHANGE]]: 'Currency Exchange',
  [QUESTION_IDS[KIWI_SAVER]]: 'KiwiSaver',
};

export const OPTIONS_TO_QUESTION_IDS = questionsMapper({
  [REPAYMENT_PREFERENCES]: {
    1: QUESTION_IDS[PRINCIPAL_AND_INTEREST],
    2: QUESTION_IDS[INTEREST_ONLY],
    3: QUESTION_IDS[INTEREST_IN_ADVANCE],
    4: QUESTION_IDS[LINE_OF_CREDIT],
  },
  [FEATURE_PREFERENCES]: {
    1: QUESTION_IDS[OFFSET_ACCOUNT],
    2: QUESTION_IDS[REDRAW],
  },
});

export const CUSTOM_CONTENT = questionsMapper({
  [OTHER_CURRENT_BANK]: 'Other Bank',
  [CURRENT_BANK_UNHAPPINESS_REASON]: 'Why?',
  [PREFERRED_LENDERS_OTHER]: 'Other Bank',
  [RETIREMENT_AGE]: 'What age is client_name planning to retire?',
  [RETIREMENT_TERM]: 'Will client_name be retiring during the loan term?',
  [REPAY_LOAN_METHOD]: 'How would client_name repay the loan?',
  [ANY_FINANCIAL_IMPACT]: 'Anything that could have a financial impact?',
  [ANTICIPATED_CHANGES]: 'What sort of changes does client_name anticipate?',
  [GENERAL_INSURANCE]: 'Get your insurance sorted!',
  [NEW_GENERAL_INSURANCE]:
    'We have two options for building/fixed contents insurance that apply from your contract date. You have the option of either: <ul><li>30 Day Complimentary Insurance*; or</li><li>90 days complimentary pre-settlement building insurance^ when you purchase a building insurance policy that starts from your settlement date.</li></ul>By selecting ‘yes’, I consent to being contacted by Concierge for the purpose of:<ul><li>finding out if I am eligible to for the above insurance options; and</li><li>if I am eligible to receive a quote and if required apply for home building, home contents or home building and contents or landlord building, landlord contents or landlord building and contents insurance (as applicable), which is underwritten by Allianz Australia Insurance Limited.</li></ul>',
  [MOVING_SERVICES]:
    'Buying a house can be stressful. Want help with any of these things:',
  [LIFESTYLE_INSURANCE_OBLIGATION]:
    'Would you like an obligation free chat with a Wealth Market financial adviser to make sure you’re set up for the future?',
  [LIFESTYLE_INSURANCE_ADVISER]:
    'Will you be speaking to your own financial adviser or provider of loan protection?',
  [LIFESTYLE_INSURANCE_RISK]:
    'I understand the risk of not having loan protection in place.',
  [HOME_NOW]: 'Want help getting your new home connected?',
  [RATE_PREFERENCES]: 'Rate Preferences?',
  [REPAYMENT_PREFERENCES]: 'Repayment Preferences?',
  [FEATURE_PREFERENCES]: 'Feature Preferences?',
  [REDRAW_IMPORTANT]: 'Why is this important to you?',
  [OFFSET_ACCOUNT_IMPORTANT]: 'Why is this important to you?',
  [LIFE_AND_HEALTH]:
    'Do you need protection for you and your loved ones to give financial security if an accident or illness was to occur?',
  [LIFE_AND_HEALTH_REASON]: 'Please provide reason for declining',
  [GENERAL_INSURANCE_NZ]:
    'Do you need to protect the stuff you own from accidental damage, loss or theft?',
  [GENERAL_INSURANCE_NZ_REASON]: 'Please provide reason for declining',
  [UK_PENSION_TRANSFERS]:
    'Do you have a UK Pension that needs to be transferred home?',
  [ASSET_FINANCE]:
    'Do you need funds for new or used assets, including: vehicles, plant or equipment?',
  [CURRENCY_EXCHANGE]: 'Are you wanting to buy or sell overseas dollars?',
  [KIWI_SAVER]:
    'Do you need advice around which provider and fund is best for you?',
});

export const CUSTOM_NOTES = {
  [QUESTION_IDS[NEW_GENERAL_INSURANCE]]: [
    'Loan Market Insurance & Concierge Insurance arranges this insurance as an agent for the insurer, Allianz Australia Insurance Limited ABN 15 000 122 850 AFSL 234708. Full terms and conditions of this cover and the complimentary insurance offers is available online at loanmarketinsurance.com.au.',
  ],
  [QUESTION_IDS[GENERAL_INSURANCE]]: [
    '(1) For full details, of the terms, conditions, limits and exclusions that apply please read the Home and Contents Insurance Product Disclosure Statement (PDS), any applicable supplementary PDS and the Home Buildings and Home Contents Key Fact Sheets (KFS) available online at loanmarketinsurance.com.au before making a decision to purchase the insurance.',
    '(2) Full terms and conditions of 30 days free insurance here or loanmarketinsurance.com.au/free-cover-conditions.',
  ],
};

export const CUSTOM_SUBCONTENT_HEADER = questionsMapper({
  [HOME_NOW]:
    'Home Now compares 30 of Australia’s leading electricity, gas, and internet brands to find you the most competitive deals - then connects the service you choose.',
});

export const CUSTOM_SUBCONTENT = questionsMapper({
  [NEW_GENERAL_INSURANCE]: '',
  [GENERAL_INSURANCE]:
    'Ask about our 30-day free building insurance which you can use pre or post-settlement (1) (2). Alternatively, you can take advantage of up to 90 days complimentary pre-settlement building insurance when you purchase your building and contents insurance to start from your settlement date. Loan Market Insurance has a range of solutions to keep you covered including landlords insurance, car insurance and more.',
  [MOVING_SERVICES]: [
    'Tax Depreciation schedules for investors',
    'Building & pest reports',
    'Removals and relocations',
    'Storage solutions',
    'Home improvement services',
    'Solar power solutions',
    'Pet care & transportation',
    'Homeware discounts and offers',
  ],
  [HOME_NOW]: ['Electricity', 'Gas', 'Internet'],
});

export const QUESTIONS_OVERRIDE = {
  antiHawking: {
    CUSTOM_CONTENT: questionsMapper({
      [GENERAL_INSURANCE]:
        'We have two options for building/fixed contents insurance that apply from your contract date. You have the option of either: <ul><li>30 Day Complimentary Insurance*; or</li><li>90 days complimentary pre-settlement building insurance^ when you purchase a building insurance policy that starts from your settlement date.</li></ul>By selecting ‘yes’, I consent to being contacted by Concierge for the purpose of:<ul><li>finding out if I am eligible to for the above insurance options; and</li><li>if I am eligible to receive a quote and if required apply for home building, home contents or home building and contents or landlord building, landlord contents or landlord building and contents insurance (as applicable), which is underwritten by Allianz Australia Insurance Limited.</li></ul>',
      [MOVING_SERVICES]:
        'Concierge can assist with a range of services to help with your new property purchase.',
    }),
    CUSTOM_SUBCONTENT: questionsMapper({
      [GENERAL_INSURANCE]: '',
      [MOVING_SERVICES]: [
        'Tax depreciation schedules for investors',
        'Property management services',
        'Removals',
        'Home improvement services',
        'Storage solutions',
        'Solar power solutions',
        'Pet care & transports',
        'Home wellness and art discount vouchers',
      ],
    }),
    CUSTOM_TITLE: {
      [QUESTION_IDS[GENERAL_INSURANCE]]: 'Concierge Insurance',
      [QUESTION_IDS[MOVING_SERVICES]]: 'Concierge Moving & Investor Services',
    },
    CUSTOM_NOTES: {
      [QUESTION_IDS[GENERAL_INSURANCE]]: [
        'Loan Market Insurance & Concierge Insurance arranges this insurance as an agent for the insurer, Allianz Australia Insurance Limited ABN 15 000 122 850 AFSL 234708. Full terms and conditions of this cover and the complimentary insurance offers is available online at loanmarketinsurance.com.au.',
      ],
    },
  },
};

export const LIFESTYLE_INSURANCE_SUBQS = [
  QUESTION_IDS[LIFESTYLE_INSURANCE_OBLIGATION],
  QUESTION_IDS[LIFESTYLE_INSURANCE_ADVISER],
  QUESTION_IDS[LIFESTYLE_INSURANCE_RISK],
  QUESTION_IDS[LIFESTYLE_INSURANCE_NOTES],
];

export const IMPORTANT_QS = [
  ...QUESTION_IDS[RATE_PREFERENCES_IMPORTANT],
  ...QUESTION_IDS[PRINCIPAL_AND_INTEREST_IMPORTANT],
  ...QUESTION_IDS[INTEREST_ONLY_IMPORTANT],
  ...QUESTION_IDS[INTEREST_IN_ADVANCE_IMPORTANT],
  ...QUESTION_IDS[LINE_OF_CREDIT_IMPORTANT],
  ...QUESTION_IDS[REDRAW_IMPORTANT],
  ...QUESTION_IDS[OFFSET_ACCOUNT_IMPORTANT],
];

export const LIFESTYLE_INSURANCE_YES_NO_QS = LIFESTYLE_INSURANCE_SUBQS.filter(
  (s) => s !== QUESTION_IDS[LIFESTYLE_INSURANCE_NOTES],
);

const CUSTOM_SUBQUESTIONS_MAPPINGS = {
  [FIXED_RATE]: [RATE_PREFERENCES_IMPORTANT, FIXED_RATE_DURATION],
  [VARIABLE_RATE]: [RATE_PREFERENCES_IMPORTANT],
  [SPLIT_RATE]: [RATE_PREFERENCES_IMPORTANT, FIXED_RATE_DURATION],
  [FLOATING_RATE]: [RATE_PREFERENCES_IMPORTANT],
  [FIXED_AND_FLOATING_COMBO]: [RATE_PREFERENCES_IMPORTANT, FIXED_RATE_DURATION],
  [RATE_PREFERENCES_IMPORTANT]: [RATE_PREFERENCES_IMPORTANT_OTHER],
  [HAS_PREFERRED_LENDERS]: [PREFERRED_LENDERS],
  [PREFERRED_LENDERS]: [PREFERRED_LENDERS_OTHER, PREFERRED_LENDERS_REASON],
  [REPAYMENT_PREFERENCES]: [
    PRINCIPAL_AND_INTEREST,
    INTEREST_ONLY_PERIOD,
    LINE_OF_CREDIT,
    INTEREST_IN_ADVANCE,
  ],
  [FEATURE_PREFERENCES]: [REDRAW, OFFSET_ACCOUNT],
  [REDRAW]: [REDRAW_IMPORTANT],
  [OFFSET_ACCOUNT]: [OFFSET_ACCOUNT_IMPORTANT],
  [HAPPY_WITH_CURRENT_BANK]: [CURRENT_BANK_UNHAPPINESS_REASON],
  [LIFESTYLE_INSURANCE_OBLIGATION]: [
    LIFESTYLE_INSURANCE_ADVISER,
    LIFESTYLE_INSURANCE_RISK,
    LIFESTYLE_INSURANCE_NOTES,
  ],
  [HOME_NOW]: [HOME_NOW_NOTES],
};

export const CUSTOM_SUBQUESTIONS = {
  AU: {
    ...filteredQsMapper(CUSTOM_SUBQUESTIONS_MAPPINGS, 0),
    [QUESTION_IDS[RATE_PREFERENCES][0]]: [
      QUESTION_IDS[FIXED_RATE],
      QUESTION_IDS[VARIABLE_RATE],
      QUESTION_IDS[SPLIT_RATE],
    ],
    [QUESTION_IDS[ANTICIPATED_CHANGES][0]]: NATURE_OF_CHANGE_QS,
    [QUESTION_IDS[REPAY_LOAN_METHOD][0]]: [
      QUESTION_IDS[REPAY_LOAN_METHOD_OTHER][0],
    ],
  },
  NZ: {
    ...filteredQsMapper(CUSTOM_SUBQUESTIONS_MAPPINGS, 1),
    [QUESTION_IDS[RATE_PREFERENCES][1]]: [
      QUESTION_IDS[FIXED_RATE],
      QUESTION_IDS[FLOATING_RATE],
      QUESTION_IDS[FIXED_AND_FLOATING_COMBO],
    ],
    [QUESTION_IDS[ANTICIPATED_CHANGES][1]]: NATURE_OF_CHANGE_QS,
    [QUESTION_IDS[REPAY_LOAN_METHOD][1]]: [
      QUESTION_IDS[REPAY_LOAN_METHOD_OTHER][1],
    ],
  },
};

export const RATE_PREFERENCES_OPTIONS_FILTER = {
  [QUESTION_IDS[FIXED_RATE]]: [1, 5, -1],
  [QUESTION_IDS[VARIABLE_RATE]]: [3, 4, -1],
  [QUESTION_IDS[SPLIT_RATE]]: [3, 4, 5, -1],
  [QUESTION_IDS[FLOATING_RATE]]: [3, 4, -1],
  [QUESTION_IDS[FIXED_AND_FLOATING_COMBO]]: [3, 4, 5, -1],
};

const COMMON_LOAN_OPTIONS_QS = [
  {
    QuestionId: QUESTION_IDS[FIXED_RATE],
    SubQuestion: [],
    QuestionContent: 'Fixed Rate',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
  },
  {
    QuestionId: QUESTION_IDS[REDRAW],
    SubQuestion: [],
    QuestionContent: 'Redraw',
    QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
  },
  {
    QuestionId: QUESTION_IDS[OFFSET_ACCOUNT],
    SubQuestion: [],
    QuestionContent: OFFSET_ACCOUNT_LABEL,
    QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
  },
];

export const LOAN_OPTIONS_QS = {
  AU: [
    ...COMMON_LOAN_OPTIONS_QS,
    {
      QuestionId: QUESTION_IDS[VARIABLE_RATE],
      SubQuestion: [],
      QuestionContent: 'Variable Rate',
      QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
    },
    {
      QuestionId: QUESTION_IDS[SPLIT_RATE],
      SubQuestion: [],
      QuestionContent: 'Split Rate',
      QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
    },
  ],
  NZ: [
    ...COMMON_LOAN_OPTIONS_QS,
    {
      QuestionId: QUESTION_IDS[FLOATING_RATE],
      SubQuestion: [],
      QuestionContent: 'Floating Rate',
      QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
    },
    {
      QuestionId: QUESTION_IDS[FIXED_AND_FLOATING_COMBO],
      SubQuestion: [],
      QuestionContent: FIXED_AND_FLOATING,
      QuestionDisplayTypeId: DISPLAY_OPTIONS.SINGLE_SELECT_POPUP,
    },
  ],
};

export const HOME_NOW_NOTES_Q = {
  QuestionId: QUESTION_IDS[HOME_NOW_NOTES],
  SubQuestion: [],
  QuestionContent: '',
  QuestionDisplayTypeId: DISPLAY_OPTIONS.TEXT_ONLY,
  CustomPlaceholder: 'Notes (optional) Eg. move date, relocation information',
};

export const HAS_PREFERRED_LENDERS_Q = {
  QuestionId: QUESTION_IDS[HAS_PREFERRED_LENDERS],
  SubQuestion: [],
  QuestionContent: '',
  QuestionDisplayTypeId: DISPLAY_OPTIONS.YES_NO_ONLY,
};

export const CUSTOM_SPAN_CONTENT = questionsMapper({
  [RETIREMENT_AGE]: 'years old',
  [NATURE_OF_CHANGE_FINANCIAL_IMPACT]: 'p. month',
});

export const CUSTOM_PLACEHOLDERS = questionsMapper({
  [CURRENT_BANK_UNHAPPINESS_REASON]: OPTIONAL_DETAILS_PLACEHOLDER,
  [PREFERRED_LENDERS_REASON]: OPTIONAL_DETAILS_PLACEHOLDER,
  [NATURE_OF_CHANGE_OTHER]: 'Enter Other Details',
  [NATURE_OF_CHANGE_DETAILS]: 'Notes (Optional)',
  [LIFESTYLE_INSURANCE_NOTES]: 'Notes (Optional)',
  [HOME_NOW_NOTES]: 'Notes (optional) Eg. move date, relocation information',
});

const QUESTION_FILTERS_MAPPINGS = {
  [RETIREMENT]: [RETIREMENT_AGE, RETIREMENT_TERM],
  [HORIZON]: [ANY_FINANCIAL_IMPACT],
  [LONG_TERM_GOALS]: [],
  [LOAN_OPTIONS]: [
    RATE_PREFERENCES,
    REPAYMENT_PREFERENCES,
    FEATURE_PREFERENCES,
  ],
  [CURRENT_BANKS]: [CURRENT_BANK, HAPPY_WITH_CURRENT_BANK],
  [PREFERRED_BANKS]: [HAS_PREFERRED_LENDERS],
  [FIXED_RATE]: [RATE_PREFERENCES_IMPORTANT, FIXED_RATE_DURATION],
  [VARIABLE_RATE]: [RATE_PREFERENCES_IMPORTANT],
  [SPLIT_RATE]: [RATE_PREFERENCES_IMPORTANT, FIXED_RATE_DURATION],
  [FLOATING_RATE]: [RATE_PREFERENCES_IMPORTANT],
  [FIXED_AND_FLOATING_COMBO]: [RATE_PREFERENCES_IMPORTANT, FIXED_RATE_DURATION],
  [PRINCIPAL_AND_INTEREST]: [
    PRINCIPAL_AND_INTEREST_IMPORTANT,
    PRINCIPAL_AND_INTEREST_REPAYMENT_FREQUENCY,
  ],
  [INTEREST_ONLY]: [
    INTEREST_ONLY_IMPORTANT,
    INTEREST_ONLY_PERIOD,
    INTEREST_ONLY_REPAYMENT_FREQUENCY,
  ],
  [INTEREST_IN_ADVANCE]: [INTEREST_IN_ADVANCE_IMPORTANT],
  [REDRAW]: [REDRAW_IMPORTANT],
  [OFFSET_ACCOUNT]: [OFFSET_ACCOUNT_IMPORTANT],
};

export const QUESTION_FILTERS_OVERRIDE = {
  antiHawking: {
    AU: {
      [TEAM]: [
        QUESTION_IDS[NEW_GENERAL_INSURANCE],
        QUESTION_IDS[MOVING_SERVICES],
        QUESTION_IDS[LIFESTYLE_INSURANCE_OBLIGATION],
        QUESTION_IDS[HOME_NOW],
      ],
    },
  },
};

export const QUESTION_FILTERS = {
  AU: {
    ...filteredQsMapper(QUESTION_FILTERS_MAPPINGS, 0),
    [QUESTION_IDS[RATE_PREFERENCES][0]]: [
      QUESTION_IDS[FIXED_RATE],
      QUESTION_IDS[VARIABLE_RATE],
      QUESTION_IDS[SPLIT_RATE],
    ],
    [QUESTION_IDS[LINE_OF_CREDIT][0]]: [
      QUESTION_IDS[LINE_OF_CREDIT_IMPORTANT][0],
      QUESTION_IDS[LINE_OF_CREDIT_REPAYMENT_FREQUENCY][0],
    ],
    [TEAM]: [
      QUESTION_IDS[GENERAL_INSURANCE],
      QUESTION_IDS[MOVING_SERVICES],
      QUESTION_IDS[LIFESTYLE_INSURANCE_OBLIGATION],
      QUESTION_IDS[HOME_NOW],
    ],
  },
  NZ: {
    ...filteredQsMapper(QUESTION_FILTERS_MAPPINGS, 1),
    [QUESTION_IDS[RATE_PREFERENCES][1]]: [
      QUESTION_IDS[FIXED_RATE],
      QUESTION_IDS[FLOATING_RATE],
      QUESTION_IDS[FIXED_AND_FLOATING_COMBO],
    ],
    [QUESTION_IDS[LINE_OF_CREDIT][1]]: [
      QUESTION_IDS[LINE_OF_CREDIT_IMPORTANT][1],
    ],
    [TEAM]: [
      QUESTION_IDS[LIFE_AND_HEALTH],
      QUESTION_IDS[GENERAL_INSURANCE_NZ],
      QUESTION_IDS[UK_PENSION_TRANSFERS],
      QUESTION_IDS[ASSET_FINANCE],
      QUESTION_IDS[CURRENCY_EXCHANGE],
      QUESTION_IDS[KIWI_SAVER],
    ],
  },
};

export const LONG_TERM_GOALS_ICONS = {
  1: 'sl-custom-house-2',
  2: 'sl-custom-building-2',
  3: 'sl-custom-beach',
  4: 'sl-custom-car-1',
  5: 'sl-custom-hand-eco',
  6: 'sl-custom-callout',
};

export const REQUIRED = 'required';

export const QUESTION_VALIDATIONS = questionsMapper({
  [RETIREMENT_AGE]: [REQUIRED],
  [RETIREMENT_TERM]: [REQUIRED],
  [REPAY_LOAN_METHOD]: [REQUIRED],
  [REPAY_LOAN_METHOD_OTHER]: [REQUIRED],
  [ANY_FINANCIAL_IMPACT]: [REQUIRED],
  [NATURE_OF_CHANGE_OTHER]: [REQUIRED],
  [NATURE_OF_CHANGE_START_DATE]: [REQUIRED],
  [NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION_UNIT]: [REQUIRED],
  [NATURE_OF_CHANGE_PERIOD_IMPACT_DURATION]: [REQUIRED],
  [NATURE_OF_CHANGE_FINANCIAL_IMPACT]: [REQUIRED],
  [NATURE_OF_CHANGE_OBJECTIVE_LIST]: [REQUIRED],
});

const requiredQsMapper = (arr) => {
  const map = { AU: [], NZ: [] };
  arr.forEach((key) => {
    map.AU.push(QUESTION_IDS[key][0]);
    map.NZ.push(QUESTION_IDS[key][1]);
  });
  return map;
};

export const REQUIRED_PREFERENCES_QS = requiredQsMapper([
  HAPPY_WITH_CURRENT_BANK,
  CURRENT_BANK,
  PREFERRED_LENDERS,
  RATE_PREFERENCES_YES_NO,
  FEATURE_PREFERENCES_YES_NO,
  REPAYMENT_PREFERENCES_YES_NO,
]);

export const REQUIRED_FUTURE_QS = requiredQsMapper([
  RETIREMENT_AGE,
  RETIREMENT_TERM,
  ANY_FINANCIAL_IMPACT,
]);

export const ACCREDITED_LENDERS_ONLY_QS = {
  [PREFERRED_BANKS]: QUESTION_IDS[PREFERRED_LENDERS],
};

export const REQUIRED_TEAM_QS = {
  AU: [
    QUESTION_IDS[GENERAL_INSURANCE],
    QUESTION_IDS[MOVING_SERVICES],
    QUESTION_IDS[HOME_NOW],
  ],
  NZ: [
    QUESTION_IDS[LIFE_AND_HEALTH],
    QUESTION_IDS[GENERAL_INSURANCE_NZ],
    QUESTION_IDS[UK_PENSION_TRANSFERS],
    QUESTION_IDS[ASSET_FINANCE],
    QUESTION_IDS[CURRENCY_EXCHANGE],
    QUESTION_IDS[KIWI_SAVER],
  ],
};

export const QUESTION_TYPES = {
  [POSSIBLE_ADVERSE_CHANGES]: 'Possible Adverse Changes',
  [OBJECTIVES]: 'Objectives',
  [INSURANCE]: 'Insurance',
  [INSURANCE_AND_REFERRALS]: 'Insurance%20%26%20Referrals',
};

export const LOAN_OPTIONS_LABELS = {
  [FIXED_RATE]: 'Fixed Rate',
  [VARIABLE_RATE]: 'Variable Rate',
  [FLOATING_RATE]: 'Floating Rate',
  [SPLIT_RATE]: 'Fixed & Variable (Split) Rate',
  [FIXED_AND_FLOATING_COMBO]: FIXED_AND_FLOATING,
  [PRINCIPAL_AND_INTEREST_REPAYMENT]: PRINCIPAL_AND_INTEREST_LABEL,
  [INTEREST_ONLY_REPAYMENT]: INTEREST_ONLY_LABEL,
  [INTEREST_IN_ADVANCE_REPAYMENT]: 'Interest in Advance',
  [REVOLVING_CREDIT_FACILITY]: 'Revolving Credit Facility',
  [LINE_OF_CREDIT_REPAYMENT]: 'Line of Credit',
  [OFFSET_ACCOUNT_FEATURE]: OFFSET_ACCOUNT_LABEL,
  [REDRAW_FEATURE]: 'Redraw',
};

export const LOAN_OPTIONS_MODAL_CONTENT_NZ = {
  [`${QUESTION_IDS[RATE_PREFERENCES][1]}-fixed`]: {
    name: LOAN_OPTIONS_LABELS[FIXED_RATE],
    value: 'fixed',
    content: [
      {
        title: 'What is a Fixed Rate?',
        text:
          'With a fixed rate home loan the interest rate you pay is fixed for a period of six months to five years. At the end of the term, you can choose to re-fix again for a new term or move to a floating rate.',
      },
      {
        title: 'Advantages',
        text:
          'A fixed rate offers great certainty, because you know exactly how much your repayments will be.',
      },
      {
        title: 'Remember',
        text:
          'If you repay your loan early, there can be early repayment fees to pay.',
      },
    ],
  },
  [`${QUESTION_IDS[RATE_PREFERENCES][1]}-floating`]: {
    name: LOAN_OPTIONS_LABELS[FLOATING_RATE],
    value: 'variable',
    content: [
      {
        title: 'What is a Floating Rate?',
        text:
          'With floating rate home loans interest rates will fluctuate as rates change, normally these changes are linked to the Official Cash Rate (OCR).',
      },
      {
        title: 'Advantages',
        text:
          'If you repay your loan early, there won’t be early repayment fees to pay.',
      },
      {
        title: 'Remember',
        text:
          'The floating rate doesn’t offer certainty. If the floating interest rates change, your repayments will also change.',
      },
    ],
  },
  [`${QUESTION_IDS[RATE_PREFERENCES][1]}-fixed & floating combo`]: {
    name: LOAN_OPTIONS_LABELS[FIXED_AND_FLOATING_COMBO],
    value: 'split',
    content: [
      {
        title: 'What is a Fixed & Floating Combo?',
        text:
          'With a Fixed & Floating combo it can give you a balance between the certainty of a fixed rate and the flexibility of a floating rate.',
      },
      {
        title: 'Advantages',
        text:
          'Splitting a loan can give you a balance between the certainty of a fixed rate and the flexibility of a floating rate. It lets you make extra repayments without any charges on the floating rate portion.',
      },
      {
        title: 'Remember',
        text:
          'If you repay your entire loan early, there can be early repayment fees to pay on the fixed portion. There is no certainty with the floating rate portion. If there are interest rate changes, your repayments will also change.',
      },
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][1]}-1`]: {
    name: LOAN_OPTIONS_LABELS[PRINCIPAL_AND_INTEREST_REPAYMENT],
    value: '1',
    content: [
      {
        title: 'What is a Principal and Interest Repayment?',
        text:
          'This is the most common type of home loan. You can choose a term of up to 30 years with most lenders. Most of the early repayments pay off the interest, while most of the later payments pay off the principal (the initial amount you borrowed).',
      },
      {
        title: 'Advantages',
        text:
          'Regular repayments will keep you on track for the term of your loan so you know when it will be paid off.',
      },
      {
        title: 'Remember',
        text:
          'You need to make regular repayments to pay off your loan during the term. Paying off your loan early could result in fees.',
      },
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][1]}-2`]: {
    name: LOAN_OPTIONS_LABELS[INTEREST_ONLY_REPAYMENT],
    value: '2',
    content: [
      {
        title: 'What is an Interest Only Repayment?',
        text:
          'You pay the interest-only part of the repayment, not the principal, so the payments are lower. You can take an interest-only loan for a year or two and then switch to principal and interest (*See note).',
      },
      {
        title: 'Advantages',
        text:
          'Your initial repayments are lower, as you are only required to make interest repayments.',
      },
      {
        title: 'Remember',
        text:
          'Your repayments are only covering interest, so your actual loan principal is not reducing. *Note: Most Lenders do a maximum term of 5 years IO.',
      },
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][1]}-4`]: {
    name: LOAN_OPTIONS_LABELS[REVOLVING_CREDIT_FACILITY],
    value: '4',
    content: [
      {
        title: 'What is a Revolving Credit Facility?',
        text:
          'A Revolving Credit Facility works like a giant overdraft. Your pay goes straight into the account and bills are paid out of the account when they’re due. By keeping the loan as low as possible at any time, you pay less interest (lenders calculate interest daily).',
      },
      {
        title: 'Advantages',
        text:
          'There are no set repayments, which provides flexibility. You can also make lump sum payments if you wish.',
      },
      {
        title: 'Remember',
        text:
          'Because there are no set repayments you need some financial discipline to make sure you are paying off your loan over time.',
      },
    ],
  },
  [`${QUESTION_IDS[FEATURE_PREFERENCES][1]}-1`]: {
    name: LOAN_OPTIONS_LABELS[OFFSET_ACCOUNT_FEATURE],
    value: '1',
    content: [
      {
        title: 'What is an Offset Account?',
        text:
          'An offset account is an everyday savings or transactional account linked to a home loan.',
      },
      {
        title: 'Advantages',
        text:
          'The money in your account is offset daily against your loan balance, meaning that less interest is charged on your loan.',
      },
      {
        title: 'Remember',
        text:
          'Any linked savings accounts do not earn any interest when they are offsetting a loan.',
      },
    ],
  },
  [`${QUESTION_IDS[FEATURE_PREFERENCES][1]}-2`]: {
    name: LOAN_OPTIONS_LABELS[REDRAW_FEATURE],
    value: '2',
    content: [
      {
        title: 'What is a Redraw Facility?',
        text:
          'A redraw facility is a feature available on some floating rate loans. It allows you to withdraw money that you’ve already contributed to your home loan.',
      },
      {
        title: 'Advantages',
        text:
          'Extra repayments reduce your principal amount, so you end up paying less interest and these extra repayments can be withdrawn later if needed.',
      },
      {
        title: 'Remember',
        text:
          'There are redraw fees charged per withdrawal and you can only withdraw up to a certain amount. There are also limits on how many redraws you can make each year.',
      },
    ],
  },
};

export const LOAN_OPTIONS_MODAL_CONTENT = {
  [`${QUESTION_IDS[RATE_PREFERENCES][0]}-fixed`]: {
    name: LOAN_OPTIONS_LABELS[FIXED_RATE],
    value: 'fixed',
    descriptionTitle: 'What is a Fixed Rate?',
    description: [
      'Rate is fixed for a specified term giving certainty of interest and repayments for that term.',
    ],
    list: [
      'Rate is fixed for a specified term giving certainty of interest and repayments for that term.',
      'Ensure each applicant understands each of the following risks:',
      'Rate is fixed at a point in time and applicant(s) will not benefit from subsequent market interest rate reductions during fixed rate period.',
      'Rate may change between the time of approval and the time of drawdown if rate guarantee has not been obtained.',
      'Limited or no ability to make additional repayments when the interest rate is fixed.',
      'May not have the ability to redraw or utilise an offset account to reduce interest.',
      'Possibility of expensive break costs if, during the fixed interest rate period, applicant(s):',
    ],
    subList: [
      'Repay loan in full;',
      'Switch to another product or loan type;',
      'Make additional repayments;',
      'Sell the property; or',
      'Seek further funds.',
    ],
  },
  [`${QUESTION_IDS[RATE_PREFERENCES][0]}-variable`]: {
    name: LOAN_OPTIONS_LABELS[VARIABLE_RATE],
    value: 'variable',
    descriptionTitle: 'What is a Variable Rate?',
    description: [
      'Interest charged and repayments will change to reflect interest rate movements.',
    ],
    list: [
      'Interest rate and repayment amount may increase while the loan is on a variable rate.',
    ],
  },
  [`${QUESTION_IDS[RATE_PREFERENCES][0]}-split`]: {
    name: LOAN_OPTIONS_LABELS[SPLIT_RATE],
    value: 'split',
    descriptionTitle: 'What is a Split Rate?',
    description: [
      'The applicant(s) may want a loan split between fixed and variable portions.',
    ],
    descriptionList: [
      'Fixed rate portion - Rate is fixed for a specified term giving certainty of interest and repayments for the fixed  rate portion.',
      'Variable rate portion - Interest charged and repayments will change to reflect interest rate movements for the variable rate portion.',
    ],
    list: [
      'Applicant(s) will not obtain the full benefit of rate decreases and will still have some exposure to the risk of rate increases.',
      'Applicant(s) will generally not be able to change the ratio of the fixed and variable portions.',
      'Applicant(s) will be required to make separate repayments for each portion.',
      'Fixed rate may change between the time of approval and the time of drawdown if rate guarantee has not been obtained.',
      'Limited or no flexibility in relation to the fixed rate portion concerning making additional repayments, redraws and offset accounts during the fixed rate period.',
      'Possibility of expensive break costs in relation to the fixed rate portion if during the fixed rate period, the applicant(s):',
    ],
    subList: [
      'Repay loan in full;',
      'Switch to another product or loan type;',
      'Make additional repayments;',
      'Sell the property; or',
      'Seek further funds.',
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][0]}-1`]: {
    name: LOAN_OPTIONS_LABELS[PRINCIPAL_AND_INTEREST_REPAYMENT],
    value: '1',
    descriptionTitle: 'What is a Principal and Interest Repayment?',
    description: [
      'Repayments cover loan principal and interest so that the loan is paid in full by the end of the loan term.',
      'Applicant(s) could pay less interest over the life of the loan as compared with a loan which features a period of interest only repayments.',
      'Interest rates on principal and interest repayments are generally lower than interest only.',
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][0]}-2`]: {
    name: LOAN_OPTIONS_LABELS[INTEREST_ONLY_REPAYMENT],
    value: '2',
    descriptionTitle: 'What is an Interest Only Repayment?',
    description: [
      'Allows smaller payments during the interest only period enabling:',
    ],
    descriptionList: [
      'Higher cash on hand for other purposes.',
      'Flexibility to manage cash flow.',
      'Smaller initial payments on investment home loans may  serve a tax purpose. Has the applicant(s) sought tax advice?',
    ],
    list: [
      'Higher interest rates may apply to interest only loans.',
      'Interest only payments will not reduce the loan principal.',
      'Not repaying loan principal will result in the applicant paying more interest over the loan term.',
      'After the end of the interest only period, principal and interest repayments will be required and these will be higher than they would have been if the loan had principal and interest repayments throughout the loan term.',
      'The amount of equity that is built-up in the property securing the loan will be less with an interest only loan.',
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][0]}-3`]: {
    name: LOAN_OPTIONS_LABELS.INTEREST_IN_ADVANCE_REPAYMENT,
    value: '3',
    descriptionTitle: 'What is an Interest in Advance Repayment?',
    description: [
      'Allows the applicant(s) to make lump sum interest only payments in advance.',
      'May serve a tax purpose. Has the applicant(s) sought tax advice?',
      'May be discounts on interest rate.',
    ],
    list: [
      `Rate must be fixed and all the risks set out above in relation to 'Fixed Interest' rate are applicable. Please explain each of these risks to the applicant(s).`,
      'Explain any different risks applicable to specific lender products (e.g. limitations on refunds for interest paid in advance).',
    ],
  },
  [`${QUESTION_IDS[REPAYMENT_PREFERENCES][0]}-4`]: {
    name: LOAN_OPTIONS_LABELS[LINE_OF_CREDIT_REPAYMENT],
    value: '4',
    descriptionTitle: 'What is a Line of Credit?',
    description: [
      'Flexibility of revolving line of credit allows applicant(s) to draw to an approved limit from time to time with only an obligation to pay monthly interest and otherwise flexibility of repayment amounts.',
    ],
    list: [
      'Higher interest rates may apply to a line of credit as compared to a principal and interest loan.',
      'Not paying off principal may result in more interest being paid over the loan term.',
      'Usually no formal repayment structure exists to the facility and so financial discipline is required to repay the loan.',
      'If lender has discretion to reduce or cancel the limit, applicant(s) may be required to pay off the amount owing at any time and would need a plan for doing so.',
    ],
  },
  [`${QUESTION_IDS[FEATURE_PREFERENCES][0]}-1`]: {
    name: LOAN_OPTIONS_LABELS[OFFSET_ACCOUNT_FEATURE],
    value: '1',
    descriptionTitle: 'What is an Offset Account?',
    description: [
      'Allows applicant(s) to link a savings account in their name to a loan account to reduce amount of interest payable under loan.',
      'Will only be of benefit where the applicant expects to have sufficient funds in the offset account so that the interest savings on the loan will exceed the additional costs related to the offset account.',
    ],
    list: [
      'Feature may not be available when the loan is on a fixed rate.',
      'May only be a partial interest rate offset.',
      'Fees may apply.',
      'Explain any different risks applicable to specific lender products, including a higher interest rate on the loan.',
    ],
  },
  [`${QUESTION_IDS[FEATURE_PREFERENCES][0]}-2`]: {
    name: LOAN_OPTIONS_LABELS[REDRAW_FEATURE],
    value: '2',
    descriptionTitle: 'What is a Redraw?',
    description: [
      'Allows applicant(s) to access extra  repayments that they made additional to their required minimum repayments.',
    ],
    list: [
      'Lender may charge fees for each redraw.',
      `Each redraw may be subject to the lender's discretion.`,
    ],
  },
};
