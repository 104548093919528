import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import employmentActions from 'actions/employmentActions';
import UIActions from 'actions/UIActions';
import { logEvent, EVENTS } from 'lib/amplitude';

import * as employmentSelectors from 'selectors/employmentSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as advisorSelectors from 'selectors/advisorSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import EmploymentForm from 'components/PopupForms/EmploymentForm';
import { EMPLOYMENTS_SLUG } from 'constants/applyData';

class EmploymentPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    hasBluestoneOnlineApplicationFlag: PropTypes.bool.isRequired,
    NZBNLoading: PropTypes.bool.isRequired,
  };

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} job`;
    }
    return working.id ? 'Edit your job' : 'Add a job';
  }

  value = () => undefined;

  logSaveEvent() {
    logEvent(EVENTS.SAVE_HISTORY, { section: EMPLOYMENTS_SLUG });
  }

  logSaveAndAddEvent() {
    logEvent(EVENTS.SAVE_AND_ADD_HISTORY, { section: EMPLOYMENTS_SLUG });
  }

  logRemoveEvent() {
    logEvent(EVENTS.REMOVE_HISTORY, { section: EMPLOYMENTS_SLUG });
  }

  renderForm() {
    const {
      isLocked,
      working,
      clientIdOptionsForNonsharable,
      hasBluestoneOnlineApplicationFlag,
      NZBNLoading,
    } = this.props;

    return (
      <EmploymentForm
        key={`employmentPopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        clientIdOptionsForNonsharable={clientIdOptionsForNonsharable}
        working={working}
        save={this.save}
        remove={this.remove}
        saveAndAddAnother={this.saveAndAddAnother}
        hasBluestoneOnlineApplicationFlag={hasBluestoneOnlineApplicationFlag}
        NZBNLoading={NZBNLoading}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: employmentSelectors.entity(state)(id),
    working: employmentSelectors.working(state)(id),
    NZBNLoading: employmentSelectors.NZBNLoading(state),
    clientIdOptionsForNonsharable: clientSelectors.clientIdOptionsForNonsharable(
      state,
    ),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    hasBluestoneOnlineApplicationFlag: advisorSelectors.hasBluestoneOnlineApplicationFlag(
      state,
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      load: employmentActions.loadEmployment,
      create: employmentActions.createEmployment,
      update: employmentActions.updateEmployment,
      delete: employmentActions.deleteEmployment,
      clearWorking: employmentActions.clearWorkingEmployment,
      clearErrors: UIActions.clearAsyncRequestErrors,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(EmploymentPopup),
);
