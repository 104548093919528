import amplitude from 'amplitude-js';

export const EVENTS = {
  OPEN_SECTION: 'Open section',
  CLOSE_SECTION: 'Close section',
  SAVE_AND_CLOSE: 'Save and close',
  EDIT_INFORMATION: 'Edit information',
  START_BANK_CONNECT: 'Start bank connect',
  CONNECT_WITH_BANK: 'Connect with bank connect',
  COMPLETE_BANK_CONNECT: 'Complete connection with bank connect',
  CLOSE_CONNECTION_WITH_BANK: 'Close connection with bank connect',
  LOG_OUT: 'Log out',
  ADD_COAPPLICANT: 'Add co-applicant',
  ADD_PROPERTY: 'Add a property',
  ADD_FINANCIALS: 'Add financials',
  SAVE_FINANCIALS: 'Save financials',
  SAVE_AND_ADD_FINANCIALS: 'Save and add financials',
  REMOVE_FINANCIALS: 'Remove financials',
  ADD_HISTORY: 'Add history',
  SAVE_HISTORY: 'Save history',
  SAVE_AND_ADD_HISTORY: 'Save and add history',
  REMOVE_HISTORY: 'Remove history',
  ADD_DOCUMENTS: 'Add documents',
  SAVE_DOCUMENTS: 'Save documents',
  EDIT_DOCUMENTS: 'Edit documents',
  REMOVE_DOCUMENTS: 'Remove documents',
};

export const logEvent = (event, properties = {}) => {
  return amplitude.getInstance().logEvent(event, properties);
};

export const setUserId = (userId) => {
  return amplitude.getInstance().setUserId(userId);
};

export default () => {
  return amplitude
    .getInstance()
    .init(window.LM_CONFIG.AMPLITUDE_API_KEY, null, { saveEvents: false });
};
