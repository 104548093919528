import { createSelector } from 'reselect';
import _ from 'lodash';
import { formatCurrency, formatCurrencyPm } from 'lib/intlFormatters';

export const collections = (state) => state.bankConnectCollection.collections;
export const accounts = (state) => state.bankConnectCollection.accounts;
export const recurringExpenseItems = (state) =>
  state.bankConnectCollection.recurringExpenseItems;

export const collection = createSelector(collections, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);

export const collectionsUpdatedAt = createSelector(collections, (a) => {
  // eslint-disable-next-line unicorn/explicit-length-check
  if (!a.length) {
    return null;
  }
  return a[a.length - 1].createdDate;
});

export const duplicateCollection = createSelector(
  collection,
  collections,
  (a, b) =>
    _.memoize((collectionId) => {
      const newCollection = a(collectionId);
      return b.find(
        (e) =>
          e.id !== collectionId &&
          e.institutionName === newCollection.institutionName &&
          e.customerName === newCollection.customerName,
      );
    }),
);

export const accountsByCollectionId = createSelector(accounts, (a) =>
  _.memoize((collectionId) =>
    a.filter((acc) => acc.collectionId === collectionId),
  ),
);

export const collectionsWithAccounts = createSelector(
  collections,
  accountsByCollectionId,
  (a, b) =>
    a.map((c) => {
      return { ...c, accounts: b(c.id) };
    }),
);

export const recurringExpenseItemsByTypeId = createSelector(
  recurringExpenseItems,
  (a) =>
    _.memoize((typeId) =>
      _.orderBy(
        a.filter((e) => e.expenseTypeId === typeId),
        ['total'],
        ['desc'],
      ),
    ),
);

export const recurringExpenseItemsByTypeIdMerged = createSelector(
  recurringExpenseItemsByTypeId,
  (a) =>
    _.memoize((typeId) => {
      const mapped = a(typeId).reduce((acc, cur) => {
        const existing = acc[cur.description];
        acc[cur.description] = existing
          ? {
              ...existing,
              total: existing.total + cur.total,
              numberOfTransactions:
                existing.numberOfTransactions + cur.numberOfTransactions,
              monthlyAverage: existing.monthlyAverage + cur.monthlyAverage,
            }
          : cur;
        return acc;
      }, {});
      return _.orderBy(Object.values(mapped), ['total'], ['desc']);
    }),
);

export const totalExpenseByTypeId = createSelector(collections, (a) => {
  const result = {};

  a.forEach((c) => {
    _.forEach(c.expenseSummaryData, (data, typeId) => {
      if (result[typeId]) {
        result[typeId] += data.total;
      } else {
        result[typeId] = data.total;
      }
    });
  });

  return result;
});

export const totalExpense = createSelector(totalExpenseByTypeId, (a) =>
  _.memoize((typeId) => a[typeId]),
);

export const recurringExpenseItemsMetadata = createSelector(
  recurringExpenseItemsByTypeId,
  totalExpense,
  (a, b) =>
    _.memoize((typeId) => {
      const items = a(typeId);
      const itemsExist = !!items.length;
      if (!itemsExist) {
        return { itemsExist };
      }
      const totalExpensePm = b(typeId) / 6;
      const totalRecurringPm = _.sumBy(items, 'total') / 6;
      return {
        itemsExist,
        expenseRange: (intl) => {
          if (totalRecurringPm === totalExpensePm) {
            return formatCurrencyPm(intl)(totalExpensePm);
          }
          return `${formatCurrency(intl)(
            totalRecurringPm,
          )} - ${formatCurrencyPm(intl)(totalExpensePm)}`;
        },
      };
    }),
);
