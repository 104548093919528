import React from 'react';
import {
  PURPOSE_FIRST_HOME,
  PURPOSE_NEXT_HOME,
  PURPOSE_COMMERCIAL,
  PURPOSE_INDUSTRIAL,
  PURPOSE_INVESTMENT,
} from 'shared/constants/myCRMTypes/applications';
import {
  BORROWER,
  GUARANTOR,
  PERSON,
  PARTNER_OR_SPOUSE,
  COMPANY_OR_TRUST,
} from 'constants/options';
import { SECTIONS_TO_VIEW_SERVICEABILITY } from 'shared/constants/goalDashboard';
import GoalPreferencesModal from 'containers/goal/GoalPreferencesModal/GoalPreferencesModal';

export const getIconFromLoanPurpose = (purpose = '') => {
  const primaryPurpose = purpose.split(', ')[0];
  if (primaryPurpose.includes('Home')) {
    return 'sl-custom-house-2';
  }
  const buildingPurposes = ['Investment Property', 'Commercial', 'Industrial'];
  if (buildingPurposes.includes(primaryPurpose)) {
    return 'sl-custom-building-3';
  }
  return 'sl-custom-dollar-bag';
};

export const getLoanPurpose = (purpose, isOpportunity) => {
  if (!purpose || typeof purpose !== 'string') {
    return isOpportunity ? 'Opportunity' : 'Loan Application';
  }
  const purposeList = purpose.split(', ');
  if (purposeList.length <= 3 && purpose.length <= 50) {
    return purpose;
  }
  const shortPurpose = purposeList.slice(0, 3).join(', ');
  return `${
    shortPurpose.length > 50 ? shortPurpose.slice(0, 50) : shortPurpose
  }...`;
};

export const getCustomMenu = (
  active,
  applications,
  swichGoalLoanApp,
  showPreferencesModal,
) => {
  const items = applications.map((application) => ({
    applicationId: application.id,
    title: getLoanPurpose(application.purpose, application.isOpportunity),
    description: application.clientName,
    icon: getIconFromLoanPurpose(application.purpose),
    onClick:
      active === application.id
        ? () => {}
        : () => swichGoalLoanApp(application.id),
  }));
  items.push({
    applicationId: null,
    title: 'Goal Setter Preferences',
    description: '',
    icon: 'sl-custom-setting-adjustment',
    onClick: showPreferencesModal,
  });
  return {
    theme: 'customMenu',
    items,
  };
};

export const mapWithContactId = (list = [], contactId) => {
  return list.map((i) => ({ ...i, contactId }));
};

export const goalPrimaryPurpose = (goals = {}) => {
  const primaryPurpose = Object.keys(goals).find((key) => !!goals[key]);
  if (!primaryPurpose) {
    return null;
  }
  switch (Number(primaryPurpose)) {
    case PURPOSE_FIRST_HOME:
    case PURPOSE_NEXT_HOME:
      return 'Residential';
    case PURPOSE_COMMERCIAL:
    case PURPOSE_INDUSTRIAL:
    case PURPOSE_INVESTMENT:
      return 'Investment';
    default:
      return 'Refinance';
  }
};

// TODO Remove this if isGuarantor is specified and replace with !isGuarantor order
export const sortClientsByRole = (client = {}) =>
  [BORROWER, GUARANTOR].indexOf(client.role);
export const sortClientsByType = (client = {}) =>
  [PERSON, PARTNER_OR_SPOUSE, COMPANY_OR_TRUST].indexOf(client.type);

export const getImportantDateQs = (isImportantDatesEnabled, applicant) => {
  const questions = [];
  if (isImportantDatesEnabled) {
    questions.push('hasImportantDates');
    if (applicant.hasImportantDates) {
      questions.push('hasSettlementDate');
      applicant.hasSettlementDate && questions.push('settlementDate');
      questions.push('hasFinanceDate');
      applicant.hasFinanceDate && questions.push('financeDate');
      questions.push('hasAuctionDate');
      applicant.hasAuctionDate && questions.push('auctionDate');
    }
  } else {
    questions.push('hasSettlementDate');
    applicant.hasSettlementDate && questions.push('settlementDate');
  }
  return questions;
};

export const showPreferencesModal = (modal, value, update) => () => {
  const onSave = (v) => {
    update(v);
    modal.hideModal();
  };
  modal.showModal({
    title: 'Goal Setter Preferences',
    content: (
      <GoalPreferencesModal
        value={value}
        onSave={onSave}
        onClose={() => modal.hideModal()}
      />
    ),
    closeOnOverlay: false,
    theme: 'halfWidth',
  });
};

export const canViewServiceabilityChecker = (obj) =>
  !!(obj && SECTIONS_TO_VIEW_SERVICEABILITY.every((s) => obj[s]));
