import locale from 'config/locale';

export const REQUIRED = 'required';
export const BUTTON_REQUIRED = 'buttonRequired';
export const POSTCODE = 'postcode';
export const EMAIL = 'email';
export const PHONE_NUMBER = 'phoneNumber';
export const PHONE_NUMBER_INTERNATIONAL = 'phoneNumberInternational';
export const DATE = 'date';
export const DATE_NOT_REQUIRED = 'dateNotRequired';
export const MONTH_YEAR = 'monthYear';
export const MOBILE = 'mobile';
export const MOBILE_INTERNATIONAL = 'mobileInternational';
export const OKTA_LOGIN_TOKEN_REQUIRED = 'oktaLoginTokenRequired';
export const LOCALITY = 'locality';
export const OPTIONAL_AUTOCOMPLETE = 'optionalAutocomplete';
export const REQUIRED_ADDRESS = 'requiredAddress';
export const MULTIPLE_CHOICE_REQUIRED = 'multipleChoiceRequired';
export const LESS_THAN_PROPERTY_VALUE = 'depositLessThanPropValue';
export const PROPERTY = 'property';
export const GREATER_THAN_ZERO = 'greaterThanZero';
export const GREATER_THAN_OR_EQL_TO_ZERO = 'greaterThanOrEqlToZero';
export const AT_LEAST_TWENTY_THOUSAND = 'atLestTwentyThousand';
export const NO_ERROR = null;
export const VALUE_EXISTS = 'valueExists';
export const IN_THE_PAST = 'inThePast';
export const IN_THE_FUTURE = 'inTheFuture';
export const GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR =
  'greaterThanEmploymentStartMonthYear';
export const INCOMPLETE_FORM = 'incompleteForm';
export const REQUIRED_FORMATTED_ADDRESS = 'requiredFormattedAddress';
export const INVALID_NZBN = 'invalidNzbn';
export const INVALID_NZBN_STRUCTURE = 'invalidNZBNStructure';
export const INVALID_NZBN_LENGTH = 'invalidNZBNLength';
export const PASSWORD = 'password';
export const PASSWORD_MATCH = 'passwordMatch';
export const OKTA_RECOVERY_TOKEN = 'resetPasswordCode';

export const ERROR_MESSAGES = {
  [REQUIRED]: 'Please provide an answer',
  [BUTTON_REQUIRED]: 'Please select one',
  [POSTCODE]: 'Please enter your 4-digit postcode',
  [EMAIL]: 'Please re-enter your email address',
  [MOBILE]: 'Please enter a valid AU or NZ mobile number',
  [MOBILE_INTERNATIONAL]: 'Please enter a valid mobile number',
  [PHONE_NUMBER]: 'Please enter a phone number longer than 8 digits',
  [PHONE_NUMBER_INTERNATIONAL]: 'Please enter a valid phone number',
  [LOCALITY]: locale.isAU
    ? 'Please try again - Enter a valid suburb or postcode'
    : 'Please try again - Enter a valid suburb',
  [REQUIRED_ADDRESS]:
    'Start typing, then select your full address when it appears.',
  [MULTIPLE_CHOICE_REQUIRED]: 'Please select at least one',
  [OKTA_LOGIN_TOKEN_REQUIRED]: `Please enter 6-digit activation code`,
  [LESS_THAN_PROPERTY_VALUE]:
    'Your deposit is more than the value of your property',
  [PROPERTY]: 'Invalid Property',
  [GREATER_THAN_ZERO]:
    'That doesn’t look right… please enter an amount greater than zero',
  [GREATER_THAN_OR_EQL_TO_ZERO]:
    'That doesn’t look right… please enter an amount greater than or equal to zero',
  [AT_LEAST_TWENTY_THOUSAND]:
    'That doesn’t look right… please enter an amount greater than 20,000',
  [VALUE_EXISTS]: 'Oops...Current item exists.',
  [IN_THE_PAST]: 'Please select a date in the past',
  [IN_THE_FUTURE]: 'Please select a date in the future',
  [GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR]:
    'Please select a date after the start date',
  [DATE]: 'Please enter a date',
  [DATE_NOT_REQUIRED]: 'Please enter a date',
  [MONTH_YEAR]: 'Please enter a month and year',
  [INCOMPLETE_FORM]: `Oops! Please check you've answered everything.`,
  [INVALID_NZBN]:
    'NZBN entered is not in the NZBN Register. Please check and update accordingly.',
  [INVALID_NZBN_LENGTH]: 'NZBN must be 13 characters long',
  [INVALID_NZBN_STRUCTURE]: 'NZBN must start with "94"',
  [REQUIRED_FORMATTED_ADDRESS]:
    'Start typing, then select your full address when it appears.',
  [PASSWORD]: 'Passwords must be a minimum of 12 characters.',
  [PASSWORD_MATCH]: 'Passwords don’t match',
  [OKTA_RECOVERY_TOKEN]: 'Please enter the 6-digit code',
};
