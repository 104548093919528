export const POSITIVE_CHART_SPECTRUM = [
  '#007FD7',
  '#55B724',
  '#FF6119',
  '#F5882C',
  '#F1A839',
  '#EFC747',
  '#A2B86E',
  '#55A894',
  '#607D8B',
  '#105B78',
  '#0D3B55',
  '#003C55',
];

export const NEGATIVE_CHART_SPECTRUM = [
  '#D64627',
  '#007FD7',
  '#FF6119',
  '#F5882C',
  '#F1A839',
  '#EFC747',
  '#A2B86E',
  '#55A894',
  '#607D8B',
  '#105B78',
  '#0D3B55',
  '#003C55',
];

export const SERVICEABILITY_CHART_SPECTRUM = [
  '#007FD7',
  '#55B724',
  '#003C55',
  '#FF6119',
  '#F5882C',
  '#F1A839',
  '#EFC747',
  '#A2B86E',
  '#55A894',
  '#607D8B',
  '#105B78',
  '#0D3B55',
];
export const SUMMARY_SECTION_COLOUR = '#4a4a4a';
export const OTHER_SECTION_COLOUR = '#7D7E7F';
export const LM_BRAND_COLOR = '#00AAE5';
export const BYOB_REPLACE_COLOR = '#4A4A4A';
