import { createSelector } from 'reselect';
import _ from 'lodash';
import { convertFrequency } from 'shared/lib/numbrero';

import { YEARLY, MONTHLY } from 'shared/constants/options';
import { POSITIVE_CHART_SPECTRUM } from 'constants/colours';
import { isOrderlessEqual } from 'lib/utils/dataUtils';

import { isPrimaryOrPartnerClient } from 'selectors/clientSelectors';
import {
  categoryLabel,
  onlyIncomeTypes,
  incomeTypes,
} from 'selectors/incomeTypeSelectors';

export const workingIncomes = (state) => state.income.working;
export const isSupportedCategory = createSelector(
  onlyIncomeTypes,
  (a) => (category, type) => a[category] === type,
);

export const isSupportedSubCategory = createSelector(
  incomeTypes,
  (a) => (category, type) =>
    !!(
      a[category] &&
      a[category].length > 1 &&
      a[category].find((b) => b.value === `${type}`)
    ),
);

export const entities = createSelector(
  (state) => state.income.entities,
  isSupportedCategory,
  isSupportedSubCategory,
  (a, b, c) =>
    a.filter(
      (income) =>
        b(income.category, income.type) || c(income.category, income.type),
    ),
);

export const hasOldInformation = createSelector(entities, (a) =>
  a.some((b) => b.isOldData),
);

export const primaryContactIncomes = createSelector(
  entities,
  isPrimaryOrPartnerClient,
  (l, isPrimaryContact) =>
    l.filter(({ clientIds = [] }) => clientIds.some(isPrimaryContact)),
);

export const incomesGroupedByCategory = createSelector(
  primaryContactIncomes,
  (a) => _.groupBy(a, 'category'),
);

export const entity = createSelector(entities, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);

export const incomeByAssetOrPropertyId = createSelector(entities, (a) =>
  _.memoize((assetId) =>
    _.find(a, (e) => e.assetId === assetId || e.propertyId === assetId),
  ),
);

export const working = (state) => (id) => workingIncomes(state)[id];

export const workingIncomeByAssetOrPropertyId = createSelector(
  workingIncomes,
  (a) =>
    _.memoize(
      (assetId) =>
        _.find(
          a,
          (w) => w && (w.assetId === assetId || w.propertyId === assetId),
        ) || a.new,
    ),
);

export const clientIncomes = createSelector(entities, (a) => {
  return _.memoize(
    (clientIds) => {
      return a.filter((income) => {
        return isOrderlessEqual(income.clientIds, clientIds);
      });
    },
    (clientIds) => clientIds.join('-'),
  );
});

export const clientIncomesMonthlyTotal = createSelector(clientIncomes, (a) =>
  _.memoize(
    (clientIds) =>
      _.sumBy(a(clientIds), (v) =>
        convertFrequency(v.value, parseInt(v.frequency, 10), MONTHLY),
      ),
    (clientIds) => clientIds.join('-'),
  ),
);

export const hasOldClientInformation = createSelector(clientIncomes, (a) =>
  _.memoize((clientIds) => {
    const b = a(clientIds);
    return b.some((c) => c.isOldData);
  }),
);

export const totalIncomesMonthly = createSelector(primaryContactIncomes, (a) =>
  _.sumBy(a, (v) =>
    convertFrequency(v.value, parseInt(v.frequency, 10), MONTHLY),
  ),
);

export const totalAnnualIncome = createSelector(entities, (a) =>
  _.sumBy(a, (v) =>
    convertFrequency(v.value, parseInt(v.frequency, 10), YEARLY),
  ),
);

export const chartData = createSelector(
  incomesGroupedByCategory,
  categoryLabel,
  (a, b) =>
    _.map(a, (data, category) => ({
      label: b(category),
      data: data.map((v) =>
        convertFrequency(v.value, parseInt(v.frequency, 10), MONTHLY),
      ),
      colour:
        POSITIVE_CHART_SPECTRUM[
          (parseInt(category, 10) + 1) % POSITIVE_CHART_SPECTRUM.length
        ],
    })),
);
