import React from 'react';
import PropTypes from 'prop-types';

import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';

import styles from './SimpleHeader.css';

const SimpleHeader = ({ next, message, timeToLiveMs, delay }) => (
  <AnimationWrapper key='simpleHeader' id='simpleHeader'>
    <AnimatedHeading onFinish={next} delay={delay}>
      <h1 data-duration={timeToLiveMs} className={styles.header}>
        {message}
      </h1>
    </AnimatedHeading>
  </AnimationWrapper>
);

SimpleHeader.propTypes = {
  next: PropTypes.func,
  message: PropTypes.string.isRequired,
  timeToLiveMs: PropTypes.number,
  delay: PropTypes.number,
};

SimpleHeader.defaultProps = {
  delay: 500,
  timeToLiveMs: 2200,
};

export default SimpleHeader;
