import { takeEvery, all, put, select, call } from 'redux-saga/effects';
import { arrayOfTruthyKeys } from 'shared/lib/utils';
import loanAmountCalculator from 'shared/lib/loanAmountCalculator';
import { convertToPercentage } from 'lib/utils/numberUtils';
import { valuesOnTargetKey } from 'lib/utils/dataUtils';
import { loanUseForScenario } from 'lib/loanParamsBuilder';
import { isGroupingProducts } from 'lib/compareHelper';
import { LOAN_FEATURES_COMPARE_PAGE } from 'constants/loanFeatures';
import {
  DEFAULT_EXISTING_PROPERTY_ID,
  DEFAULT_LOAN_YEAR,
  DEFAULT_LVR_VALUE,
  DEFAULT_CURRENT_INTEREST_RATE,
} from 'shared/constants/defaults';
import lenders from 'shared/lib/lenderHelper';

import {
  REQUEST_PRODUCTS,
  REQUEST_MORE_PRODUCTS,
  REQUEST_PRODUCT_DETAIL,
  REQUEST_SORTED_PRODUCTS,
  REQUEST_LENDER_PRODUCTS,
  REQUEST_FEATURED_PRODUCTS,
  REQUEST_REFINANCE_PRODUCTS,
} from 'actions/productsActionTypes';

import * as fundingSelectors from 'selectors/fundingSelectors';
import { accreditedLenders } from 'selectors/goalLoanAppInfoSelectors';
import {
  advisor,
  scenario as getScenario,
  getLoanPurposeProductType,
  getCurrentInterestRate,
} from 'selectors/scenarioSelectors';
import { getInterestProductParameters } from 'selectors/interestSavingSelectors';
import { monitorSpinnerRequest } from 'lib/sagaHelpers';

import * as productsApi from 'services/productsApi';
import { getUserInfo } from 'services/usersApi';
import productsActions from 'actions/productsActions';
import structureActions from 'actions/structureActions';

import { fetchAccreditedLenders } from 'sagas/lenderSagas';

import { featureFlags } from 'lib/rollout';

const getWorkingStructure = (state) => state.structure.working;
const getAggregator = (state) => state.aggregator.aggregator;

export const DEFAULT_LVR_PERCENT = convertToPercentage(DEFAULT_LVR_VALUE);

export function* filterProducts(products) {
  if (featureFlags.lendersByAccreditation.isEnabled()) {
    const currentAdvisor = yield select(advisor);
    let brokerId = currentAdvisor.familyId;
    if (!brokerId) {
      const {
        application: { applicants },
        clientId,
      } = yield call(getUserInfo, false);
      const primaryApplicant = applicants.find(
        (applicant) => applicant.clientId === clientId,
      );
      if (primaryApplicant.brokerId) {
        brokerId = primaryApplicant.brokerId;
      } else {
        return products;
      }
    }
    yield call(fetchAccreditedLenders, brokerId);
    const accredited = yield select(accreditedLenders);
    const productList = products.productList.filter(
      (p) => !!accredited.find((l) => l.lenderId === p.lender.id),
    );
    return { productList };
  }
  return products;
}

export function* callProductsApi(appendResult) {
  try {
    const requestValues = yield select(getWorkingStructure);
    const request = {
      ...requestValues,
      productDetail: null,
      interestType: arrayOfTruthyKeys(requestValues.interestType).join(),
      features: valuesOnTargetKey(
        arrayOfTruthyKeys(requestValues.features),
        LOAN_FEATURES_COMPARE_PAGE,
        'searchParam',
      ).join(),
      isGrouping: isGroupingProducts(requestValues.selectedLenderIds),
      lenders:
        requestValues.selectedLenderIds.join() ||
        lenders.userPanel.map((l) => l.lenderId).join(),
    };
    const result = yield productsApi.find(request);
    const products = yield call(filterProducts, result);
    yield !appendResult
      ? put(productsActions.setProducts(products))
      : put(productsActions.appendProducts(products));
  } catch (error) {
    console.log('products setting failed!', error);
    if (!appendResult) {
      put(productsActions.setProducts([]));
    }
  }
}

export function* fetchProductDetail({ payload: { productId } }) {
  try {
    const { loanTerm, loanAmount, repaymentType } = yield select(
      getWorkingStructure,
    );
    const product = yield productsApi.getDetail(
      productId,
      loanAmount,
      loanTerm,
      repaymentType,
    );
    yield put(productsActions.setProductDetail(product));
  } catch (error) {
    console.error(error);
  }
}

export function* fetchProducts() {
  yield callProductsApi();
}

export function* fetchMoreProducts({ payload }) {
  yield put(productsActions.setProductsLoadingMore(true));
  yield put(structureActions.updateWorkingStructure({ page: payload }));
  yield call(callProductsApi, true);
  yield put(productsActions.setProductsLoadingMore(false));
}

export function* fetchSortedProducts({ payload }) {
  yield put(structureActions.updateWorkingStructure({ orderField: payload }));
  yield callProductsApi();
}

export function* fetchLenderProducts({ payload: { lenderId, page } }) {
  yield put(
    structureActions.updateWorkingStructure({
      selectedLenderIds: [lenderId],
      page,
    }),
  );
  yield callProductsApi();
}

export function* fetchFeaturedProducts({ payload: { lenderId, lenderCode } }) {
  try {
    const scenario = yield select(getScenario);
    const loanAmount = loanAmountCalculator(scenario);
    const productType = loanUseForScenario(scenario);
    const lvr = convertToPercentage(DEFAULT_LVR_VALUE);
    const products = yield call(
      productsApi.getFeaturedProducts,
      lenderId,
      loanAmount,
      productType,
      lvr,
    );

    yield put(productsActions.setFeaturedProducts({ lenderCode, products }));
  } catch (error) {
    yield put(
      productsActions.setFeaturedProducts({ lenderCode, products: {} }),
    );
    console.log(error);
  }
}

export function* fetchRefinanceProducts({
  payload: { currentInterestRate, mortgageAmount },
}) {
  let rate = currentInterestRate;
  let loanAmount = mortgageAmount;
  if (!rate) {
    rate = yield select(getCurrentInterestRate);
    if (!rate) {
      rate = DEFAULT_CURRENT_INTEREST_RATE;
    }
  }
  if (!loanAmount) {
    loanAmount = yield select(fundingSelectors.totalLoanRequired);
  }

  const currentParams = yield select(getInterestProductParameters);
  // TODO: Get loan purpose from apply data
  const productType = yield select(
    getLoanPurposeProductType,
    DEFAULT_EXISTING_PROPERTY_ID,
  );

  if (
    currentParams.mortgageAmount !== loanAmount ||
    currentParams.productType !== productType ||
    currentParams.currentParams !== rate
  ) {
    yield put(structureActions.updateWorkingStructure({ loanAmount }));
    const aggregator = yield select(getAggregator);

    const result = yield call(productsApi.getInterest, {
      loanAmount,
      rate,
      lvr: DEFAULT_LVR_PERCENT,
      loanTerm: DEFAULT_LOAN_YEAR,
      productType,
    });

    yield put(
      productsActions.setInterestProducts({
        ...result,
        params: {
          currentInterestRate: rate,
          mortgageAmount: loanAmount,
          productType,
          aggregatorId: aggregator.id,
        },
      }),
    );
  }
}

export default function* productsSagas() {
  yield all([
    monitorSpinnerRequest(takeEvery, REQUEST_PRODUCTS, fetchProducts),
    takeEvery(REQUEST_MORE_PRODUCTS, fetchMoreProducts),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_PRODUCT_DETAIL,
      fetchProductDetail,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_SORTED_PRODUCTS,
      fetchSortedProducts,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_LENDER_PRODUCTS,
      fetchLenderProducts,
    ),
    takeEvery(REQUEST_FEATURED_PRODUCTS, fetchFeaturedProducts),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_REFINANCE_PRODUCTS,
      fetchRefinanceProducts,
    ),
  ]);
}
