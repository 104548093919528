import { takeEvery, all, put, select, call } from 'redux-saga/effects';
import locale from 'config/locale';

import {
  REQUEST_MAX_BORROW,
  REQUEST_QUICK_LMI,
  REQUEST_LISTING_INFO,
} from 'actions/otherActionTypes';
import otherActions from 'actions/otherActions';

import {
  monitorSpinnerRequest,
  monitorSingleAsyncProgress,
} from 'lib/sagaHelpers.js';
import { lvrCalculator } from 'shared/lib/utils';
import { featureFlags } from 'lib/rollout';

import { maximumLoanAmount } from 'selectors/eligibilitySelectors';
import { adultClientsOnContact } from 'selectors/clientSelectors';
import { getContactBrokerId } from 'selectors/contactSelectors';
import { readLoanApplication } from 'sagas/loanApplicationSagas';
import { updateGoalContactServiceabilityMaxBorrows } from 'sagas/goal/goalLoanApplicationSagas';
import { getMaxBorrow } from 'services/serviceabilityApi';
import { getQuickLMI, getListingInfo } from 'services/otherApi';

import { DEFAULT_LOAN_YEAR } from 'shared/constants/defaults';

function* fetchMaxBorrow({ payload: { isGoalSetter, contactId, loanAppId } }) {
  !isGoalSetter && (yield call(readLoanApplication));

  const clientsOnContactSelector = yield select(adultClientsOnContact);
  const clients = yield call(clientsOnContactSelector, contactId);
  const clientIds = clients.map((c) => c.id).join(',');
  const brokerId = yield select(getContactBrokerId);
  const accreditedOnly =
    isGoalSetter && featureFlags.lendersByAccreditation.isEnabled();

  const result = yield call(getMaxBorrow, {
    loanAppId,
    clientIds,
    familyId: contactId,
    countryCode: locale.countryCode,
    brokerId,
    accreditedOnly,
    nzNewServiceability: featureFlags.nzNewServiceability.isEnabled(),
  });
  yield put(otherActions.setMaxBorrow(result));

  if (isGoalSetter) {
    const getMaximumLoanAmount = yield select(maximumLoanAmount);
    yield call(updateGoalContactServiceabilityMaxBorrows, {
      contactId,
      maximumLoanAmount: getMaximumLoanAmount,
    });
  }
}

export function* fetchQuickLmi({
  payload: {
    loanAmount,
    propertyValue,
    isFirstHomeBuyer,
    state,
    ownerOccupied,
  },
}) {
  const lvr = lvrCalculator(loanAmount, propertyValue) || 0;
  if (lvr > 0.8 && lvr <= 1) {
    const result = yield call(getQuickLMI, {
      loanAmount,
      propertyValue,
      isFirstHomeBuyer: isFirstHomeBuyer || false,
      state,
      loanTerm: DEFAULT_LOAN_YEAR,
      ownerOccupied,
    });
    yield put(otherActions.setQuickLmi(result));
  } else {
    yield put(otherActions.setQuickLmi({ average: 0 }));
  }
}

export function* fetchRayWhiteListingInfo({ payload }) {
  const result = yield call(getListingInfo, payload);
  yield put(otherActions.setListingInfo(result));
}

export default function* serviceabilitySagas() {
  yield all([
    monitorSpinnerRequest(takeEvery, REQUEST_QUICK_LMI, fetchQuickLmi),
    monitorSpinnerRequest(takeEvery, REQUEST_MAX_BORROW, fetchMaxBorrow),
    monitorSingleAsyncProgress(
      takeEvery,
      REQUEST_LISTING_INFO,
      fetchRayWhiteListingInfo,
    ),
  ]);
}
