import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { REQUIRED } from 'constants/validators';
import applicationActions from 'actions/loanApplicationActions';
import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import applySection from 'hocs/applySection';
import { YES_NO_OPTIONS } from 'constants/options';
import Question from 'components/Question/Question';
import RadioButtonList from 'components/ButtonList/RadioButtonList';

import { accordionPropTypes } from 'types/customPropTypes';
import Questions from 'lib/Questions';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import * as applicationSelectors from 'selectors/applicationSelectors';

import Textarea from 'components/Textarea/Textarea';
import Form from 'components/Form/Form';

export const messages = defineMessages({
  title: {
    id: 'ForeseeableChangesApply.foreseeableChanges',
    defaultMessage: 'Foreseeable Changes',
  },
  horizon: {
    id: 'ForeseeableChangesApply.horizon',
    defaultMessage: 'Do you see any changes on the horizon?',
  },
  questionsToAnswer: {
    id: 'ForeseeableChangesApply.questionsToAnswer',
    defaultMessage:
      '{questionsLeft, plural, =0 {All questions answered} one {1 question left} other {{questionsLeft, number} questions to answer}}',
  },
  primaryApplicantForeseesChange: {
    id: 'ForeseeableChangesApply.primaryApplicantForeseesChange',
    defaultMessage:
      'Do you see any changes to your employment, income or expenses that would make it hard to pay off a loan?',
  },
  partnerForeseesChange: {
    id: 'ForeseeableChangesApply.partnerForeseesChange',
    defaultMessage:
      'Does your partner see any changes to their employment, income or expenses that would make it hard to pay off a loan?',
  },
  details: {
    id: 'ForeseeableChangesApply.details',
    defaultMessage: 'Details',
  },
});

const questionSet = (props) => {
  const questions = new Questions();
  questions.addBranch(['primaryApplicantForeseesChange', REQUIRED]);
  if (props.primaryApplicantForeseesChange) {
    questions.add(['primaryApplicantForeseeableChangeDetails', REQUIRED]);
  }
  if (props.askPartner) {
    questions.addBranch(['partnerForeseesChange', REQUIRED]);
  }
  if (props.partnerForeseesChange) {
    questions.add(['partnerForeseeableChangeDetails', REQUIRED]);
  }
  questions.add('linkButton');
  return questions.arrayOfQuestions();
};

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    questionsLeft,
  } = props;

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages.questionsToAnswer, {
      questionsLeft,
    }),
    headerDescription: formatMessage(messages.horizon),
  };
};

export const nextButtonProps = (props) => ({
  disabled: !props.accordionProps.isLocked && !props.formCompleted,
});

export class ForeseeableChangesApply extends Component {
  static displayName = 'ForeseeableChangesApply';

  static propTypes = {
    intl: intlShape.isRequired,
    ...manageQuestionsPropTypes,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    application: PropTypes.object.isRequired,
    primaryApplicantForeseeableChange: PropTypes.string,
    setForeseeableChangeDetails: PropTypes.func.isRequired,
    saveAndNext: PropTypes.func.isRequired,
  };

  static questionsToAsk = questionSet;

  static forceVisibility = true;

  setForeseeableChangeDetails = (clientId) => (value) => {
    this.props.setForeseeableChangeDetails({ clientId, value });
  };

  setForeseesChanges = (clientId) => (value) => {
    this.props.setForeseesChangesMetadata({ [clientId]: value });
    if (!value) {
      this.setForeseeableChangeDetails(clientId)('');
    }
  };

  render() {
    const {
      primaryApplicant,
      primaryApplicantsPartner,
      askPartner,
      primaryApplicantForeseesChange,
      primaryApplicantForeseeableChangeDetails,
      partnerForeseesChange,
      partnerForeseeableChangeDetails,
      questions,
      formCompleted,
      intl: { formatMessage },
      accordionProps: { isLocked },
      setCurrentQuestionTo,
      saveAndNext,
    } = this.props;

    return (
      <Form
        id='foreseeableChanges'
        onSubmit={saveAndNext}
        formCompleted={formCompleted}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <Question
          {...questions.primaryApplicantForeseesChange}
          label={formatMessage(messages.primaryApplicantForeseesChange)}
          direction='row'
          forceFocus={false}
        >
          <RadioButtonList
            action={this.setForeseesChanges(primaryApplicant.id)}
            items={YES_NO_OPTIONS}
            value={primaryApplicantForeseesChange}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.primaryApplicantForeseeableChangeDetails}
          label={formatMessage(messages.details)}
        >
          <Textarea
            action={this.setForeseeableChangeDetails(primaryApplicant.id)}
            value={primaryApplicantForeseeableChangeDetails}
            disabled={isLocked}
          />
        </Question>
        {askPartner && (
          <div>
            <Question
              {...questions.partnerForeseesChange}
              label={formatMessage(messages.partnerForeseesChange)}
              direction='row'
              forceTopStyle
            >
              <RadioButtonList
                action={this.setForeseesChanges(primaryApplicantsPartner.id)}
                items={YES_NO_OPTIONS}
                value={partnerForeseesChange}
                disabled={isLocked}
              />
            </Question>
            <Question
              {...questions.partnerForeseeableChangeDetails}
              label={formatMessage(messages.details)}
            >
              <Textarea
                action={this.setForeseeableChangeDetails(
                  primaryApplicantsPartner.id,
                )}
                value={partnerForeseeableChangeDetails}
                disabled={isLocked}
              />
            </Question>
          </div>
        )}
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const primaryApplicantId = ownProps.primaryApplicant.id;
  const props = {
    application: applicationSelectors.workingApplication(state),
    primaryApplicantForeseesChange: applicationSelectors.clientForeseesChanges(
      state,
    )(primaryApplicantId),
    primaryApplicantForeseeableChangeDetails: applicationSelectors.clientForeseeableChangeDetails(
      state,
    )(primaryApplicantId),
    questionsLeft: applicationSelectors.getForeseeableChangeQuestionsLeft(
      state,
    ),
    askPartner: applicationSelectors.askPartnerForeseesChange(state),
  };
  if (props.askPartner) {
    const partner = ownProps.primaryApplicantsPartner;
    props.partnerForeseesChange = applicationSelectors.clientForeseesChanges(
      state,
    )(partner.id);
    props.partnerForeseeableChangeDetails = applicationSelectors.clientForeseeableChangeDetails(
      state,
    )(partner.id);
  }

  return props;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setForeseeableChangeDetails:
        applicationActions.setForeseeableChangeDetails,
      updateLoanApplication: applicationActions.updateLoanApplication,
      setForeseesChangesMetadata: applicationActions.setForeseesChangesMetadata,
    },
    dispatch,
  );
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    dispatchProps.updateLoanApplication(stateProps.application);
  },
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    applyOwnPropsChecker,
  )(
    manageQuestions(
      applySection({
        iconName: 'sl-custom-question-mark',
        furtherDecoration,
        nextButtonProps,
      })(ForeseeableChangesApply),
    ),
  ),
);
