import { EXPENSE_CATEGORY_TYPES_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export const getExpenseCategoryTypes = (version, countryId) => {
  return axiosWithAuth
    .get(`${EXPENSE_CATEGORY_TYPES_API_PATH}`, {
      params: { version, countryId },
    })
    .then(returnJSON);
};
