import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import classNames from 'classnames/bind';

import { generateUniqueID } from 'lib/utils/stringUtils';

import styles from './AccordionButton.css';

class AccordionButton extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { id, name, children } = this.props;
    const dropArrowStyles = classNames(
      styles.dropArrow,
      this.state.isExpanded ? 'mi-arrow-filled-up' : 'mi-arrow-filled-down',
    );
    const animationProps = {
      transitionName: 'fadeInAndOpen',
      component: 'div',
      transitionEnterTimeout: 320,
      transitionLeaveTimeout: 320,
    };

    return (
      <div id={generateUniqueID('Accordion', id)}>
        <div className={styles.summary} onClick={this.toggleExpanded}>
          <span className={styles.name}>{name}</span>
          <i className={dropArrowStyles} />
        </div>
        <CSSTransitionGroup {...animationProps}>
          {this.state.isExpanded && children}
        </CSSTransitionGroup>
      </div>
    );
  }
}

export default AccordionButton;
