import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import delay from 'hocs/delay';

import AnimatedComponent from 'components/AnimatedComponent/AnimatedComponent';

import styles from 'components/AnimatedHeading/AnimatedHeading.css';

const AnimatedHeading = ({
  onFinish,
  afterExitingAnimation,
  children,
  rootTransitionName,
  elementTransitionName,
  skipAllowed,
  onElementEnter,
  currentElementStyle,
  className,
}) => (
  <AnimatedComponent
    id={skipAllowed ? 'skipAllowedElement' : ''}
    rootTransitionName={rootTransitionName}
    elementTransitionName={elementTransitionName}
    elementStyle={styles.element}
    currentElementStyle={currentElementStyle}
    rootStyle={classNames(styles.root, className)}
    onFinish={onFinish}
    afterExitingAnimation={afterExitingAnimation}
    onElementEnter={onElementEnter}
  >
    {children}
  </AnimatedComponent>
);

AnimatedHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  onFinish: PropTypes.func,
  afterExitingAnimation: PropTypes.func,
  elementTransitionName: PropTypes.string,
  rootTransitionName: PropTypes.string,
  skipAllowed: PropTypes.bool,
  onElementEnter: PropTypes.func,
  currentElementStyle: PropTypes.string,
  className: PropTypes.string,
};

AnimatedHeading.defaultProps = {
  elementTransitionName: 'swipeLeft',
  rootTransitionName: 'swipeLeft',
  skipAllowed: true,
};

export default delay(AnimatedHeading);
