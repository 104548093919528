import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as UISelectors from 'selectors/UISelectors';

import LockedBanner from 'components/LockedBanner/LockedBanner';

import ApplicantApply from 'components/ApplicantApply/ApplicantApply';
import FamilyApply from 'components/FamilyApply/FamilyApply';
import CoapplicantsApply from 'components/CoapplicantsApply/CoapplicantsApply';
import SelectedLoanApply from 'components/SelectedLoanApply/SelectedLoanApply';
import AssetsApply from 'components/AssetsApply/AssetsApply';
import LiabilitiesApply from 'components/LiabilitiesApply/LiabilitiesApply';
import EmploymentsApply from 'components/EmploymentsApply/EmploymentsApply';
import GoalApply from 'components/GoalApply/GoalApply';
import IncomesApply from 'components/IncomesApply/IncomesApply';
import ExpensesApply from 'components/ExpensesApply/ExpensesApply';
import ForeseeableChangesApply from 'components/ForeseeableChangesApply/ForeseeableChangesApply';
import CreditHistoryApply from 'components/CreditHistoryApply/CreditHistoryApply';
import ResidenceApply from 'components/ResidenceApply/ResidenceApply';
import LoanAmountApply from 'components/LoanAmountApply/LoanAmountApply';
import PropertiesApply from 'components/PropertiesApply/PropertiesApply';
import ToPurchaseApply from 'components/ToPurchaseApply/ToPurchaseApply';
import AddressApply from 'components/AddressApply/AddressApply';
import DocumentsApply from 'components/DocumentsApply/DocumentsApply';
import ApplySummary from 'components/ApplySummary/ApplySummary';

import applyGoalMessages from 'constants/messages/ApplyGoal';
import {
  PROFILE_CATEGORIES,
  PROFILE_SECTIONS,
} from 'constants/profileSections';
import {
  APPLY_BASE_PATH,
  CURRENT_BANK_PATH,
  PREFERRED_LOAN_OPTIONS_PATH,
  LONG_TERM_PATH,
  TEAM_PATH,
} from 'shared/constants/paths';
import { TO_SLUG } from 'constants/applyData';

import locale from 'config/locale';
import { absolutePopup } from 'lib/utils/browserUtils';
import { featureFlags } from 'lib/rollout';
import { logEvent, EVENTS } from 'lib/amplitude';

const isAbsolutePopup = absolutePopup();

export function ApplyOnlineContentContainer(props) {
  const {
    isLocked,
    messages,
    formatMessage,
    isSharedApplication,
    primaryApplicant,
    primaryContact,
    primaryApplicantsPartner,
    setMetadata,
    metadata,
    updateLoanApplication,
    applicationId,
    requests,
    requestErrors,
    clearAsyncRequestErrors,
    setPageError,
    pushSpinner,
    popSpinner,
    section,
    isExact,
    hasOldInformationMap,
    startAnimationSequence,
    completedSections,
    nextSequence,
    showProgress,
    progress,
    push,
    urlPath,
    partial,
    loanAmountOn,
    isProfileSectionVisible,
    isProfileCategoryVisible,
    headingTip,
    lendersSectionText,
    loanOptionsSectionText,
    families,
    futurePlansCompleted,
    servicesCompleted,
    location,
    partialProfileSections,
  } = props;

  useEffect(() => {
    scrollIntoSection();
  }, [partialProfileSections]);

  useEffect(() => {
    if (!section) {
      return;
    }
    const profileSectionFromSlug = Object.keys(TO_SLUG).find(
      (k) => TO_SLUG[k] === section,
    );
    if (!isProfileSectionVisible(profileSectionFromSlug)) {
      push(`${APPLY_BASE_PATH}/${applicationId}`);
    }
  }, [section]);

  const saveApplicationMetadata = () =>
    updateLoanApplication({ id: applicationId, metadata });

  const goToLenders = () => {
    push(`${APPLY_BASE_PATH}/${applicationId}${CURRENT_BANK_PATH}`);
  };

  const goToLoanFeatures = () => {
    push(`${APPLY_BASE_PATH}/${applicationId}${PREFERRED_LOAN_OPTIONS_PATH}`);
  };

  const goToFuturePlans = (familyId) => () => {
    push(`${APPLY_BASE_PATH}/${applicationId}${LONG_TERM_PATH}/${familyId}`);
  };

  const goToServices = () => {
    push(`${APPLY_BASE_PATH}/${applicationId}${TEAM_PATH}`);
  };

  const makeMetadataProps = (...keys) => {
    return keys.reduce(
      (data, key) => {
        data[key] = metadata[key];
        return data;
      },
      {
        setMetadata,
        onSubmit: saveApplicationMetadata,
      },
    );
  };

  const makeAsyncUpdateProps = () => ({
    requestId: applicationId,
    requests,
    requestErrors,
    clearAsyncRequestErrors,
    setPageError,
    pushSpinner,
    popSpinner,
  });

  const getBaseAccordionProps = () => {
    return {
      nextSequence,
      isLocked,
      isShared: isSharedApplication,
      showProgress,
      progress,
    };
  };

  const openForm = (slug) => () => {
    logEvent(EVENTS.OPEN_SECTION, { section: slug });
    push(`${urlPath}/${slug}`);
  };
  const closeForm = () => {
    push(urlPath);
  };

  const makeAccordionSectionProps = (slug) => {
    const hasOldInformation = hasOldInformationMap[slug];
    return {
      // eslint-disable-next-line sonarjs/no-extra-arguments
      accordionProps: getBaseAccordionProps(slug),
      openAccordion: section,
      slug,
      open: openForm,
      close: closeForm,
      key: `${slug}-apply`,
      applicationId,
      startAnimationSequence,
      isCompleted: completedSections[slug],
      isExact: isAbsolutePopup ? isExact : undefined,
      isOpen: section === slug,
      hasOldInformation,
      warningMessage: hasOldInformation
        ? formatMessage(messages.hasOldInformation)
        : '',
      urlPath,
    };
  };

  const scrollIntoSection = () => {
    const { query: { section } = {} } = location;
    if (section !== '') {
      setTimeout(() => {
        const element = document.querySelector(`#SectionTile__${section}`);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  };

  const renderGoals = () =>
    !partial &&
    isProfileSectionVisible(PROFILE_SECTIONS.GOAL) && (
      <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.GOALS}>
        <h5>{formatMessage(messages.goals)}</h5>
        <GoalApply
          key='goal'
          {...makeAccordionSectionProps('goal')}
          {...makeAsyncUpdateProps()}
          messages={applyGoalMessages}
        />
      </div>
    );

  const renderApplicants = () => (
    <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.APPLICANTS}>
      <h5>{formatMessage(messages.applicants)}</h5>
      {isProfileSectionVisible(PROFILE_SECTIONS.APPLICANT) && (
        <ApplicantApply
          key='ApplicantApply'
          {...makeAccordionSectionProps('applicant')}
          {...makeAsyncUpdateProps()}
          primaryApplicant={primaryApplicant}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.FAMILY) && (
        <FamilyApply
          key='FamilyApply'
          {...makeAccordionSectionProps('family')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasHousehold')}
          primaryContact={primaryContact}
          primaryApplicant={primaryApplicant}
          primaryApplicantsPartner={primaryApplicantsPartner}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.COAPPLICANTS) && (
        <CoapplicantsApply
          key='CoapplicantsApply'
          {...makeAccordionSectionProps('coapplicants')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasCoapplicants')}
        />
      )}
    </div>
  );

  const renderProperties = () => (
    <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.PROPERTIES}>
      <h5>{formatMessage(messages.properties)}</h5>
      {isProfileSectionVisible(PROFILE_SECTIONS.RESIDENCE) && (
        <ResidenceApply
          key='ResidenceApply'
          {...makeAccordionSectionProps('residence')}
          primaryApplicant={primaryApplicant}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.TO_PURCHASE) && (
        <ToPurchaseApply
          key='ToPurchaseApply'
          {...makeAccordionSectionProps('to-purchase')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps(
            'lookingToBuyProperty',
            'lookingToBuyAnotherProperty',
            'hasDeposits',
          )}
          primaryApplicant={primaryApplicant}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.PROPERTIES) && (
        <PropertiesApply
          key='PropertiesApply'
          {...makeAsyncUpdateProps()}
          {...makeAccordionSectionProps('properties')}
          {...makeMetadataProps('ownOtherProperties')}
        />
      )}
    </div>
  );

  const renderFinancialPosition = () => (
    <div
      className='nestedRoundCorner'
      key={PROFILE_CATEGORIES.FINANCIAL_POSITION}
    >
      <h5>{formatMessage(messages.financialPosition)}</h5>
      {isProfileSectionVisible(PROFILE_SECTIONS.ASSETS) && (
        <AssetsApply
          key='AssetsApply'
          {...makeAccordionSectionProps('assets')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasAssets')}
          primaryApplicant={primaryApplicant}
          primaryApplicantsPartner={primaryApplicantsPartner}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.LIABILITIES) && (
        <LiabilitiesApply
          key='LiabilitiesApply'
          {...makeAccordionSectionProps('liabilities')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasLiabilities')}
          primaryApplicant={primaryApplicant}
          primaryApplicantsPartner={primaryApplicantsPartner}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.INCOMES) && (
        <IncomesApply
          key='IncomesApply'
          {...makeAccordionSectionProps('incomes')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasIncomes')}
          primaryApplicant={primaryApplicant}
          primaryApplicantsPartner={primaryApplicantsPartner}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.EXPENSES) && (
        <ExpensesApply
          key='ExpensesApply'
          {...makeAccordionSectionProps('expenses')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasExpenses')}
        />
      )}
    </div>
  );

  const renderHistory = () => (
    <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.HISTORY}>
      <h5>{formatMessage(messages.history)}</h5>
      {isProfileSectionVisible(PROFILE_SECTIONS.ADDRESSES) && (
        <AddressApply
          key='AddressApply'
          {...makeAccordionSectionProps('addresses')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasExpandedAddresses')}
          primaryApplicant={primaryApplicant}
          primaryApplicantsPartner={primaryApplicantsPartner}
        />
      )}
      {isProfileSectionVisible(PROFILE_SECTIONS.EMPLOYMENTS) && (
        <EmploymentsApply
          key='EmploymentsApply'
          {...makeAccordionSectionProps('employments')}
          {...makeAsyncUpdateProps()}
          {...makeMetadataProps('hasExpandedEmployments')}
          primaryApplicant={primaryApplicant}
          primaryApplicantsPartner={primaryApplicantsPartner}
        />
      )}
      {!partial &&
        isProfileSectionVisible(PROFILE_SECTIONS.FORESEEABLE_CHANGES) &&
        !featureFlags.disableMilliForeseeableChanges.isEnabled() &&
        !featureFlags.goalSetterFactFindMerge.isEnabled() &&
        !locale.isNZ && (
          <ForeseeableChangesApply
            key='ForeseeableChanges'
            {...makeAccordionSectionProps('foreseeable-changes')}
            {...makeAsyncUpdateProps()}
            primaryApplicant={primaryApplicant}
            primaryApplicantsPartner={primaryApplicantsPartner}
          />
        )}
      {isProfileSectionVisible(PROFILE_SECTIONS.CREDIT_HISTORY) && (
        <CreditHistoryApply
          key='CreditHistoryApply'
          {...makeAccordionSectionProps('credit-history')}
          {...makeAsyncUpdateProps()}
        />
      )}
    </div>
  );

  const renderLoanAmountApply = () =>
    (!isSharedApplication || loanAmountOn) &&
    !partial && (
      <div
        className='nestedRoundCorner'
        key={PROFILE_CATEGORIES.LOAN_AMOUNT_APPLY}
      >
        <h5>{formatMessage(messages.loanAmountApply)}</h5>
        <LoanAmountApply
          key='LoanAmountApply'
          {...makeAccordionSectionProps('loan-amount')}
        />
      </div>
    );

  const renderSupportingInfo = () =>
    isProfileSectionVisible(PROFILE_SECTIONS.DOCUMENTS) && (
      <div
        className='nestedRoundCorner'
        key={PROFILE_CATEGORIES.SUPPORTING_INFORMATION}
      >
        <h5>{formatMessage(messages.supportingInformation)}</h5>
        <DocumentsApply
          key='DocumentsApply'
          {...makeAccordionSectionProps('documents')}
        />
      </div>
    );

  const renderPreferences = () =>
    !partial && (
      <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.PREFERENCES}>
        <h5>{formatMessage(messages.preferences)}</h5>
        {isProfileSectionVisible(PROFILE_SECTIONS.LENDERS) && (
          <ApplySummary
            key='lenders'
            id='lenders'
            title={formatMessage(messages.lenders)}
            titleDescription={
              lendersSectionText || formatMessage(messages.lendersDescription)
            }
            iconName='sl-custom-bank-2'
            onClick={goToLenders}
            isCompleted={!!lendersSectionText}
            isLocked={isLocked}
          />
        )}
        {isProfileSectionVisible(PROFILE_SECTIONS.LOAN_FEATURES) && (
          <ApplySummary
            key='loanFeatures'
            id='loanFeatures'
            title={formatMessage(messages.loanFeatures)}
            titleDescription={
              loanOptionsSectionText ||
              formatMessage(messages.loanFeaturesDescription)
            }
            iconName='sl-custom-checklist'
            onClick={goToLoanFeatures}
            isCompleted={!!loanOptionsSectionText}
            isLocked={isLocked}
          />
        )}
      </div>
    );

  const renderLookingAhead = () =>
    !partial && (
      <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.LOOKING_AHEAD}>
        <h5>{formatMessage(messages.lookingAhead)}</h5>
        {isProfileSectionVisible(PROFILE_SECTIONS.FUTURE_PLANS) &&
          families.map((family) => (
            <ApplySummary
              key={`futurePlans-${family.contactId}`}
              id={`futurePlans-${family.contactId}`}
              title={formatMessage(messages.futurePlans, {
                familyName: family.familyFirstName,
              })}
              titleDescription={
                futurePlansCompleted[family.contactId]
                  ? formatMessage(messages.futurePlansCompletedDesc)
                  : formatMessage(messages.futurePlansDescription)
              }
              iconName='sl-custom-road'
              onClick={goToFuturePlans(family.contactId)}
              isCompleted={futurePlansCompleted[family.contactId]}
              isLocked={isLocked}
            />
          ))}
      </div>
    );

  const renderReferralsAndServices = () =>
    !partial && (
      <div className='nestedRoundCorner' key={PROFILE_CATEGORIES.SERVICES}>
        <h5>{formatMessage(messages.services)}</h5>
        {isProfileSectionVisible(PROFILE_SECTIONS.SERVICES) && (
          <ApplySummary
            key='services'
            id='services'
            title={formatMessage(messages.services)}
            titleDescription={formatMessage(messages.servicesDescription)}
            iconName='sl-custom-umbrella'
            onClick={goToServices}
            isCompleted={servicesCompleted}
            isLocked={isLocked}
          />
        )}
      </div>
    );

  const renderSectionCategories = (category) => {
    // THOUGHT: would it have been better if we create components per section? And would it be okay if these components are on this file?
    switch (category) {
      case PROFILE_CATEGORIES.GOALS:
        return renderGoals();
      case PROFILE_CATEGORIES.APPLICANTS:
        return renderApplicants();
      case PROFILE_CATEGORIES.PROPERTIES:
        return renderProperties();
      case PROFILE_CATEGORIES.FINANCIAL_POSITION:
        return renderFinancialPosition();
      case PROFILE_CATEGORIES.HISTORY:
        return renderHistory();
      case PROFILE_CATEGORIES.LOAN_AMOUNT_APPLY:
        return renderLoanAmountApply();
      case PROFILE_CATEGORIES.SUPPORTING_INFORMATION:
        return renderSupportingInfo();
      case PROFILE_CATEGORIES.PREFERENCES:
        return renderPreferences();
      case PROFILE_CATEGORIES.LOOKING_AHEAD:
        return renderLookingAhead();
      case PROFILE_CATEGORIES.SERVICES:
        return renderReferralsAndServices();
      default:
        return null;
    }
  };

  return (
    <>
      {isLocked && (
        <LockedBanner
          key='lockedBanner'
          message={formatMessage(messages.locked)}
        />
      )}
      {headingTip}
      {!isSharedApplication && !partial && locale.isAU && (
        <div className='nestedRoundCorner'>
          <h5>{formatMessage(messages.productLoan)}</h5>
          <SelectedLoanApply key='SelectedLoanApply' slug='selected-loan' />
        </div>
      )}
      {Object.values(PROFILE_CATEGORIES)
        .filter(isProfileCategoryVisible)
        .map(renderSectionCategories)}
    </>
  );
}

const mapStateToProps = (state) => ({
  isProfileCategoryVisible: UISelectors.isProfileCategoryVisible(state),
  isProfileSectionVisible: UISelectors.isProfileSectionVisible(state),
  partialProfileSections: UISelectors.partialProfileSections(state),
});
export const ApplyOnlineContent = connect(mapStateToProps)(
  ApplyOnlineContentContainer,
);

ApplyOnlineContentContainer.propTypes = {
  applicationId: PropTypes.number,
  isSharedApplication: PropTypes.bool,
  primaryContact: PropTypes.object,
  primaryApplicant: PropTypes.object,
  primaryApplicantsPartner: PropTypes.object,
  startAnimationSequence: PropTypes.func,
  push: PropTypes.func,
  isLocked: PropTypes.bool,
  updateLoanApplication: PropTypes.func,
  setMetadata: PropTypes.func,
  metadata: PropTypes.object,
  completedSections: PropTypes.object,
  requests: PropTypes.arrayOf(PropTypes.object),
  requestErrors: PropTypes.arrayOf(PropTypes.object),
  nextSequence: PropTypes.arrayOf(PropTypes.string),
  pushSpinner: PropTypes.func,
  popSpinner: PropTypes.func,
  setPageError: PropTypes.func,
  clearAsyncRequestErrors: PropTypes.func,
  hasOldInformation: PropTypes.bool,
  hasOldInformationMap: PropTypes.object,
  messages: PropTypes.object,
  formatMessage: PropTypes.func,
  section: PropTypes.string,
  isExact: PropTypes.bool,
  showProgress: PropTypes.bool,
  progress: PropTypes.number,
  partial: PropTypes.bool,
  urlPath: PropTypes.string,
  loanAmountOn: PropTypes.bool,
  isProfileSectionVisible: PropTypes.func.isRequired,
  isProfileCategoryVisible: PropTypes.func.isRequired,
  headingTip: PropTypes.node,
  lendersSectionText: PropTypes.string,
  loanOptionsSectionText: PropTypes.string,
  families: PropTypes.arrayOf(PropTypes.object),
  futurePlansCompleted: PropTypes.object,
  servicesCompleted: PropTypes.bool,
  location: PropTypes.object,
  partialProfileSections: PropTypes.arrayOf(PropTypes.string),
};
