import React from 'react';
import { Switch } from 'react-router-dom';

import { RouteForLoggedin } from 'routes/helpers';
import { ModalProvider } from 'contexts/ModalContext';
import { ParentWrapper } from 'containers/goal/ParentWrapper';

import GoalPreferencesCurrentBank from 'containers/goal/GoalPreferencesCurrentBank/GoalPreferencesCurrentBank';
import GoalPreferencesPreferredLenders from 'containers/goal/GoalPreferencesPreferredLenders/GoalPreferencesPreferredLenders';
import GoalPreferencesLoanOptions from 'containers/goal/GoalPreferencesLoanOptions/GoalPreferencesLoanOptions';

import {
  APPLY_BASE_PATH,
  CURRENT_BANK_PATH,
  PREFERRED_LENDERS_PATH,
  PREFERRED_LOAN_OPTIONS_PATH,
} from 'shared/constants/paths';

const GoalPreferencesApplyRoutes = () => {
  return (
    <ModalProvider>
      <ParentWrapper>
        <Switch>
          <RouteForLoggedin
            path={`${APPLY_BASE_PATH}/:loanApplicationId${CURRENT_BANK_PATH}`}
            component={GoalPreferencesCurrentBank}
          />
          <RouteForLoggedin
            path={`${APPLY_BASE_PATH}/:loanApplicationId${PREFERRED_LENDERS_PATH}`}
            component={GoalPreferencesPreferredLenders}
          />
          <RouteForLoggedin
            path={`${APPLY_BASE_PATH}/:loanApplicationId${PREFERRED_LOAN_OPTIONS_PATH}`}
            component={GoalPreferencesLoanOptions}
          />
        </Switch>
      </ParentWrapper>
    </ModalProvider>
  );
};

export default GoalPreferencesApplyRoutes;
