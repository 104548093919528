import { createSelector } from 'reselect';

import { OTHER_AU_BANKS } from 'shared/constants/otherLenders';
import lenders, { isLenderBigFour } from 'shared/lib/lenderHelper';

const bankSorter = (b1, b2) => {
  return b2.name.toLowerCase() < b1.name.toLowerCase() ? 1 : -1;
};

const getMilliBankDetails = (mogoId) => {
  const bankList = lenders.all.concat(OTHER_AU_BANKS);
  return bankList.find((bank) => bank.mogoId === mogoId);
};

export const bankListRaw = (state) => state.mogoConnect.bankList;

const getActiveList = createSelector(bankListRaw, (a) =>
  a.filter((bank) => bank.active),
);

export const numberOfLenders = createSelector(
  getActiveList,
  (bankList) => bankList.length,
);

export const bankList = createSelector(getActiveList, (rawList) =>
  rawList
    .map((lender) => {
      const milliBankDetails = getMilliBankDetails(lender.id);
      let bankDetails = {
        id: lender.id,
        name:
          (milliBankDetails && milliBankDetails.lenderName) ||
          lender.name ||
          '',
      };

      if (milliBankDetails) {
        bankDetails = {
          ...bankDetails,
          key: milliBankDetails.key,
          milliLenderId: milliBankDetails.lenderId,
        };
      }

      return bankDetails;
    })
    .sort(bankSorter)
    .reduce(
      (curList, lender) => {
        if (isLenderBigFour(lender.milliLenderId)) {
          curList.bigFour.push(lender);
        } else {
          curList.others.push(lender);
        }
        return curList;
      },
      {
        bigFour: [],
        others: [],
      },
    ),
);

export const loginUrl = (state) => state.mogoConnect.loginDetails.hybridUrl;

export const loginAccessId = (state) => state.mogoConnect.loginDetails.accessId;

export const getLoginReference = (state) =>
  state.mogoConnect.loginDetails.reference;

export const isLoginFetching = (state) =>
  state.mogoConnect.loginDetailsFetching;

export const isCheckingAccessId = (state) =>
  !!state.mogoConnect.checkingAccessId;

export const isAccessIdChecked = (state) => !!state.mogoConnect.checkedAccessId;

export const checkedAccessId = (state) => state.mogoConnect.checkedAccessId;

export const loginError = (state) => state.mogoReport.loginDetailsError;

export const bankName = (state) => state.mogoConnect.bankDetails.name;
