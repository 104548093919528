import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import SVGInline from 'react-svg-inline';
import Button from 'components/Button/Button';
import Pop from 'components/animations/Pop';
import styles from './PopupStatus.css';

import { RESOURCES_BY_TYPE } from 'constants/actionableErrorResources';

const ActionableErroredStatus = ({ actionableError }) => {
  const resources = RESOURCES_BY_TYPE[actionableError.type] || {};
  return (
    <div
      id='actionableErrorStatus'
      className={styles.status}
      data-testid='actionableErroredStatus'
    >
      <div className={styles.description}>
        <strong
          data-testid='actionableErroredStatus.text'
          className={classNames(styles.heading, styles.erroredHeading)}
        >
          {actionableError.text}
        </strong>
        <span data-testid='actionableErroredStatus.description'>
          {actionableError.description}
        </span>
      </div>
      {resources.icon && (
        <Pop>
          <SVGInline width='118px' height='118px' svg={resources.icon} />
        </Pop>
      )}
      <div className={styles.buttons}>
        <Button
          theme='applyButtonPrimary'
          onClick={actionableError.confirmAction}
        >
          {resources.confirmActionText || 'Okay'}
        </Button>
        <Button onClick={actionableError.cancelAction}>
          {resources.cancelActionText || 'Cancel'}
        </Button>
      </div>
    </div>
  );
};

ActionableErroredStatus.propTypes = {
  actionableError: PropTypes.object,
};

export default ActionableErroredStatus;
