import { isNotABot, buildTrackedData } from 'lib/trackAnalyticsHelper';
import { toPageTitle } from 'lib/utils/stringUtils';
import {
  TRACK_CURRENT_USER,
  TRACK_EMAIL_CAPTURE,
  TRACK_PASSWORD_CAPTURE,
  TRACK_MOBILE_CAPTURE,
  TRACK_SUBMITTED_FOR_REVIEW,
  TRACK_BANK_DATA_DOWNLOAD,
  CONVERT_SCENARIO,
  SET_LOAN_PURPOSE,
  SET_SCENARIO_NAME,
  TRACK_TOKEN_EXPIRED,
  TRACK_OKTA_SESSION_EXPIRED,
} from 'actions/scenarioActionTypes';
import {
  CLEAR_HANDHOLD_SECTIONS,
  SET_FULL_HANDHOLD_SECTIONS,
  SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS,
  SET_APPLICATION_TESTED,
  SET_PAGE_ERROR,
} from 'actions/UIActionTypes';
import {
  BANK_CONNECT_DATA_DOWNLOAD_EVENT,
  CUSTOMER_IO_TRIGGER_EVENT,
  ERROR_EVENT,
  LEAD_CONVERTED_EVENT,
  LEAD_EMAIL_CAPTURED_EVENT,
  LEAD_PASSWORD_CAPTURED_EVENT,
  LEAD_MOBILE_CAPTURED_EVENT,
  SET_IS_APPLICATION_TESTED_EVENT,
  SET_IS_HANDHOLD_EVENT,
  SET_LOAN_PURPOSE_EVENT,
  SET_LOAN_SCENARIO_EVENT,
  TRACK_TOKEN_EXPIRED_EVENT,
  TRACK_OKTA_SESSION_EXPIRED_EVENT,
  USER_DATA_EVENT,
  VIRTUAL_PAGE_VIEW_EVENT,
} from 'constants/GTMEvents';

import * as advisorSelectors from 'selectors/advisorSelectors';
import { getErrorDetails } from 'lib/errorHelper';

const SUBMITTED_FOR_REVIEW_TRIGGER = 'trigger_submitted_for_review_workflow';

function trackAnalytics() {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return (store) => (next) => (action) => {
    const state = store.getState();
    if (isNotABot(navigator.userAgent)) {
      const scenario = state.scenario;
      switch (action.type) {
        case TRACK_SUBMITTED_FOR_REVIEW: {
          window.dataLayer.push({
            event: CUSTOMER_IO_TRIGGER_EVENT,
            label: SUBMITTED_FOR_REVIEW_TRIGGER,
          });
          break;
        }
        case TRACK_BANK_DATA_DOWNLOAD: {
          window.dataLayer.push({
            event: BANK_CONNECT_DATA_DOWNLOAD_EVENT,
            status: action.payload.status,
          });
          break;
        }
        case TRACK_CURRENT_USER: {
          const subscriptions = advisorSelectors.hasBankConnectSubscription(
            state,
          )
            ? 'Bank Connect'
            : '';
          const userData = {
            event: USER_DATA_EVENT,
            ...buildTrackedData(action.payload),
            user_subscriptions: subscriptions,
          };

          window.dataLayer.push(userData);
          break;
        }
        case TRACK_PASSWORD_CAPTURE:
          if (!scenario.passwordCaptured) {
            window.dataLayer.push({ event: LEAD_PASSWORD_CAPTURED_EVENT });
          }
          break;
        case TRACK_MOBILE_CAPTURE:
          if (!scenario.mobileCaptured) {
            window.dataLayer.push({ event: LEAD_MOBILE_CAPTURED_EVENT });
          }
          break;
        case TRACK_EMAIL_CAPTURE:
          if (!scenario.emailCaptured) {
            window.dataLayer.push({ event: LEAD_EMAIL_CAPTURED_EVENT });
          }
          break;
        case CONVERT_SCENARIO:
          if (!scenario.converted) {
            window.dataLayer.push({ event: LEAD_CONVERTED_EVENT });
          }
          break;
        case SET_LOAN_PURPOSE:
          window.dataLayer.push({
            event: SET_LOAN_PURPOSE_EVENT,
            loan_purpose: action.payload,
          });
          break;
        case SET_SCENARIO_NAME:
          window.dataLayer.push({
            event: SET_LOAN_SCENARIO_EVENT,
            loan_scenario: action.payload,
          });
          break;
        case '@@router/LOCATION_CHANGE': {
          const {
            location: { pathname },
            namespace,
          } = action.payload;
          if (!namespace || pathname === '/') {
            return;
          }

          window.dataLayer.push({
            event: VIRTUAL_PAGE_VIEW_EVENT,
            virtualPagePath: pathname,
            virtualPageTitle: toPageTitle(pathname),
          });
          break;
        }
        case SET_FULL_HANDHOLD_SECTIONS:
        case SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS:
        case CLEAR_HANDHOLD_SECTIONS: {
          window.dataLayer.push({
            event: SET_IS_HANDHOLD_EVENT,
            is_handhold: [
              SET_FULL_HANDHOLD_SECTIONS,
              SET_FULL_HANDHOLD_NO_FORESEEABLE_SLUG_SECTIONS,
            ].includes(action.type),
          });
          break;
        }
        case SET_APPLICATION_TESTED: {
          window.dataLayer.push({
            event: SET_IS_APPLICATION_TESTED_EVENT,
            is_application_tested: action.payload,
          });
          break;
        }
        case SET_PAGE_ERROR: {
          const { name, details, status } = getErrorDetails(
            action.payload.status,
          );
          window.dataLayer.push({
            event: ERROR_EVENT,
            name,
            details,
            status,
          });
          break;
        }
        case TRACK_TOKEN_EXPIRED: {
          const { clientId, brokerFamilyId } = action.payload;
          window.dataLayer.push({
            event: TRACK_TOKEN_EXPIRED_EVENT,
            client_id: clientId,
            broker_family_id: brokerFamilyId,
          });
          break;
        }
        case TRACK_OKTA_SESSION_EXPIRED: {
          const { clientId, brokerFamilyId } = action.payload;
          window.dataLayer.push({
            event: TRACK_OKTA_SESSION_EXPIRED_EVENT,
            client_id: clientId,
            broker_family_id: brokerFamilyId,
          });
          break;
        }
        default:
          break;
      }
    }
    return next(action);
  };
}

export default trackAnalytics;
