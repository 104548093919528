import axiosForAuth, { returnJSON, throwJSON } from './baseApi';

const FACTOR_TYPE = 'sms';
const PROVIDER = 'OKTA';

export const getUserById = (userId) => {
  return axiosForAuth.get(`/identity/users/${userId}`);
};

export const getUserByEmail = (email) => {
  return axiosForAuth.get(`/identity/users?email=${encodeURIComponent(email)}`);
};

export const register = (data) => {
  return axiosForAuth
    .post('/identity/users/register-consumer-milli', data)
    .then(returnJSON, throwJSON);
};

export const login = (username, password, deviceToken) => {
  return axiosForAuth
    .post('identity/login/login', { username, password, deviceToken })
    .then(returnJSON, throwJSON);
};

export const loginWithToken = (token) => {
  return axiosForAuth
    .post('identity/login/login-with-token', { token })
    .then(returnJSON, throwJSON);
};

export const enrollFactor = (stateToken, phoneNumber) => {
  return axiosForAuth
    .post('/identity/users/factors/enroll?updatePhone=true', {
      stateToken,
      phoneNumber,
      factorType: FACTOR_TYPE,
      provider: PROVIDER,
    })
    .then(returnJSON, throwJSON);
};

export const activateFactor = (stateToken, factorId, passCode) => {
  return axiosForAuth.post(`/identity/users/factors/${factorId}/activate`, {
    stateToken,
    passCode,
  });
};

export const resendEnrollFactor = (stateToken, factorId, phoneNumber) => {
  return axiosForAuth
    .post(`/identity/users/factors/resend-enroll`, {
      stateToken,
      factorId,
      factorType: FACTOR_TYPE,
      provider: PROVIDER,
      profile: { phoneNumber },
    })
    .then(returnJSON, throwJSON);
};

export const sendCode = (stateToken, factorId) => {
  return axiosForAuth
    .post('/identity/users/factors/send', { stateToken, factorId })
    .then(returnJSON, throwJSON);
};

export const verifyCode = (stateToken, factorId, passCode) => {
  return axiosForAuth.post('/identity/users/factors/verify', {
    stateToken,
    factorId,
    passCode,
  });
};

export const resendCode = (stateToken, factorId) => {
  return axiosForAuth
    .post('/identity/users/factors/resend', { stateToken, factorId })
    .then(returnJSON, throwJSON);
};

export const verifyRecoveryToken = (recoveryToken, deviceToken) => {
  return axiosForAuth
    .post('/identity/users/recovery/token', { recoveryToken, deviceToken })
    .then(returnJSON, throwJSON);
};

export const resetPassword = (stateToken, newPassword) => {
  return axiosForAuth
    .post('/identity/users/recovery/reset-password', {
      stateToken,
      newPassword,
    })
    .then(returnJSON, throwJSON);
};

export const getUserFactors = (userId) => {
  return axiosForAuth
    .get(`/identity/users/${userId}/factors`)
    .then(returnJSON, throwJSON);
};

export const sendUserFactor = (userId, factorId) => {
  return axiosForAuth
    .post(`/identity/users/${userId}/factors/${factorId}/send`)
    .then(returnJSON, throwJSON);
};

export const resendUserFactor = (userId, factorId) => {
  return axiosForAuth
    .post(`/identity/users/${userId}/factors/resend`, { factorId })
    .then(returnJSON, throwJSON);
};

export const verifyUserFactor = (userId, factorId, passCode) => {
  return axiosForAuth
    .post(`/identity/users/${userId}/factors/${factorId}/verify`, { passCode })
    .then(returnJSON, throwJSON);
};

export const getEmailAvailability = (email, clientId) => {
  const params = { email };

  if (clientId) {
    params.clientId = clientId;
  }

  return axiosForAuth
    .get(`/identity/users/account/availability`, {
      params: params,
    })
    .then(returnJSON, throwJSON);
};
