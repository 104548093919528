import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import styles from './EditableItem.css';

const EditableItem = ({
  url,
  editOnClick,
  leftLabel,
  leftDescription,
  rightLabel,
  rightDescription,
  leftImage,
  leftIcon,
  rightIcon,
  hasWarning,
}) => (
  <div className={styles.item}>
    <Button
      className={classNames({
        [styles.editableButton]: true,
        [styles.hasWarning]: hasWarning,
      })}
      theme='inputButton'
      url={url}
      onClick={editOnClick}
    >
      <div className={styles.container}>
        <div className={`${styles.labelWrapper} ${styles.left}`}>
          {(leftIcon && (
            <span className={classNames(leftIcon, styles.icon)} />
          )) ||
            (leftImage && <img className={styles.image} src={leftImage} />)}
          <div className={styles.leftLabelAndDescription}>
            {!!leftLabel && <div className={styles.label}>{leftLabel}</div>}
            {leftDescription && (
              <div className={styles.description}>{leftDescription}</div>
            )}
          </div>
        </div>
        <div className={`${styles.labelWrapper} ${styles.right}`}>
          {(rightLabel && (
            <div className={classNames(styles.label, 'brandColor__font')}>
              {rightLabel}
            </div>
          )) ||
            (rightIcon && (
              <span
                className={classNames(rightIcon, styles.icon, styles.label)}
              />
            ))}
          {rightDescription && (
            <div className={styles.description}>{rightDescription}</div>
          )}
        </div>
      </div>
      {hasWarning && (
        <div className={styles.warning}>
          <i className='mi-icon-warning-section' />
        </div>
      )}
    </Button>
  </div>
);

EditableItem.propTypes = {
  url: PropTypes.string,
  editOnClick: PropTypes.func,
  leftLabel: PropTypes.string.isRequired,
  leftDescription: PropTypes.string,
  leftIcon: PropTypes.string,
  leftImage: PropTypes.string,
  rightLabel: PropTypes.string,
  rightDescription: PropTypes.string,
  rightIcon: PropTypes.string,
  hasWarning: PropTypes.bool,
};

EditableItem.defaultProps = {};

export default EditableItem;
