import { bindActionCreators } from 'redux';

import { DEFAULT_EXISTING_PROPERTY_ID } from 'shared/constants/defaults';
import { connectToMirroredProperty } from 'lib/reducerHelper';

import scenarioActions from 'actions/scenarioActions';
import {
  propertyValue,
  existingPropertyAdsFee,
  existingPropertyRealEstateCommissionPercentage,
  mortgageAmount,
} from 'selectors/scenarioSelectors';

import SingleInputForm from 'components/PopupForms/SingleInputForm';
import RealEstateAgentFeesForm from 'components/PopupForms/RealEstateAgentFeesForm';

export const OutstandingMortgageForm = connectToMirroredProperty(
  (state) => ({
    value: mortgageAmount(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setValue: scenarioActions.setPropertyMortgageAmount(
          DEFAULT_EXISTING_PROPERTY_ID,
        ),
      },
      dispatch,
    ),
)(SingleInputForm);

export const MirroredRealEstateAgentFeesForm = connectToMirroredProperty(
  (state) => ({
    value: existingPropertyAdsFee(state),
    baseValue: propertyValue(state, DEFAULT_EXISTING_PROPERTY_ID),
    percent: existingPropertyRealEstateCommissionPercentage(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setValue: scenarioActions.setPropertyAdsFee(
          DEFAULT_EXISTING_PROPERTY_ID,
        ),
        setPercent: scenarioActions.setPropertyRealEstateCommissionPercentage(
          DEFAULT_EXISTING_PROPERTY_ID,
        ),
      },
      dispatch,
    ),
)(RealEstateAgentFeesForm);
