import { buildTrackedData } from 'lib/trackAnalyticsHelper';
import { buildRolloutCustomProperties, setCustomProperties } from 'lib/rollout';
import { TRACK_CURRENT_USER } from 'actions/scenarioActionTypes';

// eslint-disable-next-line unicorn/consistent-function-scoping
export default () => (next) => (action) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case TRACK_CURRENT_USER: {
      const customProperties = buildRolloutCustomProperties(
        buildTrackedData(action.payload),
      );
      setCustomProperties(customProperties);
      break;
    }
    default:
      break;
  }
  return next(action);
};
