import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';

import scenarioActions from 'actions/scenarioActions';
import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';
import { otherCosts } from 'selectors/scenarioSelectors';
import { formatCurrency } from 'lib/intlFormatters';

import Popup, { popupProps } from 'components/PopupItem/Popup';
import OtherCostsForm from 'components/PopupForms/OtherCostsForm';

import * as myPropTypes from 'types/customPropTypes';

class OtherCostsPopup extends Popup {
  static propTypes = {
    ...popupProps,
    name: myPropTypes.stringOrFunction,
    title: myPropTypes.stringOrFunction,
    value: myPropTypes.stringOrNumber,
    formatValue: PropTypes.func,
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    ...Popup.defaultProps,
    formatValue: _.identity,
    closeOnOverlay: true,
  };

  title() {
    return this.props.name || 'Other Costs';
  }

  formatValue = (v) => formatCurrency(this.props.intl)(v);

  value() {
    const { value } = this.props;
    if (value === undefined) {
      return;
    }
    return value;
  }

  onSubmit = () => {
    this.setProcessed();
    this.onProcessed = this.closePopup;
  };

  renderBody() {
    return <OtherCostsForm {...this.props} onSubmit={this.onSubmit} />;
  }

  renderDescription(style) {
    return (
      <div className={style}>
        <h1>What are other costs?</h1>
        <p>
          If you want to borrow additional funds to cover things like paying off
          a car loan or credit card debt make an allowance for it here.
        </p>
        <p>
          Consolidating your debts into a home loan can save a lot on interest
          repayments over the long term. Our experts can guide you through this
          process and help you understand how much you could save.
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || 0;
  const data = otherCosts(state);
  const item = data[id];
  return {
    index: id,
    data,
    name: item ? item.name : undefined,
    value: item ? item.value : undefined,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      action: scenarioActions.setPropertyOtherCost(PROSPECTIVE_PROPERTY_ID),
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(OtherCostsPopup),
);
