import axios from 'axios';

import { LOAN_APPLICATION_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON, throwJSON } from './baseApi';

export function getLoanApplication(loanApplicationId) {
  return axiosWithAuth
    .get(`${LOAN_APPLICATION_API_PATH}/${loanApplicationId}`)
    .then(returnJSON);
}

export function putLoanApplication(loanApplication) {
  return axiosWithAuth
    .put(`${LOAN_APPLICATION_API_PATH}/${loanApplication.id}`, loanApplication)
    .then(returnJSON);
}

export function putStructureForLoanApplication(structure) {
  return axiosWithAuth
    .put(
      `${LOAN_APPLICATION_API_PATH}/${structure.loanApplicationId}/structures/${structure.id}`,
      structure,
    )
    .then(returnJSON);
}

export function postApplicant(applicant) {
  return axiosWithAuth
    .post(
      `${LOAN_APPLICATION_API_PATH}/${applicant.loanApplicationId}/applicants`,
      applicant,
    )
    .then(returnJSON);
}

export function postCompanyApplicant(applicant) {
  return axiosWithAuth
    .post(
      `${LOAN_APPLICATION_API_PATH}/${applicant.loanApplicationId}/company-applicants`,
      applicant,
    )
    .then(returnJSON);
}

export function getProperties(loanApplicationId) {
  return axiosWithAuth
    .get(`${LOAN_APPLICATION_API_PATH}/${loanApplicationId}/properties`)
    .then(returnJSON);
}

export function getFundingsFromLoanApplication(loanApplicationId) {
  return axiosWithAuth
    .get(`${LOAN_APPLICATION_API_PATH}/${loanApplicationId}/fundings`)
    .then(returnJSON);
}

export function getStructuresFromLoanApplication(loanApplicationId) {
  return axiosWithAuth
    .get(`${LOAN_APPLICATION_API_PATH}/${loanApplicationId}/structures`)
    .then(returnJSON);
}

export function getSecuritiesFromLoanApplication(loanApplicationId) {
  return axiosWithAuth
    .get(`${LOAN_APPLICATION_API_PATH}/${loanApplicationId}/securities`)
    .then(returnJSON);
}

export function postStructureForLoanApplication(structure) {
  return axiosWithAuth
    .post(
      `${LOAN_APPLICATION_API_PATH}/${structure.loanApplicationId}/structures`,
      structure,
    )
    .then(returnJSON);
}

export function postFundingForLoanApplication(funding) {
  return axiosWithAuth
    .post(
      `${LOAN_APPLICATION_API_PATH}/${funding.loanApplicationId}/fundings`,
      funding,
    )
    .then(returnJSON);
}

export function postPropertyForApplication(property) {
  return axiosWithAuth
    .post(
      `${LOAN_APPLICATION_API_PATH}/${property.applicationId}/properties`,
      property,
    )
    .then(returnJSON);
}

export function getDocumentsForLoanApplication(
  loanApplicationId,
  countryCode = 'AU',
) {
  return axiosWithAuth
    .get(
      `${LOAN_APPLICATION_API_PATH}/${loanApplicationId}/documents?countryCode=${countryCode}`,
    )
    .then(returnJSON);
}

export function getForeseeableChangesFromLoanApplication(loanApplicationId) {
  return axiosWithAuth
    .get(
      `${LOAN_APPLICATION_API_PATH}/${loanApplicationId}/foreseeable-changes`,
    )
    .then(returnJSON);
}

export function putForeseeableChangesForLoanApplication(forseeableChanges) {
  return axiosWithAuth
    .put(
      `${LOAN_APPLICATION_API_PATH}/${forseeableChanges.loanApplicationId}/foreseeable-changes`,
      forseeableChanges,
    )
    .then(returnJSON);
}

export const getSharedLoanAppInfo = (token) => {
  return axios
    .get(`${LOAN_APPLICATION_API_PATH}/shared-loan-app-info`, {
      params: { token },
    })
    .then(returnJSON, throwJSON);
};
