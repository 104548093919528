import * as actionTypes from 'actions/productsActionTypes';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { DEFAULT_PRODUCT_INITIAL_STATE } from 'shared/constants/defaults';

const productsReducer = handleActions(
  {
    [actionTypes.SET_PRODUCTS](state, action) {
      const { productList } = action.payload;
      return update(state, {
        productList: { $set: productList || [] },
      });
    },
    [actionTypes.SET_PRODUCTS_LOADING_MORE](state, action) {
      return { ...state, isLoadingMore: action.payload };
    },
    [actionTypes.APPEND_PRODUCTS](state, action) {
      const { productList } = action.payload;
      return update(state, {
        productList: { $push: productList || [] },
      });
    },
    [actionTypes.SET_PRODUCT_DETAIL](state, action) {
      return { ...state, productDetail: action.payload };
    },
    [actionTypes.CLEAR_PRODUCT_DETAIL](state) {
      return { ...state, productDetail: {} };
    },
    [actionTypes.SET_FEATURED_PRODUCTS](state, { payload }) {
      const { lenderCode, products } = payload;

      return update(state, {
        featuredProducts: {
          [lenderCode]: { $set: products || {} },
        },
      });
    },
    [actionTypes.SET_INTEREST_PRODUCTS](state, { payload }) {
      return update(state, {
        interestProducts: { $set: payload || {} },
      });
    },
  },
  DEFAULT_PRODUCT_INITIAL_STATE,
);

export default productsReducer;
