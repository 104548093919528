import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { interpolateNumber } from 'd3-interpolate';
import { pie, arc } from 'd3-shape';
import { easeCubicInOut } from 'd3-ease';
import { timer } from 'd3-timer';
import _ from 'lodash';
import classNames from 'classnames';

import { generateUniqueID } from 'lib/utils/stringUtils';
import styles from './DonutChart.css';

const middleAngle = (d) => d.startAngle + (d.endAngle - d.startAngle) / 2;

class DonutChart extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number,
    valueInfoLabel: PropTypes.string,
    percentageFormatter: PropTypes.func.isRequired,
    valueFormatter: PropTypes.func.isRequired,
    duration: PropTypes.number,
    simple: PropTypes.bool,
  };

  static defaultProps = {
    duration: 500,
  };

  static lineariseSectionedData(sections) {
    return sections.reduce(
      (p, { label, data, colour }, index) =>
        p.concat(
          data
            .filter((d) => d > 0)
            .map((value, i) => ({
              value,
              colour,
              key: `${label}-${i}-${index}`,
              sectionId: index,
              opacity: (data.length - i) / data.length,
            })),
        ),
      [],
    );
  }

  static mainSectionedData(data) {
    return data
      .map((s, i) => ({
        value: _.sum(s.data),
        label: s.label,
        displayNegative: s.displayNegative,
        sectionId: i,
      }))
      .filter((d) => d.value > 0);
  }

  constructor(props) {
    super(props);

    this.pie = pie()
      .value((v) => v.value)
      .padAngle(0.02)
      .sort(null);
    this.size = 220;
    this.radius = this.size / 2;
    this.focus = 0;
    this.prevFocus = null;
    const outerRadius = 102;
    const innerRadius = 75;
    this.arc = arc().outerRadius(outerRadius).innerRadius(innerRadius);
    this.outerArc = arc().innerRadius(innerRadius).outerRadius(this.radius);
    this.arcInterp = interpolateNumber(outerRadius, this.radius);
    const linearData = DonutChart.lineariseSectionedData(props.data);
    const mainSections = DonutChart.mainSectionedData(props.data);
    this.sectionAngles = this.pie(mainSections).map(
      (d) => Math.PI / 2 - middleAngle(d),
    );
    this.state = {
      data: linearData,
      sections: mainSections,
      total: props.value || _.sumBy(mainSections, 'value'),
      angle: this.sectionAngles[0],
      transition: 1,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, value } = nextProps;
    if (this.props.data !== data) {
      const newData = DonutChart.lineariseSectionedData(data);
      const mainSections = DonutChart.mainSectionedData(data);
      this.pie.startAngle(0).endAngle(Math.PI * 2);
      this.sectionAngles = this.pie(mainSections).map(
        (d) => Math.PI / 2 - middleAngle(d),
      );
      this.setState({
        data: newData,
        sections: mainSections,
        total: value || _.sumBy(mainSections, 'value'),
        angle: this.sectionAngles[this.focus],
      });
    }
  }

  componentWillUnmount() {
    this.transitionTimer && this.transitionTimer.stop();
  }

  transitionToAngle(newAngle) {
    const { angle } = this.state;
    const { duration } = this.props;
    const oldAngle = angle < newAngle ? angle + Math.PI * 2 : angle;
    const interp = interpolateNumber(oldAngle, newAngle);
    this.transitionTimer = timer((elapsed) => {
      const percentElapsed = elapsed < duration ? elapsed / duration : 1;
      const transition = easeCubicInOut(percentElapsed);
      this.setState({
        angle: interp(transition),
        transition,
      });
      if (percentElapsed === 1) {
        this.transitionTimer.stop();
        this.transitionTimer = null;
      }
    });
  }

  rotateToNextSection = () => {
    if (this.transitionTimer) {
      return;
    }
    this.prevFocus = this.focus;
    this.focus = (this.focus + 1) % this.sectionAngles.length;
    const angle = this.sectionAngles[this.focus];
    this.transitionToAngle(angle);
  };

  isSectionFocused = (focus, sectionId) => {
    if (typeof focus !== 'number' || typeof sectionId !== 'number') {
      return false;
    }

    const { sections } = this.state;
    if (sections && focus >= 0 && focus < sections.length) {
      return sections[focus].sectionId === sectionId;
    }
    return false;
  };

  renderSegments = (d) => {
    const { key, colour, sectionId, opacity } = d.data;
    const { transition } = this.state;
    let a = this.arc;
    if (this.isSectionFocused(this.focus, sectionId)) {
      a = this.outerArc.outerRadius(this.arcInterp(transition));
    } else if (this.isSectionFocused(this.prevFocus, sectionId)) {
      a = this.outerArc.outerRadius(this.arcInterp(1 - transition));
    }
    return (
      <path fill={colour} opacity={opacity} d={a(d)} key={`segment-${key}`} />
    );
  };

  renderPercentageLabel = (percent) => {
    const { label, valueInfoLabel, percentageFormatter } = this.props;
    return valueInfoLabel ? (
      <div className={styles.heading}>
        {percentageFormatter(percent)} of total {label.toLowerCase()} <br />
        {valueInfoLabel}.
      </div>
    ) : (
      <div className={styles.heading}>
        {percentageFormatter(percent)} of total {label.toLowerCase()}.
      </div>
    );
  };

  render() {
    const { id, label, valueFormatter, simple } = this.props;
    const { data, angle, total, sections } = this.state;
    if (sections.length === 0) {
      return null;
    }
    const activeSection = sections[this.focus];
    const transform = `translate(${this.radius},${this.radius})`;
    const pieData = this.pie.startAngle(angle).endAngle(angle + Math.PI * 2)(
      data,
    );
    const rootStyle = classNames(styles.root, {
      [styles.simple]: simple,
    });

    return (
      <div
        id={generateUniqueID('DonutChart', id)}
        className={rootStyle}
        onClick={this.rotateToNextSection}
      >
        <div className={styles.chartArea}>
          <div className={styles.donut}>
            <div className={styles.content}>
              <div>
                <div className={styles.heading}>{valueFormatter(total)}</div>
                <div className={styles.label}>{label}</div>
              </div>
            </div>
            <div className={styles.content}>
              <svg
                viewBox={`0 0 ${this.size} ${this.size}`}
                className={styles.svg}
              >
                <g transform={transform}>{pieData.map(this.renderSegments)}</g>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.infoArea}>
          <div className={styles.top}>
            <div className={styles.emphasis}>{activeSection.label}</div>
            <div className={styles.emphasis}>
              {valueFormatter(
                activeSection.displayNegative
                  ? -activeSection.value
                  : activeSection.value,
              )}
            </div>
          </div>
          <div className={styles.line} />
          <div className={styles.bottom}>
            {this.renderPercentageLabel(activeSection.value / total)}
          </div>
        </div>
      </div>
    );
  }
}

export default DonutChart;
