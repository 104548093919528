import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as actionTypes from './contactActionTypes';
import * as individualActionTypes from './individualContactActionTypes';

export const contactActions = createActions({}, ...Object.values(actionTypes));

export default {
  ...contactActions,
  ...createIndividualisedActions(individualActionTypes),
};
