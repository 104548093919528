export const CUSTOMER_IO_TRIGGER_EVENT = 'customer-io-trigger';
export const BANK_CONNECT_DATA_DOWNLOAD_EVENT = 'bank_connect_data_download';
export const USER_DATA_EVENT = 'user_data';
export const LEAD_EMAIL_CAPTURED_EVENT = 'lead_email_captured';
export const LEAD_PASSWORD_CAPTURED_EVENT = 'lead_password_captured';
export const LEAD_MOBILE_CAPTURED_EVENT = 'lead_mobile_captured';
export const LEAD_CONVERTED_EVENT = 'lead_converted';
export const SET_LOAN_PURPOSE_EVENT = 'set_loan_purpose';
export const SET_LOAN_SCENARIO_EVENT = 'set_loan_scenario';
export const VIRTUAL_PAGE_VIEW_EVENT = 'virtualPageview';
export const SET_IS_HANDHOLD_EVENT = 'set_is_handhold';
export const SET_IS_GOAL_SETTER_EVENT = 'set_is_goal_setter';
export const SET_IS_APPLICATION_TESTED_EVENT = 'set_is_application_tested';
export const TRACK_TOKEN_EXPIRED_EVENT = 'auth_token_expired';
export const TRACK_OKTA_SESSION_EXPIRED_EVENT = 'okta_session_expired';
export const BACK_BUTTON_EVENT = 'back_button';
export const ERROR_EVENT = 'error';
export const VALIDATION_EVENT = 'validation';
