import { createSelector } from 'reselect';
import _ from 'lodash';
import { generateClientIdOptions } from 'lib/optionHelpers';
import { combineStringsGrammatically } from 'lib/utils/stringUtils';
import { groupClientsToFamilies } from 'lib/utils/formUtils';

import {
  sortClientsByType,
  sortClientsByRole,
  canViewServiceabilityChecker,
} from 'lib/goalLoanHelper';

import { PERSON, BORROWER } from 'constants/options';

export const workingClients = (state) => state.client.working;
export const clients = (state) =>
  _.sortBy(state.client.entities, [sortClientsByType, sortClientsByRole]);
export const goalLoanApplication = (state) => state.goalApplication;
export const client = createSelector(clients, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);

export const working = (state) => (id) => workingClients(state)[id];
export const titleOptions = (state) => state.client.titleOptions;

export const primaryApplicant = createSelector(clients, (a) =>
  a.find((e) => e.primaryApplicant),
);

export const getPrimaryApplicantId = createSelector(
  primaryApplicant,
  (a) => a && a.id,
);

export const primaryApplicantContactId = createSelector(primaryApplicant, (a) =>
  a ? a.contactId : undefined,
);

export const applicants = createSelector(
  clients,
  (c) =>
    c && c.filter((a) => !a.isDependent && a.isCoapplicant && !a.isDeceased),
);

export const adultClients = createSelector(
  applicants,
  (c) => c && c.filter((a) => a.type === PERSON),
);

export const borrowers = createSelector(
  adultClients,
  (c) => c && c.filter((a) => a.role === BORROWER),
);

export const clientsOnContact = createSelector(clients, (a) =>
  _.memoize((contactId) => a.filter((e) => e.contactId === contactId)),
);

export const applicantsOnContact = createSelector(clientsOnContact, (a) =>
  _.memoize((contactId) =>
    a(contactId).filter((e) => !e.isDependent && !e.isDeceased),
  ),
);

export const adultClientsOnContact = createSelector(adultClients, (a) =>
  _.memoize((contactId) => a.filter((e) => e.contactId === contactId)),
);

export const nextClient = createSelector(
  adultClientsOnContact,
  (a) => (contactId, clientId) => {
    const filteredClients = a(contactId);
    const idx = filteredClients.findIndex((c) => c.id === clientId);
    return idx < filteredClients.length - 1 ? filteredClients[idx + 1] : {};
  },
);

export const primaryApplicantsPartner = createSelector(
  primaryApplicantContactId,
  clientsOnContact,
  (a, b) =>
    b(a).find((e) => !e.primaryApplicant && !e.isDependent && !e.isDeceased),
);

export const isPrimaryOrPartnerClient = createSelector(
  getPrimaryApplicantId,
  primaryApplicantsPartner,
  (primaryId, partner) => (clientId) => {
    if (clientId === primaryId) {
      return true;
    }
    if (partner && clientId === partner.id) {
      return true;
    }
    return false;
  },
);

export const dependents = createSelector(clientsOnContact, (a) =>
  _.memoize((contactId) => a(contactId).filter((e) => e.isDependent)),
);

export const workingDependents = createSelector(workingClients, (a) =>
  Object.keys(a).reduce((map, key) => {
    if ((a[key] || {}).isDependent) {
      map[key] = a[key];
    }
    return map;
  }, {}),
);

export const primaryApplicantsDependents = createSelector(
  primaryApplicantContactId,
  clientsOnContact,
  (contactId, c) => c(contactId).filter((e) => e.isDependent),
);

export const primaryHasFamilyEntities = createSelector(
  primaryApplicantsPartner,
  primaryApplicantsDependents,
  (a, b) => !!(a || b.length > 0),
);

export const clientIdOwnershipOptions = createSelector(
  primaryApplicant,
  primaryApplicantsPartner,
  (a, b) =>
    a
      ? generateClientIdOptions({
          primaryId: a.id,
          partnerId: b && b.id,
          possessive: true,
          sharable: true,
        })
      : [],
);

export const clientIdOwnershipOptionsNonSharable = createSelector(
  primaryApplicant,
  primaryApplicantsPartner,
  (a, b) =>
    a
      ? generateClientIdOptions({
          primaryId: a.id,
          partnerId: b && b.id,
          possessive: true,
          sharable: false,
        })
      : [],
);

export const clientIdOptionsSharable = createSelector(
  primaryApplicant,
  primaryApplicantsPartner,
  (a, b) =>
    a
      ? generateClientIdOptions({
          primaryId: a.id,
          partnerId: b && b.id,
          possessive: false,
          sharable: true,
        })
      : [],
);

export const clientIdOptionsForNonsharable = createSelector(
  primaryApplicant,
  primaryApplicantsPartner,
  (a, b) =>
    a
      ? generateClientIdOptions({
          primaryId: a.id,
          partnerId: b && b.id,
          possessive: false,
          sharable: false,
        })
      : [],
);

export const getCoapplicants = createSelector(clients, (entities) =>
  (entities || [])
    .filter(
      (entity) =>
        !entity.isDependent &&
        entity.isCoapplicant &&
        !entity.primaryApplicant &&
        !entity.isDeceased,
    )
    .map((entity) => ({
      ...entity,
      isClient: true,
    })),
);

export const applicantNames = createSelector(adultClients, (entities) =>
  combineStringsGrammatically((entities || []).map((e) => e.displayName)),
);

export const canClientsViewServiceability = createSelector(
  adultClientsOnContact,
  goalLoanApplication,
  (getAdultClientsOnContact, application) => (contactId) => {
    const filteredClients = getAdultClientsOnContact(contactId);
    const { profilePercentages } = application;
    return filteredClients.every((c) => {
      const { completedSections } =
        profilePercentages.find((p) => p.clientId === c.id) || {};
      return canViewServiceabilityChecker(completedSections);
    });
  },
);

export const families = createSelector(
  adultClients,
  goalLoanApplication,
  canClientsViewServiceability,
  (entities, application, serviceabilityCompletion) => {
    const { profilePercentages } = application;
    return groupClientsToFamilies(entities).map((e) => {
      const clientIds = e.clients.map((c) => c.id);
      const percentageSum = profilePercentages.reduce(
        (a, b) => a + (clientIds.includes(b.clientId) ? b.percent : 0),
        0,
      );
      return {
        ...e,
        familyPercentageAverage: percentageSum / clientIds.length,
        canViewServiceability: serviceabilityCompletion(e.contactId),
      };
    });
  },
);

export const family = createSelector(families, (f) =>
  _.memoize((id) => f.find((o) => o.contactId === id)),
);

export const primaryFamily = createSelector(families, (f) => {
  return f && f.length > 0 ? f[0] : {};
});
