import _ from 'lodash';
import { isEmptyObject } from 'shared/lib/utils';

export const UTM_FIELDS = [
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_campaign',
];

export function filterUtmQueries(queries) {
  const utmQueries = {};

  Object.keys(queries).forEach((key) => {
    if (UTM_FIELDS.includes(key)) {
      const value = queries[key];
      utmQueries[_.camelCase(key)] = Array.isArray(value) ? value[0] : value;
    }
  });

  return utmQueries;
}

export function storeUtm(store, params) {
  const utmQueries = filterUtmQueries(params);

  if (!isEmptyObject(utmQueries)) {
    store.dispatch({
      type: 'setUtm',
      payload: utmQueries,
    });
  }
}
