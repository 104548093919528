import { GOAL_SETTER_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON } from '../baseApi';

const BASE_API = GOAL_SETTER_API_PATH.PREFERENCES;

export function getPreferences(adviserId) {
  return axiosWithAuth.get(`${BASE_API}/${adviserId}`).then(returnJSON);
}

export function postPreferences(adviserId, data) {
  return axiosWithAuth.post(`${BASE_API}/${adviserId}`, data).then(returnJSON);
}
