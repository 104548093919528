import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Report.css';

const Report = ({ id, title, children, theme }) => {
  const rootStyle = classNames(styles.root, styles[theme]);
  return (
    <div id={id} className={rootStyle}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );
};

Report.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  theme: PropTypes.string,
};

export default Report;
