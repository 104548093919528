import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import locale from 'config/locale';
import { formatCurrency, formatRate } from 'lib/intlFormatters';
import structureActions from 'actions/structureActions';

import ButtonNext from 'components/ButtonNext/ButtonNext';
import { API_FIXED_RESULT } from 'shared/constants/interestType';
import getLender from 'lib/lenderHelper';
import { formatProductName } from 'lib/utils/stringUtils';
import { lenderPdpBannerUrl } from 'lib/utils/imageUtils';
import { structureToQueryString } from 'lib/compareHelper';
import { DEFAULT_STRUCTURE_INITIAL_STATE } from 'shared/constants/defaults';
import { COMPARE_PAGE_PATH } from 'shared/constants/paths';

import Button from 'components/Button/Button';
import RepaymentHeaderDisplay from 'components/RepaymentsExplanation/RepaymentHeaderDisplay';
import { monthlyRepaymentForStructures } from 'shared/lib/loanAmountCalculator';

import * as structureSelectors from 'selectors/structureSelectors';
import { getIsLocked } from 'selectors/applicationSelectors';
import { isHandholdOn } from 'selectors/UISelectors';

import styles from './SelectedLoanApply.css';

const messages = defineMessages({
  compareButton: {
    id: 'SelectedLoanApply.compareButton',
    defaultMessage: 'Compare Home Loans',
  },
  title: {
    id: 'SelectedLoanApply.title',
    defaultMessage: 'Get started. Compare Home Loans.',
  },
  description: {
    id: 'SelectedLoanApply.description',
    defaultMessage:
      'Compare Rates and repayments from Australia’s biggest banks and specialist lenders.',
  },
  loanTerm: {
    id: 'SelectedLoanApply.loanTerm',
    defaultMessage: '{loanTerm} Years',
  },
  viewDetailText: {
    id: 'SelectedLoanApply.viewDetail.text',
    defaultMessage: 'View loan details',
  },
  fixedTerm: {
    id: 'SelectedLoanApply.fixedTerm',
    defaultMessage:
      'Fixed - {year, number} {year, plural, 1 {year} other {years}}',
  },
  variable: {
    id: 'SelectedLoanApply.variable',
    defaultMessage: 'Variable',
  },
  loanAmount: {
    id: 'SelectedLoanApply.loanAmount',
    defaultMessage: 'Loan amount',
  },
  rate: {
    id: 'SelectedLoanApply.rate',
    defaultMessage: 'Rate',
  },
  comparison: {
    id: 'SelectedLoanApply.comparison',
    defaultMessage: 'Comparison',
  },
  topDeal: {
    id: 'SelectedLoanApply.topDeal',
    defaultMessage: 'Today’s top deal',
  },
  initialRepayment: {
    id: 'SelectedLoanApply.initialRepayment',
    defaultMessage: 'Initial repayment',
  },
});

class SelectedLoanApply extends Component {
  static displayName = 'SelectedLoanApply';
  static propTypes = {
    structures: PropTypes.arrayOf(PropTypes.object),
    loadStructure: PropTypes.func,
    intl: intlShape.isRequired,
    primaryStructure: PropTypes.object,
    isLocked: PropTypes.bool.isRequired,
    handhold: PropTypes.bool.isRequired,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      lenderImageErrorIds: [],
    };
  }

  loadStructureAndGo = (structureId, url) => () => {
    this.props.loadStructure(structureId);
    this.props.history.push(url);
  };

  renderLabelAndHint = (label, hint) => {
    return (
      <div className={styles.labelAndHintGroup}>
        <div className={styles.label}>{label}</div>
        <div className={styles.hint}>{hint}</div>
      </div>
    );
  };

  handleImageError = (id) => () => {
    this.setState({
      lenderImageErrorIds: [...this.state.lenderImageErrorIds, id],
    });
  };

  renderstructures() {
    const { structures, primaryStructure } = this.props;
    const { key } = getLender(primaryStructure.lenderId) || {};
    const bannerUrl = lenderPdpBannerUrl(
      primaryStructure.lenderId,
      locale.data.countryCode,
    );
    const hasBanner = !this.state.lenderImageErrorIds.includes(
      primaryStructure.lenderId,
    );
    return (
      <section className={styles.sectionGroup}>
        <div className={styles.badge}>Selected</div>
        <div className={styles.banner}>
          {hasBanner && (
            <img
              className={styles.logo}
              src={bannerUrl}
              onError={this.handleImageError(primaryStructure.lenderId)}
              alt={primaryStructure.productDetail.lender.name}
            />
          )}
        </div>
        <div className={styles.structuresInfo}>
          {structures.map((s) => this.renderStructure(s, key))}
        </div>
        {structures.length > 1 && this.renderSummarySection()}
      </section>
    );
  }

  renderStructure(structure, key) {
    const {
      intl,
      intl: { formatMessage },
      isLocked,
      handhold,
    } = this.props;
    const {
      productId,
      productDetail,
      loanAmount,
      loanTerm,
      repaymentFrequency,
      repaymentType,
    } = structure;

    const repaymentTerm = API_FIXED_RESULT.includes(
      productDetail.interestRateTypeInitial,
    )
      ? formatMessage(messages.fixedTerm, {
          year: productDetail.initialPeriod / 12,
        })
      : formatMessage(messages.variable);

    return (
      <div className={styles.applyWrapper} key={`structure-${productId}`}>
        <h2 className={styles.mobileOnlyTitle}>
          {formatProductName(productDetail.name)}
        </h2>
        <div className={`${styles.bodyWrapper} ${styles.apply}`}>
          <div className={styles.titleAndButtonGroup}>
            <h2 className={styles.desktopOnlyTitle}>
              {formatProductName(productDetail.name)}
            </h2>
            <div className={styles.buttonsGroup}>
              {!handhold && (
                <Button
                  onClick={this.loadStructureAndGo(
                    structure.id,
                    `/product-detail/${productId}?source=apply`,
                  )}
                  theme='productAction'
                  className={styles[`button${key}`]}
                >
                  {formatMessage(messages.viewDetailText)}
                </Button>
              )}
              {!isLocked && !handhold && (
                <Button
                  onClick={this.loadStructureAndGo(
                    structure.id,
                    `${COMPARE_PAGE_PATH}/?${structureToQueryString(
                      structure,
                    )}`,
                  )}
                  theme='productAction'
                  className={styles[`buttonWhite${key}`]}
                >
                  Select a different loan
                </Button>
              )}
            </div>
          </div>
          <div className={styles.productInfo}>
            <RepaymentHeaderDisplay
              repaymentInitial={productDetail.repaymentInitial}
              repaymentFrequency={repaymentFrequency}
              repaymentType={repaymentType}
              dynamicValue={false}
            />
            <div className={styles.infoGroup}>
              {this.renderLabelAndHint(
                formatMessage(messages.loanTerm, { loanTerm }),
                'Repayment term',
              )}
              {this.renderLabelAndHint(
                formatCurrency(intl)(loanAmount),
                formatMessage(messages.loanAmount),
              )}
            </div>
            <div className={styles.infoGroup}>
              {this.renderLabelAndHint(
                repaymentTerm,
                formatMessage(messages.topDeal),
              )}
              {this.renderLabelAndHint(
                formatRate(intl)(productDetail.rates.initial.rate),
                formatMessage(messages.rate),
              )}
              {this.renderLabelAndHint(
                formatRate(intl)(productDetail.rates.comparison.rate),
                formatMessage(messages.comparison),
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoProduct() {
    const {
      intl: { formatMessage },
      handhold,
    } = this.props;
    return (
      <div className={classNames(styles.noProductWrapper, styles.sectionGroup)}>
        <div className={styles.iconAndTitleSection}>
          <i className='sl-custom-house-2' />
          <div className={styles.textSection}>
            <h4>{formatMessage(messages.title)}</h4>
            <div className={styles.description}>
              {formatMessage(messages.description)}
            </div>
          </div>
        </div>
        {!handhold && (
          <div className={styles.buttonSection}>
            <ButtonNext
              id='next'
              url={`${COMPARE_PAGE_PATH}/?${structureToQueryString(
                DEFAULT_STRUCTURE_INITIAL_STATE,
              )}`}
            >
              {formatMessage(messages.compareButton)}
            </ButtonNext>
          </div>
        )}
      </div>
    );
  }

  renderSummarySection() {
    const { structures, intl } = this.props;
    const addedAmount = monthlyRepaymentForStructures(structures);
    return (
      <div className={styles.summarySection}>
        <h3 className={styles.structureCount}>{structures.length} Loans</h3>
        <div className={styles.repaymentInfo}>
          {this.renderLabelAndHint('Total', 'Initial Repayment')}
          {this.renderLabelAndHint(
            formatCurrency(intl)(addedAmount),
            'Per Month',
          )}
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.structures.length) {
      return (
        <div className={styles.sectionGroupWrapper}>
          {this.renderNoProduct()}
        </div>
      );
    }
    return (
      <div className={styles.sectionGroupWrapper}>
        {this.renderstructures()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  structures: structureSelectors.structures(state),
  primaryStructure: structureSelectors.primaryStructure(state),
  isLocked: getIsLocked(state),
  handhold: isHandholdOn(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadStructure: structureActions.loadStructure,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectedLoanApply)),
);
