/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import moment from 'moment';
import bowser from 'bowser';
import _ from 'lodash';

import { DEFAULT_MIN_DATE, DEFAULT_MAX_DATE } from 'shared/constants/defaults';
import { makeArrayFromRange } from 'lib/utils/dataUtils';

import NSelection from 'components/NSelection/NSelection';
import Input from 'components/Input/Input';

import styles from './DateSelector.css';

export const messages = defineMessages({
  day: {
    id: 'DateSelector.day',
    defaultMessage: 'Day',
  },
  month: {
    id: 'DateSelector.month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'DateSelector.year',
    defaultMessage: 'Year',
  },
});

const processDate = (dayAction, monthAction, yearAction) => (d, M, y) => {
  const daysInNewMonth = moment({ M, y }).daysInMonth();
  if (dayAction) {
    if (d > daysInNewMonth) {
      dayAction();
    } else {
      dayAction(d);
    }
  }
  monthAction(M);
  yearAction(y);
};

const DateSelector = ({
  type,
  dayAction,
  monthAction,
  yearAction,
  day,
  month,
  year,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  forceFocus,
  onFocus,
  yearsOrder = 'ascending',
  disabled,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const showNativeDatePicker = bowser.mobile || bowser.tablet;
  const focusProps = {
    forceFocus,
    onFocus,
  };
  const nSelectFocusProps = showNativeDatePicker ? {} : focusProps;
  const validYear = !!year;
  const validMonthYear = validYear && month >= 0;
  const validFullDate = validMonthYear && day > 0;
  const { date: minDay, months: minMonth, years: minYear } = minDate.toObject();
  const { date: maxDay, months: maxMonth, years: maxYear } = maxDate.toObject();

  const years = makeArrayFromRange(
    minYear,
    maxYear,
    null,
    yearsOrder === 'descending',
  );

  const minSelectMonth = year === minYear ? minMonth : 0;
  const maxSelectMonth = year === maxYear ? maxMonth : 11;
  const months = makeArrayFromRange(
    minSelectMonth,
    maxSelectMonth,
    moment.monthsShort(),
  );

  const minSelectDay = month === minMonth && year === minYear ? minDay : 1;
  const maxSelectDay =
    month === maxMonth && year === maxYear
      ? maxDay
      : moment({ M: month, y: year }).daysInMonth();
  const days = makeArrayFromRange(minSelectDay, maxSelectDay);

  const processValues = _.flow(_.toNumber, (a) => (_.isNaN(a) ? undefined : a));
  const dateProcessor = _.overArgs(
    processDate(dayAction, monthAction, yearAction),
    _.times(3, () => processValues),
  );
  const dispatchDay = _.partial(dateProcessor, _, month, year);
  const dispatchMonth = _.partial(dateProcessor, day, _, year);
  const dispatchYear = _.partial(dateProcessor, day, month);
  const selections = [
    {
      action: dispatchMonth,
      value: month,
      items: months,
      defaultMessage: messages.month,
    },
    {
      action: dispatchYear,
      value: year,
      items: years,
      defaultMessage: messages.year,
    },
  ];

  let inputVal;

  if (type === 'date' || type === 'dateReversed') {
    inputVal = moment({ y: year, M: month, d: day });
    inputVal = validFullDate ? inputVal.format('YYYY-MM-DD') : '';
    const dayPart = {
      action: dispatchDay,
      value: day,
      items: days,
      defaultMessage: messages.day,
    };
    if (type === 'dateReversed') {
      selections.reverse().push(dayPart);
    } else {
      selections.unshift(dayPart);
    }
  } else if (type === 'month') {
    inputVal = moment({ y: year, M: month, d: 1 });
    inputVal = validMonthYear ? inputVal.format('YYYY-MM') : '';
  }

  const rootStyle = styles.main;

  return (
    <div className={rootStyle}>
      {showNativeDatePicker && (
        <Input
          value={inputVal}
          type={type}
          {...focusProps}
          className={styles.dateInput}
          action={(v) => {
            const workingDate = new Date(v);
            const newDay = workingDate.getDate();
            const newMonth = workingDate.getMonth();
            const newYear = workingDate.getFullYear();
            dateProcessor(newDay, newMonth, newYear);
          }}
          min={minDate.format('YYYY-MM-DD')}
          max={maxDate.format('YYYY-MM-DD')}
          disabled={disabled}
        />
      )}
      <NSelection
        nonTabbable={showNativeDatePicker}
        {...nSelectFocusProps}
        selections={selections}
        disabled={disabled}
      />
    </div>
  );
};

DateSelector.propTypes = {
  type: PropTypes.oneOf(['month', 'date', 'dateReversed']).isRequired,
  day: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  month: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dayAction: PropTypes.func,
  monthAction: PropTypes.func.isRequired,
  yearAction: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  yearsOrder: PropTypes.string,
  forceFocus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateSelector;
