const nested = /([\dA-Za-z-]+)?(\/[\dA-Za-z-]+)?(\/[\dA-Za-z-]+)?/;
const NZ_SLUG = 'nz';
const GOAL_SLUG = 'goal';
const APPLY_SLUG = 'apply';
const OKTA_CALLBACK_SLUG = 'authorization-code/callback';

module.exports.nestedURL = /^\/(?!api\/)([\dA-Za-z-]+)?(\/[\dA-Za-z-]+)?(\/[\dA-Za-z-]+)?\/?(index\.html)?$/;
module.exports.WIDGET_REGEX = /^\/widgets\/(\w+)\/?(index\.html)?$/;
module.exports.NZ_SLUG = NZ_SLUG;
module.exports.GOAL_SLUG = GOAL_SLUG;
module.exports.APPLY_SLUG = APPLY_SLUG;
module.exports.NZ_SLUG_REGEX = new RegExp(`^/${NZ_SLUG}($|/|#)`);
module.exports.OKTA_CALLBACK_SLUG_REGEX = new RegExp(
  `^/${OKTA_CALLBACK_SLUG}($|/|#)`,
);
module.exports.GOAL_SLUG_REGEX = new RegExp(
  `^/(${nested.source}/)?${GOAL_SLUG}/?$`,
);
module.exports.APPLY_SLUG_REGEX = new RegExp(
  `^/(${nested.source}/)?${APPLY_SLUG}/?$`,
);
module.exports.ADVISOR_UUID = /^[\dA-Fa-f]{8}(?:-[\dA-Fa-f]{4}){3}-[\dA-Fa-f]{12}$/;
module.exports.ADVISOR_ORG_ID = /^\d+$/;
