/* eslint-disable unicorn/consistent-function-scoping */
import { isIE11 } from 'lib/utils/browserUtils';

let alreadyRun = false;

export default () => (next) => (action) => {
  next(action);
  if (!alreadyRun && isIE11()) {
    alreadyRun = true;
    // TODO: make this lint-compatible
    (function () {
      function CustomEvent(event, params) {
        params = params || {
          bubbles: false,
          cancelable: false,
          detail: undefined,
        };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(
          event,
          params.bubbles,
          params.cancelable,
          params.detail,
        );
        return evt;
      }
      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
      window.Event = CustomEvent;
    })();
  }
};
