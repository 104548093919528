import React from 'react';
import PropTypes from 'prop-types';
import Question from 'components/Question/Question';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import { YES_NO_OPTIONS } from 'constants/options';

const ApplyAdditionalQuestion = ({
  id,
  label,
  hint,
  action,
  value,
  disabled,
}) => (
  <Question id={id} label={label} direction='row' hint={hint}>
    <RadioButtonList
      action={action}
      items={YES_NO_OPTIONS}
      value={value}
      disabled={disabled}
    />
  </Question>
);

ApplyAdditionalQuestion.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  action: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ApplyAdditionalQuestion;
