import update from 'immutability-helper';
import moment from 'moment';
import { connectToMirror } from '@loanmarket/react-redux-mirror';

import _ from 'lodash';
import { logger as coreLogger } from 'lib/coreLogger';
import { NO_ERROR } from 'constants/validators';
import { paramsId } from 'selectors/sharedSelectors';

export const mirrorProperties = {
  mirror: 'scenario',
  subset: ['properties'],
};

export const connectToMirroredProperty = connectToMirror(mirrorProperties);
export const connectToMirroredScenario = connectToMirror({
  mirror: 'scenario',
});

export const mergeActionsWithParamsId = (
  stateProps,
  dispatchProps,
  ownProps,
) => {
  const id = paramsId(undefined, ownProps);
  const dispatchable =
    id === undefined
      ? dispatchProps
      : _.mapValues(dispatchProps, (action) => action(id));
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchable,
  };
};

export const clearWorking = (state, action, defaultNewType = {}) => {
  const id = action.payload || 'new';
  return update(state, {
    working: {
      [id]: { $set: id === 'new' ? defaultNewType : null },
    },
  });
};

export const removeWorking = (state, action) => {
  const id = action.payload;
  if (!id) {
    coreLogger('reducerHelper').warn({
      action: 'Tried removing a working obj that does not exist',
      data: { id },
    });
    return state;
  }

  const keysToRetain = Object.keys(state.working).filter(
    (k) => k !== id.toString(),
  );
  return update(state, {
    working: { $set: _.pick(state.working, keysToRetain) },
  });
};

export const loadEntityIntoWorking = (state, action, defaultNewType = {}) => {
  const id = parseInt(action.payload, 10) || action.payload;
  return update(state, {
    working: {
      [id]: {
        $set: update(defaultNewType, {
          $merge: state.entities.find((e) => e.id === id) || {},
        }),
      },
    },
  });
};

export const setWorkingDob = (state, payload, key) => {
  const { id, value } = payload;
  const isAgeUnit = key === 'ageUnit';
  const existingKey = isAgeUnit ? 'age' : 'ageUnit';
  const existingValue = (state.working[id] || {})[existingKey];
  let age = isAgeUnit ? existingValue : value;
  let ageUnit = isAgeUnit ? value : existingValue;
  if (ageUnit === 'years' && age % 1 > 0) {
    age *= 12;
    ageUnit = 'months';
  }
  const dob = moment().subtract(age, ageUnit).toObject();
  return update(state, {
    working: {
      [id]: {
        dob: {
          day: { $set: dob.date },
          month: { $set: dob.months },
          year: { $set: dob.years },
        },
        [key]: { $set: value },
      },
    },
  });
};

export const setWorkingValue = (state, payload, key, transform = _.identity) =>
  update(state, {
    working: {
      [payload.id]: {
        [key]: {
          $set: transform(payload.value),
        },
      },
    },
  });

export const mergeWorkingValue = (state, payload, transform = _.identity) =>
  update(state, {
    working: {
      [payload.id]: {
        $merge: transform(payload.value),
      },
    },
  });

export const removeEntity = (state, action) => {
  const id = parseInt(action.payload, 10) || action.payload;
  const index = state.entities.findIndex((e) => e.id === id);
  if (index < 0) {
    coreLogger('reducerHelper').warn({
      action: 'Tried to remove an entity that does not exist',
      data: { id },
    });
    return state;
  }
  return update(state, {
    entities: {
      $splice: [[index, 1]],
    },
  });
};

export const setNewEntity = (state, action, transform = _.identity) =>
  update(state, {
    entities: {
      $push: [transform(action.payload)],
    },
  });

export const setNewEntities = (state, action, transform = _.identity) =>
  update(state, {
    entities: {
      $set: action.payload.map(transform),
    },
  });

export const insertNewEntities = (state, action, transform = _.identity) =>
  update(state, {
    entities: {
      $set: _.unionBy(
        state.entities,
        action.payload.map(transform),
        (e) => e.id,
      ),
    },
  });

export const setEntity = (state, action) => {
  const index = state.entities.findIndex((e) => e.id === action.payload.id);
  if (index < 0) {
    coreLogger('reducerHelper').warn({
      action: 'Tried to set entity that does not exist already',
      data: { id: action.payload.id },
    });
    return state;
  }
  return update(state, {
    entities: {
      $splice: [[index, 1, action.payload]],
    },
  });
};

export const mergeEntity = (state, action) => {
  const index = state.entities.findIndex((e) => e.id === action.payload.id);
  if (index < 0) {
    coreLogger('reducerHelper').warn({
      action: 'Tried to set entity that does not exist already',
      data: { id: action.payload.id },
    });
    return state;
  }
  return update(state, {
    entities: {
      [index]: {
        $merge: action.payload,
      },
    },
  });
};

export const setNewOrMergeEntity = (state, action) => {
  const index = state.entities.findIndex((e) => e.id === action.payload.id);
  if (index < 0) {
    return setNewEntity(state, action);
  }
  return mergeEntity(state, action);
};

const clientIdToNumber = (clientId) => {
  return clientId === 'new' ? clientId : _.toNumber(clientId);
};

export const setWorkingClientIds = (state, action) => {
  const { id, value } = action.payload;
  let clientIds = value || [];
  if (typeof value === 'string') {
    clientIds = value.split(',');
  } else if (!Array.isArray(value)) {
    clientIds = [value];
  }
  return update(state, {
    working: {
      [id]: {
        clientIds: { $set: clientIds.map(clientIdToNumber) },
      },
    },
  });
};

export const setWorkingFormattedAddress = (state, payload, key) => {
  const { id, value } = payload;
  const text = value && value.text !== undefined ? value.text : value;

  return update(state, {
    working: {
      [id]: {
        [key]: { formattedAddress: { $set: text } },
      },
    },
  });
};

export const setWorkingIntlAddress = (state, payload, key) => {
  const { id, value } = payload;
  const { countryCode, formattedAddress, placeId } = value || payload;

  return update(state, {
    working: {
      [id]: {
        [key]: {
          formattedAddress: { $set: formattedAddress },
          countryCode: { $set: countryCode },
          placeId: { $set: placeId },
          isCustomAddress: { $set: true },
        },
      },
    },
  });
};

export const setWorkingCountryCode = (state, payload, key) => {
  const { id, value } = payload;
  const text = value && value.text !== undefined ? value.text : value;

  return update(state, {
    working: {
      [id]: {
        [key]: { countryCode: { $set: text } },
      },
    },
  });
};

export const setError = (state, action) => {
  const {
    id,
    value: { id: errorId, text, blocking, type, description },
  } = action.payload;
  const value =
    text === NO_ERROR ? null : { type, text, blocking, description };
  return update(state, {
    working: {
      [id]: {
        errors: {
          [errorId]: { $set: value },
        },
      },
    },
  });
};

export const setApplicationError = (state, action) => {
  const { loanApplicationId, text, blocking } = action.payload;
  return update(state, {
    errors: {
      [loanApplicationId]: {
        $set: text === NO_ERROR ? null : { text, blocking },
      },
    },
  });
};

export const getNewNewId = (working) => {
  const news = Object.keys(working)
    .filter((key) => key.includes('new'))
    .sort();
  const biggestNew = news[news.length - 1] || 'new';
  if (!working[biggestNew]) {
    return biggestNew;
  }
  const match = biggestNew.match(/new(\d*)$/);
  const nextNumber = _.toNumber(match[1]) + 1;

  return `new${nextNumber}`;
};

export default setWorkingValue;
