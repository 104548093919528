/* eslint-disable unicorn/consistent-function-scoping */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from '@loan_market/react-router-redux-multi';
import PropTypes from 'prop-types';

import { useModal } from 'contexts/ModalContext';

import Button from 'components/Button/Button';
import Question from 'components/Question/Question';
import Form from 'components/Form/Form';
import {
  renderQuestionContent,
  getOrderedQuestionKeys,
  getQuestionId,
} from 'lib/goalSetterFormHelper';

import UIActions from 'actions/UIActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';

import { PREFERRED_LOAN_OPTIONS } from 'shared/constants/paths';
import { PROFILE_SECTIONS } from 'constants/profileSections';
import {
  DISPLAY_OPTIONS,
  OPTIONS_TO_QUESTION_IDS,
  LOAN_OPTIONS_YES_NO_QS,
  LOAN_OPTIONS_RISK_QS,
  QUESTION_IDS,
} from 'shared/constants/goalLoanAppInfo';
import { BACK_BUTTON_EVENT } from 'constants/GTMEvents';

import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

import { toGoalDashboard } from 'lib/pathHelper';
import { getSectionFromUrl } from 'lib/utils/browserUtils';

import styles from 'containers/goal/GoalSetterForm/GoalSetterForm.css';
import contentLayoutStyles from 'components/ContentLayout/ContentLayout.css';
import LoanOptionsModal from './GoalPreferencesLoanOptionsModal';

export function GoalPreferencesLoanOptions(props) {
  const {
    questions,
    answers,
    familyId,
    saveLoanAppInfoAnswers,
    active,
    goTo,
    back,
    trackEvent,
    match: { url },
  } = props;
  const [savedAnswers, updateSavedAnswers] = useState({});
  const modal = useModal();

  const saveAnswers = (data) =>
    saveLoanAppInfoAnswers({
      section: PREFERRED_LOAN_OPTIONS,
      answers: data,
      loanAppId: active,
      familyId,
    });

  const onSave = (isBack) => () => {
    const allAnswers = { ...answers, ...savedAnswers };
    const customAnswers = {};
    Object.keys(questions).forEach((questionId) => {
      if (!(allAnswers[questionId] && allAnswers[questionId].length > 0)) {
        customAnswers[LOAN_OPTIONS_YES_NO_QS[questionId]] = false;
      }
    });
    if (Object.keys(customAnswers).length > 0) {
      saveAnswers(customAnswers);
    }
    if (isBack) {
      const sectionName = getSectionFromUrl(url);
      trackEvent({ event: BACK_BUTTON_EVENT, section: sectionName });
      back();
    } else {
      goTo(toGoalDashboard(active, PROFILE_SECTIONS.LOAN_FEATURES));
    }
  };

  const onModalSave = (rootQ, option) => (modalAnswers) => {
    const value =
      rootQ.customDisplayTypeId === DISPLAY_OPTIONS.MULTI_SELECT_POPUP
        ? [...(answers[rootQ.questionId] || []), Number(option)]
        : option;
    updateSavedAnswers({
      ...savedAnswers,
      ...modalAnswers,
      [rootQ.questionId]: value,
    });
    saveAnswers({
      ...modalAnswers,
      [rootQ.questionId]: value,
      [LOAN_OPTIONS_YES_NO_QS[rootQ.questionId]]: true,
      [LOAN_OPTIONS_RISK_QS[rootQ.questionId]]: true,
    });
    modal.hideModal();
  };

  const getClearedAnswers = (subQs) =>
    subQs.reduce((map, a) => {
      map[a.questionId] = '';
      if (a.subQuestions.length > 0) {
        map[a.subQuestions[0].questionId] = '';
      }
      return map;
    }, {});

  const onUnselect = (rootQ, option, subQs) => () => {
    const value =
      rootQ.customDisplayTypeId === DISPLAY_OPTIONS.MULTI_SELECT_POPUP
        ? (answers[rootQ.questionId] || []).filter((o) => o !== Number(option))
        : '';
    const clearedAnswers = getClearedAnswers(subQs);
    updateSavedAnswers({
      ...savedAnswers,
      [rootQ.questionId]: value,
      ...clearedAnswers,
    });
    saveAnswers({
      [rootQ.questionId]: value,
      ...clearedAnswers,
      [LOAN_OPTIONS_RISK_QS[rootQ.questionId]]: false,
    });
    modal.hideModal();
  };

  const getModalQ = (q, key) => {
    const questionIds =
      (OPTIONS_TO_QUESTION_IDS[q.questionId] || {})[key] || key;
    return q.subQuestions.find(
      (r) => r.questionId === getQuestionId(questionIds),
    );
  };

  const onSectionClick = (q, item) => {
    const modalQ = getModalQ(q, item.key);
    const isRatePreferencesQ =
      q.questionId === getQuestionId(QUESTION_IDS.RATE_PREFERENCES);
    const modalAnswers =
      isRatePreferencesQ &&
      answers[q.questionId] &&
      answers[q.questionId] !== item.key
        ? getClearedAnswers(modalQ.subQuestions)
        : savedAnswers;
    const modalContentProps = {
      modalQ,
      answers: { ...answers, ...modalAnswers },
      isSelected: item.isSelected,
      selected: item.key,
      save: onModalSave(q, item.key),
      hideModal: modal.hideModal,
      unselect: onUnselect(q, item.key, modalQ.subQuestions),
    };
    modal.showModal({
      title: modalQ.content,
      content: <LoanOptionsModal {...modalContentProps} />,
      closeOnOverlay: false,
    });
  };

  const renderQuestion = (q) => {
    if (!q) {
      return q;
    }
    return (
      <Question
        id={`${q.questionId}`}
        key={`${q.questionId}`}
        theme='goalSetterForm'
        subtitle={
          q.customDisplayTypeId === DISPLAY_OPTIONS.SINGLE_SELECT_POPUP
            ? '[Choose one]'
            : '[Choose one or more]'
        }
        label={q.content}
      >
        {renderQuestionContent(
          q,
          { ...answers, ...savedAnswers },
          onSectionClick,
        )}
      </Question>
    );
  };

  return (
    <>
      <div className={contentLayoutStyles.backButton}>
        <Button
          id='backButton'
          icon='mi-arrow-left'
          onClick={onSave(true)}
          theme='linkButtonTheme'
        >
          Back
        </Button>
      </div>
      <Form id={PREFERRED_LOAN_OPTIONS} className={styles.root}>
        {getOrderedQuestionKeys(questions, PREFERRED_LOAN_OPTIONS).map((qId) =>
          renderQuestion(questions[qId]),
        )}
        <Button onClick={onSave()} className='brandColor__button' theme='big'>
          Save and Close
        </Button>
      </Form>
    </>
  );
}

GoalPreferencesLoanOptions.propTypes = {
  match: PropTypes.object,
  goTo: PropTypes.func,
  questions: PropTypes.object,
  answers: PropTypes.object,
  active: PropTypes.number,
  saveLoanAppInfoAnswers: PropTypes.func,
  familyId: PropTypes.number,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
};

const mapStateToProps = (state) => {
  const family = clientSelectors.primaryFamily(state);
  return {
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(
      PREFERRED_LOAN_OPTIONS,
    ),
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      family.contactId,
      PREFERRED_LOAN_OPTIONS,
    ),
    familyId: family.contactId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      back: goBack,
      trackEvent: UIActions.trackEvent,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalPreferencesLoanOptions);
