export const SECURITY_GUARANTEE = 0;
export const SECURITY_FIRST_MORTGAGE = 1;
export const SECURITY_SECOND_MORTGAGE = 2;
export const SECURITY_TERM_DEPOSIT = 3;

export const securityTypes = {
  [SECURITY_GUARANTEE]: 'Guarantee',
  [SECURITY_FIRST_MORTGAGE]: 'Registered Mortgage',
  [SECURITY_SECOND_MORTGAGE]: '2nd Registered Mortgage',
  [SECURITY_TERM_DEPOSIT]: 'Term Deposit',
};
