import { createSelector } from 'reselect';
import _ from 'lodash';
import getLender from 'lib/lenderHelper';
import { structureHasProduct } from 'lib/loanReportHelper';

export const workingStructure = (state) => state.structure.working; // this is not an array, only one structure loaded at a time
export const structuresNotFiltered = (state) => state.structure.entities;
export const structures = createSelector(structuresNotFiltered, (all) =>
  all.filter(structureHasProduct),
);

export const getLoanAmount = createSelector(
  workingStructure,
  (working) => (working && working.loanAmount) || 0,
);

// ... remove
export const getLoanTerm = (state) => state.structure.working.loanTerm;
export const getRepaymentType = (state) =>
  state.structure.working.repaymentType;
export const getRepaymentFrequency = (state) =>
  state.structure.working.repaymentFrequency;
export const getSelectedProductId = (state) =>
  state.structure.working.productId;

export const primaryStructure = createSelector(
  structures,
  (selectedstructures) => {
    let structure;
    const primaryLoan = _.maxBy(selectedstructures, (s) => s.loanAmount);

    if (primaryLoan) {
      const lenderInfo = getLender(primaryLoan.lenderId) || {};

      structure = {
        ...primaryLoan,
        lenderInfo,
      };
    }

    return structure;
  },
);

export const sumOfStructureLoanAmount = createSelector(
  structuresNotFiltered,
  (selectedstructures) => _.sumBy(selectedstructures, (s) => s.loanAmount),
);
