import { createSelector } from 'reselect';
import * as applicationSelectors from 'selectors/applicationSelectors';

export const advisor = (state) => state.advisor;

export const featureFlags = createSelector(advisor, (a) => a.featureFlags);

export const subscriptions = createSelector(advisor, (a) => a.subscriptions);

export const hasBankConnectSubscription = createSelector(
  subscriptions,
  applicationSelectors.isBrokerCustomerCare,
  (s, isCustomerCare) => s.bankConnectPlus || !!isCustomerCare,
);

export const hasBankConnectExpenseSubscription = createSelector(
  subscriptions,
  (s) => s.bankConnectPlus,
);

export const hasBluestoneOnlineApplicationFlag = createSelector(
  featureFlags,
  (f) => f.bluestoneOnlineApplication,
);
