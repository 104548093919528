export const isValidProduct = (product) => {
  if (!product) {
    return false;
  }
  const { rates = {}, repayments = {}, repaymentInitial = {} } = product;
  return (
    Object.keys(rates).length > 0 &&
    Object.keys(repayments).length > 0 &&
    Object.keys(repaymentInitial).length > 0
  );
};

export const structureHasProduct = (structure) => {
  return (
    !!structure &&
    !!structure.productId &&
    isValidProduct(structure.productDetail)
  );
};
