import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import {
  hideBodyOverflow,
  dispatchNewEvent,
  cssDurationToMilliseconds,
} from 'lib/utils/browserUtils';
import Button from 'components/Button/Button';
import styles from './FullScreenSection.css';

class FullScreenSection extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isShown: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
    ]).isRequired,
    close: PropTypes.func,
  };

  static handleScroll = _.debounce(
    () => dispatchNewEvent('scroll', window),
    500,
    { leading: true, trailing: false },
  );

  static transitionTimeout = cssDurationToMilliseconds(
    styles.transitionTimeout,
  );

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    hideBodyOverflow(nextProps.isShown);
  }

  render() {
    const { id, title, isShown, close, children } = this.props;
    return (
      <CSSTransitionGroup
        key={`Transition${id}`}
        transitionName={styles.slideUpScreen}
        transitionEnterTimeout={FullScreenSection.transitionTimeout}
        transitionLeaveTimeout={FullScreenSection.transitionTimeout}
      >
        {isShown && (
          <div
            className={styles.root}
            onScroll={FullScreenSection.handleScroll}
          >
            <div className={styles.container}>
              <div className={styles.header}>
                <h2>{title}</h2>
                <div className={styles.button}>
                  <Button id='closePopup' onClick={close} theme='noBorder'>
                    <i className='mi-cross-with-circle' />
                  </Button>
                </div>
              </div>
              {children}
            </div>
          </div>
        )}
      </CSSTransitionGroup>
    );
  }
}

export default FullScreenSection;
