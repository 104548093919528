import { handleActions } from 'redux-actions';
import update from 'immutability-helper';

import * as addressActionTypes from 'actions/addressActionTypes';
import * as individualAddressActionTypes from 'actions/individualAddressActionTypes';
import {
  ADDRESS_TYPE_CURRENT,
  ADDRESS_TYPE_POSTAL,
} from 'shared/constants/myCRMTypes/address';

import {
  setWorkingValue,
  setWorkingClientIds,
  setWorkingFormattedAddress,
  setWorkingIntlAddress,
  removeWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  setError,
  getNewNewId,
} from 'lib/reducerHelper';

const actionTypes = { ...addressActionTypes, ...individualAddressActionTypes };
export const newAddress = {
  clientIds: [],
  startDate: {},
  endDate: {},
  address: {},
  errors: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: null,
  },
};

const addressReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_ADDRESS](state, action) {
      return removeWorking(state, action);
    },
    [actionTypes.LOAD_ADDRESS](state, action) {
      return loadEntityIntoWorking(state, action, newAddress);
    },
    [actionTypes.LOAD_NEW_CURRENT_ADDRESS](state, action) {
      const id = getNewNewId(state.working);
      const clientIds = action.payload;
      return update(state, {
        working: {
          [id]: {
            $set: {
              ...newAddress,
              id,
              clientIds,
              typeId: ADDRESS_TYPE_CURRENT.id,
            },
          },
        },
      });
    },
    [actionTypes.LOAD_NEW_MAIL_ADDRESS](state, action) {
      const id = getNewNewId(state.working);
      const clientIds = action.payload;

      return update(state, {
        working: {
          [id]: {
            $set: {
              ...newAddress,
              id,
              clientIds,
              typeId: ADDRESS_TYPE_POSTAL.id,
            },
          },
        },
      });
    },
    [actionTypes.REMOVE_ADDRESS](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_ADDRESS](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_ADDRESSES](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.SET_ADDRESS](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_ADDRESS_TYPE_ID](state, action) {
      return setWorkingValue(state, action.payload, 'typeId');
    },
    [actionTypes.SET_ADDRESS_CLIENT_IDS](state, action) {
      return setWorkingClientIds(state, action);
    },
    [actionTypes.SET_ADDRESS_OWNERSHIP_TYPE_ID](state, action) {
      return setWorkingValue(state, action.payload, 'ownershipTypeId');
    },
    [actionTypes.SET_ADDRESS_START_DATE_YEAR](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            startDate: { year: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_ADDRESS_START_DATE_MONTH](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            startDate: { month: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_ADDRESS_END_DATE_YEAR](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            endDate: { year: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_ADDRESS_END_DATE_MONTH](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            endDate: { month: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_ADDRESS_ADDRESS](state, action) {
      return setWorkingFormattedAddress(state, action.payload, 'address');
    },
    [actionTypes.SET_INTL_ADDRESS](state, action) {
      return setWorkingIntlAddress(state, action.payload, 'address');
    },
    [actionTypes.SET_ADDRESS_ERROR](state, action) {
      return setError(state, action);
    },
  },
  initialState,
);

export default addressReducer;
