import _ from 'lodash';
import {
  DEFAULT_EXISTING_PROPERTY_ID,
  DEFAULT_LOAN_AMOUNT,
} from '../constants/defaults';
import { lvrCalculator } from './utils';
import { isRefinanceOrRenovate } from './checkLoanPurpose';
import {
  getDefaultExistingProperty,
  getExistingProperties,
  getProspectiveProperty,
} from './propertyHelper';
import { BUYER_SCENARIO_MOVE } from '../constants/loanScenarios';
import { REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE } from '../constants/repaymentTypes';

export function incomeFromSellingExistingProperties(scenario) {
  const existingProperties = getExistingProperties(scenario);
  if (existingProperties.length != null && existingProperties.length > 0) {
    return existingProperties
      .filter((property) => property.intendsToSell)
      .reduce(
        (availableEquity, property) => availableEquity + property.equity,
        0,
      );
  }
  return 0;
}

export function refinanceCostBreakdown(property) {
  const renovationCost = property.renovationCost || 0;
  const additionalFunds = property.additionalFunds || 0;
  const consolidateDebtAmount = property.consolidateDebtAmount || 0;
  const exitFee = property.exitFee || 0;
  const propertyMortgage = property.mortgageAmount || 0;

  const additionalBorrowings =
    Number(renovationCost) +
    Number(additionalFunds) +
    Number(consolidateDebtAmount);
  const refinancedMortgage =
    Number(propertyMortgage) + Number(exitFee) + additionalBorrowings;

  const equityRetained = property.value - refinancedMortgage;
  return {
    refinancedMortgage,
    equityRetained,
    additionalBorrowings,
    LVR: lvrCalculator(refinancedMortgage, property.value),
  };
}

export function calculateCosts(scenario) {
  // The working steps are all wrapped in Number constructors because the server
  // calls this function with params that are Strings
  const loanPurpose = scenario.loanPurpose;
  if (!loanPurpose) {
    return 0;
  }
  if (isRefinanceOrRenovate(loanPurpose)) {
    const refinanceCosts = refinanceCostBreakdown(
      getDefaultExistingProperty(scenario),
    );
    return refinanceCosts.refinancedMortgage;
  }

  const prospectiveProperty = getProspectiveProperty(scenario) || {};
  const propertyValue = prospectiveProperty.value;
  const totalFee = prospectiveProperty.totalFee || 0;
  const movingCost = prospectiveProperty.movingCost || 0;
  const conveyancerCost = prospectiveProperty.conveyancerCost || 0;
  const renovationCost = prospectiveProperty.renovationCost || 0;

  let existingPropertyEquity =
    (scenario.name === BUYER_SCENARIO_MOVE &&
      scenario.properties &&
      incomeFromSellingExistingProperties(scenario)) ||
    0;
  existingPropertyEquity =
    existingPropertyEquity >= 0 ? 0 : Math.abs(existingPropertyEquity);

  const totalOtherCost = prospectiveProperty.otherCosts
    ? prospectiveProperty.otherCosts.reduce(
        (prev, curr) => prev + Number(curr.value),
        0,
      )
    : 0;
  const costOfExistingPropertyRenovation =
    (scenario.name === BUYER_SCENARIO_MOVE &&
      scenario.properties &&
      scenario.properties[DEFAULT_EXISTING_PROPERTY_ID] &&
      scenario.properties[DEFAULT_EXISTING_PROPERTY_ID].renovationCost) ||
    0;

  return (
    Number(propertyValue) +
    Number(totalFee) +
    Number(movingCost) +
    Number(conveyancerCost) +
    Number(costOfExistingPropertyRenovation) +
    Number(renovationCost) +
    Number(existingPropertyEquity) +
    totalOtherCost
  );
}

export function calculateContributions(scenario) {
  const loanPurpose = scenario.loanPurpose;
  const prospectiveProperty = getProspectiveProperty(scenario);

  if (!loanPurpose) {
    return 0;
  }
  if (isRefinanceOrRenovate(loanPurpose)) {
    return 0;
  }

  const depositAmount =
    (prospectiveProperty && prospectiveProperty.depositAmount) || 0;
  const governmentGrant =
    (prospectiveProperty && prospectiveProperty.grant) || 0;

  return Number(depositAmount) + Number(governmentGrant);
}

export default function loanAmountCalculator(scenario) {
  return (
    calculateCosts(scenario) - calculateContributions(scenario) ||
    DEFAULT_LOAN_AMOUNT
  );
}

export function monthlyRepaymentForStructures(structures) {
  return _.sumBy(structures, (s) => {
    const monthlyRepayment = s.productDetail.repaymentInitial.monthly;
    return s.repaymentType === REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE
      ? monthlyRepayment.principalAndInterestAmount
      : monthlyRepayment.interestOnlyAmount;
  });
}
