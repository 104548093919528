import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';
import PropTypes from 'prop-types';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';

import { useModal } from 'contexts/ModalContext';

import goalLoanApplicationActions from 'actions/goal/goalLoanApplicationActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';

import loanApplicationActions from 'actions/loanApplicationActions';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as advisorOrgSelectors from 'selectors/advisorOrgSelectors';

import { TEAM } from 'shared/constants/goalDashboard';
import { PROFILE_SECTIONS } from 'constants/profileSections';
import {
  DISPLAY_OPTIONS,
  QUESTION_IDS,
} from 'shared/constants/goalLoanAppInfo';
import { renderQuestionContent } from 'lib/goalSetterFormHelper';
import { toGoalDashboard } from 'lib/pathHelper';
import locale from 'config/locale';

import LMConciergeLogo from 'assets/loanmarket-concierge.png';
import WealthMarketLogo from 'assets/wealthmarket.png';
import LMHomeNow from 'assets/loanmarket-homenow.png';

import styles from './GoalTeam.css';

// TODO: refactor to reduce cognitive complexity
// eslint-disable-next-line sonarjs/cognitive-complexity
export function GoalTeam(props) {
  const {
    questions,
    answers,
    updateHasVisited,
    active,
    saveLoanAppInfoAnswers,
    familyId,
    goTo,
    fetchQuestionsAndAnswers,
    isPCF,
  } = props;
  const [savedAnswers, updateSavedAnswers] = useState({});
  const {
    LIFESTYLE_INSURANCE_OBLIGATION,
    LIFESTYLE_INSURANCE_ADVISER,
    LIFESTYLE_INSURANCE_ALI,
    LIFESTYLE_INSURANCE_RISK,
    HOME_NOW,
    LIFESTYLE_INSURANCE,
    HOME_NOW_NOTES,
    LIFE_AND_HEALTH,
    GENERAL_INSURANCE_NZ,
    LIFE_AND_HEALTH_DETAILS,
    LIFE_AND_HEALTH_REASON,
    GENERAL_INSURANCE_NZ_DETAILS,
    GENERAL_INSURANCE_NZ_REASON,
  } = QUESTION_IDS;
  const modal = useModal();

  const logos = {
    [LIFESTYLE_INSURANCE_OBLIGATION]: isPCF ? undefined : WealthMarketLogo,
    [HOME_NOW]: LMHomeNow,
  };

  const renderModalTitle = () => (
    <>
      <i className='sl-custom-lightbulb-2' />
      Need to Know
    </>
  );

  const renderModalContent = (action, value) => {
    const onOkClick = () => {
      action(value);
      modal.hideModal();
    };
    return (
      <>
        <div className={styles.modalContent}>
          Confirming you decline the option to review our risk insurance
          requirements and therefore exempt the adviser from any liability or
          loss caused as a result of this decline.
        </div>
        <Button className='brandColor__button' onClick={onOkClick}>
          Ok
        </Button>
      </>
    );
  };

  useEffect(() => {
    updateHasVisited(TEAM);
    if (Object.keys(questions).length === 0) {
      fetchQuestionsAndAnswers({ familyId, loanAppId: active });
    }
  }, []);

  const setAnswer = (questionId, value) => {
    const customAnswers = {};
    if (value === '1') {
      switch (questionId) {
        case LIFESTYLE_INSURANCE_OBLIGATION:
          customAnswers[LIFESTYLE_INSURANCE_ADVISER] = '0';
          customAnswers[LIFESTYLE_INSURANCE_RISK] = '0';
          customAnswers[LIFESTYLE_INSURANCE] = '1';
          break;
        case LIFESTYLE_INSURANCE_ADVISER:
          customAnswers[LIFESTYLE_INSURANCE_OBLIGATION] = '0';
          customAnswers[LIFESTYLE_INSURANCE_RISK] = '0';
          customAnswers[LIFESTYLE_INSURANCE] = '1';
          break;
        case LIFESTYLE_INSURANCE_RISK:
          customAnswers[LIFESTYLE_INSURANCE_OBLIGATION] = '0';
          customAnswers[LIFESTYLE_INSURANCE_ADVISER] = '0';
          customAnswers[LIFESTYLE_INSURANCE] = '0';
          break;
        case LIFE_AND_HEALTH:
          customAnswers[LIFE_AND_HEALTH_REASON] = '';
          break;
        case GENERAL_INSURANCE_NZ:
          customAnswers[GENERAL_INSURANCE_NZ_REASON] = '';
          break;
      }
    }
    if (questionId === HOME_NOW && savedAnswers[HOME_NOW_NOTES] === undefined) {
      customAnswers[HOME_NOW_NOTES] = answers[HOME_NOW_NOTES];
    }
    if (questionId === HOME_NOW_NOTES && savedAnswers[HOME_NOW] === undefined) {
      customAnswers[HOME_NOW] = answers[HOME_NOW];
    }
    if (value === '0') {
      switch (questionId) {
        case LIFESTYLE_INSURANCE_ADVISER:
          customAnswers[LIFESTYLE_INSURANCE_ALI] = '0';
          customAnswers[LIFESTYLE_INSURANCE] = '0';
          break;
        case LIFE_AND_HEALTH:
          customAnswers[LIFE_AND_HEALTH_DETAILS] = '';
          break;
        case GENERAL_INSURANCE_NZ:
          customAnswers[GENERAL_INSURANCE_NZ_DETAILS] = '';
          break;
        case HOME_NOW:
          customAnswers[HOME_NOW_NOTES] = '';
      }
    }
    updateSavedAnswers({
      ...savedAnswers,
      [questionId]: value,
      ...customAnswers,
    });
  };

  const onSave = (isBack) => () => {
    if (Object.keys(savedAnswers).length > 0) {
      saveLoanAppInfoAnswers({
        section: TEAM,
        answers: savedAnswers,
        loanAppId: active,
        familyId,
      });
    }
    if (!isBack) {
      goTo(toGoalDashboard(active, PROFILE_SECTIONS.SERVICES));
    }
  };

  const onNotRightNowClick = (okAction, args) => {
    modal.showModal({
      title: renderModalTitle(),
      content: renderModalContent(okAction, args),
      closeOnOverlay: false,
      theme: 'halfWidth',
    });
  };

  const showSubQ = (questionId) => {
    const allAnswers = { ...answers, ...savedAnswers };
    switch (questionId) {
      case LIFESTYLE_INSURANCE_ADVISER:
        return allAnswers[LIFESTYLE_INSURANCE_OBLIGATION] === '0';
      case LIFESTYLE_INSURANCE_RISK:
        return (
          allAnswers[LIFESTYLE_INSURANCE_OBLIGATION] === '0' &&
          allAnswers[LIFESTYLE_INSURANCE_ADVISER] === '0'
        );
      case HOME_NOW_NOTES:
        return allAnswers[HOME_NOW] === '1';
      case LIFE_AND_HEALTH_DETAILS:
        return allAnswers[LIFE_AND_HEALTH] === '1';
      case LIFE_AND_HEALTH_REASON:
        return allAnswers[LIFE_AND_HEALTH] === '0';
      case GENERAL_INSURANCE_NZ_DETAILS:
        return allAnswers[GENERAL_INSURANCE_NZ] === '1';
      case GENERAL_INSURANCE_NZ_REASON:
        return allAnswers[GENERAL_INSURANCE_NZ] === '0';
      default:
        return true;
    }
  };

  const renderQuestion = (q) => {
    if (!showSubQ(q.questionId)) {
      return null;
    }
    let subtitle = q.customSubcontent;
    if (subtitle && typeof subtitle !== 'string') {
      subtitle = (
        <ul>
          {q.customSubcontent.map((c, idx) => (
            <li key={`${c}-${idx}`}>{c}</li>
          ))}
        </ul>
      );
    } else if (isPCF && typeof subtitle === 'string') {
      subtitle = subtitle.replace('Loan Market', 'Concierge');
    }

    let notes = q.customNotes;
    if (notes && Array.isArray(notes)) {
      notes = (
        <ul>
          {notes.map((c, idx) => (
            <li key={`${c}-${idx}`}>
              {isPCF ? c.replace('loanmarket', 'concierge') : c}
            </li>
          ))}
        </ul>
      );
    }
    const content = isPCF
      ? (q.content || '').replace('Wealth Market', '')
      : q.content;

    const isFullWidthInput = [q.displayTypeId, q.customDisplayTypeId].includes(
      DISPLAY_OPTIONS.TEXT_ONLY,
    );
    const isSingleLineText =
      q.customDisplayTypeId === DISPLAY_OPTIONS.SINGLE_LINE_TEXT;
    const isSingleCheckbox =
      q.customDisplayTypeId === DISPLAY_OPTIONS.SINGLE_CHECK_BOX;
    const qsWithReasonQ = [LIFE_AND_HEALTH, GENERAL_INSURANCE_NZ];
    const question =
      locale.isNZ && qsWithReasonQ.includes(q.questionId)
        ? { ...q, onNotRightNowClick }
        : q;
    const subtitleHeader = q.customSubcontentHeader;

    if (isSingleCheckbox) {
      return (
        <div className={styles.checkboxWrapper}>
          {renderQuestionContent(
            question,
            { ...answers, ...savedAnswers },
            setAnswer,
          )}
        </div>
      );
    }

    return (
      <Question
        id={`${q.questionId}`}
        key={`${q.questionId}`}
        className={isFullWidthInput ? 'fullWidthInput' : ''}
        theme='goalTeam'
        label={content}
        showLabel={!isFullWidthInput || isSingleLineText}
        subtitle={subtitle}
        subtitleHeader={subtitleHeader}
        notes={notes}
        hideTopLine
      >
        {renderQuestionContent(
          question,
          { ...answers, ...savedAnswers },
          setAnswer,
        )}
      </Question>
    );
  };

  const renderSection = (question) => {
    const q = question;
    return (
      <section>
        {logos[q.questionId] && <img src={logos[q.questionId]} />}
        <h5>{q.customTitle}</h5>
        {renderQuestion(q)}
        {q.subQuestions.map(renderQuestion)}
      </section>
    );
  };

  return (
    <ContentLayout
      title={
        locale.isNZ
          ? 'Have you considered the following?'
          : 'Let’s talk about your team!'
      }
      theme='noContentBg'
      saveAction={onSave(true)}
    >
      <div className={styles.root}>
        {locale.isAU && (
          <section>
            <img className={styles.concierge} src={LMConciergeLogo} />
            <h5>We&#39;ll take care of you and your family.</h5>
            <p>
              From insurance, removal and storage services or connecting
              services at your new property - we’ll take care of you.
            </p>
          </section>
        )}
        {questions.map(renderSection)}
      </div>
      <Button onClick={onSave()} className='brandColor__button' theme='big'>
        Save and Close
      </Button>
    </ContentLayout>
  );
}

GoalTeam.propTypes = {
  active: PropTypes.number,
  goTo: PropTypes.func,
  updateHasVisited: PropTypes.func,
  questions: PropTypes.array,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  familyId: PropTypes.number,
  isPCF: PropTypes.bool,
  fetchQuestionsAndAnswers: PropTypes.func,
};

const toSortedArray = (obj) => {
  const returnValue = [];
  Object.keys(obj).forEach((x) => returnValue.push(obj[x]));
  return returnValue.sort((a, b) => (a.index > b.index ? 1 : -1));
};

const mapStateToProps = (state) => {
  const family = clientSelectors.primaryFamily(state);
  const sortedQuestions = toSortedArray(
    goalLoanAppInfoSelectors.questionsForSection(state)(TEAM),
  );
  return {
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    questions: sortedQuestions,
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      family.contactId,
      TEAM,
    ),
    familyId: family.contactId,
    isPCF: advisorOrgSelectors.isPCF(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      updateHasVisited: goalLoanApplicationActions.updateHasVisited,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      fetchQuestionsAndAnswers:
        loanApplicationActions.requestGoalSetterQuestionsAndAnswers,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalTeam);
