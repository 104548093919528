import { createAction } from 'redux-actions';
import _ from 'lodash';

export const actionWithWrapper = (type) => (id) =>
  createAction(type, (payload) => ({ id, value: payload }));

export const createIndividualisedActions = (actionTypes) => {
  const individualActionsValues = Object.values(actionTypes);
  return individualActionsValues.reduce((prev, type) => {
    const next = prev;
    next[type] = actionWithWrapper(type);
    return next;
  }, {});
};

export const bindIndividualActions = (actions, dispatch) =>
  _.each(actions, (action, key) => {
    actions[key] = (id) => (pl) => dispatch(action(id)(pl));
  });
