import { createActions } from 'redux-actions';

export const CLEAR_WORKING_STRUCTURE = 'clearWorkingStructure';
export const LOAD_STRUCTURE = 'loadStructure';
export const SET_STRUCTURE = 'setStructure';
export const REMOVE_STRUCTURE = 'removeStructure';
export const SET_NEW_STRUCTURE = 'setNewStructure';
export const SET_NEW_STRUCTURES = 'setNewStructures';
export const UPDATE_WORKING_STRUCTURE = 'updateWorkingStructure';
export const SET_PRODUCT_ID = 'setProductId';
export const SET_LENDER_ID = 'setLenderId';
export const SAVE_STRUCTURE = 'saveStructure';
export const SELECT_PRODUCT = 'selectProduct';
export const RESUME_WORKING_STRUCTURE = 'resumeWorkingStructure';
export const GOAL_SAVE_STRUCTURE = 'goalSaveStructure';
export const ADD_SPLIT = 'addSplit';
export const EDIT_SPLITS = 'editSplits';
export const REMOVE_SPLIT = 'removeSplit';

export default createActions(
  {},
  CLEAR_WORKING_STRUCTURE,
  LOAD_STRUCTURE,
  SET_STRUCTURE,
  REMOVE_STRUCTURE,
  SET_NEW_STRUCTURE,
  SET_NEW_STRUCTURES,
  UPDATE_WORKING_STRUCTURE,
  SET_PRODUCT_ID,
  SET_LENDER_ID,
  SAVE_STRUCTURE,
  SELECT_PRODUCT,
  RESUME_WORKING_STRUCTURE,
  GOAL_SAVE_STRUCTURE,
  ADD_SPLIT,
  REMOVE_SPLIT,
  EDIT_SPLITS,
);
