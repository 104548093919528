import _ from 'lodash';
import { serializeQueriesForUrl, extractQueries } from 'lib/utils/browserUtils';
import { DEFAULT_STRUCTURE_INITIAL_STATE } from 'shared/constants/defaults';
import { COMPARE_PAGE_PATH } from 'shared/constants/paths';

export const isGroupingProducts = (lenders) =>
  lenders && (lenders.length === 0 || lenders.length >= 4);
export const queryableStructureFields = [
  'selectedLenderIds',
  'loanAmount',
  'loanTerm',
  'interestType',
  'repaymentType',
  'features',
  'productType',
  'lvr',
  'financials',
];

const querableStructure = (structure) =>
  _.pick(structure, queryableStructureFields);
export const structureToQueryString = (structure) =>
  serializeQueriesForUrl(querableStructure(structure));

export const hasStructureChanged = (prevStructure, nextStructure) =>
  !_.isEqual(
    querableStructure(prevStructure),
    querableStructure(nextStructure),
  );
// Listen to parsed query structure object, note it wont have any empty default array which we want to keep for updates
export const buildStructureFromQueryString = (query) => {
  const parsedObj = extractQueries(query);
  parsedObj.selectedLenderIds = (parsedObj.selectedLenderIds || []).map(
    _.toNumber,
  );
  parsedObj.loanAmount = _.toNumber(parsedObj.loanAmount);
  parsedObj.loanTerm = _.toNumber(parsedObj.loanTerm);
  return { ...DEFAULT_STRUCTURE_INITIAL_STATE, ...parsedObj };
};

export const gotoCompareWithStructure = ({
  lenderId,
  goTo,
  updateWorkingStructure,
}) => {
  updateWorkingStructure({ selectedLenderIds: [lenderId] });
  goTo(COMPARE_PAGE_PATH);
};
