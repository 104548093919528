import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import locale from 'config/locale';

import GoalForm from 'components/GoalForm/GoalForm';

import * as applicationSelectors from 'selectors/applicationSelectors';
import loanApplicationActions from 'actions/loanApplicationActions';
import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import applySection from 'hocs/applySection';

import Questions from 'lib/Questions';
import { combineStringsGrammatically } from 'lib/utils/stringUtils';
import { hasKeySelectedFrom } from 'lib/utils/dataUtils';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { featureFlags } from 'lib/rollout';
import { getImportantDateQs } from 'lib/goalLoanHelper';
import { MULTIPLE_CHOICE_REQUIRED } from 'constants/validators';
import {
  GOAL_OPTIONS_PURCHASE,
  GOAL_OPTIONS_REFINANCE,
} from 'shared/constants/myCRMTypes/applications';

const nextButtonProps = (props) => ({
  disabled: !props.formCompleted,
});

// eslint-disable-next-line sonarjs/cognitive-complexity
const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    workingApplication: { primaryGoals: selectedGoals },
    messages,
  } = props;

  const goals = {
    purchaseGoals: {
      values: GOAL_OPTIONS_PURCHASE(locale)(formatMessage),
      label: 'Purchase',
      singleActionLabel: 'Purchase a',
    },
    refinanceGoals: {
      values: GOAL_OPTIONS_REFINANCE(locale)(formatMessage),
      label: 'Refinance',
      singleActionLabel: 'Refinance a',
    },
  };

  const hasGoals = {
    purchaseGoals: hasKeySelectedFrom(
      goals.purchaseGoals.values,
      selectedGoals,
    ),
    refinanceGoals: hasKeySelectedFrom(
      goals.refinanceGoals.values,
      selectedGoals,
    ),
  };

  const tickedSections = Object.values(hasGoals).filter((value) => value);
  const isSingle = tickedSections.length === 1;
  const postfix = !tickedSections.length
    ? ''
    : (isSingle && 'Single') || 'Multi';

  let label = '';

  if (postfix) {
    const singleGoalObject = isSingle
      ? Object.keys(hasGoals).find((key) => hasGoals[key])
      : {};

    label = isSingle
      ? goals[singleGoalObject].singleActionLabel
      : combineStringsGrammatically(
          Object.keys(hasGoals).reduce((accum, curr) => {
            return hasGoals[curr] ? accum.concat(goals[curr].label) : accum;
          }, []),
        );
  }

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(
      messages[`headerDescription${postfix}`],
      postfix ? { label } : {},
    ),
    headerDescription: formatMessage(
      messages[`headerDescription${postfix}`],
      postfix ? { label } : {},
    ),
  };
};

class GoalApply extends Component {
  static displayName = 'GoalApply';
  static propTypes = {
    intl: intlShape.isRequired,
    ...manageQuestionsPropTypes,
    workingApplication: PropTypes.object.isRequired,
    saveAndNext: PropTypes.func.isRequired,
  };

  static questionsToAsk = (applicant) => {
    const questions = new Questions();
    const isImportantDatesEnabled = featureFlags.importantDatesInMilli.isEnabled();
    questions.addBranch(
      ['primaryGoals', MULTIPLE_CHOICE_REQUIRED],
      featureFlags.financialGoalSummary.isEnabled()
        ? 'whatYouWantTodo'
        : 'specificGoal',
      ...getImportantDateQs(isImportantDatesEnabled, applicant),
    );
    return questions.arrayOfQuestions();
  };

  static forceVisibility = true;
  static forceActive = true;

  render() {
    const {
      questions,
      accordionProps: { isLocked },
      workingApplication,
      intl: { formatMessage },
      setPrimaryGoals,
      setSpecificGoal,
      setWhatYouWantTodo,
      setHasImportantDates,
      setHasImportantDateOfType,
      setImportantDateDay,
      setImportantDateMonth,
      setImportantDateYear,
      saveAndNext,
      formCompleted,
      setCurrentQuestionTo,
      messages,
    } = this.props;

    return (
      <GoalForm
        onSubmit={saveAndNext}
        formCompleted={formCompleted}
        formatMessage={formatMessage}
        messages={messages}
        setCurrentQuestionTo={setCurrentQuestionTo}
        setSpecificGoal={setSpecificGoal}
        setWhatYouWantTodo={setWhatYouWantTodo}
        setPrimaryGoals={setPrimaryGoals}
        setHasImportantDates={setHasImportantDates}
        setHasImportantDateOfType={setHasImportantDateOfType}
        setImportantDateYear={setImportantDateYear}
        setImportantDateMonth={setImportantDateMonth}
        setImportantDateDay={setImportantDateDay}
        isMultipleImportantDatesEnabled={featureFlags.importantDatesInMilli.isEnabled()}
        questions={questions}
        workingApplication={workingApplication}
        isLocked={isLocked}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  workingApplication: applicationSelectors.workingApplication(state),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
      setPrimaryGoals: loanApplicationActions.setPrimaryGoals,
      setSpecificGoal: loanApplicationActions.setSpecificGoal,
      setWhatYouWantTodo: loanApplicationActions.setWhatYouWantTodo,
      setHasImportantDates: loanApplicationActions.setHasImportantDates,
      setHasImportantDateOfType:
        loanApplicationActions.setHasImportantDateOfType,
      setImportantDateDay: loanApplicationActions.setImportantDateDay,
      setImportantDateYear: loanApplicationActions.setImportantDateYear,
      setImportantDateMonth: loanApplicationActions.setImportantDateMonth,
    },
    dispatch,
  );
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    dispatchProps.updateLoanApplication(stateProps.workingApplication);
  },
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    applyOwnPropsChecker,
  )(
    manageQuestions(
      applySection({
        iconName: 'sl-custom-flag-1',
        furtherDecoration,
        nextButtonProps,
      })(GoalApply),
      (props) => props.workingApplication,
    ),
  ),
);
