import React from 'react';
import classNames from 'classnames';
import { ERROR_MESSAGES, INCOMPLETE_FORM } from 'constants/validators';
import visibilityHOC from 'hocs/visibility';
import forceFocus from 'hocs/forceFocus';
import PropTypes from 'prop-types';
import SharedButton from '@bit/loanmarket.share.components.button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import styles from './Button.css';

// TODO: NEEDS REFACTORING!
const WrapButton = (props) => {
  const { newTheme, theme: sharedTheme, hasErrorMessage, errorMessage } = props;
  const newProps = {
    ...props,
    theme: newTheme ? classNames(styles[newTheme]) : sharedTheme,
  };
  // spread operator: BAD!
  return (
    <SharedButton
      {...newProps}
      errorMessage={
        hasErrorMessage ? errorMessage || ERROR_MESSAGES[INCOMPLETE_FORM] : ''
      }
      renderErrorMessage={(message) => (
        <ErrorMessage className={styles.error} message={message} />
      )}
    />
  );
};

WrapButton.propTypes = {
  errorMessage: PropTypes.string,
  theme: PropTypes.string,
  hasErrorMessage: PropTypes.bool,
  newTheme: PropTypes.string,
};

export default visibilityHOC(
  forceFocus(WrapButton),
  SharedButton.animationProps,
);
