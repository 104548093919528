import { defineMessages } from 'react-intl';

export const OFFSET_ACCOUNT = {
  key: 'offsetAccount',
  searchParam: 'offset_account',
  ...defineMessages({
    label: {
      id: 'loanFeatures.offsetAccount.label',
      defaultMessage: 'Offset Account',
    },
    simplifiedLabel: {
      id: 'loanFeatures.offsetAccount.simplifiedLabel',
      defaultMessage: 'Offset',
    },
    hint: {
      id: 'loanFeatures.offsetAccount.hint',
      defaultMessage:
        'Money held in an offset account reduces the total balance of the loan, which helps you pay off the loan quicker and save money on interest.',
    },
    tooltip: {
      id: 'loanFeatures.offsetAccount.tooltip',
      defaultMessage:
        'Money held in an offset account reduces the total balance of the loan, which helps you pay off the loan quicker and save money on interest.',
    },
  }),
};

export const REDRAW_FACILITY = {
  key: 'redrawFacility',
  searchParam: 'redraw_available',
  ...defineMessages({
    label: {
      id: 'loanFeatures.redrawFacility.label',
      defaultMessage: 'Redraw Facility',
    },
    simplifiedLabel: {
      id: 'loanFeatures.redrawFacility.simplifiedLabel',
      defaultMessage: 'Redraw',
    },
    hint: {
      id: 'loanFeatures.redrawFacility.hint',
      defaultMessage:
        'Home loans with a redraw facility allow you to quickly redraw any additional repayments you have made, giving you access to fast cash when you need it.',
    },
    tooltip: {
      id: 'loanFeatures.redrawFacility.tooltip',
      defaultMessage:
        'Home loans with a redraw facility allow you to quickly redraw any additional repayments you have made, giving you access to fast cash when you need it.',
    },
  }),
};

export const CREDIT_CARD = {
  key: 'creditCard',
  searchParam: 'credit_card',
  ...defineMessages({
    label: {
      id: 'loanFeatures.creditCard.label',
      defaultMessage: 'Credit Card',
    },
    simplifiedLabel: {
      id: 'loanFeatures.creditCard.simplifiedLabel',
      defaultMessage: 'Credit Card',
    },
    hint: {
      id: 'loanFeatures.creditCard.hint',
      defaultMessage:
        'Save on standard account fees and charges, by choosing a home loan package that includes a credit card.',
    },
    tooltip: {
      id: 'loanFeatures.creditCard.tooltip',
      defaultMessage:
        'Save on standard account fees and charges, by choosing a home loan package that includes a credit card.',
    },
  }),
};

export const PROFESSIONAL_PACK = {
  key: 'professionalPack',
  searchParam: 'professional_pack',
  ...defineMessages({
    label: {
      id: 'loanFeatures.professionalPack.label',
      defaultMessage: 'Professional Pack',
    },
    tooltip: {
      id: 'loanFeatures.professionalPack.tooltip',
      defaultMessage:
        'Professional packages are generally only available on home loan amounts over a particular value. Generally, the greater the loan amount the more likely the lender will be able to offer additional features such as rate discounts.',
    },
  }),
};

export const CHEQUE_BOOK = {
  key: 'chequeBook',
  searchParam: 'cheque_book',
  ...defineMessages({
    label: {
      id: 'loanFeatures.chequeBook.label',
      defaultMessage: 'Cheque Book',
    },
    tooltip: {
      id: 'loanFeatures.chequeBook.tootltip',
      defaultMessage:
        'When finalising the loan, you will receive a blank cheque book from your chosen lender.',
    },
  }),
};

export const NO_ONGOING_FEES = {
  key: 'noOngoingFees',
  searchParam: 'no_ongoing_fees',
  ...defineMessages({
    label: {
      id: 'loanFeatures.noOngoingFees.label',
      defaultMessage: 'No Ongoing Fees',
    },
    tooltip: {
      id: 'loanFeatures.noOngoingFees.tootltip',
      defaultMessage:
        'Loans that have no monthly and annual fees and charges during the loan period.',
    },
  }),
};

export const INTRO_RATE = {
  key: 'introRate',
  searchParam: 'intro_rate',
  ...defineMessages({
    label: {
      id: 'loanFeatures.introRate.label',
      defaultMessage: 'Intro Rate',
    },
    tooltip: {
      id: 'loanFeatures.introRate.tootltip',
      defaultMessage:
        'Also known as the honeymoon rate, intro rate home loans are designed to attract borrowers and are offered for a set period of time, after which the interest rate increases in line with the lenders other mortgage products.',
    },
  }),
};

export const CAPITALISE_LMI = {
  key: 'capitaliseLmi',
  searchParam: 'capitalise_lmi',
  ...defineMessages({
    label: {
      id: 'loanFeatures.capitaliseLmi.label',
      defaultMessage: 'Capitalise LMI',
    },
    tooltip: {
      id: 'loanFeatures.capitaliseLmi.tootltip',
      defaultMessage:
        'Most lenders will allow you to capitalise many of your upfront costs including your LMI into your loan amount, thus reducing your upfront outlay.',
    },
  }),
};

export const NON_CONFORMING = {
  key: 'nonConforming',
  searchParam: 'non_conforming',
  ...defineMessages({
    label: {
      id: 'loanFeatures.nonConforming.label',
      defaultMessage: 'Non Conforming',
    },
    tooltip: {
      id: 'loanFeatures.nonConforming.tootltip',
      defaultMessage: `Non-conforming loans are for applicants who do not adhere to a lender's typical loan underwriting criteria. Common situations are where the applicant has a poor credit history.`,
    },
  }),
};

export const NO_GENUINE_SAVINGS = {
  key: 'noGenuineSavings',
  searchParam: 'no_genuine_savings',
  ...defineMessages({
    label: {
      id: 'loanFeatures.noGenuineSavings.label',
      defaultMessage: 'No Genuine Savings',
    },
    tooltip: {
      id: 'loanFeatures.noGenuineSavings.tootltip',
      defaultMessage: `A deposit given to you as a gift, sale of an asset or from another source that a bank does not consider regular savings, qualifies you for a "non genuine savings" loan.`,
    },
  }),
};

export const LOAN_FEATURES = [OFFSET_ACCOUNT, REDRAW_FACILITY, CREDIT_CARD];

export const LOAN_FEATURES_COMPARE_PAGE = [
  PROFESSIONAL_PACK,
  CHEQUE_BOOK,
  REDRAW_FACILITY,
  NO_ONGOING_FEES,
  INTRO_RATE,
  OFFSET_ACCOUNT,
  CAPITALISE_LMI,
  NON_CONFORMING,
  NO_GENUINE_SAVINGS,
];

export const LOAN_FEATURES_FOR_TEMPLATE = LOAN_FEATURES.reduce(
  (previous, current) => ({
    ...previous,
    [current.key]: current.label,
  }),
  {},
);

export const formatLoanFeatures = (formatMessage) =>
  LOAN_FEATURES.map((f) => ({
    ...f,
    label: formatMessage ? formatMessage(f.label) : f.label.defaultMessage,
    simplifiedLabel:
      f.simplifiedLabel &&
      (formatMessage
        ? formatMessage(f.simplifiedLabel)
        : f.simplifiedLabel.defaultMessage),
    hint:
      f.hint && (formatMessage ? formatMessage(f.hint) : f.hint.defaultMessage),
  }));

export const formatLoanFeaturesOnComparePage = (formatMessage) =>
  LOAN_FEATURES_COMPARE_PAGE.map((f) => ({
    ...f,
    label: formatMessage ? formatMessage(f.label) : f.label.defaultMessage,
    tooltip:
      f.tooltip &&
      (formatMessage ? formatMessage(f.tooltip) : f.tooltip.defaultMessage),
  }));

export default LOAN_FEATURES;
