import { createSelector } from 'reselect';

import { featureFlags } from 'lib/rollout';

import lenders from 'shared/lib/lenderHelper';
import { ACCREDITED_LENDERS_ONLY_QS } from 'shared/constants/goalLoanAppInfo';

export const allQuestions = (state) => state.goalLoanAppInfo.questions;
export const allAnswers = (state) => state.goalLoanAppInfo.answers;
export const accreditedLenders = ({ lender }) => {
  return lender.accreditedLenders.map((l) => {
    const lenderWithCode = lenders.all.find((a) => a.lenderId === l.lenderId);
    return { ...l, key: lenderWithCode?.key };
  });
};

const buildAccreditedLenderOptions = (question, accreditedOnlyQs, lenders) => {
  const { questionId, subQuestions } = question;
  const accreditedLendersOnly = accreditedOnlyQs.includes(questionId);
  return {
    ...question,
    accreditedOnly: accreditedLendersOnly,
    options: accreditedLendersOnly ? lenders : null,
    subQuestions:
      subQuestions &&
      subQuestions.map((q) => {
        return buildAccreditedLenderOptions(q, accreditedOnlyQs, lenders);
      }),
  };
};
export const accreditedLenderIds = createSelector(accreditedLenders, (a) =>
  featureFlags.lendersByAccreditation.isEnabled()
    ? a.map((b) => b.lenderId)
    : undefined,
);
export const questionsForSection = createSelector(
  allQuestions,
  accreditedLenders,
  (questions, lenders) => (section) => {
    const sectionQuestions = questions[section] || {};
    const accreditedLendersOnly =
      featureFlags.lendersByAccreditation.isEnabled() &&
      !!ACCREDITED_LENDERS_ONLY_QS[section];

    if (!accreditedLendersOnly) {
      return sectionQuestions;
    }

    const accreditedOnlyQs = ACCREDITED_LENDERS_ONLY_QS[section];
    return Object.keys(sectionQuestions).reduce((accum, qId) => {
      const question = sectionQuestions[qId];
      return {
        ...accum,
        [qId]: buildAccreditedLenderOptions(
          question,
          accreditedOnlyQs,
          lenders,
        ),
      };
    }, {});
  },
);

export const answersForSection = createSelector(
  allAnswers,
  (answers) => (familyId, section) => (answers[familyId] || {})[section] || {},
);
