export const CLEAR_WORKING_CONTACT = 'clearWorkingContact';
export const LOAD_CONTACT = 'loadContact';
export const SET_CONTACT = 'setContact';
export const REMOVE_CONTACT = 'removeContact';
export const SET_NEW_CONTACT = 'setNewContact';
export const SET_NEW_CONTACTS = 'setNewContacts';
export const MERGE_CONTACT = 'mergeContact';
export const SET_NEW_OR_MERGE_CONTACT = 'setNewOrMergeContact';

export const CREATE_CONTACT = 'createContact';
export const UPDATE_CONTACT = 'updateContact';
export const DELETE_CONTACT = 'deleteContact';
