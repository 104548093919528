import _ from 'lodash';

// test-cicd-deployments-08

export function arrayOfTruthyKeys(object) {
  return _.keys(_.pickBy(object));
}

export function stateExtractor(locality) {
  return locality ? locality.split(' ').slice(-1).pop() : null;
}

export function postcodeExtractor(locality) {
  const postcode = locality.match(/(\d{4})/);
  if (!postcode) {
    return;
  }
  return postcode[0];
}

export function parseLocality(locality) {
  if (locality) {
    const match = locality.match(/(.+),\s(\d+)\s(\w+)/);
    if (match) {
      return match.slice(1);
    }
  }
  return [];
}

export function arrayToTruthyObj(array) {
  if (!array) {
    return {};
  }
  return _.reduce(
    array,
    (result, a) => {
      result[a] = true;
      return result;
    },
    {},
  );
}

export function truthyObjToArray(obj) {
  if (!obj || typeof obj !== 'object') {
    return [];
  }
  const arr = [];
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      arr.push(key);
    }
  });
  return arr;
}

export function formatOptionalStateAndSuburb({ state, suburb }) {
  return `${suburb || ''} ${state || ''}`.trim();
}

const MOBILE_LOCAL_TO_INTL = {
  '04': '+61',
  '02': '+64',
};

export function addCountryPrefixToMobile(mobile) {
  if (mobile && mobile.number) {
    return mobile.number;
  }
  // TODO remove the following after complete international phone number migration
  if (!mobile || typeof mobile !== 'string') {
    throw new Error(`String expected, instead got ${mobile}`);
  }
  const noSpaceMobile = mobile.replace(/\s/g, '');
  if (noSpaceMobile[0] === '+') {
    return noSpaceMobile;
  }
  const startingWith = noSpaceMobile.slice(0, 2);
  return noSpaceMobile.replace(
    /^0/,
    MOBILE_LOCAL_TO_INTL[startingWith] || '+61',
  );
}

export function sanitiseMobileForAuth0(mobile) {
  if (!mobile || typeof mobile !== 'string') {
    throw new Error(`String expected, instead got ${mobile}`);
  }
  const regexp = /[^\d+]/g;
  return mobile.replace(regexp, '');
}

// this beautiful code is from LeverOne = https://gist.github.com/LeverOne/1308368
export function generateUuid(a, b) {
  for (
    b = a = '';
    a++ < 36;
    b +=
      (a * 51) & 52
        ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16)
        : '-'
  ) {} // eslint-disable-line no-empty
  return b;
}
/*
// Here is a human readable version
function(
  a,b                // placeholders
){
  for(               // loop :)
      b=a='';        // b - result , a - numeric variable
      a++<36;        //
      b+=a*51&52  // if "a" is not 9 or 14 or 19 or 24
                  ?  //  return a random number or 4
         (
           a^15      // if "a" is not 15
              ?      // genetate a random number from 0 to 15
           8^Math.random()*
           (a^20?16:4)  // unless "a" is 20, in which case a random number from 8 to 11
              :
           4            //  otherwise 4
           ).toString(16)
                  :
         '-'            //  in other cases (if "a" is 9,14,19,24) insert "-"
      );
  return b
 }
*/

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const numerals = '0123456789';

const allChars = `${alphabet}${alphabet.toLowerCase()}${numerals}`;
const insensitiveChars = `${alphabet}${numerals}`;

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateRandomString = (len, caseSensitive = false) => {
  const chars = caseSensitive ? allChars : insensitiveChars;
  const charlen = chars.length;
  const buf = [];
  const outLen = len || 10;

  for (let i = 0; i < outLen; ++i) {
    buf.push(chars[getRandomInt(0, charlen - 1)]);
  }

  return buf.join('');
};

export const randomBall = () =>
  _.sample(['⚽️', '🏀', '🏈', '⚾️', '🎱', '🏉', '🏐', '🎾']);

export const stringToInt = (value) =>
  (value && parseInt(value, 10)) || undefined;

export const convertKeysToCamelCase = (object) => {
  return _.reduce(
    object,
    (clone, v, k) => {
      if (typeof v === 'object' && !Array.isArray(v)) {
        return { ...clone, [_.camelCase(k)]: convertKeysToCamelCase(v) };
      }
      return { ...clone, [_.camelCase(k)]: v };
    },
    {},
  );
};

export function isEmptyObject(object) {
  return _.isPlainObject(object) && _.isEmpty(object);
}

export const fileDisplayName = (fileName) => {
  if (fileName.length > 30) {
    let shortName = `${fileName.slice(0, 25)}…`;
    const ext = fileName.includes('.') && fileName.split('.').pop();
    if (ext) {
      shortName += `.${ext}`;
    }
    return shortName;
  }
  return fileName;
};

export const getCorrectApplication = (data, loanApplicationId) => {
  return (
    data.find((application) =>
      loanApplicationId
        ? `${application.id}` === `${loanApplicationId}`
        : application.is_active_app,
    ) || _.sortBy(data, 'id')[data.length - 1]
  );
};

export const lvrCalculator = (loanAmount, totalSecurity) =>
  totalSecurity ? loanAmount / totalSecurity : undefined;

// Extracted from https://github.com/airbnb/enzyme/blob/master/docs/guides/jsdom.md for mocking window Object
// Previously needed for window.performance when using sinon lolex, not needed anymore after lolex handles it
export function copyProps(src, target) {
  const props = Object.getOwnPropertyNames(src)
    .filter((prop) => typeof target[prop] === 'undefined')
    .reduce(
      (result, prop) =>
        Object.assign(
          {
            [prop]: Object.getOwnPropertyDescriptor(src, prop),
          },
          result,
        ),
      {},
    );
  Object.defineProperties(target, props);
}

export function getPropertyCaseInsensitive(obj, propName) {
  if (!obj || typeof obj !== 'object' || !propName) {
    return null;
  }
  const key = Object.keys(obj).find(
    (k) => k.toLowerCase() === propName.toLowerCase(),
  );
  return key && obj[key];
}

// TEMP backwards compatibility for mobile number structure
export function intlMobile(mobile) {
  return typeof mobile === 'string' ? mobile : mobile && mobile.number;
}

export const roundOff = (num, dec = 2) => {
  if (dec <= 0) {
    return 0;
  }
  return Math.round(num * 10 ** dec) / 10 ** dec;
};

export const removeFromArray = (array, item) => {
  if (!array) {
    return array;
  }
  if (array.length === 0) {
    return array;
  }

  const indexOfItem = array.indexOf(item);
  if (indexOfItem === -1) {
    return array;
  }
  return [...array.slice(0, indexOfItem), ...array.slice(indexOfItem + 1)];
};

export const decodeHtmlEncodedText = (text) => {
  // add more html encoded characters when needed
  if (!text) {
    return text;
  }
  return text.replace(/&quot;/g, '"');
};

export const tryParseJson = (text = '') => {
  try {
    return JSON.parse(text);
  } catch (error) {
    // NOTE: It'll be difficult to associate this failure with the correct loan application.
    // eslint-disable-next-line no-console
    console.warn(`Failed to parse '${text}' as a JSON object: ${error}`);
    return {};
  }
};
