export const ALL_FINANCIALS = 'all';
export const FULL_DOC_VALUE = 'full';

export const FINANCIALS = [
  {
    key: 'alldoc',
    value: ALL_FINANCIALS,
    label: 'All Doc',
  },
  {
    key: 'fulldoc',
    value: FULL_DOC_VALUE,
    label: 'Full Doc',
    tooltip: `Full doc loans, or 'normal' home loans, are the most common type of home loan used. Includes additional paperwork on-top of the those included in low doc.`,
  },
  {
    key: 'lowdoc',
    value: 'low',
    label: 'Low Doc',
    tooltip:
      'Low doc loans can be used when the client is unable to provide all necessary paperwork the banks requires, mostly for the self-employed.',
  },
  {
    key: 'nodoc',
    value: 'none',
    label: 'No Doc',
    tooltip:
      'Getting harder and harder to obtain although they are still available with the right loan structure and strategy, no doc loans require at least your BAS (business activity statement) for the past year.',
  },
];
