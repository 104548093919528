import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import fundingActions from 'actions/fundingActions';
import UIActions from 'actions/UIActions';

import * as fundingSelectors from 'selectors/fundingSelectors';
import * as propertySelectors from 'selectors/propertySelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import {
  FUNDING_TYPE_REQUIRED,
  FUNDING_TYPE_AVAILABLE,
} from 'shared/constants/myCRMTypes/funding';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import SimpleFundingForm from 'components/PopupForms/SimpleFundingForm';
class FundingPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
    setFundingValue: PropTypes.func.isRequired,
    setFundingName: PropTypes.func.isRequired,
    setFundingDescription: PropTypes.func.isRequired,
    setFundingPropertyId: PropTypes.func.isRequired,
    propertyOptionsForFunding: PropTypes.arrayOf(PropTypes.object).isRequired,
    depositTypes: PropTypes.arrayOf(PropTypes.object),
  };

  onLoadNew() {
    const {
      loadNewFunding,
      propertyId,
      history: { location },
    } = this.props;
    const { type } = (location && location.query) || {};
    loadNewFunding({ propertyId, type });
  }

  title() {
    const { id, working } = this.props;
    const processTitle = super.title() || (id === 'new' ? 'Add' : 'Edit');
    let fundingType = '';
    if (working && working.type) {
      if (working.type === FUNDING_TYPE_REQUIRED) {
        fundingType = 'required';
      }
      if (working.type === FUNDING_TYPE_AVAILABLE) {
        fundingType = 'available';
      }
    }
    return `${processTitle} ${fundingType} funding`;
  }

  isLoaded() {
    const { working, propertyOptionsForFunding } = this.props;

    let loaded = working && working.type;
    if (loaded && working.type === FUNDING_TYPE_REQUIRED) {
      loaded = !!propertyOptionsForFunding.length;
    }

    return loaded;
  }

  renderForm() {
    const {
      isLocked,
      working,
      setFundingValue,
      setFundingName,
      setFundingDescription,
      setFundingPropertyId,
      propertyOptionsForFunding,
      depositTypes,
    } = this.props;

    return (
      <SimpleFundingForm
        isLocked={isLocked}
        working={working}
        setValue={setFundingValue}
        setName={setFundingName}
        setDescription={setFundingDescription}
        setFundingPropertyId={setFundingPropertyId}
        save={this.save}
        remove={this.remove}
        propertyOptionsForFunding={propertyOptionsForFunding}
        depositTypes={depositTypes}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: fundingSelectors.entity(state)(id),
    working: fundingSelectors.working(state)(id),
    propertyOptionsForFunding: propertySelectors.propertyOptionsForFunding(
      state,
    ),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    depositTypes: state.depositType.depositTypes,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';

  return bindActionCreators(
    {
      load: fundingActions.loadFunding,
      create: fundingActions.createFunding,
      update: fundingActions.updateFunding,
      delete: fundingActions.deleteFunding,
      loadNewFunding: fundingActions.loadNewFunding,
      clearWorking: fundingActions.clearWorkingFunding,
      clearErrors: UIActions.clearAsyncRequestErrors,
      setFundingValue: fundingActions.setFundingValue(id),
      setFundingName: fundingActions.setFundingName(id),
      setFundingDescription: fundingActions.setFundingDescription(id),
      setFundingPropertyId: fundingActions.setFundingPropertyId(id),
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FundingPopup),
);
