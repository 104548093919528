export const OWNER_OCCUPIED_RESIDENCE = 'Primary Residence';
export const OWNER_OCCUPIED_INVESTMENT = 'Investment';

export const SINGLE_HOUSEHOLD = 'single';
export const SINGLE_PARENT_HOUSEHOLD = 'singleParentWithChildren';
export const COUPLE_HOUSEHOLD = 'couple';
export const COUPLE_PARENT_HOUSEHOLD = 'coupleWithChildren';

export const WEEKLY = 52;
export const FORTNIGHTLY = 26;
export const MONTHLY = 12;
export const QUARTERLY = 4;
export const HALF_YEARLY = 2;
export const YEARLY = 1;

export const YEARS = 'years';
export const MONTHS = 'months';

export const PROPERTY_OWNED = 'own';
export const PROPERTY_RENT = 'rent';
export const PROPERTY_BOARDING = 'boarding';
export const PROPERTY_WITH_PARENTS = 'livingWithParents';
export const PROPERTY_PURCHASING = 'purchasing';
export const NOT_OWN_PROPERTY_TYPES = [
  PROPERTY_RENT,
  PROPERTY_WITH_PARENTS,
  PROPERTY_BOARDING,
];

export const OKTA_LOGIN_TOKEN_LENGTH = 6;
