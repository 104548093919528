import { GOAL_SETTER_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON } from '../baseApi';

const BASE_API = GOAL_SETTER_API_PATH.LOAN_APPLICATIONS;

export function getLoanApplication(id) {
  return axiosWithAuth.get(`${BASE_API}/${id}`).then(returnJSON);
}

export function putLoanApplication(loanApplication) {
  return axiosWithAuth
    .put(`${BASE_API}/${loanApplication.id}`, loanApplication)
    .then(returnJSON);
}

export function getFundingsFromLoanApplication(id) {
  return axiosWithAuth.get(`${BASE_API}/${id}/fundings`).then(returnJSON);
}

export function getStructuresFromLoanApplication(id) {
  return axiosWithAuth.get(`${BASE_API}/${id}/structures`).then(returnJSON);
}

export function getSecuritiesFromLoanApplication(id) {
  return axiosWithAuth.get(`${BASE_API}/${id}/securities`).then(returnJSON);
}

export function getProperties(id) {
  return axiosWithAuth.get(`${BASE_API}/${id}/properties`).then(returnJSON);
}

export function postFundingForLoanApplication(funding) {
  return axiosWithAuth
    .post(`${BASE_API}/${funding.loanApplicationId}/fundings`, funding)
    .then(returnJSON);
}

export function getAssets(id) {
  return axiosWithAuth.get(`${BASE_API}/${id}/assets`).then(returnJSON);
}

export function putStructures(structure) {
  const { loanApplicationId, id } = structure;
  return axiosWithAuth
    .put(`${BASE_API}/${loanApplicationId}/structures/${id}`, structure)
    .then(returnJSON);
}
