import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { formatCurrency, formatPercentage } from 'lib/intlFormatters';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';

import {
  LIABILITY_CARD_CATEGORY,
  liabilityCategories,
} from 'shared/constants/myCRMTypes/liabilities';

import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import liabilityActions from 'actions/liabilityActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import Button from 'components/Button/Button';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import EditableItem from 'components/EditableItem/EditableItem';
import DonutChart from 'components/DonutChart/DonutChart';
import QuestionHint from 'components/Question/QuestionHint';
import { liabilitiesHint } from 'lib/hintHelper';
import { logEvent, EVENTS } from 'lib/amplitude';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';
import { LIABILITIES_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'LiabilitiesApply.liabilities',
    defaultMessage: 'Liabilities',
  },
  titleDescription: {
    id: 'LiabilitiesApply.titleDescription',
    defaultMessage: 'Tell us about your liabilities',
  },
  headerDescription: {
    id: 'LiabilitiesApply.headerDescription',
    defaultMessage: 'Do you have any liabilities?',
  },
  titleDescriptionCompleted: {
    id: 'LiabilitiesApply.titleDescriptionCompleted',
    defaultMessage:
      '{liabilityValue, plural, =0 {You have no liabilities} other {-{liabilityValue, number, currency}}}',
  },
  headerDescriptionCompleted: {
    id: 'LiabilitiesApply.headerDescriptionCompleted',
    defaultMessage:
      '{liabilityValue, plural, =0 {You have no liabilities} other {Your liabilities total is {liabilityValue, number, currency}}}',
  },
  addLiability: {
    id: 'LiabilitiesApply.addLiability',
    defaultMessage: 'Add a liability',
  },
  editLiability: {
    id: 'LiabilitiesApply.editLiability',
    defaultMessage: 'Edit liability',
  },
  hasLiabilityQuestion: {
    id: 'LiabilitiesApply.hasLiabilityQuestion',
    defaultMessage: 'Do you have any liabilities?',
  },
});

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    totalLiabilities,
    isCompleted,
    warningMessage,
    primaryApplicantsLiabilities,
    primaryApplicantsPartnersLiabilities,
    sharedLiabilities,
  } = props;
  const postfix = isCompleted ? 'Completed' : '';
  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      liabilityValue: totalLiabilities,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      liabilityValue: totalLiabilities,
    }),
    warningMessage,
    liabilities: primaryApplicantsLiabilities.concat(
      primaryApplicantsPartnersLiabilities,
      sharedLiabilities,
    ),
  };
};

const nextButtonProps = ({ isCompleted, hasLiabilities }) => ({
  disabled: !isCompleted && _.isNil(hasLiabilities),
});

const confirmEntities = (props) => {
  const {
    confirmLiabilities,
    applicationId,
    primaryContactLiabilities,
  } = props;
  confirmLiabilities({
    id: applicationId,
    liabilities: primaryContactLiabilities,
  });
};

class LiabilitiesApply extends Component {
  static displayName = 'LiabilitiesApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    liabilities: PropTypes.arrayOf(PropTypes.object).isRequired,
    liabilityChartData: PropTypes.arrayOf(PropTypes.object),
    primaryApplicantsLiabilities: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    primaryApplicantsPartnersLiabilities: PropTypes.arrayOf(PropTypes.object),
    sharedLiabilities: PropTypes.arrayOf(PropTypes.object),
    primaryApplicantsLiabilitiesTotal: PropTypes.number.isRequired,
    primaryApplicantsPartnersLiabilitiesTotal: PropTypes.number,
    sharedLiabilitiesTotal: PropTypes.number,
    totalLiabilities: PropTypes.number,
    setMetadata: PropTypes.func.isRequired,
    hasLiabilities: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    warningMessage: PropTypes.string,
    urlPath: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    applicationId: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    primaryContactLiabilities: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    primaryApplicantsPartnersLiabilities: [],
    sharedLiabilities: [],
  };

  onHasLiabilitiesClick = (value) => {
    this.props.setMetadata({ hasLiabilities: value });
  };

  newLiabilityUrl = () => `${this.props.urlPath}/liabilities/liability/new`;
  editLiabilityUrl = (id) =>
    `${this.props.urlPath}/liabilities/liability/${id}`;

  editableLiabilityItem = (liability) => {
    const {
      accordionProps: { isLocked },
    } = this.props;
    const category = liabilityCategories.find(
      (c) => c.id === liability.category,
    );
    const value =
      liability.category === LIABILITY_CARD_CATEGORY.id
        ? liability.totalCardLimit
        : liability.totalLoanAmount;
    const rightLabel = value
      ? formatCurrency(this.props.intl)(value)
      : undefined;

    return (
      <EditableItem
        key={`liability-${liability.id}`}
        leftIcon={category.icon}
        url={this.editLiabilityUrl(liability.id)}
        leftLabel={category.name}
        leftDescription={liability.description}
        rightLabel={rightLabel}
        rightDescription={rightLabel ? 'Total' : undefined}
        hasWarning={liability.isOldData && !isLocked}
      />
    );
  };

  onAddLiability = () => {
    logEvent(EVENTS.ADD_FINANCIALS, { section: LIABILITIES_SLUG });
  };

  renderYourLiabilities = () => {
    const {
      intl,
      primaryApplicantsLiabilities,
      primaryApplicantsLiabilitiesTotal,
    } = this.props;
    return (
      primaryApplicantsLiabilities.length > 0 && [
        <h4 key='your-title'>You</h4>,
        <ApplyItemContainer
          includeTotal
          totalTitle='Your liabilities'
          totalValue={formatCurrency(intl)(primaryApplicantsLiabilitiesTotal)}
          key='applyItemContainer'
        >
          {primaryApplicantsLiabilities.map(this.editableLiabilityItem)}
        </ApplyItemContainer>,
      ]
    );
  };

  renderPartnersLiabilities = () => {
    const {
      intl,
      primaryApplicantsPartnersLiabilities,
      primaryApplicantsPartnersLiabilitiesTotal,
    } = this.props;
    return (
      primaryApplicantsPartnersLiabilities.length > 0 && [
        <h4 key='partner-title'>Your partner</h4>,
        <ApplyItemContainer
          includeTotal
          totalTitle='Your partner’s liabilities'
          totalValue={formatCurrency(intl)(
            primaryApplicantsPartnersLiabilitiesTotal,
          )}
          key='applyItemContainerPartner'
        >
          {primaryApplicantsPartnersLiabilities.map(this.editableLiabilityItem)}
        </ApplyItemContainer>,
      ]
    );
  };

  renderSharedLiabilities = () => {
    const { intl, sharedLiabilities, sharedLiabilitiesTotal } = this.props;
    return (
      sharedLiabilities.length > 0 && [
        <h4 key='your-title'>You and your partner</h4>,
        <ApplyItemContainer
          includeTotal
          totalTitle='Your shared liabilities'
          totalValue={formatCurrency(intl)(sharedLiabilitiesTotal)}
          key='applyItemContainer'
        >
          {sharedLiabilities.map(this.editableLiabilityItem)}
        </ApplyItemContainer>,
      ]
    );
  };

  renderHasLiabilities() {
    const { intl, totalLiabilities, liabilityChartData } = this.props;
    return (
      <div>
        <DonutChart
          id='liabilities'
          data={liabilityChartData}
          label='Liabilities'
          value={totalLiabilities}
          percentageFormatter={formatPercentage(intl)}
          valueFormatter={formatCurrency(intl)}
          simple
        />
        {this.renderYourLiabilities()}
        {this.renderPartnersLiabilities()}
        {this.renderSharedLiabilities()}
        <QuestionHint id='hasLiabilities' hint={liabilitiesHint} />
      </div>
    );
  }

  renderMaybeNoLiabilties() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      hasLiabilities,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasLiabilities'
        label={formatMessage(messages.hasLiabilityQuestion)}
        hint={liabilitiesHint}
        action={this.onHasLiabilitiesClick}
        value={hasLiabilities}
        disabled={isLocked}
      />
    );
  }

  render() {
    const {
      accordionProps: { isLocked },
      hasLiabilities,
      liabilities,
      intl: { formatMessage },
    } = this.props;

    return (
      <div id='liabilitiesApply'>
        {/* use entity length to decide instead of total value because bank connect creates liability with no value but just repayments */}
        {liabilities.length
          ? this.renderHasLiabilities()
          : this.renderMaybeNoLiabilties()}
        {(liabilities.length || hasLiabilities) && !isLocked && (
          <Button
            url={this.newLiabilityUrl()}
            theme='applyNew'
            icon='sl-custom-credit-card'
            onClick={this.onAddLiability}
          >
            {formatMessage(messages.addLiability)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    liabilities: liabilitySelectors.liabilities(state),
    liabilityChartData: liabilitySelectors.chartData(state),
    primaryApplicantsLiabilities: liabilitySelectors.clientsLiabilities(state)([
      ownProps.primaryApplicant.id,
    ]),
    primaryApplicantsLiabilitiesTotal: liabilitySelectors.clientsLiabilitiesTotal(
      state,
    )([ownProps.primaryApplicant.id]),
    working: liabilitySelectors.workingLiabilities(state),
    primaryContactLiabilities: liabilitySelectors.primaryContactLiabilities(
      state,
    ),
  };
  const clientIds = [ownProps.primaryApplicant.id];
  if (ownProps.primaryApplicantsPartner) {
    clientIds.push(ownProps.primaryApplicantsPartner.id);
    props.primaryApplicantsPartnersLiabilities = liabilitySelectors.clientsLiabilities(
      state,
    )([ownProps.primaryApplicantsPartner.id]);
    props.primaryApplicantsPartnersLiabilitiesTotal = liabilitySelectors.clientsLiabilitiesTotal(
      state,
    )([ownProps.primaryApplicantsPartner.id]);
    props.sharedLiabilities = liabilitySelectors.clientsLiabilities(state)(
      clientIds,
    );
    props.sharedLiabilitiesTotal = liabilitySelectors.clientsLiabilitiesTotal(
      state,
    )(clientIds);
  }
  props.totalLiabilities = liabilitySelectors.totalLiabilities(state);
  props.applicationId = applicationSelectors.getApplicationId(state);
  return props;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmLiabilities: liabilityActions.confirmLiabilities,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      furtherDecoration,
      iconName: 'sl-custom-credit-card',
      nextButtonProps,
      confirmEntities,
    })(LiabilitiesApply),
  ),
);
