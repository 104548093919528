import locale from 'config/locale';

import { ADDRESS_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON, throwJSON } from './baseApi';

export const searchAddressApi = (query, countryCode, withPlaceId) =>
  axiosWithAuth.get(`${ADDRESS_API_PATH}/search`, {
    params: {
      query,
      countryCode: countryCode || locale.data.countryCode,
      withPlaceId,
    },
  });

export const searchAddress = (query, countryCode) =>
  searchAddressApi(query, countryCode, false).then(returnJSON, throwJSON);

export const searchAddressWithPlaceId = (query, countryCode) =>
  searchAddressApi(query, countryCode, true).then(returnJSON, throwJSON);
