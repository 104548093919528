import React from 'react';
import PropTypes from 'prop-types';

import SectionFooter from 'components/SectionFooter/SectionFooter';

import styles from './SectionGroup.css';

const SectionGroup = ({
  id,
  title,
  colour,
  children,
  totalValue,
  valueInfoLabel,
}) => {
  return (
    <div id={id} className={styles.root}>
      {title && (
        <div className={styles.title} style={{ color: colour }}>
          {title}
        </div>
      )}
      {children}
      <SectionFooter
        name={title}
        value={totalValue}
        valueInfoLabel={valueInfoLabel}
        isGroup
      />
    </div>
  );
};

SectionGroup.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  totalValue: PropTypes.string,
  colour: PropTypes.string,
  valueInfoLabel: PropTypes.string,
};

export default SectionGroup;
