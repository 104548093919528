import { takeEvery, all, put, call, select } from 'redux-saga/effects';

import locale from 'config/locale';
import goalLoanAppInfoActions, {
  REQUEST_LOAN_APP_INFO_QUESTIONS,
  REQUEST_LOAN_APP_INFO_ANSWERS,
  SAVE_LOAN_APP_INFO_ANSWERS,
} from 'actions/goal/goalLoanAppInfoActions';
import { LONG_TERM_GOALS } from 'shared/constants/goalLoanAppInfo';

import {
  getAllLoanAppInfoQuestions,
  getAllLoanAppInfoAnswers,
  postLoanAppInfoAnswers,
} from 'services/goal/goalLoanAppInfoApi';
import { monitorAsyncRequest, monitorSpinnerRequest } from 'lib/sagaHelpers.js';
import { getErrorStatus } from 'lib/errorHelper';
import UIActions from 'actions/UIActions';

import * as contactSelectors from 'selectors/contactSelectors';

export function* requestLoanAppInfoQuestions({
  payload: { familyId, sections, featureNames },
}) {
  try {
    yield put(UIActions.pushSpinner('requestLoanAppInfoQuestions'));
    const adviserId = yield select(contactSelectors.getContactBrokerId);
    const questions = yield call(
      getAllLoanAppInfoQuestions,
      familyId,
      locale.isNZ,
      adviserId,
      sections,
      featureNames,
    );
    yield put(goalLoanAppInfoActions.setLoanAppInfoQuestions(questions));
  } catch (error) {
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
  }
  yield put(UIActions.popSpinner('requestLoanAppInfoQuestions'));
}

export function* requestLoanAppInfoAnswers({
  payload: { loanAppId, sections, featureNames },
}) {
  try {
    yield put(UIActions.pushSpinner('requestLoanAppInfoAnswers'));
    const answers = yield call(getAllLoanAppInfoAnswers, loanAppId, sections);
    yield put(goalLoanAppInfoActions.setLoanAppInfoAnswers(answers));
  } catch (error) {
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
  }
  yield put(UIActions.popSpinner('requestLoanAppInfoAnswers'));
}

export function* saveLoanAppInfoAnswers({
  payload: { section, clientId, answers, loanAppId, familyId },
}) {
  try {
    const isLongTermSection = section === LONG_TERM_GOALS;
    yield call(postLoanAppInfoAnswers, section, {
      loanAppId,
      ...(!isLongTermSection && { clientId }),
      familyId,
      answers,
    });
    yield call(requestLoanAppInfoAnswers, { payload: { loanAppId } });
  } catch (error) {
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
    throw error;
  }
}

export default function* goalLoanAppInfoSagas() {
  yield all([
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_LOAN_APP_INFO_QUESTIONS,
      requestLoanAppInfoQuestions,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_LOAN_APP_INFO_ANSWERS,
      requestLoanAppInfoAnswers,
    ),
    yield monitorSpinnerRequest(
      takeEvery,
      SAVE_LOAN_APP_INFO_ANSWERS,
      saveLoanAppInfoAnswers,
    ),
  ]);
}
