import { ADVISORS_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON, throwJSON } from './baseApi';

export const getAdvisorInfo = (params) => {
  return axiosWithAuth
    .get(`${ADVISORS_API_PATH}/`, {
      params,
    })
    .then(returnJSON, throwJSON);
};

export const getAdvisorProfilePhoto = (params) => {
  return axiosWithAuth
    .get(`${ADVISORS_API_PATH}/profile-photo`, {
      params,
    })
    .then(returnJSON, throwJSON);
};

export const getAdviserSubscriptions = (adviserId) => {
  return axiosWithAuth
    .get(`${ADVISORS_API_PATH}/${adviserId}/subscriptions`)
    .then(returnJSON, throwJSON);
};

export const getAdviserFeatureFlags = () => {
  return axiosWithAuth
    .get(`${ADVISORS_API_PATH}/feature-flags`)
    .then(returnJSON, throwJSON);
};
