import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RouteForLoggedin } from 'routes/helpers';

import GoalPreferencesCurrentBank from 'containers/goal/GoalPreferencesCurrentBank/GoalPreferencesCurrentBank';
import GoalPreferencesPreferredLenders from 'containers/goal/GoalPreferencesPreferredLenders/GoalPreferencesPreferredLenders';
import GoalPreferencesLoanOptions from 'containers/goal/GoalPreferencesLoanOptions/GoalPreferencesLoanOptions';

import {
  CURRENT_BANK_PATH,
  PREFERRED_LENDERS_PATH,
  PREFERRED_LOAN_OPTIONS_PATH,
} from 'shared/constants/paths';

const GoalPreferencesRoutes = (props) => {
  const { active } = props;

  return (
    <Switch>
      <RouteForLoggedin
        exact
        path={`/${active}${CURRENT_BANK_PATH}`}
        component={GoalPreferencesCurrentBank}
      />
      <RouteForLoggedin
        exact
        path={`/${active}${PREFERRED_LENDERS_PATH}`}
        component={GoalPreferencesPreferredLenders}
      />
      <RouteForLoggedin
        exact
        path={`/${active}${PREFERRED_LOAN_OPTIONS_PATH}`}
        component={GoalPreferencesLoanOptions}
      />
    </Switch>
  );
};

GoalPreferencesRoutes.propTypes = {
  active: PropTypes.number,
};

export default GoalPreferencesRoutes;
