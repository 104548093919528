import {
  PROPERTY_OWNED,
  PROPERTY_RENT,
  PROPERTY_BOARDING,
  PROPERTY_WITH_PARENTS,
} from 'shared/constants/options';
import {
  ADDRESS_OWNERSHIP_TYPE_RENTING,
  ADDRESS_OWNERSHIP_TYPE_BOARDING,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE,
  ADDRESS_OWNERSHIP_TYPE_WITH_PARENTS,
} from 'shared/constants/myCRMTypes/address';

export const isPropertyOwnedByClient = (property = {}) =>
  property.owned === PROPERTY_OWNED;

export const getPropertyOwnershipById = (ownershipId) => {
  switch (parseInt(ownershipId, 10)) {
    case ADDRESS_OWNERSHIP_TYPE_RENTING.id:
      return PROPERTY_RENT;
    case ADDRESS_OWNERSHIP_TYPE_BOARDING.id:
      return PROPERTY_BOARDING;
    case ADDRESS_OWNERSHIP_TYPE_OWN_HOME.id:
    case ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE.id:
      return PROPERTY_OWNED;
    case ADDRESS_OWNERSHIP_TYPE_WITH_PARENTS.id:
      return PROPERTY_WITH_PARENTS;
    default:
    // return nothing
  }
};

export const getPropertyOwnershipTypeId = (ownershipType) => {
  switch (ownershipType) {
    case PROPERTY_RENT:
      return ADDRESS_OWNERSHIP_TYPE_RENTING.id;
    case PROPERTY_BOARDING:
      return ADDRESS_OWNERSHIP_TYPE_BOARDING.id;
    case PROPERTY_WITH_PARENTS:
      return ADDRESS_OWNERSHIP_TYPE_WITH_PARENTS.id;
    case PROPERTY_OWNED:
      return ADDRESS_OWNERSHIP_TYPE_OWN_HOME.id;
    default:
    // return nothing
  }
};
