import { createActions } from 'redux-actions';

export const LOAD_GOAL_LOAN_APPLICATION = 'loadGoalLoanApplication';
export const UPDATE_GOAL_PREFERENCES = 'updateGoalPreferences';
export const UPDATE_GOAL_LOAN_APPLICATION = 'updateGoalLoanApplication';
export const SET_ACTIVE_GOAL_LOAN_APP = 'setActiveGoalLoanApp';
export const SET_GOAL_LOAN_APPS_LIST = 'setGoalLoanAppsList';
export const SWITCH_GOAL_LOAN_APP = 'switchGoalLoanApp';
export const UPDATE_HAS_VISITED = 'updateHasVisited';
export const SET_GOAL_CLIENT_PROFILE_PERCENTAGES =
  'setGoalClientProfilePercentages';
export const SET_GOAL_CONTACT_SERVICEABILITY_MAX_BORROWS =
  'setGoalContactServiceabilityMaxBorrows';
export const SET_GOAL_PREFERENCES = 'setGoalPreferences';
export const UPDATE_GOAL_PROFILE_PERCENTAGE = 'updateGoalProfilePercentage';
export const UPDATE_GOAL_SERVICEABILITY_MAX_BORROW =
  'updateGoalServiceabilityMaxBorrow';

const actionTypes = {
  LOAD_GOAL_LOAN_APPLICATION,
  UPDATE_GOAL_PREFERENCES,
  UPDATE_GOAL_LOAN_APPLICATION,
  SET_ACTIVE_GOAL_LOAN_APP,
  SET_GOAL_LOAN_APPS_LIST,
  SWITCH_GOAL_LOAN_APP,
  UPDATE_HAS_VISITED,
  SET_GOAL_CLIENT_PROFILE_PERCENTAGES,
  SET_GOAL_CONTACT_SERVICEABILITY_MAX_BORROWS,
  SET_GOAL_PREFERENCES,
  UPDATE_GOAL_PROFILE_PERCENTAGE,
  UPDATE_GOAL_SERVICEABILITY_MAX_BORROW,
};

export default createActions({}, ...Object.values(actionTypes));
