import { WEEKLY } from '../constants/options';

export default function repaymentCalculator({
  interestRate = 0,
  loanAmount = 0,
  loanYears = 30,
  ongoingAnnualFee = 0,
}) {
  const monthlyInterestRate = interestRate / 100 / 12;
  const months = 12 * loanYears;
  const sub = (1 + monthlyInterestRate) ** -months;

  return (monthlyInterestRate * loanAmount) / (1 - sub) + ongoingAnnualFee / 12;
}

export function interestOnlyRepaymentCalculator(
  interestRate,
  loanAmount,
  frequency = WEEKLY,
) {
  return (interestRate / 100) * (loanAmount / frequency);
}

export function creditCardMonthlyRepaymentCalculator(cardLimit) {
  return 0.03 * cardLimit || 0;
}
