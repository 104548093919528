import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

import * as expenseActionTypes from 'actions/expenseActionTypes';
import * as individualExpenseActionTypes from 'actions/individualExpenseActionTypes';
import { entity } from 'selectors/expenseSelectors';

import { newExpense } from 'constants/expenseDefaults';
import { unformatCurrency } from 'shared/lib/numbrero';

import {
  setEntity,
  setWorkingValue,
  setWorkingClientIds,
  clearWorking,
  removeEntity,
  setNewEntity,
  setNewEntities,
  insertNewEntities,
  setError,
} from 'lib/reducerHelper';

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newExpense,
  },
};

const actionTypes = { ...expenseActionTypes, ...individualExpenseActionTypes };
const expenseReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_EXPENSE](state, action) {
      return clearWorking(state, action, newExpense);
    },
    [actionTypes.LOAD_EXPENSE](state, action) {
      const working = update(newExpense, {
        $merge: entity({ expense: state })(action.payload),
      });
      return update(state, {
        working: {
          [action.payload]: { $set: working },
        },
      });
    },
    [actionTypes.REMOVE_EXPENSE](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_EXPENSE](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_EXPENSES](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.INSERT_NEW_EXPENSES](state, action) {
      return insertNewEntities(state, action);
    },
    [actionTypes.SET_EXPENSE](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_EXPENSE_TYPE_ID](state, action) {
      return setWorkingValue(state, action.payload, 'typeId');
    },
    [actionTypes.SET_EXPENSE_FREQUENCY](state, action) {
      return setWorkingValue(state, action.payload, 'frequency');
    },
    [actionTypes.SET_EXPENSE_PERCENTAGE](state, action) {
      return setWorkingValue(state, action.payload, 'percentage');
    },
    [actionTypes.SET_EXPENSE_VALUE](state, action) {
      return setWorkingValue(state, action.payload, 'value', unformatCurrency);
    },
    [actionTypes.SET_EXPENSE_DESCRIPTION](state, action) {
      return setWorkingValue(state, action.payload, 'description');
    },
    [actionTypes.SET_EXPENSE_CLIENT_IDS](state, action) {
      return setWorkingClientIds(state, action);
    },
    [actionTypes.SET_EXPENSE_ERROR](state, action) {
      return setError(state, action);
    },
  },
  initialState,
);

export default expenseReducer;
