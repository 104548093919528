/**
 * @formUtils
 * These utils are for
 * functions related to specific form/state values
 */
import _uniq from 'lodash/uniq';
import _sortBy from 'lodash/sortBy';
import _toLower from 'lodash/toLower';
import moment from 'moment';

import {
  SINGLE_PARENT_HOUSEHOLD,
  COUPLE_HOUSEHOLD,
  COUPLE_PARENT_HOUSEHOLD,
  PROPERTY_OWNED,
} from 'shared/constants/options';
import { formatOptionalStateAndSuburb } from 'shared/lib/utils';
import { combineStringsGrammatically } from './stringUtils';

export function householdIsParent(household) {
  return [SINGLE_PARENT_HOUSEHOLD, COUPLE_PARENT_HOUSEHOLD].includes(household);
}

export function householdIsCouple(household) {
  return [COUPLE_HOUSEHOLD, COUPLE_PARENT_HOUSEHOLD].includes(household);
}

export function propertyDescriptionGenerator(property) {
  return property.owned === PROPERTY_OWNED
    ? property.address.formattedAddress
    : `property to purchase in ${formatOptionalStateAndSuburb(property)}`;
}

export const makeFullNameString = ({ firstName, middleName, lastName }) =>
  `${firstName}${middleName ? ` ${middleName} ` : ' '}${lastName || ''}`.trim();

export const makeFormalDisplayName = ({
  preferredName,
  firstName,
  middleName,
  lastName,
}) => {
  return (firstName && lastName) || !preferredName
    ? makeFullNameString({ firstName, middleName, lastName })
    : preferredName;
};

export function makeCasualDisplayName(client) {
  return client.preferredName || client.firstName;
}

export function isTomorrowWorkDay() {
  const dayOfWeek = moment().day();

  return ![5, 6].includes(dayOfWeek);
}

export const getContactIds = (applicants) =>
  _uniq(applicants.map((a) => ({ id: a.contactId })));

export const groupClientsToFamilies = (clients) => {
  if (!clients) {
    return [];
  }

  const sortRef = [];

  const groupFamilies = clients.reduce((accum, curr) => {
    if (!accum[curr.contactId]) {
      accum[curr.contactId] = [];
      sortRef.push(curr.contactId);
    }

    accum[curr.contactId].push(curr);
    return accum;
  }, {});

  const families = Object.keys(groupFamilies).map((contactId) => {
    const family = groupFamilies[contactId];
    const isSingleClient = family.length === 1;
    const firstClient = family[0];

    let familyFullName;
    let familyFirstName;
    if (isSingleClient) {
      familyFullName = firstClient.displayFullName;
      familyFirstName = firstClient.displayName;
    } else if (family.length >= 2) {
      const secondClient = family[1];
      const isSameLastName =
        _toLower(firstClient.lastName) === _toLower(secondClient.lastName);
      familyFullName = combineStringsGrammatically([
        isSameLastName ? firstClient.displayName : firstClient.displayFullName,
        secondClient.displayFullName,
      ]);
      familyFirstName = combineStringsGrammatically(
        [firstClient.displayName, secondClient.displayName],
        true,
      );
    }
    return {
      contactId: Number(contactId),
      familyFullName,
      familyFirstName,
      clients: family,
    };
  });

  return _sortBy(families, (f) => sortRef.indexOf(f.contactId));
};
