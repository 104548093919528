import { GOAL_SETTER_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON, throwJSON } from '../baseApi';

const BASE_API = GOAL_SETTER_API_PATH.LOAN_APP_INFO;

export function getLoanAppInfoQuestions(section, familyId) {
  return axiosWithAuth
    .get(`${BASE_API}/${section}/questions?familyId=${familyId}`)
    .then(returnJSON);
}

export function getLoanAppInfoAnswers(section, loanAppId, familyId) {
  return axiosWithAuth
    .get(
      `${BASE_API}/${section}/answers?loanAppId=${loanAppId}&familyId=${familyId}`,
    )
    .then(returnJSON);
}

export function getAllLoanAppInfoQuestions(
  familyId,
  isNZ,
  brokerId,
  sections = [],
  featureNames = [],
) {
  return axiosWithAuth
    .get(`${BASE_API}/questions`, {
      params: {
        familyId,
        isNZ,
        brokerId,
        sections: sections.join(','),
        featureNames: featureNames.join(','),
      },
    })
    .then(returnJSON)
    .catch(throwJSON);
}

export function getAllLoanAppInfoAnswers(loanAppId, sections = []) {
  return axiosWithAuth
    .get(`${BASE_API}/answers`, {
      params: {
        loanAppId,
        sections: sections.join(','),
      },
    })
    .then(returnJSON);
}

export function postLoanAppInfoAnswers(section, data) {
  return axiosWithAuth
    .post(`${BASE_API}/${section}/answers`, data)
    .then(returnJSON);
}
