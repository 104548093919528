import { PRODUCTS_API_PATH } from 'shared/constants/apiPaths';

import axios, { returnJSON } from './baseApi';

export function getLowestRateProduct(params) {
  return axios
    .create()
    .get(`${PRODUCTS_API_PATH}/lowestRateProduct`, { params })
    .then(returnJSON);
}

export function getLowestRate(params) {
  return axios
    .create()
    .get(`${PRODUCTS_API_PATH}/lowestRate`, { params })
    .then(returnJSON)
    .then((data) => data.interestRate);
}
