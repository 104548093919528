import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { REQUIRED } from 'constants/validators';

import { documentCategoryOptions } from 'constants/documents';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import Question from 'components/Question/Question';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Questions from 'lib/Questions';

import styles from './Form.css';

const messages = defineMessages({
  categoryRadio: {
    id: 'DocumentForm.categoryRadio',
    defaultMessage: 'What type of document?',
  },
  categorySelect: {
    id: 'DocumentForm.categorySelect',
    defaultMessage: 'Document type',
  },
  save: {
    id: 'DocumentForm.save',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'DocumentForm.remove',
    defaultMessage: 'Remove',
  },
  questionTypeLabel: {
    id: 'DocumentForm.questionTypeLabel',
    defaultMessage: '{categoryName} type',
  },
});

const questionSet = (props) => {
  const questions = new Questions();

  questions.addBranch(['category', REQUIRED]);
  if (props.category) {
    questions.addBranch(['type', REQUIRED], 'saveButton', 'removeButton');
  }
  return questions.arrayOfQuestions();
};

export class DisconnectedDocumentForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    subType: PropTypes.string,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func,
    setDocumentType: PropTypes.func.isRequired,
    setDocumentCategory: PropTypes.func.isRequired,
    setDocumentError: PropTypes.func.isRequired,
    isLocked: PropTypes.bool,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;

  static revealMethod = 'chunks';
  static revealOverBranch = false;

  renderDocumentType = () => {
    const {
      working: { category, type },
      questions,
      intl: { formatMessage },
      setDocumentType,
      isLocked,
      documentTypes,
    } = this.props;
    // This additional logic has to be repeated here because of the unhandled intl.
    if (category) {
      const categoryName = documentCategoryOptions().find(
        (c) => c.value === category,
      ).name;
      const documentTypeOptions = documentTypes[category];
      return (
        <Question
          {...questions.type}
          label={formatMessage(messages.questionTypeLabel, { categoryName })}
          className='fullWidthInput'
        >
          <Selection
            action={setDocumentType}
            value={type}
            items={documentTypeOptions}
            disabled={isLocked}
          />
        </Question>
      );
    }
    return null;
  };

  render() {
    const {
      working,
      questions,
      save,
      formCompleted,
      intl,
      setDocumentCategory,
      remove,
      isLocked,
    } = this.props;
    const { category } = working;
    return (
      <div>
        {!category ? (
          <Question
            {...questions.category}
            label={intl.formatMessage(messages.categoryRadio)}
            className='fullWidthInput'
          >
            <RadioButtonList
              action={setDocumentCategory}
              value={category}
              items={documentCategoryOptions()}
              disabled={!working.id || isLocked}
            />
          </Question>
        ) : (
          <Question
            {...questions.category}
            label={intl.formatMessage(messages.categorySelect)}
            className='fullWidthInput'
          >
            <Selection
              action={setDocumentCategory}
              value={category}
              items={documentCategoryOptions()}
              disabled={!working.id || isLocked}
            />
          </Question>
        )}

        {this.renderDocumentType()}
        <Question {...questions.saveButton} className='fullWidthButton'>
          <Button
            theme='linkButton'
            disabled={!formCompleted}
            onClick={() => save(working)}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </Question>
        {questions.removeButton && <hr className={styles.hr} />}
        <Question {...questions.removeButton} className='fullWidthButton'>
          <Button className='brandColor__reverseButton' onClick={remove}>
            <i className='sl-custom-bin-2' key='1' />
            {intl.formatMessage(messages.remove)}
          </Button>
        </Question>
        <input className='hidden' disabled={!formCompleted} type='submit' />
      </div>
    );
  }
}

export default injectIntl(
  manageQuestions(DisconnectedDocumentForm, (props) => props.working),
);
