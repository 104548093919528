import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import locale from 'config/locale';

import { convertFrequency } from 'shared/lib/numbrero';
import {
  formatCurrency,
  formatCurrencyPmMedium,
  formatFrequency,
  formatPercentage,
} from 'lib/intlFormatters';
import { incomesHint } from 'lib/hintHelper';
import { logEvent, EVENTS } from 'lib/amplitude';

import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { MONTHLY } from 'shared/constants/options';

import * as incomeSelectors from 'selectors/incomeSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as incomeTypeSelectors from 'selectors/incomeTypeSelectors';

import incomeActions from 'actions/incomeActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import Button from 'components/Button/Button';
import EditableItem from 'components/EditableItem/EditableItem';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import DonutChart from 'components/DonutChart/DonutChart';
import QuestionHint from 'components/Question/QuestionHint';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';
import { INCOMES_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'IncomesApply.title',
    defaultMessage: 'Income',
  },
  titleDescription: {
    id: 'IncomesApply.titleDescription',
    defaultMessage: 'Tell us about your income',
  },
  headerDescription: {
    id: 'IncomesApply.headerDescription',
    defaultMessage: 'Let’s find out about your income.',
  },
  titleDescriptionCompleted: {
    id: 'IncomesApply.titleDescriptionCompleted',
    defaultMessage:
      '{incomesValue, plural, =0 {You have no incomes} other {+{incomesValue, number, currency} p.m.}}',
  },
  headerDescriptionCompleted: {
    id: 'IncomesApply.headerDescriptionCompleted',
    defaultMessage:
      '{incomesValue, plural, =0 {You have no incomes} other {Your income is {incomesValue, number, currency} per month.}}',
  },
  addIncome: {
    id: 'IncomesApply.addIncome',
    defaultMessage: 'Add an income',
  },
  editIncome: {
    id: 'IncomesApply.editIncome',
    defaultMessage: 'Edit income',
  },
  hasIncomeQuestion: {
    id: 'IncomesApply.hasIncomeQuestion',
    defaultMessage: 'Do you have any income?',
  },
});

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    totalIncomes: incomesValue,
    isCompleted,
    warningMessage,
  } = props;
  const postfix = isCompleted ? 'Completed' : '';

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      incomesValue,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      incomesValue,
    }),
    warningMessage,
  };
};

const nextButtonProps = ({ isCompleted, hasIncomes }) => ({
  disabled: !isCompleted && _.isNil(hasIncomes),
});

const confirmEntities = (props) => {
  const { confirmIncomes, primaryContactIncomes, applicationId } = props;
  confirmIncomes({ id: applicationId, incomes: primaryContactIncomes });
};

class IncomesApply extends Component {
  static displayName = 'IncomesApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    incomeChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryApplicantsIncomes: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryApplicantsPartnersIncomes: PropTypes.arrayOf(PropTypes.object),
    sharedIncomes: PropTypes.arrayOf(PropTypes.object),
    primaryApplicantsIncomesTotal: PropTypes.number,
    primaryApplicantsPartnersIncomesTotal: PropTypes.number,
    sharedIncomesTotal: PropTypes.number,
    totalIncomes: PropTypes.number,
    hasIncomes: PropTypes.bool,
    setMetadata: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    warningMessage: PropTypes.string,
    urlPath: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    applicationId: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    primaryContactIncomes: PropTypes.arrayOf(PropTypes.object),
    incomeTypeCategories: PropTypes.arrayOf(PropTypes.object),
    rentalIncomeCategory: PropTypes.object,
  };

  static defaultProps = {
    primaryApplicantsPartnersIncomes: [],
    sharedIncomes: [],
  };

  onHasIncomesClick = (value) => {
    this.props.setMetadata({ hasIncomes: value });
  };

  incomePopupUrl = (id) =>
    `${this.props.urlPath}/incomes/income/${id || 'new'}`;

  editableIncomeItem = (income) => {
    const {
      accordionProps: { isLocked },
      incomeTypeCategories,
      rentalIncomeCategory,
    } = this.props;
    const translation =
      (incomeTypeCategories || []).find(
        (c) => c.value === income.category.toString(),
      ) || rentalIncomeCategory;

    let incomeType = '';
    if (locale.isNZ) {
      incomeType = income.isGross ? ' (gross)' : ' (net)';
    }
    return (
      <EditableItem
        leftIcon={translation.icon}
        key={`editableIncome-${income.id}`}
        url={this.incomePopupUrl(income.id)}
        leftDescription={income.description}
        leftLabel={translation.label}
        rightLabel={`${formatCurrency(this.props.intl)(
          convertFrequency(
            income.value,
            parseInt(income.frequency, 10),
            MONTHLY,
          ),
        )}${incomeType}`}
        rightDescription='p/month'
        hasWarning={income.isOldData && !isLocked}
      />
    );
  };

  onAddIncome = () => {
    logEvent(EVENTS.ADD_FINANCIALS, { section: INCOMES_SLUG });
  };

  renderYourIncomes = () => {
    const {
      intl,
      primaryApplicantsIncomes,
      primaryApplicantsIncomesTotal,
    } = this.props;
    return (
      primaryApplicantsIncomes.length > 0 && [
        <h4 key='yourIncomeTitle'>You</h4>,
        <ApplyItemContainer
          key='YourIncomeItemContainer'
          includeTotal
          totalTitle='Your total income per month'
          totalValue={formatCurrencyPmMedium(intl)(
            primaryApplicantsIncomesTotal,
          )}
        >
          {primaryApplicantsIncomes.map(this.editableIncomeItem)}
        </ApplyItemContainer>,
      ]
    );
  };

  renderYourPartnersIncomes = () => {
    const {
      intl,
      primaryApplicantsPartnersIncomes,
      primaryApplicantsPartnersIncomesTotal,
    } = this.props;
    return (
      primaryApplicantsPartnersIncomes.length > 0 && [
        <h4 key='title'>Your partner</h4>,
        <ApplyItemContainer
          key='YourPartnersIncomeItemContainer'
          includeTotal
          totalTitle='Your partner’s total income per month'
          totalValue={formatCurrencyPmMedium(intl)(
            primaryApplicantsPartnersIncomesTotal,
          )}
        >
          {primaryApplicantsPartnersIncomes.map(this.editableIncomeItem)}
        </ApplyItemContainer>,
      ]
    );
  };

  renderSharedIncomes = () => {
    const { intl, sharedIncomes, sharedIncomesTotal } = this.props;
    return (
      sharedIncomes &&
      sharedIncomes.length > 0 && [
        <h4 key='title'>You and your partner</h4>,
        <ApplyItemContainer
          key='YourPartnersIncomeItemContainer'
          includeTotal
          totalTitle='Your shared total income per month'
          totalValue={formatCurrencyPmMedium(intl)(sharedIncomesTotal)}
        >
          {sharedIncomes.map(this.editableIncomeItem)}
        </ApplyItemContainer>,
      ]
    );
  };

  renderHasIncomes() {
    const { intl, incomeChartData, totalIncomes } = this.props;
    return (
      <div>
        <DonutChart
          id='incomes'
          data={incomeChartData}
          label='Income'
          value={totalIncomes}
          percentageFormatter={formatPercentage(intl)}
          valueFormatter={formatCurrency(intl)}
          frequencyLabel={formatFrequency(intl, MONTHLY)}
          simple
        />
        {this.renderYourIncomes()}
        {this.renderYourPartnersIncomes()}
        {this.renderSharedIncomes()}
        <QuestionHint id='hasIncomes' hint={incomesHint} />
      </div>
    );
  }

  renderMaybeNoIncomes() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      hasIncomes,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasIncomes'
        label={formatMessage(messages.hasIncomeQuestion)}
        hint={incomesHint}
        action={this.onHasIncomesClick}
        value={hasIncomes}
        disabled={isLocked}
      />
    );
  }

  render() {
    const {
      intl: { formatMessage },
      accordionProps: { isLocked },
      totalIncomes,
      hasIncomes,
    } = this.props;

    return (
      <div id='incomesApply'>
        {totalIncomes ? this.renderHasIncomes() : this.renderMaybeNoIncomes()}
        {(totalIncomes || hasIncomes) && !isLocked && (
          <Button
            url={this.incomePopupUrl()}
            theme='applyNew'
            icon='sl-custom-coins-1'
            onClick={this.onAddIncome}
          >
            {formatMessage(messages.addIncome)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const props = {
    incomeChartData: incomeSelectors.chartData(state),
    primaryApplicantsIncomes: incomeSelectors.clientIncomes(state)([
      ownProps.primaryApplicant.id,
    ]),
    primaryApplicantsIncomesTotal: incomeSelectors.clientIncomesMonthlyTotal(
      state,
    )([ownProps.primaryApplicant.id]),
    primaryContactIncomes: incomeSelectors.primaryContactIncomes(state),
  };
  const clientIds = [ownProps.primaryApplicant.id];
  if (ownProps.primaryApplicantsPartner) {
    clientIds.push(ownProps.primaryApplicantsPartner.id);
    props.primaryApplicantsPartnersIncomes = incomeSelectors.clientIncomes(
      state,
    )([ownProps.primaryApplicantsPartner.id]);
    props.primaryApplicantsPartnersIncomesTotal = incomeSelectors.clientIncomesMonthlyTotal(
      state,
    )([ownProps.primaryApplicantsPartner.id]);
    props.sharedIncomes = incomeSelectors.clientIncomes(state)(clientIds);
    props.sharedIncomesTotal = incomeSelectors.clientIncomesMonthlyTotal(state)(
      clientIds,
    );
  }
  props.totalIncomes = incomeSelectors.totalIncomesMonthly(state);
  props.applicationId = applicationSelectors.getApplicationId(state);
  props.incomeTypeCategories = incomeTypeSelectors.incomeTypeCategories(state);
  props.rentalIncomeCategory = incomeTypeSelectors.rentalIncomeCategory(state);
  return props;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmIncomes: incomeActions.confirmIncomes,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-coins-1',
      furtherDecoration,
      nextButtonProps,
      confirmEntities,
    })(IncomesApply),
  ),
);
