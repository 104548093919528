import locale from 'config/locale';

import { UTILS_API_PATH } from 'shared/constants/apiPaths';
import axios, { returnJSON, throwJSON } from './baseApi';

export default function find(keyword) {
  return axios
    .get('/localities', {
      baseURL: UTILS_API_PATH,
      params: { keyword, countryCode: locale.data.countryCode },
    })
    .then(returnJSON, throwJSON);
}
