import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { push } from '@loan_market/react-router-redux-multi';
import _ from 'lodash';

import clientActions from 'actions/clientActions';
import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import applySection from 'hocs/applySection';
import {
  questionSet as clientFormQuestions,
  ComposableClientForm,
  propsTransformForQuestion,
} from 'components/PopupForms/ClientForm';
import * as clientSelectors from 'selectors/clientSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import Questions from 'lib/Questions';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { makeCasualDisplayName } from 'lib/utils/formUtils';
import { isGoalSetter } from 'lib/utils/browserUtils';
import { hasUpdatedEmailOrMobile, areSameAddresses } from 'lib/clientHelper';
import { accordionPropTypes } from 'types/customPropTypes';
import Form from 'components/Form/Form';

const messages = defineMessages({
  primaryApplicant: {
    id: 'ApplicantApply.primaryApplicant',
    defaultMessage: 'Primary Applicant',
  },
  fullLegalName: {
    id: 'ApplicantApply.fullLegalName',
    defaultMessage: 'Your Full legal name',
  },
  homeAddress: {
    id: 'ApplicantApply.homeAddress',
    defaultMessage: 'Current home address',
  },
  mobileAndEmail: {
    id: 'ApplicantApply.mobileAndEmail',
    defaultMessage: 'Mobile and email address',
  },
  title: {
    id: 'ApplicantApply.title',
    defaultMessage: 'Your Details',
  },
  titleDescription: {
    id: 'ApplicantApply.titleDescription',
    defaultMessage: 'Confirm your contact details',
  },
  titleDescriptionCompleted: {
    id: 'ApplicantApply.titleDescriptionCompleted',
    defaultMessage: '{name}',
  },
  headerDescription: {
    id: 'ApplicantApply.headerDescription',
    defaultMessage: 'Please confirm your contact details',
  },
  headerDescriptionCompleted: {
    id: 'ApplicantApply.headerDescriptionCompleted',
    defaultMessage: "{name}'s contact details",
  },
});

export const furtherDecoration = (props) => {
  const {
    primaryApplicant: client,
    intl: { formatMessage },
  } = props;
  const name = makeCasualDisplayName(client);
  const postfix = name ? 'Completed' : '';
  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      name,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      name,
    }),
  };
};

const nextButtonProps = (props) => ({
  disabled: !props.formCompleted,
});

export class ApplicantApply extends Component {
  static displayName = 'ApplicantApply';
  static propTypes = {
    intl: intlShape.isRequired,
    ...manageQuestionsPropTypes,
    updateClient: PropTypes.func.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    working: PropTypes.object,
    primaryApplicant: PropTypes.object.isRequired,
    saveAndNext: PropTypes.func.isRequired,
  };

  static questionsToAsk = (client) => {
    const questions = new Questions();

    questions.addForm(clientFormQuestions, client);

    return questions.arrayOfQuestions();
  };

  static forceVisibility = true;

  handleLess = () => {
    if (this.accSection) {
      this.accSection.scrollToTheRightSpot({ openSection: {} });
    }
  };

  componentDidMount() {
    const { isOpen, loadClient, primaryApplicant } = this.props;
    if (isOpen) {
      loadClient(primaryApplicant.id);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isOpen,
      loadClient,
      primaryApplicant,
      clearWorkingClient,
      clientAllAddresses,
    } = this.props;
    const addressesUpdated = !_.isEqualWith(
      prevProps.clientAllAddresses,
      clientAllAddresses,
      areSameAddresses,
    );
    if ((!prevProps.isOpen && isOpen) || (addressesUpdated && isOpen)) {
      loadClient(primaryApplicant.id);
    } else if (prevProps.isOpen && !isOpen) {
      this.timeout = setTimeout(
        () => clearWorkingClient(primaryApplicant.id),
        1000,
      );
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  render() {
    const {
      working,
      workingCurrentAddress,
      workingMailAddress,
      isSameAsHomeAddress,
      setCurrentQuestionTo,
      formCompleted,
      accordionProps: { isLocked, isShared },
      saveAndNext,
    } = this.props;
    const questionProps = _.pick(this.props, _.keys(manageQuestionsPropTypes));

    return (
      <Form
        id='applicantApply'
        onSubmit={saveAndNext}
        formCompleted={formCompleted}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        {working && (
          <ComposableClientForm
            working={working}
            workingCurrentAddress={workingCurrentAddress}
            workingMailAddress={workingMailAddress}
            isSameAsHomeAddress={isSameAsHomeAddress}
            questionKey='primaryApplicant'
            fieldsOnly
            {...questionProps}
            onLess={this.handleLess}
            isLocked={isLocked}
            isShared={isShared}
          />
        )}
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const clientId = ownProps.primaryApplicant.id;
  return {
    requestId: clientId,
    working: clientSelectors.working(state)(clientId),
    client: clientSelectors.client(state)(clientId),
    application: applicationSelectors.workingApplication(state),
    workingCurrentAddress: addressSelectors.workingClientCurrentAddress(state)(
      clientId,
    ),
    workingMailAddress: addressSelectors.workingClientMailAddress(state)(
      clientId,
    ),
    isSameAsHomeAddress: addressSelectors.workingClientIsMailAddressSame(state)(
      clientId,
    ),
    clientAllAddresses: addressSelectors.clientAllAddresses(state)(clientId),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      loadClient: clientActions.loadClient,
      clearWorkingClient: clientActions.clearWorkingClient,
      updateClient: clientActions.updateClient,
      updateClientLogin: clientActions.updateClientLogin,
      goTo: push,
    },
    dispatch,
  ),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    const { emailUpdated, mobileUpdated } = hasUpdatedEmailOrMobile(
      stateProps.working,
      stateProps.client,
    );
    if (emailUpdated || mobileUpdated) {
      const path = isGoalSetter()
        ? `/${stateProps.application.id}/clients/${stateProps.requestId}/applicant/confirm`
        : `/apply/${stateProps.application.id}/applicant/applicant/confirm`;
      dispatchProps.updateClientLogin({
        email: stateProps.working.email,
        clientId: stateProps.working.id,
        path,
      });
    } else {
      dispatchProps.updateClient(stateProps.working);
    }
  },
  close: () => {
    if (window.location.href.includes('confirm')) {
      setTimeout(() => ownProps.close(), 1000);
    } else {
      ownProps.close();
    }
  },
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    applyOwnPropsChecker,
  )(
    manageQuestions(
      applySection({
        iconName: 'sl-custom-id-1',
        furtherDecoration,
        nextButtonProps,
      })(ApplicantApply),
      propsTransformForQuestion,
    ),
  ),
);
