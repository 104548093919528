import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorPage from 'containers/ErrorPage';
import Spinner from 'components/Spinner/Spinner';
import { withRouter } from 'react-router';
import UIActions from 'actions/UIActions';

export class PageWrapper extends Component {
  static propTypes = {
    fullPageSpinnerLoading: PropTypes.bool,
    pageError: PropTypes.object,
    location: PropTypes.object,
    children: PropTypes.node,
    clearPageError: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    // Clear page error on error page leave
    const { pageError, location, clearPageError } = this.props;
    if (pageError && location !== prevProps.location) {
      clearPageError();
    }
  }

  render() {
    const { fullPageSpinnerLoading, pageError, children } = this.props;
    if (pageError) {
      return <ErrorPage error={pageError} />;
    }
    if (fullPageSpinnerLoading) {
      return <Spinner loading />;
    }
    return children;
  }
}

const mapStateToProps = ({
  UISettings: { fullPageSpinnerLoading, pageError },
}) => ({
  fullPageSpinnerLoading,
  pageError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearPageError: UIActions.clearPageError,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageWrapper),
);
