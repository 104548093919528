import _ from 'lodash';
import lenders from 'shared/lib/lenderHelper';
import { defineMessages } from 'react-intl';
import { REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE } from 'shared/constants/repaymentTypes';
import { MONTHLY } from 'shared/constants/options';
import { STRUCTURE_FREQUENCY_OPTIONS, LENDER_OPTIONS } from 'constants/options';

const messages = defineMessages({
  principalInterest: {
    id: 'RepaymentType.PrincipalInterest',
    defaultMessage: 'Principal + Interest',
  },
  interestOnly: {
    id: 'RepaymentType.interestOnly',
    defaultMessage: 'Interest Only',
  },
});

export default function getLender(lenderId) {
  return lenders.userPanel.find((b) => b.lenderId === lenderId);
}

export function getFrequencyAndAmountLabels(
  repaymentInitial,
  repaymentFrequency,
  repaymentType,
  intl,
) {
  const result = {};
  const frequencyItem = _.find(
    STRUCTURE_FREQUENCY_OPTIONS,
    (s) => s.value === parseInt(repaymentFrequency || MONTHLY, 10),
  );
  const repaymentItem = repaymentInitial[_.camelCase(frequencyItem.name)];
  if (repaymentType === REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE) {
    result.amount = repaymentItem.principalAndInterestAmount || 0;
    result.repaymentTypeLabel = intl.formatMessage(messages.principalInterest);
  } else {
    result.amount = repaymentItem.interestOnlyAmount || 0;
    result.repaymentTypeLabel = intl.formatMessage(messages.interestOnly);
  }
  result.frequency = frequencyItem.label;
  return result;
}

export const lenderProductTypes = defineMessages({
  variable: {
    id: 'ProductTable.variable',
    defaultMessage: 'Variable',
  },
  fixed_1_year: {
    id: 'ProductTable.fixed_1_year',
    defaultMessage: '1 Year Fixed',
  },
  fixed_2_year: {
    id: 'ProductTable.fixed_2_year',
    defaultMessage: '2 Years Fixed',
  },
  fixed_3_year: {
    id: 'ProductTable.fixed_3_year',
    defaultMessage: '3 Years Fixed',
  },
  fixed_4_year: {
    id: 'ProductTable.fixed_4_year',
    defaultMessage: '4 Years Fixed',
  },
  fixed_5_year: {
    id: 'ProductTable.fixed_5_year',
    defaultMessage: '5 Years Fixed',
  },
});

export const findLender = (name) =>
  Promise.resolve(
    LENDER_OPTIONS(true).filter((lender) =>
      lender.text.toLowerCase().includes(name.toLowerCase()),
    ),
  );

export const findCreditor = (creditors) => (name) =>
  Promise.resolve(
    creditors.filter((creditor) =>
      creditor.text.toLowerCase().includes(name.toLowerCase()),
    ),
  );
