import _ from 'lodash';

export default {
  areOwnPropsEqual: (a, b) => {
    if (a.openAccordion === a.slug || b.openAccordion === a.slug) {
      return false;
    }
    return _.isEqual(_.omit(a, 'openAccordion'), _.omit(b, 'openAccordion'));
  },
};
