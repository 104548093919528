import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Questions from 'lib/Questions';
import { REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO } from 'constants/validators';
import { FUNDING_TYPE_REQUIRED } from 'shared/constants/myCRMTypes/funding';

import Form from 'components/Form/Form';
import Input from 'components/Input/Input';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Selection from 'components/Selection/Selection';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

const questionSet = (working) => {
  const questions = new Questions();
  if (working.type === FUNDING_TYPE_REQUIRED) {
    questions.addBranch(['propertyId', REQUIRED]);
  }
  if (!working.isRequired) {
    questions.addBranch(['name', REQUIRED]);
  }
  questions.addBranchOrAdd(['value', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO]);
  questions.addBranch(['description']);
  return questions.arrayOfQuestions();
};

class DisconnectedSimpleFundingForm extends Component {
  static propTypes = {
    working: PropTypes.object.isRequired,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    setName: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    depositTypes: PropTypes.arrayOf(PropTypes.object),
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  onSubmit = () => {
    const { save } = this.props;
    save();
  };

  render() {
    const {
      isLocked,
      questions,
      setCurrentQuestionTo,
      setValue,
      setName,
      setDescription,
      setFundingPropertyId,
      remove,
      formCompleted,
      working,
      propertyOptionsForFunding,
      depositTypes,
    } = this.props;
    const isNewValue = !working.id;

    const getDepositType = (name) =>
      name && depositTypes.findIndex((d) => d.name === name) < 0
        ? 'Other'
        : name;

    return (
      <Form
        id='simpleFundingForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          <Question
            {...questions.propertyId}
            label='Which property?'
            className='fullWidthInput'
          >
            {working.propertyId ? (
              <Selection
                action={setFundingPropertyId}
                value={working.propertyId}
                items={propertyOptionsForFunding}
                disabled
              />
            ) : (
              <RadioButtonList
                action={setFundingPropertyId}
                value={working.propertyId}
                items={propertyOptionsForFunding}
              />
            )}
          </Question>
          <Question {...questions.name} label='Type' className='fullWidthInput'>
            <Selection
              action={setName}
              value={getDepositType(working.name)}
              items={depositTypes}
            />
          </Question>
          <Question
            {...questions.value}
            className='fullWidthInput'
            label='Amount'
          >
            <CurrencyInput
              maxLength={9}
              value={working.value}
              action={setValue}
            />
          </Question>
          <Question
            {...questions.description}
            className='fullWidthInput'
            label='Description [optional]'
          >
            <Input value={working.description} action={setDescription} />
          </Question>
          <Button
            hasErrorMessage={!formCompleted}
            id='PopupButton'
            className='brandColor__button'
            onClick={this.onSubmit}
          >
            {isNewValue ? 'Add' : 'Update'}
          </Button>
          {!isNewValue && (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              Remove
            </Button>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

export default manageQuestions(
  DisconnectedSimpleFundingForm,
  (props) => props.working,
);
