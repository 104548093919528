import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';

const ButtonNext = (props) => (
  <Button {...props} icon='mi-arrow-with-circle-right' theme='buttonNext'>
    {props.children || 'Next'}
  </Button>
);

ButtonNext.propTypes = {
  children: PropTypes.node,
};

export default ButtonNext;
