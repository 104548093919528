/**
 * @stringUtils
 * These utils are for
 * functions related to strings and formatting
 */
import _startCase from 'lodash/startCase';
import { defineMessages } from 'react-intl';
import moment from 'moment';
import { isEmptyObject } from 'shared/lib/utils';

export function arrayToPrettyStringList(a, conjunction = 'and') {
  return a.reduce((p, v, index, array) => {
    if (!p) {
      return v;
    }
    if (index === array.length - 1) {
      return `${p} ${conjunction} ${v}`;
    }
    return `${p}, ${v}`;
  }, null);
}

export function generateUniqueID(string, id) {
  return `${string}__${id}`;
}

export function yearsOptions(formatMessage = null, length = null) {
  const messages = defineMessages({
    loanTermYearOrYearsText: {
      id: 'LoanTerm.yearOrYears.text',
      defaultMessage: '{key, plural, =0 {Year} other {Years}}',
    },
  });
  return Array.from({ length: length || 30 }, (v, k) => {
    let descriptor = '';
    if (formatMessage) {
      descriptor = formatMessage(messages.loanTermYearOrYearsText, { key: k });
    } else {
      descriptor = k === 0 ? 'Year' : 'Years';
    }
    return { value: k + 1, name: `${k + 1} ${descriptor}` };
  });
}

export function formatProductName(str = '') {
  const pattern = new RegExp(
    [
      /&lt;|LVR|Low Doc|EXISTING CUSTOMERS/, // this pattern will match $ or LVR or Low Doc or EXISTING CUSTOMERS in string
      /\(.*\)?/, // Example: 'ABC (Owner Occupied)' or 'ABC (Owner' - the pattern will match (Owner Occupied) or (Owner in string.
      /(\s?[<>]?\s?\$[\d+,.KMkm]+\s?(-|to|up to)?\s?[<>]?\${0,1}[\d+,.KMkm]+)/, // Example: $900K-$1M or $900,000-$1M or $900K-$1.5M or $900K to $1M or $900K up to $1M or >$900K to $1M or >$900K up to $1M
      /[^\d\s%A-Za-z]/, // this pattern match all characters except number 0 to 9, A to Z, a-z, space and %.
      /\s?\d+[Kk]/, // Example: 'ABC 100k' or 'ABC 100K' - the pattern will match: 100K or 100k in string
      /(\$1[\d,Mm]+)/, // Example: $1000, $1000,000, $1M, $1m
      /\s?[<>]?((\d+)|(\d?))?%?\s?(up to|to|-)?\s?\d+%([<>]?\s?[ optu|-]+\s[\d$Kk]+\s?[ optu|-]+\s[\d$,]+)?/, // Example: 'ABC <80%' or 'ABC >80%' or 'ACB 50%-80%' or 'ACB 50% - 80%' or 'ACB 50-80%' or 'ABC 50% to 80%' or 'ABC >50% to 80%' or 'ABC 50% up to 80%' or 'ABC >50% up to 80%' in string.
      /(\s|^)(?!(\d+\s(yr|yrs|years|year)(\s|^|$)))(\d+)/, // remove number except "[no] years" or "[no] year" or "[no] yr" or "[no] yrs"
    ]
      .map((r) => r.source)
      .join('|'),
    'gi',
  );
  let c = str.replace(pattern, '');
  c = c.trim();
  c = c.replace(/\s\s+/g, ' '); // Replace multiple white space to one white space
  c = c.replace(/%+$/g, ''); // Replace % at end
  if (c.match(/Fixed(.*)Fixed$/g)) {
    c = c.replace(/\sFixed$/g, ''); // Remove "Fixed" at last if already having Fixed
  }
  return c;
}

/**
 * This function will concatenate strings using character ',' and
 * add character '&' to last item
 * @example
 * // returns 'a, b, c & d'
 * formatTextWithComma(['a', 'b', 'c', 'd']);
 * @param arr {string[]}
 * @return {string}
 */
export function formatTextWithComma(arr) {
  const target = arr.filter((a) => a);
  switch (target.length) {
    case 0:
      return '';
    case 1:
      return target[0];
    case 2:
      return `${target[0]} & ${target[1]}`;
    default:
      return `${target[0]}, ${formatTextWithComma(target.slice(1))}`;
  }
}

export function splitStrIntoLines(str) {
  const paragraphs =
    typeof str === 'string' ? str.split(/\n\n|\r\r|\r\n\r\n/g) : null; // treat double new line characters as paragraph
  return paragraphs
    ? paragraphs.map((p) => p.trim().split(/\n|\r|\r\n/))
    : null; // split each paragraph into lines
}

export function shortStringWithoutCuttingWords(value, length) {
  const pattern = `^(.{${length}}[^\\s|.]*).*`;
  const reg = new RegExp(pattern, 'g');
  return value.replace(reg, '$1');
}

export function shortenStringWithEllipsis(str, length) {
  if (str.length > length) {
    return `${str.slice(0, Math.max(0, length - 3))}...`;
  }
  return str;
}

export function formatPeriod(formatDate, fromDate, toDate) {
  const formatDateOptions = { month: 'short', year: 'numeric' };

  const from = formatDate(moment(fromDate), formatDateOptions);
  const to = isEmptyObject(toDate)
    ? 'Now'
    : formatDate(moment(toDate), formatDateOptions);

  return `${from} - ${to}`;
}

export function toPageTitle(str) {
  if (typeof str !== 'string') {
    return str;
  }
  return str
    .split('/')
    .map(_startCase)
    .filter((v) => !!v && !v.match(/^(\s+|\d+)$/g))
    .join(' - ');
}

export const combineStringsGrammatically = (
  strings = [],
  useAmpersand = false,
) => {
  if (strings === null) {
    return '';
  }
  const length = strings.length;
  const mutableStrings = [...strings];
  const and = useAmpersand ? '&' : 'and';
  if (length === 2) {
    return mutableStrings.join(` ${and} `);
  } else if (length > 2) {
    mutableStrings[length - 1] = `${and} ${mutableStrings[length - 1]}`;
  }
  return mutableStrings.join(', ');
};
