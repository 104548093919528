import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { REQUIRED } from 'constants/validators';
import applicationActions from 'actions/loanApplicationActions';
import applySection from 'hocs/applySection';
import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import commonMessages from 'constants/commonMessages';
import Question from 'components/Question/Question';
import YesNoButtonList from 'components/ButtonList/YesNoButtonList';
import Textarea from 'components/Textarea/Textarea';

import { accordionPropTypes } from 'types/customPropTypes';
import Questions from 'lib/Questions';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import Form from 'components/Form/Form';

import {
  workingApplication,
  creditHistoryDetails,
  getCreditHistoryQuestionsLeft,
} from 'selectors/applicationSelectors';

import { featureFlags } from 'lib/rollout';
import locale from 'config/locale';

export const messages = defineMessages({
  title: {
    id: 'CreditHistoryApply.title',
    defaultMessage: 'Credit',
  },
  creditHistory: {
    id: 'CreditHistoryApply.creditHistory',
    defaultMessage: 'Please tell us about your credit history.',
  },
  behindInPayments: {
    id: 'CreditHistoryApply.behindInPayments',
    defaultMessage:
      'Are you or any co-applicants behind in repaying any current debts or loans?',
  },
  director: {
    id: 'CreditHistoryApply.director',
    defaultMessage:
      'Have you or any co-applicants been a director in the past {years} years?',
  },
  conduct: {
    id: 'CreditHistoryApply.conduct',
    defaultMessage:
      'Do you or any co-applicants have good account conduct (e.g. no missed payments, dishonours or unarranged overdrafts)?',
  },
  questionsToAnswer: {
    id: 'CreditHistoryApply.questionsToAnswer',
    defaultMessage:
      '{questionsLeft, plural, =0 {All questions answered} one {1 question left} other {{questionsLeft, number} questions to answer}}',
  },
  bankrupt: {
    id: 'CreditHistoryApply.bankrupt',
    defaultMessage:
      'Have you or any co-applicants had any previous credit issues or bankruptcy?',
  },
  bankruptMore: {
    id: 'CreditHistoryApply.bankruptMore',
    defaultMessage:
      'e.g. Trouble paying back a loan, credit card, monies owing to friends/family etc.',
  },
});

export const furtherDecoration = ({
  intl: { formatMessage },
  questionsLeft,
}) => ({
  title: formatMessage(messages.title),
  titleDescription: formatMessage(messages.questionsToAnswer, {
    questionsLeft,
  }),
  headerDescription: formatMessage(messages.creditHistory),
});

export const nextButtonProps = (props) => ({
  disabled: !props.accordionProps.isLocked && !props.formCompleted,
});

export class CreditHistoryApply extends Component {
  static displayName = 'CreditHistoryApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    application: PropTypes.object.isRequired,
    behindInPayments: PropTypes.bool,
    director: PropTypes.bool,
    conduct: PropTypes.bool,
    bankrupt: PropTypes.bool,
    bankruptDetail: PropTypes.string,
    setBehindInPayments: PropTypes.func.isRequired,
    setDirector: PropTypes.func.isRequired,
    setConduct: PropTypes.func.isRequired,
    setBankrupt: PropTypes.func.isRequired,
    setBankruptDetail: PropTypes.func.isRequired,
    setBehindInPaymentsDetail: PropTypes.func.isRequired,
    setDirectorDetail: PropTypes.func.isRequired,
    saveAndNext: PropTypes.func.isRequired,
    questionsLeft: PropTypes.number.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = (application) => {
    const questions = new Questions();

    questions.addBranch(['bankrupt', REQUIRED]);
    if (application.bankrupt) {
      questions.add(['bankruptDetail', REQUIRED]);
    }

    questions.addBranch(['behindInPayments', REQUIRED]);
    if (application.behindInPayments) {
      questions.add(['behindInPaymentsDetail', REQUIRED]);
    }

    questions.addBranch(['director', REQUIRED]);
    if (application.director && locale.isAU) {
      questions.add(['directorDetail', REQUIRED]);
    }

    if (featureFlags.goalSetterFactFindMerge.isEnabled()) {
      questions.addBranch(['conduct', REQUIRED]);
    }

    questions.addBranch('saveButton');

    return questions.arrayOfQuestions();
  };

  static forceVisibility = true;

  render() {
    const {
      behindInPayments,
      director,
      conduct,
      bankrupt,
      bankruptDetail,
      behindInPaymentsDetail,
      directorDetail,
      setBehindInPayments,
      setDirector,
      setConduct,
      setBankrupt,
      setBankruptDetail,
      setBehindInPaymentsDetail,
      setDirectorDetail,
      accordionProps: { isLocked },
      questions,
      formCompleted,
      intl: { formatMessage },
      setCurrentQuestionTo,
      saveAndNext,
    } = this.props;

    return (
      <Form
        id='creditHistoryApply'
        onSubmit={saveAndNext}
        formCompleted={formCompleted}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <Question
          {...questions.bankrupt}
          label={formatMessage(messages.bankrupt)}
          hint={formatMessage(messages.bankruptMore)}
          direction='row'
          forceFocus={false}
        >
          <YesNoButtonList
            action={setBankrupt}
            value={bankrupt}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.bankruptDetail}
          label={formatMessage(commonMessages.details)}
          hideTopLine
        >
          <Textarea
            action={setBankruptDetail}
            value={bankruptDetail}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.behindInPayments}
          label={formatMessage(messages.behindInPayments)}
          direction='row'
        >
          <YesNoButtonList
            action={setBehindInPayments}
            value={behindInPayments}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.behindInPaymentsDetail}
          label={formatMessage(commonMessages.details)}
        >
          <Textarea
            action={setBehindInPaymentsDetail}
            value={behindInPaymentsDetail}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.director}
          label={formatMessage(messages.director, {
            years: featureFlags.goalSetterFactFindMerge.isEnabled() ? 7 : 5,
          })}
          direction='row'
        >
          <YesNoButtonList
            action={setDirector}
            value={director}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.directorDetail}
          label={formatMessage(commonMessages.details)}
        >
          <Textarea
            action={setDirectorDetail}
            value={directorDetail}
            disabled={isLocked}
          />
        </Question>
        <Question
          {...questions.conduct}
          label={formatMessage(messages.conduct)}
          direction='row'
        >
          <YesNoButtonList
            action={setConduct}
            value={conduct}
            disabled={isLocked}
          />
        </Question>
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  application: workingApplication(state),
  questionsLeft: getCreditHistoryQuestionsLeft(state),
  ...creditHistoryDetails(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setBehindInPayments: applicationActions.setBehindInPayments,
      setDirector: applicationActions.setDirector,
      setConduct: applicationActions.setConduct,
      setBankrupt: applicationActions.setBankrupt,
      setBankruptDetail: applicationActions.setBankruptDetail,
      setBehindInPaymentsDetail: applicationActions.setBehindInPaymentsDetail,
      setDirectorDetail: applicationActions.setDirectorDetail,
      updateLoanApplication: applicationActions.updateLoanApplication,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: () => {
    dispatchProps.updateLoanApplication(stateProps.application);
  },
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    applyOwnPropsChecker,
  )(
    manageQuestions(
      applySection({
        iconName: 'sl-custom-star-5',
        furtherDecoration,
        nextButtonProps,
      })(CreditHistoryApply),
    ),
  ),
);
