/**
 * @browserUtils
 * These utils are for
 * functions related to browser, events, paths
 */
import bowser from 'bowser';
import qs from 'qs';
import _isNaN from 'lodash/isNaN';
import { GOAL_SLUG } from 'shared/config/pathMatcher';
import LocalStorageProxy, {
  SESSION_EXEMPTED_PROPS,
} from 'lib/localStorageProxy';
import {
  HELP_EXPIRED_SESSION_PATH,
  PRIVACY_POLICY_PATH,
} from 'shared/constants/paths';

export function dispatchNewEvent(eventName, target) {
  let event;
  try {
    event = new Event(eventName, {
      bubbles: true,
    });
  } catch (error) {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, false);
  }
  if (target && typeof target.dispatchEvent === 'function') {
    target.dispatchEvent(event);
  }
}

export function touchOrClick(e) {
  const isTouchSupported = 'ontouchstart' in document;
  return !isTouchSupported || e.type !== 'click';
}

export function isIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

export function hideBodyOverflow(hide) {
  if (hide) {
    document.querySelectorAll('body')[0].classList.add('overflowHidden');
  } else {
    document.querySelectorAll('body')[0].classList.remove('overflowHidden');
  }
}

export const handleSessionExpiry = () => {
  LocalStorageProxy.clearAllExcept(SESSION_EXEMPTED_PROPS);
  window.location.href = `${
    window.location.pathname
  }#${HELP_EXPIRED_SESSION_PATH}?redirect=${encodeURIComponent(
    window.location.href,
  )}`;
};

export function cssDurationToMilliseconds(duration) {
  const number = parseFloat(duration);
  if (_isNaN(number)) {
    return NaN;
  }
  const unit = duration.replace(/\d+(\.\d+)?/g, ''); // remove digit chars
  switch (unit) {
    case 'ms':
      return number;
    case 's':
      return 1000 * number;
    case 'm':
      return 60 * 1000 * number;
    default:
      return 0;
  }
}

export const getInbetweenerDebounceTime = () => {
  return process.env.NODE_ENV === 'development' ? 10 : 500;
};

// Current requirements for formatting:
// Android: 8+
// Anything else
export const allowFormFormatting = () => {
  return !(
    bowser.osname === 'Android' &&
    bowser.compareVersions([bowser.osversion, '8.0']) < 0
  );
};
// Hide parent and show popup as absolute when modal is open on iOS 11
// https://hackernoon.com/how-to-fix-the-ios-11-input-element-in-fixed-modals-bug-aaf66c7ba3f8
export const absolutePopup = () => {
  return (
    !!bowser.ios && bowser.compareVersions([bowser.osversion, '11.0']) >= 0
  );
};

export const convertToClassName = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.replace(/[^\dA-Za-z]/g, '');
};

export const isGoalSetter = () => {
  const { pathname } = window.location;
  return (
    pathname.endsWith(`/${GOAL_SLUG}/`) || pathname.endsWith(`/${GOAL_SLUG}`)
  );
};

export const isOneTimePrivacyPolicy = () => {
  const { hash } = window.location;
  return (
    hash.includes(PRIVACY_POLICY_PATH) &&
    hash.includes('token') &&
    hash.includes('name') &&
    hash.includes('advisorUuid')
  );
};

export function extractQueries(string) {
  const sanitizedString = string.replace(/^(#\/([^?]+)?(\/?)(\??))|\?/, '');
  return qs.parse(sanitizedString);
}

export function serializeQueriesForUrl(obj) {
  return qs.stringify(obj);
}

export const getSectionFromUrl = (url = window.location.href) => {
  const pattern = '.*/[0-9]{6}/([A-Za-z0-9-]+).*';
  const section = url.match(pattern);
  return section && section[1];
};

export const optimizeActivate = (key, value) =>
  window.dataLayer &&
  window.dataLayer.push({ event: 'optimize.activate', [key]: value });

export const handleEnter = (action) => (e) => {
  if (e.key === 'Enter') {
    action();
  }
};
