import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as propertyActionTypes from './propertyActionTypes';
import * as scenarioActionTypes from './scenarioActionTypes';

export const scenarioActions = createActions(
  {},
  ...Object.values(scenarioActionTypes),
);
export default {
  ...scenarioActions,
  ...createIndividualisedActions(propertyActionTypes),
};
