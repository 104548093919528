export const ADDRESS_TYPE_CURRENT = { id: 1, name: 'Current' };
export const ADDRESS_TYPE_PREVIOUS = { id: 4, name: 'Previous' };

export const ADDRESS_TYPE_POSTAL = { id: 2 };
export const ADDRESS_TYPE_POST_SETTLEMENT = { id: 3 };
export const ADDRESS_TYPE_OTHER = { id: 5 };

export const HISTORIC_ADDRESS_TYPES = [
  ADDRESS_TYPE_CURRENT,
  ADDRESS_TYPE_PREVIOUS,
];

export const ADDRESS_OWNERSHIP_TYPE_BOARDING = { id: 1, name: 'Boarding' };
const ADDRESS_OWNERSHIP_TYPE_CARAVAN = { id: 2, name: 'Caravan' };
export const ADDRESS_OWNERSHIP_TYPE_OWN_HOME = { id: 3, name: 'Own home' };
export const ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE = {
  id: 4,
  name: 'Own home - mortgage',
};
export const ADDRESS_OWNERSHIP_TYPE_RENTING = { id: 5, name: 'Renting' };
export const ADDRESS_OWNERSHIP_TYPE_WITH_PARENTS = {
  id: 6,
  name: 'With parents',
};
const ADDRESS_OWNERSHIP_TYPE_TO_BE_PURCHASED = {
  id: 7,
  name: 'To be purchased',
};
const ADDRESS_OWNERSHIP_TYPE_OTHER = { id: 8, name: 'Other' };

export const ADDRESS_OWNERSHIP_TYPES = [
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME,
  ADDRESS_OWNERSHIP_TYPE_OWN_HOME_MORTGAGE,
  ADDRESS_OWNERSHIP_TYPE_RENTING,
  ADDRESS_OWNERSHIP_TYPE_WITH_PARENTS,
  ADDRESS_OWNERSHIP_TYPE_BOARDING,
  ADDRESS_OWNERSHIP_TYPE_CARAVAN,
  ADDRESS_OWNERSHIP_TYPE_TO_BE_PURCHASED,
  ADDRESS_OWNERSHIP_TYPE_OTHER,
];
