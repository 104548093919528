export const CLEAR_WORKING_LIABILITY = 'clearWorkingLiability';
export const LOAD_LIABILITY = 'loadLiability';
export const SET_LIABILITY = 'setLiability';
export const REMOVE_LIABILITY = 'removeLiability';
export const SET_NEW_LIABILITY = 'setNewLiability';
export const SET_NEW_LIABILITIES = 'setNewLiabilities';
export const INSERT_NEW_LIABILITIES = 'insertNewLiabilities';

export const CREATE_LIABILITY = 'createLiability';
export const UPDATE_LIABILITY = 'updateLiability';
export const DELETE_LIABILITY = 'deleteLiability';

export const CONFIRM_LIABILITIES = 'confirmLiabilities';
