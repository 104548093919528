import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button/Button';
import SVGInline from 'react-svg-inline';
import SpinningWheel from 'assets/icons/spinning-wheel.svg';

import styles from './ButtonWithSpinner.css';

const ButtonWithSpinner = ({
  id,
  showSpinner,
  onClick,
  label,
  labelWhileSpinning,
}) =>
  !showSpinner ? (
    <Button id={id} onClick={onClick} theme='buttonNext'>
      {label}
    </Button>
  ) : (
    <div className={styles.showSpinner}>
      <div className={styles.spinner}>
        <SVGInline svg={SpinningWheel} />
      </div>
      <span className={styles.label}>{labelWhileSpinning}</span>
    </div>
  );

ButtonWithSpinner.propTypes = {
  id: PropTypes.string,
  showSpinner: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelWhileSpinning: PropTypes.string,
};

export default ButtonWithSpinner;
