export const REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE = 'principal_interest';
export const REPAYMENT_TYPE_INTEREST_ONLY_VALUE = 'interest';
export const REPAYMENT_TYPE_PRINCIPAL_INTEREST_LABEL = 'Principal + Interest';
export const REPAYMENT_TYPE_INTEREST_ONLY_LABEL = 'Interest Only';
export const INTEREST_ONLY_TERM_ALL_VALUE = 'all';
export const REPAYMENT_TYPE_PRINCIPAL_INTEREST = 'Principal and Interest';
export const REPAYMENT_TYPE_INTEREST_ONLY = 'Interest only';
export const REPAYMENT_TYPE_ALL = 'P&I or I/O';

const interestOnlyDescription =
  'When repaying an interest only loan, you pay off only the interest only on the loan for a fixed time. This reduces the amount of your repayments for a set period, but not the balance of the principal you borrowed.';

const principalInterest = {
  key: 'principalInterest',
  value: REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE,
  label: 'Principal + Interest',
  tooltip:
    'A periodic payment, usually paid monthly, that includes the interest charges for the period plus an amount applied to the principal balance',
  hint:
    'A periodic payment, usually paid monthly, that includes the interest charges for the period plus an amount applied to the principal balance',
};

const interestOnly = {
  key: 'interestOnly',
  value: REPAYMENT_TYPE_INTEREST_ONLY_VALUE,
  label: REPAYMENT_TYPE_INTEREST_ONLY_LABEL,
  tooltip: interestOnlyDescription,
  hint: interestOnlyDescription,
};

const interestOnlyOption = {
  key: 'interestOnly',
  value: REPAYMENT_TYPE_INTEREST_ONLY_VALUE,
  label: REPAYMENT_TYPE_INTEREST_ONLY_LABEL,
  tooltip: interestOnlyDescription,
  hint: interestOnlyDescription,
};

export const REPAYMENT_TYPES_BANKING_PREFERENCES = [
  principalInterest,
  interestOnly,
];

export const REPAYMENT_TYPES = [principalInterest, interestOnlyOption];

export const INTEREST_ONLY_TERM_ALL = {
  name: 'All',
  value: INTEREST_ONLY_TERM_ALL_VALUE,
};

export const INTEREST_ONLY_TERM_TEN_YEARS_PLUS = {
  value: 10,
  name: '10+ Years',
};
