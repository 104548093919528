import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { onError, getLenderStyle } from 'lib/utils/imageUtils';

import styles from './MultiSelectByLogo.css';

const useItemToggleSelect = (action) => {
  const [selectedItemValues, setSelectedItemValues] = useState([]);

  const toggleItem = (item) => () => {
    const isItemSelected = selectedItemValues.includes(item.value);
    if (isItemSelected) {
      setSelectedItemValues(selectedItemValues.filter((i) => i !== item.value));
    } else {
      setSelectedItemValues([...selectedItemValues, item.value]);
    }
  };

  useEffect(() => {
    action(selectedItemValues);
  }, [selectedItemValues]);

  return [selectedItemValues, setSelectedItemValues, toggleItem];
};

const MultiSelectByLogo = ({
  optionsByRow,
  selectedValues,
  action,
  hideLogoOnError,
}) => {
  const [
    selectedItemValues,
    setSelectedItemValues,
    toggleItem,
  ] = useItemToggleSelect(action);
  const [showLogos, setShowLogos] = useState({});
  const [valuesInitialised, setValuesInitialised] = useState(false);

  useEffect(() => {
    if (!selectedValues || valuesInitialised) {
      return;
    }
    if (selectedItemValues.length === 0 && selectedValues.length > 0) {
      setSelectedItemValues([...selectedValues]);
      setValuesInitialised(true);
    }
  }, [selectedValues]);

  const onLogoLoad = (value) => () =>
    hideLogoOnError && setShowLogos((s) => ({ ...s, [value]: true }));

  const renderItem = (item) => {
    const { value, title, logoSrc } = item;
    const selected = selectedItemValues.includes(value);
    const className = classNames(styles.item, { [styles.selected]: selected });
    return (
      <li
        key={value}
        title={title}
        className={className}
        onClick={toggleItem(item)}
        style={
          hideLogoOnError && logoSrc
            ? getLenderStyle(value, showLogos)
            : undefined
        }
      >
        {logoSrc ? (
          <img
            src={logoSrc}
            className={styles.logo}
            alt={title}
            onLoad={onLogoLoad(value)}
            onError={!hideLogoOnError ? onError : undefined}
          />
        ) : (
          <span>{title}</span>
        )}
      </li>
    );
  };

  const renderRow = (row, index) => (
    <ol key={index} className={styles.row}>
      {row.map(renderItem)}
    </ol>
  );

  return <div className={styles.root}>{optionsByRow.map(renderRow)}</div>;
};

const valuePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);
MultiSelectByLogo.propTypes = {
  optionsByRow: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: valuePropType.isRequired,
        title: PropTypes.string.isRequired,
        logoSrc: PropTypes.string.isRequired,
      }),
    ),
  ).isRequired,
  selectedValues: PropTypes.arrayOf(valuePropType),
  action: PropTypes.func.isRequired,
};

export default MultiSelectByLogo;
