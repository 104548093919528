import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

import _ from 'lodash';

import { paramsId, paramCacheResolver } from 'selectors/sharedSelectors';

import {
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_FHB,
} from 'shared/constants/loanScenarios';
import {
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_EXISTING_PROPERTY_ID,
} from 'shared/constants/defaults';

import {
  getName,
  incomeFromSellingProperties,
  otherCosts,
} from 'selectors/scenarioSelectors';

import {
  purchasePriceMetadata,
  costOfSaleMetadata,
  FHOGMetadata,
  stampDutyMetadata,
  LMIMetadata,
  legalFeesMetadata,
  movingAndCleaningMetadata,
  renovationsMetadata,
  otherCostMetadata,
  contributionsMetadata,
  outstandingMortgageMetadata,
  realestateFeeMetadata,
  interestOnlyRepaymentsMetadata,
  managementFeeMetadata,
  vacancyAllowanceMetadata,
  repairFeeMetadata,
  LVRMetadata,
} from 'constants/reportMetadata';

// execute metadata's selector to get actual value
export const hydrateMetadata = (metadata) =>
  createCachedSelector(paramsId, metadata.value, (id, v) => ({
    ...metadata,
    url: _.isFunction(metadata.url) ? metadata.url(id) : metadata.url,
    value: v,
  }))(paramCacheResolver);

export const targetMetadata = (id) => (metadata) => {
  if (!metadata.path) {
    return metadata;
  }
  return {
    id,
    ...metadata,
    url: metadata.url(id),
    value: (state) => {
      return metadata.value(state, id);
    },
  };
};

export const targetProspectiveProperty = targetMetadata(
  PROSPECTIVE_PROPERTY_ID,
);
export const targetDefaultExistingProperty = targetMetadata(
  DEFAULT_EXISTING_PROPERTY_ID,
);

/*
 * SELECTORS FOR LOAN REPORT
 */

export const otherCostsSections = createSelector(otherCosts, (costs) =>
  costs.map((cost, index) => ({
    key: cost.name,
    name: cost.name,
    value: cost.value,
    icon: 'sl-custom-dollar-bag',
    url: otherCostMetadata.url(index),
  })),
);

export const loanReportPurchaseCosts = createSelector(
  getName,
  otherCosts,
  incomeFromSellingProperties,
  hydrateMetadata(targetProspectiveProperty(purchasePriceMetadata)),
  hydrateMetadata(targetDefaultExistingProperty(costOfSaleMetadata)),
  hydrateMetadata(stampDutyMetadata),
  hydrateMetadata(LMIMetadata),
  hydrateMetadata(targetProspectiveProperty(legalFeesMetadata)),
  hydrateMetadata(targetProspectiveProperty(movingAndCleaningMetadata)),
  hydrateMetadata(targetProspectiveProperty(renovationsMetadata)),
  otherCostsSections,
  (
    name,
    otherCostsList,
    soldPropertyIncome,
    purchasePrice,
    costOfSale,
    stampDuty,
    lmi,
    legalFees,
    movingAndCleaning,
    renovations,
    costs,
  ) => {
    const section = [purchasePrice];
    if (name === BUYER_SCENARIO_MOVE && soldPropertyIncome < 0) {
      section.push(costOfSale);
    }
    section.push(
      stampDuty,
      lmi,
      legalFees,
      movingAndCleaning,
      renovations,
      ...costs,
    );
    section.push({
      ...otherCostMetadata,
      value: undefined,
      url: otherCostMetadata.url(otherCostsList.length),
    });
    return section;
  },
);

export const loanReportContributions = createSelector(
  getName,
  hydrateMetadata(contributionsMetadata),
  hydrateMetadata(FHOGMetadata),
  (name, contributions, FHOG) => {
    const section = [contributions];
    if (name === BUYER_SCENARIO_FHB) {
      section.push(FHOG);
    }
    return section;
  },
);

/*
 * SELECTORS FOR SALES REPORT
 */

export const salesReportSellingCosts = createSelector(
  hydrateMetadata(outstandingMortgageMetadata),
  hydrateMetadata(realestateFeeMetadata),
  hydrateMetadata(targetDefaultExistingProperty(legalFeesMetadata)),
  hydrateMetadata(targetDefaultExistingProperty(renovationsMetadata)),
  hydrateMetadata(targetDefaultExistingProperty(movingAndCleaningMetadata)),
  (...args) => args,
);

/*
 * SELECTORS FOR CASH FLOW REPORT
 */

export const cashFlowReportCostSection = createSelector(
  hydrateMetadata(interestOnlyRepaymentsMetadata),
  hydrateMetadata(managementFeeMetadata),
  hydrateMetadata(vacancyAllowanceMetadata),
  hydrateMetadata(repairFeeMetadata),
  (...args) => args,
);

export const calculatorWidget = createSelector(
  hydrateMetadata(stampDutyMetadata),
  hydrateMetadata(LMIMetadata),
  hydrateMetadata(targetProspectiveProperty(legalFeesMetadata)),
  hydrateMetadata(targetProspectiveProperty(movingAndCleaningMetadata)),
  hydrateMetadata(targetProspectiveProperty(renovationsMetadata)),
  hydrateMetadata(LVRMetadata),
  (...args) => args,
);
