import * as actionTypes from 'actions/householdActionTypes';

import { handleActions } from 'redux-actions';

export const initialState = {};

const householdReducer = handleActions(
  {
    [actionTypes.CLEAR_HOUSEHOLD]() {
      return initialState;
    },
    [actionTypes.SET_HOUSEHOLD_SHAPE](state, action) {
      return { ...state, householdShape: action.payload };
    },
    [actionTypes.SET_IS_PARTNER_COAPPLICANT](state, action) {
      return { ...state, isPartnerCoapplicant: action.payload };
    },
    [actionTypes.SET_PARTNER_FIRST_NAME](state, action) {
      return { ...state, partnerFirstName: action.payload };
    },
    [actionTypes.SET_FORM_COMPLETED](state, action) {
      return { ...state, formCompleted: action.payload };
    },
  },
  initialState,
);

export default householdReducer;
