export const SET_PROPERTY_ERROR = 'setPropertyError';
export const SET_PROPERTY_ERRORS = 'setPropertyErrors';

export const SET_PROPERTY_LOCALITY = 'setPropertyLocality';
export const SET_PROPERTY_VALUE = 'setPropertyValue';
export const SET_PROPERTY_STATE = 'setPropertyState';
export const SET_PROPERTY_MORTGAGE_AMOUNT = 'setPropertyMortgageAmount';
export const SET_PROPERTY_TYPE = 'setPropertyType';
export const SET_PROPERTY_OWNER_OCCUPIED = 'setPropertyOwnerOccupied';
export const SET_PROPERTY_INTENDS_TO_SELL = 'setPropertyIntendsToSell';
export const SET_PROPERTY_DEPOSIT_AMOUNT = 'setPropertyDepositAmount';
export const SET_PROPERTY_RENTAL_AMOUNT = 'setPropertyRentalAmount';
export const SET_PROPERTY_WEEKLY_RENTAL_AMOUNT =
  'setPropertyWeeklyRentalAmount';
export const SET_PROPERTY_RENTAL_FREQUENCY = 'setPropertyRentalFrequency';
export const SET_PROPERTY_ONGOING_FEE = 'setPropertyOngoingFee';
export const SET_PROPERTY_ONGOING_FEE_FREQUENCY =
  'setPropertyOngoingFeeFrequency';
export const SET_PROPERTY_INVESTMENT_LOWEST_RATE_MANUAL =
  'setPropertyInvestmentLowestRateManual';
export const SET_PROPERTY_OTHER_COST = 'setPropertyOtherCost';
export const SET_PROPERTY_FHOG_ELIGIBILITY = 'setPropertyFhogEligibility';
export const MERGE_PROPERTY_REPORT_DATA = 'mergePropertyReportData';

export const SET_PROPERTY_MANAGEMENT_FEE_PERCENTAGE =
  'setPropertyManagementFeePercentage';
export const SET_PROPERTY_VACANCY_FEE_PERCENTAGE =
  'setPropertyVacancyFeePercentage';
export const SET_PROPERTY_REPAIR_FEE = 'setPropertyRepairFee';
export const SET_PROPERTY_REALESTATE_COMMISSION_PERCENTAGE =
  'setPropertyRealEstateCommissionPercentage';
export const SET_PROPERTY_ADS_FEE = 'setPropertyAdsFee';
export const SET_PROPERTY_CONVEYANCER_COST = 'setPropertyConveyancerCost';
export const SET_PROPERTY_MOVING_COST = 'setPropertyMovingCost';
export const SET_PROPERTY_RENOVATION_COST = 'setPropertyRenovationCost';

export const SET_PROPERTY_RENOVATION_PURPOSE = 'setPropertyRenovationPurpose';
export const SET_PROPERTY_CURRENT_LENDER = 'setPropertyCurrentLender';
export const SET_PROPERTY_CURRENT_LENDER_ID = 'setPropertyCurrentLenderId';
export const SET_PROPERTY_CURRENT_INTEREST_RATE =
  'setPropertyCurrentInterestRate';
export const SET_PROPERTY_ADDITIONAL_FUNDS = 'setPropertyAdditionalFunds';
export const SET_PROPERTY_CONSOLIDATE_DEBT_AMOUNT =
  'setPropertyConsolidateDebtAmount';
export const SET_PROPERTY_EXIT_FEE = 'setPropertyExitFee';
