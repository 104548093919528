/**
 * @dataUtils
 * These utils are for
 * functions related to arrays and objects
 */

import _ from 'lodash';

export function isEmptyObjectOrObjectWithOnlyUndefinedValues(object) {
  return (
    _.isPlainObject(object) &&
    (_.isEmpty(object) || _.values(object).every((v) => v === undefined))
  );
}

export function dataOfMatchingValueByKey(array, value, key) {
  if (!Array.isArray(array) || value === undefined || key === undefined) {
    return undefined;
  }
  const obj = array.find((option) => option.value === value);
  if (!obj) {
    return undefined;
  }
  return obj[key];
}

export function valuesOnTargetKey(keys, collection, targetKey) {
  return collection
    .filter((element) => keys.includes(element.key))
    .map((element) => element[targetKey]);
}

export function makeArrayFromRange(min, max, values, isDesc) {
  return (() => (isDesc ? _.rangeRight : _.range))()(min, max + 1).map((a) => ({
    value: a,
    name: values ? values[a] : a,
  }));
}

// obj1 = [{key: 'a'}, {key: 's'}]
// obj2 = {b: false, a: false} -> intersect = [], return false
// obj2 = {b: false, a: true} -> intersect = ['a'], return true
export function hasKeySelectedFrom(obj1, obj2) {
  return !!_.intersection(
    _.map(obj1, (obj) => obj.key),
    _.keys(_.pickBy(obj2)),
  ).length;
}

export const isOrderlessEqual = (a, b) => {
  if (!Array.isArray(a) || !Array.isArray(b)) {
    throw new TypeError(
      `A pair of arrays expected in isOrderlessEqual, instead got ${a} and ${b}`,
    );
  }

  return !_.xor(a, b).length;
};

export const addOrRemoveFromArray = (array, value) => {
  const index = array.indexOf(value);
  return index > -1
    ? [...array.slice(0, index), ...array.slice(index + 1)]
    : [...array, value];
};

export const areArraysEqual = (arr1, arr2) => {
  if (!arr1 || !arr2) {
    return arr1 === arr2;
  }
  return _.isEqual(arr1.sort(), arr2.sort());
};
