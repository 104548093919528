import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/goal/goalLoanApplicationActions';

export const initialState = {
  active: null,
  list: [],
  profilePercentages: [],
  serviceabilityMaxBorrows: [],
  preferences: {},
};

const goalLoanApplicationReducer = handleActions(
  {
    [actionTypes.SET_ACTIVE_GOAL_LOAN_APP](state, action) {
      return { ...state, active: action.payload };
    },
    [actionTypes.SET_GOAL_LOAN_APPS_LIST](state, action) {
      return { ...state, list: action.payload };
    },
    [actionTypes.SET_GOAL_CLIENT_PROFILE_PERCENTAGES](state, action) {
      return { ...state, profilePercentages: action.payload };
    },
    [actionTypes.SET_GOAL_CONTACT_SERVICEABILITY_MAX_BORROWS](state, action) {
      return { ...state, serviceabilityMaxBorrows: action.payload };
    },
    [actionTypes.SET_GOAL_PREFERENCES](state, action) {
      return {
        ...state,
        preferences: { ...state.preferences, ...action.payload },
      };
    },
  },
  initialState,
);

export default goalLoanApplicationReducer;
