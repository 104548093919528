import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import locale from 'config/locale';

import Question from 'components/Question/Question';
import Textarea from 'components/Textarea/Textarea';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Form from 'components/Form/Form';
import ChecklistGroup from 'components/ChecklistGroup/ChecklistGroup';
import ImportantDateGroupedQuestion from 'components/GoalForm/ImportantDateGroupedQuestion';

import { YES_NO_OPTIONS } from 'constants/options';
import {
  GOAL_OPTIONS_PURCHASE,
  GOAL_OPTIONS_REFINANCE,
} from 'shared/constants/myCRMTypes/applications';

const IMPORTANT_DATE_TYPES = ['settlement', 'finance', 'auction'];

const GoalForm = (props) => {
  const {
    onSubmit,
    formCompleted,
    formatMessage,
    messages,
    setCurrentQuestionTo,
    setPrimaryGoals,
    setSpecificGoal,
    setWhatYouWantTodo,
    setHasImportantDates,
    setHasImportantDateOfType,
    setImportantDateDay,
    setImportantDateMonth,
    setImportantDateYear,
    isMultipleImportantDatesEnabled,
    workingApplication,
    questions,
    isLocked,
    theme,
    onFocus,
  } = props;
  const {
    whatYouWantTodo,
    specificGoal,
    primaryGoals,
    hasImportantDates,
  } = workingApplication;

  const { groupPurchaseGoal, groupRefinanceGoals } = {
    groupPurchaseGoal: {
      action: setPrimaryGoals,
      value: primaryGoals,
      label: formatMessage(messages.primaryGoalsPurchaseGroup),
      items: GOAL_OPTIONS_PURCHASE(locale)(formatMessage),
      id: 'applyGoal--0',
    },
    groupRefinanceGoals: {
      action: setPrimaryGoals,
      value: primaryGoals,
      label: formatMessage(messages.primaryGoalsRefinanceGroup),
      items: GOAL_OPTIONS_REFINANCE(locale)(formatMessage),
      id: 'applyGoal--1',
    },
  };

  const checklistGroup = [groupPurchaseGoal, groupRefinanceGoals];

  const onHasImportantDatesChange = (value) => {
    setHasImportantDates(value);
    if (!value) {
      IMPORTANT_DATE_TYPES.forEach((type) => {
        setHasImportantDateOfType({ type, value });
      });
    }
  };

  const renderImportantDateQ = (type) => {
    const hasImportantDateKey = `has${_.capitalize(type)}Date`;
    const importantDateKey = `${type}Date`;
    const importantDateMessages =
      !isMultipleImportantDatesEnabled && type === 'settlement'
        ? {
            hasImportantDate: messages.hasSettlementDateOnly,
            importantDate: messages.settlementDateOnly,
          }
        : {
            hasImportantDate: messages[hasImportantDateKey],
            importantDate: messages[importantDateKey],
          };
    return (
      <ImportantDateGroupedQuestion
        key={type}
        type={type}
        hasImportantDateQ={questions[hasImportantDateKey]}
        hasImportantDate={workingApplication[hasImportantDateKey]}
        setHasImportantDateOfType={setHasImportantDateOfType}
        importantDateQ={questions[importantDateKey]}
        importantDate={workingApplication[importantDateKey]}
        setImportantDateDay={setImportantDateDay}
        setImportantDateMonth={setImportantDateMonth}
        setImportantDateYear={setImportantDateYear}
        isLocked={isLocked}
        formatMessage={formatMessage}
        messages={importantDateMessages}
        hideTopLine={isMultipleImportantDatesEnabled}
        theme={theme}
      />
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      formCompleted={formCompleted}
      onFocusLost={setCurrentQuestionTo(undefined)}
      onFocus={onFocus}
    >
      <Question
        {...questions.primaryGoals}
        label={formatMessage(messages.primaryGoals)}
        direction='row'
        theme={theme}
      >
        <ChecklistGroup groups={checklistGroup} disabled={isLocked} />
      </Question>

      <Question
        {...questions.whatYouWantTodo}
        label={formatMessage(messages.whatYouWantTodo)}
        direction='column'
        hint={
          messages.whatYouWantTodoHint &&
          formatMessage(messages.whatYouWantTodoHint)
        }
        theme={theme}
      >
        <Textarea
          action={setWhatYouWantTodo}
          value={whatYouWantTodo}
          disabled={isLocked}
          placeholder={
            messages.whatYouWantTodoPlaceHolder &&
            formatMessage(messages.whatYouWantTodoPlaceHolder)
          }
        />
      </Question>

      <Question
        {...questions.specificGoal}
        label={formatMessage(messages.specificGoal)}
        direction='column'
        hint={
          messages.specificGoalHint && formatMessage(messages.specificGoalHint)
        }
        theme={theme}
      >
        <Textarea
          action={setSpecificGoal}
          value={specificGoal}
          disabled={isLocked}
          placeholder={
            messages.specificGoalPlaceholder &&
            formatMessage(messages.specificGoalPlaceholder)
          }
        />
      </Question>

      <Question
        {...questions.hasImportantDates}
        label={formatMessage(messages.hasImportantDates)}
        direction='row'
        theme={theme}
      >
        <RadioButtonList
          action={onHasImportantDatesChange}
          value={hasImportantDates}
          items={YES_NO_OPTIONS}
          disabled={isLocked}
        />
      </Question>

      {IMPORTANT_DATE_TYPES.map(renderImportantDateQ)}
    </Form>
  );
};

GoalForm.propTypes = {
  workingApplication: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formCompleted: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  setCurrentQuestionTo: PropTypes.func.isRequired,
  setPrimaryGoals: PropTypes.func.isRequired,
  setSpecificGoal: PropTypes.func.isRequired,
  setWhatYouWantTodo: PropTypes.func.isRequired,
  setHasImportantDates: PropTypes.func.isRequired,
  setHasImportantDateOfType: PropTypes.func.isRequired,
  setImportantDateDay: PropTypes.func.isRequired,
  setImportantDateMonth: PropTypes.func.isRequired,
  setImportantDateYear: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isMultipleImportantDatesEnabled: PropTypes.bool.isRequired,
  theme: PropTypes.string,
};

export default GoalForm;
