import axios from 'axios';
import { ADVISOR_ORGS_API_PATH } from 'shared/constants/apiPaths';

import { returnJSON, throwJSON } from './baseApi';

export const getAdvisorOrgInfo = ({
  id: advisorOrgId,
  slug,
  shouldFetchPrincipleAdvisor,
}) => {
  return axios
    .get(`${ADVISOR_ORGS_API_PATH}/${slug}`, {
      params: { shouldFetchPrincipleAdvisor, advisorOrgId },
    })
    .then(returnJSON, throwJSON);
};
