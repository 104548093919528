import { handleActions } from 'redux-actions';

import * as expenseCategoryTypeActions from 'actions/expenseCategoryTypeActions';

import { setNewEntities } from 'lib/reducerHelper';

export const initialState = {
  entities: [],
};

const expenseCategoryTypeReducer = handleActions(
  {
    [expenseCategoryTypeActions.SET_EXPENSE_CATEGORY_TYPES](state, action) {
      return setNewEntities(state, action);
    },
  },
  initialState,
);

export default expenseCategoryTypeReducer;
