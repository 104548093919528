import React from 'react';
import renderHTML from 'react-render-html';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import styles from './RepaymentsExplanation.css';

const disclaimers = defineMessages({
  monthlyRepaymentDescription: {
    id: 'ProductDetail.monthlyRepaymentDescription',
    defaultMessage: `<div>*Monthly Repayments</div>
                     <div>Monthly repayments are calculated to take into account the interest rate, loan amount and repayment term. To get a complete indication of the total repayment costs you need to factor into account the upfront fee, ongoing monthly and annual fees, and other transaction charges over the life of the loan.</div>
                     <div>Variable rate loans show the monthly repayments at the current rate. Note that variables rates will move up and down over time, loosely in line with the offical cash rates set by the Reserve Bank of Australia (RBA).</div>
                     <div>Fixed rate loans show the monthly repayment during the fixed period only. After the fixed period has ended fixed rate loans usually automatically switches over to a variable rate for the remainder of the loan term.</div>
                     <div>Monthly repayment calculations are an approximate guide only. Speak to a LoanMarket Mortgage broker to understand the exact cost of a loan given your particular circumstances.</div>`,
  },
  comparisonRateDescription1: {
    id: 'ProductDetail.comparisonRateDescription1',
    defaultMessage: `<div>**Comparison Rates</div>
                     <div>A comparison rate is a tool to help consumers identify the true cost of a loan. It factors in the interest rate, loan amount, repayment term and ongoing fees and charges and displays this as a single percentage rate that can be used to compare various loans from different lenders.</div>`,
  },
  comparisonRateDescription2: {
    id: 'ProductDetail.comparisonRateDescription2',
    defaultMessage: `<div>Monthly Repayment and Comparison Rate* calculations do not include:</div>
                        <ul>
                          <li>The use of account features such as early repayments and offset accounts which vary widely among loans and can reduce the cost of a loan significantly.</li>
                          <li>Cost savings such as fee waivers or special rate discounts.</li>
                          <li>Fees and charges associated with loan options or events that may or may not be used by the borrower, such as early repayment or redraw fees.</li>
                          <li>Lenders Mortgage Insurance (LMI) which may be required to secure the loan and can increase the cost of the loan.</li>
                          <li>Government charges such as stamp duty or mortgage registration fees.</li>
                          <li>Fees and charges which aren’t available at the time the comparison rate is provided.</li>
                        </ul>`,
  },
});

const RepaymentsExplanation = ({ intl: { formatMessage } }) => (
  <section className={styles.root}>
    <div className={styles.disclaimerText}>
      {renderHTML(formatMessage(disclaimers.monthlyRepaymentDescription))}
    </div>
    <div className={styles.disclaimerText}>
      {renderHTML(formatMessage(disclaimers.comparisonRateDescription1))}
    </div>
    <div className={styles.disclaimerText}>
      {renderHTML(formatMessage(disclaimers.comparisonRateDescription2))}
    </div>
  </section>
);

RepaymentsExplanation.propTypes = {
  intl: intlShape,
};

export default injectIntl(RepaymentsExplanation);
