import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { shortStringWithoutCuttingWords } from 'lib/utils/stringUtils';

import styles from './ProductSection.css';

export const messages = defineMessages({
  showMore: {
    id: 'ProductSection.showMore',
    defaultMessage: 'Show More',
  },
  showLess: {
    id: 'ProductSection.showLess',
    defaultMessage: 'Show Less',
  },
});

class ProductSection extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    title: PropTypes.string,
    extraTitle: PropTypes.arrayOf(PropTypes.string),
    shortDescription: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    attributes: PropTypes.arrayOf(PropTypes.object),
    className: PropTypes.string,
    showLessLength: PropTypes.number,
  };

  static renderAbility(isAvailable) {
    const iconStyle =
      +isAvailable === 1
        ? classNames(styles.iconCheck, 'mi-check')
        : classNames(styles.iconCross, 'mi-cross');
    return (
      <div>
        <i className={iconStyle} />
      </div>
    );
  }

  static contentClass = (isFullContent) =>
    classNames.bind(styles)('sectionContent', { fullContent: isFullContent });

  static rootClass = (className) =>
    classNames.bind(styles)('productSection', className);

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  getDescription = (shortDescription, description, showLessLength) => {
    if (this.state.open || !showLessLength) {
      return description;
    }

    return `${
      shortDescription ||
      shortStringWithoutCuttingWords(description, showLessLength)
    }...`;
  };

  toggleShowMore = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderShowLessShowMore = (shortDescription, description, showLessLength) => {
    const { intl } = this.props;
    const showMore = intl.formatMessage(messages.showMore);
    const showLess = intl.formatMessage(messages.showLess);

    if (shortDescription) {
      return this.state.open ? (
        <div>
          <a onClick={this.toggleShowMore}>{showLess}</a>
        </div>
      ) : (
        <span className={styles.showMoreLess}>
          <a onClick={this.toggleShowMore}>{showMore}</a>
        </span>
      );
    }

    if (!showLessLength) {
      return null;
    }

    return (
      <span className={styles.showMoreLess}>
        <a onClick={this.toggleShowMore}>
          {this.state.open ? showLess : showMore}
        </a>
      </span>
    );
  };

  renderDesc() {
    const { shortDescription, description, showLessLength } = this.props;

    return (
      <div className={styles.description}>
        <span>
          {this.getDescription(shortDescription, description, showLessLength)}
        </span>
        {this.renderShowLessShowMore(
          shortDescription,
          description,
          showLessLength,
        )}
      </div>
    );
  }

  renderAttributeItems() {
    const { attributes } = this.props;
    return (
      <dl className={styles.productAttributeList}>
        {attributes.map(({ label, data, isText }, index) => {
          const showIcon = isText === false;
          return (
            <div
              key={`attributeItem-${index}`}
              className={styles.labelDataGroup}
            >
              <dt className={styles.label}>{label}</dt>
              <dd className={styles.data}>
                {showIcon ? ProductSection.renderAbility(data) : data}
              </dd>
            </div>
          );
        })}
      </dl>
    );
  }

  render() {
    const { title, extraTitle, attributes, className } = this.props;
    const hasAttributes = attributes !== undefined && attributes.length > 0;
    return (
      <div className={ProductSection.rootClass(className)}>
        <div className={ProductSection.contentClass(!hasAttributes)}>
          <div className={styles.titleWrapper}>
            <h4
              className={classNames(
                styles.title,
                !extraTitle && styles.fullWidth,
              )}
            >
              {title}
            </h4>
            {extraTitle && (
              <div className={styles.extraTitleWrapper}>
                {extraTitle.map((exTitle, i) => (
                  <div className={styles[`extraTitle${i + 1}`]} key={exTitle}>
                    {exTitle}
                  </div>
                ))}
              </div>
            )}
          </div>
          {this.renderDesc()}
        </div>
        {attributes && this.renderAttributeItems()}
      </div>
    );
  }
}

export default injectIntl(ProductSection);
