import _ from 'lodash';

import { percentageFormatter } from 'lib/utils/numberUtils';
import loanAmountCalculator from 'shared/lib/loanAmountCalculator';
import { getProspectiveProperty } from 'shared/lib/propertyHelper';

const NOT_APPLICABLE = 'Not Applicable';

export function calculateAverageOfAttribute(existingProperties, attribute) {
  return (
    _.map(existingProperties, attribute).reduce((p, c) => p + c, 0) /
    _.size(existingProperties)
  );
}

export function buildProfile(scenario) {
  const prospectiveProperty = getProspectiveProperty(scenario);

  return {
    $last_name: scenario.displayName,
    $email: scenario.email,
    Postcode: scenario.postcode,
    Locality:
      (prospectiveProperty && prospectiveProperty.locality) || NOT_APPLICABLE,
    'Loan Amount': loanAmountCalculator(scenario),
    'Deposit Amount Percentage':
      (prospectiveProperty &&
        percentageFormatter(
          prospectiveProperty.depositAmount / prospectiveProperty.value,
        )) ||
      NOT_APPLICABLE,
    'First Home Buyer':
      prospectiveProperty && prospectiveProperty.FHOGEligibility === 'true',
  };
}

export function pathnameToPageTitle(pathname) {
  return _.startCase(pathname).replace(/\s\d+($|\s)/g, ' *$1');
}

export function prettifyPathname(pathToPrettify, suffix) {
  const prettifiedPath = pathnameToPageTitle(pathToPrettify) || 'Home';
  return `Visited ${prettifiedPath} ${suffix || 'Page'}`;
}

function pushId(match, ids) {
  if (match) {
    ids.push(match[1]);
  }
  return match;
}

export function extractIdsFromPath(path) {
  const ids = [];
  const re = /\/(\d+)(?:\/|$)/g;
  let match = pushId(re.exec(path), ids);
  while (match !== null) {
    re.lastIndex -= 1;
    match = pushId(re.exec(path), ids);
  }
  // eslint-disable-next-line unicorn/explicit-length-check
  return ids.length ? ids : undefined;
}

function testForBot(agent) {
  return /bot|googlebot|crawler|spider|robot|crawling/i.test(agent);
}

export function isABot(agent) {
  return testForBot(agent);
}

export function isNotABot(agent) {
  return !testForBot(agent);
}

export function getGAClientID() {
  if (!document.cookie || document.cookie.length === 0) {
    return;
  }
  const gaCookie = document.cookie
    .split(';')
    .find((s) => s.trim().startsWith('_ga='));
  return gaCookie && gaCookie.replace(/_ga=GA\d+\.\d+\./g, '').trim();
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function buildTrackedData({
  isClient,
  clientId,
  familyId,
  countryCode,
  assignedAdvisorId,
  advisor = {},
  isGoalSetter = false,
  appId,
}) {
  return {
    user_family_id: `${familyId || ''}`,
    user_client_id: `${(!isGoalSetter && clientId) || ''}`,
    user_is_client: isClient ? 'true' : 'false',
    user_is_principal:
      advisor.role && !isClient
        ? `${advisor.role.includes('Principal')}`
        : 'false',
    user_is_loan_writer:
      advisor.role && !isClient
        ? `${advisor.role.includes('Loan Writer')}`
        : 'false',
    user_is_assistant: isClient ? 'false' : `${!!advisor.isAssistant}`,
    user_is_corporate: isClient ? 'false' : `${advisor.corporateTeam !== ''}`,
    user_corporate_team: isClient ? '' : `${advisor.corporateTeam || ''}`,
    user_country: `${countryCode || ''}`,
    user_branding_category: `${advisor.brandingCategory || ''}`,
    user_adviser_org_id: `${advisor.advisorOrgId || ''}`,
    user_adviser_org_name: `${advisor.advisorOrgName || ''}`,
    user_actual_adviser_org_name: `${advisor.actualAdviserOrgName || ''}`,
    user_assigned_adviser_id: `${assignedAdvisorId || ''}`,
    user_adviser_name: `${advisor.name || ''}`,
    user_adviser_email: `${advisor.email || ''}`,
    user_loan_app_id: `${appId || ''}`,
    user_reporting_state_name: `${advisor.reportingStateName || ''}`,
  };
}
