export const ROLE_ADULT = 1;
export const ROLE_CHILD = 2;

export const RESIDENCY_CITIZEN = { id: 1, name: 'Citizen' };
export const RESIDENCY_NON_RESIDENT = { id: 2, name: 'Non Resident' };
export const RESIDENCY_RESIDENT = { id: 3, name: 'Permanent Resident' };
export const RESIDENCY_TEMPORARY_RESIDENT = {
  id: 4,
  name: 'Temporary Resident',
};

export const CLIENT_RESIDENCIES = [
  /* ignor citizen because that is a separate question */
  RESIDENCY_RESIDENT,
  RESIDENCY_TEMPORARY_RESIDENT,
  RESIDENCY_NON_RESIDENT,
];

export const MARITAL_STATUS_SINGLE = { id: 1, name: 'Single' };
export const MARITAL_STATUS_MARRIED = { id: 2, name: 'Married' };
export const MARITAL_STATUS_DEFACTOR = { id: 3, name: 'Defacto' };
export const MARITAL_STATUS_SEPARATED = { id: 4, name: 'Separated' };
export const MARITAL_STATUS_DIVORCED = { id: 5, name: 'Divorced' };
export const MARITAL_STATUS_WIDOWED = { id: 6, name: 'Widowed' };

export const MARITAL_STATUSES = [
  MARITAL_STATUS_SINGLE,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_DEFACTOR,
  MARITAL_STATUS_SEPARATED,
  MARITAL_STATUS_DIVORCED,
  MARITAL_STATUS_WIDOWED,
];
