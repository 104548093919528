import { takeEvery, all, call, put, select } from 'redux-saga/effects';
import { push } from '@loan_market/react-router-redux-multi';

import {
  START_ANIMATION_SEQUENCE,
  NEXT_ANIMATION_SEQUENCE,
  STOP_ANIMATION_SEQUENCE,
  PAUSE_ANIMATION_SEQUENCE,
  SET_PATH_WITH_ANIMATION_SEQUENCE,
} from 'actions/UIActionTypes';

import UIActions from 'actions/UIActions';
import memoryRouterActions from 'actions/memoryRouterActions';
import * as UISelectors from 'selectors/UISelectors';

export function* nextAnimationSequence() {
  const sequence = yield select(UISelectors.sequence);
  const nextPageList = yield select(UISelectors.nextPath);
  const cloned = [...sequence];
  const nextAnimation = cloned.shift();
  // eslint-disable-next-line unicorn/explicit-length-check
  if ((!nextAnimation || nextAnimation === '/') && nextPageList.length) {
    const nextPage = nextPageList[nextPageList.length - 1];
    yield put(UIActions.setNextPath()); // clear the next path
    yield put(push(nextPage));
  }
  yield put(UIActions.setAnimationSequence(cloned));
  yield put(memoryRouterActions.push(nextAnimation || '/'));
}

export function* startAnimationSequence({ payload }) {
  yield put(UIActions.setAnimationSequence(payload));
  yield call(nextAnimationSequence);
}

export function* stopAnimationSequence() {
  yield put(UIActions.setAnimationSequence([]));
  yield put(memoryRouterActions.push('/'));
}

export function* goToPathWithAnimation({ payload }) {
  const { path, animation } = payload;
  if (!animation) {
    return yield put(push(path));
  }
  yield put(UIActions.setNextPath(path));
  yield put(UIActions.startAnimationSequence(animation));
}

export function* pauseAnimationSequence() {
  yield put(memoryRouterActions.push('/'));
}

function* watchStartAnimationSequence() {
  yield takeEvery(START_ANIMATION_SEQUENCE, startAnimationSequence);
}

function* watchNextAnimationSequence() {
  yield takeEvery(NEXT_ANIMATION_SEQUENCE, nextAnimationSequence);
}

function* watchStopAnimationSequence() {
  yield takeEvery(STOP_ANIMATION_SEQUENCE, stopAnimationSequence);
}

function* watchGoToPathWithAnimation() {
  yield takeEvery(SET_PATH_WITH_ANIMATION_SEQUENCE, goToPathWithAnimation);
}

export default function* employmentSagas() {
  yield all([
    watchStartAnimationSequence(),
    watchNextAnimationSequence(),
    watchStopAnimationSequence(),
    watchGoToPathWithAnimation(),
    takeEvery(PAUSE_ANIMATION_SEQUENCE, pauseAnimationSequence),
  ]);
}
