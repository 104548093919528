import _ from 'lodash';
import * as request from 'axios';
import { parseCurrencyValues } from 'lib/utils/numberUtils';
import { UTILS_API_PATH } from 'shared/constants/apiPaths';
import axios from './baseApi';

export function getPurchaseCost(params) {
  return axios
    .get('/purchase-cost', {
      baseURL: UTILS_API_PATH,
      params,
    })
    .then((response) => {
      const purchaseCostData = _.pick(response.data, [
        'grant',
        'mortgageRegFee',
        'stampDutyPayable',
        'transferFee',
        'totalFee',
        'otherConcession',
        'totalConcession',
      ]);

      const additionalData = _.pick(params, [
        'totalIncome',
        'movingCost',
        'conveyancerCost',
        'renovationCost',
      ]);

      return parseCurrencyValues({ ...purchaseCostData, ...additionalData });
    })
    .catch((error) => error);
}

export function getQuickLMI(data) {
  return axios
    .post(`${UTILS_API_PATH}/quick-lmi`, data)
    .then((response) => response.data)
    .catch((error) => error);
}

export function getListingInfo(listingId) {
  return axios
    .get(`${UTILS_API_PATH}/${listingId}/ray-white-listing-info`)
    .then((response) => response.data)
    .catch((error) => error);
}

export function getCountryFromIPAddress() {
  return request
    .get('https://geolocation-db.com/json/')
    .then((response) => response.data && response.data.country_code)
    .catch((error) => error);
}
