import _ from 'lodash';
import { takeEvery, all, put, call, select, fork } from 'redux-saga/effects';
import goalLoanApplicationActions, {
  LOAD_GOAL_LOAN_APPLICATION,
  UPDATE_GOAL_LOAN_APPLICATION,
  UPDATE_GOAL_PREFERENCES,
  SWITCH_GOAL_LOAN_APP,
  UPDATE_HAS_VISITED,
  UPDATE_GOAL_PROFILE_PERCENTAGE,
  UPDATE_GOAL_SERVICEABILITY_MAX_BORROW,
} from 'actions/goal/goalLoanApplicationActions';
import structureActions, {
  GOAL_SAVE_STRUCTURE,
} from 'actions/structureActions';
import scenarioActions from 'actions/scenarioActions';

import {
  getLoanApplication,
  putLoanApplication,
  getFundingsFromLoanApplication,
  getStructuresFromLoanApplication,
  getSecuritiesFromLoanApplication,
  getProperties,
  getAssets,
  putStructures,
} from 'services/goal/goalLoanApplicationApi';

import { getDocumentsForLoanApplication } from 'services/loanApplicationApi';

import {
  getContactLiabilities,
  getContactExpenses,
  getContactIncomes,
  getContactClients,
  getContactAddress,
  getContactEmployments,
} from 'services/contactApi';

import { getCompany } from 'services/companyApi';
import { getContactApplications } from 'services/goal/goalContactApi';
import { getDetail as getProductDetail } from 'services/productsApi';
import { getAdvisorInfo } from 'services/advisorsApi';
import {
  getPreferences,
  postPreferences,
} from 'services/goal/goalPreferencesApi';

import LocalStorageProxy, { GOAL_EXEMPTED_PROPS } from 'lib/localStorageProxy';
import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import { toMyCRMLogin } from 'lib/pathHelper';
import { getContactIds } from 'lib/utils/formUtils';
import { getErrorStatus } from 'lib/errorHelper';
import { featureFlags } from 'lib/rollout';
import locale from 'config/locale';

import UIActions from 'actions/UIActions';
import addressActions from 'actions/addressActions';
import assetActions from 'actions/assetActions';
import liabilityActions from 'actions/liabilityActions';
import incomeActions from 'actions/incomeActions';
import expenseActions from 'actions/expenseActions';
import employmentActions from 'actions/employmentActions';
import clientActions from 'actions/clientActions';
import contactActions from 'actions/contactActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import loanApplicantActions from 'actions/loanApplicantActions';
import fundingActions from 'actions/fundingActions';
import documentActions from 'actions/documentActions';
import propertiesActions from 'actions/applyPropertyActions';
import expenseCategoryTypeActions from 'actions/expenseCategoryTypeActions';

import { requestAdvisorInfo } from 'sagas/advisorSagas';
import { fetchAccreditedLenders } from 'sagas/lenderSagas';
import {
  requestLoanAppInfoQuestions,
  requestLoanAppInfoAnswers,
} from 'sagas/goal/goalLoanAppInfoSagas';

import { totalLoanRequired } from 'selectors/fundingSelectors';
import * as advisorSelector from 'selectors/advisorSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import {
  getHasVisitedKey,
  goalLoanApplication,
} from 'selectors/goalLoanApplicationSelectors';
import {
  goalPercentageCompleted,
  completedSections as goalCompletedSections,
} from 'selectors/completionSelectors';

import { mapWithContactId, goalPrimaryPurpose } from 'lib/goalLoanHelper';

import * as SpinnerNames from 'constants/spinnerNames';
import { PERSON } from 'constants/options';
import {
  setRentalResidencyProperty,
  getIncomeTypes,
  getDepositTypes,
  getDocumentTypes,
  getTitleOptions,
} from 'sagas/loanApplicationSagas';

export function* computeGoalClientProfilePercentages({ payload: clients }) {
  const promises = clients.map(function* (c) {
    yield put(loanApplicantActions.setPrimaryApplicant(c.id));
    const percent = yield select(goalPercentageCompleted);
    const completedSections = yield select(goalCompletedSections);
    return {
      clientId: c.id,
      percent: Math.floor(percent * 100),
      completedSections,
    };
  });
  const percentages = yield all(promises);
  yield put(
    goalLoanApplicationActions.setGoalClientProfilePercentages(percentages),
  );
}

export function* updateGoalContactServiceabilityMaxBorrows({
  contactId,
  maximumLoanAmount,
}) {
  const application = yield select(goalLoanApplication);
  const maxBorrows = application.serviceabilityMaxBorrows;
  const findContact = maxBorrows.find((m) => m.contactId === contactId);
  let newMaxBorrows;

  if (findContact) {
    newMaxBorrows = [
      ...maxBorrows.filter((m) => m.contactId !== contactId),
      { contactId, maximumLoanAmount },
    ];
  } else {
    newMaxBorrows = [...maxBorrows, { contactId, maximumLoanAmount }];
  }
  yield put(
    goalLoanApplicationActions.setGoalContactServiceabilityMaxBorrows(
      newMaxBorrows,
    ),
  );
}

export function* requestClientsInfo(applicants, loanAppId) {
  const [persons, companies] = _.partition(applicants, (a) => !!a.clientId);
  const personsContactIds = [...new Set(persons.map((a) => a.contactId))];
  const [...clients] = _.flatten(
    yield Promise.all([
      ...personsContactIds.map((contactId) =>
        getContactClients(contactId, loanAppId),
      ),
      ...companies.map((c) => getCompany(c.companyId, c.contactId)),
    ]),
  ).map((c) => ({
    ...c,
    isCoapplicant: applicants.some(
      (a) => a.clientId === c.id || a.companyId === c.id,
    ),
    // TODO: Uncomment once api is ready to return isGuarantor in applicants array
    // isGuarantor: persons.find(a => a.clientId === c.id).isGuarantor,
  }));

  const currentAdvisor = yield select(advisorSelector.advisor);
  const sortRef = [...new Set(clients.map((c) => c.contactId))];

  const sortedClients = _.sortBy(clients, (c) => sortRef.indexOf(c.contactId))
    .map((c) => ({ ...c, advisorId: currentAdvisor.familyId }))
    .filter((c) => c.type === PERSON);
  yield put(clientActions.setNewClients(sortedClients));

  const contacts = applicants.map((a) => {
    const contactDetails = clients.find(
      (c) => c.id === (a.clientId || a.companyId),
    );
    return { ...contactDetails, ...a, id: a.contactId };
  });
  yield put(contactActions.setNewContacts(contacts)); // TODO move someplace else?
}

export function* requestContactInfo(contactId) {
  const applicationId = LocalStorageProxy.primaryLoanApplicationId;
  const [
    liabilities = [],
    incomes = [],
    expenses = [],
    employments = [],
  ] = yield Promise.all([
    getContactLiabilities(contactId, applicationId),
    getContactIncomes(contactId),
    getContactExpenses(contactId, featureFlags.livingExpenseV2.isEnabled()),
    getContactEmployments(contactId),
  ]);

  yield put(
    liabilityActions.insertNewLiabilities(
      mapWithContactId(liabilities, contactId),
    ),
  );
  yield put(
    incomeActions.insertNewIncomes(mapWithContactId(incomes, contactId)),
  );
  yield put(
    expenseActions.insertNewExpenses(mapWithContactId(expenses, contactId)),
  );
  yield put(
    employmentActions.insertNewEmployments(
      mapWithContactId(employments, contactId),
    ),
  );
}

export function* loadLoanStructureDetails(structure) {
  try {
    const { productId, loanAmount, loanTerm, repaymentType } = structure;
    if (productId) {
      const productDetail = yield getProductDetail(
        productId,
        loanAmount,
        loanTerm,
        repaymentType,
      );
      yield put(structureActions.setStructure({ ...structure, productDetail }));
    } else {
      yield put(structureActions.setStructure(structure));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`loan structure ${structure.id} has no product yet`);
  }
}

export function* loadLoanStructures(loanApplicationId) {
  let structures;
  try {
    structures = yield call(
      getStructuresFromLoanApplication,
      loanApplicationId,
      true,
    );
  } catch (error) {
    structures = [];
  }

  // eslint-disable-next-line unicorn/explicit-length-check
  if (structures.length) {
    yield put(structureActions.setNewStructures(structures));
    yield all(structures.map((s) => call(loadLoanStructureDetails, s)));
  } else {
    const loanAmount = yield select(totalLoanRequired);
    yield put(structureActions.updateWorkingStructure({ loanAmount }));
  }
}

export function* setRentalResidence(realEstateAssets, properties) {
  const clients = yield select(clientSelectors.adultClients);
  yield all(
    clients.map((a) =>
      call(
        setRentalResidencyProperty,
        {
          realEstateAssets: realEstateAssets.filter((rea) =>
            (rea.clientIds || []).includes(a.clientId),
          ),
          notOwnProperties: properties,
        },
        a.id,
      ),
    ),
  );
}

export function* requestLoanAppInfo(application) {
  const { id: loanApplicationId, applicants } = application;
  const [
    allAssets,
    properties,
    fundings,
    securities,
    documents,
  ] = yield Promise.all([
    getAssets(loanApplicationId),
    getProperties(loanApplicationId),
    getFundingsFromLoanApplication(loanApplicationId),
    getSecuritiesFromLoanApplication(loanApplicationId),
    getDocumentsForLoanApplication(loanApplicationId, locale.countryCode),
  ]);

  yield fork(loadLoanStructures, loanApplicationId);

  const contactIds = [...new Set(applicants.map((a) => a.contactId))];
  const [...addresses] = yield Promise.all(
    contactIds.map((contactId) => getContactAddress(contactId)),
  );

  yield put(addressActions.setNewAddresses(_.flatten(addresses)));
  yield put(documentActions.setNewDocuments(documents));
  const { assets = [], realEstateAssets = [] } = allAssets;
  yield put(assetActions.setNewAssets(assets));
  yield put(
    propertiesActions.setNewProperties(properties.concat(realEstateAssets)),
  );
  yield put(fundingActions.setNewFundings(fundings));
  yield put(loanApplicationActions.setSecurities(securities));
  yield call(setRentalResidence, realEstateAssets, properties);
}

export function* requestGoalLoanApplicationList(loadedApplication) {
  const primaryLoanAppId = LocalStorageProxy.primaryLoanApplicationId;
  let application = {};
  if (!primaryLoanAppId) {
    application = loadedApplication;
    LocalStorageProxy.primaryLoanApplicationId = loadedApplication.id;
  } else {
    application = yield call(getLoanApplication, primaryLoanAppId);
  }

  const primaryContactId = application.applicants[0].contactId;
  const primaryClients = application.applicants
    .filter((a) => !!a.clientId)
    .map((applicant) => applicant.clientId);
  const applications = yield call(
    getContactApplications,
    primaryContactId,
    primaryClients.join(','),
  );
  yield put(goalLoanApplicationActions.setGoalLoanAppsList(applications));
}

export function* requestGoalPreferences(brokerId) {
  const preferences = yield getPreferences(brokerId);
  yield put(goalLoanApplicationActions.setGoalPreferences(preferences));
}

const getFeaturesNamesForQuestions = () => {
  const features = ['antiHawking'];
  return features.filter((feature) => featureFlags[feature].isEnabled());
};

export function* requestGoalQuestionsAndAnswers(loanAppId, familyId) {
  const featureNames = getFeaturesNamesForQuestions();
  yield fork(requestLoanAppInfoQuestions, {
    payload: { familyId, featureNames },
  });
  yield fork(requestLoanAppInfoAnswers, { payload: { loanAppId } });
}

export function* updateGoalPreferences({ payload }) {
  const currentAdvisor = yield select(advisorSelector.advisor);
  const preferences = yield call(
    postPreferences,
    currentAdvisor.familyId,
    payload,
  );
  yield put(goalLoanApplicationActions.setGoalPreferences(preferences));
}

export function* requestGoalLoanApplicationData(loanApplicationId) {
  const application = yield call(getLoanApplication, loanApplicationId);
  const { countryCode, applicants } = application;
  if (countryCode) {
    locale.countryCode = countryCode;
  }

  const clientsWithBroker = applicants.filter((a) => a.brokerId && a.clientId);
  if (clientsWithBroker.length === 0) {
    throw new Error('No applicants found!');
  } // make sure there are contacts as clients because user might open a loan app with only a business as an applicant

  yield put(
    loanApplicationActions.setNewWorkingApplication({
      ...application,
      contacts: getContactIds(applicants),
    }),
  );

  const contactIds = [...new Set(applicants.map((a) => a.contactId))];
  const brokerId = clientsWithBroker[0].brokerId;
  yield fork(getIncomeTypes, locale.countryCode);
  yield fork(getDepositTypes);
  yield fork(getDocumentTypes);
  yield fork(getTitleOptions);
  yield call(requestClientsInfo, applicants, loanApplicationId);
  yield all(contactIds.map((c) => call(requestContactInfo, c)));
  yield call(requestLoanAppInfo, application);
  yield call(requestGoalLoanApplicationList, application);
  yield call(requestGoalQuestionsAndAnswers, loanApplicationId, contactIds[0]);
  yield call(requestGoalPreferences, brokerId);

  const advisor = yield call(requestAdvisorInfo, {
    payload: { familyId: brokerId, showOffice: true },
  });
  const brokerFamilyId = LocalStorageProxy.brokerFamilyId;
  const advisorToTrack =
    advisor.familyId === brokerFamilyId || !brokerFamilyId
      ? advisor
      : yield call(getAdvisorInfo, {
          familyId: brokerFamilyId,
          showOffice: true,
        });
  yield put(
    scenarioActions.trackCurrentUser({
      isClient: false,
      familyId: advisorToTrack.familyId,
      countryCode,
      appId: application.id,
      advisor: advisorToTrack,
      isGoalSetter: true,
      email: advisorToTrack.email,
      mobile: advisorToTrack.mobile,
      assignedAdvisorId: advisor.familyId,
    }),
  );
  yield put(
    scenarioActions.setLoanPurpose(
      goalPrimaryPurpose(application.primaryGoals),
    ),
  );
  yield put(expenseCategoryTypeActions.fetchExpenseCategoryTypes());
  if (featureFlags.lendersByAccreditation.isEnabled()) {
    yield call(fetchAccreditedLenders);
  }
}

export function* loadGoalLoanApplication({ payload: loanApplicationId }) {
  try {
    yield call(requestGoalLoanApplicationData, loanApplicationId);
    yield put(
      goalLoanApplicationActions.setActiveGoalLoanApp(
        Number(loanApplicationId),
      ),
    );
  } catch (error) {
    console.error(error);
    LocalStorageProxy.clearAllExcept(GOAL_EXEMPTED_PROPS); // clear localStorage on access error
    window.location.href = toMyCRMLogin(window.location.href);
  }
}

export function* switchGoalLoanApplication({ payload: loanApplicationId }) {
  yield put(UIActions.pushSpinner('switchGoalLoanApplication'));
  try {
    LocalStorageProxy.loginAsLoanApplicationId = loanApplicationId;
    yield put(
      goalLoanApplicationActions.setActiveGoalLoanApp(
        Number(loanApplicationId),
      ),
    );
    yield call(requestGoalLoanApplicationData, loanApplicationId);
    yield put(
      UIActions.startAnimationSequence(['/checkout-appointment-companion']),
    );
  } catch (error) {
    console.error(error);
    LocalStorageProxy.clearAllExcept(GOAL_EXEMPTED_PROPS);
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
  }
  yield put(UIActions.popSpinner('switchGoalLoanApplication'));
}

export function* updateLoanApplication({ payload }) {
  const currentApplication = yield select(
    applicationSelectors.workingApplication,
  );
  const applicationId = currentApplication.id;

  yield call(putLoanApplication, { ...currentApplication, id: applicationId });
  yield put(
    scenarioActions.setLoanPurpose(
      goalPrimaryPurpose(currentApplication.primaryGoals),
    ),
  );
  yield put(
    loanApplicationActions.setLoanApplication({
      ...currentApplication,
      ...payload,
    }),
  );
  yield put(loanApplicationActions.loadApplication(applicationId));
  yield call(requestGoalLoanApplicationList, currentApplication);
}

export function* updateHasVisited({ payload: section }) {
  yield put(UIActions.pushSpinner('updateHasVisited'));
  try {
    const application = yield select(applicationSelectors.workingApplication);
    const key = getHasVisitedKey(section);
    if (!application.metadata[key]) {
      yield put(loanApplicationActions.setMetadata({ [key]: true }));
      const { id, metadata } = yield select(
        applicationSelectors.workingApplication,
      );
      yield call(updateLoanApplication, { payload: { id, metadata } });
    }
  } catch (error) {
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
  }
  yield put(UIActions.popSpinner('updateHasVisited'));
}

export function* saveStructure({ payload: { structure } }) {
  yield put(UIActions.pushSpinner(SpinnerNames.SAVE_STRUCTURE));
  try {
    yield call(putStructures, structure);
    yield put(structureActions.setStructure(structure));
  } catch (error) {
    yield put(UIActions.setPageError({ status: getErrorStatus(error), error }));
  }
  yield put(UIActions.popSpinner(SpinnerNames.SAVE_STRUCTURE));
}

export default function* loanApplicationSagas() {
  yield all([
    yield monitorAsyncRequest(
      takeEvery,
      LOAD_GOAL_LOAN_APPLICATION,
      loadGoalLoanApplication,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      UPDATE_GOAL_LOAN_APPLICATION,
      updateLoanApplication,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      SWITCH_GOAL_LOAN_APP,
      switchGoalLoanApplication,
    ),
    yield monitorAsyncRequest(takeEvery, UPDATE_HAS_VISITED, updateHasVisited),
    yield monitorAsyncRequest(takeEvery, GOAL_SAVE_STRUCTURE, saveStructure),
    yield monitorAsyncRequest(
      takeEvery,
      UPDATE_GOAL_PREFERENCES,
      updateGoalPreferences,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      UPDATE_GOAL_PROFILE_PERCENTAGE,
      computeGoalClientProfilePercentages,
    ),
    yield monitorAsyncRequest(
      takeEvery,
      UPDATE_GOAL_SERVICEABILITY_MAX_BORROW,
      updateGoalContactServiceabilityMaxBorrows,
    ),
  ]);
}
