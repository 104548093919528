import React from 'react';

export function coApplicationsHint() {
  return (
    <div>
      <div>Examples of co-applicants include: </div>
      <ul>
        <li>
          Guarantors, e.g. Parents providing security to help you get a loan
        </li>
        <li>Borrowers, e.g. Friends and family that will be co-owners</li>
        <li>Trusts or other business entities involved in the transaction</li>
      </ul>
    </div>
  );
}
export function assetsHint() {
  return (
    <div>
      <div>Examples of assets include: </div>
      <ul>
        <li>Cars or boats</li>
        <li>Investments like stocks or shares</li>
        <li>Home contents, furniture and appliances</li>
      </ul>
    </div>
  );
}
export function incomesHint() {
  return (
    <div>
      <div>Examples of incomes include: </div>
      <ul>
        <li>Salary / wages</li>
        <li>Regular overtime</li>
        <li>Bonuses</li>
      </ul>
    </div>
  );
}
export function propertiesHint() {
  return (
    <div>
      <div>Examples include: </div>
      <ul>
        <li>A residential investment property</li>
        <li>A commercial property</li>
        <li>A farm or rural property</li>
      </ul>
    </div>
  );
}
export function prospectivePropertiesHint() {
  return (
    <div>
      <div>Examples include: </div>
      <ul>
        <li>Home, e.g. A property for residential purpose</li>
        <li>Investment, e.g. A property for investment purpose</li>
      </ul>
    </div>
  );
}
export function liabilitiesHint() {
  return (
    <div>
      <div>Examples of liabilities include: </div>
      <ul>
        <li>Credit card</li>
        <li>Student loans</li>
        <li>Car or personal loans</li>
      </ul>
    </div>
  );
}
