import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import SectionTile from '@bit/loanmarket.share.components.section-tile';
import styles from './SectionItemList.css';
import sectionTileStyles from './SectionTile.css';

export const SectionItemList = (props) => {
  const onItemClick = (item) => () => props.onItemClick(item);

  const renderItem = (item) => (
    <div
      key={item.key}
      className={classNames(sectionTileStyles.rootWrapper, {
        [sectionTileStyles.selected]: item.isSelected,
        [sectionTileStyles[props.theme]]: !!props.theme,
      })}
    >
      <SectionTile
        id={item.key}
        title={item.title}
        titleDescription={item.description || ''}
        iconName={item.icon}
        actionIcon='mi-arrow-right'
        onClick={onItemClick(item)}
      />
      {item.isSelected && <i className='sl-custom-check-filled' />}
    </div>
  );

  return (
    <div className={styles.items}>{props.sectionItems.map(renderItem)}</div>
  );
};

SectionItemList.displayName = 'SectionItemList';
SectionItemList.propTypes = {
  sectionItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.string,
      isSelected: PropTypes.bool,
    }),
  ),
  onItemClick: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  theme: PropTypes.string,
};

export default SectionItemList;
