import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import * as clientSelectors from 'selectors/clientSelectors';
import * as contactSelectors from 'selectors/contactSelectors';

import { accordionPropTypes } from 'types/customPropTypes';
import { householdIsCouple, makeCasualDisplayName } from 'lib/utils/formUtils';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import householdActions from 'actions/householdActions';
import clientActions from 'actions/clientActions';

import EditableItem from 'components/EditableItem/EditableItem';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import HouseholdForm from 'components/HouseholdForm/HouseholdForm';
import Button from 'components/Button/Button';
import QuestionHint from 'components/Question/QuestionHint';

import applySection from 'hocs/applySection';

const messages = defineMessages({
  title: {
    id: 'FamilyApply.title',
    defaultMessage: 'Your Household',
  },
  titleDescription: {
    id: 'FamilyApply.titleDescription',
    defaultMessage: 'Tell us about your current household',
  },
  titleDescriptionCompleted: {
    id: 'FamilyApply.titleDescriptionCompleted',
    defaultMessage: '{household}, {dependentCount} dependent children',
  },
  headerDescription: {
    id: 'FamilyApply.headerDescription',
    defaultMessage: 'Tell us about your current household',
  },
  headerDescriptionCompleted: {
    id: 'FamilyApply.headerDescriptionCompleted',
    defaultMessage:
      '{household}, with {dependentCount} dependent {dependentCount, plural, =1 {child} other {children}}.',
  },
  addFamilyMember: {
    id: 'FamilyApply.addFamilyMember',
    defaultMessage: 'Add a family member',
  },
  editFamilyMember: {
    id: 'FamilyApply.editFamilyMember',
    defaultMessage: 'Edit your family member',
  },
  enterPartner: {
    id: 'FamilyApply.enterPartner',
    defaultMessage: 'Enter your partners details',
  },
  isDependent: {
    id: 'FamilyApply.isDependent',
    defaultMessage: 'Child { index }',
  },
  enterDependents: {
    id: 'FamilyApply.enterDependents',
    defaultMessage: 'Enter your dependents details',
  },
  yourPartnersDetails: {
    id: 'FamilyApply.yourPartnersDetails',
    defaultMessage: 'Partner',
  },
  addPartnerDetails: {
    id: 'FamilyApply.addPartnerDetails',
    defaultMessage: 'Add Partner Details',
  },
  dependentLabel: {
    id: 'FamilyApply.dependentLabel',
    defaultMessage: 'Dependent',
  },
  addChildDetails: {
    id: 'FamilyApply.addChildDetails',
    defaultMessage: 'Add Child Details',
  },
  couple: {
    id: 'FamilyApply.couple',
    defaultMessage: 'Couple',
  },
  single: {
    id: 'FamilyApply.single',
    defaultMessage: 'Single',
  },
  addMoreDetails: {
    id: 'FamilyApply.addMoreDetails',
    defaultMessage: 'Add more details',
  },
  ageYears: {
    id: 'FamilyApply.ageYears',
    defaultMessage: `{age} years old`,
  },
  ageMonths: {
    id: 'FamilyApply.ageMonths',
    defaultMessage: `{age} months old`,
  },
  ageYearsAndMonths: {
    id: 'FamilyApply.ageYearsAndMonths',
    defaultMessage: `{ageYears} years and {ageMonths} months old`,
  },
  householdHint: {
    id: 'FamilyApply.householdHint',
    defaultMessage:
      'Understanding your household helps us determine your borrowing capacity, and select a lender better suited to your situation.',
  },
});

const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    householdShape,
    dependents,
    isCompleted,
  } = props;

  const dependentCount = dependents.length.toString() || '0';
  const household = formatMessage(
    messages[householdIsCouple(householdShape) ? 'couple' : 'single'],
  );
  const postfix = isCompleted ? 'Completed' : '';

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      household,
      dependentCount,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      household,
      dependentCount,
    }),
  };
};

const nextButtonProps = (props) => {
  return {
    disabled: !props.householdFormCompleted,
  };
};

class FamilyApply extends Component {
  static displayName = 'FamilyApply';
  static propTypes = {
    intl: intlShape.isRequired,
    dependents: PropTypes.arrayOf(PropTypes.object),
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    hasHousehold: PropTypes.bool,
    primaryApplicantsPartner: PropTypes.object,
    primaryHasFamilyEntities: PropTypes.bool.isRequired,
    saveAndNext: PropTypes.func.isRequired,
    urlPath: PropTypes.string.isRequired,
    loadDependentsIntoWorking: PropTypes.func.isRequired,
    householdShape: PropTypes.string,
    workingHouseholdShape: PropTypes.string,
    isCouple: PropTypes.bool,
    isParent: PropTypes.bool,
    isSingle: PropTypes.bool,
    isCoupleOnlyWithPartnerAdded: PropTypes.bool,
  };

  componentDidMount() {
    this.props.loadDependentsIntoWorking();
  }

  popupTitle = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    const { popupClientId } = this.state;
    const action = popupClientId === 'new' ? 'add' : 'edit';
    if (action === 'add') {
      return formatMessage(messages.addFamilyMember);
    }
    return formatMessage(messages.editFamilyMember);
  };

  newClientUrl = () => `${this.props.urlPath}/family/client/new`;
  editClientUrl = (id) => `${this.props.urlPath}/family/client/${id}`;

  renderItems = (partner, dependents) => {
    const {
      intl: { formatMessage },
    } = this.props;
    const items = [];

    if (partner) {
      items.push(
        <EditableItem
          leftIcon='sl-custom-connection-2'
          key='addThisClientPartner'
          url={this.editClientUrl(partner.id)}
          leftLabel={formatMessage(messages.yourPartnersDetails)}
          rightLabel={
            makeCasualDisplayName(partner) ||
            formatMessage(messages.yourPartnersDetails)
          }
          rightDescription={
            partner.mobile || formatMessage(messages.addMoreDetails)
          }
        />,
      );
    }

    dependents.forEach((child, i) => {
      const age = child.age;
      let unit = child.ageUnit === 'years' ? 'Years' : 'Months';
      let ageYears;
      let ageMonths;
      if (age % 1 !== 0) {
        ageYears = Math.floor(age);
        ageMonths = Math.floor((age % 1) * 12);
        unit = 'YearsAndMonths';
      }
      const ageDescription = formatMessage(messages[`age${unit}`], {
        age,
        ageYears,
        ageMonths,
      });
      items.push(
        <EditableItem
          key={i}
          leftIcon='sl-custom-connection-2'
          url={this.editClientUrl(child.id)}
          leftLabel={formatMessage(messages.dependentLabel)}
          rightLabel={
            child.firstName ||
            formatMessage(messages.isDependent, { index: i + 1 })
          }
          rightDescription={
            !_.isEmpty(child.dob)
              ? ageDescription
              : formatMessage(messages.addMoreDetails)
          }
        />,
      );
    });

    return items;
  };

  renderHasHousehold() {
    const { primaryApplicantsPartner, dependents } = this.props;
    const hasDependentsAdded = (dependents || []).length > 0;
    const hasItemsToShow = !!primaryApplicantsPartner || hasDependentsAdded;
    return (
      hasItemsToShow && (
        <ApplyItemContainer>
          {this.renderItems(primaryApplicantsPartner, dependents)}
        </ApplyItemContainer>
      )
    );
  }

  getCurrentHouseholdShape() {
    const { workingHouseholdShape, householdShape } = this.props;
    return workingHouseholdShape || householdShape;
  }

  getButtonLabel() {
    const { isCouple, isParent, primaryApplicantsPartner } = this.props;
    if (isCouple && isParent && !primaryApplicantsPartner) {
      return 'Add a family member';
    } else if (isCouple && !isParent) {
      return 'Add your partner';
    } else if (isParent) {
      return 'Add your child';
    }
  }

  render() {
    const {
      intl: { formatMessage },
      accordionProps: { isLocked },
      saveAndNext,
      primaryHasFamilyEntities,
      isCoupleOnlyWithPartnerAdded,
      isSingle,
    } = this.props;

    const showAddMemberButton =
      !isSingle && !isCoupleOnlyWithPartnerAdded && !isLocked;
    return (
      <div id='familyApply'>
        <HouseholdForm isLocked={isLocked} onSubmit={saveAndNext} />
        {primaryHasFamilyEntities && this.renderHasHousehold()}
        {showAddMemberButton && (
          <Button
            url={this.newClientUrl()}
            theme='applyNew'
            icon='sl-custom-connection-2'
            visibility={!isLocked}
          >
            {this.getButtonLabel()}
          </Button>
        )}
        <QuestionHint
          id='familyApply'
          hint={formatMessage(messages.householdHint)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dependents: clientSelectors.primaryApplicantsDependents(state),
    householdShape: contactSelectors.householdShape(state),
    householdFormCompleted: state.household.formCompleted,
    workingHouseholdShape: state.household.householdShape,
    primaryHasFamilyEntities: clientSelectors.primaryHasFamilyEntities(state),
    isCoupleOnlyWithPartnerAdded: contactSelectors.isCoupleOnlyWithPartnerAdded(
      state,
    ),
    isCouple: contactSelectors.isCouple(state),
    isParent: contactSelectors.isParent(state),
    isSingle: contactSelectors.isSingle(state),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = { ...ownProps, ...stateProps, ...dispatchProps };

  const originalOnSubmit = props.onSubmit;
  props.onSubmit = () => {
    dispatchProps.saveHousehold();
    originalOnSubmit();
  };

  return props;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveHousehold: householdActions.saveHousehold,
      loadDependentsIntoWorking: clientActions.loadDependentsIntoWorking,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-connection-2',
      furtherDecoration,
      nextButtonProps,
    })(FamilyApply),
  ),
);
