import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as myPropTypes from 'types/customPropTypes';
import { reduxMirrorPropTypes } from '@loanmarket/react-redux-mirror';

import _ from 'lodash';

import Questions from 'lib/Questions';

import { REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO } from 'constants/validators';

import Form from 'components/Form/Form';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

const questionSet = ({ validation }) => {
  const questions = new Questions();
  questions.addBranch([
    'value',
    ..._.get(validation, 'value', [REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO]),
  ]);
  return questions.arrayOfQuestions();
};

class SingleInputForm extends Component {
  static propTypes = {
    value: myPropTypes.primitiveType,
    validation: PropTypes.object,
    setValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
    ...reduxMirrorPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  handleUpdate = () => {
    const { sync, onSubmit } = this.props;
    sync();
    onSubmit();
  };

  handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && this.formCompleted) {
      this.handleUpdate();
    }
  };

  render() {
    const {
      value,
      label,
      setValue,
      spanValue,
      inputComponent,
      questions,
      setCurrentQuestionTo,
      formCompleted,
    } = this.props;
    return (
      <Form
        formCompleted={formCompleted}
        onSubmit={this.handleUpdate}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <Question {...questions.value} className='fullWidthInput' label={label}>
          {spanValue === undefined ? (
            <CurrencyInput
              id='id'
              maxLength={9}
              action={setValue}
              value={value}
              onKeyDown={this.handleOnKeyDown}
            />
          ) : (
            <InputWithSpan
              id='id'
              inputComponent={inputComponent}
              spanValue={spanValue}
              type='number'
              maxLength={9}
              action={setValue}
              value={value}
              onKeyDown={this.handleOnKeyDown}
            />
          )}
        </Question>
        <Button
          disabled={!formCompleted}
          id='PopupButton'
          className='brandColor__button margin-0'
          onClick={this.handleUpdate}
        >
          Update
        </Button>
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

export default manageQuestions(SingleInputForm);
