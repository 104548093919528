import { defineMessages } from 'react-intl';

import {
  FIXED_RATE_TERM_ALL_VALUE,
  VARIABLE_RATE,
  FIXED_RATE,
} from 'shared/constants/interestType';

const INTEREST_TYPES = [
  {
    key: VARIABLE_RATE,
    ...defineMessages({
      label: {
        id: 'interestTypes.variableRate.label',
        defaultMessage: 'Variable Rate',
      },
      tooltip: {
        id: 'interestTypes.variable.tooltip',
        defaultMessage:
          'Variable home loan rates give you flexibility and extra features to save you money. Your variable interest rate will move up and down over time, loosely in line with the official interest rates set by the Reserve Bank of Australia (RBA).',
      },
    }),
  },
  {
    key: FIXED_RATE,
    ...defineMessages({
      label: {
        id: 'interestTypes.fixedRate.label',
        defaultMessage: 'Fixed Rate',
      },
      tooltip: {
        id: 'interestTypes.fixed.tooltip',
        defaultMessage:
          'You can lock in your home loan rate so you have a set repayment each month for a certain amount of time. A fixed interest rate is good for a borrower who wants certainty about their repayments and likes being able to budget around a set amount.',
      },
    }),
  },
];

const interestTypeMessages = defineMessages({
  interestTypeFixedAll: {
    id: 'interestTypes.fixAll.label',
    defaultMessage: 'All',
  },
  interestTypeFixed6Year: {
    id: 'interestTypes.fix6Year.label',
    defaultMessage: 'Fix 6+ Years',
  },
  interestTypeFixedOtherYear: {
    id: 'interestTypes.Year.text',
    defaultMessage: 'Fix {key} {key, plural, =1 {Year} other {Years}}',
  },
});

const fixedRateYears = (formatMessage, length) =>
  Array.from({ length }, (v, k) => ({
    key: `interestTypeTerm_${k + 1}_years`,
    value: `${k + 1}`,
    name: formatMessage(interestTypeMessages.interestTypeFixedOtherYear, {
      key: k + 1,
    }),
  }));

export const formatInterestTypes = (formatMessage) =>
  INTEREST_TYPES.map((i) => ({
    ...i,
    label: formatMessage(i.label),
    tooltip: i.tooltip ? formatMessage(i.tooltip) : null,
  }));

export const formatInitialPeriod = (formatMessage) => [
  {
    key: 'interestTypeTerm_all_years',
    value: FIXED_RATE_TERM_ALL_VALUE,
    name: formatMessage(interestTypeMessages.interestTypeFixedAll),
  },
  ...fixedRateYears(formatMessage, 5),
  {
    key: 'interestTypeTerm_6_years',
    value: '6',
    name: formatMessage(interestTypeMessages.interestTypeFixed6Year),
  },
];
