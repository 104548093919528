import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import contactActions from 'actions/contactActions';
import clientActions from 'actions/clientActions';
import UIActions from 'actions/UIActions';

import * as contactSelectors from 'selectors/contactSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as advisorSelectors from 'selectors/advisorSelectors';

import { PERSON_CONTACT_TYPES } from 'constants/options';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import ContactForm from 'components/PopupForms/ContactForm';
import { EMAIL_TAKEN_BY_ADVISER } from 'constants/messages/authError';
import { mergeProps } from 'lib/popupHelper';

class CoapplicantPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    this.setNoScroll(props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps);
    this.setNoScroll(nextProps);
  }

  setNoScroll(nextProps) {
    const working = (nextProps || this.props).working;

    this.noScroll = working && PERSON_CONTACT_TYPES.includes(working.type);
  }

  onLoadNew() {
    const { setIsCoapplicant } = this.props;
    setIsCoapplicant(true);
  }

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} Co-Applicant`;
    }
    return working.id ? 'Edit Co-Applicant' : 'Add a Co-Applicant';
  }

  value = () => undefined;

  hideErrorOnForm = (processing) => {
    const { errors } = this.props;
    if (!processing && errors.length > 0) {
      return errors[0].error.message !== EMAIL_TAKEN_BY_ADVISER;
    }
    return true;
  };

  renderForm() {
    const {
      isLocked,
      working,
      workingCurrentAddress,
      workingMailAddress,
      isSameAsHomeAddress,
      hasBluestoneOnlineApplicationFlag,
      parentPath,
    } = this.props;

    return (
      <ContactForm
        isLocked={isLocked}
        key={`contactPopupForm-${this.state.refreshKey}`}
        popup
        working={working}
        workingCurrentAddress={workingCurrentAddress}
        workingMailAddress={workingMailAddress}
        isSameAsHomeAddress={isSameAsHomeAddress}
        save={this.save}
        remove={this.remove}
        hasBluestoneOnlineApplicationFlag={hasBluestoneOnlineApplicationFlag}
        parentPath={parentPath}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  const working = contactSelectors.working(state)(id);
  let clientId;
  if (id === 'new') {
    clientId = 'new';
  } else {
    clientId = working ? contactSelectors.contactClientId(working) : null;
  }
  return {
    id,
    application: applicationSelectors.workingApplication(state),
    item: contactSelectors.contact(state)(id),
    working,
    workingCurrentAddress: addressSelectors.workingClientCurrentAddress(state)(
      clientId,
    ),
    workingMailAddress: addressSelectors.workingClientMailAddress(state)(
      clientId,
    ),
    isSameAsHomeAddress: addressSelectors.workingClientIsMailAddressSame(state)(
      clientId,
    ),
    hasBluestoneOnlineApplicationFlag: advisorSelectors.hasBluestoneOnlineApplicationFlag(
      state,
    ),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
    parentPath: ownProps.parentPath,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = (ownProps.working && ownProps.working.id) || 'new';
  return bindActionCreators(
    {
      load: contactActions.loadContact,
      create: contactActions.createContact,
      update: contactActions.updateContact,
      delete: contactActions.deleteContact,
      clearWorking: contactActions.clearWorkingContact,
      clearErrors: UIActions.clearAsyncRequestErrors,
      setIsCoapplicant: contactActions.setContactIsCoapplicant(id),
      updateClientLogin: clientActions.updateClientLogin,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CoapplicantPopup),
);
