import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from '@loan_market/react-router-redux-multi';
import { bindActionCreators } from 'redux';

import ImageMessage from 'components/AnimationSequences/ImageMessage';
import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import * as applicationSelectors from 'selectors/applicationSelectors';
import { isFork } from 'selectors/scenarioSelectors';
import {
  FINAL_SEQUENCE,
  fullHandholdInbetweener,
  PARTIAL_HANDHOLD_INBETWEENER,
} from 'constants/applyData';
import _ from 'lodash';

export class ApplyHandholdComponent extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    currentHandholdSection: PropTypes.string.isRequired,
    goTo: PropTypes.func.isRequired,
    updateLoanApplication: PropTypes.func.isRequired,
    next: PropTypes.func,
    startAnimationSequence: PropTypes.func.isRequired,
    stopAnimationSequence: PropTypes.func.isRequired,
    setHandholdSections: PropTypes.func,
    match: PropTypes.object.isRequired,
    isFullHandhold: PropTypes.bool,
    isFork: PropTypes.bool,
    handholdSections: PropTypes.arrayOf(PropTypes.string),
    isCustomerCare: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.FULL_HANDHOLD_INBETWEENER = fullHandholdInbetweener(
      props.isFork,
      props.isCustomerCare,
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isCustomerCare !== this.props.isCustomerCare) {
      this.FULL_HANDHOLD_INBETWEENER = fullHandholdInbetweener(
        this.props.isFork,
        this.props.isCustomerCare,
      );
    }
  }

  shiftedNewHandholdSections = () => {
    const { handholdSections } = this.props;
    const newHandholdSections = [...handholdSections];
    newHandholdSections.shift();
    return newHandholdSections;
  };

  getHandholdSequence = (section) => {
    const nextHandholdSequence = [];
    if (section) {
      nextHandholdSequence.push('/apply-handhold');
      const priorPath = this.handholdData(section).exclamationPath;
      if (priorPath) {
        nextHandholdSequence.unshift(priorPath);
      }
    } else {
      nextHandholdSequence.push(FINAL_SEQUENCE);
    }

    return _.flatten(nextHandholdSequence);
  };

  primaryButtonAction = () => {
    const {
      application,
      goTo,
      stopAnimationSequence,
      setHandholdSections,
      currentHandholdSection,
    } = this.props;

    goTo(`/apply/${application.id}/${currentHandholdSection}`);
    stopAnimationSequence();

    const newSections = this.shiftedNewHandholdSections();
    const newSequence = this.getHandholdSequence(newSections[0]);
    setHandholdSections({
      handholdSections: newSections,
      nextHandholdSequence: newSequence,
    });
  };

  handholdData = (section) =>
    (this.props.isFullHandhold
      ? this.FULL_HANDHOLD_INBETWEENER[section]
      : PARTIAL_HANDHOLD_INBETWEENER[section]) || {};

  secondaryButtonAction = () => {
    const {
      application,
      updateLoanApplication,
      startAnimationSequence,
      setHandholdSections,
      currentHandholdSection,
    } = this.props;

    const newSections = this.shiftedNewHandholdSections();
    const newSequence = this.getHandholdSequence(newSections[0]);
    startAnimationSequence(newSequence);
    setHandholdSections({
      handholdSections: newSections,
      nextHandholdSequence: newSequence,
    });

    const { secondaryActionMetadata } = this.handholdData(
      currentHandholdSection,
    );
    if (secondaryActionMetadata) {
      const updatedMetadata = {
        ...application.metadata,
        ...secondaryActionMetadata,
      };
      updateLoanApplication({
        ...application,
        metadata: updatedMetadata,
      });
    }
  };

  render() {
    const { currentHandholdSection } = this.props;
    return (
      <ImageMessage
        {...this.handholdData(currentHandholdSection)}
        id={currentHandholdSection}
        primaryButtonAction={this.primaryButtonAction}
        secondaryButtonIcon='mi-arrow-with-circle-right'
        secondaryButtonAction={this.secondaryButtonAction}
        imageAnimationType='delayed'
        imagePathTiming='easeIn'
        imageAnimTiming='easeOut'
      />
    );
  }
}

const mapStateToProps = (state) => ({
  application: applicationSelectors.workingApplication(state),
  handholdSections: state.UISettings.handholdSections,
  currentHandholdSection: state.UISettings.handholdSections[0],
  isFullHandhold: state.UISettings.isFullHandhold,
  isFork: isFork(state),
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNextPath: UIActions.setNextPath,
      startAnimationSequence: UIActions.startAnimationSequence,
      stopAnimationSequence: UIActions.stopAnimationSequence,
      setHandholdSections: UIActions.setHandholdSections,
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
      goTo: push,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApplyHandholdComponent);
