export const ADD_PROPERTY = 'addProperty';
export const CONVERT_SCENARIO = 'convertScenario';
export const TRACK_CURRENT_USER = 'trackCurrentUser';
export const TRACK_EMAIL_CAPTURE = 'trackEmailCapture';
export const TRACK_PASSWORD_CAPTURE = 'trackPasswordCapture';
export const TRACK_MOBILE_CAPTURE = 'trackMobileCapture';
export const TRACK_SUBMITTED_FOR_REVIEW = 'trackSubmittedForReview';
export const TRACK_BANK_DATA_DOWNLOAD = 'trackBankDataDownload';
export const TRACK_TOKEN_EXPIRED = 'trackTokenExpired';
export const TRACK_OKTA_SESSION_EXPIRED = 'trackOktaSessionExpired';
export const MERGE_SCENARIO_DATA = 'mergeScenarioData';
export const REQUEST_PURCHASE_COST = 'requestPurchaseCost';
export const REQUEST_CASH_FLOW_DATA = 'requestCashFlowData';
export const REQUEST_LOWEST_RATE = 'requestLowestRate';
export const REQUEST_USER_INFO = 'requestUserInfo';
export const REQUEST_SIGN_UP = 'requestSignUp';
export const REQUEST_LOGIN = 'requestLogin';
export const SET_CONSUMER_UUID = 'setConsumerUuid';
export const SET_USER_ID = 'setUserId';
export const SET_OKTA_DATA = 'setOktaData';
export const SET_FACTOR_ID = 'setFactorId';
export const SET_RETURNING_USER = 'setReturningUser';
export const REQUEST_ACTIVATE_FACT_FIND = 'requestActivateFactFind';
export const REQUEST_ACTIVATE_ORGANIC_SIGNUP = 'requestActivateOrganicSignup';
export const REQUEST_CONFIRM_EMAIL = 'requestConfirmEmail';

export const REQUEST_EMAIL_VERIFICATION = 'requestEmailVerification';
export const REQUEST_VERIFY_RECOVERY_TOKEN = 'requestVerifyRecoveryToken';
export const REQUEST_RESET_PASSWORD_LINK = 'requestResetPasswordLink';
export const SET_UTM = 'setUtm';
export const SET_LOAN_PURPOSE = 'setLoanPurpose';
export const SET_SCENARIO_NAME = 'setScenarioName';
export const SAVE_FORK_CHOICE = 'saveForkChoice';
export const SET_FORK_CHOICE = 'setForkChoice';
export const SET_QUESTION = 'setQuestion';
export const SET_LOAN_TYPE = 'setLoanType';
export const SET_LOAN_FEATURES = 'setLoanFeatures';
export const SET_REPAYMENT_TYPE = 'setRepaymentType';
export const SET_IGNORE_LOAN_FEATURES = 'setIgnoreLoanFeatures';
export const SET_FOUND_PROPERTY = 'setFoundProperty';
export const SET_DEPOSIT_SAVED = 'setDepositSaved';
export const SET_LOAN_AMOUNT_MANUAL = 'setLoanAmountManual';
export const SET_LOAN_YEAR = 'setLoanYear';
export const SET_DISPLAY_NAME = 'setDisplayName';
export const SET_LAST_NAME = 'setLastName';
export const SET_POSTCODE_COUNTRY = 'setPostcodeCountry';
export const FETCH_POSTCODE_COUNTRY = 'fetchPostcodeCountry';
export const SET_POSTCODE_MANUAL_ENTRY = 'setPostcodeManualEntry';
export const SET_EMAIL = 'setEmail';
export const SET_MOBILE = 'setMobile';
export const SET_REFINANCE_REASONS = 'setRefinanceReasons';
export const SET_LOGIN_TOKEN = 'setLoginToken';
export const SET_MOBILE_VALIDATED = 'setMobileValidated';
export const SET_RAY_WHITE_DATA = 'setRayWhiteData';
export const SET_ERRORS = 'setErrors';
export const SET_ERROR = 'setError';
export const SET_ALLOCATED_BROKER_FAMILY_ID = 'setAllocatedBrokerFamilyId';
export const SET_SHARED_TOKEN = 'setSharedToken';
export const SET_SHARED_CLIENT_ID = 'setSharedClientId';
export const SET_SHARED_LOAN_APP_ID = 'setSharedLoanAppId';
export const SET_RECOVERY_TOKEN = 'setRecoveryToken';
export const SET_LOGIN_EMAIL = 'setLoginEmail';
export const SET_RESET_PASSWORD_EMAIL = 'setResetPasswordEmail';
export const SET_RESET_PASSWORD_CODE = 'setResetPasswordCode';
export const RESUME_SCENARIO = 'resumeScenario';

export const REQUEST_CALCULATOR_INFO = 'requestCalculatorInfo';

export const HANDLE_INVALID_SHARED_TOKEN = 'handleInvalidSharedToken';
