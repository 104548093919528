import { bindIndividualActions } from 'lib/actionHelpers';
import {
  connectToMirroredProperty,
  mergeActionsWithParamsId,
} from 'lib/reducerHelper';

import scenarioActions from 'actions/scenarioActions';
import { paramsId } from 'selectors/sharedSelectors';
import {
  propertyWeeklyRentalIncome,
  propertyManagementFeePercentage,
  propertyVacancyFeePercentage,
  propertyAnnualRepairFee,
  mortgageAmount,
  propertyInterestRate,
} from 'selectors/scenarioSelectors';

import SingleInputForm from 'components/PopupForms/SingleInputForm';
import InterestOnlyRepaymentForm from 'components/PopupForms/InterestOnlyRepaymentForm';

export const PropertyRentalIncomeForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: propertyWeeklyRentalIncome(state, ownProps),
    inputComponent: 'CurrencyInput',
    spanValue: 'per week',
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyWeeklyRentalAmount,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const ManagementFeeForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: propertyManagementFeePercentage(state, ownProps),
    spanValue: '%',
    label: 'Percentage of Rent Charged',
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyManagementFeePercentage,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const VacancyFeeForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: propertyVacancyFeePercentage(state, ownProps),
    spanValue: '%',
    label: 'Vacancy rate (Percentage of time with no tenants)',
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyVacancyFeePercentage,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const RepairFeeForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: propertyAnnualRepairFee(state, ownProps),
    spanValue: 'per year',
    inputComponent: 'CurrencyInput',
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyRepairFee,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const MirroredInterestOnlyRepaymentForm = connectToMirroredProperty(
  (state, ownProps) => ({
    id: paramsId(state, ownProps),
    value: mortgageAmount(state, ownProps),
    percent: propertyInterestRate(state, ownProps),
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyMortgageAmount,
        setPercent: scenarioActions.setPropertyInvestmentLowestRateManual,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(InterestOnlyRepaymentForm);
