import { createActions } from 'redux-actions';

export const REQUEST_LOAN_APP_INFO_QUESTIONS = 'getLoanAppInfoQuestions';
export const REQUEST_LOAN_APP_INFO_ANSWERS = 'getLoanAppInfoAnswers';
export const SET_LOAN_APP_INFO_QUESTIONS = 'setLoanAppInfoQuestions';
export const SET_LOAN_APP_INFO_ANSWERS = 'setLoanAppInfoAnswers';
export const SAVE_LOAN_APP_INFO_ANSWERS = 'saveLoanAppInfoAnswers';

const actionTypes = {
  REQUEST_LOAN_APP_INFO_QUESTIONS,
  REQUEST_LOAN_APP_INFO_ANSWERS,
  SET_LOAN_APP_INFO_QUESTIONS,
  SET_LOAN_APP_INFO_ANSWERS,
  SAVE_LOAN_APP_INFO_ANSWERS,
};

export default createActions({}, ...Object.values(actionTypes));
