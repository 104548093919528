import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import _ from 'lodash';

import { paramsId, paramCacheResolver } from 'selectors/sharedSelectors';
import { hasBankConnectSubscription } from 'selectors/advisorSelectors';
import { workingApplication } from 'selectors/applicationSelectors';
import {
  documentTypesPerSubCategoryKey,
  documentSubCategoryLookup,
  documentTypeLookup,
} from 'shared/constants/myCRMTypes/documents';
import { SUB_CATEGORY_OTHER_DOCUMENT } from 'shared/constants/myCRMTypes/documentsAU';
import {
  fileInviteCategories,
  FILE_INVITE_STATUSES,
  DOCUMENT_ERROR_TYPES,
} from 'constants/documents';

import locale from 'config/locale';

const workingDocuments = (state) => state.document.working;
export const entities = (state) => state.document.entities;
export const documentTypes = (state) => state.document.documentTypes;
export const supportedEntities = createSelector(
  (state) => state.document.entities,
  (a) => {
    return a.filter((doc) => {
      const docType = documentTypeLookup(locale)[doc.type];
      const subCategory =
        docType &&
        documentSubCategoryLookup(locale.countryCode)[docType.subCategory];
      return subCategory && subCategory.category === doc.category;
    });
  },
);

export const entity = createCachedSelector(
  entities,
  paramsId,
  (entityList, id) => entityList.find((ent) => ent.id === id),
)(paramCacheResolver);

export const working = (state) => (id) => workingDocuments(state)[id];

const documentsGroupedByCategory = createSelector(supportedEntities, (a) =>
  _.groupBy(a, 'category'),
);

export const documentsGroupedBySubCategory = createSelector(
  supportedEntities,
  (a) =>
    _.reduce(
      documentTypesPerSubCategoryKey(locale.countryCode),
      (current, typeOptions, subCategory) => {
        const types = _.map(typeOptions, 'id');
        const match = a.filter(
          (e) =>
            types.includes(parseInt(e.type, 10)) ||
            (subCategory === SUB_CATEGORY_OTHER_DOCUMENT.key && !e.type),
        );

        return { ...current, [subCategory]: match };
      },
      {},
    ),
);

export const fileInvite = createSelector(
  (state) => state.document.fileInvite,
  workingApplication,
  (fileInvite, application) => ({
    ...fileInvite,
    enabled: application.isFileInvite !== false,
  }),
);

export const isFileInviteComplete = (state) =>
  state.document.fileInvite.status === FILE_INVITE_STATUSES.RETURNED;

export const getHasMainCategories = createSelector(
  fileInvite,
  documentsGroupedByCategory,
  hasBankConnectSubscription,
  (fileInviteDetails, docsByCat, isBankConnect) => {
    const docCategories = Object.keys(docsByCat);
    if (fileInviteDetails.inviteUrl) {
      const REQUIRED_CATEGORIES = fileInviteCategories(isBankConnect)
        .filter((c) => c.required)
        .map((c) => c.key);
      const common = _.intersection(REQUIRED_CATEGORIES, docCategories);
      return common.length === REQUIRED_CATEGORIES.length;
    }
    return docCategories.length > 0;
  },
);

export const getDocumentCount = createSelector(
  documentsGroupedBySubCategory,
  (docsBySubCat) =>
    Object.keys(docsBySubCat).reduce(
      (count, key) => count + (docsBySubCat[key].length || 0),
      0,
    ),
);

export const actionableError = createSelector(workingDocuments, (working) =>
  _.memoize((id) => {
    const errors = working[id] && working[id].errors;
    const error = errors && errors[id];
    const actionableErrors = [DOCUMENT_ERROR_TYPES.INVALID_FILE];
    return error && actionableErrors.includes(error.type) ? error : null;
  }),
);
