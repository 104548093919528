// Frequency
export const YEARLY = 1;
export const HALF_YEARLY = 2;
export const QUARTERLY = 3;
export const MONTHLY = 4;
export const FORTNIGHTLY = 5;
export const WEEKLY = 6;

// Enquiry
export const LM_ONLINE_LOAN_APP_SOURCE_ID = 1;
export const WEBSITE_LOAN_MARKET = -3;
export const REFERRER = -1;
export const AFFILIATE = -2;
export const SELF_GENERATED = -4;

export const LM_ONLINE_MEMBER = -9;
export const LM_BROKER_WEBISTE_MEMBER = -29;
export const LM_ONLINE_MEMBER_BYOB = -38;
export const WEBSITE_LOAN_MARKET_BYOB = -39;
export const RAYWHITE_CALCULATOR = -42;

// Subscription
export const BROKER_WIDGET = 2;

// Doc uploading
export const MAX_FILE_SIZE = 100 * 1024 * 1024;
