import LENDER_DEFAULT from 'assets/lender-default.png';

const LOGO_HOST = 'https://logos.applynow.finance';

export const lenderLogoUrl = (lenderCode, countryCode) => {
  return `${LOGO_HOST}/lenders/logos/${countryCode.toLowerCase()}/${lenderCode.toLowerCase()}.svg`;
};

export const lenderReversedLogoUrl = (lenderId, countryCode, mogoId) => {
  return `${LOGO_HOST}/lenders/reversed-logos/${countryCode.toLowerCase()}/${
    lenderId || `mogo/${mogoId}`
  }.svg`;
};

export const lenderBannerUrl = (lenderId, countryCode) => {
  return `${LOGO_HOST}/lenders/banners/${countryCode.toLowerCase()}/lender/${lenderId}.jpg`;
};

export const lenderPdpBannerUrl = (lenderId, countryCode) => {
  return `${LOGO_HOST}/lenders/banners/${countryCode.toLowerCase()}/pdp/${lenderId}.jpg`;
};

export const onError = (e) => {
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  e.target.onerror = null;
  e.target.src = LENDER_DEFAULT;
};

export const getLenderStyle = (lender, showLogos) => {
  return Object.keys(showLogos).length
    ? { display: showLogos[lender] ? undefined : 'none' }
    : { visibility: showLogos[lender] ? undefined : 'hidden' };
};
