import * as actionTypes from 'actions/scenarioActionTypes';
import * as propertyActionTypes from 'actions/propertyActionTypes';
import update from 'immutability-helper';
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { unformatCurrency } from 'shared/lib/numbrero';
import { percentageUnformatter } from 'lib/utils/numberUtils';
import { postcodeExtractor } from 'shared/lib/utils';

import {
  DEFAULT_EXISTING_PROPERTY_ID,
  INITIAL_SCENARIO_STATE,
  DEFAULT_NEW_PROPERTY,
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_RESIDENTIAL_SCENARIO_STATE,
  DEFAULT_BUYER_SCENARIO_FHB_STATE,
  DEFAULT_BUYER_SCENARIO_MOVE_STATE,
  DEFAULT_BUYER_SCENARIO_INVEST_STATE,
  DEFAULT_BUYER_SCENARIO_COMPARE_STATE,
  DEFAULT_INVESTMENT_SCENARIO_STATE,
  DEFAULT_REFINANCE_SCENARIO_STATE,
} from 'shared/constants/defaults';
import {
  LOAN_PURPOSE_RESIDENTIAL,
  LOAN_PURPOSE_INVESTMENT,
  LOAN_PURPOSE_REFINANCE,
} from 'shared/constants/loanPurposes';
import {
  BUYER_SCENARIO_FHB,
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_INVEST,
  BUYER_SCENARIO_COMPARE,
} from 'shared/constants/loanScenarios';
import { NO_ERROR } from 'constants/validators';
import { WEEKLY } from 'shared/constants/options';
import {
  getKeepPostcode,
  getExistingPropertyPurpose,
} from 'selectors/scenarioSelectors';

const setPropertyIdValue = (state, payload, key, formatter = _.identity) =>
  update(state, {
    properties: {
      [payload.id]: {
        [key]: {
          $set: formatter(payload.value),
        },
      },
    },
  });

const actionHandlers = {
  [actionTypes.TRACK_EMAIL_CAPTURE](state) {
    return { ...state, emailCaptured: true };
  },
  [actionTypes.TRACK_PASSWORD_CAPTURE](state) {
    return { ...state, passwordCaptured: true };
  },
  [actionTypes.TRACK_MOBILE_CAPTURE](state) {
    return { ...state, mobileCaptured: true };
  },
  [actionTypes.SET_LOAN_PURPOSE](state, action) {
    const loanPurpose = action.payload;
    switch (loanPurpose) {
      case LOAN_PURPOSE_RESIDENTIAL:
        return update(state, { $merge: DEFAULT_RESIDENTIAL_SCENARIO_STATE });
      case LOAN_PURPOSE_INVESTMENT:
        return update(state, { $merge: DEFAULT_INVESTMENT_SCENARIO_STATE });
      case LOAN_PURPOSE_REFINANCE:
        return update(state, { $merge: DEFAULT_REFINANCE_SCENARIO_STATE });
      default:
        return state;
    }
  },
  [actionTypes.SET_UTM](state, action) {
    return update(state, { utm: { $set: action.payload } });
  },
  [actionTypes.SET_SCENARIO_NAME](state, action) {
    const name = action.payload;
    switch (name) {
      case BUYER_SCENARIO_FHB:
        return update(state, {
          $merge: DEFAULT_BUYER_SCENARIO_FHB_STATE,
          properties: { [DEFAULT_EXISTING_PROPERTY_ID]: { $set: undefined } },
        });
      case BUYER_SCENARIO_MOVE:
        return update(state, { $merge: DEFAULT_BUYER_SCENARIO_MOVE_STATE });
      case BUYER_SCENARIO_INVEST:
        return update(state, { $merge: DEFAULT_BUYER_SCENARIO_INVEST_STATE });
      case BUYER_SCENARIO_COMPARE:
        return update(state, {
          $merge: DEFAULT_BUYER_SCENARIO_COMPARE_STATE,
          properties: { [DEFAULT_EXISTING_PROPERTY_ID]: { $set: undefined } },
        });
      default:
        return state;
    }
  },
  [actionTypes.SET_ALLOCATED_BROKER_FAMILY_ID](state, action) {
    return update(state, { allocatedBrokerFamilyId: { $set: action.payload } });
  },
  [actionTypes.SET_LOGIN_EMAIL](state, action) {
    return update(state, { loginEmail: { $set: action.payload } });
  },
  [actionTypes.SET_SHARED_TOKEN](state, action) {
    return update(state, { sharedToken: { $set: action.payload } });
  },
  [actionTypes.SET_SHARED_CLIENT_ID](state, action) {
    return update(state, { sharedClientId: { $set: action.payload } });
  },
  [actionTypes.SET_SHARED_LOAN_APP_ID](state, action) {
    return update(state, { sharedLoanAppId: { $set: action.payload } });
  },
  [actionTypes.SET_RECOVERY_TOKEN](state, action) {
    return update(state, { recoveryToken: { $set: action.payload } });
  },
  [actionTypes.SET_LOAN_TYPE](state, action) {
    return update(state, { loanType: { $set: action.payload } });
  },
  [actionTypes.SET_LOAN_FEATURES](state, action) {
    return update(state, { loanFeatures: { $set: action.payload } });
  },
  [actionTypes.SET_REPAYMENT_TYPE](state, action) {
    return update(state, { repaymentType: { $set: action.payload } });
  },
  [actionTypes.SET_CONSUMER_UUID](state, action) {
    return update(state, { consumerUuid: { $set: action.payload } });
  },
  [actionTypes.SET_USER_ID](state, action) {
    return update(state, { userId: { $set: action.payload } });
  },
  [actionTypes.SET_OKTA_DATA](state, action) {
    return update(state, { oktaData: { $set: action.payload } });
  },
  [actionTypes.SET_FACTOR_ID](state, action) {
    return update(state, { factorId: { $set: action.payload } });
  },
  [actionTypes.SET_RETURNING_USER](state, action) {
    return update(state, { returningUser: { $set: action.payload } });
  },
  [actionTypes.SET_IGNORE_LOAN_FEATURES](state, action) {
    return update(state, { ignoreLoanFeatures: { $set: action.payload } });
  },
  [actionTypes.SET_FOUND_PROPERTY](state, action) {
    const foundProperty = action.payload;
    const updates = {
      foundProperty: { $set: foundProperty },
      properties: {
        [PROSPECTIVE_PROPERTY_ID]: {},
      },
    };

    if (foundProperty) {
      updates.properties[PROSPECTIVE_PROPERTY_ID].locality = { $set: null };
    } else {
      updates.properties[PROSPECTIVE_PROPERTY_ID].state = { $set: null };
      if (!state.isPostcodeManualEntry) {
        const existingProperty = state.properties[DEFAULT_EXISTING_PROPERTY_ID];
        if (existingProperty && existingProperty.locality) {
          const postcode = postcodeExtractor(existingProperty.locality);
          updates.postcode = { $set: postcode };
        } else {
          updates.postcode = { $set: null };
        }
      }
    }
    return update(state, updates);
  },
  [actionTypes.SET_DEPOSIT_SAVED](state, action) {
    if (action.payload) {
      return update(state, { depositSaved: { $set: true } });
    }
    return update(state, {
      depositSaved: { $set: false },
      properties: {
        [PROSPECTIVE_PROPERTY_ID]: {
          depositAmount: { $set: 0 },
        },
      },
    });
  },
  [actionTypes.SET_LOAN_YEAR](state, action) {
    return update(state, { loanYear: { $set: action.payload } });
  },
  [actionTypes.SET_LOAN_AMOUNT_MANUAL](state, action) {
    return update(state, {
      loanAmountManual: { $set: unformatCurrency(action.payload) },
    });
  },
  [actionTypes.SET_DISPLAY_NAME](state, action) {
    return update(state, { displayName: { $set: action.payload } });
  },
  [actionTypes.SET_LAST_NAME](state, action) {
    return update(state, { lastName: { $set: action.payload } });
  },
  [actionTypes.SET_FORK_CHOICE](state, action) {
    return update(state, { forkChoice: { $set: action.payload } });
  },
  [actionTypes.SET_QUESTION](state, action) {
    return update(state, { question: { $set: action.payload } });
  },
  [actionTypes.SET_POSTCODE_COUNTRY](state, action) {
    return update(state, { postcodeCountry: { $set: action.payload } });
  },
  [actionTypes.SET_POSTCODE_MANUAL_ENTRY](state, action) {
    const { text, postcode } = action.payload;

    return update(state, {
      locality: { $set: text },
      postcode: { $set: postcode ? postcode.toString() : text },
      isPostcodeManualEntry: { $set: true },
    });
  },
  [actionTypes.SET_EMAIL](state, action) {
    return update(state, { email: { $set: action.payload } });
  },
  [actionTypes.SET_MOBILE](state, action) {
    return update(state, {
      mobile: { $set: action.payload },
      mobileValidated: { $set: false },
    });
  },
  [actionTypes.SET_LOGIN_TOKEN](state, action) {
    return update(state, {
      loginToken: { $set: action.payload },
      errors: { $set: INITIAL_SCENARIO_STATE.errors },
    });
  },
  [actionTypes.SET_MOBILE_VALIDATED](state, action) {
    return update(state, { mobileValidated: { $set: action.payload } });
  },
  [actionTypes.SET_RESET_PASSWORD_EMAIL](state, action) {
    return update(state, { resetPasswordEmail: { $set: action.payload } });
  },
  [actionTypes.SET_RESET_PASSWORD_CODE](state, action) {
    return update(state, { resetPasswordCode: { $set: action.payload } });
  },
  [actionTypes.SET_REFINANCE_REASONS](state, action) {
    const currentReasons = state.refinanceReasons;
    const newReasons = action.payload;
    const changes = {
      refinanceReasons: { $set: newReasons },
      properties: {
        [DEFAULT_EXISTING_PROPERTY_ID]: [
          'renovationCost',
          'consolidateDebt',
          'additionalFunds',
        ].reduce((p, c) => {
          if (currentReasons[c] && !newReasons[c]) {
            p[c] = { $set: 0 };
          }
          return p;
        }, {}),
      },
    };
    return update(state, changes);
  },
  [actionTypes.MERGE_SCENARIO_DATA](state, action) {
    if (action.error) {
      return state;
    }
    return update(state, { $merge: action.payload });
  },
  [actionTypes.SET_ERRORS](state, action) {
    return update(state, { errors: { $set: action.payload } });
  },
  [actionTypes.CONVERT_SCENARIO](state) {
    return update(state, { converted: { $set: true } });
  },
  [actionTypes.SET_ERROR](state, action) {
    const { id, text, blocking, showAsBanner } = action.payload;
    return update(state, {
      errors: {
        [id]: {
          $set:
            text === NO_ERROR ? undefined : { text, blocking, showAsBanner },
        },
      },
    });
  },
  [actionTypes.ADD_PROPERTY](state, action) {
    const id = action.payload || DEFAULT_EXISTING_PROPERTY_ID;
    return update(state, {
      properties: {
        [id]: { $set: update(DEFAULT_NEW_PROPERTY, { id: { $set: id } }) },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_LOCALITY](state, action) {
    const { value } = action.payload;
    const text = value && value.text !== undefined ? value.text : value;

    const keepPostcode = getKeepPostcode({ scenario: state })(
      getExistingPropertyPurpose({ scenario: state }),
    );

    return update(state, {
      postcode: { $set: keepPostcode ? value.postcode : undefined },
      isPostcodeManualEntry: { $set: false },
      properties: {
        [action.payload.id]: {
          locality: { $set: text },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_RENTAL_AMOUNT](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: {
          rentalAmount: {
            $set: unformatCurrency(action.payload.value),
          },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_RENTAL_FREQUENCY](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: {
          rentalFrequency: {
            $set: unformatCurrency(action.payload.value),
          },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_STATE](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: { state: { $set: action.payload.value } },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_VALUE](state, action) {
    return setPropertyIdValue(state, action.payload, 'value', unformatCurrency);
  },
  [propertyActionTypes.SET_PROPERTY_TYPE](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: { type: { $set: action.payload.value } },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_DEPOSIT_AMOUNT](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'depositAmount',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_ERROR](state, action) {
    const {
      id,
      value: { id: errorId, text, blocking },
    } = action.payload;
    return update(state, {
      properties: {
        [id]: {
          errors: {
            [errorId]: {
              $set: text === NO_ERROR ? undefined : { text, blocking },
            },
          },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_ERRORS](state, action) {
    const { id, value: errors } = action.payload;
    return update(state, {
      properties: { [id]: { errors: { $set: errors } } },
    });
  },
  [propertyActionTypes.SET_PROPERTY_INTENDS_TO_SELL](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: {
          intendsToSell: { $set: action.payload.value },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_OWNER_OCCUPIED](state, action) {
    const keepPostcode = getKeepPostcode({ scenario: state })(
      action.payload.value,
    );

    const existingProperty = state.properties[DEFAULT_EXISTING_PROPERTY_ID];
    const postcode =
      keepPostcode && existingProperty && existingProperty.locality
        ? postcodeExtractor(existingProperty.locality)
        : undefined;

    return update(state, {
      postcode: { $set: postcode },
      properties: {
        [action.payload.id]: {
          ownerOccupied: { $set: action.payload.value },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_MORTGAGE_AMOUNT](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: {
          mortgageAmount: { $set: unformatCurrency(action.payload.value) },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_RENTAL_AMOUNT](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: {
          rentalAmount: { $set: unformatCurrency(action.payload.value) },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_WEEKLY_RENTAL_AMOUNT](state, action) {
    return update(state, {
      properties: {
        [action.payload.id]: {
          rentalAmount: { $set: unformatCurrency(action.payload.value) },
          rentalFrequency: { $set: WEEKLY },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_RENTAL_FREQUENCY](state, action) {
    return setPropertyIdValue(state, action.payload, 'rentalFrequency');
  },
  [propertyActionTypes.MERGE_PROPERTY_REPORT_DATA](state, action) {
    if (!action.payload.value) {
      return state;
    }
    return update(state, {
      properties: {
        [action.payload.id]: { $merge: action.payload.value },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_OTHER_COST](state, action) {
    const {
      id,
      value: { value, name, index },
    } = action.payload;
    const otherCosts = state.properties[id].otherCosts;
    return update(state, {
      properties: {
        [id]: {
          otherCosts:
            index < otherCosts.length
              ? { [index]: { $merge: { value, name } } }
              : { $push: [{ value, name }] },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_FHOG_ELIGIBILITY](state, action) {
    return setPropertyIdValue(state, action.payload, 'FHOGEligibility');
  },
  [propertyActionTypes.SET_PROPERTY_ONGOING_FEE](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'ongoingFee',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_ONGOING_FEE_FREQUENCY](state, action) {
    return setPropertyIdValue(state, action.payload, 'ongoingFeeFrequency');
  },
  [propertyActionTypes.SET_PROPERTY_MANAGEMENT_FEE_PERCENTAGE](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'managementFeePercentage',
      percentageUnformatter,
    );
  },
  [propertyActionTypes.SET_PROPERTY_VACANCY_FEE_PERCENTAGE](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'vacancyFeePercentage',
      percentageUnformatter,
    );
  },
  [actionTypes.SET_RAY_WHITE_DATA](state, action) {
    const { office, agents } = action.payload;
    return update(state, {
      office: { $set: office },
      agents: { $set: agents },
      isRayWhiteCalculator: { $set: true },
    });
  },
  [propertyActionTypes.SET_PROPERTY_REPAIR_FEE](state, action) {
    const yearlyFee = unformatCurrency(action.payload.value);
    const weeklyFee = yearlyFee / 52;
    return update(state, {
      properties: {
        [action.payload.id]: {
          yearlyRepairFee: { $set: yearlyFee },
          weeklyRepairFee: { $set: weeklyFee },
        },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_REALESTATE_COMMISSION_PERCENTAGE](
    state,
    action,
  ) {
    return setPropertyIdValue(
      state,
      action.payload,
      'realEstateCommissionPercentage',
      percentageUnformatter,
    );
  },
  [propertyActionTypes.SET_PROPERTY_MOVING_COST](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'movingCost',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_RENOVATION_COST](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'renovationCost',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_ADS_FEE](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'adsFee',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_INVESTMENT_LOWEST_RATE_MANUAL](
    state,
    action,
  ) {
    const transform = action.payload.value === '' ? _.identity : _.toNumber;
    return setPropertyIdValue(
      state,
      action.payload,
      'investmentLowestRateManual',
      transform,
    );
  },
  [propertyActionTypes.SET_PROPERTY_CONVEYANCER_COST](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'conveyancerCost',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_CURRENT_LENDER](state, action) {
    const { value } = action.payload;
    const text = value && value.text !== undefined ? value.text : value;
    return update(state, {
      properties: { [action.payload.id]: { currentLender: { $set: text } } },
    });
  },
  [propertyActionTypes.SET_PROPERTY_CURRENT_LENDER_ID](state, action) {
    const { value } = action.payload;
    return update(state, {
      properties: {
        [action.payload.id]: { currentLenderId: { $set: +value } },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_CURRENT_INTEREST_RATE](state, action) {
    const { value } = action.payload;
    // This regex invalidates strings ending in decimal points, whilst using Number
    // will just convert it to an int. E.g. Number(3.) => 3
    const valueToSet = /^([+-])?(\d+(\.\d+)?|Infinity)$/.test(value)
      ? Number(value)
      : value;

    return update(state, {
      properties: {
        [action.payload.id]: { currentInterestRate: { $set: valueToSet } },
      },
    });
  },
  [propertyActionTypes.SET_PROPERTY_EXIT_FEE](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'exitFee',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_CONSOLIDATE_DEBT_AMOUNT](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'consolidateDebtAmount',
      unformatCurrency,
    );
  },
  [propertyActionTypes.SET_PROPERTY_RENOVATION_PURPOSE](state, action) {
    return setPropertyIdValue(state, action.payload, 'renovationPurpose');
  },
  [propertyActionTypes.SET_PROPERTY_ADDITIONAL_FUNDS](state, action) {
    return setPropertyIdValue(
      state,
      action.payload,
      'additionalFunds',
      unformatCurrency,
    );
  },
  [actionTypes.RESUME_SCENARIO](state, action) {
    const { loanPurpose, name, properties } = action.payload;
    let newState = INITIAL_SCENARIO_STATE;
    if (loanPurpose) {
      newState = actionHandlers[actionTypes.SET_LOAN_PURPOSE](newState, {
        payload: loanPurpose,
      });
      if (name) {
        newState = actionHandlers[actionTypes.SET_SCENARIO_NAME](newState, {
          payload: name,
        });
      }
    }
    return update(newState, {
      $merge: _.omit(action.payload, 'properties'),
      properties: Object.keys(properties).reduce(
        (p, key) => ({
          ...p,
          [key]: { $merge: properties[key] },
        }),
        {},
      ),
    });
  },
};

const scenarioReducer = handleActions(actionHandlers, INITIAL_SCENARIO_STATE);

export default scenarioReducer;
