// Property zoning types
export const ZONING_INDUSTRIAL = { id: 1, name: 'Industrial' };
export const ZONING_RESIDENTIAL = { id: 2, name: 'Residential' };
export const ZONING_RURAL = { id: 3, name: 'Rural' };
export const ZONING_COMMERCIAL = { id: 4, name: 'Commercial' };

export const ZONINGS = [
  ZONING_INDUSTRIAL,
  ZONING_RESIDENTIAL,
  ZONING_RURAL,
  ZONING_COMMERCIAL,
];

// Property types
export const PROPERTY_TYPE_APT_UNIT_FLAT = { id: 1, name: 'Apt Unit Flat' };
export const PROPERTY_TYPE_BEDSITTER_BACHELOR = {
  id: 2,
  name: 'Bedsitter Bachelor',
};
export const PROPERTY_TYPE_BOARDING_HOUSE = { id: 3, name: 'Boarding House' };
export const PROPERTY_TYPE_COMBINED_SHOP_RESI = {
  id: 4,
  name: 'Combined Shop Residence',
};
export const PROPERTY_TYPE_COMMERCIAL_RESIDENTIAL = {
  id: 5,
  name: 'Commercial',
};
export const PROPERTY_TYPE_COMPANY_TITLE_UNIT = {
  id: 6,
  name: 'Company Title Unit',
};
export const PROPERTY_TYPE_CONVERTED_COMMERCIAL_PROPERTY = {
  id: 7,
  name: 'Converted Commercial Property',
};
export const PROPERTY_TYPE_CONVERTED_INDUSTRIAL_PROPERTY = {
  id: 8,
  name: 'Converted Industrial Property',
};
export const PROPERTY_TYPE_CONVERTED_MOTEL_UNITS = {
  id: 9,
  name: 'Converted Motel Units',
};
export const PROPERTY_TYPE_CONVERTED_PROPERTY = {
  id: 10,
  name: 'Converted Property',
};
export const PROPERTY_TYPE_DISPLAY_HOME = { id: 11, name: 'Display Home' };
export const PROPERTY_TYPE_DUPLEX = { id: 12, name: 'Duplex' };
export const PROPERTY_TYPE_FULLY_DETACHED_HOUSE = {
  id: 13,
  name: 'Fully Detached House',
};
export const PROPERTY_TYPE_GOVERMENT_RENTAL = {
  id: 14,
  name: 'Government Rental Guarantee',
};
export const PROPERTY_TYPE_HOBBY_FARM = { id: 15, name: 'Hobby Farm' };
export const PROPERTY_TYPE_HOLIDAY_HOME = { id: 16, name: 'Holiday Home' };
export const PROPERTY_TYPE_HOLIDAY_RENTAL = { id: 17, name: 'Holiday Rental' };
export const PROPERTY_TYPE_INDUSTRIAL = { id: 18, name: 'Industrial' };
export const PROPERTY_TYPE_INNER_CITY_APARTMENT = {
  id: 19,
  name: 'Inner City Apartment',
};
export const PROPERTY_TYPE_KIT_HOME = { id: 20, name: 'Kit Home' };
export const PROPERTY_TYPE_LICENSED_BUILDER = {
  id: 21,
  name: 'Licensed Builder House Construction',
};
export const PROPERTY_TYPE_LUXURY_HOUSE = { id: 22, name: 'Luxury House' };
export const PROPERTY_TYPE_LUXURY_OTHER = { id: 23, name: 'Luxury Other' };
export const PROPERTY_TYPE_MULTIPLE_ON_TITLE = {
  id: 24,
  name: 'Multiple On Title',
};
export const PROPERTY_TYPE_NEW_STRATA_TITLE_UNIT = {
  id: 25,
  name: 'New Strata Title Unit',
};
export const PROPERTY_TYPE_NURSING_HOME = { id: 26, name: 'Nursing Home' };
export const PROPERTY_TYPE_OWNER_BUILDER_HOUSE = {
  id: 27,
  name: 'Owner Builder House Construction',
};
export const PROPERTY_TYPE_PROPERTY_DEVELOPMENT = {
  id: 28,
  name: 'Property Development',
};
export const PROPERTY_TYPE_RELOCATABLE_HOME = {
  id: 29,
  name: 'Relocatable Home',
};
export const PROPERTY_TYPE_RENTAL_GUARANTEE = {
  id: 30,
  name: 'Rental Guarantee',
};
export const PROPERTY_TYPE_RESORT_UNIT = { id: 31, name: 'Resort Unit' };
export const PROPERTY_TYPE_RETIREMENT_UNIT = {
  id: 32,
  name: 'Retirement Unit',
};
export const PROPERTY_TYPE_SEMI_DETATCHED_HOUSE = {
  id: 33,
  name: 'Semi Detached House',
};
export const PROPERTY_TYPE_SERVICED_APT = { id: 34, name: 'Serviced Apt' };
export const PROPERTY_TYPE_SINGLE_BEDROOM = {
  id: 35,
  name: 'Single Bedroom Less 50m2',
};
export const PROPERTY_TYPE_SNOW_LEASE = { id: 36, name: 'Snow lease' };
export const PROPERTY_TYPE_STRATA_TITLE_UNIT = {
  id: 37,
  name: 'Strata Title Unit',
};
export const PROPERTY_TYPE_STUDENT_ACCOMODATION = {
  id: 38,
  name: 'Student Accommodation',
};
export const PROPERTY_TYPE_STUDIO_WAREHOUSE_APT = {
  id: 39,
  name: 'Studio Warehouse Apt',
};
export const PROPERTY_TYPE_TERRACE = { id: 40, name: 'Terrace' };
export const PROPERTY_TYPE_TIMESHARE = { id: 41, name: 'Timeshare' };
export const PROPERTY_TYPE_TOWN_HOUSE = { id: 42, name: 'Town house' };
export const PROPERTY_TYPE_TRANSPORTABLE_HOME = {
  id: 43,
  name: 'Transportable Home',
};
export const PROPERTY_TYPE_UNIT_STUDENT_ACCOMODATION = {
  id: 44,
  name: 'Unit Student Accommodation',
};
export const PROPERTY_TYPE_VACANT_LAND_RESIDENTIAL = {
  id: 45,
  name: 'Vacant Land',
};
export const PROPERTY_TYPE_VILLA = { id: 46, name: 'Villa' };
export const PROPERTY_TYPE_WAREHOUSE_CONVERSION = {
  id: 47,
  name: 'Warehouse Conversion',
};
export const PROPERTY_TYPE_EIGHT_OR_LESS = {
  id: 57,
  name: '8 Hectares Or Less',
};
export const PROPERTY_TYPE_OVER_EIGHT_LESS_THAN_FORTY = {
  id: 58,
  name: 'Over 8 Less Than 40 Hectares',
};
export const PROPERTY_TYPE_OVER_FORTY = { id: 59, name: 'Over 40 Hectares' };
export const PROPERTY_TYPE_PROF_CHAMBERS = { id: 48, name: 'Prof Chambers' };
export const PROPERTY_TYPE_OFFICES = { id: 49, name: 'Offices' };
export const PROPERTY_TYPE_FACTORY = { id: 50, name: 'Factory' };
export const PROPERTY_TYPE_WAREHOUSE = { id: 51, name: 'Warehouse' };
export const PROPERTY_TYPE_VACANT_LAND_COMMERCIAL = {
  id: 52,
  name: 'Vacant Land',
};
export const PROPERTY_TYPE_RETIREMENT_VILLAGE = {
  id: 53,
  name: 'Retirement Village',
};
export const PROPERTY_TYPE_NON_SPECIALISED_COMMERCIAL = {
  id: 54,
  name: 'Non Specialised Commercial',
};
export const PROPERTY_TYPE_RESIDENTAIL_COMMERCIAL = {
  id: 55,
  name: 'Residential Commercial',
};
export const PROPERTY_TYPE_OTHER_COMMERCIAL = { id: 56, name: 'Other' };
export const PROPERTY_TYPE_NON_SPECIALISED_INDUSTRIAL = {
  id: 60,
  name: 'Non Specialised Industrial',
};
export const PROPERTY_TYPE_LIGHT_INDUSTRIAL = {
  id: 61,
  name: 'Light Industrial',
};
export const PROPERTY_TYPE_OTHER_INDUSTRIAL = { id: 62, name: 'Other' };
export const PROPERTY_TYPE_ATTACHED_UNIT = { id: 63, name: 'Attatched Unit' };
export const PROPERTY_TYPE_LIFESTYLE = { id: 66, name: 'Lifestyle' };
export const PROPERTY_TYPE_RESIDENTAIL_LEASEHOLD = {
  id: 67,
  name: 'Residential Leasehold',
};
export const PROPERTY_TYPE_STD_RESIDENTIAL = {
  id: 68,
  name: 'Std Residential',
};
export const PROPERTY_TYPE_MAORI_LAND = {
  id: 69,
  name: 'Maori Land',
  availableCountries: ['NZ'],
};

export const PROPERTY_TYPES_PER_ZONING = {
  [ZONING_INDUSTRIAL.id]: [
    PROPERTY_TYPE_NON_SPECIALISED_INDUSTRIAL,
    PROPERTY_TYPE_LIGHT_INDUSTRIAL,
    PROPERTY_TYPE_OTHER_INDUSTRIAL,
  ],
  [ZONING_RESIDENTIAL.id]: [
    PROPERTY_TYPE_APT_UNIT_FLAT,
    PROPERTY_TYPE_FULLY_DETACHED_HOUSE,
    PROPERTY_TYPE_SEMI_DETATCHED_HOUSE,
    PROPERTY_TYPE_TERRACE,
    PROPERTY_TYPE_PROPERTY_DEVELOPMENT,
    PROPERTY_TYPE_VACANT_LAND_RESIDENTIAL,
    { divider: true },
    PROPERTY_TYPE_ATTACHED_UNIT,
    PROPERTY_TYPE_BEDSITTER_BACHELOR,
    PROPERTY_TYPE_BOARDING_HOUSE,
    PROPERTY_TYPE_COMBINED_SHOP_RESI,
    PROPERTY_TYPE_COMMERCIAL_RESIDENTIAL,
    PROPERTY_TYPE_COMPANY_TITLE_UNIT,
    PROPERTY_TYPE_CONVERTED_COMMERCIAL_PROPERTY,
    PROPERTY_TYPE_CONVERTED_INDUSTRIAL_PROPERTY,
    PROPERTY_TYPE_CONVERTED_MOTEL_UNITS,
    PROPERTY_TYPE_CONVERTED_PROPERTY,
    PROPERTY_TYPE_DISPLAY_HOME,
    PROPERTY_TYPE_DUPLEX,
    PROPERTY_TYPE_GOVERMENT_RENTAL,
    PROPERTY_TYPE_HOBBY_FARM,
    PROPERTY_TYPE_HOLIDAY_HOME,
    PROPERTY_TYPE_HOLIDAY_RENTAL,
    PROPERTY_TYPE_INDUSTRIAL,
    PROPERTY_TYPE_INNER_CITY_APARTMENT,
    PROPERTY_TYPE_KIT_HOME,
    PROPERTY_TYPE_LICENSED_BUILDER,
    PROPERTY_TYPE_LIFESTYLE,
    PROPERTY_TYPE_LUXURY_HOUSE,
    PROPERTY_TYPE_LUXURY_OTHER,
    PROPERTY_TYPE_MAORI_LAND,
    PROPERTY_TYPE_MULTIPLE_ON_TITLE,
    PROPERTY_TYPE_NEW_STRATA_TITLE_UNIT,
    PROPERTY_TYPE_NURSING_HOME,
    PROPERTY_TYPE_OWNER_BUILDER_HOUSE,
    PROPERTY_TYPE_RELOCATABLE_HOME,
    PROPERTY_TYPE_RENTAL_GUARANTEE,
    PROPERTY_TYPE_RESIDENTAIL_LEASEHOLD,
    PROPERTY_TYPE_RESORT_UNIT,
    PROPERTY_TYPE_RETIREMENT_UNIT,
    PROPERTY_TYPE_SERVICED_APT,
    PROPERTY_TYPE_SINGLE_BEDROOM,
    PROPERTY_TYPE_SNOW_LEASE,
    PROPERTY_TYPE_STD_RESIDENTIAL,
    PROPERTY_TYPE_STRATA_TITLE_UNIT,
    PROPERTY_TYPE_STUDENT_ACCOMODATION,
    PROPERTY_TYPE_STUDIO_WAREHOUSE_APT,
    PROPERTY_TYPE_TIMESHARE,
    PROPERTY_TYPE_TOWN_HOUSE,
    PROPERTY_TYPE_TRANSPORTABLE_HOME,
    PROPERTY_TYPE_UNIT_STUDENT_ACCOMODATION,
    PROPERTY_TYPE_VILLA,
    PROPERTY_TYPE_WAREHOUSE_CONVERSION,
  ],
  [ZONING_RURAL.id]: [
    PROPERTY_TYPE_EIGHT_OR_LESS,
    PROPERTY_TYPE_OVER_EIGHT_LESS_THAN_FORTY,
    PROPERTY_TYPE_OVER_FORTY,
  ],
  [ZONING_COMMERCIAL.id]: [
    PROPERTY_TYPE_PROF_CHAMBERS,
    PROPERTY_TYPE_OFFICES,
    PROPERTY_TYPE_FACTORY,
    PROPERTY_TYPE_WAREHOUSE,
    PROPERTY_TYPE_VACANT_LAND_COMMERCIAL,
    PROPERTY_TYPE_RETIREMENT_VILLAGE,
    PROPERTY_TYPE_NON_SPECIALISED_COMMERCIAL,
    PROPERTY_TYPE_RESIDENTAIL_COMMERCIAL,
    PROPERTY_TYPE_OTHER_COMMERCIAL,
  ],
};

// Transaction
export const TRANSACTION_PURCHASING = 1;
export const TRANSACTION_OWNS = 2;
export const TRANSACTION_CONSTRUCT = 6;
export const TRANSACTION_PURCHASE_CONSTRUCT = 7;
export const TRANSACTION_OWNS_CONSTRUCT = 8;
export const TRANSACTION_REFINANCE_CONSTRUCT = 9;
export const TRANSACTION_CONSTRUCTIONS = [
  TRANSACTION_CONSTRUCT,
  TRANSACTION_PURCHASE_CONSTRUCT,
  TRANSACTION_OWNS_CONSTRUCT,
  TRANSACTION_REFINANCE_CONSTRUCT,
];

// Property primary Purpose
export const PRIMARY_PURPOSE_OWNER_OCCUPIED = { id: 1, name: 'Home' };
export const PRIMARY_PURPOSE_INVESTMENT = { id: 2, name: 'Investment' };

export const PROPERTY_PRIMARY_PURPOSES = [
  PRIMARY_PURPOSE_OWNER_OCCUPIED,
  PRIMARY_PURPOSE_INVESTMENT,
];

// Property status
export const STATUS_NEW_BUILDING = { id: 1, name: 'Newly constructed home' };
export const STATUS_TO_BE_BUILT = { id: 2, name: 'To be built' };
export const STATUS_ESTABLISHED = { id: 3, name: 'An established home' };
export const STATUS_VACANT_LAND = {
  id: 4,
  name: 'Vacant land to build new home',
};

export const PROPERTY_STATUSES = [
  STATUS_NEW_BUILDING,
  STATUS_TO_BE_BUILT,
  STATUS_ESTABLISHED,
  STATUS_VACANT_LAND,
];
