import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { intlShape, injectIntl, defineMessages } from 'react-intl';

import styles from './SectionFooter.css';

const messages = defineMessages({
  totalText: {
    id: 'SectionFooter.total.text',
    defaultMessage: 'Total {name}',
  },
});

const SectionFooter = ({
  name,
  value,
  valueInfoLabel,
  intl: { formatMessage },
  isGroup,
}) => (
  <div
    data-testid='section.footer'
    className={classNames(styles.root, {
      [styles.isGroup]: isGroup,
    })}
  >
    <span className={styles.footerName}>
      {formatMessage(messages.totalText, { name })}
    </span>
    <div className={styles.footerValue}>
      <span>{value}</span>
      {valueInfoLabel && <span>{valueInfoLabel}</span>}
    </div>
  </div>
);

SectionFooter.propTypes = {
  name: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueInfoLabel: PropTypes.string,
  isGroup: PropTypes.bool,
  intl: intlShape.isRequired,
};

SectionFooter.defaultProps = {
  isGroup: false,
};

export default injectIntl(SectionFooter);
