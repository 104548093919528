import { createActions } from 'redux-actions';

export const FETCH_ACCREDITED_LENDERS = 'fetchAccreditedLenders';
export const SET_ACCREDITED_LENDERS = 'setAccreditedLenders';

export default createActions(
  {},
  FETCH_ACCREDITED_LENDERS,
  SET_ACCREDITED_LENDERS,
);
