import { createSelector } from 'reselect';
import _ from 'lodash';

export const expenseCategoryTypes = (state) =>
  state.expenseCategoryType.entities || [];

export const expenseTypes = createSelector(
  expenseCategoryTypes,
  (categoryTypes) => _.flatMap(categoryTypes, (c) => c.types),
);

export const categoryById = createSelector(
  expenseCategoryTypes,
  (categoryTypes) =>
    _.memoize((categoryId) => categoryTypes.find((c) => c.id === categoryId)),
);

export const typeInfo = createSelector(expenseTypes, (types) =>
  _.memoize((typeId) => types.find((t) => t.id === typeId) || {}),
);
