/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import PropTypes from 'prop-types';

export const agentFeeDescription = (style) => (
  <div className={style}>
    <h1>What are real estate agent fees?</h1>
    <p>
      Real estate agents typically charge an advertising cost for marketing and
      a sale commission once the property is sold.
    </p>
    <h1>What should I expect to pay?</h1>
    <p>
      Fees differ in structure and are negotiable.
      <br />
      <em>Commission based fees</em> typically range between 2%–3.5%. Regional
      areas often are at the higher end due to lower prices and property
      turnover.
      <br />
      <em>Marketing and advertising fees</em> vary. A rule of thumb is to spend
      between 0.5–1.0% on marketing, plans, print and online ads. Expect to pay
      anywhere between $1000–$10,000+.
    </p>
  </div>
);

export const interestOnlyDescription = (style) => (
  <div className={style}>
    <h1>What are interest only repayments?</h1>
    <p>
      The calculated interest only repayments are a rough indication of the
      minimum cost of serving your loan.
    </p>
    <p>
      The rate is based on today’s lowest advertised investment rate. It models
      the scenario where you don’t change your loan amount and refinance to
      secure a great rate on an interest only investment loan.
    </p>
    <p>
      You may be able to change the amount of the investment loan to take
      advantage of tax breaks or improve your cash flow. We are experts at
      running the numbers and finding the right structure for you.
    </p>
  </div>
);

export const LVRDescription = (style) => (
  <div className={style}>
    <p>
      The Loan to Value Ratio (LVR) is a measure of how much you’re borrowing
      versus the value of the property.
    </p>
    <p>
      Most lenders will lend up to a maximum of 95% of the property – although
      there are exceptions to this blanket rule.
    </p>
    <p>
      If your LVR is greater than 80% you may have to pay Lenders Mortgage
      Insurance (LMI), potentially increasing the overall loan amount by about
      2%.
    </p>
    <p>
      Basically, if you can scrape together a 20% deposit you’re in an excellent
      position. But don’t worry if you can’t — we’ll find a way to make it work
      for you.
    </p>
  </div>
);

export const FHOGDescription = ({ style }) => (
  <div className={style.infoOnly}>
    <h1>What is the First Home Owners Grant (FHOG)?</h1>
    <p>
      The First Home Owners Grant is a government sponsored initiative that
      helps first time buyers get their foot into the property market. Today
      most states use the First Home Owners Grant to encourage buyers to
      purchase new or developed properties.
    </p>
    <h1>Am I eligible for the FHOG?</h1>
    <p>
      The rules are complex and differ in each state, but generally speaking, to
      be eligible:
    </p>
    <ol>
      <li>
        You must be purchasing a newly built property (never been lived in
        before)
      </li>
      <li>
        It must be the first purchase of property by the purchaser or
        co-purchaser
      </li>
      <li>You must be an Australian citizen or permanent resident</li>
    </ol>
  </div>
);

FHOGDescription.propTypes = {
  style: PropTypes.object.isRequired,
};

export const proceedsOfSaleDescription = (style) => (
  <div className={style}>
    <h1>What is the proceeds of sale?</h1>
    <p>
      It’s how much you will have in your pocket after the sale of your
      property. It’s basically the sale price less all repayments, fees and
      charges.
    </p>
    <h1>Should I use all of it to help purchase my next property?</h1>
    <p>
      Generally speaking, you want to have no less than a 20% deposit for your
      next purchase. Figuring out exactly how much to use is complex. The good
      news is we are experts at doing the numbers and working out what will work
      best for you.
    </p>
  </div>
);

export const vacancyDescription = (style) => (
  <div className={style}>
    <h1>What is a vacancy allowance?</h1>
    <p>
      When tenants move out there is usually a period that a property will be
      vacant. The vacancy allowance accounts for this loss in rental income.
    </p>
    <h1>What should I allow for?</h1>
    <p>
      Vacancy rates vary across Australia, with state averages ranging from
      about 0.5% to 5%.
    </p>
  </div>
);

export const maintenanceDescription = (style) => (
  <div className={style}>
    <h1>What are repairs and maintenance?</h1>
    <p>
      There are always costs associated with renting out a property. Simple
      examples are things like repainting, changing lightbulbs and fixing
      leaking taps.
    </p>
    <h1>What should I allow for?</h1>
    <p>
      Average Australian households* spend around $1,200 a year on repairs and
      maintence - this allowance includes both material cost (e.g. paint) and
      contractor service fees (e.g. plumbers, electricitions).
    </p>
    <p>
      * Research data from the latest Australian Bureau of Statistics’ Household
      Expenditure Survey (HES).
    </p>
  </div>
);

export const managementDescription = (style) => (
  <div className={style}>
    <h1>What are property management fees?</h1>
    <p>
      Using a professional property manager can take a lot of the pain and worry
      out of running an investment property.
    </p>
    <h1>What should I allow for?</h1>
    <p>
      Fees differ in structure and are negotiable. Rates typically range between
      5% to 10% - regional areas often being at the higher end due to lower
      rents and fewer properties.
    </p>
  </div>
);

export const stampDutyDescription = (style) => (
  <div className={style}>
    <h1>What is stamp duty?</h1>
    <p>
      Stamp duty is a basic tax applied by state governments in Australia and is
      in relation to the transfer of land or property.
    </p>
    <h1>How is stamp duty calculated?</h1>
    <p>
      Stamp duty is paid by the purchaser and determined by the property value,
      purpose, type and where it is located. The exact rules change from state
      to state.
    </p>
    <h1>Can I avoid paying it?</h1>
    <p>
      For most people the answer is no. All transfers of land - whether done
      through a sale or gift will be charged stamp duty. Concessions may be
      available for Pensioners, First home buyers, Farmers, Off-the-plan buyers
      and buyers who purchase the property as owner occupiers.
    </p>
  </div>
);

export const LMIDescription = (style) => (
  <div className={style}>
    <h1>What is LMI?</h1>
    <p>
      Lenders’ mortgage insurance (LMI) is insurance to protect your lender in
      the unlikely event that you default on your home loan. Although the lender
      has the property as a security, if property’s value declines this security
      may not be enough to cover the outstanding loan.
    </p>
    <p>
      LMI is not to be confused with mortgage protection insurance which is to
      cover you in the event of unforeseen circumstances which prohibit you from
      making repayments (including unemployment, illness or death).
    </p>
    <h1>Can I avoid paying it?</h1>
    <p>
      Lenders Mortgage Insurance, or LMI, will be payable on most loans where
      the LVR is greater than 80% (where your borrowing more than 80% of the
      value of the property). You may be able to avoid paying LMI if you
      increase your cash deposit.
    </p>
    <h1>How is LMI calculated?</h1>
    <p>
      Each lender will calculate the exact amount differently based on their
      preferred insurance provider. Our estimatated allowance is an average
      only. Roughly speaking, LMI will add about 2% to the total loan amount.
    </p>
  </div>
);

export const rentDescription = (style) => (
  <div className={style}>
    <h1>What is rent collected?</h1>
    <p>
      It’s the advertised rent per week / month - the amount the tenants pay to
      lease the property.
    </p>
    <h1>How can I calculate how much to charge?</h1>
    <p>
      Looking around for similar properties in the same location is a good place
      to start.
    </p>
  </div>
);

export const mortgageDescription = (style) => (
  <div className={style}>
    <h1>What is the outstanding mortgage?</h1>
    <p>
      It’s how much you need to pay back to your bank or lender when you sell
      your property.
    </p>
    <h1>How do I work out how much it is?</h1>
    <p>
      Jump onto your internet banking and have a look at the balance on your
      loan(s). That’s your outstanding mortgage amount.
    </p>
    <div>
      <h1>Do I really need to pay it all back?</h1>
      <p>
        Yes. To sell your property you need to release the property from the
        bank(s) mortgage on the title.
      </p>
    </div>
  </div>
);

export const existingMortgageDescription = (style) => (
  <div className={style}>
    <h1>What is the outstanding mortgage?</h1>
    <p>
      It’s how much you need to pay back to your bank or lender when you sell
      your property.
    </p>
    <h1>How do I work out how much it is?</h1>
    <p>
      Jump onto your internet banking and have a look at the balance on your
      loan(s). That’s your outstanding mortgage amount.
    </p>
  </div>
);

export const salePriceDescription = (style) => (
  <div className={style}>
    <h1>What is the sale price?</h1>
    <p>
      The sale price is the price you think you could sell your property for.
    </p>
    <h1>If you are planning on selling your property...</h1>
    <p>
      You should probably work with a conservative value, rather than assuming
      you’re going to get the highest price imaginable.
    </p>
    <h1>If you’ve already sold your property...</h1>
    <p>
      In this case it’s simple. The sale price is the exact amount shown on the
      contract of sale.
    </p>
  </div>
);

export const cashDepositDescription = (style) => (
  <div className={style}>
    <h1>What is a cash deposit?</h1>
    <p>
      A cash deposit is the savings that you will contribute to the purchase of
      a property.
    </p>
    <h1>How much do I need?</h1>
    <p>
      Generally, most banks will lend up to a maximum of 95% of the property
      value (or 95% LVR). If your LVR is greater than 80%, you may need to pay
      Lenders Mortgage Insurance (LMI), which typically increases the overall
      loan amount by about 2%. The larger your cash deposit, the less interest
      you’ll pay over the life of the loan.
    </p>
    <h1>Help! My situation is complicated.</h1>
    <p>
      If you don’t have anything saved don’t worry — we’ll find a way to get you
      there. If you already own property, you probably have built up equity that
      can be used as a deposit. If this is the case, a cash deposit may not even
      be required.
    </p>
  </div>
);

export const movingAndCleaningDescription = (style) => (
  <div className={style}>
    <h1>Moving costs</h1>
    <p>
      Professionals can make this hard work easy. Costs vary based on how far
      you’re moving and how much stuff you have to move.
    </p>
    <p>
      Local 1 bed move: $500–$2,000
      <br />
      Local 2 bed move: $2,000–$3,500
      <br />
      Interstate 3 bed home: $3,500–$5,000+
    </p>
    <h1>Cleaning costs</h1>
    <p>
      If you can afford the luxury of professional cleaning it’s a gift to
      whoever moves in.
    </p>
    <p>
      1 bed flat clean: $75-$250
      <br />3 bed house clean: $100–$350+
    </p>
  </div>
);

export const renovationDescription = (style) => (
  <div className={style}>
    <h1>What are renovation costs?</h1>
    <p>
      Renovations are one of the most popular ways to maximise the value and
      livability of a property.
    </p>
    <p>
      Costs vary A LOT. You can use these very rough, ballpark figures to budget
      - but if you’re seriously considering renovating you should speak to a
      local building expert.
    </p>
    <p>
      {' '}
      Interior painting: $2,500–$7,000
      <br />
      Exterior painting: $5,000–$20,000
      <br />
      Kitchen upgrades: $5,000–$25,000
      <br />
      Bathroom upgrades: $5,000–$15,000
      <br />
      Carpets/floorboards: $3,500–$15,000
      <br />
      Additional bedrooms: $20,000–$60,000
    </p>
  </div>
);

export const legalFeeDescription = (style) => (
  <div className={style}>
    <h1>What are legal fees?</h1>
    <p>
      Even in simple property transactions you will probably need to pay a
      conveyancer to process the transfer of the property’s title between the
      two parties. If there are any complexities in this process you may need a
      solicitor to help and provide legal guidance.
    </p>
    <h1>How much should I expect this to cost?</h1>
    <p>
      Simple transactions: $750–$1,250
      <br />
      Complex transactions: $1,250–$2,500+
    </p>
  </div>
);

export const purchasePriceDescription = (style) => (
  <div className={style}>
    <h1>What is the purchase price?</h1>
    <p>
      The purchase price is the winning bid or winning offer on property that is
      for sale. Put simply, it’s how much it cost to buy.
    </p>
    <h1>If you’ve found a place...</h1>
    <p>The purchase price is the exact amount shown on the contract of sale.</p>
    <h1>If you’re still looking for a property...</h1>
    <p>
      You should set the purchase price to the maximum you would be willing to
      pay at an auction, or if you were to make an offer on a property. Working
      with a maximum amount gives you clear boundaries - meaning that when you
      get pre-approval you can be sure you can afford anything up to that
      amount.
    </p>
  </div>
);
