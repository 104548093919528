import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl } from 'react-intl';

import addressActions from 'actions/addressActions';
import UIActions from 'actions/UIActions';
import { logEvent, EVENTS } from 'lib/amplitude';

import * as addressSelectors from 'selectors/addressSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import FormPopup, { formPopupProps } from 'components/PopupItem/FormPopup';
import AddressForm from 'components/PopupForms/AddressForm';
import { ADDRESSES_SLUG } from 'constants/applyData';

class AddressPopup extends FormPopup {
  static propTypes = {
    ...formPopupProps,
    intl: intlShape.isRequired,
  };

  onLoadNew() {
    /* anti pattern here because address.working.new is initially null */
    const { load } = this.props;
    load('new');
  }

  logSaveEvent() {
    logEvent(EVENTS.SAVE_HISTORY, { section: ADDRESSES_SLUG });
  }

  logRemoveEvent() {
    logEvent(EVENTS.REMOVE_HISTORY, { section: ADDRESSES_SLUG });
  }

  title() {
    const { working } = this.props;
    const formStateTitle = super.title();
    if (formStateTitle) {
      return `${formStateTitle} address`;
    }
    return working.id ? 'Edit your address' : 'Add an address';
  }

  value = () => undefined;

  renderForm() {
    const { isLocked, working, clientIdOptionsSharable } = this.props;

    return (
      <AddressForm
        key={`addressPopupForm-${this.state.refreshKey}`}
        isLocked={isLocked}
        clientIdOptionsSharable={clientIdOptionsSharable}
        working={working}
        save={this.save}
        remove={this.remove}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const paramId = ownProps.match.params.id;
  const id = parseInt(paramId, 10) || paramId || 'new';
  return {
    id,
    item: addressSelectors.entity(state)(id),
    working: addressSelectors.working(state)(id),
    clientIdOptionsSharable: clientSelectors.clientIdOptionsSharable(state),
    requestIsProcessing: UISelectors.requestProcessing(state)(id),
    errors: UISelectors.requestErrors(state),
    isLocked: applicationSelectors.getIsLocked(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      load: addressActions.loadAddress,
      create: addressActions.createAddress,
      update: addressActions.updateAddress,
      delete: addressActions.deleteAddress,
      clearWorking: addressActions.clearWorkingAddress,
      clearErrors: UIActions.clearAsyncRequestErrors,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AddressPopup),
);
