import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxMirrorPropTypes } from '@loanmarket/react-redux-mirror';

import Questions from 'lib/Questions';
import { REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO } from 'constants/validators';

import Form from 'components/Form/Form';
import PercentageDropdown from 'components/PercentageDropdown/PercentageDropdown';
import Button from 'components/Button/Button';
import Question from 'components/Question/Question';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

const questionSet = () => {
  const questions = new Questions();
  questions.addBranch(['value', REQUIRED, GREATER_THAN_OR_EQL_TO_ZERO]);
  return questions.arrayOfQuestions();
};

class SingleDropdownForm extends Component {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    setValue: PropTypes.func,
    percentageList: PropTypes.arrayOf(PropTypes.number),
    valueToPercentage: PropTypes.func.isRequired,
    percentageToValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
    ...reduxMirrorPropTypes,
  };

  static defaultProps = {
    label: 'Amount',
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  handleUpdate = () => {
    const { sync, onSubmit } = this.props;
    sync();
    onSubmit();
  };

  handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && this.formCompleted) {
      this.handleUpdate();
    }
  };

  handleOnChange = (value) => {
    this.props.setValue(value);
  };

  render() {
    const {
      label,
      value,
      setValue,
      valueToPercentage,
      percentageToValue,
      percentageList,
      questions,
      setCurrentQuestionTo,
      formCompleted,
    } = this.props;
    return (
      <Form
        formCompleted={formCompleted}
        onSubmit={this.handleUpdate}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <Question {...questions.value} className='fullWidthInput' label={label}>
          <PercentageDropdown
            id='PercentageDropdown'
            action={setValue}
            value={value}
            valueToPercentage={valueToPercentage}
            percentageToValue={percentageToValue}
            percentageList={percentageList}
            onKeyDown={this.handleOnKeyDown}
            hideNegativeOptions
          />
        </Question>
        <Button
          disabled={!formCompleted}
          id='PopupButton'
          className='brandColor__button margin-0'
          onClick={this.handleUpdate}
        >
          Update
        </Button>
        <input className='hidden' type='submit' />
      </Form>
    );
  }
}

export default manageQuestions(SingleDropdownForm);
