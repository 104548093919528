import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { searchAddress, searchAddressWithPlaceId } from 'services/addressApi';
import { NO_ERROR } from 'constants/validators';

import AddressInputIntl from 'components/AddressInputIntl/AddressInputIntl';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';

import { featureFlags } from 'lib/rollout';

class AutocompleteAddress extends Component {
  static propTypes = {
    value: PropTypes.string,
    action: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    errorId: PropTypes.string.isRequired,
    addressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    intlValue: PropTypes.object,
    intlOnChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  setAutoAddressError = (hardCodedText, blocking) => (inputText) => {
    this.props.setError({
      id: this.props.errorId,
      text: hardCodedText || inputText,
      blocking,
    });
  };

  setIntlAddress = (address) => {
    const { addressId, intlOnChange } = this.props;
    if (!addressId) {
      return;
    }

    const { formattedAddress, countryCode, placeId } = address;
    intlOnChange({ formattedAddress, countryCode, placeId, id: addressId });
  };

  render() {
    const {
      action,
      value,
      intlValue,
      intlOnChange,
      disabled,
      ...sharedProps
    } = this.props;

    return featureFlags.internationalAddresses.isEnabled() ? (
      <AddressInputIntl
        setAsyncError={this.setAutoAddressError(null, true)}
        removeAsyncError={this.setAutoAddressError(NO_ERROR)}
        onChange={this.setIntlAddress}
        value={intlValue}
        serviceFn={searchAddressWithPlaceId}
        isIntl
        disabled={disabled}
        sharedProps={sharedProps}
      />
    ) : (
      <AutocompleteInput
        setAsyncError={this.setAutoAddressError(null, true)}
        removeAsyncError={this.setAutoAddressError(NO_ERROR)}
        action={action}
        value={value}
        serviceFn={searchAddress}
        {...sharedProps}
      />
    );
  }
}

export default AutocompleteAddress;
