import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { percentageFormatter } from 'lib/utils/numberUtils';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import styles from './PercentageDropdown.css';

class PercentageDropdown extends Component {
  constructor(props) {
    super(props);
    /*
      We handle the blurring and focusing of this component manually because of the possible interactions with the dropdown
      section. When clicking the dropdown arrow we would expect this to be a "focusing" event, both opening it and closing it.
      In this way, the blurring and focusing needs to be abstracted from the CurrencyInput's concept of onFocus and onBlur.
    */
    this.state = {
      dropdownOpen: false,
      isFocused: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { onFocus, onBlur } = this.props;

    if (this.state.isFocused && !nextState.isFocused) {
      onBlur && onBlur();
    } else if (!this.state.isFocused && nextState.isFocused) {
      onFocus && onFocus();
    }

    return true;
  }

  onCurrencyFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  onCurrencyBlur = () => {
    this.setState({
      isFocused: false,
    });
  };

  toggleDropdown = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      isFocused: true,
    });
  };

  renderOptions = () => {
    const {
      hideNegativeOptions,
      percentageList,
      percentageToValue,
      action,
      id,
    } = this.props;
    return percentageList.map((percentage, index) => {
      const calculatedValue = percentageToValue(percentage);
      if (hideNegativeOptions && calculatedValue < 0) {
        return null;
      }
      return (
        <li
          key={`PercentageDropdownOption-${index}`}
          className={styles.item}
          onClick={() => action(calculatedValue)}
        >
          <CurrencyInput
            id={id}
            action={action}
            value={calculatedValue}
            className={styles.input}
            displayOnly
          />
          <div className={styles.percentageWrapper}>
            <span className={styles.percentage}>
              {percentageFormatter(percentage)}
            </span>
          </div>
        </li>
      );
    });
  };

  renderDropdown() {
    if (this.state.dropdownOpen) {
      return (
        <ol onClick={this.toggleDropdown} className={styles.items}>
          {this.renderOptions()}
        </ol>
      );
    }
    return null;
  }

  render() {
    const {
      action,
      error,
      value,
      className,
      id,
      valueToPercentage,
      onKeyDown,
      forceFocus,
      disabled,
    } = this.props;
    let toggleTriggered = false;

    const toggleWrapper = () => {
      if (disabled) {
        return;
      }
      toggleTriggered = true;
      this.toggleDropdown();
    };
    const blurHandler = () => {
      setTimeout(() => {
        if (!toggleTriggered) {
          this.onCurrencyBlur();
          toggleTriggered = false;
        }
      }, 50);
    };

    const dropdownStyles = disabled
      ? styles.disabledPercentageWrapper
      : styles.percentageWrapper;

    return (
      <div className={classNames(styles.root, className)}>
        <div className={styles.mainWrapper}>
          <CurrencyInput
            id={id}
            action={action}
            error={error}
            value={value}
            forceFocus={forceFocus}
            onFocus={this.onCurrencyFocus}
            onBlur={blurHandler}
            onKeyDown={onKeyDown}
            className={styles.input}
            disabled={disabled}
          />
          <div className={dropdownStyles} onClick={toggleWrapper}>
            <span className={styles.percentage}>
              {percentageFormatter(valueToPercentage(value))}
            </span>
            <i className='mi-arrow-filled-down' />
          </div>
        </div>

        {this.renderDropdown()}
      </div>
    );
  }
}

PercentageDropdown.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  action: PropTypes.func,
  value: PropTypes.number,
  valueToPercentage: PropTypes.func.isRequired,
  percentageToValue: PropTypes.func.isRequired,
  percentageList: PropTypes.arrayOf(PropTypes.number).isRequired,
  hideNegativeOptions: PropTypes.bool,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  forceFocus: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
};

PercentageDropdown.defaultProps = {
  hideNegativeOptions: false,
};

export default PercentageDropdown;
