import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import View from 'components/View/View';
import Spinner from 'components/Spinner/Spinner';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import { ApplyOnlineContent } from 'containers/ApplyOnlineContent';
import Button from 'components/Button/Button';
import ButtonNext from 'components/ButtonNext/ButtonNext';
import LocalStorageProxy from 'lib/localStorageProxy';

import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as contactSelectors from 'selectors/contactSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as completionSelectors from 'selectors/completionSelectors';
import * as UISelectors from 'selectors/UISelectors';
import * as assetSelectors from 'selectors/assetSelectors';
import * as liabilitySelectors from 'selectors/liabilitySelectors';
import * as expenseSelectors from 'selectors/expenseSelectors';
import * as incomeSelectors from 'selectors/incomeSelectors';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import { absolutePopup } from 'lib/utils/browserUtils';
import { featureOn } from 'lib/abTestHelper';
import { logger as coreLogger } from 'lib/coreLogger';
import { logEvent, EVENTS } from 'lib/amplitude';
import { LOAN_AMOUNT } from 'config/abTest';

import {
  APPLICATION_PROGRESS_BASE_PATH,
  DASHBOARD_BASE_PATH,
} from 'shared/constants/paths';

export const messages = defineMessages({
  title: {
    id: 'ApplyOnline.title',
    defaultMessage:
      '{name} profile is {percentageCompleted, number, percent} complete',
  },
  titleLoading: {
    id: 'ApplyOnline.titleLoading',
    defaultMessage: 'Your profile is loading...',
  },
  titleForLockedApplication: {
    id: 'ApplyOnline.titleForLockedApplication',
    defaultMessage: 'Your loan application.',
  },
  subtitleForSharedApplication: {
    id: 'ApplyOnline.subtitleForSharedApplication',
    defaultMessage: 'Fill out your details, and send for review.',
  },
  productLoan: {
    id: 'ApplyOnline.productLoan',
    defaultMessage: 'Product & Loan',
  },
  applicants: {
    id: 'ApplyOnline.applicants',
    defaultMessage: 'Applicants',
  },
  goals: {
    id: 'ApplyOnline.goals',
    defaultMessage: 'Goals',
  },
  household: {
    id: 'ApplyOnline.household',
    defaultMessage: 'Household',
  },
  properties: {
    id: 'ApplyOnline.properties',
    defaultMessage: 'Properties',
  },
  loanAmountApply: {
    id: 'ApplyOnline.loanAmountApply',
    defaultMessage: 'Loan Amount',
  },
  financialPosition: {
    id: 'ApplyOnline.financialPosition',
    defaultMessage: 'Financial Position',
  },
  bankConnectHeader: {
    id: 'ApplyOnline.bankConnectHeader',
    defaultMessage: 'Connect with your bank',
  },
  bankConnectDescription: {
    id: 'ApplyOnline.bankConnectDescription',
    defaultMessage:
      'Make entering your financial info and uploading statements easy',
  },
  history: {
    id: 'ApplyOnline.history',
    defaultMessage: 'History',
  },
  saveDraft: {
    id: 'ApplyOnline.saveDraft',
    defaultMessage: 'Save draft and go to dashboard',
  },
  applyForPreapproval: {
    id: 'ApplyOnline.applyForPreapproval',
    defaultMessage: 'I’m ready - Send for review',
  },
  applyForPreapprovalLocked: {
    id: 'ApplyOnline.applyForPreapprovalLocked',
    defaultMessage: 'View application progress',
  },
  supportingInformation: {
    id: 'ApplyOnline.supportingInformation',
    defaultMessage: 'Supporting Information',
  },
  uploadSupportingDocuments: {
    id: 'ApplyOnline.uploadSupportingDocuments',
    defaultMessage: 'Upload supporting documents',
  },
  locked: {
    id: 'ApplyOnline.locked',
    defaultMessage:
      'Your application is almost ready to be lodged so we’ve switched it to read only mode to ensure no accidental changes.',
  },
  hasOldInformation: {
    id: 'ApplyOnline.hasOldInformation',
    defaultMessage:
      "This information is more than 90 days old. Please confirm it's still correct.",
  },
  hasSomeOldInformation: {
    id: 'ApplyOnline.hasSomeOldInformation',
    defaultMessage:
      "Some of your information is more than 90 days old. Please confirm it's still correct.",
  },
  preferences: {
    id: 'ApplyOnline.preferences',
    defaultMessage: 'Preferences',
  },
  lenders: {
    id: 'ApplyOnline.lenders',
    defaultMessage: 'Lenders',
  },
  lendersDescription: {
    id: 'ApplyOnline.lendersDescription',
    defaultMessage: 'Your lender preferences',
  },
  loanFeatures: {
    id: 'ApplyOnline.loanFeatures',
    defaultMessage: 'Loan Features',
  },
  loanFeaturesDescription: {
    id: 'ApplyOnline.loanFeaturesDescription',
    defaultMessage: 'Your loan feature preferences',
  },
  lookingAhead: {
    id: 'ApplyOnline.lookingAhead',
    defaultMessage: 'Looking Ahead',
  },
  futurePlans: {
    id: 'ApplyOnline.futurePlans',
    defaultMessage: '{familyName}’s Future Plans',
  },
  futurePlansDescription: {
    id: 'ApplyOnline.futurePlansDescription',
    defaultMessage: 'Check you’re all set for the future',
  },
  futurePlansCompletedDesc: {
    id: 'ApplyOnline.futurePlansCompletedDesc',
    defaultMessage: 'Your plans for the future',
  },
  services: {
    id: 'ApplyOnline.services',
    defaultMessage: 'Services',
  },
  servicesDescription: {
    id: 'ApplyOnline.servicesDescription',
    defaultMessage: 'Let’s talk about your team',
  },
});

const isAbsolutePopup = absolutePopup();

export class ApplyOnline extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    match: PropTypes.object,
    location: PropTypes.object,
    handhold: PropTypes.bool,
    isSpinnerLoading: PropTypes.bool,
    ready: PropTypes.bool.isRequired,
    applicationId: PropTypes.number.isRequired,
    isSharedApplication: PropTypes.bool,
    primaryContact: PropTypes.object,
    primaryApplicant: PropTypes.object,
    primaryApplicantsPartner: PropTypes.object,
    readLoanApplication: PropTypes.func.isRequired,
    setNextPath: PropTypes.func.isRequired,
    startAnimationSequence: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    isLocked: PropTypes.bool.isRequired,
    updateLoanApplication: PropTypes.func.isRequired,
    sendLoanApplicationForReview: PropTypes.func.isRequired,
    setMetadata: PropTypes.func.isRequired,
    metadata: PropTypes.object,
    completedSections: PropTypes.object,
    percentageCompleted: PropTypes.number,
    requests: PropTypes.arrayOf(PropTypes.object).isRequired,
    requestErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
    nextSequence: PropTypes.arrayOf(PropTypes.string),
    pushSpinner: PropTypes.func.isRequired,
    popSpinner: PropTypes.func.isRequired,
    setPageError: PropTypes.func.isRequired,
    clearAsyncRequestErrors: PropTypes.func.isRequired,
    clearHandholdSections: PropTypes.func.isRequired,
    showFullhandholdProgress: PropTypes.bool,
    fullHandholdProgress: PropTypes.number,
    hasOldInformation: PropTypes.bool,
    hasOldAssetInformation: PropTypes.bool,
    hasOldLiabilityInformation: PropTypes.bool,
    hasOldExpenseInformation: PropTypes.bool,
    hasOldIncomeInformation: PropTypes.bool,
    hasOldInformationMap: PropTypes.object,
    showProgress: PropTypes.bool,
    progress: PropTypes.number,
    loanApplicationId: PropTypes.number,
    lendersSectionText: PropTypes.string,
    loanOptionsSectionText: PropTypes.string,
    families: PropTypes.arrayOf(PropTypes.object),
    futurePlansCompleted: PropTypes.object,
    servicesCompleted: PropTypes.bool,
  };

  static defaultProps = {
    metadata: {},
    handhold: false,
    isSpinnerLoading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      openAccordion: null,
    };
  }

  componentDidMount = () => {
    const { readLoanApplication } = this.props;
    if (LocalStorageProxy.token) {
      readLoanApplication();
    }

    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => {
    this.props.clearHandholdSections();
  };

  componentDidUpdate = (prevProps) => {
    const { updateLoanApplication, applicationId, metadata } = this.props;
    const hasOldAssetInfoUpdated =
      !this.props.hasOldAssetInformation && prevProps.hasOldAssetInformation;
    const hasOldLiabilityInfoUpdated =
      !this.props.hasOldLiabilityInformation &&
      prevProps.hasOldLiabilityInformation;
    const hasOldExpenseInfoUpdated =
      !this.props.hasOldExpenseInformation &&
      prevProps.hasOldExpenseInformation;
    const hasOldIncomeInfoUpdated =
      !this.props.hasOldIncomeInformation && prevProps.hasOldIncomeInformation;
    if (this.props.ready && !prevProps.ready) {
      window.scrollTo(0, 0);
    }
    if (
      hasOldAssetInfoUpdated ||
      hasOldLiabilityInfoUpdated ||
      hasOldExpenseInfoUpdated ||
      hasOldIncomeInfoUpdated
    ) {
      updateLoanApplication({ id: applicationId, metadata });
    }
  };

  getTitle() {
    const {
      intl: { formatMessage },
      isLocked,
      percentageCompleted,
      isSpinnerLoading,
      primaryApplicant,
    } = this.props;
    if (isLocked) {
      return formatMessage(messages.titleForLockedApplication);
    }
    return formatMessage(
      isSpinnerLoading ? messages.titleLoading : messages.title,
      {
        name: LocalStorageProxy.loginAsClientId
          ? `${primaryApplicant?.firstName}’s`
          : 'Your',
        percentageCompleted,
      },
    );
  }

  getSubtitle() {
    const {
      intl: { formatMessage },
      isSharedApplication,
      isLocked,
    } = this.props;
    if (isLocked || !isSharedApplication) {
      return [];
    }
    return [formatMessage(messages.subtitleForSharedApplication)];
  }

  animateToNextPath(nextPath, animationSequence) {
    const { setNextPath, startAnimationSequence } = this.props;
    setNextPath(nextPath);
    startAnimationSequence(animationSequence);
  }

  handleSubmit = (e) => {
    const {
      applicationId,
      isLocked,
      sendLoanApplicationForReview,
    } = this.props;

    e.preventDefault();
    logEvent(EVENTS.OPEN_SECTION, { section: 'sendForReview' });
    const nextPath = `${APPLICATION_PROGRESS_BASE_PATH}/${applicationId}`;

    sendLoanApplicationForReview();

    if (isLocked) {
      this.props.push(nextPath);
    } else {
      this.animateToNextPath(nextPath, [
        '/alright',
        '/send-for-review',
        '/will-find-options',
        '/expect-a-call',
      ]);
    }
  };

  handleSaveDraft = (e) => {
    const { isLocked } = this.props;
    e.preventDefault();

    const nextPath = DASHBOARD_BASE_PATH;
    if (isLocked) {
      this.props.push(nextPath);
    } else {
      coreLogger('ApplyOnline').info({ action: 'navigate to save draft' });
      this.animateToNextPath(nextPath, ['/save-draft']);
    }
  };

  render() {
    const {
      match: {
        params: { section },
        isExact,
      },
      intl: { formatMessage },
      handhold,
      isSpinnerLoading,
      ready,
      showProgress,
      progress,
      isLocked,
      hasOldInformation,
      loanApplicationId,
      lendersSectionText,
      loanOptionsSectionText,
      families,
      futurePlansCompleted,
      servicesCompleted,
      location,
    } = this.props;

    const openAccordion = section;
    const loanAmountOn = featureOn(LOAN_AMOUNT);

    return (
      <View
        inverseHeader
        showNavigation={!handhold}
        hide={isAbsolutePopup && !isExact}
      >
        <Spinner loading={isSpinnerLoading}>
          <ContentsWrapper
            id='applyOnline'
            title={this.getTitle()}
            subtitles={this.getSubtitle()}
            showLightbox={!!openAccordion}
            animateHeading={false}
            inverseHeader
            warningMessage={
              hasOldInformation
                ? formatMessage(messages.hasSomeOldInformation)
                : ''
            }
            isLocked={isLocked}
          >
            {ready && (
              <ApplyOnlineContent
                {...this.props}
                messages={messages}
                formatMessage={formatMessage}
                section={section}
                isExact={isExact}
                showProgress={showProgress}
                progress={progress}
                urlPath={`/apply/${loanApplicationId}`}
                loanAmountOn={loanAmountOn}
                lendersSectionText={lendersSectionText}
                loanOptionsSectionText={loanOptionsSectionText}
                families={families}
                futurePlansCompleted={futurePlansCompleted}
                servicesCompleted={servicesCompleted}
                location={location}
              />
            )}
            <br />
            {!handhold && (
              <ButtonNext
                id='next'
                onClick={this.handleSubmit}
                disabled={!!openAccordion}
              >
                {formatMessage(
                  messages[`applyForPreapproval${isLocked ? 'Locked' : ''}`],
                )}
              </ButtonNext>
            )}
            {!isLocked && !handhold && (
              <Button
                onClick={this.handleSaveDraft}
                theme='noBorder'
                className='margin-0 brandColor__link'
                disabled={!!openAccordion}
              >
                {formatMessage(messages.saveDraft)}
              </Button>
            )}
          </ContentsWrapper>
        </Spinner>
      </View>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const loanApplicationId = parseInt(
    ownProps.match.params.loanApplicationId,
    10,
  );
  const application = applicationSelectors.workingApplication(state);
  const primaryApplicant =
    application && clientSelectors.primaryApplicant(state);
  const primaryApplicantsPartner =
    primaryApplicant && clientSelectors.primaryApplicantsPartner(state);
  const primaryContact = contactSelectors.primaryContact(state);
  const handhold = UISelectors.isHandholdOn(state);
  const hasOldAssetInformation = assetSelectors.hasOldInformation(state);
  const hasOldLiabilityInformation = liabilitySelectors.hasOldInformation(
    state,
  );
  const hasOldExpenseInformation = expenseSelectors.hasOldInformation(state);
  const hasOldIncomeInformation = incomeSelectors.hasOldInformation(state);
  const hasOldInformation =
    hasOldAssetInformation ||
    hasOldLiabilityInformation ||
    hasOldExpenseInformation ||
    hasOldIncomeInformation;
  const hasOldInformationMap = {
    assets: hasOldAssetInformation,
    liabilities: hasOldLiabilityInformation,
    expenses: hasOldExpenseInformation,
    incomes: hasOldIncomeInformation,
  };
  const ready = handhold
    ? application && primaryContact
    : application && primaryContact && primaryApplicant;

  return {
    requests: UISelectors.requests(state),
    requestErrors: UISelectors.requestErrors(state),
    pageError: state.UISettings.pageError,
    isSpinnerLoading: UISelectors.hasActiveSpinners(state),
    nextSequence: state.UISettings.nextHandholdSequence,
    showFullhandholdProgress: state.UISettings.isFullHandhold,
    fullHandholdProgress: UISelectors.fullHandholdProgress(state),
    applicationId: application.id,
    metadata: application.metadata,
    isSharedApplication: applicationSelectors.isSharedApplication(state),
    completedSections: completionSelectors.completedSections(state),
    percentageCompleted: completionSelectors.percentageCompleted(state),
    primaryContact,
    primaryApplicant,
    primaryApplicantsPartner,
    ready: !!ready,
    employmentErrors: state.employment.errors[loanApplicationId],
    incomeErrors: state.income.errors[loanApplicationId],
    assetErrors: state.asset.errors[loanApplicationId],
    liabilityErrors: state.liability.errors[loanApplicationId],
    propertyErrors: state.property.errors[loanApplicationId],
    isLocked: applicationSelectors.getIsLocked(state),
    hasOldInformation,
    hasOldAssetInformation,
    hasOldLiabilityInformation,
    hasOldExpenseInformation,
    hasOldIncomeInformation,
    hasOldInformationMap,
    loanApplicationId,
    lendersSectionText: goalLoanApplicationSelectors.lendersSectionText(state),
    loanOptionsSectionText: goalLoanApplicationSelectors.preferencesSectionText(
      state,
    ),
    families: clientSelectors.families(state) || [],
    futurePlansCompleted: goalLoanApplicationSelectors.futurePlansCompleted(
      state,
    ),
    servicesCompleted: goalLoanApplicationSelectors.servicesCompleted(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readLoanApplication: loanApplicationActions.readLoanApplication,
      updateLoanApplication: loanApplicationActions.updateLoanApplication,
      sendLoanApplicationForReview:
        loanApplicationActions.sendLoanApplicationForReview,
      setMetadata: loanApplicationActions.setMetadata,
      setNextPath: UIActions.setNextPath,
      startAnimationSequence: UIActions.startAnimationSequence,
      pushSpinner: UIActions.pushSpinner,
      popSpinner: UIActions.popSpinner,
      setPageError: UIActions.setPageError,
      clearAsyncRequestErrors: UIActions.clearAsyncRequestErrors,
      clearHandholdSections: UIActions.clearHandholdSections,
      push,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ApplyOnline),
);
