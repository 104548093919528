import { setupLogger, getLogger, LogLevel } from '@loanmarket/logger-core';
import { CloudWatchLogger } from '@loanmarket/logger-cloud-watch';
import { cloudWatchKeyFunctions } from '@loanmarket/logger-cloud-watch/lib/utils/browser-fingerprint';
import { parse } from 'error-stack-parser';
import bowser from 'bowser';

import { generateUuid } from 'shared/lib/utils';
import loggerSession from 'lib/loggerSession';

export const DEFAULT_CONFIG = { minLogLevel: LogLevel.Trace };
export const CLOUDWATCH_CONFIG = {
  minLogLevel: LogLevel.Info,
  logger: CloudWatchLogger,
  customLoggerArgs: {
    group: process.env.LOGGER_GROUP,
    userAgent: window.navigator.userAgent,
    cloudWatchConfiguration: {
      accessKeyId: process.env.LOGGER_ACCESS_KEY_ID,
      secretAccessKey: process.env.LOGGER_SECRET_ACCESS_KEY,
      region: process.env.LOGGER_REGION,
      correctClockSkew: true,
    },
    maxRetries: 3,
    keyFunctions: cloudWatchKeyFunctions,
    newLogStreamPerRequest: true,
  },
};

export const initLogger = (config = DEFAULT_CONFIG) => {
  if (process.env.LOGGER_ACCESS_KEY_ID) {
    setupLogger(CLOUDWATCH_CONFIG);
  } else {
    setupLogger(config);
  }
};

export const generateLoggerSessionData = (otherSessionData) => {
  const { connection } = window.navigator;
  const existingSessionData = loggerSession.getData();
  const sessionId = existingSessionData.sessionId || generateUuid();
  const userAgent = {
    browser: {
      name: bowser.name,
      version: bowser.version,
    },
    os: {
      name: bowser.osname,
      version: bowser.osversion,
    },
  };
  const bandwidth = connection && {
    downlink: connection.downlink,
    effectiveType: connection.effectiveType,
  };
  return otherSessionData
    ? {
        ...existingSessionData,
        ...otherSessionData,
        sessionId,
        userAgent,
        bandwidth,
      }
    : { sessionId, userAgent, bandwidth };
};

export const setLoggerSessionData = (data) => {
  loggerSession.setData(generateLoggerSessionData(data));
};

const getIdentifiableLogData = (logData = {}) => {
  let errorStack = logData.errorStack;
  if (errorStack && errorStack instanceof Error) {
    try {
      errorStack = parse(errorStack);
    } catch (error) {
      errorStack = logData.errorStack;
    }
  }
  return JSON.stringify({
    ...loggerSession.getData(),
    ...logData,
    errorStack,
  });
};
export const logger = (name) => {
  const namedLogger = getLogger(name);
  return {
    info: (logData) => {
      namedLogger.info(getIdentifiableLogData(logData));
    },
    warn: (logData) => {
      namedLogger.warn(getIdentifiableLogData(logData));
    },
    error: (logData) => {
      namedLogger.error(getIdentifiableLogData(logData));
    },
  };
};
