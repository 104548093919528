import { createSelector } from 'reselect';
import _ from 'lodash';

import { REQUEST_USER_INFO } from 'actions/scenarioActionTypes';
import { REQUEST_ADVISOR_INFO } from 'actions/advisorActionTypes';
import { UPDATE_LOAN_APPLICATION } from 'actions/loanApplicationActionTypes';
import { LOAD_GOAL_LOAN_APPLICATION } from 'actions/goal/goalLoanApplicationActions';
import {
  FULL_HANDHOLD_CUTS,
  FULL_HANDHOLD_LAST_POSITION,
} from 'constants/applyData';
import {
  PROFILE_SECTION_CATEGORIES,
  GOAL_SETTER_PROFILE_CATEGORIES,
} from 'constants/profileSections';
import { featureFlags } from 'lib/rollout';

export const nextPath = (state) => state.UISettings.nextPath;
export const requests = (state) => state.UISettings.requests;
export const sequence = (state) => state.UISettings.animationSequence;
export const nextSequence = (state) => state.UISettings.nextAnimationSequence;
export const hasActiveSpinners = (state) => !!state.UISettings.spinners.length;
export const isHandholdOn = (state) =>
  !!(
    state.UISettings.handholdSections &&
    state.UISettings.handholdSections.length > 0
  );
export const isApplicationTested = (state) =>
  !!state.UISettings.applicationTested;
export const applicationIsRead = (state) => state.UISettings.applicationIsRead;
export const isOneTimePrivacyPolicy = (state) =>
  state.UISettings.isOneTimePrivacyPolicy;
export const handholdSections = (state) => state.UISettings.handholdSections;
export const partialProfileSections = (state) =>
  state.UISettings.partialProfileSections;

export const requestProcessing = createSelector(requests, (a) =>
  _.memoize((id) => !!a.find((r) => r.id === id && !r.error)),
);

export const requestErrors = createSelector(requests, (a) =>
  a.filter((r) => r.error),
);

export const fetchingUserInfo = createSelector(
  requests,
  (a) => !!a.find((r) => r.type === REQUEST_USER_INFO),
);

export const updatingLoanApp = createSelector(
  requests,
  (a) => !!a.find((r) => r.type === UPDATE_LOAN_APPLICATION),
);

export const loadingGoalLoanApp = createSelector(
  requests,
  (a) => !!a.find((r) => r.type === LOAD_GOAL_LOAN_APPLICATION),
);

export const advisorFetchError = createSelector(requests, (a) => {
  const request = a.find((r) => r.type === REQUEST_ADVISOR_INFO);
  return request && request.error;
});

export const fullHandholdProgress = createSelector(handholdSections, (a) => {
  const currentPosition = FULL_HANDHOLD_LAST_POSITION - a.length;
  const higherRangeIndex = FULL_HANDHOLD_CUTS.findIndex(
    (cut) => cut >= currentPosition,
  );
  if (higherRangeIndex < 0) {
    return 0;
  }

  const higherRange = FULL_HANDHOLD_CUTS[higherRangeIndex];
  return Math.round((currentPosition / higherRange) * 100);
});

export const isProfileSectionVisible = createSelector(
  partialProfileSections,
  (sections) =>
    _.memoize((section) => {
      return !sections || sections.length === 0 || sections.includes(section);
    }),
);

export const isProfileCategoryVisible = createSelector(
  partialProfileSections,
  (sections) =>
    _.memoize((category) => {
      if (
        !featureFlags.goalSetterFactFindMerge.isEnabled() &&
        GOAL_SETTER_PROFILE_CATEGORIES.includes(category)
      ) {
        return false;
      }
      return (
        !sections ||
        sections.length === 0 ||
        !!PROFILE_SECTION_CATEGORIES[category].find((s) => sections.includes(s))
      );
    }),
);
