import { createStore, applyMiddleware, compose } from 'redux';
import { getMiddlewares, sagaMiddleware } from 'middleware/middleware';
import reducers from 'reducers/reducers';
import sagas from 'sagas/sagas';

export default (middleware) => {
  const composeEnhancers =
    typeof window === 'object' &&
    process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(
    applyMiddleware(...getMiddlewares(middleware)),
  );

  const createStoreWithMiddleware = enhancer(createStore);

  const storeWithMiddleware = createStoreWithMiddleware(reducers);

  sagaMiddleware.run(sagas);

  return storeWithMiddleware;
};
