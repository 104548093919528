/* eslint-disable sonarjs/no-duplicate-string */
export const COUNTRY_NEW_ZEALAND = {
  id: 1,
  region: 'Australasia',
  countryCode: 'NZ',
  name: 'New Zealand',
};

export const COUNTRY_AUSTRALIA = {
  id: 2,
  region: 'Australasia',
  countryCode: 'AU',
  name: 'Australia',
};

export const COUNTRIES = [
  COUNTRY_AUSTRALIA,
  COUNTRY_NEW_ZEALAND,
  {
    id: 3,
    region: 'Asia',
    countryCode: 'ID',
    name: 'Indonesia',
  },
  {
    id: 4,
    region: 'Asia',
    countryCode: 'AF',
    name: 'Afghanistan ',
  },
  {
    id: 5,
    region: 'Europe',
    countryCode: 'AL',
    name: 'Albania ',
  },
  {
    id: 6,
    region: 'Africa',
    countryCode: 'DZ',
    name: 'Algeria ',
  },
  {
    id: 7,
    region: 'Australasia',
    countryCode: 'AS',
    name: 'American Samoa ',
  },
  {
    id: 8,
    region: 'Europe',
    countryCode: 'AD',
    name: 'Andorra ',
  },
  {
    id: 9,
    region: 'Africa',
    countryCode: 'AO',
    name: 'Angola ',
  },
  {
    id: 10,
    region: 'Caribbean',
    countryCode: 'AI',
    name: 'Anguilla ',
  },
  {
    id: 11,
    region: 'Caribbean',
    countryCode: 'AG',
    name: 'Antigua and Barbuda ',
  },
  {
    id: 12,
    region: 'South America',
    countryCode: 'AR',
    name: 'Argentina ',
  },
  {
    id: 13,
    region: 'Europe',
    countryCode: 'AM',
    name: 'Armenia ',
  },
  {
    id: 14,
    region: 'Caribbean',
    countryCode: 'AW',
    name: 'Aruba ',
  },
  {
    id: 15,
    region: 'Europe',
    countryCode: 'AT',
    name: 'Austria ',
  },
  {
    id: 16,
    region: 'Europe',
    countryCode: 'AZ',
    name: 'Azerbaijan ',
  },
  {
    id: 17,
    region: 'Caribbean',
    countryCode: 'BS',
    name: 'Bahamas ',
  },
  {
    id: 18,
    region: 'Middle East',
    countryCode: 'BH',
    name: 'Bahrain ',
  },
  {
    id: 19,
    region: 'Asia',
    countryCode: 'BD',
    name: 'Bangladesh ',
  },
  {
    id: 20,
    region: 'Caribbean',
    countryCode: 'BB',
    name: 'Barbados ',
  },
  {
    id: 21,
    region: 'Europe',
    countryCode: 'BY',
    name: 'Belarus ',
  },
  {
    id: 22,
    region: 'Europe',
    countryCode: 'BE',
    name: 'Belgium ',
  },
  {
    id: 23,
    region: 'North America',
    countryCode: 'BZ',
    name: 'Belize ',
  },
  {
    id: 24,
    region: 'Africa',
    countryCode: 'BJ',
    name: 'Benin ',
  },
  {
    id: 25,
    region: 'Caribbean',
    countryCode: 'BM',
    name: 'Bermuda ',
  },
  {
    id: 26,
    region: 'Asia',
    countryCode: 'BT',
    name: 'Bhutan ',
  },
  {
    id: 27,
    region: 'South America',
    countryCode: 'BO',
    name: 'Bolivia ',
  },
  {
    id: 28,
    region: 'Europe',
    countryCode: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    id: 29,
    region: 'Africa',
    countryCode: 'BW',
    name: 'Botswana ',
  },
  {
    id: 30,
    region: 'Africa',
    countryCode: 'BV',
    name: 'Bouvet Island ',
  },
  {
    id: 31,
    region: 'South America',
    countryCode: 'BR',
    name: 'Brazil ',
  },
  {
    id: 32,
    region: 'Asia',
    countryCode: 'BN',
    name: 'Brunei ',
  },
  {
    id: 33,
    region: 'Europe',
    countryCode: 'BG',
    name: 'Bulgaria ',
  },
  {
    id: 34,
    region: 'Africa',
    countryCode: 'BF',
    name: 'Burkina Faso ',
  },
  {
    id: 35,
    region: 'Africa',
    countryCode: 'BI',
    name: 'Burundi ',
  },
  {
    id: 36,
    region: 'Asia',
    countryCode: 'KH',
    name: 'Cambodia ',
  },
  {
    id: 37,
    region: 'Africa',
    countryCode: 'CM',
    name: 'Cameroon ',
  },
  {
    id: 38,
    region: 'North America',
    countryCode: 'CA',
    name: 'Canada ',
  },
  {
    id: 39,
    region: 'Africa',
    countryCode: 'CV',
    name: 'Cape Verde ',
  },
  {
    id: 40,
    region: 'Caribbean',
    countryCode: 'KY',
    name: 'Cayman Islands ',
  },
  {
    id: 41,
    region: 'Africa',
    countryCode: 'CF',
    name: 'Central African Republic ',
  },
  {
    id: 42,
    region: 'Africa',
    countryCode: 'TD',
    name: 'Chad ',
  },
  {
    id: 43,
    region: 'South America',
    countryCode: 'CL',
    name: 'Chile ',
  },
  {
    id: 44,
    region: 'Asia',
    countryCode: 'CN',
    name: 'China ',
  },
  {
    id: 45,
    region: 'Australasia',
    countryCode: 'CX',
    name: 'Christmas Island ',
  },
  {
    id: 46,
    region: 'Australasia',
    countryCode: 'CC',
    name: 'Cocos (Keeling) Islands ',
  },
  {
    id: 47,
    region: 'South America',
    countryCode: 'CO',
    name: 'Colombia ',
  },
  {
    id: 48,
    region: 'Africa',
    countryCode: 'KM',
    name: 'Comoros ',
  },
  {
    id: 49,
    region: 'Africa',
    countryCode: 'CG',
    name: 'Congo, Democratic Republic of the (Zaire) ',
  },
  {
    id: 50,
    region: 'Africa',
    countryCode: 'CD',
    name: 'Congo, Republic of ',
  },
  {
    id: 51,
    region: 'Australasia',
    countryCode: 'CK',
    name: 'Cook Islands ',
  },
  {
    id: 52,
    region: 'North America',
    countryCode: 'CR',
    name: 'Costa Rica ',
  },
  {
    id: 53,
    region: 'Europe',
    countryCode: 'HR',
    name: 'Croatia ',
  },
  {
    id: 54,
    region: 'Caribbean',
    countryCode: 'CU',
    name: 'Cuba ',
  },
  {
    id: 55,
    region: 'Europe',
    countryCode: 'CY',
    name: 'Cyprus ',
  },
  {
    id: 56,
    region: 'Europe',
    countryCode: 'CZ',
    name: 'Czech Republic ',
  },
  {
    id: 57,
    region: 'Europe',
    countryCode: 'DK',
    name: 'Denmark ',
  },
  {
    id: 58,
    region: 'Africa',
    countryCode: 'DJ',
    name: 'Djibouti ',
  },
  {
    id: 59,
    region: 'Caribbean',
    countryCode: 'DM',
    name: 'Dominica ',
  },
  {
    id: 60,
    region: 'Caribbean',
    countryCode: 'DO',
    name: 'Dominican Republic ',
  },
  {
    id: 61,
    region: 'South America',
    countryCode: 'EC',
    name: 'Ecuador ',
  },
  {
    id: 62,
    region: 'Africa',
    countryCode: 'EG',
    name: 'Egypt ',
  },
  {
    id: 63,
    region: 'North America',
    countryCode: 'SV',
    name: 'El Salvador ',
  },
  {
    id: 64,
    region: 'Africa',
    countryCode: 'GQ',
    name: 'Equatorial Guinea ',
  },
  {
    id: 65,
    region: 'Africa',
    countryCode: 'ER',
    name: 'Eritrea ',
  },
  {
    id: 66,
    region: 'Europe',
    countryCode: 'EE',
    name: 'Estonia ',
  },
  {
    id: 67,
    region: 'Africa',
    countryCode: 'ET',
    name: 'Ethiopia ',
  },
  {
    id: 68,
    region: 'South America',
    countryCode: 'FK',
    name: 'Falkland Islands ',
  },
  {
    id: 69,
    region: 'Europe',
    countryCode: 'FO',
    name: 'Faroe Islands ',
  },
  {
    id: 70,
    region: 'Australasia',
    countryCode: 'FJ',
    name: 'Fiji ',
  },
  {
    id: 71,
    region: 'Europe',
    countryCode: 'FI',
    name: 'Finland ',
  },
  {
    id: 72,
    region: 'Europe',
    countryCode: 'FR',
    name: 'France ',
  },
  {
    id: 73,
    region: 'South America',
    countryCode: 'GF',
    name: 'French Guiana ',
  },
  {
    id: 74,
    region: 'Africa',
    countryCode: 'GA',
    name: 'Gabon ',
  },
  {
    id: 75,
    region: 'Africa',
    countryCode: 'GM',
    name: 'Gambia ',
  },
  {
    id: 76,
    region: 'Europe',
    countryCode: 'GE',
    name: 'Georgia ',
  },
  {
    id: 77,
    region: 'Europe',
    countryCode: 'DE',
    name: 'Germany ',
  },
  {
    id: 78,
    region: 'Africa',
    countryCode: 'GH',
    name: 'Ghana ',
  },
  {
    id: 79,
    region: 'Europe',
    countryCode: 'GI',
    name: 'Gibraltar ',
  },
  {
    id: 80,
    region: 'Europe',
    countryCode: 'GR',
    name: 'Greece ',
  },
  {
    id: 81,
    region: 'Europe',
    countryCode: 'GL',
    name: 'Greenland ',
  },
  {
    id: 82,
    region: 'Caribbean',
    countryCode: 'GD',
    name: 'Grenada ',
  },
  {
    id: 83,
    region: 'Caribbean',
    countryCode: 'GP',
    name: 'Guadeloupe (French) ',
  },
  {
    id: 84,
    region: 'Australasia',
    countryCode: 'GU',
    name: 'Guam (USA) ',
  },
  {
    id: 85,
    region: 'North America',
    countryCode: 'GT',
    name: 'Guatemala ',
  },
  {
    id: 86,
    region: 'Africa',
    countryCode: 'GN',
    name: 'Guinea ',
  },
  {
    id: 87,
    region: 'Africa',
    countryCode: 'GW',
    name: 'Guinea Bissau ',
  },
  {
    id: 88,
    region: 'South America',
    countryCode: 'GY',
    name: 'Guyana ',
  },
  {
    id: 89,
    region: 'Caribbean',
    countryCode: 'HT',
    name: 'Haiti ',
  },
  {
    id: 91,
    region: 'North America',
    countryCode: 'HN',
    name: 'Honduras ',
  },
  {
    id: 92,
    region: 'Asia',
    countryCode: 'HK',
    name: 'Hong Kong ',
  },
  {
    id: 93,
    region: 'Europe',
    countryCode: 'HU',
    name: 'Hungary ',
  },
  {
    id: 94,
    region: 'Europe',
    countryCode: 'IS',
    name: 'Iceland ',
  },
  {
    id: 95,
    region: 'Asia',
    countryCode: 'IN',
    name: 'India ',
  },
  {
    id: 96,
    region: 'Middle East',
    countryCode: 'IR',
    name: 'Iran ',
  },
  {
    id: 97,
    region: 'Middle East',
    countryCode: 'IQ',
    name: 'Iraq ',
  },
  {
    id: 98,
    region: 'Europe',
    countryCode: 'IE',
    name: 'Ireland ',
  },
  {
    id: 99,
    region: 'Middle East',
    countryCode: 'IL',
    name: 'Israel ',
  },
  {
    id: 100,
    region: 'Europe',
    countryCode: 'IT',
    name: 'Italy ',
  },
  {
    id: 101,
    region: 'Africa',
    countryCode: 'CI',
    name: 'Ivory Coast (Cote D`Ivoire) ',
  },
  {
    id: 102,
    region: 'Caribbean',
    countryCode: 'JM',
    name: 'Jamaica ',
  },
  {
    id: 103,
    region: 'Asia',
    countryCode: 'JP',
    name: 'Japan ',
  },
  {
    id: 104,
    region: 'Middle East',
    countryCode: 'JO',
    name: 'Jordan ',
  },
  {
    id: 105,
    region: 'Asia',
    countryCode: 'KZ',
    name: 'Kazakhstan ',
  },
  {
    id: 106,
    region: 'Africa',
    countryCode: 'KE',
    name: 'Kenya ',
  },
  {
    id: 107,
    region: 'Australasia',
    countryCode: 'KI',
    name: 'Kiribati ',
  },
  {
    id: 108,
    region: 'Middle East',
    countryCode: 'KW',
    name: 'Kuwait ',
  },
  {
    id: 109,
    region: 'Asia',
    countryCode: 'KG',
    name: 'Kyrgyzstan ',
  },
  {
    id: 110,
    region: 'Asia',
    countryCode: 'LA',
    name: 'Laos ',
  },
  {
    id: 111,
    region: 'Europe',
    countryCode: 'LV',
    name: 'Latvia ',
  },
  {
    id: 112,
    region: 'Middle East',
    countryCode: 'LB',
    name: 'Lebanon ',
  },
  {
    id: 113,
    region: 'Africa',
    countryCode: 'LS',
    name: 'Lesotho ',
  },
  {
    id: 114,
    region: 'Africa',
    countryCode: 'LR',
    name: 'Liberia ',
  },
  {
    id: 115,
    region: 'Africa',
    countryCode: 'LY',
    name: 'Libya ',
  },
  {
    id: 116,
    region: 'Europe',
    countryCode: 'LI',
    name: 'Liechtenstein ',
  },
  {
    id: 117,
    region: 'Europe',
    countryCode: 'LT',
    name: 'Lithuania ',
  },
  {
    id: 118,
    region: 'Europe',
    countryCode: 'LU',
    name: 'Luxembourg ',
  },
  {
    id: 119,
    region: 'Asia',
    countryCode: 'MO',
    name: 'Macau ',
  },
  {
    id: 120,
    region: 'Europe',
    countryCode: 'MK',
    name: 'Macedonia ',
  },
  {
    id: 121,
    region: 'Africa',
    countryCode: 'MG',
    name: 'Madagascar ',
  },
  {
    id: 122,
    region: 'Africa',
    countryCode: 'MW',
    name: 'Malawi ',
  },
  {
    id: 123,
    region: 'Asia',
    countryCode: 'MY',
    name: 'Malaysia ',
  },
  {
    id: 124,
    region: 'Asia',
    countryCode: 'MV',
    name: 'Maldives ',
  },
  {
    id: 125,
    region: 'Africa',
    countryCode: 'ML',
    name: 'Mali ',
  },
  {
    id: 126,
    region: 'Europe',
    countryCode: 'MT',
    name: 'Malta ',
  },
  {
    id: 127,
    region: 'Australasia',
    countryCode: 'MH',
    name: 'Marshall Islands ',
  },
  {
    id: 128,
    region: 'Caribbean',
    countryCode: 'MQ',
    name: 'Martinique (French) ',
  },
  {
    id: 129,
    region: 'Africa',
    countryCode: 'MR',
    name: 'Mauritania ',
  },
  {
    id: 130,
    region: 'Africa',
    countryCode: 'MU',
    name: 'Mauritius ',
  },
  {
    id: 131,
    region: 'Africa',
    countryCode: 'YT',
    name: 'Mayotte ',
  },
  {
    id: 132,
    region: 'North America',
    countryCode: 'MX',
    name: 'Mexico ',
  },
  {
    id: 133,
    region: 'Australasia',
    countryCode: 'FM',
    name: 'Micronesia ',
  },
  {
    id: 134,
    region: 'Europe',
    countryCode: 'MD',
    name: 'Moldova ',
  },
  {
    id: 135,
    region: 'Europe',
    countryCode: 'MC',
    name: 'Monaco ',
  },
  {
    id: 136,
    region: 'Asia',
    countryCode: 'MN',
    name: 'Mongolia ',
  },
  {
    id: 137,
    region: 'Europe',
    countryCode: 'ME',
    name: 'Montenegro ',
  },
  {
    id: 138,
    region: 'Caribbean',
    countryCode: 'MS',
    name: 'Montserrat ',
  },
  {
    id: 139,
    region: 'Africa',
    countryCode: 'MA',
    name: 'Morocco ',
  },
  {
    id: 140,
    region: 'Africa',
    countryCode: 'MZ',
    name: 'Mozambique ',
  },
  {
    id: 141,
    region: 'Asia',
    countryCode: 'MM',
    name: 'Myanmar ',
  },
  {
    id: 142,
    region: 'Africa',
    countryCode: 'NA',
    name: 'Namibia ',
  },
  {
    id: 143,
    region: 'Australasia',
    countryCode: 'NR',
    name: 'Nauru ',
  },
  {
    id: 144,
    region: 'Asia',
    countryCode: 'NP',
    name: 'Nepal ',
  },
  {
    id: 145,
    region: 'Europe',
    countryCode: 'NL',
    name: 'Netherlands ',
  },
  {
    id: 146,
    region: 'Caribbean',
    countryCode: 'AN',
    name: 'Netherlands Antilles ',
  },
  {
    id: 147,
    region: 'Australasia',
    countryCode: 'NC',
    name: 'New Caledonia (French) ',
  },
  {
    id: 148,
    region: 'North America',
    countryCode: 'NI',
    name: 'Nicaragua ',
  },
  {
    id: 149,
    region: 'Africa',
    countryCode: 'NE',
    name: 'Niger ',
  },
  {
    id: 150,
    region: 'Africa',
    countryCode: 'NG',
    name: 'Nigeria ',
  },
  {
    id: 151,
    region: 'Australasia',
    countryCode: 'NU',
    name: 'Niue ',
  },
  {
    id: 152,
    region: 'Australasia',
    countryCode: 'NF',
    name: 'Norfolk Island ',
  },
  {
    id: 153,
    region: 'Asia',
    countryCode: 'KP',
    name: 'North Korea ',
  },
  {
    id: 154,
    region: 'Asia',
    countryCode: 'MP',
    name: 'Northern Mariana Islands ',
  },
  {
    id: 155,
    region: 'Europe',
    countryCode: 'NO',
    name: 'Norway ',
  },
  {
    id: 156,
    region: 'Middle East',
    countryCode: 'OM',
    name: 'Oman ',
  },
  {
    id: 157,
    region: 'Asia',
    countryCode: 'PK',
    name: 'Pakistan ',
  },
  {
    id: 158,
    region: 'Australasia',
    countryCode: 'PW',
    name: 'Palau ',
  },
  {
    id: 159,
    region: 'North America',
    countryCode: 'PA',
    name: 'Panama ',
  },
  {
    id: 160,
    region: 'Australasia',
    countryCode: 'PG',
    name: 'Papua New Guinea ',
  },
  {
    id: 161,
    region: 'South America',
    countryCode: 'PY',
    name: 'Paraguay ',
  },
  {
    id: 162,
    region: 'South America',
    countryCode: 'PE',
    name: 'Peru ',
  },
  {
    id: 163,
    region: 'Asia',
    countryCode: 'PH',
    name: 'Philippines ',
  },
  {
    id: 164,
    region: 'Australasia',
    countryCode: 'PN',
    name: 'Pitcairn Island ',
  },
  {
    id: 165,
    region: 'Europe',
    countryCode: 'PL',
    name: 'Poland ',
  },
  {
    id: 166,
    region: 'Australasia',
    countryCode: 'PF',
    name: 'Polynesia (French) ',
  },
  {
    id: 167,
    region: 'Europe',
    countryCode: 'PT',
    name: 'Portugal ',
  },
  {
    id: 168,
    region: 'Caribbean',
    countryCode: 'PR',
    name: 'Puerto Rico ',
  },
  {
    id: 169,
    region: 'Middle East',
    countryCode: 'QA',
    name: 'Qatar ',
  },
  {
    id: 170,
    region: 'Africa',
    countryCode: 'RE',
    name: 'Reunion ',
  },
  {
    id: 171,
    region: 'Europe',
    countryCode: 'RO',
    name: 'Romania ',
  },
  {
    id: 172,
    region: 'Europe',
    countryCode: 'RU',
    name: 'Russia ',
  },
  {
    id: 173,
    region: 'Africa',
    countryCode: 'RW',
    name: 'Rwanda ',
  },
  {
    id: 174,
    region: 'Africa',
    countryCode: 'SH',
    name: 'Saint Helena ',
  },
  {
    id: 175,
    region: 'Caribbean',
    countryCode: 'KN',
    name: 'Saint Kitts and Nevis ',
  },
  {
    id: 176,
    region: 'Caribbean',
    countryCode: 'LC',
    name: 'Saint Lucia ',
  },
  {
    id: 177,
    region: 'North America',
    countryCode: 'PM',
    name: 'Saint Pierre and Miquelon ',
  },
  {
    id: 178,
    region: 'Caribbean',
    countryCode: 'VC',
    name: 'Saint Vincent and Grenadines ',
  },
  {
    id: 179,
    region: 'Australasia',
    countryCode: 'WS',
    name: 'Samoa ',
  },
  {
    id: 180,
    region: 'Europe',
    countryCode: 'SM',
    name: 'San Marino ',
  },
  {
    id: 181,
    region: 'Africa',
    countryCode: 'ST',
    name: 'Sao Tome and Principe ',
  },
  {
    id: 182,
    region: 'Middle East',
    countryCode: 'SA',
    name: 'Saudi Arabia ',
  },
  {
    id: 183,
    region: 'Africa',
    countryCode: 'SN',
    name: 'Senegal ',
  },
  {
    id: 184,
    region: 'Europe',
    countryCode: 'RS',
    name: 'Serbia ',
  },
  {
    id: 185,
    region: 'Africa',
    countryCode: 'SC',
    name: 'Seychelles ',
  },
  {
    id: 186,
    region: 'Africa',
    countryCode: 'SL',
    name: 'Sierra Leone ',
  },
  {
    id: 187,
    region: 'Asia',
    countryCode: 'SG',
    name: 'Singapore ',
  },
  {
    id: 188,
    region: 'Europe',
    countryCode: 'SK',
    name: 'Slovakia ',
  },
  {
    id: 189,
    region: 'Europe',
    countryCode: 'SI',
    name: 'Slovenia ',
  },
  {
    id: 190,
    region: 'Australasia',
    countryCode: 'SB',
    name: 'Solomon Islands ',
  },
  {
    id: 191,
    region: 'Africa',
    countryCode: 'SO',
    name: 'Somalia ',
  },
  {
    id: 192,
    region: 'Africa',
    countryCode: 'ZA',
    name: 'South Africa ',
  },
  {
    id: 193,
    region: 'South America',
    countryCode: 'GS',
    name: 'South Georgia and South Sandwich Islands ',
  },
  {
    id: 194,
    region: 'Asia',
    countryCode: 'KR',
    name: 'South Korea ',
  },
  {
    id: 195,
    region: 'Europe',
    countryCode: 'ES',
    name: 'Spain ',
  },
  {
    id: 196,
    region: 'Asia',
    countryCode: 'LK',
    name: 'Sri Lanka ',
  },
  {
    id: 197,
    region: 'Africa',
    countryCode: 'SD',
    name: 'Sudan ',
  },
  {
    id: 198,
    region: 'South America',
    countryCode: 'SR',
    name: 'Suriname ',
  },
  {
    id: 199,
    region: 'Europe',
    countryCode: 'SJ',
    name: 'Svalbard and Jan Mayen Islands ',
  },
  {
    id: 200,
    region: 'Africa',
    countryCode: 'SZ',
    name: 'Swaziland ',
  },
  {
    id: 201,
    region: 'Europe',
    countryCode: 'SE',
    name: 'Sweden ',
  },
  {
    id: 202,
    region: 'Europe',
    countryCode: 'CH',
    name: 'Switzerland ',
  },
  {
    id: 203,
    region: 'Middle East',
    countryCode: 'SY',
    name: 'Syria ',
  },
  {
    id: 204,
    region: 'Asia',
    countryCode: 'TW',
    name: 'Taiwan ',
  },
  {
    id: 205,
    region: 'Asia',
    countryCode: 'TJ',
    name: 'Tajikistan ',
  },
  {
    id: 206,
    region: 'Africa',
    countryCode: 'TZ',
    name: 'Tanzania ',
  },
  {
    id: 207,
    region: 'Asia',
    countryCode: 'TH',
    name: 'Thailand ',
  },
  {
    id: 208,
    region: 'Australasia',
    countryCode: 'TL',
    name: 'Timor-Leste (East Timor) ',
  },
  {
    id: 209,
    region: 'Africa',
    countryCode: 'TG',
    name: 'Togo ',
  },
  {
    id: 210,
    region: 'Australasia',
    countryCode: 'TK',
    name: 'Tokelau ',
  },
  {
    id: 211,
    region: 'Australasia',
    countryCode: 'TO',
    name: 'Tonga ',
  },
  {
    id: 212,
    region: 'Caribbean',
    countryCode: 'TT',
    name: 'Trinidad and Tobago ',
  },
  {
    id: 213,
    region: 'Africa',
    countryCode: 'TN',
    name: 'Tunisia ',
  },
  {
    id: 214,
    region: 'Middle East',
    countryCode: 'TR',
    name: 'Turkey ',
  },
  {
    id: 215,
    region: 'Asia',
    countryCode: 'TM',
    name: 'Turkmenistan ',
  },
  {
    id: 216,
    region: 'Caribbean',
    countryCode: 'TC',
    name: 'Turks and Caicos Islands ',
  },
  {
    id: 217,
    region: 'Australasia',
    countryCode: 'TV',
    name: 'Tuvalu ',
  },
  {
    id: 218,
    region: 'Africa',
    countryCode: 'UG',
    name: 'Uganda ',
  },
  {
    id: 219,
    region: 'Europe',
    countryCode: 'UA',
    name: 'Ukraine ',
  },
  {
    id: 220,
    region: 'Middle East',
    countryCode: 'AE',
    name: 'United Arab Emirates ',
  },
  {
    id: 221,
    region: 'Europe',
    countryCode: 'GB',
    name: 'United Kingdom ',
  },
  {
    id: 222,
    region: 'North America',
    countryCode: 'US',
    name: 'United States ',
  },
  {
    id: 223,
    region: 'South America',
    countryCode: 'UY',
    name: 'Uruguay ',
  },
  {
    id: 224,
    region: 'Asia',
    countryCode: 'UZ',
    name: 'Uzbekistan ',
  },
  {
    id: 225,
    region: 'Australasia',
    countryCode: 'VU',
    name: 'Vanuatu ',
  },
  {
    id: 226,
    region: 'South America',
    countryCode: 'VE',
    name: 'Venezuela ',
  },
  {
    id: 227,
    region: 'Asia',
    countryCode: 'VN',
    name: 'Vietnam ',
  },
  {
    id: 228,
    region: 'Caribbean',
    countryCode: 'VG',
    name: 'Virgin Islands ',
  },
  {
    id: 229,
    region: 'Australasia',
    countryCode: 'WF',
    name: 'Wallis and Futuna Islands ',
  },
  {
    id: 230,
    region: 'Middle East',
    countryCode: 'YE',
    name: 'Yemen ',
  },
  {
    id: 231,
    region: 'Africa',
    countryCode: 'ZM',
    name: 'Zambia ',
  },
  {
    id: 232,
    region: 'Africa',
    countryCode: 'ZW',
    name: 'Zimbabwe ',
  },
];

export const PREFERRED_COUNTRY_CODES = ['au', 'nz'];
export const EXCLUDED_COUNTRY_CODES = [
  'ki',
  'nr',
  'nu',
  'nf',
  'sh',
  'sx',
  'tl',
  'wf',
  'io',
];
