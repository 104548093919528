import qs from 'qs';
import { isApplyNow } from './envHelper';

/* solution to react-router removing parsed query in react-router v.4 (why thee why)
 modified version of something from a comment on https://github.com/ReactTraining/react-router/issues/4410 */
export const addLocationQuery = (h) => {
  h.location = {
    ...h.location,
    query: qs.parse(h.location.search.slice(1)),
  };
};

export const setupGlobalConfigs = () => {
  global.window.LM_CONFIG = isApplyNow()
    ? { ...window.LM_CONFIG, ...window.APPLY_CONFIG }
    : { ...window.LM_CONFIG, ...window.MILLI_CONFIG };
};
