// WARNING: VALIDATOR ONLY SUPPORTS VALIDATING STRINGS, ANY NON-STRING PASSED IN WILL THROW AN ERROR!
import validatorJs from 'validator';
import {
  parsePhoneNumberFromString,
  getCountryCallingCode,
} from 'libphonenumber-js/max';
import _ from 'lodash';
import moment from 'moment';
import { isNumber } from 'shared/lib/numbrero';
import { MOBILE_TYPE, FIXED_LINE_OR_MOBILE_TYPE } from 'constants/phoneTypes';
import { unformatPhoneNumber } from 'lib/utils/numberUtils';

import {
  ERROR_MESSAGES,
  NO_ERROR,
  REQUIRED,
  BUTTON_REQUIRED,
  POSTCODE,
  EMAIL,
  MOBILE,
  MOBILE_INTERNATIONAL,
  PHONE_NUMBER,
  PHONE_NUMBER_INTERNATIONAL,
  DATE,
  DATE_NOT_REQUIRED,
  MONTH_YEAR,
  MULTIPLE_CHOICE_REQUIRED,
  LOCALITY,
  REQUIRED_ADDRESS,
  OPTIONAL_AUTOCOMPLETE,
  PROPERTY,
  GREATER_THAN_ZERO,
  GREATER_THAN_OR_EQL_TO_ZERO,
  AT_LEAST_TWENTY_THOUSAND,
  IN_THE_PAST,
  IN_THE_FUTURE,
  GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR,
  LESS_THAN_PROPERTY_VALUE,
  OKTA_LOGIN_TOKEN_REQUIRED,
  VALUE_EXISTS,
  REQUIRED_FORMATTED_ADDRESS,
  INVALID_NZBN,
  INVALID_NZBN_LENGTH,
  INVALID_NZBN_STRUCTURE,
  PASSWORD,
  PASSWORD_MATCH,
  OKTA_RECOVERY_TOKEN,
} from 'constants/validators';
import { isEmptyObject, intlMobile } from 'shared/lib/utils';

export const propertyFieldRuleMap = {
  value: [REQUIRED],
  locality: [REQUIRED],
  address: [REQUIRED],
  mortgage: [REQUIRED],
  mortgageAmount: [REQUIRED],
  ownerOccupied: [REQUIRED],
};

const validator = {
  validateFields(fieldRuleMap, state) {
    const errors = {};
    Object.keys(fieldRuleMap).forEach((key) => {
      const rules = fieldRuleMap[key];
      const error = this.validateField(key, rules, state);
      if (error) {
        errors[key] = error;
      }
    });
    return errors;
  },

  validateField(key, rules, state) {
    // if (!state || !state.errors) {
    //   console.log('WARNING:', 'The object you are validating does not have an errors key, therefor your async errors will fail.');
    // }
    return (
      rules
        .filter((rule) => ERROR_MESSAGES[rule]) // check that the rule has an error message associated with it
        .map((rule) => ({
          text: this[rule](state[key], state),
          blocking: true,
        })) // create an array of the results of running the rule on the value
        .concat((state.errors && state.errors[key]) || [])
        .find((result) => result && result.text !== NO_ERROR) || NO_ERROR
    );
  },

  createPopupValidator(key, rules, state) {
    return (value) => {
      const result = this.validateField(key, rules, { ...state, [key]: value });
      return result || {};
    };
  },

  requiredWithCustomErrorMsg(value, customMsg) {
    if (this[REQUIRED](value) === NO_ERROR) {
      return NO_ERROR;
    }
    return customMsg;
  },

  [REQUIRED](value) {
    if (
      value === undefined ||
      value === null ||
      validatorJs.isEmpty(validatorJs.trim(value.toString()))
    ) {
      return ERROR_MESSAGES[REQUIRED];
    }

    return NO_ERROR;
  },

  [PASSWORD](value) {
    if (
      value === undefined ||
      value === null ||
      (typeof value === 'string' && value.length < 12)
    ) {
      return ERROR_MESSAGES[PASSWORD];
    }

    return NO_ERROR;
  },

  [BUTTON_REQUIRED](value) {
    return this.requiredWithCustomErrorMsg(
      value,
      ERROR_MESSAGES[BUTTON_REQUIRED],
    );
  },

  [POSTCODE](value) {
    const regex = /^\d{4}$/;
    if (value && regex.test(value)) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[POSTCODE];
  },

  [OPTIONAL_AUTOCOMPLETE]() {
    /* dummy validator to display async error */
    return '';
  },

  [REQUIRED_ADDRESS](value) {
    if (value && !!value.toString().trim()) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[REQUIRED_ADDRESS];
  },

  [REQUIRED_FORMATTED_ADDRESS](value) {
    if (
      value &&
      !!value.formattedAddress &&
      !!value.formattedAddress.toString().trim()
    ) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[REQUIRED_ADDRESS];
  },

  [LOCALITY](value) {
    if (value) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[LOCALITY];
  },

  // eslint-disable-next-line sonarjs/cognitive-complexity
  [MOBILE](value) {
    const mobile =
      typeof value === 'string'
        ? unformatPhoneNumber(value)
        : value && value.number;
    if (!mobile) {
      return ERROR_MESSAGES[MOBILE];
    }
    const regex = /^(\+6|0)((\d)\d{7,10})$/;
    const match = mobile.match(regex);
    if (!match) {
      return ERROR_MESSAGES[MOBILE];
    }

    const international = match[1].length === 2; // is first group +6
    const countryIdentifier = match[3];

    // @TODO: In future refactor all mobile validation / formating / sanitizing into seporate module.
    if (international) {
      // is international AU
      if (countryIdentifier === '1' && mobile.length === 12) {
        return NO_ERROR;
      }
      // is international NZ
      if (countryIdentifier === '4' && _.inRange(mobile.length, 11, 14)) {
        return NO_ERROR;
      }
    } else {
      // is local AU
      if (countryIdentifier === '4' && mobile.length === 10) {
        return NO_ERROR;
      }
      // is local NZ
      if (countryIdentifier === '2' && _.inRange(mobile.length, 9, 12)) {
        return NO_ERROR;
      }
    }
    // Let test accounts through
    if (mobile.length === 10 && /^0{8}/.test(mobile)) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[MOBILE];
  },

  [MOBILE_INTERNATIONAL](value = {}) {
    const { countryCode, dialCode } = value;
    const mobile = intlMobile(value);
    if (!mobile) {
      return ERROR_MESSAGES[MOBILE_INTERNATIONAL];
    }

    // Let test accounts through
    try {
      const testNum = mobile.replace(
        `+${countryCode ? getCountryCallingCode(countryCode) : dialCode}`,
        '0',
      );
      if (testNum.length >= 10 && /0000000/.test(testNum)) {
        return NO_ERROR;
      }
    } catch (error) {
      console.log(error);
    }

    const phoneNumber = parsePhoneNumberFromString(mobile, countryCode);
    if (!phoneNumber) {
      return ERROR_MESSAGES[MOBILE_INTERNATIONAL];
    }
    const international = phoneNumber.isValid();
    const isMobile = [MOBILE_TYPE, FIXED_LINE_OR_MOBILE_TYPE].includes(
      phoneNumber.getType(),
    );
    if (international && isMobile) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[MOBILE_INTERNATIONAL];
  },

  [OKTA_LOGIN_TOKEN_REQUIRED](value) {
    if (value && value.join('').length === 6) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[OKTA_LOGIN_TOKEN_REQUIRED];
  },

  [OKTA_RECOVERY_TOKEN](value) {
    if (value && value.join('').length === 6) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[OKTA_RECOVERY_TOKEN];
  },

  [PHONE_NUMBER](value) {
    const number = intlMobile(value);
    const regex = /^\d{8,}$/;
    const match = number && number.match(regex);
    if (match) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[PHONE_NUMBER];
  },

  [PHONE_NUMBER_INTERNATIONAL](value = {}) {
    const { countryCode, dialCode } = value;
    const mobile = intlMobile(value);
    if (!mobile) {
      return ERROR_MESSAGES[PHONE_NUMBER_INTERNATIONAL];
    }

    // Lets dummy phone numbers through for testing ex. 02000000
    try {
      const testNum = mobile.replace(
        `+${countryCode ? getCountryCallingCode(countryCode) : dialCode}`,
        '0',
      );
      if (testNum.length === 8 && /000000/.test(testNum)) {
        return NO_ERROR;
      }
    } catch (error) {
      console.log(error);
    }

    const phoneNumber = parsePhoneNumberFromString(mobile, countryCode);
    if (!phoneNumber) {
      return ERROR_MESSAGES[PHONE_NUMBER_INTERNATIONAL];
    }
    if (phoneNumber.isValid()) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[PHONE_NUMBER_INTERNATIONAL];
  },

  [EMAIL](value) {
    if (value && validatorJs.isEmail(value.toString())) {
      return NO_ERROR;
    }
    return ERROR_MESSAGES[EMAIL];
  },

  [IN_THE_PAST](value) {
    if (moment({ day: 1, ...value }).isAfter()) {
      return ERROR_MESSAGES[IN_THE_PAST];
    }
    return NO_ERROR;
  },

  [IN_THE_FUTURE](value) {
    if (moment({ day: 1, ...value }).isBefore()) {
      return ERROR_MESSAGES[IN_THE_FUTURE];
    }
    return NO_ERROR;
  },

  [GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR](value, props) {
    const valueIsValidMonthYear = !this[MONTH_YEAR](value);
    const propIsValidMonthYear = !this[MONTH_YEAR](props.dateStarted || {});

    if (valueIsValidMonthYear && propIsValidMonthYear) {
      if (
        moment({ ...value, day: 1 }).isSameOrAfter(
          moment({ ...props.dateStarted, day: 1 }),
        )
      ) {
        return NO_ERROR;
      }
      return ERROR_MESSAGES[GREATER_THAN_EMPLOYMENT_START_MONTH_YEAR];
    }
    return NO_ERROR;
  },

  [DATE](value) {
    const test = new Date(value.year, value.month, value.day);
    if (isNaN(test)) {
      return ERROR_MESSAGES[DATE];
    }
    return NO_ERROR;
  },

  [DATE_NOT_REQUIRED](value) {
    const test = new Date(value.year, value.month, value.day);
    if (isNaN(test) && (value.year || value.month || value.day)) {
      return ERROR_MESSAGES[DATE_NOT_REQUIRED];
    }
    return NO_ERROR;
  },

  [MONTH_YEAR](value) {
    const test = new Date(value.year, value.month, 1);
    if (isNaN(test)) {
      return ERROR_MESSAGES[MONTH_YEAR];
    }
    return NO_ERROR;
  },

  [MULTIPLE_CHOICE_REQUIRED](value) {
    return _.values(value).some((v) => v)
      ? NO_ERROR
      : ERROR_MESSAGES[MULTIPLE_CHOICE_REQUIRED];
  },

  [PROPERTY](properties) {
    if (!properties) {
      return NO_ERROR;
    }

    const errors = {};
    Object.keys(properties).forEach((propertyId) => {
      const propertyErrors = validator.validateFields(
        propertyFieldRuleMap,
        properties[propertyId],
      );

      if (!isEmptyObject(propertyErrors)) {
        errors[propertyId] = propertyErrors;
      }
    });

    if (isEmptyObject(errors)) {
      return NO_ERROR;
    }
    return errors;
  },

  [LESS_THAN_PROPERTY_VALUE](value, props) {
    const propertyValue = props.value;
    if (propertyValue && propertyValue < value) {
      return ERROR_MESSAGES[LESS_THAN_PROPERTY_VALUE];
    }
    return NO_ERROR;
  },

  [GREATER_THAN_ZERO](value) {
    if (value && isNumber(value) && value > 0) {
      return NO_ERROR;
    }

    return ERROR_MESSAGES[GREATER_THAN_ZERO];
  },

  [GREATER_THAN_OR_EQL_TO_ZERO](value) {
    if (value !== undefined && isNumber(value) && value >= 0) {
      return NO_ERROR;
    }

    return ERROR_MESSAGES[GREATER_THAN_OR_EQL_TO_ZERO];
  },

  [AT_LEAST_TWENTY_THOUSAND](value) {
    if (value && isNumber(value) && value >= 20000) {
      return NO_ERROR;
    }

    return ERROR_MESSAGES[AT_LEAST_TWENTY_THOUSAND];
  },

  [INVALID_NZBN](value) {
    if (!value) {
      return ERROR_MESSAGES[REQUIRED];
    }
    if (!value.startsWith('94')) {
      return ERROR_MESSAGES[INVALID_NZBN_STRUCTURE];
    }
    if (value.length !== 13) {
      return ERROR_MESSAGES[INVALID_NZBN_LENGTH];
    }
    return NO_ERROR;
  },

  [VALUE_EXISTS](newValue, otherValues) {
    if (
      newValue &&
      otherValues &&
      validatorJs.isIn(
        newValue.toString().toLowerCase(),
        otherValues.map((v) => v.toString().toLowerCase()),
      )
    ) {
      return ERROR_MESSAGES[VALUE_EXISTS];
    }
    return NO_ERROR;
  },

  [PASSWORD_MATCH](password, confirmPassword) {
    if (password !== confirmPassword) {
      return ERROR_MESSAGES[PASSWORD_MATCH];
    }
    return NO_ERROR;
  },
};

export default validator;
