import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import styles from './ButtonWithDescription.css';

const ButtonWithDescription = (props) => (
  <div className={styles.root} onClick={() => props.onClick()}>
    <Button
      theme='inputButton smallIcon'
      className={styles.button}
      {...props}
    />
    <div className={styles.description}>{props.description}</div>
  </div>
);

ButtonWithDescription.propTypes = {
  onClick: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ButtonWithDescription;
