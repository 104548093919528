import { createSelector } from 'reselect';
import _ from 'lodash';
import { REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE } from 'shared/constants/repaymentTypes';
import getLender from 'lib/lenderHelper';
import { formatTextWithComma } from 'lib/utils/stringUtils';
import {
  getRepaymentType,
  getLoanAmount,
  getLoanTerm,
  getRepaymentFrequency,
  getSelectedProductId,
} from 'selectors/structureSelectors';

const calTotalOngoing = (product) =>
  product.fees.annual + product.fees.monthly * 12;
const getRatesWithPeriodYears = (product) =>
  _.mapValues(product.rates, (r) =>
    _.omit({ ...r, periodYrs: r.period / 12 }, 'period'),
  );
const getProductsWithMetaData = (products, mapper) =>
  mapper(products, (p) => ({
    ...p,
    fees: { ...p.fees, totalOngoing: calTotalOngoing(p) },
    rates: getRatesWithPeriodYears(p),
  }));

export const products = (state) => state.products;
export const getProductDetails = createSelector(
  products,
  (p) => p.productDetail,
);
export const getProductList = createSelector(products, (p) => p.productList);
export const getFeaturedProducts = (state) => products(state).featuredProducts;
export const getInterestProducts = (state) => products(state).interestProducts;
export const hasMoreLenderProducts = createSelector(
  getProductList,
  (l) => !!l && !!l.length && l.length < l[0].totalQueryCount,
);

export const getLenderId = createSelector(
  getProductDetails,
  (p) => p.lender && p.lender.id,
);
export const getLenderDescription = createSelector(
  getLenderId,
  (lenderId) => lenderId && getLender(lenderId).lenderDescription,
);
export const productDetailMetaData = createSelector(
  getProductDetails,
  getLenderDescription,
  getRepaymentType,
  getLoanAmount,
  getLoanTerm,
  getRepaymentFrequency,
  getSelectedProductId,
  (
    productDetail,
    lenderDescription,
    repaymentType,
    loanAmount,
    loanTerm,
    repaymentFrequency,
    productId,
  ) => {
    return {
      product: !_.isEmpty(productDetail)
        ? {
            ...productDetail,
            rates: getRatesWithPeriodYears(productDetail),
            fees: {
              ...productDetail.fees,
              totalOngoing: calTotalOngoing(productDetail),
            },
            documentation: productDetail.documentation
              .replace(/([A-Z])/g, ' $1')
              .trim(),
            loanPurposes: productDetail.loanPurposes
              .join(', ')
              .replace(/,s*/g, ', '), // some products have on of productTypes which is formatted as "Owner Occupied,Vacant Land"
            regions: formatTextWithComma(productDetail.regions),
          }
        : {},
      isSelectedProduct: productId === productDetail.id,
      lenderDescription,
      repaymentType,
      isPiRepayment: repaymentType === REPAYMENT_TYPE_PRINCIPAL_INTEREST_VALUE,
      loanAmount: _.toNumber(loanAmount),
      loanTerm: _.toNumber(loanTerm),
      repaymentFrequency: _.toNumber(repaymentFrequency),
    };
  },
);

export const productListMetaData = createSelector(
  getProductList,
  (productList = []) => {
    const productsToShow = productList.filter((p) => {
      const lender = getLender(p.lender.id);
      return !lender?.hideFromComparePage;
    });
    return {
      productList: getProductsWithMetaData(productsToShow, _.map),
    };
  },
);
