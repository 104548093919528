export const BUYER_SCENARIO_FHB = 'firstHomeBuyer';
export const BUYER_SCENARIO_MOVE = 'move';
export const BUYER_SCENARIO_INVEST = 'buyAndInvest';
export const BUYER_SCENARIO_COMPARE = 'justCompare';

export const INVESTMENT_SCENARIO_DEFAULT = 'invest';
export const REFINANCE_SCENARIO_DEFAULT = 'refinance';

export const FORK_DIY_RESEARCH = 'forkResearch';
export const FORK_FAST_APPROVAL = 'forkApproval';
