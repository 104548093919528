/* eslint-disable sonarjs/no-duplicate-string */
export default [
  {
    key: 'Westpac',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'CWBC',
    lenderId: 16,
    lenderName: 'Westpac',
    lenderDescription:
      'As one of the oldest and biggest banks in New Zealand and Australia, Westpac continues to provide a strategic flexibility to financial solutions to better meet individual customer needs. We’re involved in a wide range of Kiwi institutions and projects for the benefit of all New Zealanders. Sustainability and helping to create a better tomorrow is at the heart of our culture, that’s why we take a company-wide approach to helping develop solutions that contribute to a stronger New Zealand.',
    isBigLender: true,
    mogoId: 0,
  },
  {
    key: 'GetCapital',
    aggregatorIds: [2, 1, 3, 6],
    countryCode: 2,
    lenderCode: 'GetCapital',
    lenderId: 5037,
    lenderName: 'Get Capital',
    lenderDescription:
      'As a specialist business lender, we believe that every business should have the opportunity to reach its full potential without being constrained by capital. Our goal is to make it easier for business owners to access the finance they need to operate and grow = whatever their business may be. And to solve the complex problems that arise along the way, we develop powerful financial solutions. Because business finance is all we do. GetCapital has been recognised by AFR’s Fast 100, Deloitte’s Technology Fast50, Smart Company’s Smart50 and Deloitte’s Asia Pacific Technology Fast 500, reflecting our growth trajectory, strong customer focus and use of technology to improve the customer experience.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BankOfChina',
    aggregatorIds: [2, 1, 3, 7],
    countryCode: 2,
    lenderCode: 'BOC',
    lenderId: 5036,
    lenderName: 'Bank of China ',
    lenderDescription:
      'Founded in 1942, Bank of China Sydney Branch is Australia’s first foreign bank and holds full banking and private banking licenses. Bank of China (Australia) Limited was established in 2005. Since its resumption in 1985, its business scale has grown rapidly and its market influence has been expanding. It has established 10 outlets in five states of New South Wales, Victoria, Queensland, Western Australia and South Australia, and has become the leading institution in Australia’s RMB business. In November 2014, the People’s Bank of China designated the Bank of China Sydney to act as the Sydney RMB clearing bank.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'SocietyOne',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'SO',
    lenderId: 5035,
    lenderName: 'Society One',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'RatesetterAuto',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'RSAUTO',
    lenderId: 5034,
    lenderName: 'Ratesetter Auto',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Plenti',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'RSPL',
    lenderId: 5033,
    lenderName: 'Plenti',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ParamountLeasing',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'PML',
    lenderId: 5032,
    lenderName: 'Paramount Leasing',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MetroFinance',
    aggregatorIds: [2, 1, 3, 8, 6],
    countryCode: 2,
    lenderCode: 'METRO',
    lenderId: 5039,
    lenderName: 'Metro Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'GreenlightAuto',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'GLA',
    lenderId: 5030,
    lenderName: 'Greenlight Auto',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BankAustralia',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'BA',
    lenderId: 5021,
    lenderName: 'Bank Australia',
    lenderDescription:
      'Bank Australia started in 1957 as the CSIRO Co-operative Credit Society and has grown and evolved, joining together 72 credit unions and co-operatives to become Australia’s first customer-owned bank. Bank Australia considers their money to be ’clean’ because it is never loaned to industries (eg coal, nuclear weapons, gambling, tobacco, live animal export) that do harm. Instead, as a customer-owned bank, they believe it’s important to use their customers’ money in responsible ways, creating positive impact for people, their communities and the planet.',
    isBigLender: false,
    mogoId: 1029,
  },
  {
    key: 'Moneyplace',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'MP',
    lenderId: 5029,
    lenderName: 'MoneyPlace',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Prospa',
    aggregatorIds: [2, 1, 3, 8, 6],
    countryCode: 2,
    lenderCode: 'PROPSA',
    lenderId: 5042,
    lenderName: 'Prospa',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BetterChoice',
    aggregatorIds: [1, 3, 2, 6, 7],
    countryCode: 2,
    lenderCode: 'FF',
    lenderId: 3795,
    lenderName: 'Better Choice Home Loans',
    lenderDescription:
      'Better Choice is one of Australia’s leading and most innovative Mortgage Lenders. Offering solutions for a wide range of customer scenario’s whether it’s for a first home buyer, an astute investor in residential or commercial property, construction of one or more dwellings, bridging finance, non-resident, SMSF or those borrowers who have experienced a life event that may have affected their capacity to borrow.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'NowFinance',
    aggregatorIds: [1, 3, 2],
    countryCode: 2,
    lenderCode: 'FIN N',
    lenderId: 3750,
    lenderName: 'NOW Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'CapitalFinance',
    aggregatorIds: [1, 3, 2, 8, 6],
    countryCode: 2,
    lenderCode: 'CCFL',
    lenderId: 3746,
    lenderName: 'Capital Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Thinktank',
    aggregatorIds: [1, 3, 2],
    countryCode: 2,
    lenderCode: 'ThinkTank',
    lenderId: 3727,
    lenderName: 'Think Tank',
    lenderDescription:
      'Thinktank prides itself on becoming Australia’s leading specialist commercial property lender, approaching $1 billion in first mortgage loans for properties ranging from $100,000 up to $5 million. They’ve become the the first choice for SME and property investors because everything they do is aimed at securing the best deal – whether that’s financing the purchase of a commercial property, securing equity release or re-financing.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ParamountMortgage',
    aggregatorIds: [1, 3, 2],
    countryCode: 2,
    lenderCode: 'PM',
    lenderId: 3719,
    lenderName: 'Paramount Mortgage Services',
    lenderDescription:
      'Paramount Mortgages have dedicated their time to serving the mortgage broking industry and its customers, committed to providing cutting edge, competitive loan solutions, as well as real time turnaround times for more than 10 years. Having helped thousands of customers with their unique financial needs, Paramount Mortgages’ founding philosophy is based on honesty and nurturing relationships for residential, commercial, rural, industrial, business loans, specialised securities, development and construction, asset finance and leasing solutions.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MacquarieBank',
    aggregatorIds: [2, 1, 3, 6, 8, 7],
    countryCode: 2,
    lenderCode: 'MACQ',
    lenderId: 3605,
    lenderName: 'Macquarie',
    lenderDescription:
      'Macquarie Group is a leading provider of financial, advisory, investment and funds management services. Their global operations include offices in the world’s major financial centres. Their breadth of expertise covers advisory and capital markets, trading and hedging, funds management, asset finance, financing, research and retail financial services. The diversity of Macquarie Group’s operations, combined with a strong capital position and robust risk management framework, has contributed to our 45-year record of unbroken profitability.',
    isBigLender: false,
    mogoId: 1010,
    mogoName: 'Macquarie Bank',
  },
  {
    key: 'HeartlandSeniorsFinance',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'HSF',
    lenderId: 5045,
    lenderName: 'Heartland Seniors Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'JudoBank',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'JUDO',
    lenderId: 5044,
    lenderName: 'Judo Bank ',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'virgin',
    aggregatorIds: [1, 3, 2, 7, 8, 6],
    countryCode: 2,
    lenderCode: 'VIRGIN',
    lenderId: 3728,
    lenderName: 'Virgin Money Australia',
    lenderDescription:
      'At Virgin Money, we’re passionate about helping Australians realise bigger possibilities through the delivery of beautifully simple and rewarding financial products, all backed by Virgin’s legendary customer service. We reject the notion of complex financial services. Instead we aim for clarity and simplicity in everything we do.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'PrimeCapital',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'PRIME',
    lenderId: 5019,
    lenderName: 'Prime Capital',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'LaTrobe',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'LaTro',
    lenderId: 3630,
    lenderName: 'La Trobe Financial',
    lenderDescription:
      'La Trobe Financial continue to produce solid growth in the current competitive climate which is reflective of their hard work and dedication. Their brokers are committed and enthusiastic who take pride in the service they offer customers. Their ongoing strategic vision is to maximise their customer’s financial opportunity where they can. As they strive to be the best in the industry, especially when it comes to customer service, La Trobe Financial aim to deliver their best work within an environment driven by values and great products.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'StGeorge',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'StGeo',
    lenderId: 3629,
    lenderName: 'St George',
    lenderDescription:
      'St.George Bank is one of Australia’s leading retail and business banking brands, serving over 2.6 million consumer, business and corporate customers in Australia. In 2008, St.George and its South Australian brand, BankSA, became part of the Westpac Group. With more than 5700 staff and 396 St.George and BankSA retail branches, St.George is known for exceptional service, innovative, award-winning products, and specialist financial advice for retail and business customers. St.George has built its reputation on strong connections with local communities. A deepening understanding of sustainability suggests that they’re extending their commitment to a broader range of stakeholders and interests.',
    isBigLender: false,
    mogoId: 1004,
    mogoName: 'St George',
  },
  {
    key: 'Westpac',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'CWBC',
    lenderId: 3625,
    lenderName: 'Westpac',
    lenderDescription:
      'Westpac Banking Corporation was founded in 1817 and was the first bank established in Australia. Now one of the Big4, Westpac has a long and proud history as Australia’s first and oldest bank. It was established in 1817 as the Bank of New South Wales under a charter of incorporation provided by Governor Lachlan Macquarie. In October 1982 it changed its name to Westpac Banking Corporation following the acquisition of the Commercial Bank of Australia. Westpac’s vision is ‘To be one of the world’s great companies, helping our customers, communities and people to prosper and grow’. Their customer-specific focus on deposits, wealth and insurance have been key elements to their success story and has enabled them to appeal to a broader range of customers. Westpac continues to provide a strategic flexibility to financial solutions to better meet individual customer needs.',
    isBigLender: true,
    mogoId: 1002,
    mogoName: 'Westpac',
  },
  {
    key: 'CBA',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'CCBA',
    lenderId: 3624,
    lenderName: 'Commonwealth Bank',
    lenderDescription:
      'CBA and CommBank are also familiar names for the Commonwealth Bank of Australia. Part of the Big4, CBA is considered to be Australia’s leading provider of integrated financial services, including retail, premium, business and institutional banking, funds management, superannuation, insurance, investment and share-broking products and services. The Commonwealth Bank brand is considered to be one of the most recognised brand in the Australian financial services industry. The Commonwealth Bank Group provides a full range of retail banking services including home loans, credit cards, personal loans, transaction accounts, and demand including term deposits through its Commonwealth Bank and Bankwest brands.',
    isBigLender: true,
    mogoId: 1001,
    mogoName: 'Commonwealth Bank',
  },
  {
    key: 'Bankfirst',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'VTMB',
    lenderId: 3619,
    lenderName: 'Bank First',
    lenderDescription:
      'Bank First, formerly Victoria Teachers Mutual, is a mutual which means their customers own the organisation. Profits are reinvested back into Bank First to provide continuing value through highly competitive interest rates, low fees and superior customer service.',
    isBigLender: false,
    mogoId: 1015,
    mogoName: 'Teachers Mutual Bank',
  },
  {
    key: 'BOM',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'BOM',
    lenderId: 3618,
    lenderName: 'Bank of Melbourne',
    lenderDescription:
      'The Bank of Melbourne is a financial institution based in Melbourne, Australia, established in 1989 and taken over by the Westpac Banking Corporation (Westpac) in 1997. A bank who believes in delivering the right finance solutions, rather than a generic package. Their motto is to make their customer’s life easier by adding genuine value to the way they deal with money in their day to day business. From buying a first home, restructuring a loan, to renovating at home, Bank of Melbourne will customise solutions to better suit their client’s needs.',
    isBigLender: false,
    mogoId: 1008,
    mogoName: 'Bank of Melbourne',
  },
  {
    key: 'BankSA',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'BSA',
    lenderId: 3617,
    lenderName: 'Bank SA',
    lenderDescription:
      'The Bank of South Australia, also known as Bank SA is the largest financial institution in South Australia and the state’s largest home lender. The forerunners of Bank SA and the State Bank of South Australia merged under the same name State Bank of South Australia in 1984. Bank SA is now a division of Westpac, having been a division of St. George Bank before Westpac and St. George merged in 2008. Bank SA have over 100 branches in South Australia and the Northern Territory and pride themselves on their home loan packages with specialist features and low doc options loan options.',
    isBigLender: false,
    mogoId: 1018,
    mogoName: 'BankSA',
  },
  {
    key: 'NAB',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'NABBR',
    lenderId: 3615,
    lenderName: 'National Australia Bank',
    lenderDescription:
      'National Australia Bank Group is a financial services organisation with over 12,700,000 customers and 42,000 people, operating more than 1,700 stores and business banking centres globally. NAB’s major financial services franchises in Australia are complemented by businesses in New Zealand, Asia, the United Kingdom and the United States. Each brand is uniquely positioned yet built on a common commitment to provide customers with quality products and services, fair fees and charges, and relationships built on the principles of help, guidance and advice.',
    isBigLender: true,
    mogoId: 1003,
    mogoName: 'NAB',
  },
  {
    key: 'Resimac',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'Resimac',
    lenderId: 3631,
    lenderName: 'Resimac',
    lenderDescription:
      'RESIMAC Financial Services Pty Limited (ABN 40 104 815 554), is a non-bank lender that is focused on providing a broad range of home loan solutions with competitively low interest rates to Australian borrowers. RESIMAC Financial Services has a team of professionals whose aim is to make the task of obtaining a loan a pleasant experience for every borrower, and continue offering personal service to existing customers by looking after their loan.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BB',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'BBHL',
    lenderId: 3613,
    lenderName: 'Beyond Bank',
    lenderDescription:
      'Beyond Bank is one of Australia’s largest customer owned banks, with assets under management over $4 billion, 195,000 customers, 49 branches, access to over 3,000 rediATM’s and 600 staff. The bank provide personal and business banking services along with financial planning to our customers ensuring we are able to support prosperity for the many.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BOQ',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'BOQ',
    lenderId: 3608,
    lenderName: 'Bank of Queensland',
    lenderDescription:
      'Established since 1874, BOQ is one of Australia’s leading regional banks, who’s among the few not owned by the Big4. This means they’re completely independent, while offering a genuine alternative for customers looking for a full range of personal banking services. With more than 250 branches Australia-wide, BOQ have created simple, easy-to-understand banking products to help support their customer’s financial needs. Bank of Queensland pride themselves on building long-term customer relationships based on respect and compassion to both individuals and businesses.',
    isBigLender: false,
    mogoId: 1144,
    mogoName: 'Bank of Queensland',
  },
  {
    key: 'Citibank',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'Citi',
    lenderId: 3606,
    lenderName: 'Citibank',
    lenderDescription:
      'Citibank in Australia is part of Citigroup, one of the world’s largest financial services organisations. Citibank commenced consumer operations in Australia in 1985 when it was the first foreign bank to be granted a banking licence. By combining local expertise with the strength of Citibank’s worldwide consumer banking network, they’re able to offer not only a sophisticated range of products by Australian standards, but some of the world’s leading range of financial services including deposit accounts, foreign currency deposits and investments, credit cards, mortgages and insurance.',
    isBigLender: false,
    mogoId: 1006,
    mogoName: 'Citibank AU',
  },
  {
    key: 'BOS',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'BOS',
    lenderId: 3632,
    lenderName: 'Bank of Sydney',
    lenderDescription:
      'Our vision – a vision we all share – is greater than money. Since opening our doors in April 2001, we have joined with you and continue to share a common journey. Our vision recognises the great power in financial dedication and hard work, while also honouring the traits of success and perseverance. Every day across our Australian branches, regular conversations inspire and inform how we deliver our range of competitive products, all with the goal of helping you get the most out of life. We are on this journey with you – but most importantly, we are here for you.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'HomeStart',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'HomeStart',
    lenderId: 3708,
    lenderName: 'HomeStart',
    lenderDescription:
      'Established in 1989, HomeStart is a State Government organisation that is 100% focused on providing home loans for South Australians. So while we’re a profitable financial organisation, we are also considerate of our social responsibilities. This means we’re always working on new ways to help more people get into their own home, sooner.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MKMCapital',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'MKM',
    lenderId: 3713,
    lenderName: 'MKM Capital',
    lenderDescription:
      'MKM Capital is a leading provider of residentially secured 1st Mortgage solutions in Australia. As a Principal Lender backed by an institutional funding line (i.e. the credit decision is made in-house) and together with a Standard & Poors rated back office, MKM Capital’s team of professionals is able to tailor a loan solution to your needs. Accredited Introducers to MKM can be assured that their clients will be receiving prompt, efficient service in an accurate and timely manner. Founded in 2004, MKM has evolved into an established provider of the full range of non-conforming offerings. MKM’s products are designed for circumstances that require an alternative and flexible approach. MKM undertakes the full operational process for its loans. MKM’s key credit criteria when assessing an application focus on the security being offered, the borrower’s credit profile and how MKM’s products are likely to result in the borrower being in an improved financial position due to MKM’s extension of credit. MKM is a full member of the MFAA and Credit Ombudsman and holds the necessary NCCP licenses to extend credit nationally.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'HeritageBank',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'HBL',
    lenderId: 3609,
    lenderName: 'Heritage Bank',
    lenderDescription:
      'Established in 1875, Heritage is Australia’s largest customer-owned bank, enriching thousands of Australia’s financial dreams. Heritage Bank is an independent bank, strictly owned by their customers and not shareholders. True to their motto, ‘People first’, profits are shared equally amongst their customers. Their mission continues as they help their customers save money by offering better rates and lower fees than their competitors where they can.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AxsessToday',
    aggregatorIds: [2, 1, 3, 8],
    countryCode: 2,
    lenderCode: 'AXEF',
    lenderId: 5018,
    lenderName: 'Axsesstoday',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'emoney',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'emoney',
    lenderId: 3633,
    lenderName: 'emoney',
    lenderDescription:
      'emoney is one of Australia’s largest, independently owned, non-bank lenders. With a product suite of more than 500 home loans, emoney really can help you find the ideal home loan for your circumstances.  At emoney, they take the confusion out of lending—saving you time, saving you money, and putting your needs first.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AMP',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'AMP',
    lenderId: 3634,
    lenderName: 'AMP Australia',
    lenderDescription:
      'AMP is a leading independent wealth management company in Australia and New Zealand, who’ve helped individuals and organisations build financial security and since 1849. AMP boasts the largest financial advice network in Australia and New Zealand with 4,259 aligned and employed financial advisors and planners. AMP’s motto is believing that financial security can help people turn their possibilities into reality, offering everything from superannuation services for businesses, income protection to low interest rate loans.',
    isBigLender: false,
    mogoId: 1033,
    mogoName: 'AMPBank',
  },
  {
    key: 'GHL',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'GHL',
    lenderId: 5049,
    lenderName: 'Granite Homeloans',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MyStateBank',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'MBL',
    lenderId: 5052,
    lenderName: 'MyState',
    lenderDescription:
      'MyState exist to help people achieve their dreams, both big and small - and just happen to be a bank. Their customers are human and that’s how they treat them, which is why the way they do things are designed to be just that. A human way to bank.',
    isBigLender: false,
    mogoId: 1098,
    mogoName: 'MyState Bank Limited',
  },
  {
    key: 'FLEXI',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'FLEXI',
    lenderId: 5051,
    lenderName: 'Go Flexi',
    lenderDescription:
      'GO Flexi is designed to help borrowers who don’t fit a bank’s regular assessment mold. If you’re self employed, have had issues with credit history, or earn income that doesn’t tick the standard boxes, turn a no into go.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Latitude',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'latitude',
    lenderId: 5005,
    lenderName: 'Latitude Financial',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Moula(PL)',
    aggregatorIds: [2, 1, 3, 8, 6],
    countryCode: 2,
    lenderCode: 'MOULA',
    lenderId: 5002,
    lenderName: 'Moula (PL)',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'pnb',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'MyState',
    lenderId: 3662,
    lenderName: 'P&N Bank',
    lenderDescription:
      'As WA’s largest home-grown and locally owned bank, we offer you the opportunity to grow and develop your skills in an established and stable organisation. P&N have been building successful relationships with our members since 1990. Today, we have more than 100,000 members and provide the financial choice, convenience and service they trust. Unlike our main competitors who are located overseas and interstate, the P&N management base is in Western Australia. Our head offices, Board of Directors and senior management team are all located in Perth. We are proud of our local heritage and our ongoing contribution to the Western Australian community, as we are the only genuinely WA based bank.',
    isBigLender: false,
    mogoId: 1030,
  },
  {
    key: 'LibertyFinancial',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'Lib',
    lenderId: 3659,
    lenderName: 'Liberty',
    lenderDescription:
      'In 1997, Liberty pioneered specialty finance in Australia and New Zealand by offering flexible solutions that required personalised attention that was not available from other financial institutions at the time. Liberty made home ownership possible when the banks said it wasn’t possible. Their innovative and flexible approach has allowed over 140,000 customers to secure loans by advancing over $12 billion in funds. Liberty continue to provide a wide range of home loans, as well as car and business loans. Liberty is an industry award-winning lender that are a strong alternative to regular banks, always offering alternative, innovative solutions at competitive prices to support customers with greater choice and freedom.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BMM',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'BMM',
    lenderId: 3701,
    lenderName: 'BMM - Better Mortgage Management',
    lenderDescription:
      'Better Mortgage Management is one of Australia’s fastest growing mortgage managers - committed to providing the best possible home loan options suited to the individual. At BMM, the company base their operations on efficiency, technology and commitment to service. They offer an extensive range of flexible and competitive financial products, with their staff well-qualified, experienced and genuinely enjoy what they do.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'SuncorpBank',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'Sun',
    lenderId: 3658,
    lenderName: 'Suncorp',
    lenderDescription:
      'Suncorp Group includes leading general insurance, banking, life insurance and superannuation brands in Australia and New Zealand. The Group has 15,000 employees and relationships with nine million customers. We are a Top 20 ASX-listed company with $96 billion in assets. Suncorp has five core businesses include personal insurance, commercial insurance, Vero New Zealand, Suncorp Bank and Suncorp Life. These are supported by corporate and shared services divisions.',
    isBigLender: false,
    mogoId: 1011,
    mogoName: 'Suncorp Metway',
  },
  {
    key: 'FirstMac',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'First',
    lenderId: 3652,
    lenderName: 'FirstMac',
    lenderDescription:
      'Firstmac Limited is an Australian owned alternative bank to the Big4, with 35 years of experience in home and investment loans. Firstmac have secured over 68,000 home loans in the past decade. Firstmac use intelligent online technology and highly-trained local support staff based in an award-winning Australian call centre. They’re dedicated to bringing simple, affordable and innovative financial products to market, underpinned by a lengthy track record of success and a pedigree in prime, mortgage-insured, residential home loans.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'PepperMoney',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'Pepp',
    lenderId: 3649,
    lenderName: 'Pepper Money',
    lenderDescription:
      'Pepper Group is a diversified, independent global financial services business with specialist experience in core disciplines of lending, advisory and asset management across the residential and commercial property sectors, consumer, auto and equipment finance. As a leading non-bank lender, Pepper Group specialise in flexible loan solutions and individual credit assessment. Their lending capabilities include home loans, car loans, personal loans, SME loans, and commercial loans, chattel mortgages, equipment leases, mezzanine debt and fit-out finance.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MortgageEzy',
    aggregatorIds: [2, 1, 3],
    countryCode: 2,
    lenderCode: 'MEZEX',
    lenderId: 3648,
    lenderName: 'Mortgage Ezy',
    lenderDescription:
      'Mortgage Ezy is an innovative national non-bank Lender with a deep understanding of individuality, preferring to focus on niche market needs. Mortgage Ezy achieves its goals as a company by helping other people achieve theirs. Mortgage Ezy is an efficient organisation which leverages its position by processing over a billion dollars of loans annually. They distinguish themselves through immediate, thorough and constructive methodologies offering their customers exciting and flexible loan packages.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ANZ',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'ANZ',
    lenderId: 3647,
    lenderName: 'ANZ Australia',
    lenderDescription:
      'The Australia and New Zealand Banking Group Limited, more commonly known as ANZ, is the third largest bank by market capitalisation in Australia, after the CBA and Westpac. Australian operations make up the largest part of ANZ’s business, with commercial and retail banking dominating. ANZ dates back over 175 years whose commitment to building lasting partnerships with their customers, shareholders and communities in 33 countries in Australia, New Zealand, Asia, the Pacific, the Middle East, Europe and America is evident. ANZ continue to provide an excellent range of banking, financial products and services to over 9 million customers and employ over 50,000 people worldwide.',
    isBigLender: true,
    mogoId: 1005,
    mogoName: 'ANZ',
  },
  {
    key: 'Bankwest',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'BW',
    lenderId: 3646,
    lenderName: 'BankWest',
    lenderDescription:
      'Established in 1895 the Agricultural Bank of Western Australia by the WA Government, this financial institution was set to service the needs of the state’s farmers only. Now called BankWest, they have expanded to service customers nationwide, determined to get to know their clients, not just their banking, no matter where they’re located in Australia. BankWest have both business and home loan banking specialists who are dedicated to tailor make banking solutions and packages for each individual scenario. BankWest’s motto is to make banking easier and are driven by this value.',
    isBigLender: false,
    mogoId: 1016,
    mogoName: 'BankWest',
  },
  {
    key: 'Bluestone',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'Blues',
    lenderId: 3640,
    lenderName: 'Bluestone',
    lenderDescription:
      'Bluestone was established in early 2000 in Sydney, Australia and quickly expanded from a mortgage origination and securitisation platform to become a multinational Capital Management and Asset Management business with offices in the UK, Ireland, Australia and Manila. Since 2000 we have originated over $5bn worth of loans helped more than 22,000 customers and completed a total of 20 securitisations.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MEBank',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'MEB',
    lenderId: 3636,
    lenderName: 'ME Bank',
    lenderDescription:
      'Established by industry super funds, ME Bank is Australian owned and an APRA regulated Bank. ME Bank’s motto is a ’fairer way to bank’, priding themselves on providing a genuinely alternative way to bank. ME Bank’s products are straightforward, easy-to-understand and transparent. As ME Bank is owned by industry super funds, their profits go back to their customers, giving everyday Australians, who are members of industry super funds, unions and employer associations, real service, genuine respect and competitive products with no hidden costs and no bank jargon.',
    isBigLender: false,
    mogoId: 1020,
    mogoName: 'ME Bank',
  },
  {
    key: 'INGDirect',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'ING',
    lenderId: 3635,
    lenderName: 'ING',
    lenderDescription:
      'ING, also known as ING Direct, believe that they reinvented the way Australians bank by delivering simple, straightforward banking and good value products. ING DIRECT (the trading name of ING Bank (Australia) Limited) is the world’s leading direct savings bank and is wholly owned by ING Group. Their motto is = simplicity is best, which also translates to the ING success story. Offering loan packages that are simple and easy to understand is just the start. Keeping general overheads low has made it easy to pass on the savings to their customers.',
    isBigLender: false,
    mogoId: 1014,
    mogoName: 'ING',
  },
  {
    key: 'AdelaideBank',
    aggregatorIds: [2, 1, 3, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'ABL',
    lenderId: 3657,
    lenderName: 'Adelaide Bank',
    lenderDescription:
      'Adelaide Bank focus on strong customer relationships, user-friendly products and fast service. As unique mortgage brokers, they offer a more personalised approach, reliability and direct access their decision makers. Their range of home loan products present a streamlined approach to home lending which appeals to a broad range of customers, boasting on providing great all-round value to their current and new customers.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'LoanMarketGO',
    aggregatorIds: [1, 3, 2],
    countryCode: 2,
    lenderCode: 'LMGO',
    lenderId: 3710,
    lenderName: 'Go Edge',
    lenderDescription:
      'With a GO Edge loan, get expert help from your Loan Market broker to select from a range of simple and easy to understand loans at highly competitive rates. You can feel secure knowing your loan is funded by Advantedge, part of the National Australia Bank Group.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AsapFinance',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'ASAP Finance Limited',
    lenderId: 76,
    lenderName: 'ASAP Finance Limited',
    lenderDescription:
      'ASAP Finance is a leading non-bank lender offering first mortgage finance on residential, commercial and industrial properties. Since 2004 we have partnered with investors, developers and home builders to provide innovative and competitive property funding solutions. Our team combines in-depth industry knowledge with practical development experience to cater to all aspects of your funding requirements.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Sovereign',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'SOV',
    lenderId: 1,
    lenderName: 'Sovereign',
    lenderDescription:
      'AIA Group is the largest life insurance company in the world*, with a presence in 18 markets across Asia Pacific. AIA New Zealand Limited (NZ) is part of the AIA Group and has been providing insurance to New Zealanders since 1981. On 2 July 2018, AIA Group acquired Sovereign. Since then we have worked hard to bring together the best of AIA New Zealand and Sovereign’s existing product ranges, systems and processes. We’re proud to display our combined expertise at the forefront of the insurance industry. From Cape Reinga to Stewart Island, and everywhere in between, we empower our customers to live their version of healthy, every day.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ASB',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'ASB',
    lenderId: 13,
    lenderName: 'ASB',
    lenderDescription:
      'ASB pledged to serve the community; to grow; and to help kiwis grow. We’ve done that over the years by not standing still; by continuing to rethink the way people bank and how they interact with their money; by not just being another ‘bank’. We’re also inspired by success. Big or small, every milestone reached by our customers is a success and pushes us to go even further; to do better. Which is probably why more than 1.3 million personal, business and rural customers choose to bank with us.',
    isBigLender: true,
    mogoId: 0,
  },
  {
    key: 'ANZ',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'AsapFinance',
    lenderId: 14,
    lenderName: 'ANZ',
    lenderDescription:
      'ANZ is currently one of New Zealand’s largest companies based on profit and assets. ANZ finance over 30% of home loans in NZ, and have a banking relationship with nearly one in two New Zealanders.',
    isBigLender: true,
    mogoId: 0,
  },
  {
    key: 'Wisr',
    aggregatorIds: [1, 2, 3, 6],
    countryCode: 2,
    lenderCode: 'WISR',
    lenderId: 3912,
    lenderName: 'WISR',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MilduraFinance',
    aggregatorIds: [1, 3, 2],
    countryCode: 2,
    lenderCode: 'MILF',
    lenderId: 3910,
    lenderName: 'Mildura Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'DepositBondAustralia',
    aggregatorIds: [1, 2, 3, 7],
    countryCode: 2,
    lenderId: 3906,
    lenderName: 'Deposit Bond Australia',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Keystart',
    aggregatorIds: [1, 3, 2, 8, 6, 7],
    countryCode: 2,
    lenderCode: 'KHL',
    lenderId: 3900,
    lenderName: 'Keystart Home Loans',
    lenderDescription:
      'Keystart was established in 1989 to provide low-deposit home loans to West Australians unable to meet the deposit requirements from mainstream lenders.  Keystart reached a milestone in 2014, during its 25th anniversary – it has helped more than 85,000 West Australians, those who never thought they would be able to own a home, access home ownership.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Pepper',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'Pepper Money',
    lenderId: 120,
    lenderName: 'Pepper',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Basecorp',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'Basecorp',
    lenderId: 119,
    lenderName: 'Basecorp Finance',
    lenderDescription:
      'Basecorp Finance is a registered financial service provider, and a member of the Financial Services Complaints Limited disputes resolution scheme. It strives to be a leader in the non-bank sector in terms of its values and ethics in relation to responsible lending & legislative compliance.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'SouthernCross',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'SouthernCross',
    lenderId: 118,
    lenderName: 'Southern Cross',
    lenderDescription:
      'Established in 1997, Southern Cross Financial Group (SCFG) is a 100% NZ owned finance company that has specialised in short term property finance and first mortgage investments.',
    isBigLender: false,
    mogoId: 1108,
    mogoName: 'Southern Cross CU',
  },
  {
    key: 'AssetFinance-Select',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'Select - Asset',
    lenderId: 117,
    lenderName: 'Asset Finance - Select',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'PROSPA',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'PROSPA',
    lenderId: 104,
    lenderName: 'PROSPA',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Select',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'Select',
    lenderId: 103,
    lenderName: 'Select Home Loan',
    lenderDescription:
      'Select was established in New Zealand in 2019 as a residential mortgage lender. Our range of loans aims to provide flexible loan solutions to those who fall outside the traditional lenders’ criteria - helping more customers, more often.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ZipBusiness',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'Zip Business',
    lenderId: 96,
    lenderName: 'Zip Business',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BankOfChina',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'BOC',
    lenderId: 93,
    lenderName: 'Bank of China (New Zealand) Limited',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ICBC',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'ICBC',
    lenderId: 92,
    lenderName: 'Industrial and Commercial Bank of China (New Zealand) Limited',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'CCB',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'CCB',
    lenderId: 91,
    lenderName: 'China Construction Bank (New Zealand) Limited',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'FirstMortgageTrust',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'First Mortgage Trust',
    lenderId: 90,
    lenderName: 'First Mortgage Trust',
    lenderDescription:
      'As a group investment fund we offer borrowers smart finance options for borrowing against land and buildings and pride ourselves on our professional friendly service, competitive interest rates, flexible loan terms, quick turn-around, minimal paperwork and capitalised interest options - and right now, we have money to lend! Investors can enjoy competitive returns backed by a portfolio of loans secured by first mortgages that have been above those offered by trading banks on a one year deposit. If you are serious about making a smart choice, you should consider investing with First Mortgage Trust and its proven track record in providing returns to investors. First Mortgage Trust is your viable bank alternative for property finance and investments.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'CressidaCapital',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'CressidaCapital',
    lenderId: 85,
    lenderName: 'Cressida Capital',
    lenderDescription:
      'Established in 2003, Cressida Capital is a privately funded finance business represented by a team of property professionals dedicated to meeting your non-bank funding requirements. We deliver property finance solutions to those who require speed and flexibility at market leading rates. Every year we assist property investors, and developers to realise their visions, as well as businesses, trusts and entrepreneurs to leverage their property assets, in ways that mainstream banks are unable to consider. You can trust us to be there when the bank says no and help unlock the potential in your property assets.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'DBR',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'DBR',
    lenderId: 87,
    lenderName: 'DBR',
    lenderDescription:
      'DBR is a local, privately owned non-bank lender providing fast and fair short-term property finance solutions across a wide range of residential and commercial properties. Since our inception in 2008, we’ve provided over 1000 property finance solutions that have given clients the time to consolidate and move their projects forward.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'NZCU',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'NZCU Baywide',
    lenderId: 80,
    lenderName: 'NZCU',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'LibertyFinancial',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'Liberty',
    lenderId: 72,
    lenderName: 'Liberty Financial Limited',
    lenderDescription:
      'The Liberty Group is a highly regarded pioneer and leading specialty financial services company with operations across Australia and New Zealand. With offices in Auckland, Melbourne and Sydney, the group employs over 300 staff and is backed by global banks such as Credit Suisse, Commonwealth Bank, Deutsche Bank, Macquarie Bank, National Australia Bank and Westpac.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'TSB',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'TSB',
    lenderId: 60,
    lenderName: 'TSB',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'HeartlandBank',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'HeartlandBank',
    lenderId: 55,
    lenderName: 'Heartland Bank',
    lenderDescription:
      'Heartland Seniors Finance is Australia’s leading reverse mortgage provider. Established in 2004, we have helped over 19,000 people aged 60 and over release more than $1bn of equity from their homes, helping them to live a more comfortable retirement, with independence and dignity.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'SBSBank',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'SBS',
    lenderId: 53,
    lenderName: 'SBS Bank',
    lenderDescription:
      'We are New Zealand’s member bank, so when you bank with us; we’re with you. As a member-owned bank when you become a member you become part of SBS Bank. We’re committed to creating a partnership focused on helping you on the journey to financial independence. We want to build mutual trust, mutual benefits and most of all mutual respect. From your first home, to investing, retirement and anything in between. We believe that’s the way banking should be. SBS Bank – We’re with you.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'KiwiBank',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'KiwiBank',
    lenderId: 34,
    lenderName: 'Kiwibank',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'BNZ',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'BNZ',
    lenderId: 24,
    lenderName: 'BNZ',
    lenderDescription:
      'We’ve been helping our customers make their goals a reality for over 150 years, and now we’re working with them to redesign banking for the future. We put people first. That’s why we like to help our local communities, through volunteering and partnerships. We believe that protecting the environment is the responsibility of all of us – and we take active steps to minimise our impact. We are also getting behind New Zealand charities and organisations and helping them to flourish is an important part of what we do.',
    isBigLender: true,
    mogoId: 0,
  },
  {
    key: 'Avanti',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'Avanti',
    lenderId: 21,
    lenderName: 'Avanti Finance',
    lenderDescription:
      'Since 1989, Avanti has helped nearly 100,000 New Zealanders by providing finance to buy a car, pay a bill or buy a home. Our customers come from around Aotearoa, from all walks of life, with different ages, incomes, gender and ethnicity.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'TheCooperativeBank',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'TCB',
    lenderId: 17,
    lenderName: 'The Co-operative Bank',
    lenderDescription:
      'We’ve been doing the right thing by our customers since we began our journey in 1928, right here in New Zealand. As the only bank in New Zealand that shares our profits with our customers, it’s one of the many ways we do right by you. We’re thrilled to have shared more than $12 million in profits with our customers in local communities nationwide, since 2013.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Resimac',
    aggregatorIds: [5, 4],
    countryCode: 1,
    lenderCode: 'Resimac',
    lenderId: 77,
    lenderName: 'RESIMAC',
    lenderDescription:
      'RESIMAC Home Loans is a non-bank lender focused on providing a broad range of home loan solutions and competitively low interest rates for New Zealand borrowers. RESIMAC Home Loans has a team of professionals whose aim is to make the task of obtaining a home loan a pleasant experience for every borrower, and continue offering personal service to existing customers by looking after their loan.',
    isBigLender: false,
    mogoId: 1107,
  },
  {
    key: 'CFML',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'CFML',
    lenderId: 121,
    lenderName: 'CFML',
    lenderDescription:
      'Funding Partners is an agile and responsive New Zealand based property funding firm. Specialising in first mortgages within the non-consumer property sector, the team at Funding Partners is made up of skilled property professionals with many years of expertise. With the focus being on the security of the property rather than red tape, Funding Partners is able to act where traditional bank funding struggles. Funding Partners offers tailor-made solutions for individual property situations.',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Cigna',
    aggregatorIds: [4, 5],
    countryCode: 1,
    lenderCode: 'Cigna',
    lenderId: 9,
    lenderName: 'Cigna',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'TeachersMutualBank',
    aggregatorIds: [7, 8, 6],
    countryCode: 2,
    lenderCode: 'Teachers Mutual Bank',
    lenderId: 3621,
    lenderName: 'Teachers Mutual Bank',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'DepositPower',
    aggregatorIds: [7, 8, 6],
    countryCode: 2,
    lenderId: 3820,
    lenderName: 'Deposit Power',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AuswideBank',
    aggregatorIds: [7, 8, 6],
    countryCode: 2,
    lenderCode: 'Auswide Bank',
    lenderId: 3645,
    lenderName: 'Auswide Bank',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'CreditUnionSA',
    aggregatorIds: [7, 6],
    countryCode: 2,
    lenderCode: 'CUS',
    lenderId: 3704,
    lenderName: 'Credit Union SA',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'NewcastlePermanentBuildingSociety(NPBS)',
    aggregatorIds: [6],
    countryCode: 2,
    lenderCode: 'NPB',
    lenderId: 3718,
    lenderName: 'Newcastle Permanent Building Society (NPBS)',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: '86400',
    aggregatorIds: [8, 7, 6],
    countryCode: 2,
    lenderCode: '86400',
    lenderId: 5054,
    lenderName: '86 400',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'FASTExcel',
    aggregatorIds: [8],
    countryCode: 2,
    lenderCode: 'FASTEX',
    lenderId: 5071,
    lenderName: 'FASTExcel',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'UniBank',
    aggregatorIds: [7, 8, 6],
    countryCode: 2,
    lenderCode: 'UNI',
    lenderId: 5058,
    lenderName: 'UniBank',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'HealthProfessionalsBank',
    aggregatorIds: [7, 8, 6],
    countryCode: 2,
    lenderCode: 'HPB',
    lenderId: 5057,
    lenderName: 'Health Professionals Bank',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ChoiceCustom',
    aggregatorIds: [7],
    countryCode: 2,
    lenderCode: 'CHCU',
    lenderId: 5056,
    lenderName: 'ChoiceCustom',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'FirefightersMutualBank',
    aggregatorIds: [7, 8, 6],
    countryCode: 2,
    lenderCode: 'FMB',
    lenderId: 5062,
    lenderName: 'Firefighters Mutual Bank',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'FASTCustom',
    aggregatorIds: [8],
    countryCode: 2,
    lenderCode: 'FASTCU',
    lenderId: 5068,
    lenderName: 'FASTCustom',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'FASTLend',
    aggregatorIds: [8],
    countryCode: 2,
    lenderCode: 'FASTL',
    lenderId: 5070,
    lenderName: 'FASTLend',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'VictorianMortgageGroup',
    aggregatorIds: [8, 6],
    countryCode: 2,
    lenderCode: 'VMG',
    lenderId: 5061,
    lenderName: 'Victorian Mortgage Group',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MortgageMart',
    aggregatorIds: [8, 6, 7],
    countryCode: 2,
    lenderCode: 'MM',
    lenderId: 5065,
    lenderName: 'Mortgage Mart',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'PLANCustom',
    aggregatorIds: [6],
    countryCode: 2,
    lenderCode: 'PLANCU',
    lenderId: 5067,
    lenderName: 'PLANCustom',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AustralianFinancial',
    aggregatorIds: [6, 7],
    countryCode: 2,
    lenderCode: 'AUF',
    lenderId: 5066,
    lenderName: 'Australian Financial',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'PLANLend',
    aggregatorIds: [6],
    countryCode: 2,
    lenderCode: 'PLANL',
    lenderId: 5064,
    lenderName: 'PLANLend',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'OakCapital',
    aggregatorIds: [6],
    countryCode: 2,
    lenderCode: 'OAK',
    lenderId: 5060,
    lenderName: 'Oak Capital',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'PLANExcel',
    aggregatorIds: [6],
    countryCode: 2,
    lenderCode: 'PLANEX',
    lenderId: 5059,
    lenderName: 'PLANExcel',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'RedZed',
    aggregatorIds: [7],
    countryCode: 2,
    lenderCode: 'RED',
    lenderId: 5063,
    lenderName: 'RedZed',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ChoiceLend',
    aggregatorIds: [7],
    countryCode: 2,
    lenderCode: 'CHL',
    lenderId: 5055,
    lenderName: 'ChoiceLend',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ChoiceExcel',
    aggregatorIds: [7],
    countryCode: 2,
    lenderCode: 'CHEX',
    lenderId: 5069,
    lenderName: 'ChoiceExcel',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'DepositAssure',
    aggregatorIds: [2, 3, 1, 8, 6],
    countryCode: 2,
    lenderId: 5080,
    lenderName: 'Deposit Assure',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Businessloans.com.au',
    aggregatorIds: [8, 6],
    countryCode: 2,
    lenderCode: 'BLAF',
    lenderId: 5095,
    lenderName: 'Businessloans.com.au',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AgilitiCapital',
    aggregatorIds: [7],
    countryCode: 2,
    lenderCode: 'ACAF',
    lenderId: 5074,
    lenderName: 'Agiliti Capital',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'AussieBonds',
    aggregatorIds: [8],
    countryCode: 2,
    lenderId: 5084,
    lenderName: 'Aussie Bonds',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'LoansToday',
    aggregatorIds: [8, 6],
    countryCode: 2,
    lenderId: 5079,
    lenderName: 'Loans Today',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'OnDeck',
    aggregatorIds: [8],
    countryCode: 2,
    lenderCode: 'ODAF',
    lenderId: 5082,
    lenderName: 'OnDeck',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'ScottishPacificAssetFinance',
    aggregatorIds: [6],
    countryCode: 2,
    lenderCode: 'SPAF',
    lenderId: 5073,
    lenderName: 'Scottish Pacific Asset Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: "People'sChoiceCreditUnion",
    aggregatorIds: [6],
    countryCode: 2,
    lenderId: 5103,
    lenderName: "People's Choice Credit Union",
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'MedfinAssetFinance',
    aggregatorIds: [8, 7, 6],
    countryCode: 2,
    lenderCode: 'MEDAF',
    lenderId: 5104,
    lenderName: 'Medfin Asset Finance',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
  {
    key: 'Athena',
    aggregatorIds: [8],
    countryCode: 2,
    lenderCode: 'Athena',
    lenderId: 5128,
    lenderName: 'Athena',
    lenderDescription: '',
    isBigLender: false,
    mogoId: 0,
  },
];
