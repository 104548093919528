import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import locale from 'config/locale';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';

import {
  PREFERRED_COUNTRY_CODES,
  EXCLUDED_COUNTRY_CODES,
} from 'shared/constants/myCRMTypes/countries';

import styles from './AddressInputIntl.css';

const AddressInputIntl = (props) => {
  const {
    disabled,
    value = {},
    isIntl,
    serviceFn,
    setAsyncError,
    removeAsyncError,
    onChange,
    sharedProps,
  } = props;
  const initState = {
    formattedAddress: value.formattedAddress,
    countryCode: value.countryCode || locale.data.countryCode,
  };

  useEffect(() => {
    const flagComponent = document.querySelectorAll('.selected-flag');
    if (flagComponent.length) {
      flagComponent[0].removeAttribute('title');
    }
  }, []);

  const [address, updateAddress] = useState(initState);
  const btnClass = disabled ? 'flag-dropdown-disabled' : '';

  const handleFlagChange = (address, countryData) => {
    if (countryData) {
      updateAddress({
        formattedAddress: '',
        countryCode: countryData.countryCode.toUpperCase(),
      });
    }
  };

  const handleSearch = (query) => serviceFn(query, address.countryCode);
  const handleInputChange = (str) =>
    updateAddress({
      ...address,
      formattedAddress: str.text,
      placeId: str.placeId,
    });
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const sanitiseSearchResults = (results) =>
    results.map(({ text, placeId }) => ({ text, key: text, placeId }));

  useEffect(() => {
    if (
      Object.keys(address).length > 0 &&
      address.formattedAddress !== undefined
    ) {
      onChange(address);
    }
  }, [address]);

  return (
    <>
      <PhoneInput
        defaultCountry={(
          value.countryCode || locale.data.countryCode
        ).toLowerCase()}
        preferredCountries={PREFERRED_COUNTRY_CODES}
        excludeCountries={EXCLUDED_COUNTRY_CODES}
        onChange={handleFlagChange}
        disabled={!isIntl || disabled}
        buttonClass={btnClass}
        disableAreaCodes
        disableCountryCode
        dropdownClass={styles.dropdown}
      />
      <AutocompleteInput
        setAsyncError={setAsyncError}
        removeAsyncError={removeAsyncError}
        serviceFn={handleSearch}
        value={address.formattedAddress || value.formattedAddress}
        action={handleInputChange}
        disabled={disabled}
        hasFlag
        customSanitiseResults={sanitiseSearchResults}
        {...sharedProps}
      />
    </>
  );
};

AddressInputIntl.propTypes = {
  value: PropTypes.object,
  disabled: PropTypes.bool,
  setAsyncError: PropTypes.func,
  removeAsyncError: PropTypes.func,
  serviceFn: PropTypes.func,
  onChange: PropTypes.func,
  isIntl: PropTypes.bool,
  sharedProps: PropTypes.object,
};

export default AddressInputIntl;
