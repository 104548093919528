import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import _ from 'lodash';

import { FIXED_RATE } from 'shared/constants/interestType';
import {
  REPAYMENT_TYPE_INTEREST_ONLY_VALUE,
  INTEREST_ONLY_TERM_ALL,
  INTEREST_ONLY_TERM_TEN_YEARS_PLUS,
} from 'shared/constants/repaymentTypes';
import {
  DEFAULT_LOAN_YEAR,
  DEFAULT_STRUCTURE_INITIAL_STATE,
} from 'shared/constants/defaults';
import { unformatCurrency } from 'shared/lib/numbrero';
import { formatCurrencyOnChange } from 'lib/utils/numberUtils';
import { yearsOptions } from 'lib/utils/stringUtils';
import { addOrRemoveFromArray } from 'lib/utils/dataUtils';

import { comparePageFilterCopies } from 'lib/copyHelper';

import AccordionButton from 'components/AccordionButton/AccordionButton';
import Button from 'components/Button/Button';
import Checklist from 'components/Checklist/Checklist';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import FilterLenderList from 'components/FilterLenderList/FilterLenderList';
import FullScreenSection from 'components/FullScreenSection/FullScreenSection';
import Question from 'components/Question/Question';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Selection from 'components/Selection/Selection';
import Form from 'components/Form/Form';

export const messages = defineMessages({
  loanAmountAndTermText: {
    id: 'ProductFilter.loanAmountAndTerm.text',
    defaultMessage: 'Loan amount & term',
  },
  interestTypeText: {
    id: 'ProductFilter.interestType.text',
    defaultMessage: 'Interest type',
  },
  repaymentTypeText: {
    id: 'ProductFilter.repaymentType.text',
    defaultMessage: 'Repayment type',
  },
  featuresText: {
    id: 'ProductFilter.features.text',
    defaultMessage: 'Features',
  },
  productTypeText: {
    id: 'ProductFilter.productType.text',
    defaultMessage: 'Product Type',
  },
  productTypeTooltip: {
    id: 'ProductFilter.productType.tooltip',
    defaultMessage:
      'Search for a specific type of home loan, such as the commonly selected Owner Occupied.',
  },
  lvrText: {
    id: 'ProductFilter.lvr.text',
    defaultMessage: 'LVR',
  },
  lvrTooltip: {
    id: 'ProductFilter.lvr.tooltip',
    defaultMessage:
      'The Loan to Value Ratio (LVR) is a measure of how much you’re borrowing versus the value of the property. If your LVR is greater than 80% you may have to pay Lenders Mortgage Insurance (LMI), potentially increasing the overall loan amount by about 2%.',
  },
  documentationText: {
    id: 'ProductFilter.documentation.text',
    defaultMessage: 'Documentation',
  },
  resetAllText: {
    id: 'ProductFilter.resetAll.text',
    defaultMessage: 'Reset all',
  },
  updateSearchText: {
    id: 'ProductFilter.updateSearch.text',
    defaultMessage: 'Update search',
  },
  advancedFilterText: {
    id: 'ProductFilter.advancedFilter.text',
    defaultMessage:
      '{showAdvancedFilters, select, true {Close advanced filters} false {Advanced filters}}',
  },
  searchHomeLoansText: {
    id: 'ProductFilter.searchHomeLoans.text',
    defaultMessage: 'Search',
  },
  interestOnlyTerm: {
    id: 'ProductFilter.interestOnlyTerm.text',
    defaultMessage: 'Minimum Interest Only Term',
  },
  fixedRateTerm: {
    id: 'ProductFilter.fixedRateTerm.text',
    defaultMessage: 'Fixed Rate Term',
  },
});

const questionStyle = 'comparePage';

export class ProductFilter extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    structure: PropTypes.object.isRequired,
    isShow: PropTypes.bool.isRequired,
    submitAction: PropTypes.func.isRequired,
    closeFilterAction: PropTypes.func.isRequired,
    accreditedLenderIds: PropTypes.arrayOf(PropTypes.number),
  };

  constructor(props) {
    super(props);
    const { structure } = props;
    this.state = {
      showAdvancedFilters: false,
      ..._.cloneDeep(structure),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isShow && nextProps.isShow) {
      this.setState({
        showAdvancedFilters: false,
        ..._.cloneDeep(nextProps.structure),
      });
    }
  }

  onChangeFilterHandler = (key, formatFunc) => (value) => {
    this.setState({ [key]: formatFunc ? formatFunc(value) : value });
  };

  onSubmit = () => {
    const { submitAction } = this.props;
    submitAction(this.state);
  };

  setLenders = (lenderId) => {
    this.setState({
      selectedLenderIds: addOrRemoveFromArray(
        this.state.selectedLenderIds,
        lenderId,
      ),
    });
  };

  resetAll = () => {
    this.setState({
      ...DEFAULT_STRUCTURE_INITIAL_STATE,
      loanAmount: this.state.loanAmount,
    });
  };

  toggleAdvanced = () => {
    this.setState({ showAdvancedFilters: !this.state.showAdvancedFilters });
  };

  renderAdvancedFilters(itemsWithCopy) {
    const {
      intl: { formatMessage },
    } = this.props;
    const { productType, lvr, financials } = this.state;
    return [
      <Question
        id='productTypeFilter'
        label={formatMessage(messages.productTypeText)}
        className={questionStyle}
        tooltip={formatMessage(messages.productTypeTooltip)}
        key='productTypeQuestion'
      >
        <Selection
          id='productType'
          action={this.onChangeFilterHandler('productType')}
          value={productType}
          items={itemsWithCopy.productType.items}
          selectionDefault={productType}
          fullWidth
        />
      </Question>,

      <Question
        id='lvrFilter'
        label={formatMessage(messages.lvrText)}
        tooltip={formatMessage(messages.lvrTooltip)}
        className={questionStyle}
        key='lvrQuestion'
      >
        <Selection
          id='productLVR'
          action={this.onChangeFilterHandler('lvr', _.toNumber)}
          value={lvr}
          items={itemsWithCopy.lvr.items}
          selectionDefault={lvr}
          fullWidth
        />
      </Question>,

      <Question
        id='documentationFilter'
        label={formatMessage(messages.documentationText)}
        className={questionStyle}
        key='documentationQuestion'
      >
        <AccordionButton
          id='financials'
          name={itemsWithCopy.financials.summary}
          key='accFinancials'
        >
          <RadioButtonList
            id='financials'
            value={financials}
            action={this.onChangeFilterHandler('financials')}
            items={itemsWithCopy.financials.items}
            className='filterRadioButton'
            isFilterStyle
          />
          <hr />
        </AccordionButton>
      </Question>,
    ];
  }

  render() {
    const {
      intl: { formatMessage },
      isShow,
      closeFilterAction,
      accreditedLenderIds,
    } = this.props;

    const {
      interestType,
      initialPeriod,
      repaymentType,
      selectedLenderIds,
      showAdvancedFilters,
    } = this.state;

    const itemsWithCopy = comparePageFilterCopies(formatMessage, this.state);

    const advancedFilterText = formatMessage(messages.advancedFilterText, {
      showAdvancedFilters,
    });
    const iconAdvanced = showAdvancedFilters
      ? 'sl-custom-cross-1'
      : 'sl-custom-filter';
    const ioTerms = yearsOptions(formatMessage, 9);
    const isInterestOnlySelected =
      repaymentType === REPAYMENT_TYPE_INTEREST_ONLY_VALUE;
    return (
      <FullScreenSection
        id='searchFilters'
        title={formatMessage(messages.searchHomeLoansText)}
        isShown={isShow}
        close={closeFilterAction}
      >
        <Form id='productFilter' onSubmit={this.onSubmit}>
          <FilterLenderList
            action={this.setLenders}
            selectedLenderIds={selectedLenderIds || []}
            accreditedLenderIds={accreditedLenderIds}
            inline
          />

          <Question
            id='loanAmountAndTermFilter'
            label={formatMessage(messages.loanAmountAndTermText)}
            className='comparePage'
          >
            <CurrencyInputWithSelection
              id='loanAmountAndYear'
              dispatchActionOnBlur={false}
              value={this.state.loanAmount}
              action={this.onChangeFilterHandler(
                'loanAmount',
                unformatCurrency,
              )}
              selectionAction={this.onChangeFilterHandler(
                'loanTerm',
                _.toNumber,
              )}
              selectionValue={this.state.loanTerm}
              selectionItems={yearsOptions(formatMessage)}
              selectionDefault={DEFAULT_LOAN_YEAR}
              onChange={formatCurrencyOnChange}
            />
          </Question>

          <Question
            id='interestTypeFilter'
            label={formatMessage(messages.interestTypeText)}
            className={questionStyle}
          >
            <AccordionButton
              id='interestType'
              name={itemsWithCopy.interestType.summary}
            >
              <Checklist
                id='interestType'
                value={interestType}
                action={this.onChangeFilterHandler('interestType')}
                items={itemsWithCopy.interestType.items}
                inline
              />
              {interestType && interestType[FIXED_RATE] && (
                <Question
                  id='initialPeriodFilter'
                  label={formatMessage(messages.fixedRateTerm)}
                  className={questionStyle}
                >
                  <Selection
                    id='fixedRateTerm'
                    action={this.onChangeFilterHandler('initialPeriod')}
                    value={this.state.initialPeriod}
                    items={itemsWithCopy.initialPeriod.items}
                    selectionDefault={initialPeriod}
                    fullWidth
                  />
                </Question>
              )}
              <hr />
            </AccordionButton>
          </Question>

          <Question
            id='repaymentTypeFilter'
            label={formatMessage(messages.repaymentTypeText)}
            className={questionStyle}
          >
            <AccordionButton
              id='repaymentType'
              name={itemsWithCopy.repaymentType.summary}
            >
              <RadioButtonList
                id='repaymentType'
                value={this.state.repaymentType}
                action={this.onChangeFilterHandler('repaymentType')}
                items={itemsWithCopy.repaymentType.items}
                className='filterRadioButton'
                isFilterStyle
              />

              {isInterestOnlySelected && (
                <Question
                  id='interestOnlyTermFilter'
                  label={formatMessage(messages.interestOnlyTerm)}
                  className={questionStyle}
                >
                  <Selection
                    id='interestOnlyTerm'
                    action={this.onChangeFilterHandler('interestOnlyTerm')}
                    value={this.state.interestOnlyTerm}
                    items={[
                      INTEREST_ONLY_TERM_ALL,
                      ...ioTerms,
                      INTEREST_ONLY_TERM_TEN_YEARS_PLUS,
                    ]}
                    selectionDefault={this.state.interestOnlyTerm}
                    fullWidth
                  />
                </Question>
              )}
              <hr />
            </AccordionButton>
          </Question>

          <Question
            id='featuresFilter'
            label={formatMessage(messages.featuresText)}
            className={questionStyle}
          >
            <AccordionButton
              id='features'
              name={itemsWithCopy.features.summary}
            >
              <Checklist
                id='loanFeature'
                value={this.state.features}
                action={this.onChangeFilterHandler('features')}
                items={itemsWithCopy.features.items}
                inline
              />
              <hr />
            </AccordionButton>
          </Question>

          {showAdvancedFilters && this.renderAdvancedFilters(itemsWithCopy)}

          <div className='rowContainer'>
            <Button
              onClick={this.toggleAdvanced}
              icon={iconAdvanced}
              theme='filterToggle'
              id='advanceSearch'
            >
              {advancedFilterText}
            </Button>
            <Button onClick={this.resetAll} theme='filterToggle' id='resetAll'>
              {formatMessage(messages.resetAllText)}
            </Button>
          </div>
          <Button
            onClick={this.onSubmit}
            className='brandColor__button'
            id='updateSearch'
          >
            {formatMessage(messages.updateSearchText)}
          </Button>
        </Form>
      </FullScreenSection>
    );
  }
}

export default injectIntl(ProductFilter);
