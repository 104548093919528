import { createActions, createAction } from 'redux-actions';
import * as actionTypes from './liabilityActionTypes';
import * as individualActionTypes from './individualLiabilityActionTypes';

const actionWithWrapper = (type) => (id) =>
  createAction(type, (payload) => ({ id, value: payload }));

const individualLiabilityActionsValues = Object.values(individualActionTypes);
export const individualLiabilityActions = individualLiabilityActionsValues.reduce(
  (prev, type) => {
    const next = prev;
    next[type] = actionWithWrapper(type);
    return next;
  },
  {},
);

export const liabilityActions = createActions(
  {},
  ...Object.values(actionTypes),
);

export default { ...liabilityActions, ...individualLiabilityActions };
