import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import LoanMarketLogo from 'components/LoanMarketLogo/LoanMarketLogo';
import delay from 'hocs/delay';

import styles from './WelcomeTo.css';

class WelcomeTo extends Component {
  static propTypes = {
    next: PropTypes.func,
    advisorOrg: PropTypes.object,
    advisor: PropTypes.object,
    isBrokerLogin: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      showWelcome: false,
    };
  }

  componentDidMount = () => {
    this.showWelcomeTimeout = setTimeout(() => {
      this.setState({
        showWelcome: true,
      });
    }, 400);
    this.nextTimout = setTimeout(() => {
      this.props.next();
    }, 2000);
  };

  componentWillUnmount = () => {
    if (this.showWelcomeTimeout) {
      clearInterval(this.showWelcomeTimeout);
    }
    if (this.nextTimout) {
      clearInterval(this.nextTimout);
    }
  };

  renderLogo = (brandLogoUrl, isOwnBrand) => {
    if (isOwnBrand) {
      return (
        brandLogoUrl && <img src={brandLogoUrl} className={styles.brandLogo} />
      );
    }
    return <LoanMarketLogo inverse />;
  };

  render() {
    const { showWelcome } = this.state;
    const {
      advisorOrg: { brandLogoUrl, businessName, tradingName, isOwnBrand },
    } = this.props;
    const { advisor } = this.props;
    const advisorName = advisor && (advisor.preferredName || advisor.name);
    const orgName = tradingName || businessName || 'Loan Market';

    return (
      <AnimationWrapper key='welcomeTo' id='welcomeTo'>
        <div className={styles.root}>
          <div className={`${styles.logoWrapper} alignCenter`}>
            {this.renderLogo(brandLogoUrl, isOwnBrand)}
          </div>
          <CSSTransitionGroup
            transitionName='slowlyFadeIn'
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={0}
          >
            {showWelcome &&
              (this.props.isBrokerLogin ? (
                <h1 className='alignCenter'>{`Welcome back ${advisorName}.`}</h1>
              ) : (
                <h1 className='alignCenter'>{`Welcome to ${orgName}.`}</h1>
              ))}
          </CSSTransitionGroup>
        </div>
      </AnimationWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advisorOrg: state.advisorOrg,
    advisor: state.advisor,
  };
};

export default delay(connect(mapStateToProps)(WelcomeTo));
