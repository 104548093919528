import { without, omit } from 'lodash';
import { takeEvery, all, put, call, select } from 'redux-saga/effects';

import * as _ from 'sagas/addressSagas';
import { convertPropertyToOwned } from 'sagas/propertySagas';

import * as contactSelectors from 'selectors/contactSelectors';
import * as addressSelectors from 'selectors/addressSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as propertySelectors from 'selectors/propertySelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import {
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  SET_IS_AT_PRIMARY_ADDRESS,
  SET_IS_PRIMARY_AT_PARTNER_ADDRESS,
} from 'actions/addressActionTypes';
import { ADDRESS_TYPE_CURRENT } from 'shared/constants/myCRMTypes/address';
import { PROPERTY_NOT_OWNED_ID } from 'constants/tempIds';
import addressActions from 'actions/addressActions';
import propertyActions from 'actions/applyPropertyActions';

import {
  postAddressForContact,
  putAddressForContact,
  delAddressForContact,
} from 'services/contactApi';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';
import {
  getPropertyOwnershipById,
  isPropertyOwnedByClient,
} from 'lib/propertyHelper';

export function* createAddress({ payload }) {
  const selector = yield select(contactSelectors.clientOrCompanyContactId);
  const contactId = yield call(selector, payload.clientIds[0]);
  const address = yield call(postAddressForContact, contactId, payload);
  yield put(addressActions.setNewAddress(address));
}

export function* updateResidencePropertyAddress(addressDetails) {
  const residenceProperty = yield select(propertySelectors.residenceEntity);
  const newPropertyOwnership = getPropertyOwnershipById(
    addressDetails.ownershipTypeId,
  );
  if (!residenceProperty || residenceProperty.owned === newPropertyOwnership) {
    return;
  }

  const { clientIds, address } = addressDetails;
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const updatedResidenceProperty = {
    ...residenceProperty,
    owned: newPropertyOwnership,
    address,
    applicationId,
  };
  yield put(propertyActions.setProperty(updatedResidenceProperty));
  if (
    !isPropertyOwnedByClient(residenceProperty) &&
    isPropertyOwnedByClient(updatedResidenceProperty)
  ) {
    yield call(convertPropertyToOwned, clientIds, updatedResidenceProperty);
  }
}

export function* updateAddress({ payload }) {
  const selector = yield select(contactSelectors.clientOrCompanyContactId);
  const contactId = yield call(selector, payload.clientIds[0]);
  const address = yield call(putAddressForContact, contactId, payload);
  yield put(addressActions.setAddress(address));
  if (payload.typeId === ADDRESS_TYPE_CURRENT.id) {
    yield call(_.updateResidencePropertyAddress, payload);
  }
}

const isAddressNew = (address) =>
  typeof address.id === 'string' && address.id.includes('new');

export function* createOrUpdateAddress(
  address,
  clientId,
  isClientNew = false,
  forCompany = false,
) {
  let payload = { ...address, forCompany };
  if (isClientNew) {
    payload.clientIds = without([...payload.clientIds, clientId], 'new');
  }
  if (isAddressNew(address)) {
    if (!address.address.formattedAddress) {
      return;
    }
    payload = omit(payload, 'id');
    yield call(createAddress, { payload });
  } else {
    yield call(updateAddress, { payload });
  }
}

export function* deleteAddress({ payload: addressId }) {
  const addressSelector = yield select(addressSelectors.working);
  const address = yield call(addressSelector, addressId);
  const selector = yield select(contactSelectors.clientOrCompanyContactId);
  const contactId = yield call(selector, address.clientIds[0]);
  yield call(delAddressForContact, contactId, addressId);
  yield put(addressActions.removeAddress(addressId));
  const currentAddress = yield select(addressSelectors.primaryCurrentAddress);
  const residenceEntity = yield select(propertySelectors.residenceEntity);
  if (!currentAddress && (residenceEntity || {}).id === PROPERTY_NOT_OWNED_ID) {
    yield put(propertyActions.removePropertyApply(residenceEntity.id));
  }
}

export function* setIsAtPrimaryAddress({
  payload: { value, clientId, currentAddressId },
}) {
  const primaryCurrentAddress = yield select(
    addressSelectors.primaryCurrentAddress,
  );
  if (value) {
    const newClientIds = [...primaryCurrentAddress.clientIds, clientId];
    yield put(
      addressActions.setAddressClientIds(primaryCurrentAddress.id)(
        newClientIds,
      ),
    );
    yield put(addressActions.clearWorkingAddress(currentAddressId));
  } else {
    const newClientIds = without(primaryCurrentAddress.clientIds, clientId);
    yield put(
      addressActions.setAddressClientIds(primaryCurrentAddress.id)(
        newClientIds,
      ),
    );

    yield put(addressActions.loadNewCurrentAddress([clientId]));
    const addressSelector = yield select(
      addressSelectors.workingClientCurrentAddress,
    );
    const loadedAddress = addressSelector(clientId);
    yield put(addressActions.setAddressAddress(loadedAddress.id)(''));
  }
}

export function* setIsPrimaryAtPartnerAddress({
  payload: { value: isAtPartnerAddress, clientId, currentAddressId },
}) {
  const primaryApplicantId = yield select(
    clientSelectors.getPrimaryApplicantId,
  );
  const newClientIds = isAtPartnerAddress
    ? [clientId, primaryApplicantId]
    : [clientId];
  yield put(addressActions.setAddressClientIds(currentAddressId)(newClientIds));
}

export default function* addressSagas() {
  yield all([
    yield monitorAsyncRequest(takeEvery, CREATE_ADDRESS, createAddress),
    yield monitorAsyncRequest(takeEvery, UPDATE_ADDRESS, updateAddress),
    yield monitorAsyncRequest(takeEvery, DELETE_ADDRESS, deleteAddress),
    yield takeEvery(SET_IS_AT_PRIMARY_ADDRESS, setIsAtPrimaryAddress),
    yield takeEvery(
      SET_IS_PRIMARY_AT_PARTNER_ADDRESS,
      setIsPrimaryAtPartnerAddress,
    ),
  ]);
}
