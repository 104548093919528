import _ from 'lodash';
import invariant from 'invariant';

const transformQuestionList = (branch, addToCurrentBranch = false) => (
  item,
) => {
  if (_.isString(item)) {
    return {
      id: item,
      branch: branch + !addToCurrentBranch * 1,
      validation: [],
    };
  }
  invariant(
    _.isArray(item),
    'Each argument must be either a string or an array',
  );
  return {
    id: item[0],
    branch: addToCurrentBranch ? branch : branch + 1,
    validation: item.slice(1) || [],
  };
};
const maxBranch = (arr) => _(arr).map('branch').max() || 0;

export default class Questions {
  arr = [];
  addBranch = (...questions) => {
    invariant(
      questions.length,
      'Questions#addBranch must be called with at least one question',
    );
    const branch = maxBranch(this.arr);
    const questionsToAdd = questions.map(transformQuestionList(branch));
    this.arr.push(...questionsToAdd);
  };

  add = (...questions) => {
    invariant(
      questions.length,
      'Questions#add must be called with at least one question',
    );
    invariant(
      this.arr.length,
      'Please add the first questions using Questions#addBranch or Questions#addBranchOrAdd',
    );
    const branch = maxBranch(this.arr);
    const questionsToAdd = questions.map(transformQuestionList(branch, true));
    this.arr.push(...questionsToAdd);
  };

  addBranchOrAdd = (questions) => {
    invariant(
      questions.length,
      'Questions#addBranchOrAdd must be called with at least one question',
    );
    return this.arr.length ? this.add(questions) : this.addBranch(questions);
  };

  addForm = (questionsToAsk, props, addFirstQuestionFlatly = false) => {
    const currentBranch = maxBranch(this.arr);
    this.arr = this.arr.concat(
      questionsToAsk(props).map((q) => ({
        ...q,
        branch: q.branch + currentBranch - addFirstQuestionFlatly * 1,
      })),
    );
  };

  arrayOfQuestions = () => this.arr;
}
