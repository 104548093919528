import React from 'react';
import classNames from 'classnames/bind';

import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Counter from 'components/Counter/Counter';
import { formatCurrency } from 'lib/intlFormatters';
import productDetailMessages from 'constants/productDetailFormatters';

import { getFrequencyAndAmountLabels } from 'lib/lenderHelper';

import styles from './RepaymentHeaderDisplay.css';

const RepaymentHeaderDisplay = ({
  intl,
  repaymentInitial,
  repaymentFrequency,
  repaymentType,
  theme,
  dynamicValue,
  customLabel,
}) => {
  const { frequency, amount, repaymentTypeLabel } = getFrequencyAndAmountLabels(
    repaymentInitial,
    repaymentFrequency,
    repaymentType,
    intl,
  );

  return (
    <div className={classNames(styles.root, styles[theme])}>
      <div className={styles.repaymentTypeGroup}>
        <div className={styles.label}>{customLabel || repaymentTypeLabel}</div>
        <div className={styles.hint}>
          {intl.formatMessage(productDetailMessages.initialRepayment)}
        </div>
      </div>

      <div className={styles.repaymentAmountGroup}>
        <div className={styles.label}>
          {dynamicValue ? (
            <Counter
              endValue={amount}
              duration={400}
              formatValue={formatCurrency(intl)}
            />
          ) : (
            formatCurrency(intl)(amount)
          )}
        </div>
        <div className={styles.hint}>
          {intl.formatMessage(productDetailMessages.perFrequency, {
            frequency,
          })}
        </div>
      </div>
    </div>
  );
};

RepaymentHeaderDisplay.propTypes = {
  repaymentInitial: PropTypes.object,
  repaymentFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  repaymentType: PropTypes.string,
  theme: PropTypes.string,
  intl: intlShape.isRequired,
  dynamicValue: PropTypes.bool,
  customLabel: PropTypes.string,
};

RepaymentHeaderDisplay.defaultProps = {
  dynamicValue: true,
};

export default injectIntl(RepaymentHeaderDisplay);
