import { useState, useRef, useCallback } from 'react';

const useFlagInput = (num) => {
  const [refCountryData, setRefCountryData] = useState({
    dialCode: '',
    countryCode: '',
  });
  const ref = useRef(null);
  const refCB = useCallback(
    (refNode) => {
      if (refNode) {
        ref.current = refNode;
        setRefCountryData(refNode.getCountryData());
      }
      return ref;
    },
    [num],
  );

  return [refCB, refCountryData];
};

export default useFlagInput;
