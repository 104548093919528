import { createSelector } from 'reselect';
import _ from 'lodash';

import { POSITIVE_CHART_SPECTRUM } from 'constants/colours';
import {
  ASSET_CATEGORIES,
  ASSET_TYPES_PER_CATEGORY,
} from 'shared/constants/myCRMTypes/assets';

import * as propertySelectors from 'selectors/propertySelectors';
import {
  isPrimaryOrPartnerClient,
  applicantsOnContact,
  client,
} from 'selectors/clientSelectors';

import { isOrderlessEqual } from 'lib/utils/dataUtils';

export const workingAssets = (state) => state.asset.working;
export const entities = (state) => state.asset.entities;
export const assets = createSelector(
  (state) => state.asset.entities,
  (a) => {
    return a.filter((asset) => {
      const types = ASSET_TYPES_PER_CATEGORY[asset.category];
      return types && types.some((type) => type.id === asset.type);
    });
  },
);

export const hasOldInformation = createSelector(assets, (a) =>
  a.some((b) => b.isOldData),
);

export const assetsIncludingRealEstate = createSelector(
  assets,
  propertySelectors.realEstateAssets,
  (a, b) => a.concat(b),
);

export const entity = createSelector(assets, (a) =>
  _.memoize((id) => _.find(a, (e) => e.id === id)),
);

export const working = (state) => (id) => workingAssets(state)[id];

export const primaryContactAssets = createSelector(
  assetsIncludingRealEstate,
  isPrimaryOrPartnerClient,
  (a, isPrimaryContact) =>
    a.filter(({ clientIds = [] }) => clientIds.some(isPrimaryContact)),
);

export const assetsGroupedByCategory = createSelector(
  primaryContactAssets,
  (a) => _.groupBy(a, 'category'),
);

export const clientsAssets = createSelector(
  assetsIncludingRealEstate,
  client,
  applicantsOnContact,
  (a, c, clientsByContact) =>
    _.memoize(
      (clientIds) => {
        const { contactId } = c(clientIds[0]);
        const contactClients = clientsByContact(contactId).map((cc) => cc.id);
        return a.filter((i) => {
          const assetOwnerClientIds = _.intersectionBy(
            i.clientIds,
            contactClients,
          );
          return isOrderlessEqual(assetOwnerClientIds, clientIds);
        });
      },
      (clientIds) => clientIds.join('-'),
    ),
);

export const hasOldClientInformation = createSelector(clientsAssets, (a) =>
  _.memoize((clientIds) => {
    const b = a(clientIds);
    return b.some((c) => c.isOldData);
  }),
);

export const clientsAssetsTotal = createSelector(clientsAssets, (a) =>
  _.memoize(
    (clientIds) => _.sumBy(a(clientIds), 'value'),
    (clientIds) => clientIds.join('-'),
  ),
);

export const totalAssets = createSelector(primaryContactAssets, (a) =>
  _.sumBy(a, 'value'),
);

export const chartData = createSelector(assetsGroupedByCategory, (a) =>
  _.map(a, (data, assetCategory) => ({
    label: ASSET_CATEGORIES.find(
      (category) => category.id === parseInt(assetCategory, 10),
    ).name,
    data: data.map((v) => v.value),
    colour:
      POSITIVE_CHART_SPECTRUM[
        (parseInt(assetCategory, 10) + 1) % POSITIVE_CHART_SPECTRUM.length
      ],
  })),
);
