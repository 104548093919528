import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import _ from 'lodash';

import * as mogoConnectActionTypes from 'actions/mogoConnectActionTypes';

export const initialState = {
  bankList: [],
  bankDetails: {},
  loginDetails: {},
  loginDetailsFetching: false,
  loginDetailsError: null,
  checkingAccessId: null,
  checkedAccessId: null,
  checkingError: null,
};

const mogoConnectReducer = handleActions(
  {
    [mogoConnectActionTypes.SET_BANK_LIST](state, action) {
      return update(state, {
        bankList: { $set: action.payload },
      });
    },
    [mogoConnectActionTypes.SET_BANK_DETAILS](state, action) {
      return update(state, {
        bankDetails: { $set: action.payload },
      });
    },
    [mogoConnectActionTypes.REQUEST_BANK_LOGIN](state) {
      return update(state, {
        loginDetails: { $set: {} },
        loginDetailsFetching: { $set: true },
        loginDetailsError: { $set: null },
      });
    },
    [mogoConnectActionTypes.REQUEST_BANK_LOGIN_SUCCESS](state, action) {
      return update(state, {
        loginDetails: { $set: action.payload },
        loginDetailsFetching: { $set: false },
        loginDetailsError: { $set: null },
      });
    },
    [mogoConnectActionTypes.REQUEST_BANK_LOGIN_ERROR](state, action) {
      return update(state, {
        loginDetails: { $set: {} },
        loginDetailsFetching: { $set: false },
        loginDetailsError: { $set: action.payload },
      });
    },
    [mogoConnectActionTypes.CLEAR_BANK_CONNECT_SESSION](state) {
      return update(state, {
        $merge: _.omit(initialState, 'bankList'),
      });
    },
    [mogoConnectActionTypes.CHECK_BANK_LOGIN_COMPLETE](state, action) {
      return update(state, {
        checkingAccessId: { $set: action.payload },
        checkedAccessId: { $set: null },
      });
    },
    [mogoConnectActionTypes.CHECK_BANK_LOGIN_COMPLETE_SUCCESS](state, action) {
      return update(state, {
        checkingAccessId: { $set: null },
        checkedAccessId: { $set: action.payload },
      });
    },
  },
  initialState,
);

export default mogoConnectReducer;
