import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { debounce } from 'throttle-debounce';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';

import forceFocus from 'hocs/forceFocus';

import styles from './AutocompleteInput.css';

/*
 **
 ** Async auto suggestion, takes either ['result1', 'result2'] or [{text: 'result1', key: '1'}, {text: 'result2', key: '2'}]
 **
 */
class AutocompleteInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    action: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onSuggestionSelected: PropTypes.func,
    setAsyncError: PropTypes.func,
    removeAsyncError: PropTypes.func,
    serviceFn: PropTypes.func,
    setFocusRef: PropTypes.func,
    minimumChars: PropTypes.number,
    disabled: PropTypes.bool,
    hasFlag: PropTypes.bool,
    customSanitiseResults: PropTypes.func,
  };

  static defaultProps = {
    onSuggestionSelected: _.noop,
    setAsyncError: _.noop,
    removeAsyncError: _.noop,
    minimumChars: 1,
    disabled: false,
  };

  static contextTypes = {
    errored: PropTypes.bool,
    inactive: PropTypes.bool,
  };

  static renderSuggestion = (suggestion) => <span>{suggestion.text}</span>;

  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      invalidSearchResult: '',
    };
  }

  onBlur = (event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      this.onSuggestionSelected(null, { suggestion: highlightedSuggestion });
    }
  };

  onChange = (event, { newValue, method }) => {
    const { action, removeAsyncError } = this.props;
    if (method !== 'click') {
      action({ text: newValue, key: null });
      if (newValue === '') {
        setTimeout(removeAsyncError, 500);
      } /* wait for potential async error */
    }
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter' && this.state.suggestions.length) {
      e.preventDefault();
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.props.value !== value) {
      this.loadSuggestions(value, (suggestions) => {
        this.setState({
          suggestions,
        });
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.props.onSuggestionSelected();
    this.props.removeAsyncError();
    this.props.action(suggestion);
  };

  getSuggestionValue = (suggestion) => suggestion.text;

  sanitiseResults = (results) => {
    const { customSanitiseResults } = this.props;
    if (customSanitiseResults) {
      return customSanitiseResults(results);
    }
    return results.map((result) =>
      typeof result === 'string' ? { text: result, key: result } : result,
    );
  };

  loadSuggestions = debounce(300, (value, cb) => {
    if (value && value.length >= this.props.minimumChars) {
      this.props
        .serviceFn(value)
        .then((data) => {
          const suggestion = this.sanitiseResults(data).find(
            (x) => x.text === this.props.value,
          );
          if (suggestion) {
            this.onSuggestionSelected(null, { suggestion });
          } else {
            this.props.setAsyncError(
              'Please select an item from the suggestions provided',
            );
          }
          cb(this.sanitiseResults(data));
        })
        .catch((error) => {
          this.props.setAsyncError(error.message);
          // eslint-disable-next-line standard/no-callback-literal
          cb([]);
        });
    }
  });

  render() {
    const { suggestions } = this.state;
    const {
      id,
      placeholder,
      onFocus,
      value,
      setFocusRef,
      disabled,
      hasFlag,
    } = this.props;
    const { errored, inactive } = this.context;

    const inputProps = {
      id,
      value: value || '',
      placeholder,
      onChange: this.onChange,
      onBlur: this.onBlur,
      onKeyDown: this.onKeyDown,
      onFocus,
      disabled,
      autoComplete: 'off',
    };

    const rootStyle = classNames(styles.root, {
      [styles.errored]: errored,
      [styles.inactive]: inactive,
      [styles.hasFlag]: hasFlag,
    });

    return (
      <div className={rootStyle}>
        <Autosuggest
          ref={(c) => {
            if (c) {
              setFocusRef(c.input);
            }
          }}
          suggestions={suggestions}
          theme={styles}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          renderSuggestion={AutocompleteInput.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

export const unHOCed = AutocompleteInput;
export default forceFocus(AutocompleteInput);
