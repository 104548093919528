import * as fundingActionTypes from 'actions/fundingActionTypes';
import * as individualFundingActionTypes from 'actions/individualFundingActionTypes';

import update from 'immutability-helper';

import { handleActions } from 'redux-actions';
import { unformatCurrency } from 'shared/lib/numbrero';

import {
  setWorkingValue,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  setError,
} from 'lib/reducerHelper';

const actionTypes = { ...fundingActionTypes, ...individualFundingActionTypes };
export const newFunding = {
  errors: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newFunding,
  },
};

const fundingReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_FUNDING](state, action) {
      return clearWorking(state, action, newFunding);
    },
    [actionTypes.LOAD_FUNDING](state, action) {
      return loadEntityIntoWorking(state, action, newFunding);
    },
    [actionTypes.LOAD_NEW_FUNDING](state, action) {
      return update(state, {
        working: {
          new: {
            $set: update(newFunding, { $merge: action.payload }),
          },
        },
      });
    },
    [actionTypes.REMOVE_FUNDING](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_FUNDING](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_FUNDINGS](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.SET_FUNDING](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_FUNDING_VALUE](state, action) {
      return setWorkingValue(state, action.payload, 'value', unformatCurrency);
    },
    [actionTypes.SET_FUNDING_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'name');
    },
    [actionTypes.SET_FUNDING_PROPERTY_ID](state, action) {
      return setWorkingValue(state, action.payload, 'propertyId');
    },
    [actionTypes.SET_FUNDING_DESCRIPTION](state, action) {
      return setWorkingValue(state, action.payload, 'description');
    },
    [actionTypes.SET_FUNDING_ERROR](state, action) {
      return setError(state, action);
    },
  },
  initialState,
);

export default fundingReducer;
