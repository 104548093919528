import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';
import Selection from 'components/Selection/Selection';

import styles from './ComparePageNavigator.css';

class ComparePageNavigator extends Component {
  static propTypes = {
    setFilterOrderFieldAction: PropTypes.func,
    orderField: PropTypes.string,
    showSortingDropdown: PropTypes.bool,
    sortingOptions: PropTypes.arrayOf(PropTypes.object),
    backLabel: PropTypes.string,
    goBack: PropTypes.func.isRequired,
  };

  goBack = () => {
    this.props.goBack();
  };

  render() {
    const {
      orderField,
      showSortingDropdown,
      setFilterOrderFieldAction,
      backLabel,
      sortingOptions,
    } = this.props;

    return (
      <div className={styles.root}>
        <Button
          id='backButton'
          icon='mi-arrow-left'
          theme='backButtonTheme'
          className='margin-0'
          onClick={this.goBack}
        >
          {backLabel}
        </Button>
        {showSortingDropdown && (
          <Selection
            id='productDropdown'
            action={setFilterOrderFieldAction}
            items={sortingOptions}
            value={orderField}
            selectionDefault={orderField}
            simple
          />
        )}
      </div>
    );
  }
}

export default ComparePageNavigator;
