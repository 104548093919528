import { defineMessages } from 'react-intl';
import { WEEKLY, MONTHLY, YEARLY } from 'shared/constants/options.js';

const SIZE_UNIT = Object.freeze(['byte', 'KB', 'MB']);

const messages = defineMessages({
  perWeek: {
    id: 'CurrencyFormat.perWeek',
    defaultMessage: 'per week',
  },
  perWeekAbbreviated: {
    id: 'CurrencyFormat.perWeekAbbreviated',
    defaultMessage: 'p.w.',
  },
  perMonth: {
    id: 'CurrencyFormat.perMonth',
    defaultMessage: 'per month',
  },
  perMonthAbbreviated: {
    id: 'CurrencyFormat.perMonthAbbreviated',
    defaultMessage: 'p.m.',
  },
  perMonthMedium: {
    id: 'CurrencyFormat.perMonthMedium',
    defaultMessage: 'p/month',
  },
  perAnnum: {
    id: 'CurrencyFormat.perAnnum',
    defaultMessage: 'per annum',
  },
  perAnnumAbbreviated: {
    id: 'CurrencyFormat.perAnnumAbbreviated',
    defaultMessage: 'p.a.',
  },
  [SIZE_UNIT[0]]: {
    id: 'SizeFormat.byte',
    defaultMessage: '{value, number} {value, plural, =1 {byte} other {bytes}}',
  },
  [SIZE_UNIT[1]]: {
    id: 'SizeFormat.KB',
    defaultMessage: '{value, number} KB',
  },
  [SIZE_UNIT[2]]: {
    id: 'SizeFormat.MB',
    defaultMessage: '{value, number} MB',
  },
});

const _formatCurrency = (value, intl, showDecimal) => {
  return intl.formatNumber(
    value,
    showDecimal
      ? intl.formats.number.currencyFloat
      : intl.formats.number.currency,
  );
};

export const formatFrequency = (intl, freq, abbreviated = false) => {
  const postfix = abbreviated ? 'Abbreviated' : '';
  switch (freq) {
    case WEEKLY:
      return intl.formatMessage(messages[`perWeek${postfix}`]);
    case MONTHLY:
      return intl.formatMessage(messages[`perMonth${postfix}`]);
    case 'monthMedium':
      return intl.formatMessage(messages.perMonthMedium);
    case YEARLY:
      return intl.formatMessage(messages[`perAnnum${postfix}`]);
    default:
      return undefined;
  }
};

export const formatCurrency = (intl, options = {}) => {
  const { frequency, showDecimal, abbreviated } = options;
  return (value) => {
    const formattedCurrency = _formatCurrency(value, intl, showDecimal);
    if (!frequency) {
      return formattedCurrency;
    }
    return `${formattedCurrency} ${formatFrequency(
      intl,
      frequency,
      abbreviated,
    )}`;
  };
};

export const formatCurrencyPw = (intl, showDecimal = false) => (value) =>
  formatCurrency(intl, { frequency: WEEKLY, abbreviated: true, showDecimal })(
    value,
  );
export const formatCurrencyPm = (intl, showDecimal = false) => (value) =>
  formatCurrency(intl, { frequency: MONTHLY, abbreviated: true, showDecimal })(
    value,
  );
export const formatCurrencyPmMedium = (intl, showDecimal = false) => (value) =>
  formatCurrency(intl, { frequency: 'monthMedium', showDecimal })(value);
export const formatCurrencyPa = (intl, showDecimal = false) => (value) =>
  formatCurrency(intl, { frequency: YEARLY, abbreviated: true, showDecimal })(
    value,
  );

export const formatRate = (intl) => (value) =>
  intl.formatNumber(value / 100, intl.formats.number.rate);
export const formatFloat = (intl) => (value) =>
  intl.formatNumber(value, intl.formats.number.float);

export const formatPercentage = (intl) => (value) =>
  intl.formatNumber(value, intl.formats.number.percentage);

export const formatSizeFromBytes = (intl) => (bytes) => {
  const i = Math.min(
    2,
    parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10),
  );
  return (
    SIZE_UNIT[i] &&
    intl.formatMessage(messages[SIZE_UNIT[i]], {
      value: Math.round(bytes / 1024 ** i, 2),
    })
  );
};
