import React from 'react';
import { Switch } from 'react-router-dom';

import { RouteForLoggedin } from 'routes/helpers';
import { ModalProvider } from 'contexts/ModalContext';
import { ParentWrapper } from 'containers/goal/ParentWrapper';

import GoalFutureLongTerm from './GoalFutureLongTerm';
import GoalFutureRetirement from './GoalFutureRetirement';
import GoalFutureHorizon from './GoalFutureHorizon';

import {
  APPLY_BASE_PATH,
  LONG_TERM_PATH,
  RETIREMENT_PATH,
  HORIZON_PATH,
} from 'shared/constants/paths';

const GoalFutureApplyRoutes = () => {
  return (
    <ModalProvider>
      <ParentWrapper>
        <Switch>
          <RouteForLoggedin
            path={`${APPLY_BASE_PATH}/:loanApplicationId${LONG_TERM_PATH}/:familyId`}
            component={GoalFutureLongTerm}
          />
          <RouteForLoggedin
            path={`${APPLY_BASE_PATH}/:loanApplicationId${RETIREMENT_PATH}/:familyId/:clientId`}
            component={GoalFutureRetirement}
          />
          <RouteForLoggedin
            path={`${APPLY_BASE_PATH}/:loanApplicationId${HORIZON_PATH}/:familyId/:clientId`}
            component={GoalFutureHorizon}
          />
        </Switch>
      </ParentWrapper>
    </ModalProvider>
  );
};

export default GoalFutureApplyRoutes;
