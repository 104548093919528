import React from 'react';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import classNames from 'classnames/bind';

import { generateUniqueID } from 'lib/utils/stringUtils';

import styles from './QuestionHint.css';

class QuestionHint extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string,
    fullWidth: PropTypes.bool,
    hint: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
      PropTypes.func,
    ]).isRequired,
  };

  renderHintBasedOnType = (hint) => {
    switch (typeof hint) {
      case 'string':
        return renderHTML(hint);
      case 'function':
        return hint();
      default:
        return hint;
    }
  };

  render() {
    const { hint, id, fullWidth } = this.props;
    const hintId = generateUniqueID('Hint', id);
    return (
      <small
        id={hintId}
        className={classNames(styles.hint, { [styles.fullWidth]: !!fullWidth })}
      >
        {this.renderHintBasedOnType(hint)}
      </small>
    );
  }
}

export default QuestionHint;
