import React, { Component } from 'react';
import PropTypes from 'prop-types';
import validator from 'lib/validator';
import { compose } from 'redux';
import _ from 'lodash';
import hoistNonReactStatic from 'hoist-non-react-statics';
import bowser from 'bowser';

import { isEmptyObjectOrObjectWithOnlyUndefinedValues } from 'lib/utils/dataUtils';

const dirtyUpQuestions = (currentQuestion = '', questionHistory = []) => (
  props,
) => {
  return {
    ...props,
    questions: props.questions.map((question, i, array) => {
      const dirty = questionHistory.includes(question.id);
      const showError = !!(
        question.error &&
        currentQuestion !== question.id &&
        dirty
      );
      const active =
        currentQuestion === question.id || // You are focussed on this question
        (i !== 0 &&
          array[i - 1].id === currentQuestion &&
          !array[i - 1].error) || // The previous question is the current question, and it has a valid answer
        !currentQuestion || // You have click off the form. Currently a small hack since mobile starts with no current question (else an auto focus will occur and bring up the keyboard)
        (question.error && array[array.length - 1].id === currentQuestion); // You have an error on this question and are currently focussed on the last question, typically the "linkButton"
      return {
        ...question,
        dirty,
        showError,
        inactive: !active,
      };
    }),
  };
};

const showHideQuestions = (forceVisibility, revealMethod, revealOverBranch) => (
  props,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  const transforms = [
    (question, i) => ({
      ...question,
      visibility: forceVisibility || i === 0 || question.dirty,
    }),
  ];
  if (revealMethod === 'steps') {
    if (revealOverBranch) {
      transforms.push((question, i, array) => ({
        ...question,
        visibility: question.visibility || array[i - 1].dirty,
      }));
    } else {
      transforms.push((question, i, array) => ({
        ...question,
        visibility:
          question.visibility ||
          (array[i - 1].dirty &&
            (array[i - 1].branch === question.branch || !array[i - 1].error)),
      }));
    }
  } else if (revealMethod === 'chunks') {
    // If any question within the chunk is visible, then make all questions within the chunk visible.
    transforms.push((question, i, array) => ({
      ...question,
      visibility:
        question.visibility ||
        array.some((x) => x.visibility && x.branch === question.branch),
    }));
    if (revealOverBranch) {
      // If the question is the first question of the next branch
      transforms.push((question, i, array) => ({
        ...question,
        visibility:
          question.visibility ||
          (array.find((e) => e.branch === question.branch).id === question.id &&
            array
              .filter((x) => x.branch === question.branch - 1)
              .every((x) => x.visibility)),
      }));
    } else {
      transforms.push((question, i, array) => ({
        ...question,
        visibility:
          question.visibility ||
          (array.find((x) => x.branch === question.branch - 1) &&
            array
              .filter((x) => x.branch === question.branch - 1)
              .every((x) => x.visibility && !x.error)),
      }));
    }
  }

  const flowing = _.flow(transforms.map((t) => (arr) => arr.map(t)));
  const visibilityResult = flowing(props.questions);

  return {
    ...props,
    questions: visibilityResult,
  };
};

const applyValidation = (dataObj) => (props) => {
  const validatedResults = validator.validateFields(
    props.questions.reduce((p, c) => {
      // eslint-disable-next-line unicorn/explicit-length-check
      if (c.validation.length) {
        p[c.id] = c.validation;
      }
      return p;
    }, {}),
    dataObj,
  );

  return {
    ...props,
    questions: props.questions.map((question) => {
      if (validatedResults[question.id]) {
        return {
          ...question,
          error: validatedResults[question.id].text,
          errorIsBlocking: validatedResults[question.id].blocking,
        };
      }
      return {
        ...question,
        error: '',
        errorIsBlocking: false,
      };
    }),
  };
};

const modifyActiveState = (forceActive) => (props) => {
  const formCompleted = props.questions.every(
    (q) => !q.error || (q.error && !q.errorIsBlocking),
  );
  const nextProps = { ...props, formCompleted };
  if (formCompleted || forceActive) {
    nextProps.questions = props.questions.map((question) => ({
      ...question,
      inactive: false,
    }));
  }
  return nextProps;
};

const mapQuestionsToKey = (questions) => ({
  questions: questions.map((question) => ({ ...question, key: question.id })),
});

const notAnswered = (questionId, dataObject) => {
  const answer = dataObject[questionId];
  return (
    answer === undefined ||
    answer === null ||
    isEmptyObjectOrObjectWithOnlyUndefinedValues(answer)
  );
};

const manageQuestionData = (
  questionsToAsk,
  fieldRuleMap,
  forceVisibility,
  forceActive,
  revealMethod,
  revealOverBranch,
) => (dataObj, currentQuestion, questionHistory) => {
  const validate = applyValidation(dataObj);
  return compose(
    modifyActiveState(forceActive),
    showHideQuestions(forceVisibility, revealMethod, revealOverBranch),
    dirtyUpQuestions(currentQuestion, questionHistory),
    validate,
    mapQuestionsToKey,
    questionsToAsk,
  )(dataObj);
};

const manageQuestions = (WrappedComponent, getDataFn = _.identity) => {
  const questionComposition = manageQuestionData(
    WrappedComponent.questionsToAsk,
    WrappedComponent.fieldRuleMap,
    WrappedComponent.forceVisibility,
    WrappedComponent.forceActive,
    WrappedComponent.revealMethod || 'steps',
    WrappedComponent.revealOverBranch || false,
  );

  class ManagedQuestions extends Component {
    static displayName = `ManagedQuestions(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    constructor(props) {
      super(props);
      const dataObject = getDataFn(props);
      const questionData = questionComposition(dataObject);
      let currentQuestion = this.firstFocusQuestion(questionData, dataObject);

      // if mobile, then set the currentQuestion to null so we don't have any autofocussing occurring;
      if (bowser.mobile) {
        currentQuestion = null;
      }

      const questionHistory = questionData.questions
        .filter((q) => !notAnswered(q.id, dataObject))
        .map((q) => q.id);

      if (!questionHistory.includes(currentQuestion)) {
        questionHistory.push(currentQuestion);
      }

      this.questionData = questionData;
      this.state = {
        currentQuestion,
        isInitialFocus: true,
        questionHistory,
      };
    }

    componentDidUpdate(prevProps) {
      // More messiness... for asyn call we are loading data in after this question is mounted so need to re-adjust the intial focused question
      if (this.state.isInitialFocus) {
        const prevPropsObject = getDataFn(prevProps);
        const currentPropsObject = getDataFn(this.props);
        if (this.haveInitialPropsChanged(prevPropsObject, currentPropsObject)) {
          const questionData = questionComposition(currentPropsObject);
          const newFirstQuestion = this.firstFocusQuestion(
            questionData,
            currentPropsObject,
          );
          if (newFirstQuestion !== this.state.currentQuestion) {
            this.setCurrentQuestion(newFirstQuestion, true);
          }
        }
      }
    }

    firstFocusQuestion = (questionData, dataObject) => {
      const firstQuestion = questionData.questions.find(
        (q) => !!q.error || notAnswered(q.id, dataObject),
      );
      return firstQuestion ? firstQuestion.id : questionData.questions[0].id;
    };

    haveInitialPropsChanged = (prevPropsObject, currentPropsObject) => {
      return !_.isEqual(
        _.omit(currentPropsObject, this.state.currentQuestion),
        _.omit(prevPropsObject, this.state.currentQuestion),
      );
    };

    // Other than initially we load the component and programmatically shift the focused question,
    // we do not readjust the initial focused question again.
    setCurrentQuestion = (questionId, isInitialFocus = false) => {
      const stateChanges = {
        currentQuestion: questionId,
        isInitialFocus,
      };
      if (!this.state.questionHistory.includes(questionId)) {
        stateChanges.questionHistory = [
          ...this.state.questionHistory,
          questionId,
        ];
      }
      this.setState(stateChanges);
    };

    setCurrentQuestionToNextQuestion = () => {
      const { currentQuestion } = this.state;
      const dataObject = getDataFn(this.props);
      const currentQuestionList = WrappedComponent.questionsToAsk(dataObject);
      const indexOfNextQuestion =
        currentQuestionList.findIndex((q) => q.id === currentQuestion) + 1;
      if (indexOfNextQuestion < currentQuestionList.length) {
        const nextQuestionObj = currentQuestionList[indexOfNextQuestion];
        this.setCurrentQuestion(nextQuestionObj.id);
      }
    };

    decorateQuestionsWithFocusMethods = (p, c) => ({
      ...p,
      [c.id]: {
        ...c,
        forceFocus: this.state.currentQuestion === c.id,
        setCurrentQuestion: () => this.setCurrentQuestion(c.id),
        setCurrentQuestionToNextQuestion: this.setCurrentQuestionToNextQuestion,
        transition: true,
      },
    });

    render() {
      const dataObject = getDataFn(this.props);
      const questionData = questionComposition(
        dataObject,
        this.state.currentQuestion,
        this.state.questionHistory,
      );
      const keyedQuestions = questionData.questions.reduce(
        this.decorateQuestionsWithFocusMethods,
        {},
      );
      this.questionData = questionData;
      return (
        <WrappedComponent
          {...this.props}
          questions={keyedQuestions}
          formCompleted={questionData.formCompleted}
          setCurrentQuestionTo={(id) => () => this.setCurrentQuestion(id)}
          setCurrentQuestionToNextQuestion={
            this.setCurrentQuestionToNextQuestion
          }
          currentQuestion={this.state.currentQuestion}
        />
      );
    }
  }

  hoistNonReactStatic(ManagedQuestions, WrappedComponent);
  return ManagedQuestions;
};

export const questionPropTypes = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    error: PropTypes.string,
    visibility: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    inactive: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
  }),
);

export const manageQuestionsPropTypes = {
  questions: questionPropTypes,
  formCompleted: PropTypes.bool.isRequired,
  setCurrentQuestionTo: PropTypes.func.isRequired,
  setCurrentQuestionToNextQuestion: PropTypes.func.isRequired,
  currentQuestion: PropTypes.string,
};

export default manageQuestions;
