import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { debounce } from 'lodash';

import { getInbetweenerDebounceTime } from 'lib/utils/browserUtils';

import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';
import AnimatedChecklist from 'components/AnimatedChecklist/AnimatedChecklist';
import Textarea from 'components/Textarea/Textarea';

import Button from 'components/Button/Button';

import ReactVivus from './ReactVivus';

import styles from './ImageMessage.css';

// Timings
const imageAnimationTime = 60; // This is in frames
const headerDelayMs = 10;
const checklistItemTimeMs = 950;

const isAnimationDisplayed = (animatedImage) => {
  const imageHolder = animatedImage && animatedImage.getContainer();

  return imageHolder && imageHolder.getBoundingClientRect().height > 0;
};

export default class ImageMessage extends Component {
  static propTypes = {
    next: PropTypes.func,
    gotoNextPath: PropTypes.func,
    image: PropTypes.string,
    heading: PropTypes.string,
    primaryButtonText: PropTypes.string,
    primaryButtonIcon: PropTypes.string,
    primaryButtonAction: PropTypes.func,
    secondaryButtonText: PropTypes.string,
    secondaryButtonIcon: PropTypes.string,
    secondaryButtonAction: PropTypes.func,
    checklist: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    imageAnimationType: PropTypes.string,
    imagePathTiming: PropTypes.string,
    imageAnimTiming: PropTypes.string,
    delay: PropTypes.number,
    headerTimeMs: PropTypes.number,
    imageSize: PropTypes.oneOf(['medium', 'large', 'tall']),
    alwaysShowImage: PropTypes.bool,
    noButton: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    theme: PropTypes.string,
    onLoad: PropTypes.func,
    isStatic: PropTypes.bool,
    equalButtonEmphasis: PropTypes.bool,
    textareaPlaceholder: PropTypes.string,
    textareaValue: PropTypes.string,
    textareaAction: PropTypes.func,
  };

  static defaultProps = {
    alwaysShowImage: false,
    delay: 0,
    headerTimeMs: 950,
    imageAnimationType: 'delayed',
    imagePathTiming: 'easeIn',
    imageAnimTiming: 'easeOut',
    imageSize: 'medium',
    primaryButtonText: 'Next',
    equalButtonEmphasis: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
      imageFinished: false,
      backgroundFinishedSliding: false,
    };
    this._timeouts = [];
  }

  componentDidMount() {
    const { headerTimeMs, checklist, onLoad } = this.props;

    // DON'T START SHOWING CONTENT TIL INBETWEENER FINISHED SLIDING UP!
    // 250 = 550ms - 300ms from animation.css slideUpAndDown theme
    this._timeouts.push(
      setTimeout(() => {
        this.setState({ backgroundFinishedSliding: true });
        if (!isAnimationDisplayed(this.animatedImage)) {
          // Animation is not displayed on mobile
          this.setImageFinished();
        }

        if (!checklist) {
          this._timeouts.push(
            setTimeout(() => {
              this.setShowButton(true);
            }, headerTimeMs),
          );
        }
      }, 250),
    );

    onLoad && onLoad();
  }

  componentWillUnmount() {
    this._timeouts.forEach(clearTimeout);
  }

  setImageFinished() {
    this.setState({
      imageFinished: true,
    });
  }

  setShowButton(showButton) {
    this.setState({
      showButton,
    });
  }

  handleImageFinished = () => {
    const { checklist, headerTimeMs, noButton } = this.props;
    this.setImageFinished();
    if (!checklist) {
      this._timeouts.push(
        setTimeout(() => {
          if (noButton) {
            return this.props.next();
          }
          this.setState({
            showButton: true,
          });
        }, headerTimeMs),
      );
    }
  };

  nextRoute = () => {
    const { gotoNextPath, next } = this.props;
    if (gotoNextPath) {
      gotoNextPath();
    } else {
      next();
    }
  };

  skipToEnd = debounce(
    () => {
      if (!this.state.showButton) {
        // We need to use setTimeout here as this setState call is
        // not propogated during animations
        // We do not know why yet - investigation is ongoing
        this._timeouts.push(
          setTimeout(
            this.setState({
              showButton: true,
              imageFinished: true,
            }),
            checklistItemTimeMs,
          ),
        );
      }
    },
    getInbetweenerDebounceTime(),
    { leading: true, trailing: false },
  );

  renderButtons() {
    const { showButton } = this.state;
    const {
      primaryButtonText,
      primaryButtonAction,
      primaryButtonIcon,
      secondaryButtonText,
      secondaryButtonIcon,
      secondaryButtonAction,
      equalButtonEmphasis,
    } = this.props;
    return (
      <div>
        <Button
          id='inbetweenerNextButton'
          visibility={showButton}
          icon={primaryButtonIcon}
          theme='transparent'
          className='brandColor__font--hover'
          animationClassName='centerAlignBlock'
          onClick={primaryButtonAction || this.nextRoute}
          transition
        >
          {primaryButtonText}
        </Button>
        {secondaryButtonText && (
          <Button
            id='inbetweenerSkipButton'
            icon={secondaryButtonIcon}
            visibility={showButton}
            theme={equalButtonEmphasis ? 'transparent' : 'inbetweenSecondary'}
            className={equalButtonEmphasis ? 'brandColorOnHover' : 'margin-0'}
            animationClassName='centerAlignBlock'
            onClick={secondaryButtonAction || this.nextRoute}
            transition
          >
            {secondaryButtonText}
          </Button>
        )}
      </div>
    );
  }

  renderTextArea() {
    const { textareaPlaceholder, textareaValue, textareaAction } = this.props;
    return (
      <Textarea
        action={textareaAction}
        value={textareaValue}
        placeholder={textareaPlaceholder}
      />
    );
  }

  render() {
    const { imageFinished, showButton, backgroundFinishedSliding } = this.state;
    if (!backgroundFinishedSliding) {
      return null;
    }
    const {
      image: imageFile,
      heading: header,
      checklist: items,
      alwaysShowImage,
      imageAnimationType,
      imagePathTiming,
      imageAnimTiming,
      delay,
      headerTimeMs,
      imageSize,
      id,
      isStatic,
      noButton,
      textareaAction,
    } = this.props;

    const hasTextArea = !!textareaAction;

    const mainContainerStyle = classNames(styles.mainContainer, {
      [styles.alwaysShowImage]: alwaysShowImage,
      [styles.hasImg]: isStatic,
      centerAlignBlock: hasTextArea,
    });

    return (
      <AnimationWrapper key='imageMessage' id={`imageMessage__${id}`}>
        <div
          className={mainContainerStyle}
          onClick={this.skipToEnd}
          onTouchStart={this.skipToEnd}
        >
          {imageFile &&
            (isStatic ? (
              <div className={styles.headerImg}>
                <img src={imageFile} />
              </div>
            ) : (
              <ReactVivus
                svg={imageFile}
                type={imageAnimationType}
                pathTiming={imagePathTiming}
                animTiming={imageAnimTiming}
                className={classNames({
                  [styles.imageContainer]: true,
                  [styles.imageMedium]: imageSize === 'medium',
                  [styles.imageLarge]: imageSize === 'large',
                  [styles.imageTall]: imageSize === 'tall',
                })}
                onAnimationFinished={this.handleImageFinished}
                duration={showButton ? 0 : imageAnimationTime}
                ref={(animatedImage) => {
                  this.animatedImage = animatedImage;
                }}
                delayMs={delay}
              />
            ))}
          <div
            className={classNames({
              [styles.headerAndCheckList]: true,
              [styles.noImage]: !imageFile,
              [styles.noCheckList]: !items && !!imageFile,
              [styles.headerNoMargin]: hasTextArea,
            })}
          >
            {imageFinished && (
              <AnimatedHeading
                delay={showButton ? 0 : headerDelayMs}
                skipAllowed={false}
                currentElementStyle={styles.header}
                className={classNames({
                  [styles.animatedHeader]: true,
                  alignLeft: hasTextArea,
                })}
              >
                <h1>{header}</h1>
              </AnimatedHeading>
            )}
            {items && imageFinished && (
              <AnimatedChecklist
                delay={showButton ? 0 : headerTimeMs}
                items={items}
                itemTransitionTimeMs={showButton ? 0 : checklistItemTimeMs}
                onFinish={() => this.setShowButton(true)}
              />
            )}
            {hasTextArea && this.renderTextArea()}
          </div>
        </div>
        {!noButton && this.renderButtons()}
      </AnimationWrapper>
    );
  }
}
