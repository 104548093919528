import React, { useState, useContext, createContext } from 'react';

const ModalContext = createContext();

function ModalProvider(props) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [modalConfig, setModalConfig] = useState({});

  const defaultModalConfig = {
    closeOnOverlay: false,
    content: null,
    title: '',
    theme: '',
    isCloseButtonVisible: true,
  };

  const showModal = (config) => {
    setModalConfig({ ...defaultModalConfig, ...config });
    setIsModalShown(true);
  };

  const hideModal = () => setIsModalShown(false);

  return (
    <ModalContext.Provider
      value={{ isModalShown, showModal, hideModal, modalConfig }}
      {...props}
    />
  );
}

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
