import { takeEvery, all, put, call, select } from 'redux-saga/effects';

import lenderActions, { FETCH_ACCREDITED_LENDERS } from 'actions/lenderActions';

import * as lendersApi from 'services/lendersApi';
import * as contactSelectors from 'selectors/contactSelectors';

import { monitorSpinnerRequest } from 'lib/sagaHelpers';

export function* fetchAccreditedLenders(familyId) {
  try {
    const adviserId = yield select(contactSelectors.getContactBrokerId);
    const accreditedLenders = yield call(
      lendersApi.getAccreditedLenders,
      familyId || adviserId,
    );
    yield put(lenderActions.setAccreditedLenders(accreditedLenders));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch the expense category types!', error);
  }
}

export default function* lenderSaga() {
  yield all([
    yield monitorSpinnerRequest(
      takeEvery,
      FETCH_ACCREDITED_LENDERS,
      fetchAccreditedLenders,
    ),
  ]);
}
