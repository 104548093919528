import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';

import * as fundingSelectors from 'selectors/fundingSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import ReportContent from 'components/ReportContent/ReportContent';
import applySection from 'hocs/applySection';

const messages = defineMessages({
  title: {
    id: 'LoanAmountApply.title',
    defaultMessage: 'Loan Amount',
  },
  titleDescription: {
    id: 'LoanAmountApply.titleDescription',
    defaultMessage: '{totalLoan, number, currency}',
  },
  headerDescription: {
    id: 'LoanAmountApply.headerDescriptionCompleted',
    defaultMessage: 'You’ll be borrowing {totalLoan, number, currency}.',
  },
});

export const furtherDecoration = (props) => {
  const {
    totalLoan,
    intl: { formatMessage },
  } = props;

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages.titleDescription, { totalLoan }),
    headerDescription: formatMessage(messages.headerDescription, { totalLoan }),
  };
};

const LoanAmountApply = (props) => {
  const {
    fundingReportMetadata,
    structureReportMetadata,
    securityReportMetaData,
  } = props;

  return (
    <div id='loanAmountApply'>
      <div>
        <p>
          <strong>Review your total loan amount</strong>
        </p>
        <p>
          Here is a summary of the funds required and your cash contributions,
          with a calculation showing the total amount you need to borrow.
        </p>
      </div>
      <ReportContent
        data={[
          ...fundingReportMetadata,
          ...structureReportMetadata,
          ...securityReportMetaData,
        ]}
        simpleDonutSection
      />
    </div>
  );
};

LoanAmountApply.displayName = 'LoanAmountApply';

LoanAmountApply.propTypes = {
  fundingReportMetadata: PropTypes.arrayOf(PropTypes.object),
  structureReportMetadata: PropTypes.arrayOf(PropTypes.object),
  securityReportMetaData: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  totalLoan: fundingSelectors.totalLoanRequired(state),
  fundingReportMetadata: fundingSelectors.reportMetadata(state),
  structureReportMetadata: applicationSelectors.structureReportMetadata(state),
  securityReportMetaData: applicationSelectors.securityReportMetaData(state),
});

export default injectIntl(
  connect(mapStateToProps)(
    applySection({
      iconName: 'sl-custom-atm',
      furtherDecoration,
    })(LoanAmountApply),
  ),
);
