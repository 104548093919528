import * as actionTypes from 'actions/structureActions';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { DEFAULT_STRUCTURE_INITIAL_STATE } from 'shared/constants/defaults';

import {
  removeEntity,
  setEntity,
  setNewEntity,
  setNewEntities,
} from 'lib/reducerHelper';

export const initialState = {
  errors: {},
  working: DEFAULT_STRUCTURE_INITIAL_STATE, // We only support one structure FOR NOW
  entities: [],
};

const loanStructureReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_STRUCTURE](state) {
      return update(state, {
        working: { $set: DEFAULT_STRUCTURE_INITIAL_STATE },
      });
    },
    [actionTypes.LOAD_STRUCTURE](state, action) {
      const id = parseInt(action.payload, 10);
      return update(state, {
        working: {
          $set: update(DEFAULT_STRUCTURE_INITIAL_STATE, {
            $merge: state.entities.find((e) => e.id === id) || {},
          }),
        },
      });
    },
    [actionTypes.REMOVE_STRUCTURE](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_STRUCTURE](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_STRUCTURES](state, action) {
      // TO BE REMOVED after MYCRM handles other fields updating
      if (state.entities.length > 0) {
        action.payload = action.payload.map((s) => {
          const existing = state.entities.find((e) => e.id === s.id);
          return { ...existing, ...s, selectedLenderIds: [] };
        });
      }
      return setNewEntities(state, action, (payload) => ({
        ...DEFAULT_STRUCTURE_INITIAL_STATE,
        ...payload,
      }));
    },
    [actionTypes.UPDATE_WORKING_STRUCTURE](state, action) {
      return update(state, {
        working: {
          $merge: action.payload,
        },
      });
    },
    [actionTypes.SET_STRUCTURE](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_PRODUCT_ID](state, action) {
      return update(state, {
        working: { productId: { $set: action.payload } },
      });
    },
    [actionTypes.SET_LENDER_ID](state, action) {
      return update(state, { working: { lenderId: { $set: action.payload } } });
    },
    [actionTypes.RESUME_WORKING_STRUCTURE](state, action) {
      return update(state, { working: { $set: action.payload } });
    },
  },
  initialState,
);

export default loanStructureReducer;
