export const REQUEST_BANK_LIST = 'requestBankList';
export const SET_BANK_LIST = 'setBankList';

export const REQUEST_BANK_LOGIN = 'requestBankLogin';
export const REQUEST_BANK_LOGIN_SUCCESS = 'requestBankLoginSuccess';
export const REQUEST_BANK_LOGIN_ERROR = 'requestBankLoginError';
export const CLEAR_BANK_CONNECT_SESSION = 'clearBankConnectSession';

export const CHECK_BANK_LOGIN_COMPLETE = 'checkBankLoginComplete';
export const CHECK_BANK_LOGIN_COMPLETE_SUCCESS =
  'checkBankLoginCompleteSuccess';
export const CHECK_COLLECTION_COMPLETE = 'checkCollectionComplete';

export const SET_TRANSACTION_STATUS = 'setTransactionStatus';
export const SET_BANK_DETAILS = 'setBankDetails';
