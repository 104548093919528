import React, { useState } from 'react';
import PropTypes from 'prop-types';

import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';
import Button from 'components/Button/Button';

import { NATURE_OF_CHANGE } from 'shared/constants/goalLoanAppInfo';

function NatureOfChangeModal(props) {
  const {
    displayName,
    updateAnswers,
    natureOfChangeQs,
    answers,
    isSelected,
    optionId,
  } = props;
  const [modalAnswers, updateModalAnswers] = useState(answers);

  const setModalAnswer = (questionId, value) =>
    updateModalAnswers({ ...modalAnswers, [questionId]: value });
  const questions = natureOfChangeQs.reduce((map, q) => {
    map[q.questionId] = q;
    return map;
  }, {});

  const onSubmit = (intent) => () => {
    const params =
      intent === 'select'
        ? { ...modalAnswers, natureOfChange: `${optionId}` }
        : {
            natureOfChangeId: modalAnswers.natureOfChangeId,
            natureOfChange: modalAnswers.natureOfChange,
          };
    updateAnswers(params);
  };

  const formProps = {
    formName: NATURE_OF_CHANGE,
    action: setModalAnswer,
    questions,
    answers: modalAnswers,
    displayName,
    isModalContent: true,
    submitButtonProps: {
      text: 'Update',
      action: onSubmit('select'),
    },
    showBackButton: false,
  };

  return (
    <>
      <GoalSetterForm {...formProps} />
      {isSelected && (
        <Button
          onClick={onSubmit('unselect')}
          theme='noBorder'
          className='brandColor__font--hover'
        >
          Unselect this option
        </Button>
      )}
    </>
  );
}

NatureOfChangeModal.propTypes = {
  displayName: PropTypes.string,
  updateAnswers: PropTypes.func,
  natureOfChangeQs: PropTypes.arrayOf(PropTypes.object),
  answers: PropTypes.object,
  isSelected: PropTypes.bool,
  optionId: PropTypes.string,
};

export default NatureOfChangeModal;
