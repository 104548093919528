import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  client,
  dependents,
  primaryApplicantsPartner,
} from 'selectors/clientSelectors';
import { householdIsCouple, householdIsParent } from 'lib/utils/formUtils';
import {
  SINGLE_HOUSEHOLD,
  SINGLE_PARENT_HOUSEHOLD,
  COUPLE_HOUSEHOLD,
  COUPLE_PARENT_HOUSEHOLD,
} from 'shared/constants/options';
import { PERSON_CONTACT_TYPES, COMPANY_OR_TRUST } from 'constants/options';

export const workingContacts = (state) => state.contact.working;
export const contacts = (state) => state.contact.entities;
export const contactClientId = (contact) =>
  PERSON_CONTACT_TYPES.includes(contact.type)
    ? contact.clientId
    : contact.companyId;
export const contactByClientId = createSelector(contacts, (a) =>
  _.memoize((clientOrCompanyId) => {
    return a.find(
      (contact) =>
        contact.clientId === clientOrCompanyId ||
        contact.companyId === clientOrCompanyId,
    );
  }),
);

export const clientOrCompanyContactId = createSelector(
  client,
  contactByClientId,
  (a, b) =>
    _.memoize((clientOrCompanyId) => {
      const foundClient = a(clientOrCompanyId);
      if (foundClient) {
        return foundClient.contactId;
      }
      const foundContact = b(clientOrCompanyId);
      if (foundContact) {
        return foundContact.id;
      }
      return null;
    }),
);

export const contact = createSelector(contacts, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);
export const working = (state) => (id) => workingContacts(state)[id];

export const primaryContact = createSelector(contacts, (a) =>
  a ? a.find((e) => e.isPrimaryContact) : null,
);

export const companyContacts = createSelector(contacts, (a) =>
  a.filter((e) => e.type === COMPANY_OR_TRUST),
);

export const coapplicantContacts = createSelector(contacts, (a) =>
  a.filter((e) => e.type !== COMPANY_OR_TRUST && !e.isPrimaryContact),
);

export const personContacts = createSelector(contacts, (a) =>
  a.filter((e) => PERSON_CONTACT_TYPES.includes(e.type)),
);

export const primaryContactsDependents = createSelector(
  primaryContact,
  dependents,
  (a, b) => a && b(a.contactId),
);

export const household = (state) => state.household;

export const householdShape = createSelector(
  primaryContactsDependents,
  primaryApplicantsPartner,
  (children, partner) => {
    if (!children) {
      return undefined;
    }
    if (partner) {
      return children && children.length > 0
        ? COUPLE_PARENT_HOUSEHOLD
        : COUPLE_HOUSEHOLD;
    }
    return children && children.length > 0
      ? SINGLE_PARENT_HOUSEHOLD
      : SINGLE_HOUSEHOLD;
  },
);

export const currentHouseholdShape = createSelector(
  household,
  householdShape,
  (workingHousehold, savedHouseholdShape) => {
    return workingHousehold.householdShape || savedHouseholdShape;
  },
);

export const isCouple = createSelector(currentHouseholdShape, (currentShape) =>
  householdIsCouple(currentShape),
);

export const isParent = createSelector(currentHouseholdShape, (currentShape) =>
  householdIsParent(currentShape),
);

export const isSingle = createSelector(
  isCouple,
  isParent,
  (_isCouple, _isChildren) => {
    return !_isCouple && !_isChildren;
  },
);

export const isCoupleOnlyWithPartnerAdded = createSelector(
  isCouple,
  isParent,
  primaryApplicantsPartner,
  (_isCouple, _isParent, partner) => {
    return _isCouple && !_isParent && !!partner;
  },
);

export const getContactBrokerId = createSelector(personContacts, (c) =>
  c && c.length > 0 ? c[0].brokerId : null,
);
