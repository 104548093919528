import _ from 'lodash';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { unformatCurrency } from 'shared/lib/numbrero';

import * as propertyActionTypes from 'actions/applyPropertyActionTypes';
import * as individualPropertyActionTypes from 'actions/individualPropertyActionTypes';

import {
  PROPERTY_OWNED,
  PROPERTY_RENT,
  PROPERTY_PURCHASING,
} from 'shared/constants/options';
import { PROPERTY_NOT_OWNED_ID } from 'constants/tempIds';

import {
  setWorkingValue,
  setWorkingFormattedAddress,
  setWorkingIntlAddress,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  insertNewEntities,
  setWorkingClientIds,
  setError,
} from 'lib/reducerHelper';

const actionTypes = {
  ...propertyActionTypes,
  ...individualPropertyActionTypes,
};

export const newAddress = { formattedAddress: null, suburb: null, state: null };

export const newProperty = {
  errors: {},
  residence: false,
  owned: PROPERTY_OWNED,
  address: {},
  isPreapproved: false,
};

export const newResidenceProperty = {
  ...newProperty,
  owned: null,
  residence: true,
  startDate: {},
};

export const newProspectiveProperty = {
  ...newProperty,
  owned: PROPERTY_PURCHASING,
  address: null,
  isPreapproved: true,
};

// TODO: Think about whether we should be moving these newXXX objects or functions to a separate file
export const newNotOwnResidencyProperty = (ownership = PROPERTY_RENT) => ({
  ...newResidenceProperty,
  owned: ownership,
  id: PROPERTY_NOT_OWNED_ID,
});

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newProperty,
  },
};

const propertyReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_PROPERTY](state, action) {
      return clearWorking(state, action, newProperty);
    },
    [actionTypes.LOAD_RESIDENCE_PROPERTY](state) {
      return update(state, {
        working: {
          new: { $set: newResidenceProperty },
        },
      });
    },
    [actionTypes.LOAD_PROSPECTIVE_PROPERTY](state) {
      return update(state, {
        working: {
          new: { $set: newProspectiveProperty },
        },
      });
    },
    [actionTypes.LOAD_PROPERTY](state, action) {
      return loadEntityIntoWorking(state, action, newProperty);
    },
    [actionTypes.REMOVE_PROPERTY_APPLY](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_PROPERTY](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_PROPERTIES](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.INSERT_NEW_PROPERTIES](state, action) {
      return insertNewEntities(state, action);
    },
    [actionTypes.SET_PROPERTY](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_PROPERTY_ERROR](state, action) {
      return setError(state, action);
    },
    [actionTypes.SET_PROPERTY_CLIENT_IDS](state, action) {
      return setWorkingClientIds(state, action);
    },
    [actionTypes.SET_PROPERTY_APPLICATION_ID](state, action) {
      return setWorkingValue(state, action.payload, 'applicationId');
    },
    [actionTypes.SET_PROPERTY_OWNED](state, action) {
      return setWorkingValue(state, action.payload, 'owned');
    },
    [actionTypes.SET_PROPERTY_PRIMARY_PURPOSE_ID](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'primaryPurposeId',
        _.toNumber,
      );
    },
    [actionTypes.SET_PROPERTY_FOUND_PROPERTY](state, action) {
      const { id, value } = action.payload;
      let newState = setWorkingValue(state, action.payload, 'foundProperty');
      if (value === 'false') {
        newState = setWorkingValue(newState, { id, value: null }, 'suburb');
        newState = setWorkingValue(newState, { id, value: null }, 'postcode');
      }
      return newState;
    },
    [actionTypes.SET_PROPERTY_OWNER](state, action) {
      return setWorkingValue(state, action.payload, 'owner');
    },
    [actionTypes.SET_PROPERTY_STATE](state, action) {
      return setWorkingValue(state, action.payload, 'state');
    },
    [actionTypes.SET_PROPERTY_INTL_ADDRESS](state, action) {
      return setWorkingIntlAddress(state, action.payload, 'address');
    },
    [actionTypes.SET_PROPERTY_LOCALITY](state, action) {
      const { id, value } = action.payload;
      const { country, suburb, postcode, state: stateName, text } = value;
      return update(state, {
        working: {
          [id]: {
            suburb: { $set: suburb },
            postcode: { $set: postcode },
            state: { $set: stateName },
            country: { $set: country },
            locality: { $set: text },
          },
        },
      });
    },

    [actionTypes.SET_PROPERTY_ADDRESS](state, action) {
      return setWorkingFormattedAddress(state, action.payload, 'address');
    },
    [actionTypes.SET_PROPERTY_VALUE](state, action) {
      return setWorkingValue(state, action.payload, 'value', unformatCurrency);
    },
    [actionTypes.SET_PROPERTY_FUTURE_VALUE](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'futureValue',
        unformatCurrency,
      );
    },
    [actionTypes.SET_PROPERTY_ZONING_ID](state, action) {
      return setWorkingValue(state, action.payload, 'zoningId', _.toNumber);
    },
    [actionTypes.SET_PROPERTY_STATUS_ID](state, action) {
      return setWorkingValue(state, action.payload, 'statusId', _.toNumber);
    },
    [actionTypes.SET_PROPERTY_PROPERTY_TYPE_ID](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'propertyTypeId',
        _.toNumber,
      );
    },
    [actionTypes.SET_PROPERTY_EXISTING_MORTGAGES](state, action) {
      return setWorkingValue(state, action.payload, 'existingMortgages');
    },
    [actionTypes.SET_PROPERTY_USE_AS_SECURITY](state, action) {
      return setWorkingValue(state, action.payload, 'useAsSecurity');
    },
    [actionTypes.SET_PROPERTY_GENERATE_RENTAL_INCOME](state, action) {
      return setWorkingValue(state, action.payload, 'generateRentalIncome');
    },
    [actionTypes.SET_PROPERTY_IS_INTEREST_TAX_DEDUCTIBLE](state, action) {
      return setWorkingValue(state, action.payload, 'isInterestTaxDeductible');
    },
    // eslint-disable-next-line sonarjs/no-identical-functions
    [actionTypes.SET_PROPERTY_RESIDENCE_OWNED](state) {
      return update(state, {
        working: {
          new: { $set: newProspectiveProperty },
        },
      });
    },
  },
  initialState,
);

export default propertyReducer;
