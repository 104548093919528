import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { unformatCurrency } from 'shared/lib/numbrero';
import validator from 'lib/validator';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { NO_ERROR } from 'constants/validators';
import { PURCHASE_COST_LIST } from 'constants/reportItemTitles';

import styles from './Form.css';

// this should be refactored to populate the fundings reducer not other costs

class OtherCostsForm extends Component {
  static propTypes = {
    index: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    action: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.object),
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: null,
  };

  constructor(props) {
    super();
    this.state = {
      name: props.name,
      value: unformatCurrency(props.value),
      error: NO_ERROR,
    };
  }

  onNameBlur = () => {
    this.setState({ error: this.errorOnForm() });
  };

  handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleUpdate();
    }
  };

  handleUpdate = () => {
    const error = this.errorOnForm();
    this.setState({ error });

    if (error === NO_ERROR) {
      this.props.action({
        index: this.props.index,
        name: this.state.name,
        value: this.state.value,
      });
      this.props.onSubmit();
    }
  };

  errorOnForm = () => {
    const { data, index } = this.props;

    const error = validator.required(this.state.name);
    if (error !== NO_ERROR) {
      return error;
    }
    const otherNames = data
      .filter((v, i) => !!v.name && i !== index)
      .map((v) => v.name)
      .concat(PURCHASE_COST_LIST);
    return validator.valueExists(this.state.name, otherNames);
  };

  handleOnCurrencyChange = (value) => {
    this.setState({ value: unformatCurrency(value) });
  };

  handleOnNameChange = (value) => {
    this.setState({ name: value });
  };

  render() {
    const errorMsg =
      this.state.error === NO_ERROR ? (
        ''
      ) : (
        <ErrorMessage className={styles.error} message={this.state.error} />
      );

    return (
      <div className={styles.root}>
        <span className={styles.label}>Name</span>
        <Input
          id='other'
          maxLength={50}
          value={this.state.name || ''}
          action={this.handleOnNameChange}
          onKeyDown={this.handleOnKeyDown}
          onBlur={this.onNameBlur}
          onChange={this.handleOnNameChange}
        />
        {errorMsg}
        <span className={styles.label}>
          Allowance for borrowing additional funds
        </span>
        <CurrencyInput
          id='id'
          maxLength={9}
          action={this.handleOnCurrencyChange}
          value={this.state.value}
          onKeyDown={this.handleOnKeyDown}
        />
        <Button
          id='PopupButton'
          className='brandColor__button'
          onClick={this.handleUpdate}
        >
          Update
        </Button>
      </div>
    );
  }
}

export default OtherCostsForm;
