import _ from 'lodash';

export const filterTypesByCountryCode = (types, countryCode) => {
  return types.filter(
    (type) =>
      !type.availableCountries || type.availableCountries.includes(countryCode),
  );
};

export const generateOptions = (rawTypes, countryCode) => {
  const types = countryCode
    ? filterTypesByCountryCode(rawTypes, countryCode)
    : rawTypes;
  return types.map((type) => {
    if (!type.id && !type.key) {
      return type;
    } /* for an option that is not really a type like { divider: true } */
    return {
      value: type.id || type.key,
      key: type.id || type.key,
      name: type.name,
      label: type.name,
      icon: type.icon,
    };
  });
};

export const findOption = (options, id) => options.find((o) => o.value === id);

const clientOptionLabels = {
  primary: {
    base: 'You',
    possessive: 'Yours',
  },
  partner: {
    base: 'Your partner',
    possessive: 'Your partner’s',
  },
  both: {
    base: 'You and your partner',
    possessive: 'You and your partner’s',
  },
};

const clientOptionGenerator = (ids, caseToUse) => (key) => ({
  key,
  value: ids[key],
  name: clientOptionLabels[key][caseToUse],
  label: clientOptionLabels[key][caseToUse],
});

export const generateClientIdOptions = _.memoize((config) => {
  const { primaryId, partnerId, sharable, possessive } = config;
  const orderedBoth = [primaryId, partnerId].sort();
  const ids = {
    primary: primaryId,
    partner: partnerId,
    both: orderedBoth.join(),
  };
  const caseToUse = possessive ? 'possessive' : 'base';
  const clientOption = clientOptionGenerator(ids, caseToUse);

  const options = [clientOption('primary')];
  if (partnerId) {
    options.push(clientOption('partner'));
    if (sharable) {
      options.push(clientOption('both'));
    }
  }
  return options;
});
