import { hasUpdatedEmailOrMobile } from './clientHelper';

const createWithValidation = (stateProps, dispatchProps) => (args) => {
  const { email } = stateProps.working;
  if (email) {
    dispatchProps.clearErrors();
    dispatchProps.updateClientLogin({
      email,
      action: () => dispatchProps.create(args),
    });
  } else {
    dispatchProps.create(args);
  }
};

const updateWithValidation = (stateProps, dispatchProps, ownProps) => (
  args,
) => {
  const { email } = stateProps.working;
  if (email) {
    const { emailUpdated, mobileUpdated } = hasUpdatedEmailOrMobile(
      stateProps.working,
      stateProps.item,
    );
    const path = `${ownProps.location.pathname}/confirm`;
    const params =
      emailUpdated || mobileUpdated
        ? { path }
        : { action: () => dispatchProps.update(args) };
    dispatchProps.clearErrors();
    dispatchProps.updateClientLogin({
      email: stateProps.working.email,
      clientId: stateProps.working.id,
      ...params,
    });
  } else {
    dispatchProps.update(args);
  }
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  create: createWithValidation(stateProps, dispatchProps),
  update: updateWithValidation(stateProps, dispatchProps, ownProps),
});
