import { defineMessages } from 'react-intl';
import { toLoginOnSignout } from 'lib/pathHelper';

export const NETWORK_ERROR_MESSAGE =
  'Looks like there’s a problem with your network connection. Check if you have internet access and try again.';

export const messages = defineMessages({
  title: {
    id: 'ErrorPage.title',
    defaultMessage: 'Oops!',
  },
  notFound: {
    id: 'ErrorPage.notFound',
    defaultMessage: 'We’re having trouble finding what you’re looking for.',
  },
  noPermission: {
    id: 'ErrorPage.noPermission',
    defaultMessage:
      'You don’t have permission to open this page. Please log in.',
  },
  timeOut: {
    id: 'ErrorPage.timeOut',
    defaultMessage: 'The request timed out. Try reloading the page.',
  },
  serverError: {
    id: 'ErrorPage.serverError',
    defaultMessage:
      'Something went wrong. Our team is looking into it. Please try again later.',
  },
  homeButtonText: {
    id: 'ErrorPage.homeButtonText',
    defaultMessage: 'Home',
  },
  logInButtonText: {
    id: 'ErrorPage.logInButtonText',
    defaultMessage: 'Log In',
  },
  errorCode: {
    id: 'ErrorPage.errorCode',
    defaultMessage: 'Error Code',
  },
  networkError: {
    id: 'ErrorPage.networkError',
    defaultMessage: NETWORK_ERROR_MESSAGE,
  },
  factFindError: {
    id: 'ErrorPage.factFindError',
    defaultMessage:
      'An error has occurred generating your Online Fact Find. Please refer to your broker for MyCRM Support assistance.',
  },
});

const ERROR_NOT_FOUND = {
  name: 'Not Found Error',
  icon: 'GhostEyes',
  message: messages.notFound,
  buttonUrl: '/',
  buttonText: messages.homeButtonText,
};

const ERROR_NO_PERMISSION = {
  name: 'No Permission Error',
  icon: 'Lock',
  message: messages.noPermission,
  buttonUrl: toLoginOnSignout(),
  buttonText: messages.logInButtonText,
};

const ERROR_TIME_OUT = {
  name: 'Time Out Error',
  icon: 'Clock',
  message: messages.timeOut,
  buttonUrl: '/',
  buttonText: messages.homeButtonText,
};

const ERROR_SERVER = {
  name: 'Server Error',
  icon: 'GhostEyes',
  message: messages.serverError,
  buttonUrl: '/',
  buttonText: messages.homeButtonText,
};

const ERROR_NETWORK_CONNECTION = {
  name: 'Network Error',
  icon: 'Tower',
  message: messages.networkError,
  buttonUrl: '/',
  buttonText: messages.homeButtonText,
};

const ERROR_GENERATING_FACTFIND = {
  name: 'Fact Find Error',
  icon: 'Lock',
  message: messages.factFindError,
  buttonUrl: '/',
  buttonText: messages.homeButtonText,
};

const ERROR_STATUS_MAP = {
  400: ERROR_NOT_FOUND,
  401: ERROR_NO_PERMISSION,
  403: ERROR_NO_PERMISSION,
  404: ERROR_NOT_FOUND,
  408: ERROR_TIME_OUT,
  410: ERROR_NOT_FOUND,
  500: ERROR_SERVER,
  502: ERROR_SERVER,
  504: ERROR_NETWORK_CONNECTION,
  505: ERROR_GENERATING_FACTFIND,
};

export const getPageError = (status) =>
  ERROR_STATUS_MAP[status] || ERROR_NOT_FOUND;

export const getErrorStatus = (err) =>
  (err && err.response && err.response.status) || 400;

export const getErrorDetails = (status = 400, customError = {}) => {
  const defaultError = getPageError(status);
  return {
    name:
      customError.name ||
      `${messages.title.defaultMessage} - ${defaultError.name}`,
    details: customError.details || defaultError.message.defaultMessage,
    status,
  };
};

export const isNetworkOffline = (error) => {
  if (!error) {
    return false;
  }
  return error.message === 'Network Error';
};
