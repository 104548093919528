import { defineMessages } from 'react-intl';

const PRODUCT_LVR = [
  {
    value: 50,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow50',
        defaultMessage: 'Borrow up to 50%',
      },
    }),
  },
  {
    value: 60,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow60',
        defaultMessage: 'Borrow up to 60%',
      },
    }),
  },
  {
    value: 70,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow70',
        defaultMessage: 'Borrow up to 70%',
      },
    }),
  },
  {
    value: 80,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow80',
        defaultMessage: 'Borrow up to 80%',
      },
    }),
  },
  {
    value: 85,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow85',
        defaultMessage: 'Borrow up to 85%',
      },
    }),
  },
  {
    value: 90,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow90',
        defaultMessage: 'Borrow up to 90%',
      },
    }),
  },
  {
    value: 95,
    ...defineMessages({
      name: {
        id: 'productLvr.borrow95',
        defaultMessage: 'Borrow up to 95%',
      },
    }),
  },
];

export const formatProductLvr = (formatMessage) =>
  PRODUCT_LVR.map((p) => ({
    ...p,
    name: formatMessage(p.name),
  }));
