/* eslint-disable sonarjs/no-duplicate-string */
// Types:
export const LIABILITY_ATO_DEBT = {
  id: 1,
  name: 'ATO Centrelink Debt',
  availableCountries: ['AU'],
};
export const LIABILITY_CHARGE_CARD = { id: 2, name: 'Charge Card' };
export const LIABILITY_COMMERCIAL_BILL = { id: 3, name: 'Commercial bill' };
export const LIABILITY_CONTINGENT = { id: 4, name: 'Contingent Liability' };
export const LIABILITY_CHILD_MAINTENANCE = {
  id: 5,
  name: 'Court Ruled Child Maintenance',
};
export const LIABILITY_OTHER_DEBT = { id: 6, name: 'Court Ruled Other Debt' };
export const LIABILITY_CREDIT_CARD = { id: 7, name: 'Credit Card' };
export const LIABILITY_STUDENT_HECS = { id: 8, name: 'Student Loan' };
export const LIABILITY_HIRE_PURCHASE = { id: 9, name: 'Hire Purchase' };
export const LIABILITY_LEASE = { id: 10, name: 'Lease' };
export const LIABILITY_LINE_OF_CREDIT = { id: 11, name: 'Line of Credit' };
export const LIABILITY_GUARANTOR = { id: 12, name: 'Loan as Guarantor' };
export const LIABILITY_MAINTENANCE = { id: 13, name: 'Maintenance' };
export const LIABILITY_OTHER = { id: 14, name: 'Other' };
export const LIABILITY_OTHER_LOAN = { id: 15, name: 'Loan' };
export const LIABILITY_OUTSTANDING_TAX = { id: 16, name: 'Outstanding Tax' };
export const LIABILITY_OVERDRAFT = { id: 17, name: 'Overdraft' };
export const LIABILITY_PERSONAL_LOAN = { id: 18, name: 'Personal Loan' };
export const LIABILITY_CAR_LOAN = { id: 19, name: 'Car Loan' };
export const LIABILITY_STORE_CARD = { id: 20, name: 'Store Card' };
export const LIABILITY_TERM_LOAN = { id: 21, name: 'Term Loan' };
export const LIABILITY_BUY_NOW_PAY_LATER = {
  id: 25,
  name: 'Buy Now Pay Later',
};

// Categories:
export const LIABILITY_GOV_CATEGORY = {
  id: 1,
  name: 'Government / Tax',
  icon: 'sl-custom-library',
};
export const LIABILITY_CARD_CATEGORY = {
  id: 2,
  name: 'Card',
  icon: 'sl-custom-credit-card',
};
export const LIABILITY_OTHER_CATEGORY = {
  id: 3,
  name: 'Other',
  icon: 'sl-custom-edit-3',
};
export const LIABILITY_STUDENT_CATEGORY = {
  id: 4,
  name: 'Student Loan',
  icon: 'sl-custom-dollar-bag',
};
export const LIABILITY_STUDENT_CATEGORY_AU = {
  id: 4,
  name: 'HECS/HELP Student Loan',
  icon: 'sl-custom-dollar-bag',
};
export const LIABILITY_MAINTENANCE_CATEGORY = {
  id: 5,
  name: 'Maintenance',
  icon: 'sl-custom-dollar-bag',
};
export const LIABILITY_PERSONAL_LOAN_CATEGORY = {
  id: 6,
  name: 'Personal Loan',
  icon: 'sl-custom-dollar-bag',
};
export const LIABILITY_CAR_CATEGORY = {
  id: 7,
  name: 'Car Loan',
  icon: 'sl-custom-car-3',
};

export const liabilityCategories = [
  LIABILITY_CARD_CATEGORY,
  LIABILITY_CAR_CATEGORY,
  LIABILITY_PERSONAL_LOAN_CATEGORY,
  LIABILITY_STUDENT_CATEGORY,
  LIABILITY_GOV_CATEGORY,
  LIABILITY_MAINTENANCE_CATEGORY,
  LIABILITY_OTHER_CATEGORY,
];

export const liabilityCategoriesAU = [
  LIABILITY_CARD_CATEGORY,
  LIABILITY_CAR_CATEGORY,
  LIABILITY_PERSONAL_LOAN_CATEGORY,
  LIABILITY_STUDENT_CATEGORY_AU,
  LIABILITY_GOV_CATEGORY,
  LIABILITY_MAINTENANCE_CATEGORY,
  LIABILITY_OTHER_CATEGORY,
];

export const liabilityTypesPerCategory = {
  [LIABILITY_GOV_CATEGORY.id]: [LIABILITY_ATO_DEBT, LIABILITY_OUTSTANDING_TAX],
  [LIABILITY_CARD_CATEGORY.id]: [
    LIABILITY_CREDIT_CARD,
    LIABILITY_CHARGE_CARD,
    LIABILITY_STORE_CARD,
  ],
  [LIABILITY_OTHER_CATEGORY.id]: [
    LIABILITY_COMMERCIAL_BILL,
    LIABILITY_CONTINGENT,
    LIABILITY_CHILD_MAINTENANCE,
    LIABILITY_OTHER_DEBT,
    LIABILITY_HIRE_PURCHASE,
    LIABILITY_LEASE,
    LIABILITY_LINE_OF_CREDIT,
    LIABILITY_GUARANTOR,
    LIABILITY_OTHER,
    LIABILITY_OTHER_LOAN,
    LIABILITY_OVERDRAFT,
    LIABILITY_TERM_LOAN,
    LIABILITY_BUY_NOW_PAY_LATER,
  ],
  [LIABILITY_STUDENT_CATEGORY.id]: [LIABILITY_STUDENT_HECS],
  [LIABILITY_MAINTENANCE_CATEGORY.id]: [LIABILITY_MAINTENANCE],
  [LIABILITY_PERSONAL_LOAN_CATEGORY.id]: [LIABILITY_PERSONAL_LOAN],
  [LIABILITY_CAR_CATEGORY.id]: [LIABILITY_CAR_LOAN],
};

export const theOnlyType = {
  [LIABILITY_STUDENT_CATEGORY.id]: LIABILITY_STUDENT_HECS.id,
  [LIABILITY_MAINTENANCE_CATEGORY.id]: LIABILITY_MAINTENANCE.id,
  [LIABILITY_PERSONAL_LOAN_CATEGORY.id]: LIABILITY_PERSONAL_LOAN.id,
  [LIABILITY_CAR_CATEGORY.id]: LIABILITY_CAR_LOAN.id,
};

export const LIABILITY_NUMBER_PROPERTIES = [
  'originalLoanAmount',
  'repaymentAmount',
  'totalCardLimit',
  'totalLoanAmount',
];
