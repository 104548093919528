import { EXPENSE_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export function putExpense(expense, isLivingExpensesV2Enabled) {
  return axiosWithAuth
    .put(`${EXPENSE_API_PATH}/${expense.id}`, {
      ...expense,
      isLivingExpensesV2Enabled,
    })
    .then(returnJSON);
}

export function delExpense(expenseId) {
  return axiosWithAuth
    .delete(`${EXPENSE_API_PATH}/${expenseId}`)
    .then(returnJSON);
}
