import { GOAL_SETTER_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON } from '../baseApi';

const { CONTACTS: BASE_API } = GOAL_SETTER_API_PATH;

export function getContactAssets(contactId) {
  return axiosWithAuth.get(`${BASE_API}/${contactId}/assets`).then(returnJSON);
}

export function getContactApplications(contactId, clientIds) {
  return axiosWithAuth
    .get(`${BASE_API}/${contactId}/applications`, { params: { clientIds } })
    .then(returnJSON);
}
