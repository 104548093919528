import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { interpolateNumber } from 'd3-interpolate';
import { timer } from 'd3-timer';
import { easeExpOut } from 'd3-ease';
import _ from 'lodash';

class Counter extends Component {
  static propTypes = {
    endValue: PropTypes.number.isRequired,
    duration: PropTypes.number,
    className: PropTypes.string,
    formatValue: PropTypes.func,
    roundValue: PropTypes.bool,
    startValue: PropTypes.number,
  };

  static defaultProps = {
    duration: 400,
    formatValue: _.identity,
    roundValue: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentValue: _.isFinite(props.startValue)
        ? props.startValue
        : props.endValue,
    };
  }

  componentDidMount() {
    const { startValue, duration, endValue } = this.props;
    const { currentValue } = this.state;

    if (Math.round(currentValue) !== Math.round(endValue)) {
      this.startTransition(startValue, endValue, duration);
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { endValue, duration } = nextProps;
    const { currentValue } = this.state;
    if (endValue !== this.props.endValue) {
      this.startTransition(currentValue, endValue, duration);
    }
  }

  componentWillUnmount() {
    this.transition && this.transition.stop();
  }

  startTransition(currentValue, endValue, duration) {
    const transFn = this.transitionFn(currentValue, endValue, duration);

    if (!this.transition) {
      this.transition = timer(transFn);
    } else {
      this.transition.restart(transFn);
    }
  }

  transitionFn = (start, end, duration) => {
    const interp = interpolateNumber(start, end);
    return (elapsed) => {
      const t = elapsed / duration;
      if (t > 1) {
        this.setState({ currentValue: end });
        this.transition && this.transition.stop();
      } else {
        this.setState({ currentValue: interp(easeExpOut(t)) });
      }
    };
  };

  renderCurrentValue() {
    const { formatValue, endValue, roundValue } = this.props;
    const { currentValue } = this.state;

    return formatValue(
      endValue === currentValue || !roundValue
        ? currentValue
        : Math.round(currentValue),
    );
  }

  render() {
    const { className } = this.props;

    return <span className={className}>{this.renderCurrentValue()}</span>;
  }
}

export default Counter;
