import axios from 'axios';
import { DOCUMENT_API_PATH } from 'shared/constants/apiPaths';
import locale from 'config/locale';

import { axiosWithAuth, returnJSON } from './baseApi';

export function getCancelTokenSource() {
  return axios.CancelToken.source();
}

export function putPresigned(presigned, binary, options) {
  return axios.put(presigned, binary, options).then(returnJSON);
}

export function getSignedUrl(params) {
  return axiosWithAuth
    .get(`${DOCUMENT_API_PATH}/secure-url`, { params })
    .then(returnJSON);
}

export function putDocument(documentId, document) {
  return axiosWithAuth
    .put(`${DOCUMENT_API_PATH}/${documentId}`, document)
    .then(returnJSON);
}

export function delDocument(documentId) {
  return axiosWithAuth
    .delete(`${DOCUMENT_API_PATH}/${documentId}`)
    .then(returnJSON);
}

export function getFileInvite(clientId) {
  return axiosWithAuth
    .get(`${DOCUMENT_API_PATH}/file-invite/${clientId}`)
    .then(returnJSON);
}

export function getDocumentTypes() {
  return axiosWithAuth
    .get(`${DOCUMENT_API_PATH}/types?countryCode=${locale.countryCode}`)
    .then(returnJSON);
}
