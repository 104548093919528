import { INVALID_NZBN, NO_ERROR, ERROR_MESSAGES } from 'constants/validators';

export const NZBN_NO_ERROR = { id: 'nzbn', text: NO_ERROR };
export const NZBN_INVALID = {
  id: 'nzbn',
  text: ERROR_MESSAGES[INVALID_NZBN],
  blocking: true,
};
const NZBN_START = '94';

export const isValidNZBN = (nzbn) =>
  !!nzbn && nzbn.length === 13 && nzbn.startsWith(NZBN_START);
