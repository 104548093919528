import React, { Component } from 'react';
import PropTypes from 'prop-types';

const forceFocus = (WrappedComponent) =>
  class ForcedFocus extends Component {
    static displayName = `ForcedFocus(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    static propTypes = {
      forceFocus: PropTypes.bool,
    };

    componentDidMount() {
      if (this.focusRef && this.props.forceFocus) {
        this.focusRef.focus();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.focusRef && this.props.forceFocus && !prevProps.forceFocus) {
        this.focusRef.focus();
      }
    }

    setFocusRef = (ref) => {
      this.focusRef = ref;
    };

    render() {
      return (
        <WrappedComponent {...this.props} setFocusRef={this.setFocusRef} />
      );
    }
  };

export default forceFocus;
