import _ from 'lodash';

// NOTE: Numbrero is being slowly replaced by Intl and lib/intlFormatters.js

function valueCanBeHandled(value) {
  if (
    value === null ||
    typeof value === 'undefined' ||
    !isFinite(value) ||
    value === ''
  ) {
    return false;
  }
  return true;
}

export function isNumber(n) {
  return typeof n !== 'boolean' && !isNaN(n);
}

export function numberToString(value) {
  if (!valueCanBeHandled(value)) {
    return '';
  }
  let returnVal = value;
  if (returnVal % 1 === 0) {
    returnVal = returnVal.toString();
  } else {
    returnVal = parseFloat(returnVal).toFixed(2);
  }
  return returnVal.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export function formatNumber(
  value,
  { prefix = '', suffix = '', negativeSign = true } = {},
) {
  if (!valueCanBeHandled(value)) {
    return '';
  }

  const negativeChar = negativeSign ? '-' : '';
  const suffixToAdd =
    (suffix !== '' && suffix.indexOf(' ') !== 0 ? ' ' : '') + suffix;

  if (value < 0) {
    return `${negativeChar}${prefix}${numberToString(-value)}${suffixToAdd}`;
  } else if (value > 0) {
    return `${prefix}${numberToString(value)}${suffixToAdd}`;
  }

  return `${prefix}0${suffixToAdd}`;
}

// TODO remove last usages of these functions; They are deprecated in favour of intl!
export const formatCurrency = _.partialRight(formatNumber, { prefix: '$' });
export const formatPercentage = _.flow(numberToString, (v) => `${v || '-'}%`);

export function unformatCurrency(value) {
  if (!value && value !== 0) {
    return null;
  }
  if (typeof value === 'string' || value instanceof String) {
    return Number(value.replace(/[^\d.-]+/g, ''));
  }
  if (valueCanBeHandled(value)) {
    return Number(value);
  }
  return null;
}

// Values of frequency are supposed to be constants in constants/options.js
export function convertFrequency(value, fromFrequency, toFrequency) {
  return value * (fromFrequency / toFrequency);
}
