import { FUNDINGS_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export function getDepositTypes() {
  return axiosWithAuth
    .get(`${FUNDINGS_API_PATH}/deposit-types`)
    .then(returnJSON);
}

export function putFunding(funding) {
  return axiosWithAuth
    .put(`${FUNDINGS_API_PATH}/${funding.id}`, funding)
    .then(returnJSON);
}

export function delFunding(fundingId) {
  return axiosWithAuth
    .delete(`${FUNDINGS_API_PATH}/${fundingId}`)
    .then(returnJSON);
}
