import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { formatCurrency, formatPercentage } from 'lib/intlFormatters';
import { findOption } from 'lib/optionHelpers';
import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';

import { ASSET_REAL_ESTATE_CATEGORY } from 'shared/constants/myCRMTypes/assets';

import * as assetSelectors from 'selectors/assetSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import assetActions from 'actions/assetActions';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';

import Button from 'components/Button/Button';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import EditableItem from 'components/EditableItem/EditableItem';
import DonutChart from 'components/DonutChart/DonutChart';
import QuestionHint from 'components/Question/QuestionHint';
import { assetsHint } from 'lib/hintHelper';
import { logEvent, EVENTS } from 'lib/amplitude';
import { ASSET_CATEGORY_OPTIONS } from 'constants/options';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';
import { ASSETS_SLUG } from 'constants/applyData';

const messages = defineMessages({
  title: {
    id: 'AssetsApply.assets',
    defaultMessage: 'Assets',
  },
  titleDescription: {
    id: 'AssetsApply.titleDescription',
    defaultMessage: 'Tell us about your assets',
  },
  headerDescription: {
    id: 'AssetsApply.headerDescription',
    defaultMessage: 'Do you have any assets?',
  },
  titleDescriptionCompleted: {
    id: 'AssetsApply.titleDescriptionCompleted',
    defaultMessage:
      '{totalAssets, plural, =0 {You have no assets} other {+{totalAssets, number, currency}}}',
  },
  headerDescriptionCompleted: {
    id: 'AssetsApply.headerDescriptionCompleted',
    defaultMessage:
      '{totalAssets, plural, =0 {You have no assets} other {Your assets are worth {totalAssets, number, currency}}}',
  },
  totalAssets: {
    id: 'AssetsApply.totalAssets',
    defaultMessage: 'Total assets',
  },
  addAsset: {
    id: 'AssetsApply.addAsset',
    defaultMessage: 'Add an asset',
  },
  editAsset: {
    id: 'AssetsApply.editAsset',
    defaultMessage: 'Edit asset',
  },
  hasAssetQuestion: {
    id: 'AssetsApply.hasAssetQuestion',
    defaultMessage: 'Do you have any assets?',
  },
});

export const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    totalAssets,
    isCompleted,
    warningMessage,
  } = props;
  const postfix = isCompleted ? 'Completed' : '';
  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      totalAssets,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      totalAssets,
    }),
    warningMessage,
  };
};

const nextButtonProps = ({ isCompleted, hasAssets }) => ({
  disabled: !isCompleted && _.isNil(hasAssets),
});

const confirmEntities = (props) => {
  const { confirmAssets, applicationId, primaryContactAssets } = props;

  confirmAssets({ id: applicationId, assets: primaryContactAssets });
};

class AssetsApply extends Component {
  static displayName = 'AssetsApply';
  static propTypes = {
    intl: intlShape.isRequired,
    assetChartData: PropTypes.arrayOf(PropTypes.object),
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    primaryApplicantsAssets: PropTypes.arrayOf(PropTypes.object).isRequired,
    primaryApplicantsPartnersAssets: PropTypes.arrayOf(PropTypes.object),
    sharedAssets: PropTypes.arrayOf(PropTypes.object),
    primaryApplicantsAssetsTotal: PropTypes.number,
    primaryApplicantsPartnersAssetTotal: PropTypes.number,
    sharedAssetsTotal: PropTypes.number,
    totalAssets: PropTypes.number,
    hasAssets: PropTypes.bool,
    setMetadata: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    warningMessage: PropTypes.string,
    urlPath: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    applicationId: PropTypes.number,
    // eslint-disable-next-line react/no-unused-prop-types
    primaryContactAssets: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    primaryApplicantsPartnersAssets: [],
    sharedAssets: [],
  };

  onHasAssetsClick = (value) => {
    this.props.setMetadata({ hasAssets: value });
  };

  newAssetUrl = () => `${this.props.urlPath}/assets/asset/new`;
  editAssetUrl = (id, isRealEstate) =>
    `${this.props.urlPath}/assets/${isRealEstate ? 'property' : 'asset'}/${id}`;

  editableAssetItem = (singleAsset) => {
    const {
      intl,
      accordionProps: { isLocked },
    } = this.props;
    const isRealEstate = singleAsset.category === ASSET_REAL_ESTATE_CATEGORY.id;
    const categoryInfo = findOption(
      ASSET_CATEGORY_OPTIONS,
      singleAsset.category,
    );

    return (
      <EditableItem
        key={`asset-${singleAsset.id}`}
        url={this.editAssetUrl(singleAsset.id, isRealEstate)}
        leftLabel={categoryInfo.label}
        leftIcon={categoryInfo.icon}
        leftDescription={
          isRealEstate
            ? singleAsset.address.formattedAddress
            : singleAsset.description
        }
        rightLabel={
          singleAsset.value
            ? formatCurrency(intl)(singleAsset.value)
            : undefined
        }
        rightDescription={singleAsset.value ? 'Total' : undefined}
        hasWarning={singleAsset.isOldData && !isLocked}
      />
    );
  };

  onAddAsset = () => {
    logEvent(EVENTS.ADD_FINANCIALS, { section: ASSETS_SLUG });
  };

  renderMaybeNoAssets() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      hasAssets,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasAssets'
        label={formatMessage(messages.hasAssetQuestion)}
        hint={assetsHint}
        action={this.onHasAssetsClick}
        value={hasAssets}
        disabled={isLocked}
      />
    );
  }

  renderHasAssets() {
    const {
      assetChartData,
      totalAssets,
      intl,
      primaryApplicantsAssets,
      primaryApplicantsAssetsTotal,
      primaryApplicantsPartnersAssets,
      primaryApplicantsPartnersAssetTotal,
      sharedAssets,
      sharedAssetsTotal,
    } = this.props;

    return (
      <div id='assetsApply'>
        <DonutChart
          id='assets'
          data={assetChartData}
          label='Assets'
          value={totalAssets}
          percentageFormatter={formatPercentage(intl)}
          valueFormatter={formatCurrency(intl)}
          simple
        />
        {primaryApplicantsAssets.length > 0 && [
          <h4 key='your-title'>You</h4>,
          <ApplyItemContainer
            includeTotal
            totalTitle='Your assets'
            totalValue={formatCurrency(intl)(primaryApplicantsAssetsTotal)}
            key='applyItemContainer'
          >
            {primaryApplicantsAssets.map(this.editableAssetItem)}
          </ApplyItemContainer>,
        ]}
        {primaryApplicantsPartnersAssets.length > 0 && [
          <h4 key='partner-title'>Your partner</h4>,
          <ApplyItemContainer
            includeTotal
            totalTitle='Your partner’s assets'
            totalValue={formatCurrency(intl)(
              primaryApplicantsPartnersAssetTotal,
            )}
            key='applyItemContainer'
          >
            {primaryApplicantsPartnersAssets.map(this.editableAssetItem)}
          </ApplyItemContainer>,
        ]}
        {sharedAssets.length > 0 && [
          <h4 key='you-and-parter-title'>You and your partner</h4>,
          <ApplyItemContainer
            includeTotal
            totalTitle='Your shared assets'
            totalValue={formatCurrency(intl)(sharedAssetsTotal)}
            key='applyItemContainer'
          >
            {sharedAssets.map(this.editableAssetItem)}
          </ApplyItemContainer>,
        ]}
        <QuestionHint id='hasAssets' hint={assetsHint} />
      </div>
    );
  }

  render() {
    const {
      totalAssets,
      hasAssets,
      accordionProps: { isLocked },
      intl: { formatMessage },
    } = this.props;

    return (
      <div id='assetApply'>
        {totalAssets ? this.renderHasAssets() : this.renderMaybeNoAssets()}
        {(totalAssets || hasAssets) && !isLocked && (
          <Button
            url={this.newAssetUrl()}
            theme='applyNew'
            icon='sl-custom-car-1'
            onClick={this.onAddAsset}
          >
            {formatMessage(messages.addAsset)}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id: primaryApplicantId } = ownProps.primaryApplicant;

  const props = {
    assetChartData: assetSelectors.chartData(state),
    primaryApplicantsAssets: assetSelectors.clientsAssets(state)([
      primaryApplicantId,
    ]),
    primaryApplicantsAssetsTotal: assetSelectors.clientsAssetsTotal(state)([
      primaryApplicantId,
    ]),
    clientIdOwnershipOptions: clientSelectors.clientIdOwnershipOptions(state),
    primaryContactAssets: assetSelectors.primaryContactAssets(state),
  };
  if (ownProps.primaryApplicantsPartner) {
    const partnerId = ownProps.primaryApplicantsPartner.id;
    const clientIds = [primaryApplicantId, partnerId];
    props.primaryApplicantsPartnersAssets = assetSelectors.clientsAssets(
      state,
    )([partnerId]);
    props.primaryApplicantsPartnersAssetTotal = assetSelectors.clientsAssetsTotal(
      state,
    )([partnerId]);
    props.sharedAssets = assetSelectors.clientsAssets(state)(clientIds);
    props.sharedAssetsTotal = assetSelectors.clientsAssetsTotal(state)(
      clientIds,
    );
  }
  props.totalAssets = assetSelectors.totalAssets(state);
  props.applicationId = applicationSelectors.getApplicationId(state);
  return props;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      confirmAssets: assetActions.confirmAssets,
    },
    dispatch,
  );

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-car-1',
      furtherDecoration,
      nextButtonProps,
      confirmEntities,
    })(AssetsApply),
  ),
);
