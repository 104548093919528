import React from 'react';
import PropTypes from 'prop-types';

import { generateUniqueID } from 'lib/utils/stringUtils';

import styles from './AnimatedWrapper.css';

const AnimationWrapper = ({ id, children }) => (
  <section
    id={generateUniqueID('AnimationWrapper', id)}
    className={styles.container}
  >
    {children}
  </section>
);

AnimationWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default AnimationWrapper;
