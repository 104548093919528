import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ApplyItemContainer.css';

const ApplyItemContainer = ({
  children,
  includeTotal,
  totalTitle,
  totalValue,
  showHint,
  smallMargin,
}) => (
  <div
    className={classNames(styles.root, {
      [styles.total]: includeTotal,
      [styles.hasHint]: showHint,
      [styles.smallMargin]: smallMargin,
    })}
  >
    {children}
    {includeTotal && (
      <div className={styles.totalSection}>
        <div>{totalTitle}</div>
        <div className={styles.value}>{totalValue}</div>
      </div>
    )}
  </div>
);

ApplyItemContainer.propTypes = {
  children: PropTypes.node,
  totalValue: PropTypes.string,
  includeTotal: PropTypes.bool,
  totalTitle: PropTypes.string,
  showHint: PropTypes.bool,
  smallMargin: PropTypes.bool,
};

export default ApplyItemContainer;
