/* eslint-disable react/display-name */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import { extractQueries, isGoalSetter } from 'lib/utils/browserUtils';

import locale from 'config/locale';
import LocalStorageProxy from 'lib/localStorageProxy';
import { setFeature } from 'lib/abTestHelper';
import { isApplyNow } from 'lib/envHelper';
import propsMixin from 'hocs/propsMixin';
import { OKTA_GROUPS } from 'constants/okta-groups';

import {
  DASHBOARD_BASE_PATH,
  HELP_PATH,
  NOT_FOUND_PATH,
  NZ_BRANDED_EXCLUDE_REDIRECT,
  ROOT_PATH,
  UPDATE_LOGIN_POPUP_PATHS,
  PRIVACY_POLICY_PATH,
} from 'shared/constants/paths';

import * as UISelectors from 'selectors/UISelectors';
import { primaryApplicant } from 'selectors/clientSelectors';
import { workingApplication } from 'selectors/applicationSelectors';
import { hydrateMetadata } from 'selectors/reportSelectors';
import Popup from 'components/PopupItem/Popup';
import ParentView from 'components/ParentView/ParentView';
import UpdateLoginPopup from 'components/Popups/UpdateLoginPopup';
import { toMyCRM } from 'lib/pathHelper';
import { featureFlags } from 'lib/rollout';

export const routeComponentPropTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

const routePropTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  store: PropTypes.object,
  path: PropTypes.string,
};

const UnconnectedRouteForLoggedin = ({
  component: Component,
  fetchingUserInfo,
  loadingGoalLoanApp,
  ...rest
}) => <SecureRoute {...rest} component={Component} />;

UnconnectedRouteForLoggedin.propTypes = {
  ...routePropTypes,
  fetchingUserInfo: PropTypes.bool.isRequired,
  loadingGoalLoanApp: PropTypes.bool,
};

export const RouteForLoggedin = connect((state) => ({
  fetchingUserInfo: UISelectors.fetchingUserInfo(state),
  loadingGoalLoanApp: UISelectors.loadingGoalLoanApp(state),
}))(UnconnectedRouteForLoggedin);

const UnconnectedApplyRouteForLoggedin = ({
  component: Component,
  fetchingUserInfo,
  loadingGoalLoanApp,
  applicant,
  application,
  path,
  ...rest
}) => {
  const isPrivacyPolicyEnabled = featureFlags.oneTouchPrivacy.isEnabled();
  if (
    isPrivacyPolicyEnabled &&
    !LocalStorageProxy.loginAsLoanApplicationId &&
    !fetchingUserInfo &&
    !!applicant &&
    !(application?.hasSignedPrivacyPolicy || applicant?.hasSignedPrivacyPolicy)
  ) {
    return <Redirect to={PRIVACY_POLICY_PATH} />;
  }
  return <SecureRoute {...rest} component={Component} />;
};

UnconnectedApplyRouteForLoggedin.propTypes = {
  ...routePropTypes,
  fetchingUserInfo: PropTypes.bool.isRequired,
  loadingGoalLoanApp: PropTypes.bool,
  applicant: PropTypes.object,
  application: PropTypes.object,
};

export const ApplyRouteForLoggedin = connect((state) => ({
  fetchingUserInfo: UISelectors.fetchingUserInfo(state),
  loadingGoalLoanApp: UISelectors.loadingGoalLoanApp(state),
  applicant: primaryApplicant(state),
  application: workingApplication(state),
}))(UnconnectedApplyRouteForLoggedin);

export const redirectIfLoggedIn = (
  Component,
  { isAuthenticated, idToken },
  defaultPath = DASHBOARD_BASE_PATH,
) => (props) => {
  if (!isAuthenticated) {
    return <Component {...props} />;
  }
  const {
    claims: { groups },
  } = idToken;
  const {
    loginAsLoanApplicationId,
    loginAsClientId,
    advisorOrgSlug,
  } = LocalStorageProxy;

  if (
    groups.includes(OKTA_GROUPS.CORPORATE) ||
    groups.includes(OKTA_GROUPS.ADVISER)
  ) {
    const origin = `${window.location.origin}${
      advisorOrgSlug ? `/${advisorOrgSlug}` : ''
    }`;
    let redirectUrl = toMyCRM();
    if (loginAsLoanApplicationId && loginAsClientId) {
      redirectUrl = `${origin}/#/apply/${loginAsLoanApplicationId}`;
    } else if (loginAsLoanApplicationId) {
      redirectUrl = `${origin}/goal/#/${loginAsLoanApplicationId}`;
    }
    window.location.href = redirectUrl;
    return;
  }
  return <Redirect to={defaultPath} />;
};

const UnconnectedRouteForNonLoggedin = ({
  component: Component,
  advisorOrg,
  ...rest
}) => {
  const { authState } = useOktaAuth();
  const exclude =
    (rest.path !== ROOT_PATH || !authState.isAuthenticated) &&
    !NZ_BRANDED_EXCLUDE_REDIRECT.find((path) => rest.path.includes(path));
  const NZBranded =
    advisorOrg.countryCode === 'NZ' || locale.data.countryCode === 'NZ';
  if (
    isApplyNow() &&
    Object.keys(advisorOrg).length === 0 &&
    !LocalStorageProxy.advisorOrgSlug &&
    rest.path === '/'
  ) {
    return <Redirect to={`${HELP_PATH}${NOT_FOUND_PATH}`} />;
  }
  if (!isGoalSetter() && exclude && NZBranded) {
    return <Redirect to={HELP_PATH} />;
  }
  return <Route {...rest} render={redirectIfLoggedIn(Component, authState)} />;
};
UnconnectedRouteForNonLoggedin.propTypes = {
  ...routePropTypes,
  advisorOrg: PropTypes.object,
};

export const RouteForNonLoggedin = connect((state) => ({
  advisorOrg: state.advisorOrg,
}))(UnconnectedRouteForNonLoggedin);

const UnconnectedNZExcludedRoute = ({
  component: Component,
  advisorOrg,
  ...rest
}) => {
  const exclude =
    (rest.path !== ROOT_PATH || !authState.isAuthenticated) &&
    !NZ_BRANDED_EXCLUDE_REDIRECT.find((path) => rest.path.includes(path));
  const NZBranded =
    advisorOrg.countryCode === 'NZ' || locale.data.countryCode === 'NZ';
  if (!isGoalSetter() && exclude && NZBranded) {
    return <Redirect to={HELP_PATH} />;
  }
  return <Route {...rest} component={Component} />;
};
UnconnectedNZExcludedRoute.propTypes = {
  ...routePropTypes,
  advisorOrg: PropTypes.object,
};
export const NZExcludedRoute = connect((state) => ({
  advisorOrg: state.advisorOrg,
}))(UnconnectedNZExcludedRoute);

export const CampaignRedirect = ({ match }) => (
  <Redirect to={`/?campaign=${match.params.slug}`} />
);

CampaignRedirect.propTypes = routeComponentPropTypes;

export const SetFeatureByRoute = (props) => {
  const { feature, state } = props.match.params;
  if (state === 'on' || state === 'off') {
    setFeature(feature, state);
  }

  return <Redirect to='/' />;
};

SetFeatureByRoute.propTypes = routeComponentPropTypes;

export const PopupWithParentPath = (Component, path) => (props) => {
  return <Component {...props} parentPath={path} />;
};

const popupFactory = (metadata) =>
  injectIntl(
    connect(hydrateMetadata(metadata))(
      propsMixin(Popup, { waitForAsyncRequest: false, closeOnOverlay: true }),
    ),
  );

const generatePopupRoutes = (match) => (metadata) => (
  <Route
    key={metadata.url}
    path={`${match.path}${metadata.path || metadata.url}`}
    component={PopupWithParentPath(
      metadata.component || popupFactory(metadata),
      match.url,
    )}
  />
);

export const ParentViewWrapper = (Component, path, isGoal) => (props) => {
  const strings = path.split('/');
  const pathname = `/${strings.length > 2 ? strings[2] : ''}`;
  const isHomepage = pathname === '/';
  const viewProps = {
    showFooter: false,
    goal: isGoal,
    theme: isHomepage ? 'goalHomepage' : undefined,
    showRightContent: !isHomepage,
  };
  return (
    <ParentView viewProps={viewProps}>
      <Component {...props} />
    </ParentView>
  );
};

export const GoalRouteForLoggedin = ({ component: Component, path }) => {
  return (
    <SecureRoute
      path={path}
      component={ParentViewWrapper(Component, path, true)}
    />
  );
};
GoalRouteForLoggedin.propTypes = routePropTypes;

export const RouteWithParentWrapperForLoggedin = ({
  component: Component,
  path,
}) => {
  return (
    <RouteForLoggedin
      path={path}
      component={ParentViewWrapper(Component, path, false)}
    />
  );
};
RouteWithParentWrapperForLoggedin.propTypes = routePropTypes;

export const reportRoutes = (report, popups) => {
  const ReportPopupRoutes = ({ match }) => (
    <div>
      <Route path={match.path} component={report} />
      {!match.isExact && (
        <Switch>
          {popups.map(generatePopupRoutes(match))}
          <Redirect to={match.url} />
        </Switch>
      )}
    </div>
  );
  ReportPopupRoutes.propTypes = routeComponentPropTypes;
  return ReportPopupRoutes;
};

const UpdateLoginPopupRoute = ({ path, url }) => (
  <SecureRoute
    path={path}
    component={PopupWithParentPath(UpdateLoginPopup, url)}
  />
);

UpdateLoginPopupRoute.propTypes = {
  url: PropTypes.string,
  path: PropTypes.string,
};

export const updateLoginPopupRoutes = (match) => (
  <>
    {UPDATE_LOGIN_POPUP_PATHS.map((path) => (
      <UpdateLoginPopupRoute
        key={path}
        url={match.url}
        path={`${match.path}${path}/confirm`}
      />
    ))}
  </>
);

const UnconnectedRequestPrivacyConsentRoute = ({
  component: Component,
  location,
  fetchingUserInfo,
  ...rest
}) => {
  const { authState } = useOktaAuth();
  const { token, advisorUuid } = extractQueries(location.search) || {};
  switch (true) {
    case fetchingUserInfo || authState.isAuthenticated:
      return <SecureRoute {...rest} component={Component} />;
    case !!(!authState.isAuthenticated && token && advisorUuid):
      return <Route {...rest} component={Component} />;
    default:
      return <Redirect to={HELP_PATH} />;
  }
};
UnconnectedRequestPrivacyConsentRoute.propTypes = {
  ...routeComponentPropTypes,
  ...routePropTypes,
  fetchingUserInfo: PropTypes.bool.isRequired,
};

export const RequestPrivacyConsentRoute = connect((state) => ({
  fetchingUserInfo: UISelectors.fetchingUserInfo(state),
}))(UnconnectedRequestPrivacyConsentRoute);

const UnconnectedRouteWithAdvisorData = ({
  component: Component,
  hasAdvisor,
  ...rest
}) => {
  if (!hasAdvisor) {
    return <Redirect to={HELP_PATH} />;
  }
  return <Route {...rest} component={Component} />;
};
UnconnectedRouteWithAdvisorData.propTypes = {
  ...routePropTypes,
  hasAdvisor: PropTypes.bool,
};

export const RouteWithAdvisorData = connect((state) => ({
  hasAdvisor: !!state.advisor?.familyId,
}))(UnconnectedRouteWithAdvisorData);
