import React, { Component } from 'react';
import PropTypes from 'prop-types';

import forceFocus from 'hocs/forceFocus';
import { formatterPropType } from 'lib/formatters';
import { allowFormFormatting } from 'lib/utils/browserUtils';
import SharedInput from '@bit/loanmarket.share.components.input';

class Input extends Component {
  static propTypes = {
    formatter: formatterPropType,
  };

  static contextTypes = {
    inactive: PropTypes.bool,
    errored: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    if (allowFormFormatting() && this.props.formatter) {
      this.formatter = this.props.formatter;
    }
  }

  render() {
    const { format, unformat } = this.formatter || {};

    return <SharedInput {...this.props} format={format} unformat={unformat} />;
  }
}

export default forceFocus(Input);
