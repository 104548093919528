export const NEW_FORK = 'newFork';
export const GOAL_SETTER_LENDERS = 'goalSetterLenders';
export const LOAN_AMOUNT = 'loanAmount';

// NOTE: Value of entry is the percentage of users who will see the feature
export const abTest = {
  [NEW_FORK]: 0,
  [GOAL_SETTER_LENDERS]: 0,
  [LOAN_AMOUNT]: 0,
};
