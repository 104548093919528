import _ from 'lodash';

export const MIME_GROUPS = {
  document: {
    icon: 'sl-custom-document-1',
    value: [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/vnd.oasis.opendocument.text',
    ],
    extensions: ['pdf', 'doc', 'docx'],
  },
  sheet: {
    icon: 'sl-custom-file-table',
    value: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.spreadsheet',
    ],
    extensions: ['xls', 'xlsx'],
  },
  present: {
    icon: 'sl-custom-file-thumbnail',
    value: [
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
    extensions: ['ppt', 'pptx'],
  },
  image: {
    icon: 'sl-custom-file-image-1',
    value: ['image/jpeg', 'image/png'],
    extensions: ['jpg', 'jpeg', 'png'],
  },
};

export const MIME_TYPES = _.flatMap(MIME_GROUPS, (g) => g.value);
export const MIME_EXTENSIONS = _.flatMap(MIME_GROUPS, (g) => g.extensions);
