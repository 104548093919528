import React from 'react';
import PropTypes from 'prop-types';

export const TOGGLE_TIME = 300;
export const SHOW_TIME = 100;
const ANIMATION_TIME = 500; // Same logic as AccordionSection.js read there plz

export default class Accordion extends React.Component {
  static propTypes = {
    bully: PropTypes.bool,
    expandByDefault: PropTypes.bool,
    hide: PropTypes.bool,
    children: PropTypes.func.isRequired,
  };

  static defaultProps = {
    bully: false,
    expandByDefault: false,
    hide: false,
  };

  static openedItem = null;

  static childContextTypes = {
    isCollapsed: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
  }

  componentDidUpdate(prevProps) {
    /* open accordion if expandByDefault change from false to true because data might come through later
      (when rendering report on ExpenseApply and reading bank connect collection in specific)
      but leave it open if expandByDefault is back to false
     */
    if (!prevProps.expandByDefault && this.props.expandByDefault) {
      this.toggle();
    }
  }

  getChildContext() {
    return { isCollapsed: this.state.isCollapsed };
  }

  componentDidMount() {
    if (this.contentEl) {
      this.contentEl.style.height = '0px';
      this.setOpacity(0);
      this.contentEl.style.display = 'none';
    }
    this.props.expandByDefault && this.toggle();
  }

  componentWillUnmount() {
    Accordion.openedItem = null;
    this.parentTimeout && clearTimeout(this.parentTimeout);
    this.childTimeout && clearTimeout(this.childTimeout);
    this.toggleTimeout && clearTimeout(this.toggleTimeout);
    this.showTimeout && clearTimeout(this.showTimeout);
  }

  setOpacity(val) {
    const { hide } = this.props;

    if (hide && this.contentEl) {
      this.contentEl.style.opacity = val;
    }
  }

  contentRef = (ref) => {
    this.contentEl = ref;
  };

  close() {
    if (!this.contentEl) {
      return;
    }

    this.contentEl.style.height = `${this.contentEl.scrollHeight}px`;
    this.setOpacity(1);
    this.parentTimeout = setTimeout(() => {
      if (!this.contentEl) {
        return;
      }

      this.contentEl.style.height = '0px';
      this.setOpacity(0);
      // Hide the element to avoid unncessary rendering and TAB event
      this.childTimeout = setTimeout(() => {
        if (!this.contentEl) {
          return;
        }
        this.contentEl.style.display = 'none';
      }, ANIMATION_TIME);
    }, 10);
    this.setState({
      isCollapsed: true,
    });
  }

  toggle = () => {
    if (!this.contentEl) {
      return;
    }

    const { isCollapsed } = this.state;
    const { bully, hide } = this.props;

    if (isCollapsed) {
      this.contentEl.style.display = 'block';
      this.contentEl.style.height = `${this.contentEl.scrollHeight}px`;
      this.setOpacity(0);
      this.contentEl.style.transition = `height ${TOGGLE_TIME}ms ease-in-out${
        hide ? `, opacity ${SHOW_TIME}ms ease-in-out` : ''
      }`;
      this.toggleTimeout = setTimeout(() => {
        if (!this.contentEl) {
          return;
        }
        this.contentEl.style.height = 'auto';
      }, TOGGLE_TIME);
      this.showTimeout = setTimeout(() => {
        this.setOpacity(1);
      }, SHOW_TIME);
      if (bully && Accordion.openedItem && Accordion.openedItem.content) {
        Accordion.openedItem.close();
      }
      Accordion.openedItem = this;
      this.setState({
        isCollapsed: false,
      });
    } else {
      this.close();
      Accordion.openedItem = null;
    }
  };

  render = () =>
    this.props.children({
      contentRef: this.contentRef,
      toggle: this.toggle,
      close: this.close,
      ...this.state,
      ...this.props,
    });
}
