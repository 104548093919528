export const PURCHASE_PRICE = 'Purchase price';
export const STAMP_DUTY_GOV_FEES = 'Stamp duty';
export const LEGAL_FEES = 'Legal fees';
export const MOVING_AND_CLEANING = 'Moving & cleaning';
export const RENOVATIONS = 'Renovations';
export const OTHERS = 'Other...';

export const LMI_ALLOWANCE = 'LMI allowance';
export const FHOG_TITLE = 'First home owners grant';

export const PURCHASE_COST_LIST = [
  PURCHASE_PRICE,
  STAMP_DUTY_GOV_FEES,
  LEGAL_FEES,
  MOVING_AND_CLEANING,
  RENOVATIONS,
];

// TODO: KEIGO when milli pushes funding, need to reorganise this logic
export const LOAN_AMOUNT_ITEM_LIST_WITH_ICONS = {
  [PURCHASE_PRICE]: 'sl-custom-dollar-currency-3',
  [STAMP_DUTY_GOV_FEES]: 'sl-custom-receipt-4',
  [LEGAL_FEES]: 'sl-custom-court',
  [MOVING_AND_CLEANING]: 'sl-custom-truck-1',
  [RENOVATIONS]: 'sl-custom-roller',
};

export const AU_UNIQUE_ITEM = [STAMP_DUTY_GOV_FEES, LMI_ALLOWANCE, FHOG_TITLE];
