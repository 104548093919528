import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import ApplySummary from 'components/ApplySummary/ApplySummary';
import ApplyHeader from 'components/ApplyHeader/ApplyHeader';
import ProgressBar from 'components/ProgressBar/ProgressBar';

import { smoothScroll, scrollLock } from 'lib/smoothScroll';
import { logEvent, EVENTS } from 'lib/amplitude';
import { accordionPropTypes } from 'types/customPropTypes';
import styles from './AccordionSection.css';

// This accordion section uses inline height to control animation and
// display: none to stop hidden element from rendering AFTER the animation has been done
const TOP_MARGIN = 10;
const TIMING = 500; // tightly tied to the $timing variable in the CSS file
const ANIMATION_TIME = 2000; // time to wait til all animation finishes for display: none

export default class AccordionSection extends Component {
  static propTypes = {
    ...accordionPropTypes,
    titleDescription: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    headerDescription: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    openSelf: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    openAccordion: PropTypes.string,
    slug: PropTypes.string.isRequired,
    children: PropTypes.node,
    isCompleted: PropTypes.bool,
    id: PropTypes.string,
    forceScroll: PropTypes.bool,
    showProgress: PropTypes.bool,
    progress: PropTypes.number,
    warningMessage: PropTypes.string,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.slug === props.openAccordion,
    };
    this._timeouts = [];
  }

  componentDidMount() {
    this.content.style.display = 'none';
    this.scrollToTheRightSpot({ openSection: {} });
  }

  componentWillUnmount() {
    this._timeouts.forEach(clearTimeout);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ openAccordion: nextOpenAccordion }) {
    const { slug } = this.props;
    const { isOpen } = this.state;
    const willBeOpen = !isOpen && slug === nextOpenAccordion;
    if (willBeOpen) {
      this.getReadyToShowContent();
      // isOpen is to rerender the element with 'open' classname with css transition
      // So we need to make sure we display the element first with height 0 for the transition to happen
      this._timeouts.push(
        setTimeout(() => {
          this.setState({ isOpen: willBeOpen });
        }, 0),
      );
    }
  }

  componentDidUpdate({
    openAccordion: prevOpenAccordion,
    forceScroll: prevForceScroll,
  }) {
    const { openAccordion, forceScroll } = this.props;
    if (
      openAccordion !== prevOpenAccordion ||
      (forceScroll && !prevForceScroll)
    ) {
      this.scrollToTheRightSpot(prevOpenAccordion, forceScroll ? 1 : undefined);
    }
  }

  scrollToTheRightSpot(prevOpenAccordion, userDefinedTiming) {
    const { openAccordion, slug } = this.props;
    const currentPosition = this.root.offsetTop - TOP_MARGIN;
    const timing =
      typeof userDefinedTiming === 'number' ? userDefinedTiming : TIMING;

    if (openAccordion === slug) {
      // scroll to and open
      // this.root.scrollIntoView() would be preferable, but has bad browser support for the "smooth" option unfortunately
      this.getReadyToShowContent();
      smoothScroll(currentPosition, timing);
      this._timeouts.push(
        setTimeout(() => {
          this.content.style.height = `${this.content.scrollHeight}px`;
          scrollLock(this.root, timing, -TOP_MARGIN);
        }, timing),
      );
      this._timeouts.push(
        setTimeout(() => {
          this.content.style.height = 'auto';
          this.root.style.overflow = 'visible';
        }, timing * 2),
      );
    } else if (openAccordion === undefined && prevOpenAccordion === slug) {
      // close
      const windowTop = window.pageYOffset;
      const timingForClosing =
        Math.abs(windowTop - currentPosition) > 20 ? timing : 0;
      smoothScroll(currentPosition, timingForClosing);
      const h = this.content.scrollHeight - this.content.offsetTop;
      this.content.style.height = `${h}px`;
      this._timeouts.push(
        setTimeout(() => {
          this.content.style.height = '0px';
          this.setState({ isOpen: false });
          this.delayAndHide();
        }, timingForClosing),
      );
    } else if (prevOpenAccordion === slug && openAccordion !== slug) {
      // close and open another
      this.setState({ isOpen: false });
      this.content.style.display = 'block';
      this.content.style.height = `${this.content.scrollHeight}px`;
      const timingForClosing = openAccordion ? timing : 0;
      this._timeouts.push(
        setTimeout(() => {
          this.content.style.height = '0px';
          this.delayAndHide();
        }, timingForClosing),
      );
    }
  }

  // Before we show the sliding down effect with a height, we need to make sure the element is 'displayed'
  getReadyToShowContent() {
    this.content.style.display = 'block';
    this.content.style.height = '0px';
    this.root.style.overflow = 'hidden';
  }

  // After we make height = 0 animation, we hide the element
  delayAndHide() {
    this._timeouts.push(
      setTimeout(() => {
        if (this.content.style.height === '0px') {
          this.content.style.display = 'none';
        }
      }, ANIMATION_TIME),
    );
  }

  onFocusOnForm = () => {
    const { slug } = this.props;
    logEvent(EVENTS.EDIT_INFORMATION, { section: slug });
  };

  onApplyHeaderClose = () => {
    const { close, slug } = this.props;
    logEvent(EVENTS.CLOSE_SECTION, { section: slug });
    close();
  };

  render() {
    const {
      openAccordion,
      openSelf,
      title,
      titleDescription,
      headerDescription,
      iconName,
      children,
      isCompleted,
      isLocked,
      id,
      isFreezed,
      showProgress,
      progress,
      warningMessage,
    } = this.props;
    const { isOpen } = this.state;
    const rootStyles = classNames(styles.root, {
      [styles.expandable]: !isFreezed,
      [styles.open]: isOpen,
      [styles.anyAccordionOpen]: openAccordion,
    });
    const containerStyles = _.partial(classNames, styles.container);
    return (
      <div
        id={id}
        ref={(c) => {
          this.root = c;
        }}
        className={rootStyles}
      >
        <ApplySummary
          id={id}
          isOpen={isOpen}
          onClick={isFreezed ? undefined : openSelf}
          title={title}
          titleDescription={titleDescription}
          iconName={iconName}
          isCompleted={isCompleted}
          isLocked={isLocked}
          warningMessage={warningMessage}
        />
        <div
          ref={(c) => {
            this.content = c;
          }}
          className={styles.contentWrapper}
        >
          <div className={containerStyles(styles.content)}>
            <ApplyHeader
              isOpen={isOpen}
              close={this.onApplyHeaderClose}
              iconName={iconName}
              title={title}
              description={headerDescription}
              isFreezed={isFreezed}
              warningMessage={warningMessage}
              isLocked={isLocked}
            />
            {showProgress && <ProgressBar percentage={progress} />}
            <div
              className={styles.childrenWrapper}
              onFocus={this.onFocusOnForm}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
