import {
  MANAGEMENT_FEE_PERCENTAGE,
  VACANCY_FEE_PERCENTAGE,
  ANNUAL_REPAIR_FEE,
} from 'shared/constants/defaults';

import { WEEKLY } from 'shared/constants/options';

import { convertFrequency } from 'shared/lib/numbrero';
import { pickNumeric } from 'lib/utils/numberUtils';
import { interestOnlyRepaymentCalculator } from 'shared/lib/repaymentCalculator';

export function cashFlowCalculator(property, loanAmount, lowestRateAPI) {
  const interestRate =
    property.investmentLowestRateManual ||
    lowestRateAPI ||
    (property.lowestRateProduct && property.lowestRateProduct.interestRate);
  const weeklyRentalAmount = Math.round(
    convertFrequency(property.rentalAmount, property.rentalFrequency, WEEKLY),
  );

  const managementFeePercentage =
    property.managementFeePercentage || MANAGEMENT_FEE_PERCENTAGE;
  const managementFee = Math.round(
    managementFeePercentage * weeklyRentalAmount,
  );

  const vacancyFeePercentage =
    property.vacancyFeePercentage || VACANCY_FEE_PERCENTAGE;
  const vacancyFee = Math.round(vacancyFeePercentage * weeklyRentalAmount);

  const yearlyRepairFee = pickNumeric(
    property.yearlyRepairFee,
    ANNUAL_REPAIR_FEE,
  );
  const weeklyRepairFee = Math.round(yearlyRepairFee / 52, 10);

  const repayment = Math.round(
    interestOnlyRepaymentCalculator(interestRate, loanAmount),
  );
  const rentalExpenseAmount =
    managementFee + vacancyFee + weeklyRepairFee + repayment;
  const totalCashFlow = weeklyRentalAmount - rentalExpenseAmount;

  return {
    investmentLowestRateManual: property.investmentLowestRateManual,
    lowestRateProduct: {
      interestRate:
        lowestRateAPI ||
        (property.lowestRateProduct && property.lowestRateProduct.interestRate),
    },
    managementFeePercentage,
    vacancyFeePercentage,
    weeklyRentalAmount,
    managementFee,
    vacancyFee,
    yearlyRepairFee,
    weeklyRepairFee,
    repayment,
    rentalExpenseAmount,
    totalCashFlow,
  };
}

export function investmentEquityCalculator(property) {
  return { investmentEquity: property.value - property.mortgageAmount };
}
