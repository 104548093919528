/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames/bind';
import { API_FIXED_RESULT } from 'shared/constants/interestType';

import { getFrequencyAndAmountLabels } from 'lib/lenderHelper';
import { formatCurrency, formatRate } from 'lib/intlFormatters';
import messages from 'constants/productSummaryFormatters';
import styles from './ProductSummary.css';

const renderFeature = (isAvailable, name) => {
  const iconStyle = isAvailable
    ? classNames(styles.iconCheck, 'mi-check')
    : classNames(styles.iconCross, 'mi-cross');
  return (
    <li className={styles.ability}>
      <i className={iconStyle} />
      {name}
    </li>
  );
};

class ProductSummary extends Component {
  static propTypes = {
    features: PropTypes.shape({
      offsetAccount: PropTypes.bool,
      creditCard: PropTypes.bool,
      redrawFacility: PropTypes.bool,
    }),
    repaymentType: PropTypes.string,
    lvr: PropTypes.objectOf(PropTypes.number),
    rates: PropTypes.objectOf(
      PropTypes.shape({
        period: PropTypes.number,
        rate: PropTypes.number,
      }),
    ),
    repaymentInitial: PropTypes.object,
    fees: PropTypes.objectOf(PropTypes.number),
    repaymentFrequency: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpenDescription: false,
    };
  }

  render = () => {
    const {
      features: { offsetAccount, creditCard, redrawFacility },
      lvr,
      fees: { totalUpfront, totalOngoing },
      rates: { initial, comparison },
      repaymentType,
      repaymentFrequency,
      repaymentInitial,
      intl,
      intl: { formatMessage },
    } = this.props;
    const blockStyles = classNames(styles.block, styles.feature);
    const repaymentStyle = classNames(styles.detailRate, styles.repaymentValue);
    const rateStyles = classNames(styles.block, styles.rates);
    const {
      frequency,
      amount,
      repaymentTypeLabel,
    } = getFrequencyAndAmountLabels(
      repaymentInitial,
      repaymentFrequency,
      repaymentType,
      intl,
    );
    return (
      <section className={styles.sectionContent}>
        <div className={blockStyles}>
          <ul className={styles.abilityList}>
            {renderFeature(
              offsetAccount,
              formatMessage(messages.offsetAccountText),
            )}
            {renderFeature(
              redrawFacility,
              formatMessage(messages.redrawAvailableText),
            )}
          </ul>
          <ul className={styles.abilityList}>
            {renderFeature(creditCard, formatMessage(messages.creditCardText))}
            {renderFeature(
              true,
              formatMessage(messages.borrowUpToText, {
                maximumLvr: lvr.maximum,
              }),
            )}
          </ul>
          <ul className={styles.abilityList}>
            {renderFeature(
              true,
              `${formatCurrency(intl)(totalOngoing)} ${formatMessage(
                messages.annualFeeText,
              )}`,
            )}
            {renderFeature(
              true,
              `${formatCurrency(intl)(totalUpfront)} ${formatMessage(
                messages.upfrontFeeText,
              )}`,
            )}
          </ul>
        </div>
        <div className={rateStyles}>
          <div className={styles.detailRate}>
            <div className={styles.percent}>
              {formatRate(intl)(initial.rate)}
            </div>
            <div className={styles.hint}>{formatMessage(messages.paText)}</div>
            <div className={styles.hint}>
              {API_FIXED_RESULT.includes(initial.type)
                ? formatMessage(messages.fixedText, { term: initial.periodYrs })
                : formatMessage(messages.variableText)}
            </div>
          </div>
          <div className={styles.detailRate}>
            <div className={styles.percent}>
              {comparison.rate && formatRate(intl)(comparison.rate)}
            </div>
            <div className={styles.hint}>{formatMessage(messages.paText)}</div>
            <div className={styles.hint}>
              {formatMessage(messages.paComparisionText)}
            </div>
          </div>
          <div className={repaymentStyle}>
            <div className={styles.currency}>
              {formatCurrency(intl)(amount)}
            </div>
            <div className={styles.hint}>
              {formatMessage(messages.perFrequency, { frequency })}
            </div>
            <div className={styles.hint}>{repaymentTypeLabel}</div>
          </div>
        </div>
      </section>
    );
  };
}

export default injectIntl(ProductSummary);
