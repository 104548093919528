import { ASSETS_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON } from './baseApi';

export const putAsset = (asset) =>
  axiosWithAuth.put(`${ASSETS_API_PATH}/${asset.id}`, asset).then(returnJSON);

export const putRealEstateAsset = (asset) =>
  axiosWithAuth
    .put(`${ASSETS_API_PATH}/real-estates/${asset.id}`, asset)
    .then(returnJSON);

export const delAsset = (assetId) =>
  axiosWithAuth.delete(`${ASSETS_API_PATH}/${assetId}`).then(returnJSON);

export const postRealEstateLiabilityForAsset = (liability) =>
  axiosWithAuth
    .post(
      `${ASSETS_API_PATH}/${liability.assetId}/real-estate-liabilities`,
      liability,
    )
    .then(returnJSON);

export const postRealEstateIncomeForAsset = (income) =>
  axiosWithAuth
    .post(`${ASSETS_API_PATH}/${income.assetId}/real-estate-incomes`, income)
    .then(returnJSON);
