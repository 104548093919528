import { createSelector } from 'reselect';
import _ from 'lodash';
import { LOAN_AMOUNT_ITEM_LIST_WITH_ICONS } from 'constants/reportItemTitles';
import {
  FUNDING_TYPE_REQUIRED,
  FUNDING_TYPE_AVAILABLE,
} from 'shared/constants/myCRMTypes/funding';

import * as propertySelectors from 'selectors/propertySelectors';
import { getIsLocked } from 'selectors/applicationSelectors';

import { findOption } from 'lib/optionHelpers';

export const errors = (state) => state.funding.errors;
export const workingFundings = (state) => state.funding.working;
export const fundings = (state) => state.funding.entities;

export const entity = createSelector(fundings, (a) =>
  _.memoize((id) => a.find((e) => e.id === id)),
);

export const fundingsByPropertyId = createSelector(fundings, (a) =>
  _.memoize((propertyId) => a.filter((e) => e.propertyId === propertyId)),
);

export const working = (state) => (id) =>
  workingFundings(state)[id] || entity(state)(id);

export const fundingAvailable = createSelector(fundings, (fundingEntities) =>
  _.filter(fundingEntities, (f) => f.type === FUNDING_TYPE_AVAILABLE),
);

export const fundsRequired = createSelector(fundings, (fundingEntities) =>
  _.filter(fundingEntities, (f) => f.type === FUNDING_TYPE_REQUIRED),
);

export const fundsRequiredSum = createSelector(fundsRequired, (funds) =>
  _.sumBy(funds, 'value'),
);

export const fundsAvailableSum = createSelector(fundingAvailable, (funds) =>
  _.sumBy(funds, 'value'),
);

export const totalLoanRequired = createSelector(
  fundsRequiredSum,
  fundsAvailableSum,
  (a, b) => Math.max(Math.floor(a - b), 0), // TODO why floor????
);

const fundsOther = (type) => ({
  name: 'Add another',
  ignore: true,
  url: `/funding/new?type=${type}`,
  icon: 'sl-custom-list-2-1',
});

const mappedItems = (funds, propertyOptions) =>
  funds.map((f) => {
    let propertyDescription;
    if (f.propertyId) {
      const propertyOption = findOption(propertyOptions, f.propertyId);
      propertyDescription =
        propertyOption &&
        propertyOption.label; /* check if option is there because deleting property does not delete associated fundings in myCrm now. should be fixed in the future. */
    }
    const icon = LOAN_AMOUNT_ITEM_LIST_WITH_ICONS[f.value];
    return {
      name: f.name,
      icon: icon || 'sl-custom-dollar-bag',
      url: `/funding/${f.id}`,
      value: f.value,
      isOpenByDefault: true,
      nameInfoLabel: propertyDescription,
    };
  });

export const reportMetadata = createSelector(
  fundsRequired,
  fundingAvailable,
  totalLoanRequired,
  propertySelectors.propertyOptionsForFunding,
  getIsLocked,
  (requiredFunds, availableFunding, totalLoan, propertyOptions, isLocked) => {
    const summary = {
      label: 'Total loan required',
      value: totalLoan,
    };
    const metadata = [summary];

    const availableItems = mappedItems(availableFunding);
    if (!isLocked) {
      availableItems.push(fundsOther(FUNDING_TYPE_AVAILABLE));
    }
    const availableFundingData = {
      label: 'Available funding',
      items: availableItems,
    };
    metadata.push(availableFundingData);

    // eslint-disable-next-line unicorn/explicit-length-check
    if (propertyOptions.length) {
      const requiredItems = mappedItems(requiredFunds, propertyOptions);
      if (!isLocked) {
        requiredItems.push(fundsOther(FUNDING_TYPE_REQUIRED));
      }
      const requiredFundsData = {
        label: 'Required funds',
        items: requiredItems,
        isChartTotal: true,
      };
      metadata.push(requiredFundsData);
    }

    return metadata;
  },
);
