import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import { GREATER_THAN_ZERO, REQUIRED } from 'constants/validators';
import * as myCRMLiability from 'shared/constants/myCRMTypes/liabilities';

import {
  MYCRM_FREQUENCY_OPTIONS,
  liabilityCategoryOptions,
  liabilityTypeOptions,
} from 'constants/options';
import { WEEKLY } from 'shared/constants/options';
import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Input from 'components/Input/Input';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import NSelection from 'components/NSelection/NSelection';
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput';
import Questions from 'lib/Questions';
import { makeArrayFromRange } from 'lib/utils/dataUtils';
import { findCreditor } from 'lib/lenderHelper';
import locale from 'config/locale';

const messages = defineMessages({
  ownership: {
    id: 'LiabilityForm.ownership',
    defaultMessage: 'Ownership',
  },
  creditor: {
    id: 'LiabilityForm.creditor',
    defaultMessage: 'Creditor',
  },
  category: {
    id: 'LiabilityForm.category',
    defaultMessage: 'Liability type',
  },
  type: {
    id: 'LiabilityForm.typeSelect',
    defaultMessage: '{category} type',
  },
  description: {
    id: 'LiabilityForm.description',
    defaultMessage: 'Description (optional)',
  },
  totalCardLimit: {
    id: 'LiabilityForm.totalCardLimit',
    defaultMessage: 'Total card limit',
  },
  originalLoanAmount: {
    id: 'LiabilityForm.originalLoanAmount',
    defaultMessage: 'Loan limit',
  },
  repayments: {
    id: 'LiabilityForm.repayments',
    defaultMessage: 'Repayments',
  },
  totalLoanAmount: {
    id: 'LiabilityForm.totalLoanAmount',
    defaultMessage: 'Current balance owing / outstanding',
  },
  years: {
    id: 'LiabilityForm.years',
    defaultMessage: 'Years',
  },
  months: {
    id: 'LiabilityForm.months',
    defaultMessage: 'Months',
  },
  year: {
    id: 'LiabilityForm.year',
    defaultMessage: '{value, plural, =1 {# year} other {# years}}',
  },
  month: {
    id: 'LiabilityForm.month',
    defaultMessage: '{value, plural, =1 {# month} other {# months}}',
  },
  loanType: {
    id: 'LiabilityForm.loanType',
    defaultMessage: 'Liability loan type',
  },
  remainingLoanTerm: {
    id: 'LiabilityForm.remainingLoanTerm',
    defaultMessage: 'Remaining loan term',
  },
  save: {
    id: 'LiabilityForm.save',
    defaultMessage: 'Save',
  },
  saveAndAddAnother: {
    id: 'LiabilityForm.saveAndAddAnother',
    defaultMessage: 'Save and add another',
  },
  remove: {
    id: 'LiabilityForm.remove',
    defaultMessage: 'Remove',
  },
  confirm: {
    id: 'LiabilityForm.confirm',
    defaultMessage: 'Confirm',
  },
});

const questionSet = (liability) => {
  const questions = new Questions();
  const { category, type, clientIdOwnershipOptions } = liability;
  questions.addBranch(['category', REQUIRED]);
  const hasNoType = myCRMLiability.theOnlyType[category];
  if (!hasNoType) {
    questions.addBranch(['type', REQUIRED]);
  }
  if (category && (type || hasNoType)) {
    if (clientIdOwnershipOptions.length > 1) {
      questions.addBranch(['clientIds', REQUIRED]);
    }

    questions.add('creditor');

    const categoryIsCard =
      category === myCRMLiability.LIABILITY_CARD_CATEGORY.id;
    const totalLoanAmountRules = categoryIsCard ? [] : [GREATER_THAN_ZERO];

    questions.addBranch(['totalLoanAmount', ...totalLoanAmountRules, REQUIRED]);

    if (categoryIsCard) {
      questions.add(['totalCardLimit', REQUIRED]);
    } else {
      questions.add(['originalLoanAmount', REQUIRED]);
    }

    questions.add(
      'repayments',
      'description',
      'saveButton',
      'saveAndAddAnotherButton',
    );
  }

  return questions.arrayOfQuestions();
};

export class DisconnectedLiabilityForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onlyEditRepayments: PropTypes.bool,
    working: PropTypes.object.isRequired,
    creditors: PropTypes.arrayOf(PropTypes.object),
    clientIdOwnershipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func,
    saveAndAddAnother: PropTypes.func,
    setLiabilityClientIds: PropTypes.func.isRequired,
    setLiabilityType: PropTypes.func.isRequired,
    setLiabilityCategory: PropTypes.func.isRequired,
    setLiabilityCreditor: PropTypes.func.isRequired,
    setLiabilityDescription: PropTypes.func.isRequired,
    setLiabilityTotalCardLimit: PropTypes.func.isRequired,
    setLiabilityOriginalLoanAmount: PropTypes.func.isRequired,
    setLiabilityRepaymentAmount: PropTypes.func.isRequired,
    setLiabilityRepaymentFrequency: PropTypes.func.isRequired,
    setLiabilityRemainingYears: PropTypes.func.isRequired,
    setLiabilityRemainingMonths: PropTypes.func.isRequired,
    setLiabilityTotalLoanAmount: PropTypes.func.isRequired,
    setLiabilityError: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static defaultProps = {
    onlyEditRepayments: false,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  componentDidMount() {
    const {
      working,
      setLiabilityClientIds,
      clientIdOwnershipOptions,
    } = this.props;
    if (!working.id && clientIdOwnershipOptions.length === 1) {
      setLiabilityClientIds(clientIdOwnershipOptions[0].value);
    }
  }

  onSubmit = () => {
    const { save, working } = this.props;
    save(working);
  };

  makeTime(min, max, msg) {
    return makeArrayFromRange(min, max).map((m) => ({
      ...m,
      name: this.props.intl.formatMessage(msg, { value: m.value }),
    }));
  }

  renderLiabilityType = (categoryOptions) => {
    const {
      working: { category, type, assetId },
      questions,
      intl,
      setLiabilityType,
      onlyEditRepayments,
    } = this.props;
    // This additional logic has to be repeated here because of the unhandled intl.
    if (category && !myCRMLiability.theOnlyType[category]) {
      return (
        <Question
          {...questions.type}
          label={intl.formatMessage(messages.type, {
            category: categoryOptions.find((c) => c.value === category).label,
          })}
          className='fullWidthInput'
        >
          {type ? (
            <Selection
              action={setLiabilityType}
              value={type}
              items={liabilityTypeOptions(category)}
              disabled={!!assetId || onlyEditRepayments}
            />
          ) : (
            <RadioButtonList
              action={setLiabilityType}
              value={type}
              items={liabilityTypeOptions(category)}
            />
          )}
        </Question>
      );
    }
    return null;
  };

  render() {
    const {
      isLocked,
      working,
      creditors,
      clientIdOwnershipOptions,
      questions,
      saveAndAddAnother,
      remove,
      formCompleted,
      setCurrentQuestionTo,
      intl,
      setLiabilityClientIds,
      setLiabilityRepaymentFrequency,
      setLiabilityRepaymentAmount,
      setLiabilityDescription,
      setLiabilityRemainingMonths,
      setLiabilityCategory,
      setLiabilityCreditor,
      setLiabilityTotalLoanAmount,
      setLiabilityTotalCardLimit,
      setLiabilityOriginalLoanAmount,
      setLiabilityRemainingYears,
      onlyEditRepayments,
    } = this.props;

    const {
      clientIds,
      repaymentAmount,
      repaymentFrequency,
      remainingYears,
      remainingMonths,
      totalCardLimit,
      originalLoanAmount,
      category,
      description,
      totalLoanAmount,
      assetId,
      isOldData,
      creditorName,
    } = working;

    return (
      <Form
        id='liabilityForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          <Question
            {...questions.category}
            label={intl.formatMessage(messages.category)}
            className='fullWidthInput'
          >
            {!category ? (
              <RadioButtonList
                action={setLiabilityCategory}
                value={category}
                items={liabilityCategoryOptions(locale.isAU)}
              />
            ) : (
              <Selection
                action={setLiabilityCategory}
                value={category}
                items={liabilityCategoryOptions(locale.isAU)}
                disabled={!!assetId || onlyEditRepayments}
              />
            )}
          </Question>

          {this.renderLiabilityType(liabilityCategoryOptions(locale.isAU))}

          <Question
            {...questions.clientIds}
            label={intl.formatMessage(messages.ownership)}
            className='fullWidthInput'
          >
            {!clientIds || clientIds.length === 0 ? (
              <RadioButtonList
                action={setLiabilityClientIds}
                value={clientIds && clientIds.join()}
                items={clientIdOwnershipOptions}
              />
            ) : (
              <Selection
                action={setLiabilityClientIds}
                value={clientIds && clientIds.join()}
                items={clientIdOwnershipOptions}
                disabled={!!assetId || onlyEditRepayments}
              />
            )}
          </Question>

          <Question
            {...questions.creditor}
            label={intl.formatMessage(messages.creditor)}
            className='fullWidthInput'
          >
            <AutocompleteInput
              action={setLiabilityCreditor}
              value={creditorName}
              serviceFn={findCreditor(creditors)}
            />
          </Question>

          <Question
            {...questions.totalLoanAmount}
            label={intl.formatMessage(messages.totalLoanAmount)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setLiabilityTotalLoanAmount}
              value={totalLoanAmount}
              disabled={onlyEditRepayments}
            />
          </Question>

          <Question
            {...questions.totalCardLimit}
            label={intl.formatMessage(messages.totalCardLimit)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setLiabilityTotalCardLimit}
              value={totalCardLimit}
            />
          </Question>
          <Question
            {...questions.originalLoanAmount}
            label={intl.formatMessage(messages.originalLoanAmount)}
            className='fullWidthInput'
          >
            <CurrencyInput
              action={setLiabilityOriginalLoanAmount}
              value={originalLoanAmount}
            />
          </Question>
          <Question
            {...questions.remainingLoanTerm}
            label={intl.formatMessage(messages.remainingLoanTerm)}
            className='fullWidthInput'
          >
            <NSelection
              selections={[
                {
                  action: setLiabilityRemainingYears,
                  value: remainingYears,
                  items: this.makeTime(0, 30, messages.year),
                  defaultMessage: messages.years,
                },
                {
                  action: setLiabilityRemainingMonths,
                  value: remainingMonths,
                  items: this.makeTime(0, 12, messages.month),
                  defaultMessage: messages.months,
                },
              ]}
              nonTabbable={false}
            />
          </Question>

          <Question
            {...questions.repayments}
            label={intl.formatMessage(messages.repayments)}
            className='fullWidthInput'
          >
            <CurrencyInputWithSelection
              action={setLiabilityRepaymentAmount}
              value={repaymentAmount}
              selectionDefault={WEEKLY}
              selectionValue={repaymentFrequency}
              selectionAction={setLiabilityRepaymentFrequency}
              selectionItems={MYCRM_FREQUENCY_OPTIONS(intl.formatMessage)}
            />
          </Question>
          <Question
            {...questions.description}
            label={intl.formatMessage(messages.description)}
            className='fullWidthInput'
          >
            <Input
              action={setLiabilityDescription}
              value={description}
              disabled={onlyEditRepayments}
            />
          </Question>

          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted}
              onClick={this.onSubmit}
            >
              {intl.formatMessage(isOldData ? messages.confirm : messages.save)}
            </Button>
          </Question>
          {working.id ? (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          ) : (
            <Question
              {...questions.saveAndAddAnotherButton}
              className='fullWidthButton'
            >
              <Button
                theme='linkButton'
                hasErrorMessage={!formCompleted}
                onClick={() => saveAndAddAnother(working)}
              >
                {intl.formatMessage(messages.saveAndAddAnother)}
              </Button>
            </Question>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

export default injectIntl(
  manageQuestions(DisconnectedLiabilityForm, (props) => ({
    ...props.working,
    clientIdOwnershipOptions: props.clientIdOwnershipOptions,
  })),
);
