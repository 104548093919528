import { defineMessages } from 'react-intl';

const messages = defineMessages({
  perAnnum: {
    id: 'ProductDetail.perAnnum',
    defaultMessage: 'PER ANNUM',
  },

  interestOnlyPeriodTitle: {
    id: 'ProductDetail.interestOnlyPeriodTitle',
    defaultMessage: 'Interest only period',
  },
  interestOnlyPeriodShortDesc: {
    id: 'ProductDetail.interestOnlyPeriodShortDesc',
    defaultMessage: `You’ve indicated your preference is to pay interest only {selectedPeriodYrs, plural, =0 {for repayments} other {for {selectedPeriodYrs, number} {selectedPeriodYrs, plural, one {year} other {years}}}}. The maximum interest-only period on this loan is {periodYrs, number} {periodYrs, plural, one {year} other {years}}. Interest-only loans offer a lower-cost way to service a loan - but they come with a warning, you’re not repaying`,
  },
  interestOnlyPeriod: {
    id: 'ProductDetail.interestOnlyPeriod',
    defaultMessage: `You’ve indicated your preference is to pay interest only {selectedPeriodYrs, plural, =0 {for repayments} other {for {selectedPeriodYrs, number} {selectedPeriodYrs, plural, one {year} other {years}}}}. The maximum interest-only period on this loan is {periodYrs, number} {periodYrs, plural, one {year} other {years}}. Interest-only loans offer a lower-cost way to service a loan - but they come with a warning, you’re not repaying the loan back during this period.
    <br /><br /> The loan automatically switches over to principal + interest repayments at the end of the interest-only period.
    <br /><br /> For the calculation of your personalised comparison rate we are assuming the interest-only period is 5 years.`,
  },
  isNotInterestOnlyShortDesc: {
    id: 'ProductDetail.isNotInterestOnlyShortDesc',
    defaultMessage: 'This loan does not accommodate Interest Only.',
  },

  isNotInterestOnly: {
    id: 'ProductDetail.isNotInterestOnly',
    defaultMessage: `This loan does not accommodate Interest Only.
    <br /><br /> For the calculation of your personalised comparison rate we are assuming the interest-only period is 5 years.`,
  },
  initialRateFixedForTermTitle: {
    id: 'ProductDetail.initialRateFixedForTermTitle',
    defaultMessage:
      'Initial Rate - Fixed for {periodYrs, number} {periodYrs, plural, one {year} other {years}}',
  },

  initialRateFixedForTermShortDesc: {
    id: 'ProductDetail.initialRateFixedForTermShortDesc',
    defaultMessage: `{rate} per annum is today’s rate for the {productName}, meaning the interest rate is “locked in” and guaranteed not to change for the first {periodYrs, number} {periodYrs, plural, one {year} other {years}} of the loan`,
  },

  initialRateFixedForTerm: {
    id: 'ProductDetail.initialRateFixedForTerm',
    defaultMessage: `{rate} per annum is today’s rate for the {productName}, meaning the interest rate is “locked in” and guaranteed not to change for the first {periodYrs, number} {periodYrs, plural, one {year} other {years}} of the loan.
                    <br /><br />Fixed interest rates are good for people who want certainty about their repayments. Fixed loans allow you to budget with confidence around a set repayment amount.
                    <br /><br />Repayment figures are calculated to take into account the interest rate, loan amount and repayment term. To get a complete indication of the total repayment costs you need to factor into account the upfront application fee of {applicationFee}, ongoing monthly fee of {ongoingMonthlyFee} and an annual fee of {annualFee}.
                    <br /><br />Repayment calculations do not include:
                    <ul>
                    <li>Cost savings such as fee waivers or special rate discounts (which we may be able to negotiate on your behalf - our size and buying power puts us in the position to uncover hidden savings or discounts with the lender).</li>
                    <li>The use of smart account features such as early repayments and offset accounts, which vary widely among loans and can reduce the cost of a loan significantly - we look to save thousands in interest payments by finding the right loan and repayment structure for you.</li>
                    <li>Fees and charges associated with loan options or events that may or may not be used by the borrower, such as early repayment or redraw fees.</li>
                    <li>Lenders Mortgage Insurance (LMI) which may be required to secure the loan and can increase the cost of the loan.</li>
                    </ul>`,
  },

  todayVariableRateTitle: {
    id: 'ProductDetail.todayVariableRateTitle',
    defaultMessage: `Todays’ Variable Rate`,
  },

  todayVariableRateShortDesc: {
    id: 'ProductDetail.todayVariableRateShortDesc',
    defaultMessage: `{rate} per annum is today’s current rate for the {productName}. It’s important to understand that variables rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA)`,
  },

  todayVariableRate: {
    id: 'ProductDetail.todayVariableRate',
    defaultMessage: `{rate} per annum is today’s current rate for the {productName}. It’s important to understand that variables rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA).
                      <br /><br />Repayment figures are calculated to take into account the interest rate, loan amount and repayment term. To get a complete indication of the total repayment costs you need to factor into account the upfront application fee of {applicationFee}, ongoing monthly fee of {ongoingMonthlyFee} and an annual fee of {annualFee}.
                      <br /><br />Our repayment calculations do not include:
                      <ul>
                        <li>Cost savings such as fee waivers or special rate discounts (which we may be able to negotiate on your behalf - our size and buying power puts us in the position to uncover hidden savings or discounts with the lender).</li>
                        <li>The use of smart account features such as early repayments and offset accounts, which vary widely among loans and can reduce the cost of a loan significantly - we look to save thousands in interest payments by finding the right loan and repayment structure for you.</li>
                        <li>Fees and charges associated with loan options or events that may or may not be used by the borrower, such as early repayment or redraw fees.</li>
                        <li>Lenders Mortgage Insurance (LMI) which may be required to secure the loan and can increase the cost of the loan.</li>
                      </ul>`,
  },
  introVariableRateTermTitle: {
    id: 'ProductDetail.introVariableRateTermTitle',
    defaultMessage: `Intro Variable Rate - {periodYrs, number} {periodYrs, plural, =1 {year} other {years}}`,
  },

  introVariableRateTermShortDesc: {
    id: 'ProductDetail.introVariableRateTermShortDesc',
    defaultMessage: `{rate} per annum is today’s special variable intro rate for the {productName}. This intro rate last for {periodYrs} {periodYrs, select, one {year} other {years}}`,
  },

  introVariableRateTerm: {
    id: 'ProductDetail.introVariableRateTerm',
    defaultMessage: `{rate} per annum is today’s special variable intro rate for the {productName}. This intro rate last for {periodYrs} {periodYrs, select, one {year} other {years}}.
                    <br /><br />It’s important to understand that variables rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA).
                    <br /><br />Repayment figures are calculated to take into account the interest rate, loan amount and repayment term. To get a complete indication of the total repayment costs you need to factor into account the upfront application fee of {applicationFee}, ongoing monthly fee of {ongoingMonthlyFee} and an annual fee of {annualFee}.
                    <br /><br />Our repayment calculations do not include:
                    <ul>
                      <li>Cost savings such as fee waivers or special rate discounts (which we may be able to negotiate on your behalf - our size and buying power puts us in the position to uncover hidden savings or discounts with the lender).</li>
                      <li>The use of smart account features such as early repayments and offset accounts, which vary widely among loans and can reduce the cost of a loan significantly - we look to save thousands in interest payments by finding the right loan and repayment structure for you.</li>
                      <li>Fees and charges associated with loan options or events that may or may not be used by the borrower, such as early repayment or redraw fees.</li>
                      <li>Lenders Mortgage Insurance (LMI) which may be required to secure the loan and can increase the cost of the loan.</li>
                    </ul>`,
  },
  yourComparisonRateTitle: {
    id: 'ProductDetail.yourComparisonRateTitle',
    defaultMessage: 'Your Comparison Rate',
  },

  yourComparisonRateShortDesc: {
    id: 'ProductDetail.yourComparisonRateShortDesc',
    defaultMessage: `Your comparison rate of {rate} is a single “all costs included” interest rate, which helps you identify the true cost of a loan. It factors in initial and ongoing interest rates, your loan amount, repayment term and standard ongoing account fees and charges.`,
  },

  yourComparisonRate: {
    id: 'ProductDetail.yourComparisonRate',
    defaultMessage: `Your comparison rate of {rate} is a single “all costs included” interest rate, which helps you identify the true cost of a loan. It factors in initial and ongoing interest rates, your loan amount, repayment term and standard ongoing account fees and charges. This single figure is a great took for compare various loans from different lenders.`,
  },

  todayStandardVariableRateTitle: {
    id: 'ProductDetail.todayStandardVariableRateTitle',
    defaultMessage: `Today’s Standard Variable Rate`,
  },

  todayStandardVariableRateShortDesc: {
    id: 'ProductDetail.todayStandardVariableRateShortDesc',
    defaultMessage: `{rate} per annum is today’s current rate for the {productName}. It’s important to understand that variables rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA)`,
  },

  todayStandardVariableRate: {
    id: 'ProductDetail.todayStandardVariableRate',
    defaultMessage: `{rate} per annum is today’s current rate for the {productName}. It’s important to understand that variables rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA).
                    <br /><br />Repayment figures are calculated to take into account the interest rate, loan amount and repayment term. To get a complete indication of the total repayment costs you need to factor into account the upfront application fee of {applicationFee}, ongoing monthly fee of {ongoingMonthlyFee} and an annual fee of {annualFee}.
                    <br /><br />Our repayment calculations do not include:
                    <ul>
                      <li>Cost savings such as fee waivers or special rate discounts (which we may be able to negotiate on your behalf - our size and buying power puts us in the position to uncover hidden savings or discounts with the lender).</li>
                      <li>The use of smart account features such as early repayments and offset accounts, which vary widely among loans and can reduce the cost of a loan significantly - we look to save thousands in interest payments by finding the right loan and repayment structure for you.</li>
                      <li>Fees and charges associated with loan options or events that may or may not be used by the borrower, such as early repayment or redraw fees.</li>
                      <li>Lenders Mortgage Insurance (LMI) which may be required to secure the loan and can increase the cost of the loan.</li>
                    </ul>`,
  },

  ongoingRateVariableTitle: {
    id: 'ProductDetail.ongoingRateVariableTitle',
    defaultMessage: `Ongoing Rate - Variable`,
  },

  ongoingRateVariableShortDesc: {
    id: 'ProductDetail.ongoingRateVariableShortDesc',
    defaultMessage: `After the fixed period has ended this loan automatically switches over to a variable rate - today’s rate is {rate}. It’s important to understand that variable rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA)`,
  },

  ongoingRateVariable: {
    id: 'ProductDetail.ongoingRateVariable',
    defaultMessage: `After the fixed period has ended this loan automatically switches over to a variable rate - today’s rate is {rate}. It’s important to understand that variable rates will move up and down over time, loosely in line with the official cash rates set by the Reserve Bank of Australia (RBA).
                    <br /><br />Many people consider refinancing at the end of the fixed period in order to find the best deals available at that time, or to take advantage of any increase in equity you have built in your property.
                    <br /><br />One of the advantages of working with Loan Market is always help you review your situation at the end of the fixed period, so no need to remember when it’s up - we’ll remind you and help you consider you options then.`,
  },

  fixedPeriodYear: {
    id: 'ProductDetail.fixedPeriodYear',
    defaultMessage: `{periodYrs, number} {periodYrs, plural, one {year} other {years} }`,
  },

  periodYear: {
    id: 'ProductDetail.periodYear',
    defaultMessage: `{type} {initPeriodYrs, number} {initPeriodYrs, plural, one {year} other {years} }`,
  },
  monthlyRepaymentLabel: {
    id: 'ProductDetail.monthlyRepayment.label',
    defaultMessage: 'MONTHLY REPAYMENT*',
  },
  ratePerAnnumLabel: {
    id: 'ProductDetail.ratePerAnnumLabel',
    defaultMessage: 'RATE PER ANNUM',
  },
  perFrequency: {
    id: 'ProductDetail.perFrequency',
    defaultMessage: 'Per {frequency}*',
  },
  lenderNameTitle: {
    id: 'ProductDetail.lenderName.title',
    defaultMessage: 'About {lenderName}',
  },
  loanToValueRatioTitle: {
    id: 'ProductDetail.loanToValueRatio.title',
    defaultMessage: 'Loan to Value Ratio',
  },
  loanToValueRatioDescription: {
    id: 'ProductDetail.loanToValueRatio.description',
    defaultMessage: `Borrow up to the maximum of {maximumLvr} of the value of the property.

      Lenders Mortgage Insurance, or LMI, will be payable on most loans where the borrowings are greater than 80% of the value of the property.

      However, you may be able to capitalise LMI into your loan, increasing the overall loan amount by about 2% (in order to cover the cost of the insurance). Contact a loan market broker to find out how LVR and LMI might impact the cost of your loan repayments.`,
  },
  feeNotesTitle: {
    id: 'ProductDetail.feeNotes.title',
    defaultMessage: 'Fee Notes',
  },
  notesTitle: {
    id: 'ProductDetail.notes.title',
    defaultMessage: 'Notes',
  },
  lvrNotesTitle: {
    id: 'ProductDetail.lvrNotes.title',
    defaultMessage: 'LVR Notes',
  },
  totalLabel: {
    id: 'ProductDetail.total.label',
    defaultMessage: 'TOTAL',
  },
  annualFeeLabel: {
    id: 'ProductDetail.annualFee.label',
    defaultMessage: 'ONGOING YEARLY',
  },
  monthlyFeeLabel: {
    id: 'ProductDetail.monthlyFee.label',
    defaultMessage: 'ONGOING MONTHLY',
  },
  exitLabel: {
    id: 'ProductDetail.exit.label',
    defaultMessage: 'EXIT',
  },
  earlyRepayment: {
    id: 'ProductDetail.earlyRepayment.label',
    defaultMessage: 'EARLY REPAYMENT',
  },
  switchToFixedLabel: {
    id: 'ProductDetail.switchToFixed.label',
    defaultMessage: 'SWITCH TO FIXED',
  },
  feesAssociatedDescription: {
    id: 'ProductDetail.feesAssociated.description',
    defaultMessage: 'Fees associated with prematurely ending the loan.',
  },
  compareRatesSectionDesc: {
    id: 'ProductDetail.compareRatesSectionDesc',
    defaultMessage: `Loan switches to variable at the end of the fixed period.

      Repayments calculated to take into account ongoing monthly and annual fees.`,
  },
  interestRateTypeOngoingDesc: {
    id: 'ProductDetail.interestRateTypeOngoingDesc',
    defaultMessage:
      'Repayments calculated to take into account ongoing monthly and annual fees.',
  },
  exitFeesTitle: {
    id: 'ProductDetail.exitFees.title',
    defaultMessage: 'Exit Fees',
  },
  ongoingFeesTitle: {
    id: 'ProductDetail.ongoingFees.title',
    defaultMessage: 'Ongoing Fees',
  },
  monthlyAndAnnualFeesDescription: {
    id: 'ProductDetail.monthlyAndAnnualFees.description',
    defaultMessage:
      'Monthly and annual fees and charges during the loan period.',
  },
  applicationLabel: {
    id: 'ProductDetail.application.label',
    defaultMessage: 'APPLICATION',
  },
  settlementLabel: {
    id: 'ProductDetail.settlement.label',
    defaultMessage: 'SETTLEMENT',
  },
  legalLabel: {
    id: 'ProductDetail.legal.label',
    defaultMessage: 'LEGAL',
  },
  valuationLabel: {
    id: 'ProductDetail.valuation.label',
    defaultMessage: 'VALUATION',
  },
  establishmentLabel: {
    id: 'ProductDetail.establishment.label',
    defaultMessage: 'ESTABLISHMENT',
  },
  upfrontFeesTitle: {
    id: 'ProductDetail.upfrontFees.title',
    defaultMessage: 'Upfront fees',
  },
  upfrontFeesAssociatedDescription: {
    id: 'ProductDetail.upfrontFeesAssociated.description',
    defaultMessage: `Upfront fees associated with estabilishing the loan.`,
  },
  repaymentsTitle: {
    id: 'ProductDetail.repayments.title',
    defaultMessage: 'Repayments',
  },
  maxInterestOnlyLabel: {
    id: 'ProductDetail.maxInterestOnly.label',
    defaultMessage: 'MAX INTEREST ONLY',
  },
  maxTermInterestOnlyText: {
    id: 'ProductDetail.maxTermInterestOnly.text',
    defaultMessage:
      '{maxTermInterestOnly, number} {maxTermInterestOnly, select, 1 {year} other {years}}',
  },
  interestOnlyOptionLabel: {
    id: 'ProductDetail.interestOnlyOption.label',
    defaultMessage: 'INTEREST ONLY',
  },
  repaymentsDescription: {
    id: 'ProductDetail.repaymentsDescription.description',
    defaultMessage: 'You can make your repayments {description}',
  },
  redrawFeeLabel: {
    id: 'ProductDetail.redrawFee.label',
    defaultMessage: 'REDRAW FEE',
  },
  minimumRedrawLabel: {
    id: 'ProductDetail.minimumRedraw.label',
    defaultMessage: 'MINIMUM REDRAW',
  },
  freeTransactionsLabel: {
    id: 'ProductDetail.freeTransactions.label',
    defaultMessage: 'FREE TRANSACTIONS',
  },
  directSalaryCreditLabel: {
    id: 'ProductDetail.directSalaryCredit.label',
    defaultMessage: 'DIRECT SALARY CREDIT',
  },
  checkAccountLabel: {
    id: 'ProductDetail.checkAccount.label',
    defaultMessage: 'CHECK ACCOUNT',
  },
  professionalPackLabel: {
    id: 'ProductDetail.professionalPack.label',
    defaultMessage: 'PROFESSIONAL PACK',
  },
  portabilityOfLoanLabel: {
    id: 'ProductDetail.portabilityOfLoan.label',
    defaultMessage: 'PORTABILITY OF LOAN',
  },
  bpayOptionLabel: {
    id: 'ProductDetail.bpayOption.label',
    defaultMessage: 'BPAY OPTION',
  },
  telephoneBankingLabel: {
    id: 'ProductDetail.telephoneBanking.label',
    defaultMessage: 'TELEPHONE BANKING',
  },
  internetBankingLabel: {
    id: 'ProductDetail.internetBanking.label',
    defaultMessage: 'INTERNET BANKING',
  },
  creaditCardLabel: {
    id: 'ProductDetail.creaditCard.label',
    defaultMessage: 'CREDIT CARD',
  },
  redrawAvailableLabel: {
    id: 'ProductDetail.redrawAvailable.label',
    defaultMessage: 'REDRAW AVAILABLE',
  },
  offsetAccountLabel: {
    id: 'ProductDetail.offsetAccount.label',
    defaultMessage: 'OFFSET ACCOUNT',
  },
  bankingFeaturesTitle: {
    id: 'ProductDetail.bankingFeatures.title',
    defaultMessage: 'Banking Features',
  },
  offsetAccountTitle: {
    id: 'ProductDetail.offsetAccount.title',
    defaultMessage: 'Offset Account',
  },
  offsetAccountDescription: {
    id: 'ProductDetail.offsetAccount.description',
    defaultMessage:
      'Save on interest repayments by holding spare cash in an offset account.',
  },
  redrawFacilityDescription: {
    id: 'ProductDetail.redrawFacility.description',
    defaultMessage:
      'Get quick access to cash by drawing on the loan to pay for renovations or other purchases.',
  },
  redrawFacilityTitle: {
    id: 'ProductDetail.redrawFacility.title',
    defaultMessage: 'Redraw Facility',
  },
  minRedrawLabel: {
    id: 'ProductDetail.minRedraw.label',
    defaultMessage: 'MIN REDRAW',
  },
  redrawFacilityLabel: {
    id: 'ProductDetail.redrawFacility.label',
    defaultMessage: 'REDRAW FACILITY',
  },
  genuineSavingLabel: {
    id: 'ProductDetail.genuineSaving.label',
    defaultMessage: 'GENUINE SAVING',
  },
  creditHistoryLabel: {
    id: 'ProductDetail.creditHistory.label',
    defaultMessage: 'CREDIT HISTORY',
  },
  finalcialsLabel: {
    id: 'ProductDetail.finalcials.label',
    defaultMessage: 'DOCUMENTATION',
  },
  eligibilityConforming: {
    id: 'ProductDetail.eligibilityConforming',
    defaultMessage: 'Conforming',
  },
  eligibilityNonConforming: {
    id: 'ProductDetail.eligibilityNonConforming',
    defaultMessage: 'Non Conforming',
  },
  eligibilityRequired: {
    id: 'ProductDetail.eligibilityRequired',
    defaultMessage: 'Required',
  },
  eligibilityNotRequired: {
    id: 'ProductDetail.eligibilityNotRequired',
    defaultMessage: 'Not Required',
  },
  eligibilityTitle: {
    id: 'ProductDetail.eligibility.title',
    defaultMessage: 'Eligibility',
  },
  eligibilityDescription: {
    id: 'ProductDetail.eligibilityDescription.description',
    defaultMessage:
      'The level of financial documentaion and credit history required to apply for the loan.',
  },
  maxLabel: {
    id: 'ProductDetail.max.label',
    defaultMessage: 'MAX',
  },
  minLabel: {
    id: 'ProductDetail.min.label',
    defaultMessage: 'MIN',
  },
  termLabel: {
    id: 'ProductDetail.term.label',
    defaultMessage: 'TERM',
  },
  avalabilityTitle: {
    id: 'ProductDetail.avalabilityTitle.title',
    defaultMessage: 'Availability',
  },
  avalabilityDescription: {
    id: 'ProductDetail.avalabilityDescription.description',
    defaultMessage: 'For {loanPurposes} in {regions}',
  },
  aboutLabel: {
    id: 'ProductDetail.about.label',
    defaultMessage: 'About',
  },
  yearLabel: {
    id: 'ProductDetail.year.label',
    defaultMessage: 'year',
  },
  yearsLabel: {
    id: 'ProductDetail.years.label',
    defaultMessage: 'years',
  },
  yesLabel: {
    id: 'ProductDetail.yes.label',
    defaultMessage: 'Yes',
  },
  andLabel: {
    id: 'ProductDetail.and.label',
    defaultMessage: 'and',
  },
  noLabel: {
    id: 'ProductDetail.no.label',
    defaultMessage: 'No',
  },
  lvrLabel: {
    id: 'ProductDetail.lvr.label',
    defaultMessage: 'LVR',
  },
  capitaliseLMILabel: {
    id: 'ProductDetail.capitaliseLMILabel.label',
    defaultMessage: 'CAPITALISE LMI',
  },
  loanTermText: {
    id: 'ProductDetail.loanTermText.text',
    defaultMessage:
      '{minimumLoanTerm, number} - {maximumLoanTerm, number} years',
  },
  bankingFeaturesDescription: {
    id: 'ProductDetail.bankingFeaturesDescription',
    defaultMessage: `<div>
                        Given the wide range of loans on offer – with different interest rates, product features and fees – it pays to shop around to find the loan that fits your needs and circumstances. Some loans offer features that may be appropriate for your situation and result in savings over the life of the loan.
                        <div>Some features you may wish to consider include:</div>
                        <ul>
                          <li>an offset account</li>
                          <li>ability to make extra repayments</li>
                          <li>a redraw facility</li>
                          <li>linked credit card and savings accounts</li>
                          <li>ability to split your loan between fixed and variable interest rates</li>
                        </ul>
                    </div>`,
  },
  selectThisLoan: {
    id: 'ProductDetail.selectThisLoan.text',
    defaultMessage: 'Select this loan',
  },
  paComparison: {
    id: 'ProductDetail.paComparison',
    defaultMessage: 'P.A comparison',
  },
  paPeriod: {
    id: 'ProductDetail.paPeriod',
    defaultMessage: 'P.A {value}',
  },
  variable: {
    id: 'ProductDetail.variable',
    defaultMessage: 'variable',
  },
  paSubPeriod: {
    id: 'ProductDetail.paSubPeriod',
    defaultMessage: '{value}y',
  },
  perMonth: {
    id: 'ProductDetail.perMonth',
    defaultMessage: 'per month*',
  },
  repaymentsIOLabel: {
    id: 'ProductDetail.initialMonthlyRepaymentsIO.label',
    defaultMessage: 'INTEREST ONLY',
  },
  repaymentsPILabel: {
    id: 'ProductDetail.initialMonthlyRepaymentsPI.label',
    defaultMessage: 'PRINCIPAL + INTEREST',
  },
  upfrontFees: {
    id: 'ProductDetail.upfrontFees',
    defaultMessage: 'Upfront fees',
  },
  annualFees: {
    id: 'ProductDetail.annualFees',
    defaultMessage: 'Annual fees',
  },
  maxLVR: {
    id: 'ProductDetail.maxLVR',
    defaultMessage: 'Max LVR',
  },
  initialRepayment: {
    id: 'ProductDetail.initialRepayment',
    defaultMessage: 'Initial Repayment',
  },
  selected: {
    id: 'ProductDetail.selected',
    defaultMessage: 'Selected',
  },
});

export default messages;
