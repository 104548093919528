/* eslint-disable react/no-unused-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi/actions';

import ContentLayout from 'components/ContentLayout/ContentLayout';
import { PREFERENCES } from 'shared/constants/goalDashboard';
import { getSectionFromUrl } from 'lib/utils/browserUtils';

import {
  CURRENT_BANK,
  PREFERRED_LENDERS,
  PREFERRED_LOAN_OPTIONS,
} from 'shared/constants/paths';

import loanApplicationActions from 'actions/loanApplicationActions';
import goalLoanApplicationActions from 'actions/goal/goalLoanApplicationActions';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

import GoalPreferencesRoutes from './GoalPreferencesRoutes';
import GoalPreferencesApplyRoutes from './GoalPreferencesApplyRoutes';

export function GoalPreferences(props) {
  const {
    match,
    active,
    updateHasVisited,
    questions,
    familyId,
    fetchQuestionsAndAnswers,
  } = props;

  const {
    params: { section },
    url,
  } = match;
  const sectionName = getSectionFromUrl(url);

  const SECTION_TITLES = {
    [CURRENT_BANK]: 'Who do you bank with now?',
    [PREFERRED_LENDERS]: `Are there any particular lenders you'd like us to look at?`,
    [PREFERRED_LOAN_OPTIONS]: 'What are you looking for in a loan?',
  };

  useEffect(() => {
    updateHasVisited(PREFERENCES);
    if (Object.keys(questions).length === 0) {
      fetchQuestionsAndAnswers({ familyId, loanAppId: active });
    }
  }, []);

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    return () => clearTimeout(scrollTimeout);
  }, [section]);

  return (
    <ContentLayout
      title={SECTION_TITLES[section]}
      sectionName={sectionName}
      theme='noContentBg'
      showBackButton={false}
    >
      {match.path.includes('apply') ? (
        <GoalPreferencesApplyRoutes />
      ) : (
        <GoalPreferencesRoutes active={active} />
      )}
    </ContentLayout>
  );
}

GoalPreferences.propTypes = {
  match: PropTypes.object,
  goTo: PropTypes.func,
  active: PropTypes.number,
  updateHasVisited: PropTypes.func,
  questions: PropTypes.object,
  familyId: PropTypes.number,
  fetchQuestionsAndAnswers: PropTypes.func,
};

const mapStateToProps = (state) => {
  const family = clientSelectors.primaryFamily(state);
  return {
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    questions: goalLoanAppInfoSelectors.allQuestions(state),
    familyId: family.contactId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateHasVisited: goalLoanApplicationActions.updateHasVisited,
      fetchQuestionsAndAnswers:
        loanApplicationActions.requestGoalSetterQuestionsAndAnswers,
      goTo: push,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GoalPreferences);
