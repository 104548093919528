import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as actionTypes from './applyPropertyActionTypes';
import * as individualActionTypes from './individualPropertyActionTypes';

const propertyActions = createActions({}, ...Object.values(actionTypes));
export default {
  ...propertyActions,
  ...createIndividualisedActions(individualActionTypes),
};
