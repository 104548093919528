import { createSelector } from 'reselect';

import { INCOME_RENTAL_CATEGORY } from 'shared/constants/myCRMTypes/incomes';

export const incomeTypes = (state) => state.incomeType.incomeTypes;
export const incomeTypeCategories = createSelector(incomeTypes, (a) =>
  a.categories.filter((b) => b.value !== `${INCOME_RENTAL_CATEGORY}`),
);

export const categoryProperty = createSelector(
  incomeTypes,
  (a) => (category, property) =>
    (a.categories.find((b) => b.value === `${category}`) || {})[property],
);

export const categoryRequiresEmployment = createSelector(
  categoryProperty,
  (a) => (category) => a(category, 'requiresEmployment'),
);

export const categoryLabel = createSelector(
  categoryProperty,
  (a) => (category) => a(category, 'label'),
);

export const categoryFieldLabel = createSelector(
  categoryProperty,
  (a) => (category) => a(category, 'fieldLabel'),
);

export const categoryFieldDescription = createSelector(
  categoryProperty,
  (a) => (category) => a(category, 'fieldDescription'),
);

export const onlyIncomeTypes = createSelector(incomeTypes, (a) =>
  a.categories.reduce((map, obj) => {
    const types = a[obj.value];
    if (types && types.length === 1) {
      map[obj.value] = parseInt(types[0].value, 10);
    }
    return map;
  }, {}),
);

export const rentalIncomeCategory = createSelector(incomeTypes, (a) =>
  a.categories.find((b) => b.value === `${INCOME_RENTAL_CATEGORY}`),
);
