export const DOCUMENT_CATEGORY_BANK_STATEMENTS_KEY = 'Statements';
export const DOCUMENT_CATEGORY_IDENTITY_KEY = 'Identity';
export const DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY = 'Income';
export const DOCUMENT_CATEGORY_OTHER_DOCUMENTS_KEY = 'Other';

export const SUB_CATEGORY_BANK_STATEMENTS = {
  key: 'Statements',
  name: 'Bank Statements',
  category: DOCUMENT_CATEGORY_BANK_STATEMENTS_KEY,
};
export const SUB_CATEGORY_PASSPORT = {
  key: 'passport',
  name: 'Passport',
  category: DOCUMENT_CATEGORY_IDENTITY_KEY,
};
export const SUB_CATEGORY_DRIVER_LICENSE = {
  key: 'driverLicense',
  name: `Driver's License`,
  category: DOCUMENT_CATEGORY_IDENTITY_KEY,
};
export const SUB_CATEGORY_MEDICARE_CARD = {
  key: 'medicareCard',
  name: 'Medicare Card',
  category: DOCUMENT_CATEGORY_IDENTITY_KEY,
};
export const SUB_CATEGORY_OTHER_ID = {
  key: 'otherId',
  name: 'Other ID',
  category: DOCUMENT_CATEGORY_IDENTITY_KEY,
};
export const SUB_CATEGORY_PAYSLIPS = {
  key: 'payslips',
  name: 'Payslips (PAYG)',
  category: DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY,
};
export const SUB_CATEGORY_TAX_RETURNS = {
  key: 'taxReturns',
  name: 'Tax Returns',
  category: DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY,
};
export const SUB_CATEGORY_PROOF_OF_INCOME = {
  key: 'otherProofOfIncome',
  name: 'Other Proof Of Income',
  category: DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY,
};
export const SUB_CATEGORY_OTHER_DOCUMENT = {
  key: 'anyOtherDocument',
  name: 'Any Other Documents',
  category: DOCUMENT_CATEGORY_OTHER_DOCUMENTS_KEY,
};

export const DOCUMENT_SUB_CATEGORIES = [
  SUB_CATEGORY_BANK_STATEMENTS,
  SUB_CATEGORY_DRIVER_LICENSE,
  SUB_CATEGORY_PASSPORT,
  SUB_CATEGORY_MEDICARE_CARD,
  SUB_CATEGORY_OTHER_ID,
  SUB_CATEGORY_PAYSLIPS,
  SUB_CATEGORY_TAX_RETURNS,
  SUB_CATEGORY_PROOF_OF_INCOME,
  SUB_CATEGORY_OTHER_DOCUMENT,
];

export const BANK_STATEMENT_TYPE_ID = 5;
export const OTHER_INFO_TYPE_ID = 77;

export const DOCUMENT_TYPES = [
  {
    id: 12,
    name: 'Drivers Licence Australia',
    subCategory: SUB_CATEGORY_DRIVER_LICENSE.key,
  },
  {
    id: 13,
    name: 'Drivers Licence International',
    subCategory: SUB_CATEGORY_DRIVER_LICENSE.key,
  },
  {
    id: 32,
    name: 'Passport Australia',
    subCategory: SUB_CATEGORY_PASSPORT.key,
  },
  { id: 34, name: 'Passport Current', subCategory: SUB_CATEGORY_PASSPORT.key },
  { id: 35, name: 'Passport Expired', subCategory: SUB_CATEGORY_PASSPORT.key },
  {
    id: 11,
    name: 'Defence Force Identity Card',
    subCategory: SUB_CATEGORY_PASSPORT.key,
  },
  {
    id: 33,
    name: 'Passport International',
    subCategory: SUB_CATEGORY_PASSPORT.key,
  },
  {
    id: 30,
    name: 'Medicare Card',
    subCategory: SUB_CATEGORY_MEDICARE_CARD.key,
  },
  { id: 6, name: 'Birth Certificate', subCategory: SUB_CATEGORY_OTHER_ID.key },
  {
    id: 7,
    name: 'Birth Certificate Australia',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 8,
    name: 'Birth Certificate International',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 1,
    name: 'Australian Citizenship Certificate',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 9,
    name: 'Citizen Certificate International',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 67,
    name: 'Government / Rateable Valuation',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 18,
    name: 'Government Issued Boat Licence',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 19,
    name: 'Government Issued Employee Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 20,
    name: 'Government Issued Financial Ben Not',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 21,
    name: 'Government Issued Gun Licence',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 22,
    name: 'Government Issued Health Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 23,
    name: 'Government Issued Pilot Licence',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 24,
    name: 'Government Pension Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 26,
    name: 'ID From Current Employer',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  { id: 25, name: 'Identity Card', subCategory: SUB_CATEGORY_OTHER_ID.key },
  {
    id: 29,
    name: 'Marriage Certificate',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 31,
    name: 'Official Student Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  { id: 36, name: 'Proof Of Age Card', subCategory: SUB_CATEGORY_OTHER_ID.key },
  {
    id: 37,
    name: 'Public Service ID Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 41,
    name: 'Security Guard ID Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  {
    id: 45,
    name: 'Tertiary Student ID Card',
    subCategory: SUB_CATEGORY_OTHER_ID.key,
  },
  { id: 63, name: 'Payslips', subCategory: SUB_CATEGORY_PAYSLIPS.key },
  { id: 82, name: 'PAYG Summary', subCategory: SUB_CATEGORY_PAYSLIPS.key },
  {
    id: 44,
    name: 'Taxation Notice Australia',
    subCategory: SUB_CATEGORY_TAX_RETURNS.key,
  },
  {
    id: 60,
    name: 'Annual Financial Accounts for Year Ended 31st March',
    subCategory: SUB_CATEGORY_PROOF_OF_INCOME.key,
  },
  {
    id: 59,
    name: 'Savings Account',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: 54,
    name: 'Credit Card’s Statements',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: 2,
    name: 'Bank Cash Card',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: 3,
    name: 'Bank Credit Card',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: 4,
    name: 'Bank Passbook',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: BANK_STATEMENT_TYPE_ID,
    name: 'Bank Statement',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: 53,
    name: 'Business Bank Account',
    subCategory: SUB_CATEGORY_BANK_STATEMENTS.key,
  },
  {
    id: 66,
    name: 'Building Report',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 71,
    name: 'Client Personal Assessment Signed',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 72,
    name: 'Credit Quote & Proposal Signed',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  { id: 10, name: 'Deed Poll', subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key },
  {
    id: 73,
    name: 'Evidence of Shares and Other Investments',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 14,
    name: 'Existing Customer',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 55,
    name: 'Existing Loan Transaction History Including Latest balance',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 74,
    name: 'Family Trust Deed',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 17,
    name: 'Financial Institution Cash Card',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 15,
    name: 'Financial Institution Credit Card',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 16,
    name: 'Financial Institution Passbook',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 56,
    name: 'Gift Declaration',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 27,
    name: 'Lease Contract',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 75,
    name: 'Lenders Debt Servicing Spreadsheet / Load Worksheet',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 28,
    name: 'Letter From Current Employer',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 62,
    name:
      'Letter from Employer confirming Remuneration, Title and Length of Service',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 76,
    name: 'Loan Recommendation / Diary Notes',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: OTHER_INFO_TYPE_ID,
    name: 'Other Information',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 78,
    name:
      'Other Personal Loan Statements and Confirmation of Outstanding Balance',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 57,
    name: 'Personal Current Account',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 79,
    name: 'Quotes for improvement to Security or Written Request from Borrower',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  { id: 38, name: 'Rate Notice', subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key },
  { id: 39, name: 'Reference', subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key },
  {
    id: 68,
    name: 'Registered Valuation',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 40,
    name: 'Rent Receipt',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 58,
    name: 'Rental Account',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 64,
    name: 'Rental Estimate',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 69,
    name: 'Sale and Purchase Agreement',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 42,
    name: 'School Letter Of Intro',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 43,
    name: 'Statement From Landlord',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 80,
    name: 'Superannuation Balance',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 65,
    name: 'Tenancy Agreement/s',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 46,
    name: 'Utility Statement Electricity',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 47,
    name: 'Utility Statement Gas',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 48,
    name: 'Utility Statement Phone',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 49,
    name: 'Utility Statement Water',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 70,
    name: 'Valuations of Existing Property Owned',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 50,
    name: 'Written Ref Customer',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 51,
    name: 'Written Ref Financial Institution',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
  {
    id: 52,
    name: 'Written Ref Referee',
    subCategory: SUB_CATEGORY_OTHER_DOCUMENT.key,
  },
];

export const DOCUMENT_CATEGORIES = [
  DOCUMENT_CATEGORY_BANK_STATEMENTS_KEY,
  DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY,
  DOCUMENT_CATEGORY_IDENTITY_KEY,
  DOCUMENT_CATEGORY_OTHER_DOCUMENTS_KEY,
];
