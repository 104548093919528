import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import productsActions from 'actions/productsActions';

import { generateUniqueID } from 'lib/utils/stringUtils';
import { isGroupingProducts } from 'lib/compareHelper';

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import ProductRecord from 'components/ProductRecord/ProductRecord';
import ButtonWithSpinner from 'components/ButtonWithSpinner/ButtonWithSpinner';
import styles from './ProductList.css';

const messages = defineMessages({
  productsNotFoundText: {
    id: 'ProductList.productsNotFound.text',
    defaultMessage: 'Sorry, we can’t find any loans that match your criteria.',
  },
});

class ProductList extends Component {
  static propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    focusProductId: PropTypes.number,
    structure: PropTypes.object,
    sortingDescription: PropTypes.string,
    requestLenderProducts: PropTypes.func,
    onSelection: PropTypes.func,
    intl: intlShape.isRequired,
    isLocked: PropTypes.bool,
    hideSelectButton: PropTypes.bool,
    showMoreButton: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    onLoadMore: PropTypes.func,
  };

  requestLenderProductsAction = ({ lenderId }) => {
    this.props.requestLenderProducts({ lenderId, page: 1 });
    window.scrollTo(0, 0);
  };

  renderProduct = (product, key) => {
    const {
      isLocked,
      structure,
      focusProductId,
      sortingDescription,
      onSelection,
      hideSelectButton,
    } = this.props;
    return (
      <ProductRecord
        key={generateUniqueID('productRecord', key)}
        isLocked={isLocked}
        featuredKey={key}
        product={product}
        onSelectProduct={onSelection}
        requestLenderProductsAction={this.requestLenderProductsAction}
        loanTerm={structure.loanTerm}
        repaymentType={structure.repaymentType}
        repaymentFrequency={structure.repaymentFrequency}
        forceFocus={product.id === focusProductId}
        sortingDescription={sortingDescription}
        isGrouping={isGroupingProducts(structure.selectedLenderIds)}
        isSelectedLender={structure.lenderId === product.lender.id}
        isSelectedProduct={structure.productId === product.id}
        hideSelectButton={hideSelectButton}
      />
    );
  };

  render = () => {
    const {
      products,
      showMoreButton,
      isLoadingMore,
      onLoadMore,
      intl: { formatMessage },
    } = this.props;

    if (products.length === 0) {
      return (
        <strong className={styles.message}>
          {formatMessage(messages.productsNotFoundText)}
        </strong>
      );
    }

    return (
      <div>
        <CSSTransitionGroup
          transitionName='flyUpFadeIn'
          transitionEnterTimeout={8000}
          transitionLeaveTimeout={0}
        >
          {products.map(this.renderProduct)}
        </CSSTransitionGroup>
        {showMoreButton && (
          <ButtonWithSpinner
            id='loadMoreProducts'
            onClick={onLoadMore}
            showSpinner={isLoadingMore}
            label='Load More'
            labelWhileSpinning='Loading More Products...'
          />
        )}
      </div>
    );
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestLenderProducts: productsActions.requestLenderProducts,
      setProductDetail: productsActions.setProductDetail,
    },
    dispatch,
  );

export default injectIntl(connect(null, mapDispatchToProps)(ProductList));
