import axios from 'axios';
import { AGGREGATOR_API_PATH } from 'shared/constants/apiPaths';

import { throwJSON } from './baseApi';

export const aggregatorMapper = (data) => {
  return (
    data && {
      id: data.ID,
      companyNumber: `ABN ${data.CorporateNumber}`,
      licenceNumber: `Australian Credit Licence ${data.LicenceNumber}`,
      companyName: data.Name,
    }
  );
};

export const getAggregator = (aggregatorId = 0) => {
  return axios
    .get(`${AGGREGATOR_API_PATH}/${aggregatorId}`)
    .then(({ data }) => aggregatorMapper(data), throwJSON);
};

export const getAggregatorByFamilyId = (familyId = 0) => {
  return axios
    .get(`${AGGREGATOR_API_PATH}?familyId=${familyId}`)
    .then(({ data }) => aggregatorMapper(data), throwJSON);
};
