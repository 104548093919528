/* eslint-disable sonarjs/no-duplicate-string */
export const OTHER_LENDER = {
  lenderId: -1,
  lenderName: 'Other',
};

export const OTHER_AU_BANKS = [
  {
    lenderName: 'American Express [AU]',
    key: 'AmericanExpressAU',
    mogoId: 1601,
    mogoName: 'American Express [AU]',
    lenderCode: 'AE',
  },
  {
    lenderName: 'Bendigo Bank',
    key: 'BendigoBank',
    mogoId: 1009,
    mogoName: 'Bendigo Bank',
    lenderCode: 'BEB',
  },
  {
    lenderName: 'Credit Union Australia',
    key: 'CUA',
    mogoId: 1095,
    mogoName: 'Credit Union Australia',
    lenderCode: 'CUA',
  },
  {
    lenderName: 'Greater Bank',
    key: 'GreaterBank',
    mogoId: 1199,
    mogoName: 'Greater Bank',
    lenderCode: 'GRB',
  },
  {
    lenderName: 'HSBC',
    key: 'HSBC',
    mogoId: 1024,
    mogoName: 'HSBC AU',
    lenderCode: 'HSBC',
  },
  {
    lenderName: 'myGov',
    key: 'MyGov',
    mogoId: 2001,
    mogoName: 'myGov',
    lenderCode: 'MyGov',
  },
  {
    lenderName: 'UBank',
    key: 'UBank',
    mogoId: 1013,
    mogoName: 'UBank',
    lenderCode: 'UBank',
  },
  {
    lenderName: 'Woolworths Money',
    key: 'WoolworthsMoney',
    mogoId: 1012,
    mogoName: 'Woolworths Money',
    lenderCode: 'WOM',
  },
  {
    lenderName: '28 Degrees MasterCard',
    key: 'DegreesMasterCard',
    mogoId: 1056,
    mogoName: '28 Degrees MasterCard',
    lenderCode: 'DMC',
  },
  {
    lenderName: 'Beyond Bank',
    key: 'BeyondBank',
    mogoId: 1028,
    mogoName: 'Beyond Bank',
    lenderCode: 'BBHL',
    lenderId: 3613,
  },
  {
    lenderName: 'Broken Hill Community CU',
    key: 'BrokerhillCommunityCU',
    mogoId: 1062,
    mogoName: 'Broken Hill Community CU',
    lenderCode: 'BHCC',
  },
  {
    lenderName: 'Buyers Edge',
    key: 'BuyersEdge',
    mogoId: 1067,
    mogoName: 'Buyers Edge',
    lenderCode: 'BUE',
  },
  {
    lenderName: 'Coastline CU',
    key: 'CoastlineCU',
    mogoId: 1058,
    mogoName: 'Coastline CU',
    lenderCode: 'CCU',
  },
  {
    lenderName: 'Credit Union SA',
    key: 'CreditUnionSA',
    mogoId: 1061,
    mogoName: 'Credit Union SA',
    lenderCode: 'CUSA',
  },
  {
    lenderName: 'Creditline Card',
    key: 'CreditlineCard',
    mogoId: 1055,
    mogoName: 'Creditline Card',
    lenderCode: 'CRC',
  },
  {
    lenderName: 'Defense Bank',
    key: 'DefenseBank',
    mogoId: 1059,
    mogoName: 'Defense Bank',
    lenderCode: 'DEB',
  },
  {
    lenderName: 'Fire Services CU',
    key: 'FireServicesCU',
    mogoId: 1063,
    mogoName: 'Fire Services CU',
    lenderCode: 'FSCU',
  },
  {
    lenderName: 'FirstChoice CU',
    key: 'FirstChoiceCU',
    mogoId: 1057,
    mogoName: 'FirstChoice CU',
    lenderCode: 'FCCU',
  },
  {
    lenderName: 'Gateway Bank',
    key: 'GatewayBank',
    mogoId: 1034,
    mogoName: 'Gateway Bank',
    lenderCode: 'GAB',
  },
  {
    lenderName: 'Gem Visa Card',
    key: 'GemVisaCard',
    mogoId: 1064,
    mogoName: 'Gem Visa Card',
    lenderCode: 'GVC',
  },
  {
    lenderName: 'GO Mastercard',
    key: 'GOMastercard',
    mogoId: 1065,
    mogoName: 'GO Mastercard',
    lenderCode: 'GOM',
  },
  {
    lenderName: 'Latitude Eco Mastercard',
    key: 'LatitudeEcoMastercard',
    mogoId: 1072,
    mogoName: 'Latitude Eco Mastercard',
    lenderCode: 'LEM',
  },
  {
    lenderName: 'Latitude Infinity Card',
    key: 'LatitudeInfinityCard',
    mogoId: 1068,
    mogoName: 'Latitude Infinity Card',
    lenderCode: 'LIC',
  },
  {
    lenderName: 'Latitude Low Rate Mastercard',
    key: 'LatitudeLowRateMastercard',
    mogoId: 1070,
    mogoName: 'Latitude Low Rate Mastercard',
    lenderCode: 'LLRM',
  },
  {
    lenderName: 'Latitude Mastercard',
    key: 'LatitudeMastercard',
    mogoId: 1069,
    mogoName: 'Latitude Mastercard',
    lenderCode: 'LAM',
  },
  {
    lenderName: 'P&N Bank',
    key: 'PNBank',
    mogoId: 1030,
    mogoName: 'P&N Bank',
    lenderCode: 'MyState',
  },
  {
    lenderName: 'Qudos Mutual Bank',
    key: 'QudosMutualBank',
    mogoId: 1031,
    mogoName: 'Qudos Mutual Bank',
    lenderCode: 'QMB',
  },
  {
    lenderName: 'Queensland Country Credit Union',
    key: 'QueenslandCountryCreditUnion',
    mogoId: 1032,
    mogoName: 'Queensland Country Credit Union',
    lenderCode: 'QCCU',
  },
  {
    lenderName: 'RaboDirect',
    key: 'RaboDirect',
    mogoId: 1054,
    mogoName: 'RaboDirect',
    lenderCode: 'RAD',
  },
  {
    lenderName: 'Advantedge Financial Services',
    key: 'AdvantedgeFS',
    mogoId: 1099,
    mogoName: 'Advantedge Financial Services',
    lenderCode: 'AFS',
  },
  // {
  //   lenderName: 'Afterpay',
  //   key: 'Afterpay',
  //   mogoId: 0,
  //   mogoName: 'Afterpay',
  // },
  // {
  //   lenderName: 'Australian Military Bank',
  //   key: 'AustralianMilitary',
  //   mogoId: 0,
  //   mogoName: 'Australian Military Bank',
  // },
  {
    lenderName: 'Australian Unity',
    key: 'AustralianUnity',
    mogoId: 1087,
    mogoName: 'Australian Unity',
    lenderCode: 'AUU',
  },
  // {
  //   lenderName: 'Auswide Bank',
  //   key: 'AuswideBank',
  //   mogoId: 0,
  //   mogoName: 'Auswide Bank',
  // },
  {
    lenderName: 'Bananacoast Community Credit Union',
    key: 'BananacoastCCU',
    mogoId: 1078,
    mogoName: 'Bananacoast Community Credit Union',
    lenderCode: 'BCCU',
  },
  {
    lenderName: 'BankVic',
    key: 'BankVIC',
    mogoId: 1039,
    mogoName: 'BankVic',
    lenderCode: 'BAV',
  },
  {
    lenderName: 'Bank Of Heritage Isle',
    key: 'BankOfHeritageIsle',
    mogoId: 1040,
    mogoName: 'Bank Of Heritage Isle',
    lenderCode: 'BHI',
  },
  {
    lenderName: 'Bank Of US',
    key: 'BankOfUS',
    mogoId: 1084,
    mogoName: 'Bank Of US',
    lenderCode: 'BUS',
  },
  {
    lenderName: 'BDCU Alliance Bank',
    key: 'BDCUAllianceBank',
    mogoId: 1026,
    mogoName: 'BDCU Alliance Bank',
    lenderCode: 'BDCU',
  },
  {
    lenderName: 'Border Bank',
    key: 'BorderBank',
    mogoId: 1090,
    mogoName: 'Border Bank',
    lenderCode: 'BOB',
  },
  {
    lenderName: 'Central Murray Credit Union',
    key: 'CentralMurrayCU',
    mogoId: 1075,
    mogoName: 'Central Murray Credit Union',
    lenderCode: 'CMCU',
  },
  {
    lenderName: 'Central West Credit Union',
    key: 'CentralWestCU',
    mogoId: 1076,
    mogoName: 'Central West Credit Union',
    lenderCode: 'CWCU',
  },
  {
    lenderName: 'Family First Credit Union',
    key: 'FamilyFirstCU',
    mogoId: 1091,
    mogoName: 'Family First Credit Union',
    lenderCode: 'FFCU',
  },
  {
    lenderName: 'Firefighters Mutual Bank',
    key: 'FirefightersMB',
    mogoId: 1077,
    mogoName: 'Family First Credit Union',
    lenderCode: 'FMB',
  },
  {
    lenderName: 'G&C Mutual Bank',
    key: 'GCMutualBank',
    mogoId: 1025,
    mogoName: 'G&C Mutual Bank',
    lenderCode: 'GCMB',
  },
  {
    lenderName: 'Geelong Bank',
    key: 'GeelongBank',
    mogoId: 1083,
    mogoName: 'Geelong Bank',
    lenderCode: 'GEB',
  },
  {
    lenderName: 'Greater Bank',
    key: 'GreaterBuilding',
    mogoId: 1199,
    mogoName: 'Greater Bank',
    lenderCode: 'GRB',
  },
  {
    lenderName: 'Goulburn Murray Credit Union',
    key: 'GoulburnMurrayCU',
    mogoId: 1041,
    mogoName: 'Goulburn Murray Credit Union',
    lenderCode: 'GMCU',
  },
  {
    lenderName: 'Horizon Credit Union',
    key: 'HorizonCU',
    mogoId: 1049,
    mogoName: 'Horizon Credit Union',
    lenderCode: 'HCU',
  },
  {
    lenderName: 'Hume Bank',
    key: 'HUME',
    mogoId: 1051,
    mogoName: 'Hume Bank',
    lenderCode: 'HUB',
  },
  {
    lenderName: 'Illawarra Credit Union',
    key: 'IllawarraCU',
    mogoId: 1081,
    mogoName: 'Illawarra Credit Union',
    lenderCode: 'ICU',
  },
  {
    lenderName: 'Laboratories Credit Union',
    key: 'LaboratoriesCU',
    mogoId: 1079,
    mogoName: 'Laboratories Credit Union',
    lenderCode: 'LCU',
  },
  {
    lenderName: `Lutheran Laypeople's League`,
    key: 'LutheranLL',
    mogoId: 1086,
    mogoName: `Lutheran Laypeople's League`,
    lenderCode: 'LLL',
  },
  // {
  //   lenderName: 'IMB Bank',
  //   key: 'IMB',
  //   mogoId: 0,
  //   mogoName: 'IMB Bank',
  // },
  {
    lenderName: 'Newcastle Permanent Building Society AU',
    key: 'NewCastle',
    mogoId: 1053,
    mogoName: 'Newcastle Permanent Building Society AU',
    lenderCode: 'NPBS',
  },
  {
    lenderName: 'Northern Inland Credit Union',
    key: 'NorthernInlandCU',
    mogoId: 1047,
    mogoName: 'Northern Inland Credit Union',
    lenderCode: 'NICU',
  },
  {
    lenderName: 'Nova Alliance Bank',
    key: 'NovaAllianceBank',
    mogoId: 1085,
    mogoName: 'Nova Alliance Bank',
    lenderCode: 'NAB',
  },
  {
    lenderName: 'Orange Credit Union',
    key: 'OrangeCU',
    mogoId: 1082,
    mogoName: 'Orange Credit Union',
    lenderCode: 'OCU',
  },
  {
    lenderName: 'Police Bank',
    key: 'PoliceBank',
    mogoId: 1017,
    mogoName: 'Police Bank',
    lenderCode: 'POB',
  },
  {
    lenderName: 'Police Credit Union',
    key: 'PoliceCU',
    mogoId: 1019,
    mogoName: 'Police Credit Union',
    lenderCode: 'PCU',
  },
  {
    lenderName: 'QBank',
    key: 'QBank',
    mogoId: 1073,
    mogoName: 'QBank',
    lenderCode: 'QBA',
  },
  {
    lenderName: 'Rabobank Australia',
    key: 'RaboBank',
    mogoId: 1054,
    mogoName: 'Rabobank Australia',
    lenderCode: 'RABO',
  },
  // {
  //   lenderName: 'RACQ Bank',
  //   key: 'RACQ',
  //   mogoId: 0,
  //   mogoName: 'RACQ Bank',
  // },
  // {
  //   lenderName: 'RAMS',
  //   key: 'RAMS',
  //   mogoId: 0,
  //   mogoName: 'RAMS',
  // },
  {
    lenderName: 'Regional Australia Bank',
    key: 'RegionalAUBank',
    mogoId: 1038,
    mogoName: 'Regional Australia Bank',
    lenderCode: 'RAB',
  },
  {
    lenderName: 'Service One Alliance Bank',
    key: 'ServiceOneAlliance',
    mogoId: 1043,
    mogoName: 'Service One Alliance Bank',
    lenderCode: 'SOAB',
  },
  {
    lenderName: 'South-West Credit Union Cooperative Ltd',
    key: 'SouthWest',
    mogoId: 1036,
    mogoName: 'South-West Credit Union Cooperative Ltd',
    lenderCode: 'SCUCL',
  },
  {
    lenderName: 'Teachers Mutual Bank',
    key: 'TeachersMutual',
    mogoId: 1052,
    mogoName: 'Teachers Mutual Bank',
    lenderCode: 'TMB',
  },
  {
    lenderName: 'The Capricornian Bank',
    key: 'TheCapricornianBank',
    mogoId: 1050,
    mogoName: 'The Capricornian Bank',
    lenderCode: 'TCB',
  },
  {
    lenderName: 'The Mutual Bank',
    key: 'TheMutualBank',
    mogoId: 1080,
    mogoName: 'The Mutual Bank',
    lenderCode: 'TMB',
  },
  // {
  //   lenderName: 'Toyota Finance',
  //   key: 'ToyotaFinance',
  //   mogoId: 0,
  //   mogoName: 'Toyota Finance',
  // },
  {
    lenderName: 'Traditional Credit Union',
    key: 'TraditionalCU',
    mogoId: 1060,
    mogoName: 'Traditional Credit Union',
    lenderCode: 'TCU',
  },
  {
    lenderName: 'UniBank',
    key: 'UniBank',
    mogoId: 1074,
    mogoName: 'UniBank',
    lenderCode: 'UNB',
  },
  // {
  //   lenderName: 'Virgin Money',
  //   key: 'VirginMoney',
  //   mogoId: 0,
  //   mogoName: 'Virgin Money',
  // },
  // {
  //   lenderName: 'VW Finance',
  //   key: 'VWFinance',
  //   mogoId: 0,
  //   mogoName: 'VW Finance',
  // },
  {
    lenderName: 'Warwick Credit Union',
    key: 'WarwickCU',
    mogoId: 1048,
    mogoName: 'Warwick Credit Union',
    lenderCode: 'WCU',
  },
  {
    lenderName: 'Virgin Money Credit Cards',
    key: 'VirginMoneyCC',
    mogoId: 1113,
    mogoName: 'Virgin Money Credit Cards',
  },
  // {
  //   lenderName: 'Zip Money',
  //   key: 'ZipMoney',
  //   mogoId: 0,
  //   mogoName: 'Zip Money',
  // },
  {
    lenderId: 5045,
    lenderName: 'Heartland Seniors Finance',
    key: 'HSF',
    nextGenApiCode: undefined,
    lenderCode: 'HSF',
    lenderDescription:
      'Heartland Seniors Finance is Australia’s leading reverse mortgage provider.',
  },
  {
    lenderId: 5049,
    lenderName: 'Granite Homeloans',
    key: 'GHL',
    nextGenApiCode: undefined,
    lenderCode: 'GHL',
    lenderDescription:
      'Granite Home Loans has created a unique and innovative solution that lends 100% of the property purchase price. Requiring no deposit, no parental guarantees and no low deposit fees or mortgage insurance, our Granite 100 Owner Occupied Home Loan enables home ownership earlier by backing borrowers that have strong earning potential and a steady job.',
  },
  {
    lenderName: 'Arab Bank',
    key: 'ArabBank',
    mogoId: 1101,
    mogoName: 'Arab Bank',
  },
  {
    lenderName: 'Australian Military Bank',
    key: 'AustralianMB',
    mogoId: 1102,
    mogoName: 'Australian Military Bank',
  },
  {
    lenderName: 'Auswide Bank',
    key: 'AuswideBank',
    mogoId: 1103,
    mogoName: 'Auswide Bank',
  },
  {
    lenderName: 'Bank of Sydney',
    key: 'BankOfSydney',
    mogoId: 1104,
    mogoName: 'Bank of Sydney',
  },
  {
    lenderName: 'MyState Bank Limited',
    key: 'MyStateBankLtd',
    mogoId: 1098,
    mogoName: 'MyState Bank Limited',
  },
  {
    lenderName: 'RAMS',
    key: 'RAMS',
    mogoId: 1106,
    mogoName: 'RAMS',
  },
  {
    lenderName: 'Southern Cross CU',
    key: 'SouthernCrossCU',
    mogoId: 1108,
    mogoName: 'Southern Cross CU',
  },
  {
    lenderName: 'The Macarthur Credit Union',
    key: 'TheMac',
    mogoId: 1089,
    mogoName: 'The Macarthur Credit Union',
  },
  {
    lenderName: 'Virgin Money Home Loans',
    key: 'VirginMoneyHL',
    mogoId: 1114,
    mogoName: 'Virgin Money Home Loans',
  },
];

export const NZ_LENDERS = [
  {
    lenderId: 14,
    lenderName: 'ANZ',
    key: 'ANZ',
    lenderCode: 'ANZ',
    isBigLender: true,
    description:
      'ANZ is currently one of New Zealand’s largest companies based on profit and assets. ANZ finance over 30% of home loans in NZ, and have a banking relationship with nearly one in two New Zealanders.',
  },
  {
    lenderId: 13,
    lenderName: 'ASB',
    key: 'ASB',
    lenderCode: 'ASB',
    isBigLender: true,
    description:
      'ASB pledged to serve the community; to grow; and to help kiwis grow. We’ve done that over the years by not standing still; by continuing to rethink the way people bank and how they interact with their money; by not just being another ‘bank’. We’re also inspired by success. Big or small, every milestone reached by our customers is a success and pushes us to go even further; to do better. Which is probably why more than 1.3 million personal, business and rural customers choose to bank with us.',
  },
  {
    lenderId: 24,
    lenderName: 'BNZ',
    key: 'BNZ',
    lenderCode: 'BNZ',
    isBigLender: true,
    description:
      'We’ve been helping our customers make their goals a reality for over 150 years, and now we’re working with them to redesign banking for the future. We put people first. That’s why we like to help our local communities, through volunteering and partnerships. We believe that protecting the environment is the responsibility of all of us – and we take active steps to minimise our impact. We are also getting behind New Zealand charities and organisations and helping them to flourish is an important part of what we do.',
  },
  {
    lenderId: 16,
    lenderName: 'Westpac',
    key: 'Westpac',
    lenderCode: 'CWBC',
    isBigLender: true,
    description:
      'As one of the oldest and biggest banks in New Zealand and Australia, Westpac continues to provide a strategic flexibility to financial solutions to better meet individual customer needs. We’re involved in a wide range of Kiwi institutions and projects for the benefit of all New Zealanders. Sustainability and helping to create a better tomorrow is at the heart of our culture, that’s why we take a company-wide approach to helping develop solutions that contribute to a stronger New Zealand.',
  },
  {
    lenderId: 76,
    lenderName: 'ASAP Finance Limited',
    key: 'AsapFinance',
    lenderCode: 'AsapFinance',
    isBigLender: false,
    description:
      'ASAP Finance is a leading non-bank lender offering first mortgage finance on residential, commercial and industrial properties. Since 2004 we have partnered with investors, developers and home builders to provide innovative and competitive property funding solutions. Our team combines in-depth industry knowledge with practical development experience to cater to all aspects of your funding requirements.',
  },
  {
    lenderId: 80,
    lenderName: 'NZCU',
    key: 'NZCU',
    lenderCode: 'NZCU',
    description: '',
    isBigLender: false,
  },
  {
    lenderId: 21,
    lenderName: 'Avanti Finance',
    key: 'Avanti',
    lenderCode: 'Avanti',
    isBigLender: false,
    description:
      'Since 1989, Avanti has helped nearly 100,000 New Zealanders by providing finance to buy a car, pay a bill or buy a home. Our customers come from around Aotearoa, from all walks of life, with different ages, incomes, gender and ethnicity.',
  },
  {
    lenderId: 119,
    lenderName: 'Basecorp Finance',
    key: 'Basecorp',
    lenderCode: 'Basecorp',
    isBigLender: false,
    description:
      'Basecorp Finance is a registered financial service provider, and a member of the Financial Services Complaints Limited disputes resolution scheme. It strives to be a leader in the non-bank sector in terms of its values and ethics in relation to responsible lending & legislative compliance.',
  },
  {
    lenderId: 23,
    lenderName: 'Bluestone',
    key: 'Bluestone',
    lenderCode: 'Bluestone',
    isBigLender: false,
    description:
      'Bluestone is a fast-growing non-bank specialising in home loans for borrowers in New Zealand and Australia who require solutions not offered by the big banks. Since launching in 2000, Bluestone has assisted over 53,000 borrowers, and has over NZ$9.5b in assets under management. With no credit scorecards and a holistic approach to loan assessment, we are able to find the right loan for more borrowers by assessing each situation on its individual merits. Our New Zealand advisers are supported by a best-in-class BDM team who provide face-to-face training and support across the country.',
  },
  {
    lenderId: 121,
    lenderName: 'Funding Partners',
    key: 'CFML',
    lenderCode: 'CFML',
    isBigLender: false,
    description:
      'Funding Partners is an agile and responsive New Zealand based property funding firm. Specialising in first mortgages within the non-consumer property sector, the team at Funding Partners is made up of skilled property professionals with many years of expertise. With the focus being on the security of the property rather than red tape, Funding Partners is able to act where traditional bank funding struggles. Funding Partners offers tailor-made solutions for individual property situations.',
  },
  {
    lenderId: 85,
    lenderName: 'Cressida Capital',
    key: 'CressidaCapital',
    lenderCode: 'CressidaCapital',
    isBigLender: false,
    description:
      'Established in 2003, Cressida Capital is a privately funded finance business represented by a team of property professionals dedicated to meeting your non-bank funding requirements. We deliver property finance solutions to those who require speed and flexibility at market leading rates. Every year we assist property investors, and developers to realise their visions, as well as businesses, trusts and entrepreneurs to leverage their property assets, in ways that mainstream banks are unable to consider. You can trust us to be there when the bank says "no" and help unlock the potential in your property assets.',
  },
  {
    lenderId: 87,
    lenderName: 'DBR',
    key: 'DBR',
    lenderCode: 'DBR',
    isBigLender: false,
    description:
      'DBR is a local, privately owned non-bank lender providing fast and fair short-term property finance solutions across a wide range of residential and commercial properties. Since our inception in 2008, we’ve provided over 1000 property finance solutions that have given clients the time to consolidate and move their projects forward.',
  },
  {
    lenderId: 90,
    lenderName: 'First Mortgage Trust',
    key: 'FirstMortgageTrust',
    lenderCode: 'First Mortgage Trust',
    isBigLender: false,
    description:
      'As a group investment fund we offer borrowers smart finance options for borrowing against land and buildings and pride ourselves on our professional friendly service, competitive interest rates, flexible loan terms, quick turn-around, minimal paperwork and capitalised interest options - and right now, we have money to lend! Investors can enjoy competitive returns backed by a portfolio of loans secured by first mortgages that have been above those offered by trading banks on a one year deposit. If you are serious about making a smart choice, you should consider investing with First Mortgage Trust and its proven track record in providing returns to investors. First Mortgage Trust is your viable bank alternative for property finance and investments.',
  },
  {
    lenderId: 55,
    lenderName: 'Heartland Bank',
    key: 'HeartlandBank',
    lenderCode: 'HeartlandBank',
    isBigLender: false,
    description:
      'Heartland Seniors Finance is Australia’s leading reverse mortgage provider. Established in 2004, we have helped over 19,000 people aged 60 and over release more than $1bn of equity from their homes, helping them to live a more comfortable retirement, with independence and dignity.',
  },
  {
    lenderId: 72,
    lenderName: 'Liberty Financial',
    key: 'LibertyFinancial',
    lenderCode: 'Liberty',
    isBigLender: false,
    description:
      'The Liberty Group is a highly regarded pioneer and leading specialty financial services company with operations across Australia and New Zealand. With offices in Auckland, Melbourne and Sydney, the group employs over 300 staff and is backed by global banks such as Credit Suisse, Commonwealth Bank, Deutsche Bank, Macquarie Bank, National Australia Bank and Westpac.',
  },
  {
    lenderId: 77,
    lenderName: 'RESIMAC',
    key: 'Resimac',
    lenderCode: 'Resimac',
    mogoId: 1107,
    isBigLender: false,
    description:
      'RESIMAC Home Loans is a non-bank lender focused on providing a broad range of home loan solutions and competitively low interest rates for New Zealand borrowers. RESIMAC Home Loans has a team of professionals whose aim is to make the task of obtaining a home loan a pleasant experience for every borrower, and continue offering personal service to existing customers by looking after their loan.',
  },
  {
    lenderId: 53,
    lenderName: 'SBS Bank',
    key: 'SBSBank',
    lenderCode: 'SBS',
    isBigLender: false,
    description:
      'We are New Zealand’s member bank, so when you bank with us; we’re with you. As a member-owned bank when you become a member you become part of SBS Bank. We’re committed to creating a partnership focused on helping you on the journey to financial independence. We want to build mutual trust, mutual benefits and most of all mutual respect. From your first home, to investing, retirement and anything in between. We believe that’s the way banking should be. SBS Bank – We’re with you.',
  },
  {
    lenderId: 103,
    lenderName: 'Select Home Loan',
    key: 'Select',
    lenderCode: 'Select',
    isBigLender: false,
    description:
      'Select was established in New Zealand in 2019 as a residential mortgage lender. Our range of loans aims to provide flexible loan solutions to those who fall outside the traditional lenders’ criteria - helping more customers, more often.',
  },
  {
    lenderId: 118,
    lenderName: 'Southern Cross',
    key: 'SouthernCross',
    description:
      'Established in 1997, Southern Cross Financial Group (SCFG) is a 100% NZ owned finance company that has specialised in short term property finance and first mortgage investments.',
    mogoId: 1108,
    mogoName: 'Southern Cross CU',
    isBigLender: false,
  },
  {
    lenderId: 1,
    lenderName: 'Sovereign',
    key: 'Sovereign',
    lenderCode: 'SOV',
    isBigLender: false,
    description:
      'AIA Group is the largest life insurance company in the world*, with a presence in 18 markets across Asia Pacific. AIA New Zealand Limited (NZ) is part of the AIA Group and has been providing insurance to New Zealanders since 1981. On 2 July 2018, AIA Group acquired Sovereign. Since then we have worked hard to bring together the best of AIA New Zealand and Sovereign’s existing product ranges, systems and processes. We’re proud to display our combined expertise at the forefront of the insurance industry. From Cape Reinga to Stewart Island, and everywhere in between, we empower our customers to live their version of healthy, every day.',
  },
  {
    lenderId: 17,
    lenderName: 'The Co-operative Bank',
    key: 'TheCooperativeBank',
    lenderCode: 'TCB',
    isBigLender: false,
    description:
      'We’ve been doing the right thing by our customers since we began our journey in 1928, right here in New Zealand. As the only bank in New Zealand that shares our profits with our customers, it’s one of the many ways we do right by you. We’re thrilled to have shared more than $12 million in profits with our customers in local communities nationwide, since 2013.',
  },
  {
    lenderId: 93,
    lenderName: 'Bank of China',
    lenderCode: 'BOC',
    key: 'BankOfChina',
    description: '',
    isBigLender: false,
  },
  {
    lenderId: 60,
    lenderName: 'TSB',
    lenderCode: 'TSB',
    key: 'TSB',
    description: '',
    isBigLender: false,
  },
  {
    lenderId: 92,
    lenderName: 'Industrial and Commercial Bank of China',
    lenderCode: 'ICBC',
    key: 'ICBC',
    description: '',
    isBigLender: false,
  },
  {
    lenderId: 34,
    lenderName: 'KiwiBank',
    lenderCode: 'KiwiBank',
    key: 'KiwiBank',
    description: '',
    isBigLender: false,
  },
  {
    lenderId: 91,
    lenderName: 'China Construction Bank',
    lenderCode: 'CCB',
    key: 'CCB',
    description: '',
    isBigLender: false,
  },
];
