export function getReferrerFromQuery(query) {
  const match = query.match(/ref=(.+?)($|&)/);
  if (!match) {
    return;
  }
  return match[1];
}

export function getValueFromQuery(query, key) {
  const match = query.match(new RegExp(`(\\?|&)${key}=(.+?)($|&)`));
  if (!match) {
    return;
  }
  return decodeURIComponent(match[2]);
}

export function getQueryParams(query, keys = []) {
  return keys.reduce((acc, curr) => {
    const value = getValueFromQuery(query, curr);
    if (value) {
      acc[curr] = value;
    }
    return acc;
  }, {});
}

export function buildQueryParameters(params) {
  if (!params) {
    return '';
  }
  return Object.keys(params).reduce((str, key) => {
    if (params[key] === undefined) {
      return str;
    }
    const statement = `${key}=${encodeURIComponent(params[key])}`;
    // eslint-disable-next-line unicorn/explicit-length-check
    if (str.length) {
      return `${str}&${statement}`;
    }
    return `?${statement}`;
  }, '');
}
