import { USERS_API_PATH } from 'shared/constants/apiPaths';
import LocalStorageProxy from 'lib/localStorageProxy';
import { getAccessToken } from 'lib/okta';
import axios, {
  axiosWithAuth,
  returnJSON,
  throwJSON,
  X_OKTAAUTHFLOW,
} from './baseApi';

export const signUp = (scenario) =>
  axios
    .post(
      `${USERS_API_PATH}/sign-up`,
      { ...scenario },
      {
        headers: {
          [X_OKTAAUTHFLOW]: true,
        },
      },
    )
    .then(returnJSON)
    .catch(throwJSON);

export const activateShared = async (clientId, oktaAuth) => {
  const accessToken = await getAccessToken(oktaAuth);
  return axios
    .put(`${USERS_API_PATH}/activate-shared-okta`, {
      clientId,
      accessToken,
    })
    .then(returnJSON)
    .catch(throwJSON);
};

export const activate = async (params, oktaAuth) => {
  const accessToken = await getAccessToken(oktaAuth);
  return axios
    .post(`${USERS_API_PATH}/activate`, {
      ...params,
      accessToken,
    })
    .then(returnJSON)
    .catch(throwJSON);
};

export const setMobile = (scenario, byobTradingName) =>
  axios
    .post(`${USERS_API_PATH}/set-mobile`, { ...scenario, byobTradingName })
    .then(returnJSON);

export const getUserInfo = (isNewLogin) =>
  axiosWithAuth
    .get(`${USERS_API_PATH}/user-info`, { params: { isNewLogin } })
    .then(returnJSON);

export const sendEmailVerificationToken = async (oktaAuth) => {
  const accessToken = await getAccessToken(oktaAuth);
  const headers = { Authorization: `Bearer ${accessToken}` };
  return axiosWithAuth
    .post(`${USERS_API_PATH}/verification-token/send`, {}, { headers })
    .then(returnJSON)
    .catch(throwJSON);
};

export const verifyEmailAddress = (token) =>
  axiosWithAuth
    .post(`${USERS_API_PATH}/email/verify`, { token })
    .then(returnJSON)
    .catch(throwJSON);

export const recoverPassword = (email) =>
  axios
    .post(`${USERS_API_PATH}/password-recovery`, {
      email,
      isBYOB: !!LocalStorageProxy.advisorOrgSlug,
    })
    .then(returnJSON)
    .catch(throwJSON);

export const resendOnlineFactFind = (params) =>
  axios
    .post(`${USERS_API_PATH}/fact-find/resend`, params)
    .then(returnJSON)
    .catch(throwJSON);
