import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input/Input';
import { currencyFormatter } from 'lib/formatters';
import classNames from 'classnames/bind';

import styles from './CurrencyInput.css';

const CurrencyInput = ({
  id,
  action,
  value,
  className,
  placeholder,
  onFocus,
  onBlur,
  onKeyDown,
  onChange,
  dispatchActionOnBlur,
  forceFocus,
  displayOnly,
  disabled,
  maxLength,
}) => (
  <div className={classNames(styles.root, className)}>
    <label
      htmlFor={id}
      className={classNames(styles.icon, { [styles.disabled]: disabled })}
    >
      $
    </label>
    <Input
      id={id}
      maxLength={maxLength || 9}
      pattern='\d*'
      placeholder={placeholder}
      action={action}
      className={styles.input}
      value={value && Math.round(value)} // Integer only for currency input
      formatter={currencyFormatter}
      forceFocus={forceFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      onChange={onChange}
      dispatchActionOnBlur={dispatchActionOnBlur}
      displayOnly={displayOnly}
      disabled={disabled}
    />
  </div>
);

CurrencyInput.propTypes = {
  id: PropTypes.string,
  action: PropTypes.func.isRequired,
  value: PropTypes.number,
  placeholder: PropTypes.string,
  forceFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  dispatchActionOnBlur: PropTypes.bool,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  displayOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
};

CurrencyInput.defaultProps = {
  changeCallbackOnBlur: null,
};

export default CurrencyInput;
