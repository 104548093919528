import {
  INCOME_QUINTILE_RANGES,
  HOUSEHOLD_EXPENDITURE_BY_CATEGORY,
  INDEXATION,
} from 'constants/absHouseholdExpenditure';

function isWithinRange(value, min, max) {
  if (value < min) {
    return false;
  }
  if (max && value > max) {
    return false;
  }
  return true;
}

export function householdIncomeQuintile(grossIncome) {
  if (grossIncome < 0) {
    return 0;
  }
  return INCOME_QUINTILE_RANGES.findIndex((range) =>
    isWithinRange(grossIncome, range.min, range.max),
  );
}

function parseABSValue(value) {
  const match = value.match(/(\*+)(.+)/);
  if (match) {
    return {
      disclaimer: match[1],
      value:
        (parseFloat(match[2]) * INDEXATION * 52) /
        12 /* convert weekly to monthly */,
    };
  }
  return {
    value:
      (parseFloat(value) * INDEXATION * 52) /
      12 /* convert weekly to monthly */,
  };
}

function selectABSValue(absData, category, index) {
  const fullAbsData = HOUSEHOLD_EXPENDITURE_BY_CATEGORY[absData.id];
  const selectedValue = fullAbsData[category];
  if (selectedValue) {
    return index >= 0
      ? parseABSValue(selectedValue[index])
      : parseABSValue(selectedValue);
  }
  return undefined;
}

function sumABSData(absData, category, index) {
  if (Array.isArray(absData)) {
    const selectedValues = absData.map((e) =>
      selectABSValue(e, category, index),
    );
    const summed = selectedValues.reduce((p, c) => {
      if (!c) {
        return p;
      }
      if (p.value) {
        p.value += c.value;
      } else {
        p.value = c.value;
      }
      if (
        !p.disclaimer ||
        (p.disclaimer && c.disclaimer && c.disclaimer.length > p.disclaimer)
      ) {
        p.disclaimer = c.disclaimer;
      }
      return p;
    }, {});
    return summed.value ? summed : undefined;
  }
  return selectABSValue(absData, category, index);
}

export function getValueForAverageHousehold(absData) {
  return sumABSData(absData, 'average');
}

export function getValueForHouseholdShape(absData, shape) {
  return sumABSData(absData, shape);
}

export function getValueForIncomeQuintile(absData, grossIncome) {
  const incomeQuintile = householdIncomeQuintile(grossIncome);
  return sumABSData(absData, 'quintile', incomeQuintile);
}
