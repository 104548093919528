import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import classNames from 'classnames/bind';

import styles from './AnimatedComponent.css';

class AnimatedComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.bool,
    ]),
    id: PropTypes.string,
    onFinish: PropTypes.func,
    afterExitingAnimation: PropTypes.func,
    elementTransitionName: PropTypes.string.isRequired,
    rootTransitionName: PropTypes.string,
    rootEnterTimeout: PropTypes.number,
    rootLeaveTimeout: PropTypes.number,
    elementEnterTimeout: PropTypes.number,
    elementLeaveTimeout: PropTypes.number,
    elementStyle: PropTypes.string,
    currentElementStyle: PropTypes.string,
    rootStyle: PropTypes.string,
    onElementEnter: PropTypes.func,
  };

  static defaultProps = {
    rootTransitionName: '',
    rootEnterTimeout: 300,
    rootLeaveTimeout: 300,
    elementEnterTimeout: 300,
    elementLeaveTimeout: 300,
    elementStyle: styles.element,
    rootStyle: styles.root,
  };

  static childContextTypes = {
    next: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.timeOutId = undefined;
    this.state = {
      currentElement: -1,
    };
  }

  getChildContext() {
    /*
      Provide this function through the React context such that any child may
      move the animation forward by just calling this
    */
    return {
      next: this.moveToNextElement.bind(this),
    };
  }

  componentDidMount() {
    this.moveToNextElement();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.children) {
      this.setState({
        currentElement: 0,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentElement } = this.state;
    const { children } = this.props;
    const numberOfElements = this.numberOfElements();

    if (numberOfElements === 0) {
      return;
    }
    const child =
      numberOfElements === 1
        ? React.Children.only(children)
        : children[currentElement];

    const duration =
      currentElement === -1 || !child ? null : child.props['data-duration'];
    if (prevState.currentElement !== currentElement && duration) {
      this.timeOutId = setTimeout(
        this.moveToNextElement.bind(this, currentElement + 1),
        duration,
      );
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  getCurrentElement() {
    const { currentElement } = this.state;
    const { currentElementStyle } = this.props;

    if (this.isElementWithinRange(currentElement)) {
      const element =
        this.numberOfElements() > 1
          ? this.props.children[currentElement]
          : this.props.children;

      return (
        <div
          id={this.props.id}
          key={`element-${currentElement}`}
          className={classNames(styles.currentElement, currentElementStyle)}
        >
          {element}
        </div>
      );
    }

    return null;
  }

  isElementWithinRange(element) {
    return element >= 0 && element < this.numberOfElements();
  }

  moveToNextElement(nextElementId) {
    const {
      onFinish,
      rootLeaveTimeout,
      afterExitingAnimation,
      onElementEnter,
    } = this.props;
    const nextElement = this.state.currentElement + 1;
    const numberOfElements = this.numberOfElements();
    if (nextElementId && nextElementId === this.state.currentElement) {
      /*
        The user has already propagated the moving forward of the animation.
        This method has already been run! As a result, we should exit.
      */
      return;
    }
    if (nextElement <= numberOfElements) {
      this.clearTimeout();
      this.setState({
        currentElement: nextElement,
      });
      if (onElementEnter) {
        onElementEnter(nextElement);
      }
      if (nextElement === numberOfElements) {
        if (onFinish) {
          onFinish();
        }
        if (afterExitingAnimation) {
          this.timeOutId = setTimeout(afterExitingAnimation, rootLeaveTimeout);
        }
      }
    }
  }

  clearTimeout() {
    if (this.timeOutId) {
      clearTimeout(this.timeOutId);
    }
    this.timeOutId = undefined;
  }

  numberOfElements() {
    if (!this.props.children) {
      return 0;
    }
    return React.Children.count(this.props.children);
  }

  renderElement() {
    const {
      elementTransitionName,
      elementEnterTimeout,
      elementLeaveTimeout,
      elementStyle,
    } = this.props;
    const element = this.getCurrentElement();
    if (element) {
      return (
        <CSSTransitionGroup
          key='elementContainer'
          component='div'
          className={elementStyle}
          transitionName={elementTransitionName}
          transitionEnterTimeout={elementEnterTimeout}
          transitionLeaveTimeout={elementLeaveTimeout}
        >
          {element}
        </CSSTransitionGroup>
      );
    }
    return null;
  }

  render() {
    const {
      rootTransitionName,
      rootEnterTimeout,
      rootLeaveTimeout,
      rootStyle,
    } = this.props;

    return (
      <CSSTransitionGroup
        component='div'
        className={rootStyle}
        transitionName={rootTransitionName}
        transitionEnterTimeout={rootEnterTimeout}
        transitionLeaveTimeout={rootLeaveTimeout}
      >
        {this.renderElement()}
      </CSSTransitionGroup>
    );
  }
}

export default AnimatedComponent;
