import { defineMessages } from 'react-intl';

const messages = defineMessages({
  offsetAccountText: {
    id: 'ProductSummary.offsetAccount.text',
    defaultMessage: 'OFFSET ACCOUNT',
  },
  redrawAvailableText: {
    id: 'ProductSummary.redrawAvailable.text',
    defaultMessage: 'REDRAW AVAILABLE',
  },
  creditCardText: {
    id: 'ProductSummary.creditCard.text',
    defaultMessage: 'CREDIT CARD',
  },
  borrowUpToText: {
    id: 'ProductSummary.borrowUpTo.text',
    defaultMessage: 'BORROW UP TO {maximumLvr, number}%',
  },
  annualFeeText: {
    id: 'ProductSummary.annualFees.text',
    defaultMessage: 'ANNUAL FEES',
  },
  upfrontFeeText: {
    id: 'ProductSummary.upfrontFees.text',
    defaultMessage: 'UPFRONT FEES',
  },
  variableText: {
    id: 'ProductSummary.variable.text',
    defaultMessage: 'VARIABLE',
  },
  fixedText: {
    id: 'ProductSummary.fixed.text',
    defaultMessage: 'FIXED - {term, number}Y',
  },
  paText: {
    id: 'ProductSummary.pa.text',
    defaultMessage: 'RATE P.A',
  },
  paComparisionText: {
    id: 'ProductSummary.paComparision.text',
    defaultMessage: 'COMPARISON**',
  },
  perFrequency: {
    id: 'ProductSummary.perFrequency.text',
    defaultMessage: 'PER {frequency}*',
  },
  pmRepaymentIO: {
    id: 'ProductSummary.pmRepaymentIO.text',
    defaultMessage: 'INTEREST ONLY',
  },
  pmRepaymentPI: {
    id: 'ProductSummary.pmRepaymentPI.text',
    defaultMessage: 'PRINCIPAL + INTEREST',
  },
});

export default messages;
