import { CONTACTS_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export function getContactExpenses(contactId, isLivingExpensesV2Enabled) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/expenses`, {
      params: { isLivingExpensesV2Enabled },
    })
    .then(returnJSON);
}

export function postExpenseForContact(
  contactId,
  data,
  isLivingExpensesV2Enabled,
) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${contactId}/expenses`, {
      ...data,
      isLivingExpensesV2Enabled,
    })
    .then(returnJSON);
}

export function postClientToFamily(client) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${client.contactId}/client`, client)
    .then(returnJSON);
}

export function getContactIncomes(contactId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/incomes`)
    .then(returnJSON);
}

export function postIncomeForContact(contactId, data) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${contactId}/incomes`, data)
    .then(returnJSON);
}

export function putIncomeForContact(contactId, data) {
  return axiosWithAuth
    .put(`${CONTACTS_API_PATH}/${contactId}/incomes/${data.id}`, data)
    .then(returnJSON);
}

export function deleteIncomeForContact(contactId, incomeId) {
  return axiosWithAuth
    .delete(`${CONTACTS_API_PATH}/${contactId}/incomes/${incomeId}`)
    .then(returnJSON);
}

export function getContactAssets(contactId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/assets`)
    .then(returnJSON);
}

export function getContactLiabilities(contactId, loanAppId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/liabilities?loanAppId=${loanAppId}`)
    .then(returnJSON);
}
export function postLiabilityForContact(contactId, data) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${contactId}/liabilities`, data)
    .then(returnJSON);
}

export function postAssetForContact(contactId, data) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${contactId}/assets`, data)
    .then(returnJSON);
}

export function postRealEstateAssetForContact(contactId, data) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${contactId}/real-estate-assets`, data)
    .then(returnJSON);
}

export function getContactClients(contactId, loanAppId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/clients`, { params: { loanAppId } })
    .then(returnJSON);
}

export function getContactAddress(contactId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/addresses`)
    .then(returnJSON);
}

export function getContactEmployments(contactId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/employments`)
    .then(returnJSON);
}

export function postContactEmployment(employment) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/employment`, employment)
    .then(returnJSON);
}

export function postAddressForContact(contactId, data) {
  return axiosWithAuth
    .post(`${CONTACTS_API_PATH}/${contactId}/addresses`, data)
    .then(returnJSON);
}

export function putAddressForContact(contactId, data) {
  return axiosWithAuth
    .put(`${CONTACTS_API_PATH}/${contactId}/addresses`, data)
    .then(returnJSON);
}

export function delAddressForContact(contactId, addressId) {
  return axiosWithAuth
    .delete(`${CONTACTS_API_PATH}/${contactId}/addresses/${addressId}`)
    .then(returnJSON);
}

export function getContactBankConnectCollections(contactId) {
  return axiosWithAuth
    .get(`${CONTACTS_API_PATH}/${contactId}/bank-connect-collections`)
    .then(returnJSON);
}

export function putContactBankCollection(contactId, data) {
  return axiosWithAuth
    .put(
      `${CONTACTS_API_PATH}/${contactId}/bank-connect-collection/${data.id}`,
      data,
    )
    .then(returnJSON);
}

export function getContactBankConnectCollectionRecords(
  contactId,
  collectionIds,
) {
  return axiosWithAuth
    .get(
      `${CONTACTS_API_PATH}/${contactId}/bank-connect-collection-records?collectionIds=${collectionIds}`,
    )
    .then(returnJSON);
}
