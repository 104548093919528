import { EMPLOYMENT_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON, throwJSON } from './baseApi';

export function putEmployment(employment) {
  return axiosWithAuth
    .put(`${EMPLOYMENT_API_PATH}/${employment.id}`, employment)
    .then(returnJSON);
}

export function delEmployment(employmentId) {
  return axiosWithAuth
    .delete(`${EMPLOYMENT_API_PATH}/${employmentId}`)
    .then(returnJSON);
}

export const validateNZBN = (nzbn) => {
  return axiosWithAuth
    .get(`${EMPLOYMENT_API_PATH}/validateNZBN`, {
      params: { nzbn },
    })
    .then(returnJSON, throwJSON);
};
