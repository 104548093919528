/* eslint-disable sonarjs/no-duplicate-string */
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';

import applyOwnPropsChecker from 'lib/applyOwnPropsChecker';
import { dataOfMatchingValueByKey } from 'lib/utils/dataUtils';
import { formatCurrency } from 'lib/intlFormatters';
import { logEvent, EVENTS } from 'lib/amplitude';
import * as propertySelectors from 'selectors/propertySelectors';
import * as fundingSelectors from 'selectors/fundingSelectors';

import { PROPERTY_PRIMARY_PURPOSE_OPTIONS } from 'constants/options';
import { FUNDING_TYPE_AVAILABLE } from 'shared/constants/myCRMTypes/funding';
import { prospectivePropertiesHint } from 'lib/hintHelper';
import { featureFlags } from 'lib/rollout';

import applySection from 'hocs/applySection';
import { accordionPropTypes } from 'types/customPropTypes';
import ApplyItemContainer from 'components/ApplyItemContainer/ApplyItemContainer';
import EditableItem from 'components/EditableItem/EditableItem';
import Button from 'components/Button/Button';
import ApplyAdditionalQuestion from 'components/ApplyAdditionalQuestion/ApplyAdditionalQuestion';

import QuestionStyles from 'components/Question/Question.css';
import ButtonStyles from 'components/Button/Button.css';
import { TO_PURCHASE_SLUG } from 'constants/applyData';

export const messages = defineMessages({
  title: {
    id: 'ToPurchaseApply.title',
    defaultMessage: 'To Purchase',
  },
  titleDescription: {
    id: 'ToPurchaseApply.titleDescription',
    defaultMessage: 'Is there a property you want to purchase?',
  },
  titleDescriptionCompletedLookingToBuy: {
    id: 'ToPurchaseApply.titleDescriptionCompletedLookingToBuy',
    defaultMessage:
      '{suburbCount, plural, =0 {Still Looking} other {{suburbs}}}',
  },
  titleDescriptionCompletedJustRefinancing: {
    id: 'ToPurchaseApply.titleDescriptionCompletedJustRefinancing',
    defaultMessage: 'Just Refinancing',
  },
  headerDescription: {
    id: 'ToPurchaseApply.headerDescription',
    defaultMessage: 'Are you looking to buy a property?',
  },
  headerDescriptionCompletedLookingToBuy: {
    id: 'ToPurchaseApply.headerDescriptionCompletedLookingToBuy',
    defaultMessage: `You're looking to buy {suburbCount, plural, =0 {a property - haven’t found it yet.} one {a property in {suburbs}} other {properties in {suburbs}}}`,
  },
  headerDescriptionCompletedJustRefinancing: {
    id: 'ToPurchaseApply.headerDescriptionCompletedJustRefinancing',
    defaultMessage: 'Are you looking to buy a property?',
  },
  popupTitle: {
    id: 'ToPurchaseApply.popupTitle',
    defaultMessage: 'Purchase a property',
  },
  addProperty: {
    id: 'ToPurchaseApply.addProperty',
    defaultMessage: 'Add a property',
  },
  lookingToBuyProperty: {
    id: 'ToPurchaseApply.lookingToBuyProperty',
    defaultMessage: 'Are you looking to buy a property?',
  },
  lookingToBuyAnotherProperty: {
    id: 'ToPurchaseApply.lookingToBuyAnotherProperty',
    defaultMessage: 'Would you like to add another property to purchase?',
  },
  deposits: {
    id: 'ToPurchaseApply.deposits',
    defaultMessage: 'Do you have a deposit?',
  },
  addDeposit: {
    id: 'ToPurchaseApply.addDeposit',
    defaultMessage: 'Add a deposit',
  },
});

const furtherDecoration = (props) => {
  const {
    intl: { formatMessage },
    lookingToBuyProperty,
    prospectiveProperties,
    isCompleted,
  } = props;

  let postfix = isCompleted ? 'Completed' : '';
  if (isCompleted) {
    const lookingToBuy = lookingToBuyProperty || prospectiveProperties.length;
    postfix += lookingToBuy ? 'LookingToBuy' : 'JustRefinancing';
  }

  const propertieswithSuburbs = prospectiveProperties.filter((p) => !!p.suburb);
  const suburbCount = propertieswithSuburbs.length;
  const suburbs = propertieswithSuburbs.map((p) => p.suburb).join(', ');

  return {
    title: formatMessage(messages.title),
    titleDescription: formatMessage(messages[`titleDescription${postfix}`], {
      suburbs,
      suburbCount,
    }),
    headerDescription: formatMessage(messages[`headerDescription${postfix}`], {
      suburbs,
      suburbCount,
    }),
  };
};

const nextButtonProps = ({
  prospectiveProperties,
  lookingToBuyProperty,
  lookingToBuyAnotherProperty,
}) => ({
  disabled:
    (prospectiveProperties.length && _.isNil(lookingToBuyAnotherProperty)) ||
    (!prospectiveProperties.length && _.isNil(lookingToBuyProperty)),
});

class ToPurchaseApply extends Component {
  static displayName = 'ToPurchaseApply';
  static propTypes = {
    intl: intlShape.isRequired,
    accordionProps: PropTypes.shape(accordionPropTypes).isRequired,
    setMetadata: PropTypes.func.isRequired,
    prospectiveProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
    lookingToBuyProperty: PropTypes.bool,
    lookingToBuyAnotherProperty: PropTypes.bool,
    hasDeposits: PropTypes.bool,
    fundingAvailable: PropTypes.arrayOf(PropTypes.object),
    fundsAvailableSum: PropTypes.number,
    urlPath: PropTypes.string.isRequired,
  };

  static forceVisibility = true;

  onLookingToBuyPropertyClick = (value) => {
    this.props.setMetadata({ lookingToBuyProperty: value });
  };

  onLookingToBuyAnotherPropertyClick = (value) => {
    this.props.setMetadata({ lookingToBuyAnotherProperty: value });
  };

  onHasDepositsClick = (value) => {
    this.props.setMetadata({ hasDeposits: value });
  };

  newProspectivePropertyUrl = () =>
    `${this.props.urlPath}/to-purchase/property/new`;

  newFundingUrl = () =>
    `${this.props.urlPath}/to-purchase/funding/new?type=${FUNDING_TYPE_AVAILABLE}`;

  editPropertyUrl = (id) => `${this.props.urlPath}/to-purchase/property/${id}`;

  editFundingUrl = (id) => `${this.props.urlPath}/to-purchase/funding/${id}`;

  onAddProperty = () => {
    logEvent(EVENTS.ADD_PROPERTY, { section: TO_PURCHASE_SLUG });
  };

  renderHasProspectiveProperties() {
    const { prospectiveProperties } = this.props;
    return (
      <ApplyItemContainer>
        {prospectiveProperties.map((entity, i) => (
          <EditableItem
            leftIcon='sl-custom-court'
            key={i}
            url={this.editPropertyUrl(entity.id)}
            leftLabel={dataOfMatchingValueByKey(
              PROPERTY_PRIMARY_PURPOSE_OPTIONS,
              entity.primaryPurposeId,
              'label',
            )}
            rightLabel={entity.suburb || 'Still Looking'}
            rightDescription={entity.state}
          />
        ))}
      </ApplyItemContainer>
    );
  }

  renderMaybeNoProspectiveProperties() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      lookingToBuyProperty,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasProspectiveProperties'
        label={formatMessage(messages.lookingToBuyProperty)}
        hint={prospectivePropertiesHint}
        action={this.onLookingToBuyPropertyClick}
        value={lookingToBuyProperty}
        disabled={isLocked}
      />
    );
  }

  renderSecondQuestion() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      lookingToBuyAnotherProperty,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasAnotherProspectiveProperties'
        label={formatMessage(messages.lookingToBuyAnotherProperty)}
        hint={prospectivePropertiesHint}
        action={this.onLookingToBuyAnotherPropertyClick}
        value={lookingToBuyAnotherProperty}
        disabled={isLocked}
      />
    );
  }

  renderMaybeNoDeposits() {
    const {
      accordionProps: { isLocked },
      intl: { formatMessage },
      hasDeposits,
    } = this.props;

    return (
      <ApplyAdditionalQuestion
        id='hasDeposits'
        label={formatMessage(messages.deposits)}
        action={this.onHasDepositsClick}
        value={hasDeposits}
        disabled={isLocked}
      />
    );
  }

  renderHasDeposits() {
    const { intl, fundingAvailable, fundsAvailableSum } = this.props;
    return (
      <ApplyItemContainer
        includeTotal={fundingAvailable.length > 1}
        totalTitle='Total Deposit'
        totalValue={formatCurrency(intl)(fundsAvailableSum)}
        key='applyItemContainer'
        smallMargin
      >
        {fundingAvailable.map((entity, i) => (
          <EditableItem
            leftIcon='sl-custom-dollar-bag'
            key={i}
            url={this.editFundingUrl(entity.id)}
            leftLabel={`Deposit - ${entity.name}`}
            leftDescription={entity.description}
            rightLabel={
              entity.value ? formatCurrency(intl)(entity.value) : undefined
            }
          />
        ))}
      </ApplyItemContainer>
    );
  }

  render() {
    const {
      prospectiveProperties,
      lookingToBuyProperty,
      lookingToBuyAnotherProperty,
      hasDeposits,
      fundingAvailable,
      intl: { formatMessage },
      accordionProps: { isLocked },
    } = this.props;

    const showButton =
      (prospectiveProperties.length && lookingToBuyAnotherProperty) ||
      (!prospectiveProperties.length && lookingToBuyProperty);

    const mergeEnabled = featureFlags.goalSetterFactFindMerge.isEnabled();

    return (
      <div id='toPurchaseApply'>
        {prospectiveProperties.length ? (
          <>
            {this.renderHasProspectiveProperties()}
            {this.renderSecondQuestion()}
          </>
        ) : (
          this.renderMaybeNoProspectiveProperties()
        )}
        {showButton && !isLocked && (
          <div>
            <Button
              url={this.newProspectivePropertyUrl()}
              theme='applyNew'
              icon='sl-custom-court'
              onClick={this.onAddProperty}
            >
              {formatMessage(messages.addProperty)}
            </Button>
          </div>
        )}
        {mergeEnabled && (
          <>
            <div className={QuestionStyles.rowWithMarginTop}>
              {fundingAvailable.length > 0
                ? this.renderHasDeposits()
                : this.renderMaybeNoDeposits()}
            </div>
            {(hasDeposits || fundingAvailable.length > 0) && !isLocked && (
              <div>
                <Button
                  url={this.newFundingUrl()}
                  theme='applyNew'
                  icon='sl-custom-dollar-bag'
                  className={ButtonStyles.depositButton}
                >
                  {formatMessage(messages.addDeposit)}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  prospectiveProperties: propertySelectors.prospectiveProperties(state),
  fundingAvailable: fundingSelectors.fundingAvailable(state),
  fundsAvailableSum: fundingSelectors.fundsAvailableSum(state),
});

export default injectIntl(
  connect(
    mapStateToProps,
    null,
    null,
    applyOwnPropsChecker,
  )(
    applySection({
      iconName: 'sl-custom-court',
      furtherDecoration,
      nextButtonProps,
    })(ToPurchaseApply),
  ),
);
