import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import logo from 'assets/loanmarket-logo.svg?name=loanmarketLogo';
import logoInverted from 'assets/loanmarket-logo-rev.svg?name=loanmarketLogoRev';

class LoanMarketLogo extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    inverse: PropTypes.bool,
  };

  static defaultProps = {
    inverse: false,
  };

  render() {
    const { inverse, className } = this.props;

    return (
      <SVGInline className={className} svg={inverse ? logoInverted : logo} />
    );
  }
}

export default LoanMarketLogo;
