import * as actionTypes from 'actions/privacyPolicyActionTypes';
import update from 'immutability-helper';
import { handleActions } from 'redux-actions';

export const initialState = {
  token: null,
  isTokenValid: false,
  clientName: null,
  executingToken: false,
};

const privacyPolicyReducer = handleActions(
  {
    [actionTypes.SET_TOKEN](state, { payload }) {
      return update(state, {
        token: { $set: payload || null },
      });
    },
    [actionTypes.SET_IS_TOKEN_VALID](state, { payload }) {
      return update(state, {
        isTokenValid: { $set: !!payload },
      });
    },
    [actionTypes.SET_CLIENT_NAME](state, { payload }) {
      return update(state, {
        clientName: { $set: payload || null },
      });
    },
    [actionTypes.SET_EXECUTING_TOKEN](state, { payload }) {
      return update(state, {
        executingToken: { $set: !!payload },
      });
    },
  },
  initialState,
);

export default privacyPolicyReducer;
