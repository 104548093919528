import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { generateUniqueID } from 'lib/utils/stringUtils';

import RadioButton from 'components/Button/RadioButton';

import styles from './ButtonList.css';

class RadioButtonList extends Component {
  static propTypes = {
    id: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]),
    direction: PropTypes.string,
    action: PropTypes.func.isRequired,
    onClickDelayed: PropTypes.func,
    className: PropTypes.string,
    theme: PropTypes.string,
    disabled: PropTypes.bool,
    forceFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    isFilterStyle: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      mouseoverItemKey: null,
    };
  }

  onItemMouseEnter = (item) => () => {
    this.setState({
      mouseoverItemKey: item.key,
    });
  };

  onItemMouseLeave = () => {
    this.setState({
      mouseoverItemKey: null,
    });
  };

  onItemClick = () => {
    const { onClickDelayed, onFocus } = this.props;
    onFocus && onFocus(); /* Safari does not recognize click event as focus */
    onClickDelayed && setTimeout(onClickDelayed, 100);
  };

  listItems(items) {
    const {
      id,
      disabled,
      action,
      value,
      className,
      onFocus,
      forceFocus,
      isFilterStyle,
      theme,
    } = this.props;
    const { mouseoverItemKey } = this.state;
    const buttonClass = classNames(styles.button, className);
    return items.map((item, i) => (
      <li
        key={item.key}
        className={styles.item}
        onMouseEnter={isFilterStyle ? this.onItemMouseEnter(item) : null}
        onMouseLeave={isFilterStyle ? this.onItemMouseLeave : null}
      >
        <RadioButton
          id={`${id}--${item.key}`}
          className={buttonClass}
          selected={value === item.value}
          isMouseover={mouseoverItemKey === item.key}
          disabled={disabled}
          action={action}
          onClick={this.onItemClick}
          name={id}
          forceFocus={
            value === undefined
              ? forceFocus && i === 0
              : forceFocus && value === item.value
          }
          onFocus={onFocus}
          value={item.value}
          label={item.label}
          tooltip={item.tooltip}
          isFilterStyle={isFilterStyle}
          theme={theme}
        />
      </li>
    ));
  }

  render() {
    const { id, items, direction, theme, isFilterStyle } = this.props;
    const rootStyle = classNames(styles.root, {
      [styles.row]: direction === 'row',
      [styles[theme]]: !!theme,
      [styles.filterRadioButtonList]: isFilterStyle,
    });

    return (
      <ol id={generateUniqueID('RadioButtonList', id)} className={rootStyle}>
        {this.listItems(items)}
      </ol>
    );
  }
}

RadioButtonList.displayName = 'RadioButtonList';

export default RadioButtonList;
