import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import renderHTML from 'react-render-html';

import _ from 'lodash';

import { API_FIXED_RESULT } from 'shared/constants/interestType';
import messages from 'constants/productDetailFormatters';
import {
  splitStrIntoLines,
  generateUniqueID,
  formatProductName,
} from 'lib/utils/stringUtils';

import {
  formatRate,
  formatCurrency,
  formatPercentage,
} from 'lib/intlFormatters';
import commonMessages from 'constants/commonMessages';
import ProductSection from 'components/ProductSection/ProductSection';
import RepaymentsExplanation from 'components/RepaymentsExplanation/RepaymentsExplanation';
import RepaymentPreferencesForm from 'components/PopupForms/RepaymentPreferencesForm';

import HeaderAndProperties from './HeaderAndPropertiesSection';
import styles from './ProductDetailContent.css';

const MAX_DESCRIPTION_LENGTH = 240;

const ongoingRateVariableSection = (product, formatMessage, intl) => (
  <ProductSection
    key='ongoingRateVariable'
    id={generateUniqueID('ProductDetailContent', 'ongoingRateVariable')}
    title={formatMessage(messages.ongoingRateVariableTitle)}
    extraTitle={[
      formatRate(intl)(product.rates.ongoing.rate),
      formatMessage(messages.perAnnum),
    ]}
    shortDescription={formatMessage(messages.ongoingRateVariableShortDesc, {
      rate: formatRate(intl)(product.rates.ongoing.rate),
    })}
    description={renderHTML(
      formatMessage(messages.ongoingRateVariable, {
        rate: formatRate(intl)(product.rates.ongoing.rate),
      }),
    )}
    classname='fullContent'
    showLessLength={MAX_DESCRIPTION_LENGTH}
  />
);

const initialRateFixedForTermSection = (product, formatMessage, intl) => {
  const {
    rates: {
      initial: { rate, periodYrs },
    },
    fees: { totalUpfront, monthly, annual },
  } = product;
  return (
    <ProductSection
      key='initialRateFixedForTerm'
      id={generateUniqueID('ProductDetailContent', 'initialRateFixedForTerm')}
      title={formatMessage(messages.initialRateFixedForTermTitle, {
        periodYrs,
      })}
      extraTitle={[
        formatMessage(messages.fixedPeriodYear, { periodYrs }),
        formatMessage(messages.perAnnum),
      ]}
      shortDescription={formatMessage(
        messages.initialRateFixedForTermShortDesc,
        {
          productName: formatProductName(product.name),
          rate: formatRate(intl)(rate),
          periodYrs,
        },
      )}
      description={renderHTML(
        formatMessage(messages.initialRateFixedForTerm, {
          productName: formatProductName(product.name),
          rate: formatRate(intl)(rate),
          periodYrs,
          applicationFee: formatCurrency(intl)(totalUpfront),
          ongoingMonthlyFee: formatCurrency(intl)(monthly),
          annualFee: formatCurrency(intl)(annual),
        }),
      )}
      classname='fullContent'
      showLessLength={MAX_DESCRIPTION_LENGTH}
    />
  );
};

const interestOnlyPeriodSection = (
  product,
  formatMessage,
  intl,
  interestOnlyPeriod,
) => {
  const { maxTermInterestOnly } = product.features;
  const selectedPeriodYrs =
    !_.isNaN(interestOnlyPeriod) && interestOnlyPeriod <= 10
      ? interestOnlyPeriod
      : 0;
  const shortDescription =
    maxTermInterestOnly > 0
      ? formatMessage(messages.interestOnlyPeriodShortDesc, {
          periodYrs: maxTermInterestOnly,
          selectedPeriodYrs,
        })
      : renderHTML(formatMessage(messages.isNotInterestOnlyShortDesc));
  const description =
    maxTermInterestOnly > 0
      ? renderHTML(
          formatMessage(messages.interestOnlyPeriod, {
            periodYrs: maxTermInterestOnly,
            selectedPeriodYrs,
          }),
        )
      : renderHTML(formatMessage(messages.isNotInterestOnly));
  return (
    <ProductSection
      key='interestOnlyPeriod'
      id={generateUniqueID('ProductDetailContent', 'interestOnlyPeriod')}
      title={formatMessage(messages.interestOnlyPeriodTitle)}
      extraTitle={[
        formatMessage(messages.fixedPeriodYear, {
          periodYrs: maxTermInterestOnly,
        }),
      ]}
      shortDescription={shortDescription}
      description={description}
      className='fullContent'
      showLessLength={MAX_DESCRIPTION_LENGTH}
    />
  );
};

const todayVariableRateSection = (product, formatMessage, intl) => {
  const {
    name,
    rates: {
      initial: { rate },
    },
    fees: { totalUpfront, monthly, annual },
  } = product;
  return (
    <ProductSection
      key='todayVariableRate'
      id={generateUniqueID('ProductDetailContent', 'todayVariableRate')}
      title={formatMessage(messages.todayVariableRateTitle)}
      extraTitle={[formatRate(intl)(rate), formatMessage(messages.perAnnum)]}
      shortDescription={formatMessage(messages.todayVariableRateShortDesc, {
        productName: formatProductName(name),
        rate: formatRate(intl)(rate),
      })}
      description={renderHTML(
        formatMessage(messages.todayVariableRate, {
          productName: formatProductName(name),
          rate: formatRate(intl)(rate),
          applicationFee: formatCurrency(intl)(totalUpfront),
          ongoingMonthlyFee: formatCurrency(intl)(monthly),
          annualFee: formatCurrency(intl)(annual),
        }),
      )}
      classname='fullContent'
      showLessLength={MAX_DESCRIPTION_LENGTH}
    />
  );
};

const introVariableRateTermSection = (product, formatMessage, intl) => {
  const {
    name,
    rates: {
      initial: { periodYrs, rate },
    },
    fees: { totalUpfront, monthly, annual },
  } = product;
  return (
    <ProductSection
      key='introVariableRateTerm'
      id={generateUniqueID('ProductDetailContent', 'introVariableRateTerm')}
      title={formatMessage(messages.introVariableRateTermTitle, { periodYrs })}
      extraTitle={[formatRate(intl)(rate), formatMessage(messages.perAnnum)]}
      shortDescription={formatMessage(messages.introVariableRateTermShortDesc, {
        productName: formatProductName(name),
        rate: formatRate(intl)(rate),
        periodYrs,
      })}
      description={renderHTML(
        formatMessage(messages.introVariableRateTerm, {
          productName: formatProductName(name),
          rate: formatRate(intl)(rate),
          periodYrs,
          applicationFee: formatCurrency(intl)(totalUpfront),
          ongoingMonthlyFee: formatCurrency(intl)(monthly),
          annualFee: formatCurrency(intl)(annual),
        }),
      )}
      classname='fullContent'
      showLessLength={MAX_DESCRIPTION_LENGTH}
    />
  );
};

const yourComparisonRateSection = (product, formatMessage, intl) => (
  <ProductSection
    key='yourComparisonRate'
    id={generateUniqueID('ProductDetailContent', 'yourComparisonRate')}
    title={formatMessage(messages.yourComparisonRateTitle)}
    extraTitle={[
      formatRate(intl)(product.rates.comparison.rate),
      formatMessage(messages.perAnnum),
    ]}
    shortDescription={formatMessage(messages.yourComparisonRateShortDesc, {
      rate: formatRate(intl)(product.rates.comparison.rate),
    })}
    description={formatMessage(messages.yourComparisonRate, {
      rate: formatRate(intl)(product.rates.comparison.rate),
    })}
    classname='fullContent'
    showLessLength={MAX_DESCRIPTION_LENGTH}
  />
);

const todayStandardVariableRateSection = (product, formatMessage, intl) => {
  const {
    name,
    rates: {
      ongoing: { rate },
    },
    fees: { totalUpfront, monthly, annual },
  } = product;
  return (
    <ProductSection
      key='todayStandardVariableRate'
      id={generateUniqueID('ProductDetailContent', 'todayStandardVariableRate')}
      title={formatMessage(messages.todayStandardVariableRateTitle)}
      extraTitle={[formatRate(intl)(rate), formatMessage(messages.perAnnum)]}
      shortDescription={formatMessage(
        messages.todayStandardVariableRateShortDesc,
        { productName: formatProductName(name), rate: formatRate(intl)(rate) },
      )}
      description={renderHTML(
        formatMessage(messages.todayStandardVariableRate, {
          productName: formatProductName(name),
          rate: formatRate(intl)(rate),
          applicationFee: formatCurrency(intl)(totalUpfront),
          ongoingMonthlyFee: formatCurrency(intl)(monthly),
          annualFee: formatCurrency(intl)(annual),
        }),
      )}
      classname='fullContent'
      showLessLength={MAX_DESCRIPTION_LENGTH}
    />
  );
};

class ProductDetailContent extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    product: PropTypes.object.isRequired,
    lenderDescription: PropTypes.string,
    source: PropTypes.string,
    isLocked: PropTypes.bool,
    hideSelectButton: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isSelectedProduct: PropTypes.bool,
    onSelectProduct: PropTypes.func,
    repaymentType: PropTypes.string,
    isPiRepayment: PropTypes.bool,
    repaymentFrequency: PropTypes.number,
    interestOnlyTerm: PropTypes.string,
  };

  static defaultProps = {
    isLocked: false,
  };

  static DEFAULT_VALUE = 'n/a';

  static renderDescriptionWithLines(desc = '') {
    return splitStrIntoLines(desc).map((paragraph, index) => (
      <p key={`descLine-${index}`} className={styles.paragraph}>
        {paragraph.reduce(
          (p, c, i) => [
            ...p,
            c,
            i !== paragraph.length - 1 && <br key={`descBr-${i}`} />,
          ],
          [],
        )}
      </p>
    ));
  }

  renderRepaymentsDescription() {
    const { repaymentFrequencies } = this.props.product;
    const {
      intl: { formatMessage },
    } = this.props;

    // Format array [A, B, C] to `a, b and c`
    const repaymentFrequency = repaymentFrequencies.reduce(
      (acc, value, index, array) => {
        if (!acc) {
          return value;
        }
        const conjunction =
          index < array.length - 1
            ? ','
            : ` ${formatMessage(messages.andLabel)}`;
        return `${acc}${conjunction}${value}`;
      },
      null,
    );
    return formatMessage(messages.repaymentsDescription, {
      description: repaymentFrequency && repaymentFrequency.toLowerCase(),
    });
  }

  renderDetailedSections = () => {
    const { product, intl, interestOnlyTerm, isPiRepayment } = this.props;
    const { formatMessage } = intl;
    let content;

    if (
      API_FIXED_RESULT.includes(product.rates.initial.type) &&
      !isPiRepayment
    ) {
      content = [
        initialRateFixedForTermSection,
        ongoingRateVariableSection,
        yourComparisonRateSection,
        interestOnlyPeriodSection,
      ];
    } else if (API_FIXED_RESULT.includes(product.rates.initial.type)) {
      content = [
        initialRateFixedForTermSection,
        ongoingRateVariableSection,
        yourComparisonRateSection,
      ];
    } else if (
      !API_FIXED_RESULT.includes(product.rates.initial.type) &&
      product.features.introRate &&
      !isPiRepayment
    ) {
      content = [
        initialRateFixedForTermSection,
        ongoingRateVariableSection,
        interestOnlyPeriodSection,
        yourComparisonRateSection,
      ];
    } else if (
      !API_FIXED_RESULT.includes(product.rates.initial.type) &&
      product.features.introRate
    ) {
      content = [
        introVariableRateTermSection,
        todayStandardVariableRateSection,
        yourComparisonRateSection,
      ];
    } else if (
      !API_FIXED_RESULT.includes(product.rates.initial.type) &&
      !isPiRepayment
    ) {
      content = [
        todayVariableRateSection,
        yourComparisonRateSection,
        interestOnlyPeriodSection,
      ];
    } else if (!API_FIXED_RESULT.includes(product.rates.initial.type)) {
      content = [todayVariableRateSection, yourComparisonRateSection];
    }

    return (
      content && (
        <section className={styles.sectionGroup}>
          {content.map((c) =>
            c(product, formatMessage, intl, interestOnlyTerm),
          )}
        </section>
      )
    );
  };

  onSelectProduct = () => {
    const { isSelectedProduct, onSelectProduct } = this.props;
    onSelectProduct(isSelectedProduct);
  };

  render() {
    const {
      product,
      lenderDescription,
      isSelectedProduct,
      hideSelectButton,
      isLocked,
      isLoggedIn,
      source,
      intl,
      intl: { formatMessage },
      repaymentType,
      repaymentFrequency,
    } = this.props;
    return (
      <div className={styles.root}>
        <HeaderAndProperties
          product={product}
          hideSelectButton={hideSelectButton}
          isSelectedProduct={isSelectedProduct}
          onSelectProduct={this.onSelectProduct}
          sectionStyles={styles.sharpenTopSectionGroup}
          repaymentType={repaymentType}
          repaymentFrequency={repaymentFrequency}
          isLocked={isLocked}
          isLoggedIn={isLoggedIn}
        />
        <div className={styles.contentContainer}>
          <div className={styles.rightColumnContainer}>
            <div className={styles.repaymentPreferencesContainer}>
              <section className={styles.sectionGroup}>
                <RepaymentPreferencesForm
                  product={product}
                  source={source}
                  isLocked={isLocked}
                />
              </section>
            </div>
          </div>
          <div className={styles.leftColumnContainer}>
            {this.renderDetailedSections()}
            {lenderDescription && (
              <section className={styles.sectionGroup}>
                <ProductSection
                  title={formatMessage(messages.lenderNameTitle, {
                    lenderName: product.lender.name,
                  })}
                  description={lenderDescription}
                  className='fullContent'
                  showLessLength={ProductDetailContent.MAX_DESCRIPTION_LENGTH}
                />
              </section>
            )}

            <section className={styles.sectionGroup}>
              <ProductSection
                title={formatMessage(messages.loanToValueRatioTitle)}
                description={ProductDetailContent.renderDescriptionWithLines(
                  formatMessage(messages.loanToValueRatioDescription, {
                    maximumLvr: product.lvr.maximum,
                  }),
                )}
                attributes={[
                  {
                    label: formatMessage(messages.lvrLabel),
                    data: formatPercentage(intl)(product.lvr.maximum / 100),
                  },
                  {
                    label: formatMessage(messages.capitaliseLMILabel),
                    data: formatMessage(
                      product.isLmiCapitalise
                        ? commonMessages.yes
                        : commonMessages.no,
                    ),
                  },
                ]}
              />
              <ProductSection
                title={formatMessage(messages.avalabilityTitle)}
                description={formatMessage(messages.avalabilityDescription, {
                  loanPurposes: product.loanPurposes,
                  regions: product.regions,
                })}
                attributes={[
                  {
                    label: formatMessage(messages.termLabel),
                    data: formatMessage(messages.loanTermText, {
                      minimumLoanTerm: product.loanTerm.minimum,
                      maximumLoanTerm: product.loanTerm.maximum,
                    }),
                  },
                  {
                    label: formatMessage(messages.minLabel),
                    data: formatCurrency(intl)(product.loanAmount.minimum),
                  },
                  {
                    label: formatMessage(messages.maxLabel),
                    data: formatCurrency(intl)(product.loanAmount.maximum),
                  },
                ]}
              />
              <ProductSection
                title={formatMessage(messages.eligibilityTitle)}
                description={formatMessage(messages.eligibilityDescription)}
                attributes={[
                  {
                    label: formatMessage(messages.finalcialsLabel),
                    data: product.documentation,
                  },
                  {
                    label: formatMessage(messages.creditHistoryLabel),
                    data: formatMessage(
                      product.features.nonConforming
                        ? commonMessages.nonConfirming
                        : commonMessages.confirming,
                    ),
                  },
                  {
                    label: formatMessage(messages.genuineSavingLabel),
                    data: formatMessage(
                      product.features.noGenuineSavings
                        ? commonMessages.nonRequired
                        : commonMessages.required,
                    ),
                  },
                ]}
              />
            </section>
            <section className={styles.sectionGroup}>
              <ProductSection
                title={formatMessage(messages.offsetAccountTitle)}
                description={formatMessage(messages.offsetAccountDescription)}
                attributes={[
                  {
                    label: formatMessage(messages.offsetAccountLabel),
                    data: product.features.offsetAccount,
                    isText: false,
                  },
                ]}
              />
              <ProductSection
                title={formatMessage(messages.redrawFacilityTitle)}
                description={formatMessage(messages.redrawFacilityDescription)}
                attributes={[
                  {
                    label: formatMessage(messages.redrawFacilityLabel),
                    data: product.features.redrawFacility,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.minRedrawLabel),
                    data: formatCurrency(intl)(product.redraw.minimum),
                  },
                  {
                    label: formatMessage(messages.redrawFeeLabel),
                    data: formatCurrency(intl)(product.redraw.fee),
                  },
                ]}
              />
            </section>
            <section className={styles.sectionGroup}>
              <ProductSection
                title={formatMessage(messages.bankingFeaturesTitle)}
                description={renderHTML(
                  formatMessage(messages.bankingFeaturesDescription),
                )}
                attributes={[
                  {
                    label: formatMessage(messages.offsetAccountLabel),
                    data: product.features.offsetAccount,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.redrawAvailableLabel),
                    data: product.features.redrawFacility,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.creaditCardLabel),
                    data: product.features.creditCard,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.internetBankingLabel),
                    data: product.features.internetBanking,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.telephoneBankingLabel),
                    data: product.features.telephoneBanking,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.bpayOptionLabel),
                    data: product.features.bpayOption,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.portabilityOfLoanLabel),
                    data: product.features.portablity,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.professionalPackLabel),
                    data: product.features.professionalPack,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.checkAccountLabel),
                    data: product.features.chequeAccount,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.directSalaryCreditLabel),
                    data: product.features.directSalaryCredit,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.freeTransactionsLabel),
                    data: product.features.freeTransactions > 0,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.minimumRedrawLabel),
                    data: product.redraw.minimum > 0,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.redrawFeeLabel),
                    data: product.redraw.fee !== 0,
                    isText: false,
                  },
                ]}
              />
              <ProductSection
                title={formatMessage(messages.repaymentsTitle)}
                description={this.renderRepaymentsDescription()}
                attributes={[
                  {
                    label: formatMessage(messages.interestOnlyOptionLabel),
                    data: product.features.maxTermInterestOnly > 0,
                    isText: false,
                  },
                  {
                    label: formatMessage(messages.maxInterestOnlyLabel),
                    data: formatMessage(messages.maxTermInterestOnlyText, {
                      maxTermInterestOnly: product.features.maxTermInterestOnly,
                    }),
                  },
                ]}
              />
            </section>
            <section className={styles.sectionGroup}>
              <ProductSection
                title={formatMessage(messages.upfrontFeesTitle)}
                description={formatMessage(
                  messages.upfrontFeesAssociatedDescription,
                )}
                attributes={[
                  {
                    label: formatMessage(messages.totalLabel),
                    data: formatCurrency(intl)(product.fees.totalUpfront),
                  },
                  {
                    label: formatMessage(messages.applicationLabel),
                    data: formatCurrency(intl)(product.fees.application),
                  },
                  {
                    label: formatMessage(messages.settlementLabel),
                    data: formatCurrency(intl)(product.fees.settlement),
                  },
                  {
                    label: formatMessage(messages.legalLabel),
                    data: formatCurrency(intl)(product.fees.legal),
                  },
                  {
                    label: formatMessage(messages.valuationLabel),
                    data: formatCurrency(intl)(product.fees.valuation),
                  },
                  {
                    label: formatMessage(messages.establishmentLabel),
                    data: formatCurrency(intl)(product.fees.establishment),
                  },
                ]}
              />
              <ProductSection
                title={formatMessage(messages.ongoingFeesTitle)}
                description={formatMessage(
                  messages.monthlyAndAnnualFeesDescription,
                )}
                attributes={[
                  {
                    label: formatMessage(messages.totalLabel),
                    data: formatCurrency(intl)(product.fees.totalOngoing),
                  },
                  {
                    label: formatMessage(messages.annualFeeLabel),
                    data: formatCurrency(intl)(product.fees.annual),
                  },
                  {
                    label: formatMessage(messages.monthlyFeeLabel),
                    data: formatCurrency(intl)(product.fees.monthly),
                  },
                ]}
              />
              <ProductSection
                title={formatMessage(messages.exitFeesTitle)}
                description={formatMessage(messages.feesAssociatedDescription)}
                attributes={[
                  {
                    label: formatMessage(messages.totalLabel),
                    data: formatCurrency(intl)(
                      product.fees.exit + product.fees.switchToFixed,
                    ),
                  },
                  {
                    label: formatMessage(messages.exitLabel),
                    data: formatCurrency(intl)(product.fees.exit),
                  },
                  {
                    label: formatMessage(messages.switchToFixedLabel),
                    data: formatCurrency(intl)(product.fees.switchToFixed),
                  },
                ]}
              />
            </section>
            <section className={styles.sectionGroup}>
              <ProductSection
                title={formatMessage(messages.notesTitle)}
                description={ProductDetailContent.renderDescriptionWithLines(
                  product.notes.general,
                )}
                className='notes'
              />
              <ProductSection
                title={formatMessage(messages.lvrNotesTitle)}
                description={ProductDetailContent.renderDescriptionWithLines(
                  product.notes.lvr,
                )}
                className='notes'
              />
              <ProductSection
                title={formatMessage(messages.notesTitle)}
                description={ProductDetailContent.renderDescriptionWithLines(
                  product.notes.breakCondition,
                )}
                className='notes'
              />
              <ProductSection
                title={formatMessage(messages.feeNotesTitle)}
                description={ProductDetailContent.renderDescriptionWithLines(
                  product.notes.ongoingFee,
                )}
                className='notes'
              />
            </section>
          </div>
        </div>
        <RepaymentsExplanation />
      </div>
    );
  }
}

export default injectIntl(ProductDetailContent);
