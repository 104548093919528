import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as structureSelectors from 'selectors/structureSelectors';

import { DEFAULT_LOAN_YEAR } from 'shared/constants/defaults';
import { REPAYMENT_TYPES } from 'shared/constants/repaymentTypes';

import { STRUCTURE_FREQUENCY_OPTIONS } from 'constants/options';

import { MONTHLY } from 'shared/constants/options';

import { yearsOptions } from 'lib/utils/stringUtils';

import structureActions from 'actions/structureActions';
import productsActions from 'actions/productsActions';

import Question from 'components/Question/Question';
import CurrencyInputWithSelection from 'components/CurrencyInputWithSelection/CurrencyInputWithSelection';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import Selection from 'components/Selection/Selection';
import RepaymentHeaderDisplay from 'components/RepaymentsExplanation/RepaymentHeaderDisplay';
import Form from 'components/Form/Form';

class RepaymentPreferencesForm extends Component {
  static propTypes = {
    requestProductDetail: PropTypes.func.isRequired,
    saveStructure: PropTypes.func,
    product: PropTypes.object,
    isLocked: PropTypes.bool.isRequired,
    source: PropTypes.string,
    structure: PropTypes.object,
    intl: intlShape.isRequired,
  };

  onStructureChange = (field) => (val) => {
    const {
      structure,
      saveStructure,
      requestProductDetail,
      product,
      source,
      isLocked,
    } = this.props;
    const newStructure = {
      ...structure,
      ...{ [field]: val },
    };
    const shouldSaveToMyCRM =
      !isLocked && ['apply', 'dashboard'].includes(source);
    saveStructure({ structure: newStructure, shouldSaveToMyCRM });
    requestProductDetail({ productId: product.id });
  };

  render() {
    const {
      product,
      intl: { formatMessage },
      structure: { loanAmount, loanTerm, repaymentType, repaymentFrequency },
      isLocked,
      source,
    } = this.props;
    const disabled = isLocked && source === 'apply';

    return (
      <Form id='repaymentPreferencesForm' onSubmit={this.onSubmit}>
        <RepaymentHeaderDisplay
          repaymentInitial={product.repaymentInitial}
          repaymentFrequency={repaymentFrequency}
          repaymentType={repaymentType}
          theme='repaymentForm'
        />
        <Question
          id='loanAmountAndTermFilter'
          label='Loan amount and term'
          className='comparePage'
        >
          <CurrencyInputWithSelection
            id='loanAmountAndYear'
            dispatchActionOnBlur
            value={loanAmount}
            action={this.onStructureChange('loanAmount')}
            selectionAction={this.onStructureChange('loanTerm')}
            selectionValue={loanTerm}
            selectionItems={yearsOptions(formatMessage)}
            selectionDefault={DEFAULT_LOAN_YEAR}
            disabled={disabled}
          />
        </Question>
        <Question
          id='repaymentType'
          label='repayment type'
          className='comparePage'
        >
          <RadioButtonList
            id='repaymentType'
            value={repaymentType}
            action={this.onStructureChange('repaymentType')}
            items={REPAYMENT_TYPES}
            className='filterRadioButton'
            isFilterStyle
            disabled={disabled}
          />
        </Question>
        <Question
          id='repaymentFrequency'
          label='repayment frequency'
          className='comparePage'
        >
          <Selection
            action={this.onStructureChange('repaymentFrequency')}
            value={repaymentFrequency}
            items={STRUCTURE_FREQUENCY_OPTIONS}
            selectionDefault={MONTHLY}
            disabled={disabled}
          />
        </Question>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  structure: structureSelectors.workingStructure(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveStructure: structureActions.saveStructure,
      requestProductDetail: productsActions.requestProductDetail,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RepaymentPreferencesForm),
);
