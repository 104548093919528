export const SET_CLIENT_IS_DEPENDENT = 'setClientIsDependent';
export const SET_CLIENT_PREFERRED_NAME = 'setClientPreferredName';
export const SET_CLIENT_PREVIOUS_NAME = 'setClientPreviousName';
export const SET_CLIENT_MOTHERS_MAIDEN_NAME = 'setClientMothersMaidenName';
export const SET_CLIENT_IS_CITIZEN_AND_RESIDENT =
  'setClientIsCitizenAndResident';
export const SET_CLIENT_MARITAL_STATUS_ID = 'setClientMaritalStatusId';
export const SET_CLIENT_TITLE = 'setClientTitle';
export const SET_CLIENT_FIRST_NAME = 'setClientFirstName';
export const SET_CLIENT_MIDDLE_NAME = 'setClientMiddleName';
export const SET_CLIENT_LAST_NAME = 'setClientLastName';
export const SET_CLIENT_MOBILE = 'setClientMobile';
export const SET_CLIENT_EMAIL = 'setClientEmail';
export const SET_CLIENT_AGE = 'setClientAge';
export const SET_CLIENT_AGE_UNIT = 'setClientAgeUnit';
export const SET_CLIENT_DOB_YEAR = 'setClientDobYear';
export const SET_CLIENT_DOB_MONTH = 'setClientDobMonth';
export const SET_CLIENT_DOB_DAY = 'setClientDobDay';
export const SET_CLIENT_GENDER = 'setClientGender';
export const SET_CLIENT_CITIZENSHIP_ID = 'setClientCitizenshipId';
export const SET_CLIENT_COUNTRY_OF_RESIDENCY_ID =
  'setClientCountryOfResidencyId';
export const SET_CLIENT_IS_RESIDENT = 'setClientIsResident';
export const SET_CLIENT_RESIDENCY_ID = 'setClientResidencyId';
export const SET_CLIENT_IS_COAPPLICANT = 'setClientIsCoapplicant';
export const SET_CLIENT_ROLE = 'setClientRole';

export const SET_CLIENT_ERROR = 'setClientError';
