import {
  DOCUMENT_CATEGORY_IDENTITY_KEY,
  DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY,
  DOCUMENT_CATEGORY_BANK_STATEMENTS_KEY,
  DOCUMENT_CATEGORY_OTHER_DOCUMENTS_KEY,
} from 'shared/constants/myCRMTypes/documentsAU';

export const DOCUMENT_CATEGORY_IDENTITY = {
  key: DOCUMENT_CATEGORY_IDENTITY_KEY,
  title: 'Identity Documents',
  name: 'Identity',
  required: false,
  description: 'Please upload at least two current forms of identification.',
  tooltip: `For example: a Driver’s Licence, Passport or Medicare Card.Proof Of Age Card, University Student Card etc), Citizenship certificate, Centrelink Pension Card or a Utilities Bill that is less than three months old.`,
};

export const DOCUMENT_CATEGORY_PROOF_OF_INCOME = {
  key: DOCUMENT_CATEGORY_PROOF_OF_INCOME_KEY,
  title: 'Proof Of Income',
  name: 'Proof of Income',
  required: false,
  description: 'Please upload documents that provide proof of income.',
  tooltip: `For example: a recent Payslip, a Tax Return or a letter from your employer to confirm that you’re employed by them and earning income.
     <br/>If you’re self employed, try showing us a Tax Return or a Business Tax Return, Profit and Loss Statement no older than 18 months.`,
};

export const DOCUMENT_CATEGORY_BANK_STATEMENTS = {
  key: DOCUMENT_CATEGORY_BANK_STATEMENTS_KEY,
  title: 'Bank Statements',
  name: 'Bank Statement',
  required: false,
  description:
    'Please provide statements from all your bank and credit card accounts for the last 3 months.',
  tooltip:
    'For example: Savings Account Statements, Credit Card Statements, Superannuation Statements etc.',
};

export const DOCUMENT_CATEGORY_OTHER_DOCUMENTS = {
  key: DOCUMENT_CATEGORY_OTHER_DOCUMENTS_KEY,
  title: 'Other Documents',
  name: 'Other',
  required: false,
  description: `Upload any documents that don’t fit in the other categories here.`,
  tooltip: `For example: Anything from Utility Statements (Electricity, Gas, Water, Phone etc) to a Family Trust deed.
      <br/>Basically, if you’re not sure where to upload a document, just upload it here.`,
};

export const DOCUMENT_CATEGORIES = [
  DOCUMENT_CATEGORY_BANK_STATEMENTS,
  DOCUMENT_CATEGORY_PROOF_OF_INCOME,
  DOCUMENT_CATEGORY_IDENTITY,
  DOCUMENT_CATEGORY_OTHER_DOCUMENTS,
];
