import lenders from 'shared/lib/lenderHelper';
/* panel of LM partner lenders in order, which does not necessarily sync with products in mycrm (that's why we have separate files)
it's super important that they appear on homepage */
export const AU_LENDERS = [
  // Priority 1
  'ANZ',
  'CBA',
  // Priority 2
  'NAB',
  'Westpac',
  'LoanMarketGO',
  'StGeorge',
  'BOM',
  'BOQ',
  'Bankwest',
  'BankSA',
  // Priority 4
  'AdelaideBank',
  'AMP',
  'Bankfirst',
  'BB',
  'Bluestone',
  'FirstMac',
  'INGDirect',
  'LibertyFinancial',
  'MacquarieBank',
  'PepperMoney',
  // Priority 5
  // 'AFM',
  'emoney',
  'BOS',
  'BetterChoice',
  'BMM',
  'HeritageBank',
  'MEBank',
  'NowFinance',
  'Resimac',
  'SocietyOne',
  'SuncorpBank',
  'Thinktank',
  'LaTrobe',
  // Priority none
  'AxsessToday',
  'BankAustralia',
  'BankOfChina',
  'CapitalFinance',
  'Citibank',
  'FLEXI',
  'GetCapital',
  'GHL',
  'HomeStart',
  'HSF',
  'JudoCapital',
  'Keystart',
  'Latitude',
  'MetroFinance',
  'MKMCapital',
  'MortgageEzy',
  'Moneyplace',
  'Moula',
  'MyStateBank',
  'ParamountMortgage',
  'PeoplesChoice',
  'PoliceAndNurse',
  'PrimeCapital',
  'Ratesetter',
  'Wisr',
  'virgin',
  'pnb',
];

export const NZ_LENDERS = [
  'ANZ',
  'AsapFinance',
  'ASB',
  'Avanti',
  'Basecorp',
  'Bluestone',
  'BNZ',
  'CFML',
  'CressidaCapital',
  'DBR',
  'FirstMortgageTrust',
  'HeartlandBank',
  'LibertyFinancial',
  'Resimac',
  'SBSBank',
  'Select',
  'SouthernCross',
  'Sovereign',
  'TheCooperativeBank',
  'Westpac',
  'BankOfChina',
  'TSB',
  'ICBC',
  'KiwiBank',
  'CCB',
];

export const lmLenders = (countryCode) => {
  switch (countryCode) {
    case 'AU':
      return AU_LENDERS;
    case 'NZ':
      return NZ_LENDERS;
    default:
      return AU_LENDERS.concat(NZ_LENDERS);
  }
};

export const lmLenderKeys = (countryCode) => {
  return lenders.userPanel
    .map((l) => l.key)
    .sort((a, b) => {
      const priorityList = countryCode === 'NZ' ? NZ_LENDERS : AU_LENDERS;
      const aIndex = priorityList.findIndex((key) => key === a);
      const bIndex = priorityList.findIndex((key) => key === b);

      if (aIndex === -1) {
        return 1;
      }
      if (bIndex === -1 || aIndex < bIndex) {
        return -1;
      }
      if (aIndex > bIndex) {
        return 1;
      }
      return 0;
    });
};
