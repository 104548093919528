import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/depositTypeActions';

export const initialState = {
  depositTypes: [],
};

const depositTypeReducer = handleActions(
  {
    [actionTypes.SET_DEPOSIT_TYPES](state, action) {
      return { ...state, depositTypes: action.payload };
    },
  },
  initialState,
);

export default depositTypeReducer;
