import { CLIENTS_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export const postClient = (client) => ({
  ...client,
  loanApplicationId: 1,
  id: Math.round(Math.random() * 1000),
});

export function getClient(clientId) {
  return axiosWithAuth.get(`${CLIENTS_API_PATH}/${clientId}`).then(returnJSON);
}

export function putClient(clientId, data) {
  return axiosWithAuth
    .put(`${CLIENTS_API_PATH}/${clientId}`, data)
    .then(returnJSON);
}

export function delClient(clientId) {
  return axiosWithAuth.delete(`${CLIENTS_API_PATH}/${clientId}`);
}

export function postExpenseForClient(expense) {
  return axiosWithAuth
    .post(`${CLIENTS_API_PATH}/${expense.clientId}/expenses`, expense)
    .then(returnJSON);
}

export function getLoanApplicationStatus(clientId) {
  return axiosWithAuth
    .get(`${CLIENTS_API_PATH}/${clientId}/application-status`)
    .then(returnJSON);
}

export function deleteAsCoapplicant(coapplicant, loanApplicationId) {
  return axiosWithAuth
    .put(`${CLIENTS_API_PATH}/${coapplicant.clientId}`, {
      ...coapplicant,
      isCoapplicant: false,
      loanApplicationId,
    })
    .then(returnJSON);
}

export function postAssetForClient(asset) {
  return axiosWithAuth
    .post(`${CLIENTS_API_PATH}/${asset.clientId}/assets`, asset)
    .then(returnJSON);
}

export function postRealEstateAssetForClient(asset) {
  return axiosWithAuth
    .post(`${CLIENTS_API_PATH}/${asset.clientId}/real-estate-assets`, asset)
    .then(returnJSON);
}

export function postLiabilityForClient(liability) {
  return axiosWithAuth
    .post(`${CLIENTS_API_PATH}/${liability.clientId}/liabilities`, liability)
    .then(returnJSON);
}

export function getEmploymentsForClient(clientId) {
  return axiosWithAuth
    .get(`${CLIENTS_API_PATH}/${clientId}/employments`)
    .then(returnJSON);
}

export function postEmploymentForClient(employment) {
  return axiosWithAuth
    .post(`${CLIENTS_API_PATH}/${employment.clientId}/employments`, employment)
    .then(returnJSON);
}

export function getTitleOptions() {
  return axiosWithAuth
    .get(`${CLIENTS_API_PATH}/options/title`)
    .then(returnJSON);
}
