import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import DateSelector from 'components/DateSelector/DateSelector';

function CustomDateSelector(props) {
  const [day, updateDay] = useState();
  const [month, updateMonth] = useState();
  const [year, updateYear] = useState();

  useEffect(() => {
    if (props.value) {
      const date = moment(props.value);
      updateDay(date.date());
      updateMonth(date.month());
      updateYear(date.year());
    }
  }, [props.value]);

  useEffect(() => {
    if (day && month && year) {
      const date = moment({ y: year, M: month, d: day });
      props.action(date.format('YYYY-MM-DD[T]HH:mm:ss'));
    }
  }, [day, month, year]);

  return (
    <DateSelector
      type={props.reversed ? 'dateReversed' : 'date'}
      dayAction={updateDay}
      day={day}
      monthAction={updateMonth}
      month={month}
      yearAction={updateYear}
      year={year}
      onBlur={props.onBlur}
      minDate={moment().subtract(10, 'years')}
      maxDate={moment().add(10, 'years')}
      yearsOrder='descending'
    />
  );
}

CustomDateSelector.propTypes = {
  value: PropTypes.string,
  action: PropTypes.func,
  onBlur: PropTypes.func,
  reversed: PropTypes.bool,
};

export default CustomDateSelector;
