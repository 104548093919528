import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';
import * as applicationSelectors from 'selectors/applicationSelectors';
import { DASHBOARD_BASE_PATH } from 'shared/constants/paths';

import ImageMessage from 'components/AnimationSequences/ImageMessage';
import LocalExpertSvg from 'assets/inbetweeners/local_expert.svg';

export const mapStateToProps = (state) => ({
  isCustomerCare: applicationSelectors.isBrokerCustomerCare(state),
  advisor: state.advisor,
});

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setNextPath: UIActions.setNextPath,
      startAnimationSequence: UIActions.startAnimationSequence,
      sendLoanApplicationForReview:
        loanApplicationActions.sendLoanApplicationForReview,
      setApplicationTested: UIActions.setApplicationTested,
    },
    dispatch,
  );

export const mergeProps = (stateProps, dispatchProps, props) => ({
  ...props,
  id: 'WantPreApproval',
  heading: `${
    stateProps.isCustomerCare ? 'They' : stateProps.advisor.preferredName
  } will review your profile and organise fast pre-approval.`,
  primaryButtonText: 'Get fast pre-approval',
  primaryButtonAction: () => {
    dispatchProps.sendLoanApplicationForReview();
    dispatchProps.setApplicationTested(true);
    dispatchProps.setNextPath(DASHBOARD_BASE_PATH);
    dispatchProps.startAnimationSequence([
      '/ok-emphasis',
      '/send-for-review',
      '/will-find-options',
      '/expect-a-call',
    ]);
  },
  secondaryButtonText: 'I’ll do it later',
  secondaryButtonIcon: 'mi-arrow-with-circle-right',
  secondaryButtonAction: () => {
    dispatchProps.setNextPath(DASHBOARD_BASE_PATH);
    dispatchProps.startAnimationSequence(['/ok-emphasis']);
  },
  image: stateProps.isCustomerCare ? LocalExpertSvg : stateProps.advisor.imgUrl,
  isStatic: !stateProps.isCustomerCare,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ImageMessage);
