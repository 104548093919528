import { DOCUMENT_ERROR_TYPES } from './documents';
import UploadDocSvg from 'assets/icons/uploadDoc.svg';

export const RESOURCES_BY_TYPE = {
  [DOCUMENT_ERROR_TYPES.INVALID_FILE]: {
    confirmActionText: 'Try again',
    cancelActionText: 'Cancel',
    icon: UploadDocSvg,
  },
};
