import {
  DOCUMENT_CATEGORY_IDENTIFICATION_KEY,
  DOCUMENT_CATEGORY_EQUITY_KEY,
  DOCUMENT_CATEGORY_SECURITY_KEY,
  DOCUMENT_CATEGORY_INCOME_KEY,
  DOCUMENT_CATEGORY_OTHERS_KEY,
} from 'shared/constants/myCRMTypes/documentsNZ';

export const DOCUMENT_CATEGORY_IDENTIFICATION = {
  key: DOCUMENT_CATEGORY_IDENTIFICATION_KEY,
  title: 'Identification',
  name: 'Identification',
  required: false,
  // FIXME: Check with BA/UX about the description and tooltip
  description: 'Please upload at least one current identification.',
  tooltip:
    'For example: New Zealand or overseas passport, firearms licence, driver licence etc',
};

export const DOCUMENT_CATEGORY_EQUITY = {
  key: DOCUMENT_CATEGORY_EQUITY_KEY,
  title: 'Equity / Bank Statements',
  name: 'Equity / Bank Statements',
  required: false,
  // FIXME: Check with BA/UX about the description and tooltip
  description:
    'Please upload at least one document that provide proof of any assets or liabilities you have with financial institutions.',
  tooltip:
    'For example: personal current account statements, credit card statements, kiwisaver confirmation etc.',
};

export const DOCUMENT_CATEGORY_SECURITY = {
  key: DOCUMENT_CATEGORY_SECURITY_KEY,
  title: 'Security',
  name: 'Security',
  required: false,
  // FIXME: Check with BA/UX about the description and tooltip
  description: 'Please upload at least one security document.',
  tooltip:
    'For example: sale and purchase agreement, building report, registered valuation etc.',
};

export const DOCUMENT_CATEGORY_INCOME = {
  key: DOCUMENT_CATEGORY_INCOME_KEY,
  title: 'Income',
  name: 'Income',
  required: false,
  // FIXME: Check with BA/UX about the description and tooltip
  description:
    'Upload latest payslips or a tenancy agreement (if you receive rental income).',
  tooltip:
    'For example: payslips, tenancy agreement, letter from employer confirming remuneration, title and length of service etc',
};

export const DOCUMENT_CATEGORY_OTHERS = {
  key: DOCUMENT_CATEGORY_OTHERS_KEY,
  title: 'Other Documents',
  name: 'Other',
  required: false,
  // FIXME: Check with BA/UX about the description and tooltip
  description:
    'Upload any documents that don’t fit in the other categories here.',
  tooltip: 'For example: signed declaration form, superannuation balance etc',
};

export const DOCUMENT_CATEGORIES = [
  DOCUMENT_CATEGORY_IDENTIFICATION,
  DOCUMENT_CATEGORY_EQUITY,
  DOCUMENT_CATEGORY_SECURITY,
  DOCUMENT_CATEGORY_INCOME,
  DOCUMENT_CATEGORY_OTHERS,
];
