/**
 *  This script injects listeners to handle requests from remote domains.
 *  Currently this is simply only handling iframe resizing requests.
 */

const resizeIntervals = {};

window.addEventListener('message', (e) => {
  if (!e.data) {
    return;
  }
  if (e.data.action !== 'resize') {
    return;
  }
  if (!e.data.target || typeof e.data.target !== 'string') {
    return;
  }
  const origin = e.origin === 'null' ? '*' : e.origin;
  const { target, rate } = e.data;
  if (resizeIntervals[target]) {
    clearInterval();
  }
  resizeIntervals[target] = setInterval(() => {
    e.source.postMessage(
      {
        height: document.body.offsetHeight,
        target,
      },
      origin,
    );
  }, rate || 500);
});
