import { defineMessages } from 'react-intl';

export const LOAN_TYPE_FIXED_VALUE = 'fixed';
export const LOAN_TYPE_VARIABLE_VALUE = 'variable';
export const LOAN_TYPE_BOTH_VALUE = 'all';

const LOAN_TYPES = [
  {
    key: 'fixed',
    value: LOAN_TYPE_FIXED_VALUE,
    ...defineMessages({
      label: {
        id: 'loanTypes.fixed.label',
        defaultMessage: 'Fixed',
      },
      hint: {
        id: 'loanTypes.fixed.hint',
        defaultMessage:
          'Lock in your loan repayments by fixing your interest rate for 1–5 years or more. Fixed loans generally are less flexible than variable rate loans.',
      },
    }),
  },
  {
    key: 'variable',
    value: LOAN_TYPE_VARIABLE_VALUE,
    ...defineMessages({
      label: {
        id: 'loanTypes.variable.label',
        defaultMessage: 'Variable',
      },
      hint: {
        id: 'loanTypes.variable.hint',
        defaultMessage:
          'Variable rate loans have interest rates that fluctuate up and down, generally in step with the RBA official cash rate. These are often better featured, more flexible loans than fixed loans.',
      },
    }),
  },
  {
    key: 'both',
    value: LOAN_TYPE_BOTH_VALUE,
    ...defineMessages({
      label: {
        id: 'loanTypes.both.label',
        defaultMessage: 'Both',
      },
      hint: {
        id: 'loanTypes.both.hint',
        defaultMessage:
          'Leave both selected and we’ll show you everything. You can also split many loans so that you have both fixed and variable components at the same time.',
      },
    }),
  },
];

export const formatLoanTypes = (formatMessage) =>
  LOAN_TYPES.map((l) => ({
    ...l,
    hint: formatMessage(l.hint),
    label: formatMessage(l.label),
  }));

export default LOAN_TYPES;
