import React from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import styles from './Pop.css';

function Pop(props) {
  const { children, ...rest } = props;

  return (
    <CSSTransitionGroup
      component='div'
      transitionName={styles}
      transitionAppear
      transitionAppearTimeout={300}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={0}
      {...rest}
    >
      {props.children}
    </CSSTransitionGroup>
  );
}

Pop.propTypes = {
  children: PropTypes.node,
};

export default Pop;
