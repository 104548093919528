import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classNames from 'classnames';

import delay from 'hocs/delay';

import styles from './AnimatedList.css';

class AnimatedChecklist extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onFinish: PropTypes.func,
    itemTransitionTimeMs: PropTypes.number,
    transitionName: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    itemTransitionTimeMs: 700,
    transitionName: 'flyUp',
  };

  static renderItem(item, i) {
    return (
      <li className={styles.item} key={`AnimatedItem__${i}`}>
        {item}
      </li>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      numberToDisplay: 0,
    };
  }

  componentDidMount() {
    /*
      Timeout is neccessary for CSSTransitionGroup to animate the entrance of the first element
    */
    this.setProgressTimer(0);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.itemTransitionTimeMs < this.props.itemTransitionTimeMs) {
      this.setProgressTimer(nextProps.itemTransitionTimeMs);
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  setProgressTimer(wait) {
    this.timeout = setTimeout(this.progressChecklist.bind(this), wait);
  }

  progressChecklist() {
    const { items, onFinish, itemTransitionTimeMs } = this.props;
    const { numberToDisplay } = this.state;
    const next = numberToDisplay + 1;

    this.setState({ numberToDisplay: next });
    if (next < items.length) {
      this.setProgressTimer(itemTransitionTimeMs);
    } else if (onFinish) {
      setTimeout(onFinish, itemTransitionTimeMs);
    }
  }

  render() {
    const { items, transitionName, className } = this.props;
    const { numberToDisplay } = this.state;
    const itemsToDisplay = items
      .slice(0, numberToDisplay)
      .map((item, i) => AnimatedChecklist.renderItem(item, i));

    return (
      <CSSTransitionGroup
        component='ol'
        transitionName={transitionName}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={0}
        className={classNames(styles.root, className)}
      >
        {itemsToDisplay}
      </CSSTransitionGroup>
    );
  }
}

export default delay(AnimatedChecklist);
