import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './DrawSVG.css';

function DrawSVG(props) {
  const { children } = props;
  const child = React.Children.only(children);

  return React.cloneElement(child, {
    ...child.props,
    className: classNames(child.props.className, styles.draw),
  });
}

DrawSVG.propTypes = {
  children: PropTypes.element.isRequired,
};

export default DrawSVG;
