import { createActions, createAction } from 'redux-actions';
import * as actionTypes from './documentActionTypes';
import * as individualActionTypes from './individualDocumentActionTypes';

const actionWithWrapper = (type) => (id) =>
  createAction(type, (payload) => ({ id, value: payload }));

const individualDocumentActionsValues = Object.values(individualActionTypes);
export const individualDocumentActions = individualDocumentActionsValues.reduce(
  (prev, type) => {
    const next = prev;
    next[type] = actionWithWrapper(type);
    return next;
  },
  {},
);

export const documentActions = createActions({}, ...Object.values(actionTypes));

export default { ...documentActions, ...individualDocumentActions };
