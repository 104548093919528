import update from 'immutability-helper';

import * as documentActionTypes from 'actions/documentActionTypes';
import * as individualDocumentActionTypes from 'actions/individualDocumentActionTypes';

import { handleActions } from 'redux-actions';

import {
  setWorkingValue,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  setError,
  setApplicationError,
} from 'lib/reducerHelper';

const actionTypes = {
  ...documentActionTypes,
  ...individualDocumentActionTypes,
};

const newDocument = {
  errors: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newDocument,
  },
  fileInvite: {},
  documentTypes: [],
};

const documentReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_DOCUMENT](state, action) {
      return clearWorking(state, action, newDocument);
    },
    [actionTypes.LOAD_DOCUMENT](state, action) {
      return loadEntityIntoWorking(state, action, newDocument);
    },
    [actionTypes.SET_WORKING_DOCUMENT](state, action) {
      const id = parseInt(action.payload.id, 10) || action.payload.id;
      return update(state, {
        working: {
          [id]: {
            $set: update(newDocument, { $merge: action.payload }),
          },
        },
      });
    },
    [actionTypes.REMOVE_DOCUMENT](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_DOCUMENT](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_DOCUMENTS](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.SET_DOCUMENT](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_DOCUMENT_TYPE](state, action) {
      return setWorkingValue(state, action.payload, 'type');
    },
    [actionTypes.SET_DOCUMENT_PAGES](state, action) {
      return setWorkingValue(state, action.payload, 'pages');
    },
    [actionTypes.SET_DOCUMENT_CATEGORY](state, action) {
      const {
        payload: { id, value },
      } = action;
      return update(state, {
        working: {
          [id]: {
            category: {
              $set: value,
            },
            type: {
              $set: undefined,
            },
          },
        },
      });
    },
    [actionTypes.SET_PAGE_DETAILS](state, action) {
      const { documentId, ...pageDetails } = action.payload;
      const documentIndex = state.entities.findIndex(
        (e) => e.id === documentId,
      );
      const document = state.entities[documentIndex];
      const pageIndex = document.pages.findIndex(
        (p) => p.id === pageDetails.id,
      );

      return update(state, {
        entities: {
          [documentIndex]: {
            pages: {
              [pageIndex]: { $set: pageDetails },
            },
          },
        },
      });
    },
    [actionTypes.SET_DOCUMENT_UPLOADING](state, action) {
      return setWorkingValue(state, action.payload, 'progress');
    },
    [actionTypes.SET_AXIOS_REQUEST_CANCELER](state, action) {
      return setWorkingValue(state, action.payload, 'cancel');
    },
    [actionTypes.SET_DOCUMENT_ERROR](state, action) {
      return setError(state, action);
    },
    [actionTypes.SET_DOCUMENT_ERRORS_ON_APPLICATION](state, action) {
      return setApplicationError(state, action);
    },
    [actionTypes.SET_FILE_INVITE](state, action) {
      return update(state, {
        fileInvite: {
          $set: action.payload,
        },
      });
    },
    [actionTypes.SET_RESELECT_FILE](state, action) {
      return {
        ...state,
        reselectFile: action.payload,
      };
    },
    [actionTypes.SET_DOCUMENT_TYPES](state, action) {
      return update(state, {
        documentTypes: {
          $set: action.payload,
        },
      });
    },
  },
  initialState,
);

export default documentReducer;
