import {
  LOAN_PURPOSE_REFINANCE,
  LOAN_PURPOSE_RESIDENTIAL,
  LOAN_PURPOSE_INVESTMENT,
} from '../constants/loanPurposes';
import { getDefaultExistingProperty } from './propertyHelper';

function isPropertyResidential(scenario) {
  const defaultProperty = getDefaultExistingProperty(scenario);
  return defaultProperty && defaultProperty.ownerOccupied;
}

export function isRefinanceOrRenovate(loanPurpose) {
  return loanPurpose === LOAN_PURPOSE_REFINANCE;
}

export function isInvestment(scenario) {
  const loanPurpose = scenario.loanPurpose;

  return (
    loanPurpose === LOAN_PURPOSE_INVESTMENT ||
    (loanPurpose === LOAN_PURPOSE_REFINANCE && !isPropertyResidential(scenario))
  );
}

export function isResidential(scenario) {
  const loanPurpose = scenario.loanPurpose;
  return (
    loanPurpose === LOAN_PURPOSE_RESIDENTIAL ||
    (loanPurpose === LOAN_PURPOSE_REFINANCE && isPropertyResidential(scenario))
  );
}
