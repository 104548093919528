import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Question from 'components/Question/Question';
import Form from 'components/Form/Form';
import Button from 'components/Button/Button';

import { DISPLAY_OPTIONS } from 'shared/constants/goalLoanAppInfo';
import { ERROR_MESSAGES, REQUIRED } from 'constants/validators';
import { BACK_BUTTON_EVENT } from 'constants/GTMEvents';
import {
  renderQuestionContent,
  getOrderedQuestionKeys,
  getRequiredQs,
  shouldHideQuestion,
} from 'lib/goalSetterFormHelper';
import { getSectionFromUrl } from 'lib/utils/browserUtils';

import styles from './GoalSetterForm.css';
import contentLayoutStyles from 'components/ContentLayout/ContentLayout.css';

function GoalSetterForm(props) {
  const {
    submitButtonProps,
    formName,
    answers,
    action,
    onSectionClick,
    isModalContent,
    questions,
    displayName,
    trackEvent,
    back,
    showBackButton = true,
  } = props;
  const [isSubmitted, updateIsSubmitted] = useState(false);
  const [requiredQs, updateRequiredQs] = useState([]);

  useEffect(() => {
    updateRequiredQs(getRequiredQs(questions, answers));
  }, [questions, answers]);

  const getIncompleteQ = () =>
    requiredQs.find((qId) => !answers[qId] || answers[qId] === '');

  const renderSubQs = (q) =>
    q.subQuestions && q.subQuestions.map(renderQuestion);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderQuestion = (q) => {
    if (shouldHideQuestion(q, answers)) {
      return null;
    }
    const isMultiSelectPopup =
      q.customDisplayTypeId === DISPLAY_OPTIONS.MULTI_SELECT_POPUP;
    const isMultiSelectWithLogo =
      q.customDisplayTypeId === DISPLAY_OPTIONS.MULTI_SELECT_WITH_LOGO;
    const isYesNoWithNoLabel =
      q.displayTypeId === DISPLAY_OPTIONS.YES_NO_ONLY && q.content === '';
    let theme = isYesNoWithNoLabel ? 'yesNoWithNoLabel' : 'goalSetterForm';
    if (isMultiSelectWithLogo) {
      theme = q.parentId ? 'multiSelect' : 'goalForms';
    }

    const questionProps = {
      id: `${q.questionId}`,
      key: `${q.questionId}`,
      label: q.content.replace('client_name', displayName),
      className: isModalContent ? 'fullWidthInput' : '',
      showError:
        isSubmitted &&
        (!answers[q.questionId] ||
          (q.inlinedNextQ && !answers[q.inlinedNextQ.questionId])),
      error: requiredQs.includes(q.questionId)
        ? ERROR_MESSAGES[REQUIRED]
        : undefined,
      subtitle: isMultiSelectPopup
        ? '[Choose one or more]'
        : q.customSubcontent,
      labelIcon: q.icon,
      showLabel: !(
        q.isOtherField ||
        isMultiSelectWithLogo ||
        isYesNoWithNoLabel
      ),
      hideTopLine: q.isOtherField || isMultiSelectWithLogo,
      theme,
      footNote: q.customNotes,
    };

    const questionAction = isMultiSelectPopup ? onSectionClick : action;
    return (
      <React.Fragment key={`${q.questionId}`}>
        <Question {...questionProps}>
          {renderQuestionContent(q, answers, questionAction)}
        </Question>
        {!isMultiSelectPopup && renderSubQs(q)}
      </React.Fragment>
    );
  };

  const onSubmit = () => {
    updateIsSubmitted(true);
    const incompleteQ = getIncompleteQ();
    if (incompleteQ) {
      setTimeout(() => {
        // eslint-disable-next-line unicorn/prefer-query-selector
        const qComponent = document.getElementById(`${incompleteQ}`);
        if (qComponent) {
          qComponent.scrollIntoView();
        }
      }, 100);
    } else {
      submitButtonProps.action();
    }
  };

  const onBack = () => {
    trackEvent({ event: BACK_BUTTON_EVENT, section: getSectionFromUrl() });
    submitButtonProps.action(true);
    return back();
  };

  const renderBackButton = () => {
    return (
      <div className={contentLayoutStyles.backButton}>
        <Button
          id='backButton'
          icon='mi-arrow-left'
          onClick={onBack}
          theme='linkButtonTheme'
        >
          Back
        </Button>
      </div>
    );
  };

  return (
    <>
      {showBackButton && renderBackButton()}
      <Form
        id={formName}
        className={classNames(styles.root, {
          [styles.compact]: isModalContent,
        })}
      >
        {getOrderedQuestionKeys(questions, formName).map((id) =>
          renderQuestion(questions[id]),
        )}
        <Button
          onClick={onSubmit}
          className='brandColor__button'
          theme={submitButtonProps.theme}
          disabled={submitButtonProps.disabled}
        >
          {submitButtonProps.text}
          {submitButtonProps.theme === 'buttonNext' && (
            <i className='mi-arrow-with-circle-right' />
          )}
        </Button>
      </Form>
    </>
  );
}

GoalSetterForm.propTypes = {
  formName: PropTypes.string,
  action: PropTypes.func,
  questions: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  displayName: PropTypes.string,
  onSectionClick: PropTypes.func,
  isModalContent: PropTypes.bool,
  submitButtonProps: PropTypes.object,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
  showBackButton: PropTypes.bool,
};

export default GoalSetterForm;
