export const SET_LIABILITY_CLIENT_IDS = 'setLiabilityClientIds';
export const SET_LIABILITY_TYPE = 'setLiabilityType';
export const SET_LIABILITY_CATEGORY = 'setLiabilityCategory';
export const SET_LIABILITY_DESCRIPTION = 'setLiabilityDescription';
export const SET_LIABILITY_TOTAL_CARD_LIMIT = 'setLiabilityTotalCardLimit';
export const SET_LIABILITY_ORIGINAL_LOAN_AMOUNT =
  'setLiabilityOriginalLoanAmount';
export const SET_LIABILITY_REPAYMENT_AMOUNT = 'setLiabilityRepaymentAmount';
export const SET_LIABILITY_REPAYMENT_FREQUENCY =
  'setLiabilityRepaymentFrequency';
export const SET_LIABILITY_TOTAL_LOAN_AMOUNT = 'setLiabilityTotalLoanAmount';
export const SET_LIABILITY_REMAINING_YEARS = 'setLiabilityRemainingYears';
export const SET_LIABILITY_REMAINING_MONTHS = 'setLiabilityRemainingMonths';
export const SET_LIABILITY_CREDITOR_ID = 'setLiabilityCreditorId';
export const SET_LIABILITY_CREDITOR = 'setLiabilityCreditor';
export const SET_LIABILITY_IS_INTEREST_TAX_DEDUCTIBLE =
  'setLiabilityIsInterestTaxDeductible';
export const SET_LIABILITY_TO_REFINANCE = 'setLiabilityIsRefinance';

export const SET_LIABILITY_ERROR = 'setLiabilityError';
