import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/lenderActions';

export const initialState = {
  accreditedLenders: [],
};

const lenderReducer = handleActions(
  {
    [actionTypes.SET_ACCREDITED_LENDERS](state, action) {
      return { ...state, accreditedLenders: action.payload };
    },
  },
  initialState,
);

export default lenderReducer;
