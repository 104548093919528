import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from '@loan_market/react-router-redux-multi';
import PropTypes from 'prop-types';

import { getSection } from 'lib/goalFutureHelper';
import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';

import { RETIREMENT } from 'shared/constants/goalLoanAppInfo';

import UIActions from 'actions/UIActions';
import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';

import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

export function GoalFutureRetirement(props) {
  const {
    questions,
    answers,
    client,
    applicationId,
    goTo,
    nextClient,
    saveLoanAppInfoAnswers,
    back,
    trackEvent,
  } = props;
  const familyId = client.contactId;
  const [savedAnswers, updateSavedAnswers] = useState({});

  const setAnswer = (questionId, value) =>
    updateSavedAnswers({ ...savedAnswers, [questionId]: value });

  const onSave = (isBack) => {
    if (Object.keys(savedAnswers).length > 0) {
      saveLoanAppInfoAnswers({
        section: RETIREMENT,
        clientId: client.id,
        answers: savedAnswers,
        loanAppId: applicationId,
        familyId,
      });
    }
    if (!isBack) {
      goTo(
        getSection({
          section: RETIREMENT,
          client,
          nextClient,
          active: applicationId,
        }).nextPath,
      );
    }
  };

  const formProps = {
    formName: RETIREMENT,
    action: setAnswer,
    questions,
    answers: { ...(answers[client.id] || {}), ...savedAnswers },
    displayName: client.displayName,
    submitButtonProps: {
      text: 'Save and Next',
      action: onSave,
      theme: 'buttonNext',
    },
    back,
    trackEvent,
  };

  return <GoalSetterForm {...formProps} />;
}

GoalFutureRetirement.propTypes = {
  match: PropTypes.object,
  client: PropTypes.object,
  nextClient: PropTypes.object,
  goTo: PropTypes.func,
  questions: PropTypes.object,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  applicationId: PropTypes.number,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const { clientId, familyId } = props.match.params;
  const cId = Number(clientId);
  const fId = Number(familyId);

  return {
    applicationId: goalLoanApplicationSelectors.loanApplicationId(state),
    client: clientSelectors.client(state)(cId),
    nextClient: clientSelectors.nextClient(state)(fId, cId),
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(RETIREMENT),
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      familyId,
      RETIREMENT,
    ),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goTo: push,
      back: goBack,
      trackEvent: UIActions.trackEvent,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalFutureRetirement);
