export const CLEAR_WORKING_DOCUMENT = 'clearWorkingDocument';
export const CREATE_DOCUMENT = 'createDocument';
export const LOAD_DOCUMENT = 'loadDocument';
export const SET_WORKING_DOCUMENT = 'setWorkingDocument';
export const LOAD_NEW_CLIENT_DOCUMENT = 'loadNewClientDocument';
export const UPLOAD_DOCUMENT = 'uploadDocument';
export const REQUEST_PAGE_DETAILS = 'requestPageDetails';
export const REMOVE_DOCUMENT = 'removeDocument';
export const SET_DOCUMENT_PAGES = 'setDocumentPages';
export const SET_PAGE_DETAILS = 'setPageDetails';
export const SET_DOCUMENT = 'setDocument';
export const SET_NEW_DOCUMENT = 'setNewDocument';
export const SET_NEW_DOCUMENTS = 'setNewDocuments';
export const SET_DOCUMENT_ERROR = 'setDocumentError';
export const SET_DOCUMENT_ERRORS_ON_APPLICATION =
  'setDocumentErrorsOnApplication';
export const GET_FILE_INVITE = 'getFileInvite';
export const SET_FILE_INVITE = 'setFileInvite';

export const UPDATE_DOCUMENT = 'updateDocument';
export const DELETE_DOCUMENT = 'deleteDocument';

export const SET_RESELECT_FILE = 'setReselectFile';
export const SET_DOCUMENT_TYPES = 'setDocumentTypes';
