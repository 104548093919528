// note: simpler version of https://github.com/alicelieutier/smoothScroll/

function easeInOutCubic(t) {
  return t < 0.5 ? 4 * t ** 3 : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

function position(start, end, elapsed, duration) {
  if (elapsed > duration) {
    return end;
  }
  return start + (end - start) * easeInOutCubic(elapsed / duration);
}

const requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  function rafPoly(fn) {
    window.setTimeout(fn, 15);
  };

export function smoothScroll(end, duration = 150) {
  const start = window.pageYOffset;
  const clock = Date.now();

  const step = () => {
    const elapsed = Date.now() - clock;
    window.scroll(0, position(start, end, elapsed, duration));

    if (elapsed <= duration) {
      requestAnimationFrame(step);
    }
  };

  step();
}

export function scrollLock(el, duration = 150, offset = 0) {
  const clock = Date.now();

  const step = () => {
    const elapsed = Date.now() - clock;
    window.scroll(0, el.offsetTop + offset);
    if (elapsed <= duration) {
      requestAnimationFrame(step);
    }
  };

  step();
}
