import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, defineMessages } from 'react-intl';

import SectionItem from 'components/SectionItem/SectionItem';
import Section from 'components/Section/Section';
import { COLOR_LIST } from 'styles/common';

import { convertToClassName } from 'lib/utils/browserUtils';
import { fileDisplayName } from 'shared/lib/utils';
import { documentTypeLookup } from 'shared/constants/myCRMTypes/documents';
import { DOCUMENT_CATEGORY_FILE_INVITE } from 'constants/documents';
import locale from 'config/locale';
import FileInviteIcon from 'assets/icons/file-invite.svg';

export const messages = defineMessages({
  fileCount: {
    id: 'DocumentGroupRecord.fileCount',
    defaultMessage: '{fileCount} {fileCount, plural, one {file} other {files}}',
  },
  upload: {
    id: 'DocumentGroupRecord.upload',
    defaultMessage: 'Upload',
  },
  uploadMore: {
    id: 'DocumentGroupRecord.uploadMore',
    defaultMessage: 'Upload another file',
  },
  bankStatementsConnect: {
    id: 'DocumentGroupRecord.connect',
    defaultMessage: 'Connect',
  },
  bankStatementsConnectAgain: {
    id: 'DocumentGroupRecord.connectAgain',
    defaultMessage: 'Connect again',
  },
  bankStatementsUploadAgain: {
    id: 'DocumentGroupRecord.uploadAgain',
    defaultMessage: 'Upload again',
  },
  unknownType: {
    id: 'DocumentGroupRecord.unkownType',
    defaultMessage: 'Please set document details',
  },
});

const AUTOMATIC = 'Automatic';
const MANUAL = 'Manual';

export class DocumentGroupRecord extends Component {
  sectionHeader(contentsExists) {
    const {
      documents,
      isLocked,
      showBankConnectLink,
      intl: { formatMessage },
    } = this.props;

    if (contentsExists) {
      return formatMessage(messages.fileCount, { fileCount: documents.length });
    } else if (!isLocked) {
      return formatMessage(
        showBankConnectLink ? messages.bankStatementsConnect : messages.upload,
      );
    }

    return '';
  }

  getSVG(subCategory) {
    // convert to case switch if more than 3
    if (subCategory === DOCUMENT_CATEGORY_FILE_INVITE.key) {
      return FileInviteIcon;
    }
    return null;
  }

  renderDocs() {
    const {
      documents,
      intl: { formatMessage },
      documentTypes,
    } = this.props;

    return documents.map((doc, i) => {
      const actualType = (documentTypes[doc.category] || []).find(
        (d) => d.key === doc.type,
      );
      const type = actualType || documentTypeLookup(locale)[doc.type];
      return (
        <SectionItem
          key={`${doc.name}-${i}`}
          name={type ? type.name : formatMessage(messages.unknownType)}
          value={doc.name && fileDisplayName(doc.name)}
          icon='sl-custom-document-1'
          url={`/document/${doc.id}`}
        />
      );
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  render = () => {
    const {
      intl: { formatMessage },
      documents,
      subCategory,
      addFile,
      showBankConnectLink,
      connectBankStatements,
      isLocked,
      pendingCount,
    } = this.props;
    const contentsExists = documents.length > 0;
    const noContentsIcon = showBankConnectLink
      ? 'sl-custom-log-out-1'
      : 'sl-custom-cloud-upload';

    const showReAdd = contentsExists && !isLocked;
    const headerAction =
      !showReAdd && showBankConnectLink ? connectBankStatements : addFile;
    const bankConnectElementId = `${convertToClassName(
      subCategory,
    )}-bankConnectButton`;
    const headerProps = {
      header: {
        value: this.sectionHeader(contentsExists),
        name: subCategory,
        id:
          !showReAdd && showBankConnectLink ? bankConnectElementId : undefined,
      },
      icon: (
        <i
          className={
            contentsExists ? 'mi-arrow-with-circle-down' : noContentsIcon
          }
        />
      ),
      closedIcon: contentsExists ? (
        <i className='mi-arrow-with-circle-up' />
      ) : undefined,
      onClickHeader: !isLocked && !contentsExists ? headerAction : undefined,
      customValueClassName: contentsExists ? undefined : 'brandColor__font',
      colour: COLOR_LIST.darkGrey,
      forceCollapsedStyle: !contentsExists && !showBankConnectLink,
      nameInfoLabel: !contentsExists && showBankConnectLink ? AUTOMATIC : null,
      svg: this.getSVG(subCategory),
      count: pendingCount,
    };

    return (
      <Section {...headerProps}>
        {!showReAdd && showBankConnectLink && (
          <SectionItem
            key='manualAddBankStatementsLink'
            name='Bank Statements'
            icon='sl-custom-cloud-upload'
            rightLabel={formatMessage(messages.upload)}
            onClick={addFile}
            theme='sectionHeader'
            colour={COLOR_LIST.darkGrey}
            nameInfoLabel={MANUAL}
          />
        )}

        {this.renderDocs()}

        {showReAdd && showBankConnectLink && (
          <SectionItem
            key='connect-again'
            id={bankConnectElementId}
            rightIcon='sl-custom-log-out-1'
            name={formatMessage(messages.bankStatementsConnectAgain)}
            nameInfoLabel={AUTOMATIC}
            theme='sectionHeader'
            onClick={connectBankStatements}
          />
        )}

        {showReAdd && (
          <SectionItem
            key='add-another'
            name={formatMessage(
              showBankConnectLink
                ? messages.bankStatementsUploadAgain
                : messages.uploadMore,
            )}
            nameInfoLabel={showBankConnectLink ? MANUAL : null}
            theme='sectionHeader'
            onClick={addFile}
          />
        )}
      </Section>
    );
  };
}

DocumentGroupRecord.propTypes = {
  subCategory: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object),
  addFile: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  showBankConnectLink: PropTypes.bool,
  connectBankStatements: PropTypes.func,
  pendingCount: PropTypes.number,
  documentTypes: PropTypes.arrayOf(PropTypes.object),
};

DocumentGroupRecord.defaultProps = {
  isLocked: false,
  documents: [],
  showBankConnectLink: false,
};

export default injectIntl(DocumentGroupRecord);
