import { PROPERTY_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON } from './baseApi';

export function putProperty(property) {
  return axiosWithAuth
    .put(`${PROPERTY_API_PATH}/${property.id}`, property)
    .then(returnJSON);
}

export function delProperty(propertyId) {
  return axiosWithAuth
    .delete(`${PROPERTY_API_PATH}/${propertyId}`)
    .then(returnJSON);
}

export function getFundingsFromProperty(propertyId) {
  return axiosWithAuth
    .get(`${PROPERTY_API_PATH}/${propertyId}/fundings`)
    .then(returnJSON);
}

export function postFundingsForProperty(fundings) {
  return axiosWithAuth
    .post(`${PROPERTY_API_PATH}/${fundings[0].propertyId}/fundings`, fundings)
    .then(returnJSON);
}

export function postIncomeForProperty(income) {
  return axiosWithAuth
    .post(`${PROPERTY_API_PATH}/${income.propertyId}/incomes`, income)
    .then(returnJSON);
}
