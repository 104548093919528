import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'contexts/ModalContext';
import { Modal } from 'components/Modal/Modal';

export function ParentWrapper(props) {
  const { isModalShown, hideModal, modalConfig } = useModal();

  return (
    <>
      {isModalShown && <Modal {...modalConfig} hideModal={hideModal} />}
      {props.children}
    </>
  );
}

ParentWrapper.propTypes = {
  children: PropTypes.node,
};
