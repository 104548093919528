/* eslint-disable no-unused-vars */
import { takeEvery, all, put, select } from 'redux-saga/effects';

import {
  SET_HOUSEHOLD_SHAPE,
  SAVE_HOUSEHOLD,
} from 'actions/householdActionTypes';
import loanApplicationActions from 'actions/loanApplicationActions';
import householdActions from 'actions/householdActions';
import * as contactSelectors from 'selectors/contactSelectors';

import { SINGLE_HOUSEHOLD } from 'shared/constants/options';

export function* setHouseholdMetadata({ payload }) {
  const actualHouseholdShape = yield select(contactSelectors.householdShape);
  const householdShape = actualHouseholdShape || payload;
  const hasHousehold = householdShape !== SINGLE_HOUSEHOLD;
  yield put(loanApplicationActions.setMetadata({ hasHousehold }));
}

export function* saveHousehold() {
  const household = yield select(contactSelectors.household);
  const { householdShape: workingHouseholdShape, formCompleted } = household;
  if (!formCompleted) {
    return;
  }

  const actualHouseholdShape = yield select(contactSelectors.householdShape);
  let householdShape = workingHouseholdShape;
  if (workingHouseholdShape !== actualHouseholdShape) {
    yield put(householdActions.setHouseholdShape(actualHouseholdShape));
    householdShape = actualHouseholdShape;
  }

  if (householdShape !== SINGLE_HOUSEHOLD) {
    yield put(householdActions.clearHousehold());
  }
}

export default function* householdSagas() {
  yield all([
    yield takeEvery(SET_HOUSEHOLD_SHAPE, setHouseholdMetadata),
    yield takeEvery(SAVE_HOUSEHOLD, saveHousehold),
  ]);
}
