import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';

import {
  connectToMirroredProperty,
  connectToMirroredScenario,
  mergeActionsWithParamsId,
} from 'lib/reducerHelper';
import { bindIndividualActions } from 'lib/actionHelpers';
import { formatCurrency } from 'lib/intlFormatters';
import { lvrCalculator } from 'shared/lib/utils';

import {
  PROSPECTIVE_PROPERTY_ID,
  DEFAULT_EXISTING_PROPERTY_ID,
} from 'shared/constants/defaults';
import { REQUIRED, AT_LEAST_TWENTY_THOUSAND } from 'constants/validators';

import scenarioActions from 'actions/scenarioActions';
import {
  prospectiveProperty as getProspectiveProperty,
  propertyValue,
  conveyancerCost,
  movingCost,
  renovationCost,
  deposit,
  existingPropertyEquity,
  salesFeesAndCharges,
  mortgageAmount,
  totalCosts,
  prospectivePropertyValue,
  firstHomeOwnersGrant,
  lmi as getLmi,
} from 'selectors/scenarioSelectors';

import { maximumLmi, minimumLmi } from 'selectors/otherSelectors';
import SingleInputForm from 'components/PopupForms/SingleInputForm';
import SingleDropdownForm from 'components/PopupForms/SingleDropdownForm';

// Todo: get connectToMirror to use the same selector that is defined in the metadata?
// body render function is set on metadata, so this is non-trivial

export const PropertyValueForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: propertyValue(state, ownProps),
    validation: {
      value: [REQUIRED, AT_LEAST_TWENTY_THOUSAND],
    },
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyValue,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const LegalFeesForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: conveyancerCost(state, ownProps),
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyConveyancerCost,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const MovingCostsForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: movingCost(state, ownProps),
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyMovingCost,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const RenovationCostsForm = connectToMirroredProperty(
  (state, ownProps) => ({
    value: renovationCost(state, ownProps),
  }),
  (dispatch) =>
    bindIndividualActions(
      {
        setValue: scenarioActions.setPropertyRenovationCost,
      },
      dispatch,
    ),
  mergeActionsWithParamsId,
)(SingleInputForm);

export const ContributionsForm = connectToMirroredScenario(
  (state) => {
    const costs = totalCosts(state);
    const grant = firstHomeOwnersGrant(state);
    const thePropertyValue = prospectivePropertyValue(state);
    return {
      label: 'Cash deposit with resulting LVR',
      value: deposit(state),
      valueToPercentage: (value) =>
        lvrCalculator(costs - (value + grant), thePropertyValue),
      percentageToValue: (percentage) =>
        -(percentage * thePropertyValue - costs) - grant,
      percentageList: [0.8, 0.85, 0.9, 0.95],
    };
  },
  (dispatch) =>
    bindActionCreators(
      {
        setValue: scenarioActions.setPropertyDepositAmount(
          PROSPECTIVE_PROPERTY_ID,
        ),
      },
      dispatch,
    ),
)(SingleDropdownForm);

export function LVRInfo({ style }) {
  return (
    <div className={style.infoOnly}>
      <math className={style.narrow}>
        <b>LVR </b> =
        <box>
          Loan Amount
          <over>Property Value</over>
        </box>
      </math>
    </div>
  );
}

LVRInfo.propTypes = {
  style: PropTypes.object,
};

function StampdutyInfo(props) {
  const {
    intl,
    style,
    transferFee,
    mortgageRegFee,
    stampDutyPayable,
    totalFee,
  } = props;
  return (
    <div className={style.infoOnly}>
      <table>
        <tbody>
          <tr>
            <th>Transfer fee</th>
            <td>{formatCurrency(intl)(transferFee)}</td>
          </tr>
          <tr>
            <th>Mortgage fee</th>
            <td>{formatCurrency(intl)(mortgageRegFee)}</td>
          </tr>
          <tr>
            <th>Stamp duty</th>
            <td>{formatCurrency(intl)(stampDutyPayable)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <td>{formatCurrency(intl)(totalFee)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

StampdutyInfo.propTypes = {
  intl: intlShape.isRequired,
  style: PropTypes.object,
  transferFee: PropTypes.number,
  mortgageRegFee: PropTypes.number,
  stampDutyPayable: PropTypes.number,
  totalFee: PropTypes.number,
};

export const ConnectedStampdutyInfo = connect((state) => {
  const prospectiveProperty = getProspectiveProperty(state);
  return {
    transferFee: prospectiveProperty.transferFee,
    mortgageRegFee: prospectiveProperty.mortgageRegFee,
    stampDutyPayable: prospectiveProperty.stampDutyPayable,
    totalFee: prospectiveProperty.totalFee,
  };
})(StampdutyInfo);

function LMIInformation(props) {
  const { intl, style, lmi, maximum, minimum } = props;
  let tableContents;
  if (lmi <= 0) {
    tableContents = (
      <table>
        <tbody>
          <tr>
            <th>
              <b>Good news!</b>
              <br />
              It does not look like you’ll need LMI.
            </th>
          </tr>
        </tbody>
      </table>
    );
  } else if (maximum && minimum) {
    tableContents = (
      <table>
        <tbody>
          <tr>
            <th>
              Minimum
              <br />
              <em>({minimum.lenderName})</em>
            </th>
            <td>{formatCurrency(intl)(minimum.value)}</td>
          </tr>
          <tr>
            <th>
              Maximum
              <br />
              <em>({maximum.lenderName})</em>
            </th>
            <td>{formatCurrency(intl)(maximum.value)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Average LMI Premium</th>
            <td>{formatCurrency(intl)(lmi)}</td>
          </tr>
        </tfoot>
      </table>
    );
  } else {
    tableContents = (
      <div className='alignCenter'>
        <b>LMI</b> = Loan Amount x 2%
      </div>
    );
  }
  return <div className={style.infoOnly}>{tableContents}</div>;
}

LMIInformation.propTypes = {
  intl: intlShape.isRequired,
  style: PropTypes.object,
  lmi: PropTypes.number,
  minimum: PropTypes.object,
  maximum: PropTypes.object,
};

export const ConnectedLMIInformation = connect((state) => {
  return {
    lmi: getLmi(state),
    maximum: maximumLmi(state),
    minimum: minimumLmi(state),
  };
})(LMIInformation);

function ProceedsOfSaleInfo({
  intl,
  style,
  value,
  mortgage,
  salesFees,
  equity,
}) {
  return (
    <div className={style.infoOnly}>
      <table>
        <tbody>
          <tr>
            <th>Sale price</th>
            <td>{formatCurrency(intl)(value)}</td>
          </tr>
          <tr>
            <th>Outstanding mortgage</th>
            <td>{formatCurrency(intl)(mortgage)}</td>
          </tr>
          <tr>
            <th>Sale fees & charges</th>
            <td>{formatCurrency(intl)(salesFees)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>Total funds available</th>
            <td>{formatCurrency(intl)(equity)}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

ProceedsOfSaleInfo.propTypes = {
  intl: intlShape.isRequired,
  style: PropTypes.object,
  value: PropTypes.number,
  mortgage: PropTypes.number,
  salesFees: PropTypes.number,
  equity: PropTypes.number,
};

export const ConnectedProceedsOfSaleInfo = connect((state) => {
  return {
    value: propertyValue(state, DEFAULT_EXISTING_PROPERTY_ID),
    mortgage: mortgageAmount(state),
    salesFees: salesFeesAndCharges(state),
    equity: existingPropertyEquity(state),
  };
})(ProceedsOfSaleInfo);
