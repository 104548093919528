/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable sonarjs/cognitive-complexity */

import * as Sentry from '@sentry/browser';

import { SET_PAGE_ERROR, SET_ASYNC_REQUEST_ERROR } from 'actions/UIActionTypes';
import { TRACK_CURRENT_USER } from 'actions/scenarioActionTypes';
import LocalStorageProxy from 'lib/localStorageProxy';

export const initScope = (payload) => {
  const { clientId, familyId, email, type, mobile } = payload;
  Sentry.configureScope((scope) => {
    scope.setUser({ email, clientId, familyId, mobile });
    scope.setTag('login_as', type);
    scope.setTag('url', document.location.href);
  });
};

function trackErrors() {
  return (store) => (next) => (action) => {
    const { payload, type } = action;
    const appId = LocalStorageProxy.loginAsLoanApplicationId
      ? `[AppId: ${LocalStorageProxy.loginAsLoanApplicationId}] `
      : '';
    const loginAsClientId = LocalStorageProxy.loginAsClientId
      ? `[ClientId: ${LocalStorageProxy.loginAsClientId}] `
      : '';
    const prefix = `${appId}${loginAsClientId}`;
    switch (type) {
      case TRACK_CURRENT_USER:
        // eslint-disable-next-line no-case-declarations
        const { isClient, familyId, clientId, email, mobile } = payload;
        // eslint-disable-next-line no-case-declarations
        const params = {
          familyId,
          clientId,
          email,
          mobile,
          type: isClient ? 'client' : 'adviser',
        };
        initScope(params);
        LocalStorageProxy.sentry = JSON.stringify(params);
        break;
      case SET_PAGE_ERROR:
        if (payload && payload.err) {
          Sentry.withScope((scope) => {
            scope.setExtra('status_code', payload.status);
            Sentry.captureMessage(`${prefix}${JSON.stringify(payload.err)}`);
          });
        }
        break;
      case SET_ASYNC_REQUEST_ERROR:
        if (payload && payload.error) {
          Sentry.withScope((scope) => {
            scope.setExtra('id', payload.id);
            scope.setExtra('type', payload.type);
            Sentry.captureMessage(`${prefix}${JSON.stringify(payload.error)}`);
          });
        }
        break;
      default:
        break;
    }
    return next(action);
  };
}

export default trackErrors;
