import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Checklist from 'components/Checklist/Checklist';
import Button from 'components/Button/Button';

import styles from './GoalPreferencesModal.css';

const preferencesOptions = [
  { key: 1, value: 1, label: 'Loan Amount', name: 'Loan Amount' },
  { key: 2, value: 2, label: 'Max Borrow', name: 'Max Borrow' },
  { key: 3, value: 3, label: 'Services', name: 'Services' },
];

const GoalPreferencesModal = (props) => {
  const [checks, setChecks] = useState(props.value);

  const onUpdate = (value) => setChecks({ ...checks, ...value });

  return (
    <div className={styles.root}>
      <p className='margin-top-0'>Show/Hide sections</p>
      <Checklist
        id='preferences'
        className={styles.noBorder}
        value={checks}
        action={onUpdate}
        items={preferencesOptions}
      />
      <Button
        onClick={() => props.onSave(checks)}
        className='brandColor__button'
      >
        Save preferences
      </Button>
      <Button
        onClick={props.onClose}
        theme='noBorder'
        className='brandColor__font--hover'
      >
        Cancel
      </Button>
    </div>
  );
};

GoalPreferencesModal.propTypes = {
  value: PropTypes.object,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default GoalPreferencesModal;
