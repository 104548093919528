/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios';
import LocalStorageProxy from 'lib/localStorageProxy';
import { isGoalSetter, handleSessionExpiry } from 'lib/utils/browserUtils';
import { axiosRetry, retryConfig } from 'lib/axiosRetryHelper';

axios.defaults.baseURL = window.LM_CONFIG.SERVER_DOMAIN;
axios.defaults.headers.common = {
  Accept: 'application/json, application/xml, text/play, text/html, *.*',
  'Content-Type': 'application/json',
};

export const axiosWithAuth = axios.create();
axiosRetry(axiosWithAuth, retryConfig);

export const X_SOURCEPLATFORM = 'X-Sourceplatform';
export const X_LOGINASCLIENTID = 'X-Loginasclientid';
export const X_LOGINASLOANAPPLICATIONID = 'X-Loginasloanapplicationid';
export const X_BROKERLOGINUSERNAME = 'X-Brokerloginusername';
export const X_OKTAAUTHFLOW = 'X-Oktaauthflow';

axiosWithAuth.interceptors.request.use(
  async (config) => {
    const token = LocalStorageProxy.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }
    config.headers[X_SOURCEPLATFORM] = isGoalSetter()
      ? 'GoalSetterApp'
      : 'ConsumerApp';
    config.headers[X_OKTAAUTHFLOW] = true;

    if (LocalStorageProxy.loginAsClientId) {
      config.headers[X_LOGINASCLIENTID] = LocalStorageProxy.loginAsClientId;
      config.headers[X_LOGINASLOANAPPLICATIONID] =
        LocalStorageProxy.loginAsLoanApplicationId;
      config.headers[X_BROKERLOGINUSERNAME] =
        LocalStorageProxy.brokerLoginUsername;
    } else if (LocalStorageProxy.loginAsLoanApplicationId) {
      config.headers[X_LOGINASLOANAPPLICATIONID] =
        LocalStorageProxy.loginAsLoanApplicationId;
      config.headers[X_BROKERLOGINUSERNAME] =
        LocalStorageProxy.brokerLoginUsername;
    } else {
      delete config.headers[X_LOGINASCLIENTID];
      delete config.headers[X_LOGINASLOANAPPLICATIONID];
      delete config.headers[X_BROKERLOGINUSERNAME];
    }

    return config;
  },
  (error) => Promise.reject(error),
);

axiosWithAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      handleSessionExpiry();
    }
    return Promise.reject(error);
  },
);

export const returnJSON = (res) => (res && res.data ? res.data : undefined);
export const throwJSON = (err) => {
  if (err.response && err.response.data) {
    return Promise.reject({
      status: err.response.status,
      message: err.response.data,
    });
  }
  return Promise.reject();
};

export default axios;
