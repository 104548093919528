import _ from 'lodash';
import { formatInternationalNumber } from 'lib/utils/numberUtils';
import locale from 'config/locale';

export const getFormattedMobile = (mobile) =>
  typeof mobile === 'object'
    ? mobile && mobile.number
    : formatInternationalNumber(mobile, locale.countryCode);

export const hasUpdatedEmailOrMobile = (working, client) => {
  const emailUpdated = working.email !== client.email;

  const workingMobile = getFormattedMobile(working.mobile);
  const clientMobile = getFormattedMobile(client.mobile);
  const mobileUpdated = workingMobile !== clientMobile;

  return { emailUpdated, mobileUpdated };
};

export const isSameAddress = (address1, address2) => {
  if ((!address1 && address2) || (address1 && !address2)) {
    return false;
  }
  if (address1 === address2) {
    return true;
  }

  const { address: addressObj1, ...otherProperties1 } = address1;
  const { address: addressObj2, ...otherProperties2 } = address2;
  const formattedAddress1 = (addressObj1 || {}).formattedAddress;
  const formattedAddress2 = (addressObj2 || {}).formattedAddress;
  const isSameFormattedAddress = formattedAddress1 === formattedAddress2;

  return (
    isSameFormattedAddress &&
    Object.keys(otherProperties1).every((k) => {
      return _.isEqual(otherProperties1[k], otherProperties2[k]);
    })
  );
};

export const areSameAddresses = (addresses1, addresses2) => {
  const orderedAddress1 = _.orderBy(addresses1, (a) => a.id);
  const orderedAddress2 = _.orderBy(addresses2, (a) => a.id);
  return orderedAddress1.every((address1, index) => {
    const address2 = orderedAddress2[index];
    return isSameAddress(address1, address2);
  });
};
