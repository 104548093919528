import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { lmLenderKeys } from 'constants/lmLenders';
import locale from 'config/locale';

import AnimationWrapper from 'components/AnimationWrapper/AnimationWrapper';
import AnimatedHeading from 'components/AnimatedHeading/AnimatedHeading';
import AnimatedLogoGrid from 'components/AnimatedLogoGrid/AnimatedLogoGrid';

// Children of AnimatedHeading (actually any component that passes children through AnimatedComponent)
// can access the function "next" in contextTypes in order to manually progress the animation,
// as demonstrated below.

// class SimpleComponent extends Component {
//   static contextTypes = {
//     next: PropTypes.func
//   };
//
//   gotoNext (e) {
//     e.preventDefault();
//     e.stopPropagation();
//     this.context.next();
//   }
//
//   render() {
//     return (
//       <button onClick={this.gotoNext.bind(this)}>Click ME</button>
//     );
//   }
// }

export default class FindingLoan extends Component {
  static propTypes = {
    next: PropTypes.func,
  };

  nextRoute() {
    this.props.next();
  }

  render() {
    return (
      <AnimationWrapper key='findingLoan' id='findingLoan'>
        <AnimatedHeading delay={400} onFinish={() => this.nextRoute()}>
          <h1 data-duration={4600}>
            Searching Australia’s biggest banks and specialist lenders...
          </h1>
          <h1 data-duration={3500}>Sorting today’s top deals...</h1>
          <h1 data-duration={2700}>Finding the right loans for you...</h1>
        </AnimatedHeading>
        <AnimatedLogoGrid
          delay={1000}
          duration={2750}
          lenders={lmLenderKeys(locale.data.countryCode).slice(0, 25)}
        />
      </AnimationWrapper>
    );
  }
}
