/* eslint-disable unicorn/explicit-length-check */
import { createSelector } from 'reselect';
import _ from 'lodash';
import locale from 'config/locale';

import * as applicationSelectors from 'selectors/applicationSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import {
  GOALS,
  PREFERENCES,
  PROFILE,
  SERVICEABILITY,
  LOAN_AMOUNT,
  LOAN_STRUCTURES,
  FUTURE,
  TEAM,
} from 'shared/constants/goalDashboard';
import { TEAM_MESSAGES } from 'constants/messages/goalSetterTilesMessages';

import {
  REQUIRED_FUTURE_QS,
  HORIZON,
  LIFESTYLE_INSURANCE_YES_NO_QS,
  REQUIRED_TEAM_QS,
  REQUIRED_PREFERENCES_QS,
  LOAN_OPTIONS,
  QUESTION_IDS,
  YES_NO_OPTION_VALUES,
} from 'shared/constants/goalLoanAppInfo';
import {
  GOAL_OPTIONS_PURCHASE,
  GOAL_OPTIONS_REFINANCE,
} from 'shared/constants/myCRMTypes/applications';
import lenders from 'shared/lib/lenderHelper';
import { combineStringsGrammatically } from 'lib/utils/stringUtils';
import { getQuestionId } from 'lib/goalSetterFormHelper';
import { formatCurrency } from 'lib/intlFormatters';

const {
  CURRENT_BANK,
  OTHER_CURRENT_BANK,
  RATE_PREFERENCES,
  REPAYMENT_PREFERENCES,
  FEATURE_PREFERENCES,
  PREFERRED_LENDERS,
  LIFE_AND_HEALTH_REASON,
  LIFE_AND_HEALTH_DETAILS,
  GENERAL_INSURANCE_NZ_REASON,
  GENERAL_INSURANCE_NZ_DETAILS,
  LIFESTYLE_INSURANCE_OBLIGATION,
} = QUESTION_IDS;

export const getHasVisitedKey = (section) =>
  `hasVisited${_.capitalize(section)}`;

export const goalLoanApplication = (state) => state.goalApplication;
export const goalLoanAppInfo = (state) => state.goalLoanAppInfo;
export const goalPreferences = (state) => state.goalApplication.preferences;

export const applications = createSelector(
  goalLoanApplication,
  (application) => application.list,
);

export const activeApplication = createSelector(
  goalLoanApplication,
  (application) => application.active,
);

export const loanApplicationId = createSelector(
  activeApplication,
  applicationSelectors.getApplicationId,
  (goalAppId, loanAppId) => goalAppId || loanAppId,
);

export const applicationTitle = createSelector(
  goalLoanApplication,
  (application) => {
    const active =
      application.list.filter((app) => app.id === application.active)[0] || {};
    return (
      active.purpose ||
      (active.isOpportunity ? 'Opportunity' : 'Loan Application')
    );
  },
);

export const goalsPercentage = createSelector(
  applicationSelectors.entities,
  applicationSelectors.workingApplication,
  (l, working) => {
    const application = l.find((a) => a.id === working.id);
    const { primaryGoals } = application || {};
    return primaryGoals &&
      _.values(primaryGoals).filter((val) => val).length > 0
      ? 100
      : 0;
  },
);

export const preferencesPercentage = createSelector(
  goalLoanAppInfo,
  clientSelectors.primaryFamily,
  ({ answers }, family) => {
    const preferences = (answers[family.contactId] || {})[LOAN_OPTIONS] || {};
    const questions = REQUIRED_PREFERENCES_QS[locale.countryCode];
    const answered = questions.reduce((a, key) => {
      if (
        preferences[key] !== undefined ||
        (key === getQuestionId(CURRENT_BANK) &&
          preferences[getQuestionId(OTHER_CURRENT_BANK)] !== undefined)
      ) {
        return a + 1;
      }
      return a;
    }, 0);
    return (answered / questions.length) * 100;
  },
);

const getFamilyAnsweredQsCount = (family, requiredQs, initialCount = 0) => {
  return family.clients.reduce((total, c) => {
    return (
      total +
      requiredQs.reduce((total, qId) => {
        if (family.answers[c.id] && family.answers[c.id][qId]) {
          return total + 1;
        }
        return total;
      }, 0)
    );
  }, initialCount);
};

export const futurePercentage = createSelector(
  goalLoanAppInfo,
  clientSelectors.families,
  ({ answers }, families) => {
    let answered = 0;
    let totalClients = 0;
    const requiredQs = REQUIRED_FUTURE_QS[locale.countryCode];
    families.forEach((family) => {
      const familyAnswers = (answers[family.contactId] || {})[HORIZON] || {};
      totalClients += family.clients.length;
      answered += getFamilyAnsweredQsCount(
        { ...family, answers: familyAnswers },
        requiredQs,
        answered,
      );
    });
    return (answered / (totalClients * requiredQs.length)) * 100;
  },
);

export const profilePercentage = createSelector(
  goalLoanApplication,
  (application) => {
    const { profilePercentages } = application;
    const total = profilePercentages.reduce((sum, p) => {
      return sum + p.percent;
    }, 0);
    return total / profilePercentages.length;
  },
);

export const teamPercentage = createSelector(
  goalLoanAppInfo,
  clientSelectors.primaryFamily,
  ({ answers }, family) => {
    const familyAnswers = (answers[family.contactId] || {})[TEAM] || {};
    const questions = REQUIRED_TEAM_QS[locale.countryCode];
    const questionsCount = questions.length;
    let answered = 0;
    questions.forEach((qId) => {
      if (familyAnswers[qId] !== undefined) {
        answered++;
      }
    });

    if (locale.isNZ) {
      if (
        [LIFE_AND_HEALTH_DETAILS, LIFE_AND_HEALTH_REASON].some(
          (qId) => familyAnswers[qId] !== undefined,
        )
      ) {
        answered++;
      }
      if (
        [GENERAL_INSURANCE_NZ_DETAILS, GENERAL_INSURANCE_NZ_REASON].some(
          (qId) => familyAnswers[qId] !== undefined,
        )
      ) {
        answered++;
      }
      return (answered / (questionsCount + 2)) * 100;
    }

    if (
      LIFESTYLE_INSURANCE_YES_NO_QS.some((qId) => familyAnswers[qId] === '1')
    ) {
      answered++;
    }
    return (answered / (questionsCount + 1)) * 100;
  },
);

export const sectionPercentages = createSelector(
  applicationSelectors.workingApplication,
  goalsPercentage,
  preferencesPercentage,
  profilePercentage,
  futurePercentage,
  teamPercentage,
  ({ metadata }, goals, preferences, profile, future, team) => {
    const sections = [
      { name: GOALS, percentage: goals },
      { name: PREFERENCES, percentage: preferences },
      { name: PROFILE, percentage: profile },
      { name: SERVICEABILITY, percentage: 0, visitOnly: true },
      { name: LOAN_AMOUNT, percentage: 0, visitOnly: true },
      { name: LOAN_STRUCTURES, percentage: 0, visitOnly: true }, // TODO: get loans percentage
      { name: FUTURE, percentage: future },
      { name: TEAM, percentage: team },
    ];
    return sections.reduce((map, s) => {
      const key = getHasVisitedKey(s.name);
      const visitPercent = metadata[key] ? 100 : 0;
      if (s.visitOnly) {
        map[s.name] = visitPercent;
      } else if (s.percentage === 0 && visitPercent === 100) {
        map[s.name] = 0;
      } else {
        map[s.name] = s.percentage * 0.9 + visitPercent * 0.1;
      }
      return map;
    }, {});
  },
);

export const canViewServiceabilityTile = createSelector(
  clientSelectors.adultClients,
  clientSelectors.canClientsViewServiceability,
  (clients, serviceabilityCompletion) => {
    const mergeClients = clients.reduce((accum, curr) => {
      if (!accum[curr.contactId]) {
        accum[curr.contactId] = { canViewServiceability: true };
      }
      accum[curr.contactId].canViewServiceability =
        accum[curr.contactId].canViewServiceability &&
        serviceabilityCompletion(curr.contactId);

      return accum;
    }, {});
    return Object.values(mergeClients).some(
      ({ canViewServiceability }) => canViewServiceability,
    );
  },
);

export const canGenerateProposal = createSelector(
  sectionPercentages,
  (percentages) => Object.values(percentages).every((p) => p === 100),
);

export const goalsSectionText = createSelector(
  applicationSelectors.workingApplication,
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ primaryGoals }) => {
    const goals = { purchase: [], refinance: [] };
    Object.keys(primaryGoals).forEach((key) => {
      if (primaryGoals[key]) {
        const purchaseGoal = GOAL_OPTIONS_PURCHASE(locale)().find(
          (g) => g.value === key,
        );
        if (purchaseGoal) {
          const label = purchaseGoal.sectionText || purchaseGoal.label;
          goals.purchase.push(`${purchaseGoal.prefix}${_.toLower(label)}`);
        }
        const refinanceGoal = GOAL_OPTIONS_REFINANCE(locale)().find(
          (g) => g.value === key,
        );
        if (refinanceGoal) {
          const label = refinanceGoal.sectionText || refinanceGoal.label;
          goals.refinance.push(`${refinanceGoal.prefix}${_.toLower(label)}`);
        }
      }
    });

    const pStr = goals.purchase.length
      ? `Purchase ${combineStringsGrammatically(goals.purchase)}`
      : '';
    const rStr = goals.refinance.length
      ? `Refinance ${combineStringsGrammatically(goals.refinance)}`
      : '';

    const combineStr = [pStr, rStr].filter((s) => s);

    return combineStr.length ? `${combineStr.join('. ')}.` : '';
  },
);

const preferredLendersSummary = createSelector(
  goalLoanAppInfo,
  clientSelectors.primaryFamily,
  goalLoanAppInfoSelectors.accreditedLenders,
  ({ answers }, family, accreditedLenders = lenders.userPanel) => {
    const familyAnswers = (answers[family.contactId] || {})[LOAN_OPTIONS] || {};
    const preferredLenders =
      familyAnswers[getQuestionId(PREFERRED_LENDERS)] || [];
    return preferredLenders.reduce((arr, id) => {
      const lender = accreditedLenders.find((l) => l.lenderId === id);
      if (lender) {
        arr.push(lender.lenderCode || lender.lenderName);
      }
      return arr;
    }, []);
  },
);

export const lendersSectionText = createSelector(
  goalLoanAppInfo,
  clientSelectors.primaryFamily,
  ({ answers }, family) => {
    const familyAnswers = (answers[family.contactId] || {})[LOAN_OPTIONS] || {};
    const currentLenders = familyAnswers[getQuestionId(CURRENT_BANK)] || [];
    const preferredLenders =
      familyAnswers[getQuestionId(PREFERRED_LENDERS)] || [];
    const selected = [...currentLenders, ...preferredLenders].reduce(
      (arr, id) => {
        const lender = lenders.userPanel.find((l) => l.lenderId === id);
        if (lender) {
          arr.push(lender.lenderName);
        }
        return arr;
      },
      [],
    );
    return selected.join(', ');
  },
);

export const preferencesSectionText = createSelector(
  goalLoanAppInfo,
  clientSelectors.primaryFamily,
  preferredLendersSummary,
  ({ questions: qs, answers: as }, family, preferredLendersText) => {
    const questions = qs[LOAN_OPTIONS] || {};
    const answers = (as[family.contactId] || {})[LOAN_OPTIONS] || {};
    const strings = preferredLendersText;
    const ratePreferencesId = getQuestionId(RATE_PREFERENCES);
    const repaymentPreferencesId = getQuestionId(REPAYMENT_PREFERENCES);
    const featurePreferencesId = getQuestionId(FEATURE_PREFERENCES);
    if (answers[ratePreferencesId]) {
      strings.push(`${_.capitalize(answers[ratePreferencesId])} rate`);
    }
    if (answers[repaymentPreferencesId] && questions[repaymentPreferencesId]) {
      answers[repaymentPreferencesId].forEach((a) => {
        const repayment = questions[repaymentPreferencesId].options.find(
          (o) => Number(o.value) === a,
        );
        if (repayment) {
          strings.push(repayment.label);
        }
      });
    }
    if (answers[featurePreferencesId] && questions[featurePreferencesId]) {
      answers[featurePreferencesId].forEach((a) => {
        const feature = questions[featurePreferencesId].options.find(
          (o) => Number(o.value) === a,
        );
        if (feature) {
          strings.push(feature.label);
        }
      });
    }
    return strings.join(', ');
  },
);

export const profileSectionText = createSelector(
  clientSelectors.adultClients,
  goalLoanApplication,
  (clients, application) => {
    const { profilePercentages } = application;
    const arr = clients.map((c) => {
      const percentage = profilePercentages.find((p) => p.clientId === c.id);
      return {
        displayName: c.displayName,
        percentage: percentage && percentage.percent,
      };
    });
    return arr.reduce((str, a, i) => {
      return `${i !== 0 ? `${str}, ` : ''}${a.displayName} - ${a.percentage}%`;
    }, '');
  },
);

export const futureSectionText = createSelector(
  goalLoanAppInfo,
  clientSelectors.families,
  ({ answers }, families) => {
    const arr = [];
    families.forEach((f) => {
      const familyAnswers = (answers[f.contactId] || {})[HORIZON] || {};
      let totalClientAnswered = 0;
      const futureQs = REQUIRED_FUTURE_QS[locale.countryCode];
      f.clients.forEach((c) => {
        totalClientAnswered += futureQs.reduce((total, qId) => {
          if (familyAnswers[c.id] && familyAnswers[c.id][qId]) {
            return total + 1;
          }
          return total;
        }, 0);
      });
      const isCompleted =
        futureQs.length * f.clients.length === totalClientAnswered;
      const obj = {
        family: f.familyFirstName,
        isCompleted,
        contactId: f.contactId,
      };
      arr.push(obj);
    });
    return arr.some((a) => a.isCompleted)
      ? _.sortBy(arr, ['isCompleted'])
      : null;
  },
);

export const serviceabilityText = createSelector(
  canViewServiceabilityTile,
  clientSelectors.families,
  goalLoanApplication,
  (c, families, application) => {
    const { serviceabilityMaxBorrows } = application;

    if (!serviceabilityMaxBorrows || !serviceabilityMaxBorrows.length) {
      return () =>
        c
          ? 'See how much you can borrow'
          : 'Complete Goals and Profile for results!';
    }

    const arr = families
      .map((f) => {
        const findMaxBorrow = serviceabilityMaxBorrows.find(
          (p) => p.contactId === f.contactId,
        );
        return {
          displayName: f.familyFullName,
          maxBorrow: findMaxBorrow && findMaxBorrow.maximumLoanAmount,
        };
      })
      .filter((f) => f.maxBorrow);

    return (intl) =>
      arr.reduce(
        (str, a, i) =>
          `${i !== 0 ? `${str}, ` : ''}${a.displayName} - ${formatCurrency(
            intl,
          )(a.maxBorrow)}`,
        '',
      );
  },
);

export const loanAmountText = createSelector(
  canViewServiceabilityTile,
  goalLoanApplication,
  (c, application) => {
    const { serviceabilityMaxBorrows } = application;

    if (!c && (!serviceabilityMaxBorrows || !serviceabilityMaxBorrows.length)) {
      return 'Complete Goals and Profile for results!';
    }

    return 'Find out how much you need.';
  },
);

export const teamText = createSelector(
  goalLoanAppInfo,
  clientSelectors.primaryFamily,
  ({ answers }, family) => {
    const familyAnswers = (answers[family.contactId] || {})[TEAM] || {};
    const yesNoQuestions = REQUIRED_TEAM_QS[locale.countryCode];
    if (locale.isAU) {
      yesNoQuestions.push(LIFESTYLE_INSURANCE_OBLIGATION);
    }

    const yesNoAnswers = yesNoQuestions.map((q) => familyAnswers[q]);
    if (yesNoAnswers.includes(YES_NO_OPTION_VALUES.YES)) {
      return TEAM_MESSAGES.SUBTEXT_ANSWERED_YES;
    } else if (yesNoAnswers.includes(YES_NO_OPTION_VALUES.NO)) {
      return TEAM_MESSAGES.SUBTEXT_ANSWERED_NO;
    }
    return TEAM_MESSAGES.SUBTEXT_DEFAULT;
  },
);

export const sectionTexts = createSelector(
  goalsSectionText,
  preferencesSectionText,
  profileSectionText,
  futureSectionText,
  serviceabilityText,
  loanAmountText,
  teamText,
  (goals, preferences, profile, future, serviceability, loanAmount, team) => ({
    goals,
    preferences,
    profile,
    serviceability,
    loanAmount,
    loans: '',
    future,
    team,
  }),
);

export const futurePlansCompleted = createSelector(futureSectionText, (a) => {
  return (a || []).reduce((map, obj) => {
    map[obj.contactId] = obj.isCompleted;
    return map;
  }, {});
});

export const servicesCompleted = createSelector(
  teamPercentage,
  (a) => a >= 100,
);
