import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as actionTypes from './addressActionTypes';
import * as individualActionTypes from './individualAddressActionTypes';

const addressActions = createActions({}, ...Object.values(actionTypes));
export default {
  ...addressActions,
  ...createIndividualisedActions(individualActionTypes),
};
