import { takeEvery, all, put, call } from 'redux-saga/effects';

import {
  REQUEST_AGGREGATOR,
  REQUEST_AGGREGATOR_BY_FAMILY_ID,
} from 'actions/aggregatorActionTypes';
import aggregatorActions from 'actions/aggregatorActions';

import { getAggregator, getAggregatorByFamilyId } from 'services/aggregatorApi';
import { monitorAsyncRequest } from 'lib/sagaHelpers.js';

export function* requestAggregator({ payload: aggregatorId }) {
  const aggregator = yield call(getAggregator, aggregatorId);
  yield put(aggregatorActions.setAggregator(aggregator));
}

export function* requestAggregatorByFamilyId(familyId) {
  const aggregator = yield call(getAggregatorByFamilyId, familyId);
  yield put(aggregatorActions.setAggregator(aggregator));
}

export default function* aggregatorSagas() {
  yield all([
    yield monitorAsyncRequest(takeEvery, REQUEST_AGGREGATOR, requestAggregator),
    yield monitorAsyncRequest(
      takeEvery,
      REQUEST_AGGREGATOR_BY_FAMILY_ID,
      requestAggregator,
    ),
  ]);
}
