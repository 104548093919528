import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Modal.css';

export function Modal(props) {
  const {
    title,
    content,
    theme,
    hideModal,
    isCloseButtonVisible = true,
    closeOnOverlay,
  } = props;
  const rootStyles = classNames(styles.root, { [styles[theme]]: !!theme });

  const onClickOverlay = ({ target, currentTarget }) => {
    if (!closeOnOverlay) {
      return;
    }
    if (target === currentTarget && hideModal) {
      hideModal();
    }
  };

  return (
    <div className={styles.overlay} onClick={onClickOverlay}>
      <div className={rootStyles}>
        <div className={styles.header}>
          {title}
          {isCloseButtonVisible && (
            <i
              className={`sl-custom-cross-1 ${styles.closeIcon}`}
              onClick={hideModal}
            />
          )}
        </div>
        <div className={styles.popupBody}>{content}</div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.node,
  theme: PropTypes.string,
  closeOnOverlay: PropTypes.bool,
  hideModal: PropTypes.func,
  isCloseButtonVisible: PropTypes.bool,
};

export default Modal;
