const LOGGER_SESSION_DATA_KEY = 'loggerSessionData';

// NOTE: move to class once MILLI has moved to typescript
export default {
  getData: () => {
    const sessionDataString = sessionStorage.getItem(LOGGER_SESSION_DATA_KEY);
    return sessionDataString ? JSON.parse(sessionDataString) : {};
  },
  setData: (data) => {
    const sessionDataString = data && JSON.stringify(data);
    sessionStorage.setItem(LOGGER_SESSION_DATA_KEY, sessionDataString);
  },
};
