// NOTE if you include maximumFractionDigits or minimumFractionDigits, you have to define BOTH, firefox crashes otherwise

const commonFormats = {
  number: {
    rate: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
    float: {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
    percentage: {
      style: 'percent',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    },
  },
};

export default {
  'en-AU': {
    number: {
      ...commonFormats.number,
      currency: {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
      currencyFloat: {
        style: 'currency',
        currency: 'AUD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
    },
  },
  'en-NZ': {
    number: {
      ...commonFormats.number,
      currency: {
        style: 'currency',
        currency: 'NZD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
      currencyFloat: {
        style: 'currency',
        currency: 'NZD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      },
    },
  },
};
