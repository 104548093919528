import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AnimatedList from 'components/AnimatedList/AnimatedList';

import Check from 'assets/icons/Check';
import styles from './AnimatedChecklist.css';

export const ChecklistItem = ({ text }) => (
  <div className={styles.item}>
    <div className={styles.checkbox}>
      <Check className={styles.check} />
    </div>
    <div className={styles.title}>
      <h4>{text}</h4>
    </div>
  </div>
);

ChecklistItem.propTypes = {
  text: PropTypes.string.isRequired,
};

class AnimatedChecklist extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    itemTransitionTimeMs: PropTypes.number,
  };

  static defaultProps = {
    itemTransitionTimeMs: 700,
  };

  render() {
    const itemsToDisplay = this.props.items.map((item, i) => (
      <ChecklistItem key={`${item}_${i}`} text={item} />
    ));

    return (
      <AnimatedList
        {...this.props}
        transitionName='flyUp'
        items={itemsToDisplay}
        className={styles.root}
      />
    );
  }
}

export default AnimatedChecklist;
