import { LIABILITIES_API_PATH } from 'shared/constants/apiPaths';

import { axiosWithAuth, returnJSON } from './baseApi';

export function putLiability(liability) {
  return axiosWithAuth
    .put(`${LIABILITIES_API_PATH}/${liability.id}`, liability)
    .then(returnJSON);
}

export function delLiability(liabilityId) {
  return axiosWithAuth
    .delete(`${LIABILITIES_API_PATH}/${liabilityId}`)
    .then(returnJSON);
}
