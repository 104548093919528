import { LENDER_API_PATH } from 'shared/constants/apiPaths';
import axios, { returnJSON, throwJSON } from './baseApi';

export const getAccreditedLenders = (adviserId) => {
  return axios
    .get(`${LENDER_API_PATH}/${adviserId}/accredited-lenders`)
    .then(returnJSON)
    .catch(throwJSON);
};

export default function find(keyword) {
  return axios
    .get(LENDER_API_PATH, { params: { keyword } })
    .then(returnJSON, throwJSON);
}
