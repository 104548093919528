export const BUSINESS_UNIT_FINANCIAL_SERVICES_TEAM = {
  id: 1,
  name: 'Financial Services Team',
};
export const BUSINESS_UNIT_WEALTH_MARKET = { id: 3, name: 'Wealth Market' };
export const BUSINESS_UNIT_NZFSG = { id: 4, name: 'NZFSG' };
export const BUSINESS_UNIT_LM_FRANCHISE = {
  id: 5,
  name: 'Loan Market Franchise',
};
export const BUSINESS_UNIT_MYCRM_TEST_ACCOUNTS = {
  id: 6,
  name: 'MyCRM Test Accounts',
};
export const BUSINESS_UNIT_LM_CORPORATE = { id: 7, name: 'LM Corporate' };
export const BUSINESS_UNIT_EMOCA = { id: 8, name: 'eMOCA' };
export const BUSINESS_UNIT_LM_BROKER_SERVICES = {
  id: 10,
  name: 'LM Broker Services',
};
