import _ from 'lodash';
import { isResidential } from 'shared/lib/checkLoanPurpose';
import { stateExtractor } from 'shared/lib/utils';
import { pickNumeric } from 'lib/utils/numberUtils';
import { getProspectiveProperty } from 'shared/lib/propertyHelper';
import {
  MOVING_COST,
  CONVEYANCER_COST,
  RENOVATION_COST,
} from 'shared/constants/defaults';

function generateStateParams(state) {
  switch (state) {
    case 'VIC':
      return {
        paymentMethod: 'Electronic Transaction',
        foreignPurchaser: false,
        location: 'Melbourne', // only default to melbourne for now, alternatively Regional Victoria
      };
    case 'ACT':
      return {
        dependent: 1,
        isPensioner: false,
      };
    case 'NT':
      return { isPensioner: false };
    case 'WA':
      return {
        propertyLocation: 'South of 26th parallel (from Kalbarri 6536)',
      };
    default:
      return {};
  }
}

export default function generatePurchaseCostParams(scenario) {
  const prospectiveProperty = getProspectiveProperty(scenario);
  const state =
    prospectiveProperty.state || stateExtractor(prospectiveProperty.locality);

  const params = {
    state,
    propertyValue: _.clamp(prospectiveProperty.value, 20000, 99999999),
    propertyType: isResidential(scenario) ? 'Primary Residence' : 'Investment',
    propertyDesc: prospectiveProperty.type,
    firstHomeBuyer:
      prospectiveProperty && prospectiveProperty.FHOGEligibility === 'true',
    totalIncome: 75000,
    movingCost: pickNumeric(prospectiveProperty.movingCost, MOVING_COST),
    conveyancerCost: pickNumeric(
      prospectiveProperty.conveyancerCost,
      CONVEYANCER_COST,
    ),
    renovationCost: pickNumeric(
      prospectiveProperty.renovationCost,
      RENOVATION_COST,
    ),
  };

  return Object.assign(params, generateStateParams(state));
}
