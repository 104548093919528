import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SVGInline from 'react-svg-inline';
import Pop from 'components/animations/Pop';
import Rotation from 'components/animations/Rotation';
import DrawSVG from 'components/animations/DrawSVG';
import SpinningWheel from 'assets/icons/spinning-wheel.svg';
import GreenTick from 'assets/icons/green-tick.svg';
import Tower from 'assets/icons/tower.svg';
import GhostEyes from 'assets/icons/ghost-eyes.gif';
import styles from './PopupStatus.css';
import { isNetworkOffline, NETWORK_ERROR_MESSAGE } from 'lib/errorHelper';
import { isNetworkError } from 'axios-retry';

import ActionableErroredStatus from './ActionableErroredStatus';

function ErroredStatus({ errors }) {
  const error = errors && errors[0] && errors[0].error;
  const networkError = isNetworkOffline(error) || isNetworkError(error);
  const defaultMessage =
    'Sorry! Something went wrong. We’re fixing the problem. Try again or come back later';
  const message = networkError ? NETWORK_ERROR_MESSAGE : defaultMessage;
  const icon = networkError ? (
    <SVGInline
      width='118px'
      height='118px'
      svg={Tower}
      data-testid='towerIcon'
    />
  ) : (
    <img
      width='88px'
      height='119px'
      src={GhostEyes}
      data-testid='ghostEyesIcon'
    />
  );

  return (
    <div id='errorStatus' className={styles.status}>
      <div className={styles.description}>
        <strong className={classNames(styles.heading, styles.erroredHeading)}>
          Oops!
        </strong>
        <span>{message}</span>
      </div>
      <Pop>{icon}</Pop>
    </div>
  );
}

ErroredStatus.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object),
};

function ProcessingStatus() {
  return (
    <div id='proceedingStatus' className={styles.status}>
      <div className={styles.description}>
        <strong
          className={classNames(styles.heading, styles.processingHeading)}
        >
          Saving
        </strong>
      </div>
      <Rotation width='117px' height='117px'>
        <SVGInline svg={SpinningWheel} />
      </Rotation>
    </div>
  );
}

function ProcessedStatus() {
  return (
    <div id='proceededStatus' className={styles.status}>
      <div className={styles.description}>
        <strong className={classNames(styles.heading, styles.processedHeading)}>
          Saved
        </strong>
      </div>
      <Pop>
        <DrawSVG>
          <SVGInline width='117px' height='117px' svg={GreenTick} />
        </DrawSVG>
      </Pop>
    </div>
  );
}

class PopupStatus extends Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    errors: PropTypes.arrayOf(PropTypes.object),
    actionableError: PropTypes.object,
  };

  static componentMap = {
    errored: ErroredStatus,
    erroredActionable: ActionableErroredStatus,
    processing: ProcessingStatus,
    processed: ProcessedStatus,
  };

  render() {
    const { status, errors, actionableError } = this.props;
    return React.createElement(PopupStatus.componentMap[status], {
      errors,
      actionableError,
    });
  }
}

export default PopupStatus;
