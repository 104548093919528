export const CLEAR_WORKING_FUNDING = 'clearWorkingFunding';
export const LOAD_FUNDING = 'loadFunding';
export const LOAD_NEW_CLIENT_FUNDING = 'loadNewClientFunding';
export const SET_FUNDING = 'setFunding';
export const REMOVE_FUNDING = 'removeFunding';
export const SET_NEW_FUNDING = 'setNewFunding';
export const SET_NEW_FUNDINGS = 'setNewFundings';
export const LOAD_NEW_FUNDING = 'loadNewFunding';

export const CREATE_FUNDING = 'createFunding';
export const UPDATE_FUNDING = 'updateFunding';
export const DELETE_FUNDING = 'deleteFunding';
export const REQUEST_FUNDINGS_FROM_PROPERTY = 'requestFundingsFromProperty';
