import React from 'react';
import PropTypes from 'prop-types';
import Question from 'components/Question/Question';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import DateSelector from 'components/DateSelector/DateSelector';

import moment from 'moment';

import { YES_NO_OPTIONS } from 'constants/options';

const ImportantDateGroupedQuestion = (props) => {
  const {
    type,
    hasImportantDateQ,
    importantDateQ,
    hasImportantDate,
    importantDate,
    setHasImportantDateOfType,
    setImportantDateDay,
    setImportantDateMonth,
    setImportantDateYear,
    isLocked,
    formatMessage,
    messages,
    theme,
    hideTopLine,
  } = props;

  const onRadioChange = (value) => setHasImportantDateOfType({ type, value });
  const onDatePartChange = (setter) => (value) => setter({ value, type });

  return (
    <>
      <Question
        {...hasImportantDateQ}
        label={formatMessage(messages.hasImportantDate)}
        direction='row'
        theme={theme}
      >
        <RadioButtonList
          action={onRadioChange}
          value={hasImportantDate}
          items={YES_NO_OPTIONS}
          disabled={isLocked}
        />
      </Question>

      <Question
        {...importantDateQ}
        label={formatMessage(messages.importantDate)}
        direction='row'
        theme={theme}
        hideTopLine={hideTopLine}
      >
        <DateSelector
          type='date'
          dayAction={onDatePartChange(setImportantDateDay)}
          day={importantDate.date}
          monthAction={onDatePartChange(setImportantDateMonth)}
          month={importantDate.month}
          yearAction={onDatePartChange(setImportantDateYear)}
          year={importantDate.year}
          minDate={moment().subtract(5, 'years')}
          maxDate={moment().add(10, 'years')}
          yearsOrder='ascending'
          disabled={isLocked}
        />
      </Question>
    </>
  );
};

ImportantDateGroupedQuestion.propTypes = {
  type: PropTypes.string.isRequired,
  hasImportantDateQ: PropTypes.object,
  importantDateQ: PropTypes.object,
  hasImportantDate: PropTypes.bool,
  importantDate: PropTypes.object,
  setHasImportantDateOfType: PropTypes.func.isRequired,
  setImportantDateDay: PropTypes.func.isRequired,
  setImportantDateMonth: PropTypes.func.isRequired,
  setImportantDateYear: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  formatMessage: PropTypes.func,
  messages: PropTypes.object,
  theme: PropTypes.string,
  hideTopLine: PropTypes.bool,
};

export default ImportantDateGroupedQuestion;
