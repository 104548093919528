import { ACTION_TOKEN_API_PATH } from 'shared/constants/apiPaths';
import axios, { returnJSON, throwJSON } from './baseApi';

export const getTokenStatus = (token) =>
  axios
    .get(`${ACTION_TOKEN_API_PATH}/get-token-status`, {
      params: { token },
    })
    .then(returnJSON, throwJSON);

export const executeToken = (token) =>
  axios
    .post(`${ACTION_TOKEN_API_PATH}/execute-token`, { token })
    .then(returnJSON, throwJSON);
