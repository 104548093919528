export const BRIDGING = 'bridging';
export const COMMERCIAL = 'commercial';
export const CONSTRUCTION = 'construction';
export const EQUITY = 'equity';
export const INVESTMENT = 'investment';
export const LINE_OF_CREDIT = 'line_of_credit';
export const OWNER_OCCUPIED = 'owner_occupied';
export const REVERSE = 'reverse';
export const SMSF = 'smsf';
export const VACANT_LAND = 'vacant_land';
export const EQUIPMENT_FINANCE = 'equipment_finance';
export const ALL_PRODUCT_TYPES = 'all';
