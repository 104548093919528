import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import {
  GET_INCOMES,
  CREATE_INCOME,
  UPDATE_INCOME,
  DELETE_INCOME,
  CONFIRM_INCOMES,
  CLEAR_WORKING_INCOME,
} from 'actions/incomeActionTypes';
import incomeActions from 'actions/incomeActions';
import employmentActions from 'actions/employmentActions';

import * as contactSelectors from 'selectors/contactSelectors';
import * as employmentSelectors from 'selectors/employmentSelectors';
import * as incomeTypeSelectors from 'selectors/incomeTypeSelectors';

import { postIncomeForProperty } from 'services/propertyApi';
import {
  postIncomeForContact,
  putIncomeForContact,
  deleteIncomeForContact,
  getContactIncomes,
} from 'services/contactApi';
import { postRealEstateIncomeForAsset } from 'services/assetApi';

import { createEmployment } from 'sagas/employmentSagas';

import { EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT } from 'shared/constants/myCRMTypes/employments';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';

export function* getIncomes() {
  const primaryContact = yield select(contactSelectors.primaryContact);
  const newIncomes = yield getContactIncomes(primaryContact.id);
  yield put(incomeActions.setNewIncomes(newIncomes));
}

export function* newOrExistingEmploymentId({
  category,
  employmentId,
  clientIds,
}) {
  const categoryRequiresEmployment = yield select(
    incomeTypeSelectors.categoryRequiresEmployment,
  );
  const previousEmploymentId = EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT.id;
  const newEmploymentRequired =
    categoryRequiresEmployment(category) &&
    (employmentId === 'new' || employmentId === undefined);

  if (newEmploymentRequired) {
    const workingEmployment = yield select(employmentSelectors.working);
    const newWorkingEmployment = workingEmployment('new');
    if (!newWorkingEmployment.employerName) {
      return [];
    } // skip employment creation in bank connect
    const employment = yield createEmployment({
      payload: {
        ...newWorkingEmployment,
        clientId: clientIds[0],
      },
    });
    return [employment.id, employment.statusId === previousEmploymentId];
  }
  const employment = yield call(
    yield select(employmentSelectors.entity),
    Number(employmentId),
  );
  return [
    employmentId,
    employment && employment.statusId === previousEmploymentId,
  ];
}

export function* createIncome({ payload }) {
  const [employmentId, shouldRefreshIncomes] = yield newOrExistingEmploymentId(
    payload,
  );
  const newPayload = { ...payload, employmentId };

  let income;
  if (payload.assetId) {
    income = yield postRealEstateIncomeForAsset(newPayload);
  } else if (payload.propertyId) {
    income = yield postIncomeForProperty(newPayload);
  } else {
    const primaryContact = yield select(contactSelectors.primaryContact);
    income = yield postIncomeForContact(primaryContact.contactId, newPayload);
  }
  yield put(incomeActions.setNewIncome(income));

  shouldRefreshIncomes && (yield call(getIncomes));
}

export function* updateIncome({ payload }) {
  const [employmentId, shouldRefreshIncomes] = yield newOrExistingEmploymentId(
    payload,
  );
  const newPayload = { ...payload, employmentId };
  const primaryContact = yield select(contactSelectors.primaryContact);

  const income = yield putIncomeForContact(primaryContact.id, newPayload);
  yield put(incomeActions.setIncome({ ...payload, ...income }));

  shouldRefreshIncomes && (yield call(getIncomes));
}

export function* confirmIncomes({ payload: { incomes } }) {
  yield all(incomes.map((payload) => call(updateIncome, { payload })));
}

export function* deleteIncome({ payload }) {
  const primaryContact = yield select(contactSelectors.primaryContact);
  yield deleteIncomeForContact(primaryContact.id, payload);
  yield put(incomeActions.removeIncome(payload));
}

export function* clearWorkingNewEmployment() {
  yield put(employmentActions.clearWorkingEmployment('new'));
}

export default function* incomeSagas() {
  yield all([
    takeEvery(GET_INCOMES, getIncomes),
    monitorAsyncRequest(takeEvery, CREATE_INCOME, createIncome),
    monitorAsyncRequest(takeEvery, UPDATE_INCOME, updateIncome),
    monitorAsyncRequest(takeEvery, DELETE_INCOME, deleteIncome),
    monitorAsyncRequest(takeEvery, CONFIRM_INCOMES, confirmIncomes),
    takeEvery(CLEAR_WORKING_INCOME, clearWorkingNewEmployment),
  ]);
}
