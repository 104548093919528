import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as actionTypes from './expenseActionTypes';
import * as individualActionTypes from './individualExpenseActionTypes';

export const expenseActions = createActions({}, ...Object.values(actionTypes));

export default {
  ...expenseActions,
  ...createIndividualisedActions(individualActionTypes),
};
