import React from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import Selection from 'components/Selection/Selection';
import { generateUniqueID } from 'lib/utils/stringUtils';

import styles from './CurrencyInputWithSelection.css';

const CurrencyInputWithSelection = ({
  id,
  label,
  hasError,
  action,
  value,
  placeholder,
  selectionAction,
  selectionValue,
  selectionItems,
  selectionDefault,
  dispatchActionOnBlur,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  forceFocus,
  disabled,
  maxLength,
}) => (
  <div
    id={generateUniqueID('CurrencyInputWithSelection', id)}
    className={styles.root}
  >
    {label && (
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    )}
    <div className={styles.group}>
      <CurrencyInput
        id={id}
        maxLength={maxLength || 9}
        pattern='\d*'
        placeholder={placeholder}
        action={action}
        className={styles.input}
        value={value}
        hasError={hasError}
        dispatchActionOnBlur={dispatchActionOnBlur}
        forceFocus={forceFocus}
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />

      <Selection
        id={generateUniqueID('Selection', id)}
        action={selectionAction}
        value={selectionValue}
        items={selectionItems}
        selectionDefault={selectionDefault}
        disabled={disabled}
        rootClassName={styles.selectionWrapper}
        smallArrow
      />
    </div>
  </div>
);

CurrencyInputWithSelection.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.func.isRequired,
  selectionAction: PropTypes.func.isRequired,
  value: PropTypes.number,
  selectionItems: PropTypes.arrayOf(PropTypes.object),
  selectionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectionDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  forceFocus: PropTypes.bool,
  dispatchActionOnBlur: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.number,
};

CurrencyInputWithSelection.defaultProps = {
  hasError: false,
  changeCallbackOnBlur: null,
};

export default CurrencyInputWithSelection;
