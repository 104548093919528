import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import locale from 'config/locale';

import contactActions from 'actions/contactActions';
import addressActions from 'actions/addressActions';

import {
  REQUIRED,
  REQUIRED_FORMATTED_ADDRESS,
  EMAIL,
  PHONE_NUMBER_INTERNATIONAL,
  PHONE_NUMBER,
  INVALID_NZBN,
} from 'constants/validators';
import { manageQuestionsPropTypes } from 'hocs/manageQuestions';
import commonMessages from 'constants/commonMessages';

import Question from 'components/Question/Question';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import Input from 'components/Input/Input';
import MobileInputIntl from 'components/MobileInputIntl/MobileInputIntl';
import Button from 'components/Button/Button';
import Questions from 'lib/Questions';
import { featureFlags } from 'lib/rollout';
import { intlMobile } from 'shared/lib/utils';

export const messages = defineMessages({
  businessName: {
    id: 'CompanyForm.businessName',
    defaultMessage: 'Business name',
  },
  tfn: {
    id: 'CompanyForm.tfn',
    defaultMessage: 'Organisation tax number',
  },
  acn: {
    id: 'CompanyForm.acn',
    defaultMessage: 'Organisation company number',
  },
  nzbn: {
    id: 'CompanyForm.nzbn',
    defaultMessage: 'NZBN',
  },
  description: {
    id: 'CompanyForm.description',
    defaultMessage: 'Description',
  },
  officePhone: {
    id: 'CompanyForm.officePhone',
    defaultMessage: 'Office phone number',
  },
  officeEmail: {
    id: 'CompanyForm.officeEmail',
    defaultMessage: 'Office email address',
  },
  officeAddress: {
    id: 'CompanyForm.officeAddress',
    defaultMessage: 'Office address',
  },
});

export const propsTransformForQuestion = (props) => ({
  ...props,
  officeAddress:
    props.workingCurrentAddress && props.workingCurrentAddress.address,
});

export const questionSet = (props) => {
  const questions = new Questions();
  questions.addBranch(
    ['businessName', REQUIRED],
    'description',
    ['officeAddress', REQUIRED_FORMATTED_ADDRESS],
    [
      'officePhone',
      featureFlags.internationalPhoneNumbers.isEnabled()
        ? PHONE_NUMBER_INTERNATIONAL
        : PHONE_NUMBER,
    ],
    ['officeEmail', REQUIRED, EMAIL],
    ['tfn'],
    ['acn'],
  );
  const showNZBN = props.hasBluestoneOnlineApplicationFlag && locale.isNZ;
  if (showNZBN) {
    questions.add(['nzbn', INVALID_NZBN]);
  }
  if (!props.fieldsOnly) {
    questions.add('saveButton');
  }
  return questions.arrayOfQuestions();
};

export class DisconnectedCompanyForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    popup: PropTypes.bool,
    fieldsOnly: PropTypes.bool,
    setBusinessName: PropTypes.func.isRequired,
    setTfn: PropTypes.func.isRequired,
    setAcn: PropTypes.func.isRequired,
    setOfficeEmail: PropTypes.func.isRequired,
    setOfficePhone: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    setNZBN: PropTypes.func.isRequired,
    setCurrentAddressAddress: PropTypes.func.isRequired,
    setCurrentAddressError: PropTypes.func.isRequired,
    setIntlAddress: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    isLocked: PropTypes.bool.isRequired,
    saveCompany: PropTypes.func,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = false;

  onSubmit = () => {
    const { saveCompany, company } = this.props;
    saveCompany(company);
  };

  renderFields = () => {
    const {
      workingCurrentAddress,
      company,
      popup,
      questions,
      intl: { formatMessage },
      isLocked,
      setBusinessName,
      setDescription,
      setNZBN,
      setTfn,
      setAcn,
      setCurrentAddressAddress,
      setCurrentAddressError,
      setIntlAddress,
      setOfficeEmail,
      setOfficePhone,
    } = this.props;
    const questionClass = popup && 'fullWidthInput';

    return [
      <Question
        key='question.businessName'
        {...questions.businessName}
        label={formatMessage(messages.businessName)}
        className={questionClass}
      >
        <Input action={setBusinessName} value={company.businessName} />
      </Question>,
      <Question
        key='question.description'
        {...questions.description}
        label={formatMessage(messages.description)}
        className={questionClass}
      >
        <Input action={setDescription} value={company.description} />
      </Question>,
      <Question
        key='question.nzbn'
        {...questions.nzbn}
        label={formatMessage(messages.nzbn)}
        className={questionClass}
      >
        <Input value={company.nzbn} maxLength={13} action={setNZBN} />
      </Question>,
      <Question
        key='question.officeAddress'
        {...questions.officeAddress}
        label={formatMessage(messages.officeAddress)}
        className={questionClass}
      >
        <AutocompleteAddress
          setError={setCurrentAddressError}
          errorId='officeAddress'
          action={setCurrentAddressAddress}
          value={
            workingCurrentAddress &&
            workingCurrentAddress.address.formattedAddress
          }
          intlValue={workingCurrentAddress && workingCurrentAddress.address}
          addressId={workingCurrentAddress && workingCurrentAddress.id}
          intlOnChange={setIntlAddress}
          disabled={isLocked}
        />
      </Question>,
      <Question
        key='question.officePhone'
        {...questions.officePhone}
        label={formatMessage(messages.officePhone)}
        className={questionClass}
      >
        {featureFlags.internationalPhoneNumbers.isEnabled() ? (
          <MobileInputIntl
            onChange={setOfficePhone}
            value={intlMobile(company.officePhone)}
            countryCode={company.officePhone.countryCode}
            dialCode={company.officePhone.dialCode}
            disabled={isLocked}
          />
        ) : (
          <Input
            value={intlMobile(company.officePhone)}
            action={setOfficePhone}
            disabled={isLocked}
          />
        )}
      </Question>,
      <Question
        key='question.officeEmail'
        {...questions.officeEmail}
        label={formatMessage(messages.officeEmail)}
        className={questionClass}
      >
        <Input action={setOfficeEmail} value={company.officeEmail} />
      </Question>,
      <Question
        key='question.tfn'
        {...questions.tfn}
        label={formatMessage(messages.tfn)}
        className={questionClass}
      >
        <Input action={setTfn} value={company.tfn} />
      </Question>,
      <Question
        key='question.acn'
        {...questions.acn}
        label={formatMessage(messages.acn)}
        className={questionClass}
      >
        <Input action={setAcn} value={company.acn} />
      </Question>,
    ];
  };

  render() {
    const {
      fieldsOnly,
      questions,
      formCompleted,
      intl: { formatMessage },
    } = this.props;

    const fields = this.renderFields();
    if (fieldsOnly) {
      return <div>{fields}</div>;
    }

    return (
      <div>
        {fields}
        <Question {...questions.saveButton} className='fullWidthButton'>
          <Button
            theme='linkButton'
            disabled={!formCompleted}
            onClick={this.onSubmit}
          >
            {formatMessage(commonMessages.save)}
          </Button>
        </Question>
        <input className='hidden' disabled={!formCompleted} type='submit' />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = (ownProps.company && ownProps.company.id) || 'new';
  const currentAddressId =
    ownProps.workingCurrentAddress && ownProps.workingCurrentAddress.id;

  const actions = {
    setBusinessName: contactActions.setContactBusinessName(id),
    setDescription: contactActions.setContactDescription(id),
    setNZBN: contactActions.setContactNZBN(id),
    setTfn: contactActions.setContactTfn(id),
    setOfficePhone: contactActions.setContactOfficePhone(id),
    setAcn: contactActions.setContactAcn(id),
    setOfficeEmail: contactActions.setContactOfficeEmail(id),
    setIntlAddress: addressActions.setIntlAddress,
    setCurrentAddressAddress: addressActions.setAddressAddress(
      currentAddressId,
    ),
    setCurrentAddressError: addressActions.setAddressError(currentAddressId),
  };
  return bindActionCreators(actions, dispatch);
};

export const ComposableCompanyForm = injectIntl(
  connect(null, mapDispatchToProps)(DisconnectedCompanyForm),
);
