import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/advisorOrgActionTypes';

export const initialState = {};
const advisorOrgReducer = handleActions(
  {
    [actionTypes.SET_ADVISOR_ORG_INFO](state, action) {
      return { ...state, ...action.payload };
    },
  },
  initialState,
);

export default advisorOrgReducer;
