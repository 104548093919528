import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import _ from 'lodash';

import * as employmentActionTypes from 'actions/employmentActionTypes';
import * as individualEmploymentActionTypes from 'actions/individualEmploymentActionTypes';

import {
  setEntity,
  removeEntity,
  setNewEntity,
  setNewEntities,
  insertNewEntities,
  setWorkingValue,
  loadEntityIntoWorking,
  clearWorking,
  setWorkingFormattedAddress,
  setWorkingIntlAddress,
  setError,
  setApplicationError,
} from 'lib/reducerHelper';
import { newAddress } from 'reducers/propertyReducer';

import { EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT } from 'shared/constants/myCRMTypes/employments';

const actionTypes = {
  ...employmentActionTypes,
  ...individualEmploymentActionTypes,
};

const newEmployment = {
  errors: {},
  dateStarted: {},
  dateEnded: {},
  employerAddress: newAddress,
  employerPhone: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newEmployment,
  },
  NZBNLoading: false,
};

const employmentReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_EMPLOYMENT](state, action) {
      return clearWorking(state, action, newEmployment);
    },
    [actionTypes.LOAD_EMPLOYMENT](state, action) {
      return loadEntityIntoWorking(state, action, newEmployment);
    },
    [actionTypes.REMOVE_EMPLOYMENT](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_EMPLOYMENT](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_EMPLOYMENT](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_NEW_EMPLOYMENTS](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.INSERT_NEW_EMPLOYMENTS](state, action) {
      return insertNewEntities(state, action);
    },
    [actionTypes.SET_EMPLOYMENT_CLIENT_ID](state, action) {
      return setWorkingValue(state, action.payload, 'clientId');
    },
    [actionTypes.SET_EMPLOYMENT_TYPE_ID](state, action) {
      return setWorkingValue(state, action.payload, 'typeId', _.toNumber);
    },
    [actionTypes.SET_EMPLOYMENT_BASIS_ID](state, action) {
      return setWorkingValue(state, action.payload, 'basisId', _.toNumber);
    },
    [actionTypes.SET_EMPLOYMENT_STATUS_ID](state, action) {
      const statusId = _.toNumber(action.payload.value);
      if (statusId !== EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT.id) {
        return update(state, {
          working: {
            [action.payload.id]: {
              dateEnded: { $set: {} },
              statusId: { $set: statusId },
            },
          },
        });
      }
      return setWorkingValue(state, action.payload, 'statusId', _.toNumber);
    },
    [actionTypes.SET_EMPLOYMENT_ROLE](state, action) {
      return setWorkingValue(state, action.payload, 'role');
    },
    [actionTypes.SET_EMPLOYMENT_NATURE_ID](state, action) {
      return setWorkingValue(state, action.payload, 'natureId', _.toNumber);
    },
    [actionTypes.SET_EMPLOYMENT_NZBN](state, action) {
      return setWorkingValue(state, action.payload, 'nzbn');
    },
    [actionTypes.SET_EMPLOYMENT_DATE_STARTED_MONTH](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dateStarted: { month: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_EMPLOYMENT_DATE_STARTED_YEAR](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dateStarted: { year: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_EMPLOYMENT_DATE_ENDED_MONTH](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dateEnded: { month: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_EMPLOYMENT_DATE_ENDED_YEAR](state, action) {
      return update(state, {
        working: {
          [action.payload.id]: {
            dateEnded: { year: { $set: action.payload.value } },
          },
        },
      });
    },
    [actionTypes.SET_EMPLOYMENT_EMPLOYER_TYPE_ID](state, action) {
      return setWorkingValue(state, action.payload, 'employerTypeId');
    },
    [actionTypes.SET_EMPLOYMENT_EMPLOYER_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'employerName');
    },
    [actionTypes.SET_EMPLOYMENT_CONTACT_FIRST_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'contactFirstName');
    },
    [actionTypes.SET_EMPLOYMENT_CONTACT_LAST_NAME](state, action) {
      return setWorkingValue(state, action.payload, 'contactLastName');
    },
    [actionTypes.SET_EMPLOYMENT_EMPLOYER_ADDRESS](state, action) {
      return setWorkingFormattedAddress(
        state,
        action.payload,
        'employerAddress',
      );
    },
    [actionTypes.SET_EMPLOYMENT_EMPLOYER_INTL_ADDRESS](state, action) {
      return setWorkingIntlAddress(state, action.payload, 'employerAddress');
    },
    [actionTypes.SET_EMPLOYMENT_EMPLOYER_PHONE](state, action) {
      return setWorkingValue(state, action.payload, 'employerPhone');
    },
    [actionTypes.SET_EMPLOYMENT_IS_PROBATION](state, action) {
      return setWorkingValue(state, action.payload, 'isProbation');
    },
    [actionTypes.SET_EMPLOYMENT_ERROR](state, action) {
      return setError(state, action);
    },
    [actionTypes.SET_EMPLOYMENT_ERRORS_ON_APPLICATION](state, action) {
      return setApplicationError(state, action);
    },
    [actionTypes.SET_NZBN_LOADING](state, action) {
      return update(state, { NZBNLoading: { $set: action.payload } });
    },
  },
  initialState,
);

export default employmentReducer;
