import React from 'react';
import PropTypes from 'prop-types';
import SharedChecklistGroup from '@bit/loanmarket.share.components.checklist-group';
import checkSVG from 'assets/icons/check.svg';
import circleSVG from 'assets/icons/circle.svg';

import styles from './ChecklistGroup.css';

const ChecklistGroup = ({
  id,
  groups,
  disabled,
  checkImage,
  circleImage,
  isMasonry,
}) => {
  const props = {
    id,
    groups,
    disabled,
    checkImage,
    circleImage,
    isMasonry,
  };

  // spread operator: BAD!
  return isMasonry ? (
    <div className={styles.masonryWrapper}>
      <SharedChecklistGroup {...props} />
    </div>
  ) : (
    <SharedChecklistGroup {...props} />
  );
};

ChecklistGroup.displayName = 'ChecklistGroup';

ChecklistGroup.propTypes = {
  id: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.object).isRequired,
      action: PropTypes.func.isRequired,
      value: PropTypes.object,
    }),
  ).isRequired,
  disabled: PropTypes.bool,
  checkImage: PropTypes.string,
  circleImage: PropTypes.string,
  isMasonry: PropTypes.bool,
};

ChecklistGroup.defaultProps = {
  disabled: false,
  checkImage: checkSVG,
  circleImage: circleSVG,
  isMasonry: false,
};

export default ChecklistGroup;
