import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import SVGInline from 'react-svg-inline';

import locale from 'config/locale';
import { getPageError, messages } from 'lib/errorHelper';

import View from 'components/View/View';
import ContentsWrapper from 'components/ContentsWrapper/ContentsWrapper';
import Button from 'components/Button/Button';

import GhostEyes from 'assets/icons/ghost-eyes.gif';
import Lock from 'assets/icons/lock.svg';
import Clock from 'assets/icons/clock.svg';
import Tower from 'assets/icons/tower.svg';

export class ErrorPage extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    error: PropTypes.object.isRequired,
    goal: PropTypes.bool,
  };

  renderIcon = (interpretedError) => {
    switch (interpretedError.icon) {
      case 'Lock':
        return <SVGInline svg={Lock} width='10em' raw />;
      case 'Clock':
        return <SVGInline svg={Clock} width='10em' raw />;
      case 'Tower':
        return <SVGInline svg={Tower} width='10em' raw />;
      default:
        return <img src={GhostEyes} />;
    }
  };

  render() {
    const { intl, error, goal } = this.props;
    const interpretedError = getPageError(error.status);
    return (
      <View goal={goal}>
        <ContentsWrapper id='error' className='errorPage'>
          <div className='rowContainerAboveTablet sameSizeFlexBoxes'>
            <div className='alignCenter'>
              {this.renderIcon(interpretedError)}
            </div>
            <div>
              <h1>{intl.formatMessage(messages.title)}</h1>
              <p>{intl.formatMessage(interpretedError.message)}</p>
            </div>
          </div>
          <Button
            className='w49AboveTablet alignCenter brandColor__button'
            url={interpretedError.buttonUrl}
          >
            {intl.formatMessage(interpretedError.buttonText)}
          </Button>
          <a href={`tel:${locale.data.phoneNumber}`}>
            <i className='mi-phone' />
            <span>{locale.data.phoneNumberFormatted}</span>
          </a>
          <span>
            {`${intl.formatMessage(messages.errorCode)}: ${error.status}`}
          </span>
        </ContentsWrapper>
      </View>
    );
  }
}

export default injectIntl(ErrorPage);
