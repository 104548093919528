import React from 'react';
import PropTypes from 'prop-types';

import { YES_NO_OPTIONS } from 'constants/options';

import RadioButtonList from 'components/ButtonList/RadioButtonList';

const YesNoButtonList = ({ action, value, disabled, ...otherProps }) => (
  <RadioButtonList
    action={action}
    items={YES_NO_OPTIONS}
    value={value}
    disabled={disabled}
    {...otherProps}
  />
);

YesNoButtonList.displayName = 'YesNoButtonList';
YesNoButtonList.propTypes = {
  action: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default YesNoButtonList;
