import _ from 'lodash';

import * as incomeActionTypes from 'actions/incomeActionTypes';
import * as individualIncomeActionTypes from 'actions/individualIncomeActionTypes';

import { handleActions } from 'redux-actions';
import { unformatCurrency } from 'shared/lib/numbrero';

import {
  setWorkingValue,
  clearWorking,
  removeEntity,
  loadEntityIntoWorking,
  setEntity,
  setNewEntity,
  setNewEntities,
  insertNewEntities,
  setWorkingClientIds,
  setError,
} from 'lib/reducerHelper';

const actionTypes = { ...incomeActionTypes, ...individualIncomeActionTypes };

const newIncome = {
  errors: {},
};

export const initialState = {
  errors: {},
  entities: [],
  working: {
    new: newIncome,
  },
};

const incomeReducer = handleActions(
  {
    [actionTypes.CLEAR_WORKING_INCOME](state, action) {
      return clearWorking(state, action, newIncome);
    },
    [actionTypes.LOAD_INCOME](state, action) {
      return loadEntityIntoWorking(state, action, newIncome);
    },
    [actionTypes.REMOVE_INCOME](state, action) {
      return removeEntity(state, action);
    },
    [actionTypes.SET_NEW_INCOME](state, action) {
      return setNewEntity(state, action);
    },
    [actionTypes.SET_NEW_INCOMES](state, action) {
      return setNewEntities(state, action);
    },
    [actionTypes.INSERT_NEW_INCOMES](state, action) {
      return insertNewEntities(state, action);
    },
    [actionTypes.SET_INCOME](state, action) {
      return setEntity(state, action);
    },
    [actionTypes.SET_INCOME_CLIENT_IDS](state, action) {
      return setWorkingClientIds(state, action);
    },
    [actionTypes.SET_INCOME_TYPE](state, action) {
      return setWorkingValue(state, action.payload, 'type');
    },
    [actionTypes.SET_INCOME_CATEGORY](state, action) {
      const {
        id,
        value: { category, incomeTypes },
      } = action.payload;
      let newState = setWorkingValue(
        state,
        { id, value: category },
        'category',
        _.toNumber,
      );

      const categoryType = incomeTypes[category];
      const defaultType =
        categoryType.length === 1 ? categoryType[0].value : undefined;
      if (defaultType) {
        newState = setWorkingValue(
          newState,
          { id, value: defaultType },
          'type',
          _.toNumber,
        );
      }
      return newState;
    },
    [actionTypes.SET_INCOME_DESCRIPTION](state, action) {
      return setWorkingValue(state, action.payload, 'description');
    },
    [actionTypes.SET_INCOME_VALUE](state, action) {
      return setWorkingValue(state, action.payload, 'value', unformatCurrency);
    },
    [actionTypes.SET_INCOME_IS_GROSS](state, action) {
      return setWorkingValue(
        state,
        action.payload,
        'isGross',
        (s) => s === 'true',
      );
    },
    [actionTypes.SET_INCOME_FREQUENCY](state, action) {
      return setWorkingValue(state, action.payload, 'frequency', _.toNumber);
    },
    [actionTypes.SET_INCOME_EMPLOYMENT_ID](state, action) {
      return setWorkingValue(state, action.payload, 'employmentId');
    },
    [actionTypes.SET_INCOME_ERROR](state, action) {
      return setError(state, action);
    },
  },
  initialState,
);

export default incomeReducer;
