import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classNames from 'classnames/bind';

const visibilityHOC = (WrappedComponent, animationProps) =>
  class VisibilityComponent extends Component {
    static displayName = `VisibilityComponent(${
      WrappedComponent.displayName || WrappedComponent.name
    })`;

    static propTypes = {
      visibility: PropTypes.bool,
      transition: PropTypes.bool,
      animationClassName: PropTypes.string,
    };

    static defaultProps = {
      visibility: true,
    };

    render() {
      if (
        animationProps &&
        (!this.props.transition === undefined || this.props.transition === true)
      ) {
        const { className, ...otherAnimationProps } = animationProps;

        return (
          <CSSTransitionGroup
            className={classNames(className, this.props.animationClassName)}
            {...otherAnimationProps}
          >
            {this.props.visibility && <WrappedComponent {...this.props} />}
          </CSSTransitionGroup>
        );
      }
      return this.props.visibility && <WrappedComponent {...this.props} />;
    }
  };

export default visibilityHOC;
