/* eslint-disable react/jsx-handler-names */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { touchOrClick } from 'lib/utils/browserUtils';

import UIActions from 'actions/UIActions';

import styles from './wrapAnimation.css';

const wrapAnimation = (WrappedComponent, wrapperTheme) => {
  class WrappedAnimation extends Component {
    static propTypes = {
      match: PropTypes.object,
    };

    static contextTypes = {
      store: PropTypes.object.isRequired,
    };

    skip = (e) => {
      const skipAllowed = this.content.querySelector('#skipAllowedElement');
      if (touchOrClick(e) && skipAllowed) {
        this.next();
      }
    };

    next = (data) => {
      this.context.store.dispatch(UIActions.nextAnimationSequence(data));
    };

    close = () => {
      this.context.store.dispatch(UIActions.stopAnimationSequence());
    };

    render() {
      return (
        <div
          ref={(c) => {
            this.content = c;
          }}
          className={classNames.bind(styles)('root', { [wrapperTheme]: true })}
          onClick={this.skip}
          onTouchStart={this.skip}
        >
          <WrappedComponent
            {...this.props}
            next={this.next}
            close={this.close}
          />
        </div>
      );
    }
  }

  return WrappedAnimation;
};

export default wrapAnimation;
