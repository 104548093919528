import {
  BUYER_SCENARIO_FHB,
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_INVEST,
  BUYER_SCENARIO_COMPARE,
  FORK_DIY_RESEARCH,
  FORK_FAST_APPROVAL,
} from 'shared/constants/loanScenarios';

export const BUYER_SCENARIOS = [
  {
    value: BUYER_SCENARIO_FHB,
    label: 'Buy my first home',
    icon: 'sl-custom-house-4',
  },
  {
    value: BUYER_SCENARIO_MOVE,
    label: 'Sell my old home – Buy a new one',
    icon: 'sl-custom-truck-1',
  },
  {
    value: BUYER_SCENARIO_INVEST,
    label: 'Rent out my home - Buy a new one',
    icon: 'sl-custom-dollar-bag',
  },
  {
    value: BUYER_SCENARIO_COMPARE,
    label: 'Just let me explore',
    icon: 'sl-custom-magnifier',
  },
];

export const FORK_CHOICE_FAST_APPROVAL = {
  value: FORK_FAST_APPROVAL,
  label: 'Get fast pre-approval',
};

export const FORK_CHOICE_DIY_RESEARCH = {
  value: FORK_DIY_RESEARCH,
  label: 'Compare loans & research',
};

export const LOAN_SCENARIOS = BUYER_SCENARIOS;
