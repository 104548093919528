import _ from 'lodash';
import {
  DEFAULT_EXISTING_PROPERTY_ID,
  PROSPECTIVE_PROPERTY_ID,
} from '../constants/defaults';

export function getDefaultExistingProperty(scenario) {
  return (
    scenario.properties && scenario.properties[DEFAULT_EXISTING_PROPERTY_ID]
  );
}

export function getExistingProperties(scenario) {
  return _.filter(
    scenario.properties,
    (v, k) => v && k !== PROSPECTIVE_PROPERTY_ID,
  );
}

export function getProspectiveProperty(scenario) {
  return scenario.properties && scenario.properties[PROSPECTIVE_PROPERTY_ID];
}
