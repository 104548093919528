import { defineMessages } from 'react-intl';

export default defineMessages({
  primaryGoals: {
    id: 'GoalApply.primaryGoals',
    defaultMessage:
      'Tell us about your goals. The more we understand, the more we can help.',
  },
  primaryGoalsPurchaseGroup: {
    id: 'GoalApply.primaryGoalsPurchaseGroup',
    defaultMessage: 'Purchase',
  },
  primaryGoalsRefinanceGroup: {
    id: 'GoalApply.primaryGoalsRefinanceGroup',
    defaultMessage: 'Refinance',
  },
  specificGoal: {
    id: 'GoalApply.specificGoal',
    defaultMessage:
      'Let us know what you would like to achieve from this loan application.',
  },
  specificGoalHint: {
    id: 'GoalApply.specificGoalHint',
    defaultMessage:
      'E.g. you want to buy a new property and refinance at the same time.',
  },
  whatYouWantTodo: {
    id: 'GoalApply.whatYouWantTodo',
    defaultMessage:
      'Let us know what you would like to achieve from this loan application.',
  },
  whatYouWantTodoHint: {
    id: 'GoalApply.whatYouWantTodoHint',
    defaultMessage:
      'E.g. you want to buy a new property and refinance at the same time.',
  },
  fullLegalName: {
    id: 'GoalApply.fullLegalName',
    defaultMessage: 'Your Full legal name',
  },
  homeAddress: {
    id: 'GoalApply.homeAddress',
    defaultMessage: 'Current home address',
  },
  mobileAndEmail: {
    id: 'GoalApply.mobileAndEmail',
    defaultMessage: 'Mobile and email address',
  },
  title: {
    id: 'GoalApply.title',
    defaultMessage: 'Your Goals',
  },
  headerDescription: {
    id: 'GoalApply.headerDescription',
    defaultMessage: 'What are you looking to do?',
  },
  headerDescriptionSingle: {
    id: 'GoalApply.headerDescriptionSingle',
    defaultMessage: '{label} property',
  },
  headerDescriptionMulti: {
    id: 'GoalApply.headerDescriptionMulti',
    defaultMessage: '{label}',
  },
  hasSettlementDateOnly: {
    id: 'GoalApply.hasSettlementDateOnly',
    defaultMessage: 'Is there a date you are working towards?',
  },
  settlementDateOnly: {
    id: 'GoalApply.settlementDateOnly',
    defaultMessage: 'When do you need to get your finance ready?',
  },
  hasImportantDates: {
    id: 'GoalApply.hasImportantDates',
    defaultMessage: 'Are there any important dates you’re working towards?',
  },
  hasSettlementDate: {
    id: 'GoalApply.hasSettlementDate',
    defaultMessage: 'Settlement date',
  },
  settlementDate: {
    id: 'GoalApply.settlementDate',
    defaultMessage: '&nbsp;',
  },
  hasFinanceDate: {
    id: 'GoalApply.hasFinanceDate',
    defaultMessage: 'Finance date',
  },
  financeDate: {
    id: 'GoalApply.financeDate',
    defaultMessage: '&nbsp;',
  },
  hasAuctionDate: {
    id: 'GoalApply.hasAuctionDate',
    defaultMessage: 'Auction date',
  },
  auctionDate: {
    id: 'GoalApply.auctionDate',
    defaultMessage: '&nbsp;',
  },
});
