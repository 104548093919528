const AUTH_INTENTS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  SHARED: 'shared',
  BROKER_LOGIN: 'brokerLogin',
  SETUP_FACTOR_AND_LOGIN: 'setupFactorAndLogin',
  RESET_PASSWORD: 'resetPassword',
};

export const LOGIN_AUTH_INTENTS = [
  AUTH_INTENTS.LOGIN,
  AUTH_INTENTS.SETUP_FACTOR_AND_LOGIN,
];

export default AUTH_INTENTS;
