import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { push } from '@loan_market/react-router-redux-multi';
import _ from 'lodash';

import contactActions from 'actions/contactActions';
import clientActions from 'actions/clientActions';
import householdActions from 'actions/householdActions';
import {
  getIsLocked,
  isSharedApplication,
  getApplicationId,
} from 'selectors/applicationSelectors';
import * as employmentSelectors from 'selectors/employmentSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

import { REQUIRED } from 'constants/validators';
import {
  CONTACT_TYPE_OPTIONS,
  CONTACT_ROLE_OPTIONS,
  COMPANY_OR_TRUST,
  PERSON_CONTACT_TYPES,
  PARTNER_OR_SPOUSE,
  COUPLE_HOUSEHOLD,
  COUPLE_PARENT_HOUSEHOLD,
} from 'constants/options';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Button from 'components/Button/Button';
import Selection from 'components/Selection/Selection';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import {
  questionSet as clientFormQuestions,
  ComposableContactForm,
} from 'components/PopupForms/ClientForm';
import {
  questionSet as companyFormQuestions,
  propsTransformForQuestion as companyPropTransform,
  ComposableCompanyForm,
} from 'components/PopupForms/CompanyForm';
import Questions from 'lib/Questions';
import { logEvent, EVENTS } from 'lib/amplitude';

const messages = defineMessages({
  typeRadio: {
    id: 'ContactForm.typeRadio',
    defaultMessage: 'What type of Co-Applicant?',
  },
  typeSelect: {
    id: 'ContactForm.typeSelect',
    defaultMessage: 'Contact type',
  },
  roleRadio: {
    id: 'ContactForm.roleRadio',
    defaultMessage: 'Is this applicant a borrower or guarantor?',
  },
  roleSelect: {
    id: 'ContactForm.roleSelect',
    defaultMessage: 'Contact role',
  },
  save: {
    id: 'ContactForm.save',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'ContactForm.remove',
    defaultMessage: 'Remove',
  },
});

const propsTransformForQuestion = compose(companyPropTransform, (p) => ({
  ...p.working,
  workingCurrentAddress: p.workingCurrentAddress,
  isSameAsHomeAddress: p.isSameAsHomeAddress,
  hasBluestoneOnlineApplicationFlag: p.hasBluestoneOnlineApplicationFlag,
  homeAddress:
    p.workingCurrentAddress && p.workingCurrentAddress.address.formattedAddress,
  mailAddress:
    p.workingMailAddress && p.workingMailAddress.address.formattedAddress,
}));

const questionSet = (contact) => {
  const questions = new Questions();
  contact.useContact = true;

  if (!contact.type) {
    questions.addBranch(['type', REQUIRED]);
  }
  if (PERSON_CONTACT_TYPES.includes(contact.type)) {
    if (!contact.role) {
      questions.addBranch(['type', REQUIRED], ['role', REQUIRED]);
    } else {
      questions.addForm(clientFormQuestions, contact);
    }
  } else if (contact.type === COMPANY_OR_TRUST) {
    questions.addForm(companyFormQuestions, contact);
  }

  return questions.arrayOfQuestions();
};

export class DisconnectedContactForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    save: PropTypes.func,
    remove: PropTypes.func,
    setError: PropTypes.func.isRequired,
    setClientIsCoapplicant: PropTypes.func,
    setClientIsDependent: PropTypes.func,
    setClientRole: PropTypes.func,
    clearWorking: PropTypes.func,
    goTo: PropTypes.func,
    hasPartner: PropTypes.bool,
    NZBNLoading: PropTypes.bool,
    parentPath: PropTypes.string,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;
  static revealMethod = 'chunks';
  static revealOverBranch = true;

  componentWillUnmount() {
    const { working, clearHousehold, clearWorking } = this.props;
    if (working && working.type === PARTNER_OR_SPOUSE) {
      clearHousehold();
      clearWorking();
    }
  }

  remove = () => {
    this.props.remove(this.props.working.id);
  };

  save = () => {
    this.props.save(this.props.working.id);
  };

  renderNestedForm = (working) => {
    const {
      isLocked,
      isShared,
      workingCurrentAddress,
      workingMailAddress,
      isSameAsHomeAddress,
    } = this.props;
    const questionProps = _.pick(this.props, _.keys(manageQuestionsPropTypes));
    if (PERSON_CONTACT_TYPES.includes(working.type) && working.role) {
      return (
        <ComposableContactForm
          fieldsOnly
          working={working}
          questionKey='clientWithinContact'
          workingCurrentAddress={workingCurrentAddress}
          workingMailAddress={workingMailAddress}
          isSameAsHomeAddress={isSameAsHomeAddress}
          popup
          isLocked={isLocked}
          isShared={isShared}
          {...questionProps}
        />
      );
    } else if (working.type === COMPANY_OR_TRUST) {
      return (
        <ComposableCompanyForm
          fieldsOnly
          company={working}
          questionKey='companyWithinContact'
          workingCurrentAddress={workingCurrentAddress}
          isLocked={isLocked}
          popup
          {...questionProps}
        />
      );
    }
    return false;
  };

  setContactType = (value) => {
    const {
      setType,
      setClientIsDependent,
      setClientIsCoapplicant,
      setHouseholdShape,
      hasDependents,
      working,
    } = this.props;
    if (working.isCoapplicant) {
      logEvent(EVENTS.ADD_COAPPLICANT, { type: value });
    }
    if (value === PARTNER_OR_SPOUSE) {
      setClientIsDependent(false);
      setClientIsCoapplicant(true);
      setHouseholdShape(
        hasDependents ? COUPLE_PARENT_HOUSEHOLD : COUPLE_HOUSEHOLD,
      );
    }
    setType(value);
  };

  setContactRole = (value) => {
    const {
      working,
      setRole,
      goTo,
      setClientRole,
      setClientIsDependent,
      setClientIsCoapplicant,
      parentPath,
    } = this.props;
    if (working.type === PARTNER_OR_SPOUSE) {
      setClientRole(value);
      setClientIsDependent(false);
      setClientIsCoapplicant(true);
      goTo(`${parentPath}/client/new`);
    } else {
      setRole(value);
    }
  };

  getContactTypes = () => {
    const {
      intl: { formatMessage },
      hasPartner,
    } = this.props;
    const contactTypes = CONTACT_TYPE_OPTIONS(formatMessage);
    return hasPartner
      ? contactTypes.filter((c) => c.key !== PARTNER_OR_SPOUSE)
      : contactTypes;
  };

  render() {
    const {
      isLocked,
      working,
      questions,
      formCompleted,
      setCurrentQuestionTo,
      NZBNLoading,
      intl: { formatMessage },
    } = this.props;

    return (
      <Form
        id='contactForm'
        formCompleted={formCompleted}
        onSubmit={this.save}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          {!working.type ? (
            <Question
              {...questions.type}
              label={formatMessage(messages.typeRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={this.setContactType}
                value={undefined}
                items={this.getContactTypes()}
              />
            </Question>
          ) : (
            <Question
              {...questions.type}
              label={formatMessage(messages.typeSelect)}
              className='fullWidthInput'
            >
              <Selection
                action={this.setContactType}
                value={working.type}
                items={this.getContactTypes()}
              />
            </Question>
          )}
          {!working.role ? (
            <Question
              {...questions.role}
              label={formatMessage(messages.roleRadio)}
              className='fullWidthInput'
            >
              <RadioButtonList
                action={this.setContactRole}
                value={undefined}
                items={CONTACT_ROLE_OPTIONS(formatMessage)}
              />
            </Question>
          ) : (
            <Question
              {...questions.role}
              label={formatMessage(messages.roleSelect)}
              className='fullWidthInput'
            >
              <Selection
                action={this.setContactRole}
                value={working.role}
                items={CONTACT_ROLE_OPTIONS(formatMessage)}
              />
            </Question>
          )}
          {this.renderNestedForm(working)}
          <Question {...questions.saveButton} className='fullWidthButton'>
            <Button
              theme='linkButton'
              hasErrorMessage={!formCompleted || NZBNLoading}
              onClick={this.save}
            >
              {formatMessage(messages.save)}
            </Button>
          </Question>
          {working.id && (
            <Button className='brandColor__reverseButton' onClick={this.remove}>
              {formatMessage(messages.remove)}
            </Button>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isShared: isSharedApplication(state),
  isLocked: getIsLocked(state),
  NZBNLoading: employmentSelectors.NZBNLoading(state),
  applicationId: getApplicationId(state),
  hasPartner: !!clientSelectors.primaryApplicantsPartner(state),
  hasDependents:
    (clientSelectors.primaryApplicantsDependents(state) || []).length > 0,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = (ownProps.working && ownProps.working.id) || 'new';
  return bindActionCreators(
    {
      setType: contactActions.setContactType(id),
      setRole: contactActions.setContactRole(id),
      setError: contactActions.setContactError(id),
      setClientIsDependent: clientActions.setClientIsDependent(id),
      setClientIsCoapplicant: clientActions.setClientIsCoapplicant(id),
      setClientRole: clientActions.setClientRole(id),
      clearWorking: contactActions.clearWorkingContact,
      setHouseholdShape: householdActions.setHouseholdShape,
      clearHousehold: householdActions.clearHousehold,
      goTo: push,
    },
    dispatch,
  );
};

export default injectIntl(
  manageQuestions(
    connect(mapStateToProps, mapDispatchToProps)(DisconnectedContactForm),
    propsTransformForQuestion,
  ),
);
