export const CLEAR_WORKING_INCOME = 'clearWorkingIncome';
export const LOAD_INCOME = 'loadIncome';
export const LOAD_NEW_CLIENT_INCOME = 'loadNewClientIncome';
export const REMOVE_INCOME = 'removeIncome';
export const SET_INCOME = 'setIncome';
export const SET_NEW_INCOME = 'setNewIncome';
export const SET_NEW_INCOMES = 'setNewIncomes';
export const SET_INCOME_ERROR = 'setIncomeError';
export const INSERT_NEW_INCOMES = 'insertNewIncomes';

export const GET_INCOMES = 'getIncomes';
export const CREATE_INCOME = 'createIncome';
export const UPDATE_INCOME = 'updateIncome';
export const DELETE_INCOME = 'deleteIncome';

export const CONFIRM_INCOMES = 'confirmIncomes';
