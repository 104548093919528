import { defineMessages } from 'react-intl';

import {
  BRIDGING,
  COMMERCIAL,
  CONSTRUCTION,
  EQUITY,
  INVESTMENT,
  LINE_OF_CREDIT,
  OWNER_OCCUPIED,
  REVERSE,
  SMSF,
  VACANT_LAND,
  EQUIPMENT_FINANCE,
  ALL_PRODUCT_TYPES,
} from 'shared/constants/productTypes';

const PRODUCT_TYPES = [
  {
    value: ALL_PRODUCT_TYPES,
    ...defineMessages({
      name: {
        id: 'productTypes.allProductTypes.name',
        defaultMessage: 'All Product Types',
      },
    }),
  },
  {
    value: BRIDGING,
    ...defineMessages({
      name: {
        id: 'productTypes.bridging.name',
        defaultMessage: 'Bridging',
      },
    }),
  },
  {
    value: COMMERCIAL,
    ...defineMessages({
      name: {
        id: 'productTypes.commercial.name',
        defaultMessage: 'Commercial',
      },
    }),
  },
  {
    value: CONSTRUCTION,
    ...defineMessages({
      name: {
        id: 'productTypes.construction.name',
        defaultMessage: 'Construction',
      },
    }),
  },
  {
    value: EQUITY,
    ...defineMessages({
      name: {
        id: 'productTypes.equity.name',
        defaultMessage: 'Equity',
      },
    }),
  },
  {
    value: INVESTMENT,
    ...defineMessages({
      name: {
        id: 'productTypes.investment.name',
        defaultMessage: 'Investment',
      },
    }),
  },
  {
    value: LINE_OF_CREDIT,
    ...defineMessages({
      name: {
        id: 'productTypes.lineOfCredit.name',
        defaultMessage: 'Line of Credit',
      },
    }),
  },
  {
    value: OWNER_OCCUPIED,
    ...defineMessages({
      name: {
        id: 'productTypes.ownerOccupied.name',
        defaultMessage: 'Owner Occupied',
      },
    }),
  },
  {
    value: REVERSE,
    ...defineMessages({
      name: {
        id: 'productTypes.reverse.name',
        defaultMessage: 'Reverse',
      },
    }),
  },
  {
    value: SMSF,
    ...defineMessages({
      name: {
        id: 'productTypes.smsf.name',
        defaultMessage: 'SMSF',
      },
    }),
  },
  {
    value: VACANT_LAND,
    ...defineMessages({
      name: {
        id: 'productTypes.vacantLand.name',
        defaultMessage: 'Vacant Land',
      },
    }),
  },
  {
    value: EQUIPMENT_FINANCE,
    ...defineMessages({
      name: {
        id: 'productTypes.equipmentFinance.name',
        defaultMessage: 'Equipment Finance',
      },
    }),
  },
];

export const formatProductTypes = (formatMessage) =>
  PRODUCT_TYPES.map((p) => ({
    ...p,
    name: formatMessage(p.name),
  }));
