import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { goBack } from '@loan_market/react-router-redux-multi';

import GoalSetterForm from 'containers/goal/GoalSetterForm/GoalSetterForm';

import {
  NO_PREFERRED_LENDERS,
  PREFERRED_BANKS,
  QUESTION_IDS,
} from 'shared/constants/goalLoanAppInfo';

import goalLoanAppInfoActions from 'actions/goal/goalLoanAppInfoActions';
import UIActions from 'actions/UIActions';

import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';
import * as goalLoanAppInfoSelectors from 'selectors/goalLoanAppInfoSelectors';
import * as clientSelectors from 'selectors/clientSelectors';

import { fromPreferredLendersToNextPath, isApplyOnline } from 'lib/pathHelper';
import { getQuestionId } from 'lib/goalSetterFormHelper';
import { areArraysEqual } from 'lib/utils/dataUtils';

// eslint-disable-next-line sonarjs/cognitive-complexity
export function GoalPreferencesPreferredLenders(props) {
  const {
    questions,
    answers,
    goToPathWithAnimation,
    saveLoanAppInfoAnswers,
    active,
    familyId,
    back,
    trackEvent,
  } = props;
  const PREFERRED_LENDERS = getQuestionId(QUESTION_IDS.PREFERRED_LENDERS);
  const OTHER_PREFERRED_LENDER = getQuestionId(
    QUESTION_IDS.OTHER_PREFERRED_LENDER,
  );
  const PREFERRED_LENDERS_OTHER = getQuestionId(
    QUESTION_IDS.PREFERRED_LENDERS_OTHER,
  );
  const PREFERRED_LENDERS_REASON = getQuestionId(
    QUESTION_IDS.PREFERRED_LENDERS_REASON,
  );
  const initState = {
    [QUESTION_IDS.HAS_PREFERRED_LENDERS]: answers[PREFERRED_LENDERS]
      ? !(
          answers[PREFERRED_LENDERS].length === 1 &&
          answers[PREFERRED_LENDERS].includes(NO_PREFERRED_LENDERS)
        )
      : undefined,
  };
  const [savedAnswers, updateSavedAnswers] = useState(initState);

  const setAnswer = (questionId, value) => {
    const customAnswers = {};
    if (questionId === QUESTION_IDS.HAS_PREFERRED_LENDERS && !value) {
      customAnswers[PREFERRED_LENDERS] = [NO_PREFERRED_LENDERS];
      customAnswers[PREFERRED_LENDERS_REASON] = '';
    }
    if (
      questionId === PREFERRED_LENDERS &&
      value.length > 2 &&
      value.includes(NO_PREFERRED_LENDERS)
    ) {
      customAnswers[PREFERRED_LENDERS] = value.filter(
        (v) => v !== NO_PREFERRED_LENDERS,
      );
    }
    if (
      questionId === PREFERRED_LENDERS &&
      savedAnswers[PREFERRED_LENDERS] &&
      !value.includes(OTHER_PREFERRED_LENDER)
    ) {
      savedAnswers[PREFERRED_LENDERS_OTHER] = '';
    }
    updateSavedAnswers({
      ...savedAnswers,
      [questionId]: value,
      ...customAnswers,
    });
  };

  const onSave = (isBack) => {
    const length = Object.keys(savedAnswers).length;
    if (
      (length === 2 &&
        !areArraysEqual(
          answers[PREFERRED_LENDERS],
          savedAnswers[PREFERRED_LENDERS],
        )) ||
      length > 2
    ) {
      // TODO: fix weird error here
      saveLoanAppInfoAnswers({
        section: PREFERRED_BANKS,
        answers: savedAnswers,
        loanAppId: active,
        familyId,
      });
    }
    if (!isBack) {
      goToPathWithAnimation(fromPreferredLendersToNextPath(active));
    }
  };

  const formProps = {
    formName: PREFERRED_BANKS,
    action: setAnswer,
    questions,
    answers: { ...answers, ...savedAnswers },
    displayName: undefined,
    submitButtonProps: {
      text: `Save and ${isApplyOnline() ? 'Close' : 'Next'}`,
      action: onSave,
      theme: 'buttonNext',
    },
    back,
    trackEvent,
  };

  return <GoalSetterForm {...formProps} />;
}

GoalPreferencesPreferredLenders.propTypes = {
  match: PropTypes.object,
  goToPathWithAnimation: PropTypes.func,
  questions: PropTypes.object,
  answers: PropTypes.object,
  saveLoanAppInfoAnswers: PropTypes.func,
  active: PropTypes.number,
  familyId: PropTypes.number,
  back: PropTypes.func,
  trackEvent: PropTypes.func,
};

const mapStateToProps = (state) => {
  const family = clientSelectors.primaryFamily(state);
  return {
    questions: goalLoanAppInfoSelectors.questionsForSection(state)(
      PREFERRED_BANKS,
    ),
    answers: goalLoanAppInfoSelectors.answersForSection(state)(
      family.contactId,
      PREFERRED_BANKS,
    ),
    active: goalLoanApplicationSelectors.loanApplicationId(state),
    familyId: family.contactId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToPathWithAnimation: UIActions.goToPathWithAnimation,
      saveLoanAppInfoAnswers: goalLoanAppInfoActions.saveLoanAppInfoAnswers,
      back: goBack,
      trackEvent: UIActions.trackEvent,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GoalPreferencesPreferredLenders);
