import React from 'react';
import PropTypes from 'prop-types';
import { generateUniqueID } from 'lib/utils/stringUtils';

import Selection from 'components/Selection/Selection';

import { nameValueShape } from 'types/customPropTypes';

import styles from './NSelection.css';

const selectionShape = {
  action: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(nameValueShape),
  selectionDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const NSelection = ({
  selections,
  nonTabbable,
  onFocus = () => {},
  forceFocus,
  disabled,
}) => (
  <div className={styles.wrapper}>
    {selections.map((selection, i) => {
      const appliedForceFocus = i === 0 ? forceFocus : undefined;
      return (
        <Selection
          key={i}
          onFocus={onFocus}
          forceFocus={appliedForceFocus}
          nonTabbable={nonTabbable}
          id={generateUniqueID('Selection', i)}
          className={styles.selection}
          rootStyle={{
            width: `calc(100%/${selections.length})`,
          }}
          disabled={disabled}
          {...selection}
        />
      );
    })}
  </div>
);

NSelection.propTypes = {
  selections: PropTypes.arrayOf(PropTypes.shape(selectionShape)).isRequired,
  nonTabbable: PropTypes.bool,
  forceFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NSelection;
