import { createSelector } from 'reselect';
import _ from 'lodash';

import getLender from 'lib/lenderHelper';
import { getInterestProducts } from 'selectors/productSelectors';
import * as liabilitySelectors from 'selectors/liabilitySelectors';

const getInterestProductLenders = (state) =>
  getInterestProducts(state).products || [];

export const getInterestProductParameters = (state) =>
  getInterestProducts(state).params || {};

export const getInterestProductDetails = createSelector(
  getInterestProductLenders,
  liabilitySelectors.realestateLiabilities,
  (lenders, realestateLiabilities) => {
    const currentLenderIds = _.uniq(
      realestateLiabilities.map((re) => re.creditorId),
    );

    return lenders.map((lender) => {
      const productWithMaxSaving = _.maxBy(lender.products, (p) => p.saving);
      return {
        type: productWithMaxSaving.type,
        minInitialRate: productWithMaxSaving.rates.initial.rate,
        minInitialPeriodInYears: productWithMaxSaving.rates.initial.period / 12,
        minOngoingRate: productWithMaxSaving.rates.ongoing.rate,
        maxSaving: productWithMaxSaving.saving,
        lender: lender.lender,
        isCurrentLender: currentLenderIds.includes(lender.lender.id),
        products: _.keyBy(lender.products, 'type'),
        bank: getLender(lender.lender.id),
      };
    });
  },
);

export const getMaxInterestSaving = createSelector(
  getInterestProductDetails,
  (interestSavingProducts) => {
    return interestSavingProducts.length > 0
      ? interestSavingProducts[0].maxSaving
      : 0;
  },
);
