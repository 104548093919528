import { createSelector } from 'reselect';
import _ from 'lodash';
import { convertFrequency } from 'shared/lib/numbrero';
import { MONTHLY } from 'shared/constants/options';
import {
  LIABILITY_CARD_CATEGORY,
  LIABILITY_TERM_LOAN,
  liabilityCategories,
  liabilityTypesPerCategory,
  LIABILITY_NUMBER_PROPERTIES,
} from 'shared/constants/myCRMTypes/liabilities';
import { POSITIVE_CHART_SPECTRUM } from 'constants/colours';
import { isOrderlessEqual } from 'lib/utils/dataUtils';
import { getIsLocked } from 'selectors/applicationSelectors';
import { isPrimaryOrPartnerClient } from 'selectors/clientSelectors';

export const workingLiabilities = (state) => state.liability.working;
export const liabilities = createSelector(
  (state) => state.liability.entities,
  (a) => {
    return a.filter((liability) => {
      const types = liabilityTypesPerCategory[liability.category];
      return types && types.some((type) => type.id === liability.type);
    });
  },
);

export const hasOldInformation = createSelector(liabilities, (a) =>
  a.some((b) => b.isOldData),
);

export const realestateLiabilities = createSelector(liabilities, (a) =>
  a.filter((l) => l.assetId !== undefined),
);

export const entity = createSelector(liabilities, (a) =>
  _.memoize((id) => _.find(a, (e) => e.id === id)),
);

export const liabilityByAssetId = createSelector(liabilities, (a) =>
  _.memoize((assetId) => _.find(a, (e) => e.assetId === assetId)),
);

export const liabilitiesByAssetId = createSelector(liabilities, (a) =>
  _.memoize((assetId) => _.filter(a, (e) => e.assetId === assetId)),
);

export const working = createSelector(
  workingLiabilities,
  (selectedWorkingLiabilities) =>
    _.memoize((id) => selectedWorkingLiabilities[id]),
);

export const workingLiabilityByAssetId = createSelector(
  workingLiabilities,
  (a) =>
    _.memoize((assetId) => {
      const b = _.filter(a, (w) => w && w.assetId === assetId);
      return b.length > 0
        ? b.reduce((map, obj, idx) => {
            if (idx === 0) {
              Object.keys(obj).forEach((key) => {
                map[key] = obj[key];
              });
            } else {
              LIABILITY_NUMBER_PROPERTIES.forEach((key) => {
                map[key] += obj[key];
              });
            }
            if (b.length > 1) {
              map.isMultiple = true;
            }
            return map;
          }, {})
        : a.new;
    }),
);

export const primaryContactLiabilities = createSelector(
  liabilities,
  isPrimaryOrPartnerClient,
  (l, isPrimaryContact) =>
    l.filter(({ clientIds = [] }) => clientIds.some(isPrimaryContact)),
);

export const liabilitiesOrderedByType = createSelector(
  primaryContactLiabilities,
  (a) => _.sortBy(a, 'type'),
);

export const liabilitiesGroupedByCategory = createSelector(
  primaryContactLiabilities,
  (a) => _.groupBy(a, 'category'),
);

export const clientsLiabilities = createSelector(liabilities, (a) =>
  _.memoize(
    (clientIds) => a.filter((e) => isOrderlessEqual(e.clientIds, clientIds)),
    (clientIds) => clientIds.join('-'),
  ),
);

export const hasOldClientInformation = createSelector(clientsLiabilities, (a) =>
  _.memoize((clientIds) => {
    const b = a(clientIds);
    return b.some((c) => c.isOldData);
  }),
);

const liabilityValue = (x) =>
  (x.category === LIABILITY_CARD_CATEGORY.id
    ? x.totalCardLimit
    : x.totalLoanAmount) || 0;

export const clientsLiabilitiesTotal = createSelector(clientsLiabilities, (a) =>
  _.memoize(
    (clientIds) => _.sumBy(a(clientIds), liabilityValue),
    (clientIds) => clientIds.join('-'),
  ),
);

export const totalLiabilities = createSelector(primaryContactLiabilities, (l) =>
  _.sumBy(l, liabilityValue),
);

export const totalLiabilityRepayments = createSelector(
  primaryContactLiabilities,
  (a) =>
    _.sumBy(a, (v) =>
      convertFrequency(
        v.repaymentAmount || 0,
        v.repaymentFrequency || MONTHLY,
        MONTHLY,
      ),
    ),
);

export const totalCardLimit = createSelector(primaryContactLiabilities, (l) =>
  l
    .filter((e) => e.category === LIABILITY_CARD_CATEGORY.id)
    .reduce((a, b) => a + b.totalCardLimit, 0),
);

export const totalExistingLoans = createSelector(
  primaryContactLiabilities,
  (l) =>
    l
      .filter(
        (e) =>
          e.category !== LIABILITY_CARD_CATEGORY.id &&
          !(e.type === LIABILITY_TERM_LOAN.id && e.isRefinance),
      )
      .reduce((a, b) => a + b.totalLoanAmount, 0),
);

export const liabilityExpenseMetadata = createSelector(
  liabilitiesOrderedByType,
  getIsLocked,
  (a, isLocked) => {
    const items = a.map((data) => {
      const type =
        liabilityTypesPerCategory[data.category].find(
          (t) => t.id === data.type,
        ) || {};
      const name = data.description ? data.description : type.name;
      return {
        name,
        icon: 'sl-custom-check',
        url: `/liability/${data.id}`,
        value:
          convertFrequency(
            data.repaymentAmount,
            data.repaymentFrequency,
            MONTHLY,
          ) || 0,
      };
    });

    if (!isLocked) {
      items.push({
        name: 'Add a liability',
        ignore: true,
        url: `/liability/new`,
        icon: 'sl-custom-list-2-1',
      });
    }

    return {
      label: 'Loans and Liabilities',
      items,
    };
  },
);

export const chartData = createSelector(liabilitiesGroupedByCategory, (a) =>
  _.map(a, (data, category) => {
    const categoryId = parseInt(category, 10);
    return {
      label: liabilityCategories.find((c) => c.id === categoryId).name,
      data: data.map(liabilityValue),
      colour:
        POSITIVE_CHART_SPECTRUM[
          (categoryId + 1) % POSITIVE_CHART_SPECTRUM.length
        ],
    };
  }),
);
