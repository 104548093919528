import _ from 'lodash';

export default (store) => (next) => (action) => {
  next(action);

  const state = store.getState();
  try {
    const stateToSave = _.omit(state, ['products']);
    sessionStorage.setItem('milli', JSON.stringify(stateToSave));
  } catch (error) {
    console.log('WARNING:', error);
  }
};
