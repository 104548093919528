import { createSelector } from 'reselect';

export const quickLmi = (state) => state.other.quickLmi;
export const listingInfo = (state) => {
  const { listingInfo: info } = state.other;
  return {
    ...info,
    address: info.address || '',
  };
};
export const maximumLmi = createSelector(quickLmi, (lmi) => lmi.maximum);
export const minimumLmi = createSelector(quickLmi, (lmi) => lmi.minimum);
