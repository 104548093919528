import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import * as contactSelectors from 'selectors/contactSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';

import {
  CREATE_LIABILITY,
  UPDATE_LIABILITY,
  DELETE_LIABILITY,
  CONFIRM_LIABILITIES,
} from 'actions/liabilityActionTypes';
import liabilityActions from 'actions/liabilityActions';

import { putLiability, delLiability } from 'services/liabilityApi';

import { postLiabilityForClient } from 'services/clientApi';

import { postRealEstateLiabilityForAsset } from 'services/assetApi';

import {
  postLiabilityForContact,
  getContactLiabilities,
} from 'services/contactApi';

import { monitorAsyncRequest } from 'lib/sagaHelpers.js';

export function* getLiabilities() {
  const primaryContact = yield select(contactSelectors.primaryContact);
  const applicationId = yield select(applicationSelectors.getApplicationId);
  const newLiabilities = yield getContactLiabilities(
    primaryContact.id,
    applicationId,
  );
  yield put(liabilityActions.setNewLiabilities(newLiabilities));
}

export function* createLiability({ payload }) {
  let liability;
  if (payload.assetId) {
    liability = yield postRealEstateLiabilityForAsset(payload);
  } else if (payload.clientIds.length === 1) {
    liability = yield postLiabilityForClient({
      ...payload,
      clientId: payload.clientIds[0],
    });
  } else {
    const primaryContact = yield select(contactSelectors.primaryContact);
    liability = yield postLiabilityForContact(
      primaryContact.contactId,
      payload,
    );
  }
  yield put(liabilityActions.setNewLiability(liability));
}

export function* updateLiability({ payload }) {
  const liability = yield putLiability(payload);
  yield put(liabilityActions.setLiability({ ...payload, ...liability }));
}

export function* confirmLiabilities({ payload: { liabilities } }) {
  yield all(liabilities.map((payload) => call(updateLiability, { payload })));
}

export function* deleteLiability({ payload }) {
  yield delLiability(payload);
  yield put(liabilityActions.removeLiability(payload));
}

function* watchCreateLiability() {
  yield monitorAsyncRequest(takeEvery, CREATE_LIABILITY, createLiability);
}

function* watchUpdateLiability() {
  yield monitorAsyncRequest(takeEvery, UPDATE_LIABILITY, updateLiability);
}

function* watchDeleteLiability() {
  yield monitorAsyncRequest(takeEvery, DELETE_LIABILITY, deleteLiability);
}

function* watchConfirmLiabilities() {
  yield monitorAsyncRequest(takeEvery, CONFIRM_LIABILITIES, confirmLiabilities);
}

export default function* liabilitySagas() {
  yield all([
    watchCreateLiability(),
    watchUpdateLiability(),
    watchDeleteLiability(),
    watchConfirmLiabilities(),
  ]);
}
