export const RENOVATE = {
  key: 'renovate',
  label: 'Renovate my property',
  hint:
    'Add an extra room, update your kitchen or any other renovations to an existing property you own',
  displayLabel: 'renovate your property',
};
export const CONSOLIDATE_DEBT = {
  key: 'consolidateDebt',
  label: 'Consolidate my debt',
  hint:
    'If you have credit cards, car or personal loans you can fold these into your home loan and make significant savings on your interest repayments',
  displayLabel: 'consolidate your debt',
};
export const BETTER_DEAL = {
  key: 'betterDeal',
  label: 'Get better deal on my existing loan',
  hint:
    'We can often find ways of lowering your repayments or saving on the overall cost of the loan by finding a more competitive deal.',
  displayLabel: 'get a better deal',
};
export const ADDITIONAL_FUNDS = {
  key: 'additionalFunds',
  label: 'Borrow additional funds',
  hint:
    'Need a holiday or just some spare cash? Access any equity you’ve accumulated in your property as it increases in value.',
  displayLabel: 'borrow additional funds',
};

const REFINANCE_REASONS = [
  BETTER_DEAL,
  RENOVATE,
  CONSOLIDATE_DEBT,
  ADDITIONAL_FUNDS,
];

export const REFINANCE_REASONS_FOR_TEMPLATE = REFINANCE_REASONS.reduce(
  (previous, current) => ({
    ...previous,
    [current.key]: current.label,
  }),
  {},
);

export default REFINANCE_REASONS;
