import _ from 'lodash';
import { defineMessages } from 'react-intl';

import { formatCurrency } from 'shared/lib/numbrero';
import { LOAN_TYPE_BOTH_VALUE } from 'constants/loanTypes';
import REFINANCE_REASONS from 'shared/constants/refinanceReasons';
import { arrayOfTruthyKeys } from 'shared/lib/utils';
import { isInvestment } from 'shared/lib/checkLoanPurpose';
import {
  BUYER_SCENARIO_FHB,
  BUYER_SCENARIO_MOVE,
  BUYER_SCENARIO_INVEST,
  BUYER_SCENARIO_COMPARE,
  INVESTMENT_SCENARIO_DEFAULT,
} from 'shared/constants/loanScenarios';
import {
  PROPERTY_OWNED,
  PROPERTY_RENT,
  PROPERTY_WITH_PARENTS,
} from 'constants/options';

import { formatTextWithComma } from 'lib/utils/stringUtils';
import { dataOfMatchingValueByKey } from 'lib/utils/dataUtils';

import { formatLoanFeaturesOnComparePage } from 'constants/loanFeatures';
import { formatProductTypes } from 'constants/productTypeMessages';
import { FINANCIALS } from 'shared/constants/financials';
import { formatProductLvr } from 'constants/lvrOptions';
import {
  formatInterestTypes,
  formatInitialPeriod,
} from 'constants/interestRateMessages';

import { REPAYMENT_TYPES_BANKING_PREFERENCES } from 'shared/constants/repaymentTypes';

export function aboutPropertyTitleCopy({ name }) {
  switch (name) {
    case BUYER_SCENARIO_MOVE:
    case BUYER_SCENARIO_INVEST:
      return 'Please tell us about the property you’re looking to buy.';
    case BUYER_SCENARIO_FHB:
    case BUYER_SCENARIO_COMPARE:
      return 'Please tell us about the property you’re looking to buy.';
    case INVESTMENT_SCENARIO_DEFAULT:
      return 'Please tell us about the investment property.';
    default:
      return 'Ok, you’re looking to buy a property to live in – that’s exciting.';
  }
}

export function loanTypeCopy(loanType) {
  return loanType === LOAN_TYPE_BOTH_VALUE ? '' : `${loanType} rate `;
}

export function refinanceReasonCopy(refinanceReasons) {
  const refinanceReasonsObject = _.keyBy(REFINANCE_REASONS, 'key');
  const refinanceLabels = arrayOfTruthyKeys(refinanceReasons).map(
    (v) => refinanceReasonsObject[v].displayLabel,
  );

  if (refinanceLabels.length > 2) {
    return 'achieve your property goals';
  }

  return refinanceLabels.join(' and ');
}

export function loanPurposeCopy(loanPurpose) {
  switch (loanPurpose) {
    case 'Residential':
      return 'a Residential';
    case 'Investment':
      return 'an Investment';
    default:
      return 'a Refinance';
  }
}

export function comparePageTitleCopy(scenario) {
  return `Compare ${isInvestment(scenario) ? 'Investment' : 'Home'} Loans`;
}

export function saleReportTitleCopy(equity) {
  if (equity > 0) {
    return `You’ll have around ${formatCurrency(
      equity,
    )} after selling your home.`;
  }
  return `You’ll still owe about ${formatCurrency(
    -equity,
  )} after selling your home.`;
}

export function residencePropertySummary(property) {
  let owned;
  const purposes = [];
  switch (property.owned) {
    case PROPERTY_OWNED:
      owned = 'Owned';
      // if (property.lookingToRefinance) purposes.push('Refinance');
      if (property.useAsSecurity) {
        purposes.push('Use as security');
      }
      break;
    case PROPERTY_RENT:
      owned = 'Rented';
      break;
    case PROPERTY_WITH_PARENTS:
      owned = 'With parents';
      break;
    default:
      owned = 'Boarded';
  }

  // eslint-disable-next-line unicorn/explicit-length-check
  return `${owned}${purposes.length ? ` - ${purposes.join(', ')}` : ''}`;
}

const formatSelectionNames = (translatedObj, valueObj) =>
  formatTextWithComma(
    _.map(valueObj, (v, k) => {
      if (!v) {
        return null;
      }
      const description = _.find(translatedObj, (t) => t.key === k);
      if (!description) {
        console.error(
          `Couldn't find key "${k}" in target object, this could be a missing intl entry.`,
        );
        return k;
      }
      return description.label;
    }),
  );

export const comparePageFilterCopies = (formatMessage, structure) => {
  const translatedInterestTypes = formatInterestTypes(formatMessage);
  const translatedInitialPeriod = formatInitialPeriod(formatMessage);
  const translatedRepaymentTypes = REPAYMENT_TYPES_BANKING_PREFERENCES.map(
    (v) => _.omit(v, 'tooltip'),
  );
  const translatedLoanFeatures = formatLoanFeaturesOnComparePage(formatMessage);

  const translatedProductTypes = formatProductTypes(formatMessage);
  const translatedProductLvr = formatProductLvr(formatMessage);

  return {
    interestType: {
      items: translatedInterestTypes,
      summary:
        formatSelectionNames(translatedInterestTypes, structure.interestType) ||
        'All Interest Types',
    },
    initialPeriod: {
      items: translatedInitialPeriod,
      summary: dataOfMatchingValueByKey(
        translatedInitialPeriod,
        structure.initialPeriod,
        'name',
      ),
    },
    repaymentType: {
      items: translatedRepaymentTypes,
      summary: dataOfMatchingValueByKey(
        translatedRepaymentTypes,
        structure.repaymentType,
        'label',
      ),
    },
    features: {
      items: translatedLoanFeatures,
      summary:
        formatSelectionNames(translatedLoanFeatures, structure.features) ||
        'All Features',
    },
    productType: {
      items: translatedProductTypes,
      summary: dataOfMatchingValueByKey(
        translatedProductTypes,
        structure.productType,
        'name',
      ),
    },
    lvr: {
      items: translatedProductLvr,
      summary: `${structure.lvr}% LVR`,
    },
    financials: {
      items: FINANCIALS,
      summary: dataOfMatchingValueByKey(
        FINANCIALS,
        structure.financials,
        'label',
      ),
    },
  };
};

const messages = defineMessages({
  addMore: {
    id: 'ApplyForm.addMore',
    defaultMessage: 'Add more details',
  },
  showMore: {
    id: 'ApplyForm.showMore',
    defaultMessage: 'Show more details',
  },
  showLess: {
    id: 'ApplyForm.showLess',
    defaultMessage: 'Show less details',
  },
});

export const getMoreLessMessage = (isCollapsed, isLocked) => {
  let messageKey;

  if (!isCollapsed) {
    messageKey = 'showLess';
  } else {
    messageKey = isLocked ? 'showMore' : 'addMore';
  }

  return messages[messageKey];
};
