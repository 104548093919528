// For creating a new Contact record, we need the following set:
export const SET_CONTACT_TYPE = 'setContactType'; // one of Person, Trust, Company
export const SET_CONTACT_ROLE = 'setContactRole'; // one of Borrower, Guarantor

// These contact actions represent setting the data on a new Contact when the type is "Person"
// I (Jaryd) believe that in reality the Contact is quite blank when of this type and all this
// information is set onto a Client record within that Contact
export const SET_CONTACT_TITLE = 'setContactTitle';
export const SET_CONTACT_FIRST_NAME = 'setContactFirstName';
export const SET_CONTACT_MIDDLE_NAME = 'setContactMiddleName';
export const SET_CONTACT_LAST_NAME = 'setContactLastName';
export const SET_CONTACT_PREFERRED_NAME = 'setContactPreferredName';
export const SET_CONTACT_PREVIOUS_NAME = 'setContactPreviousName';
export const SET_CONTACT_MOTHERS_MAIDEN_NAME = 'setContactMothersMaidenName';
export const SET_CONTACT_IS_CITIZEN_AND_RESIDENT =
  'setContactIsCitizenAndResident';
export const SET_CONTACT_MOBILE = 'setContactMobile';
export const SET_CONTACT_EMAIL = 'setContactEmail';
export const SET_CONTACT_DOB_YEAR = 'setContactDobYear';
export const SET_CONTACT_DOB_MONTH = 'setContactDobMonth';
export const SET_CONTACT_DOB_DAY = 'setContactDobDay';
export const SET_CONTACT_GENDER = 'setContactGender';
export const SET_CONTACT_CITIZENSHIP_ID = 'setContactCitizenshipId';
export const SET_CONTACT_COUNTRY_OF_RESIDENCY_ID =
  'setContactCountryOfResidencyId';
export const SET_CONTACT_IS_RESIDENT = 'setContactIsResident';
export const SET_CONTACT_RESIDENCY_ID = 'setContactResidencyId';
export const SET_CONTACT_IS_COAPPLICANT = 'setContactIsCoapplicant';
export const SET_CONTACT_MARITAL_STATUS_ID = 'setContactMaritalStatusId';

// These contact actions represent setting the data on a new contact when the type is Business
export const SET_CONTACT_BUSINESS_NAME = 'setContactBusinessName';
export const SET_CONTACT_FIRM = 'setContactFirm';
export const SET_CONTACT_DESCRIPTION = 'setContactDescription';
export const SET_CONTACT_NZBN = 'setContactNZBN';
export const SET_CONTACT_TFN = 'setContactTfn';
export const SET_CONTACT_ACN = 'setContactAcn';
export const SET_CONTACT_OFFICE_PHONE = 'setContactOfficePhone';
export const SET_CONTACT_OFFICE_EMAIL = 'setContactOfficeEmail';

export const SET_CONTACT_ERROR = 'setContactError';
