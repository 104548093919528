import _ from 'lodash';

export const paramCacheResolver = (state, id) => {
  if (id === undefined) {
    return 'undefined';
  } // undefined is not a valid cache key obviously
  if (_.isObject(id)) {
    return _.get(id, 'match.params.id', 'undefined');
  }
  return id;
};

export const paramsId = (state, id) => {
  if (_.isObject(id)) {
    const idFromRoute = _.get(id, 'match.params.id');
    return parseInt(idFromRoute, 10) || idFromRoute || 0;
  }
  return id;
};
