export const AUTH_ERRORS_BY_COUNTRY = {
  AU: {
    INVALID_INVITE_TOKEN:
      'Get in touch with your Mortgage Broker and they’ll send you a new invite',
  },
  NZ: {
    INVALID_INVITE_TOKEN:
      'Get in touch with your Mortgage Adviser and they’ll send you a new invite',
  },
};

export const LOGIN_EXPIRED_ERROR_MESSAGE =
  'Enter your email address to get started';
export const EMAIL_ADDRESS_UNAVAILABLE = 'This email address is unavailable.';
export const EMAIL_TAKEN_BY_ADVISER =
  'Oops! That email is already taken by someone that works for us. Please choose a different one.';
