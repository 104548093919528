import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import _ from 'lodash';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push, goBack } from '@loan_market/react-router-redux-multi';
import classNames from 'classnames/bind';
import styles from './SectionItem.css';

export class SectionItem extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.bool,
    ]),
    rightIcon: PropTypes.string,
    rightLabel: PropTypes.string,
    valueInfoLabel: PropTypes.string,
    nameInfoLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    defaultName: PropTypes.string,
    formatValue: PropTypes.func,
    className: PropTypes.string,
    readOnly: PropTypes.bool,
    children: PropTypes.node,
    push: PropTypes.func.isRequired,
    isGroup: PropTypes.bool,
    isAccordion: PropTypes.bool,
    onClick: PropTypes.func,
    highlighted: PropTypes.bool,
    intl: intlShape.isRequired,
    theme: PropTypes.string,
    isOldData: PropTypes.bool,
    colour: PropTypes.string,
    location: PropTypes.object,
  };

  static defaultProps = {
    readOnly: false,
    isGroup: false,
    isAccordion: true,
    highlighted: false,
    theme: '',
    rightIcon: 'sl-custom-add-3',
  };

  constructor(props) {
    super(props);
    this.state = {
      isPopupOpen: false,
    };
  }

  openPopup = () => {
    const { url, push: goTo, onClick, location } = this.props;
    if (url) {
      /* TODO: replace this onClick with react router Link once we remove old popups */
      const path = `${location.pathname}${url}`;
      goTo(path);
      onClick && onClick();
    } else if (onClick) {
      onClick();
    }
  };

  renderRightLabel() {
    const { rightLabel } = this.props;
    return (
      <div className={styles.rightWrapper}>
        <span>{rightLabel}</span>
      </div>
    );
  }

  renderValue() {
    const {
      value,
      formatValue,
      valueInfoLabel,
      readOnly,
      highlighted,
      isOldData,
      rightIcon,
      theme,
    } = this.props;
    const isCalculatorWidget = theme === 'calculatorWidget';
    const arrowRightIcon = isCalculatorWidget
      ? 'sl-custom-arrow-32'
      : 'mi-arrow-with-circle-right';
    if (value !== undefined) {
      const displayValue = formatValue ? formatValue(value) : value;

      return (
        <div className={styles.rightWrapper}>
          <div className={styles.displayValue}>
            <span>{displayValue}</span>
            {valueInfoLabel && <span>{valueInfoLabel}</span>}
          </div>
          {isOldData ? (
            <i
              className={classNames('mi-icon-warning-section', {
                [styles.icon]: true,
                [styles.warn]: true,
              })}
            />
          ) : (
            !readOnly && (
              <i className={classNames(arrowRightIcon, styles.icon)} />
            )
          )}
        </div>
      );
    }

    if (highlighted) {
      return <i className={classNames(arrowRightIcon, styles.icon)} />;
    }

    return (
      !readOnly && (
        <i
          className={classNames(
            isCalculatorWidget ? arrowRightIcon : rightIcon,
            styles.icon,
          )}
        />
      )
    );
  }

  renderName() {
    const { name, defaultName, nameInfoLabel, highlighted, intl } = this.props;
    return (
      <span className={styles.leftWrapper}>
        <span>{name || defaultName}</span>
        {nameInfoLabel && (
          <span className={highlighted ? 'brandColor__font' : null}>
            {_.isFunction(nameInfoLabel) ? nameInfoLabel(intl) : nameInfoLabel}
          </span>
        )}
      </span>
    );
  }

  renderInner() {
    const {
      icon,
      readOnly,
      isGroup,
      isAccordion,
      rightLabel,
      highlighted,
      theme,
      colour,
    } = this.props;

    const classes = {
      readOnly,
      isGroup,
      isAccordion,
      clickable: !readOnly,
      highlighted,
    };
    if (theme) {
      classes[theme] = true;
    }
    const innerStyle = classNames.bind(styles, 'inner')(classes);
    return (
      <div
        className={innerStyle}
        onClick={readOnly ? undefined : this.openPopup}
        style={{ color: colour }}
      >
        <span>
          <i className={classNames(icon, styles.icon)} />
        </span>
        {this.renderName()}
        {rightLabel ? this.renderRightLabel() : this.renderValue()}
      </div>
    );
  }

  render() {
    const { id, className, children } = this.props;
    const rootStyle = classNames.bind(styles)('root', className);

    return (
      <li id={id} className={rootStyle}>
        {children || this.renderInner()}
      </li>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      goBack,
    },
    dispatch,
  );

export default injectIntl(
  connect(null, mapDispatchToProps)(withRouter(SectionItem)),
);
