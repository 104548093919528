export function redirectUrl(location) {
  if (location.hash) {
    const gaRegex = /^\/?(&_ga.*)/;
    const queryMatch = location.pathname.match(gaRegex);
    if (queryMatch) {
      const query = queryMatch[1];
      return `/${location.hash}${query}`;
    }
  }

  return false;
}
