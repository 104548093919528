import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import { sortBy } from 'lodash';
import locale from 'config/locale';

import visibilityHOC from 'hocs/visibility';
import { onError, lenderLogoUrl } from 'lib/utils/imageUtils';

import lenders from 'shared/lib/lenderHelper';

import styles from './FilterLenderList.css';

const messages = defineMessages({
  big4AussieBanksText: {
    id: 'FilterLenderList.big4AussieBanks.text',
    defaultMessage: 'BIG 4 AUSSIE BANKS',
  },
  smallerBanksText: {
    id: 'FilterLenderList.smallerBanks.text',
    defaultMessage: 'SMALLER BANKS AND SPECIALIST LENDERS',
  },
});

class FilterLenderList extends Component {
  static propTypes = {
    action: PropTypes.func,
    selectedLenderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    intl: intlShape.isRequired,
    accreditedLenderIds: PropTypes.arrayOf(PropTypes.number),
  };

  static animationProps = {
    transitionName: 'pop',
    transitionEnterTimeout: 320,
    transitionLeaveTimeout: 50,
  };

  renderLogo = (lender) => {
    const { selectedLenderIds, action } = this.props;
    const selected = selectedLenderIds.includes(lender.lenderId);
    const itemClass = classNames.bind(styles)('item', { selected });
    return (
      <li
        key={lender.lenderId}
        title={lender.lenderName}
        className={itemClass}
        data-testid={
          selected
            ? 'filtered.list.render.logo.selected'
            : 'filtered.list.render.logo.not.selected'
        }
        onClick={() => action(lender.lenderId)}
      >
        <img
          src={lenderLogoUrl(lender.key, locale.data.countryCode)}
          className={styles.svgMiddle}
          alt={lender.lenderName}
          onError={onError}
        />
      </li>
    );
  };

  getLenders = (isBigLender) => {
    return lenders.userPanel.filter((l) => l.isBigLender === isBigLender);
  };

  isForComparePage = (lender) => !lender.hideFromComparePage;

  filterLenders = (lenders) => {
    const { accreditedLenderIds } = this.props;
    if (accreditedLenderIds) {
      return lenders.filter((l) => accreditedLenderIds.includes(l.lenderId));
    }
    return lenders;
  };

  render() {
    const { intl } = this.props;
    const bigLenders = this.getLenders(true);
    const regularLenders = sortBy(
      this.getLenders(false).filter(this.isForComparePage),
      (lender) => lender.lenderName.toLowerCase(),
    );
    return (
      <div
        className={styles.lenderList}
        key='lenderList'
        data-testid='filter.lender.list'
      >
        <span className={styles.listHeader}>
          {intl.formatMessage(messages.big4AussieBanksText)}
        </span>
        <ol
          className={styles.root}
          id='bigBanks'
          data-testid='filter.lender.orderedlist'
        >
          {this.filterLenders(bigLenders).map(this.renderLogo)}
        </ol>
        <span className={styles.listHeader}>
          {intl.formatMessage(messages.smallerBanksText)}
        </span>
        <ol
          className={styles.root}
          id='banks'
          data-testid='filter.lender.orderedlist'
        >
          {this.filterLenders(regularLenders).map(this.renderLogo)}
        </ol>
        <hr className={styles.hr} />
      </div>
    );
  }
}
export default visibilityHOC(
  injectIntl(FilterLenderList),
  FilterLenderList.animationProps,
);
