import { COMPANIES_API_PATH } from 'shared/constants/apiPaths';
import { axiosWithAuth, returnJSON } from './baseApi';

export function getCompany(companyId, contactId) {
  return axiosWithAuth
    .get(`${COMPANIES_API_PATH}/${companyId}`, { params: { contactId } })
    .then(returnJSON);
}

export function updateCompany(companyId, data) {
  return axiosWithAuth
    .put(`${COMPANIES_API_PATH}/${companyId}`, data)
    .then(returnJSON);
}

export function deleteCompany(companyId, loanApplicationId) {
  return axiosWithAuth
    .delete(`${COMPANIES_API_PATH}/${companyId}`, {
      params: { loanApplicationId },
    })
    .then(returnJSON);
}
