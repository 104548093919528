import { defineMessages } from 'react-intl';

const commonMessages = defineMessages({
  yes: {
    id: 'commonMessages.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'commonMessages.no',
    defaultMessage: 'No',
  },
  required: {
    id: 'commonMessages.required',
    defaultMessage: 'Required',
  },
  nonRequired: {
    id: 'commonMessages.nonRequired',
    defaultMessage: 'Not required',
  },
  confirming: {
    id: 'commonMessages.confirming',
    defaultMessage: 'Confirming',
  },
  nonConfirming: {
    id: 'commonMessages.nonConfirming',
    defaultMessage: 'Non Confirming',
  },
  save: {
    id: 'commonMessages.save',
    defaultMessage: 'Save',
  },
  saveAndClose: {
    id: 'commonMessages.saveAndClose',
    defaultMessage: 'Save and close',
  },
  saveAndNext: {
    id: 'commonMessages.saveAndNext',
    defaultMessage: 'Save and next',
  },
  next: {
    id: 'commonMessages.next',
    defaultMessage: 'Next',
  },
  closeAndNext: {
    id: 'commonMessages.closeAndNext',
    defaultMessage: 'Close and continue',
  },
  remove: {
    id: 'commonMessages.remove',
    defaultMessage: 'Remove',
  },
  details: {
    id: 'commonMessages.details',
    defaultMessage: 'Details',
  },
  back: {
    id: 'commonMessages.back',
    defaultMessage: 'Back',
  },
  confirm: {
    id: 'commonMessages.confirm',
    defaultMessage: 'Confirm',
  },
});

export default commonMessages;
