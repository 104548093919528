import { abTest } from 'config/abTest';

const randomOnOrOff = (ratio) => {
  if (!ratio) {
    return 'off';
  }
  return Math.random() <= ratio ? 'on' : 'off';
};

export const testFeatureValue = (feature) => {
  /* this is for acceptance tests */
  if (window.abValues && window.abValues[feature]) {
    return window.abValues[feature];
  }

  try {
    return sessionStorage.getItem(feature);
  } catch (error) {
    // Browser may not support session storage
    console.log('WARNING:', error);
    return null;
  }
};

const testFeatureOn = (feature) => testFeatureValue(feature) === 'on';

export const featureOn = (feature) => testFeatureOn(feature);

export const abTestOn = (feature) => abTest[feature] !== 0;

export const setFeature = (feature, state) => {
  if (abTest[feature] !== undefined && testFeatureValue(feature) !== state) {
    try {
      sessionStorage.setItem(feature, state);
    } catch (error) {
      console.log('WARNING:', error);
    }
  }
};

export const setupAbTests = () => {
  Object.keys(abTest).forEach((feature) => {
    if (!testFeatureValue(feature)) {
      try {
        sessionStorage.setItem(feature, randomOnOrOff(abTest[feature]));
      } catch (error) {
        console.log('WARNING:', error);
      }
    }
  });
};
