import React from 'react';
import PropTypes from 'prop-types';

const Check = ({ className }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'>
    <polyline
      className={className}
      fill='none'
      strokeMiterlimit='10'
      strokeWidth='44px'
      points='16.31 145.34 82.14 211.17 239.69 53.62'
    />
  </svg>
);

Check.propTypes = {
  className: PropTypes.string,
};

export default Check;
