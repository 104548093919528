import { createSelector } from 'reselect';
import _ from 'lodash';
import lenders from 'shared/lib/lenderHelper';
import locale from 'config/locale';

export const maxBorrowResults = (state) => state.other.maxBorrow.results;

export const maxBorrowResultsWithBanks = createSelector(
  maxBorrowResults,
  (results) =>
    results &&
    results.map((result) => {
      const baseLenders = lenders.userPanel;

      const bank = baseLenders.find((b) => result.lenderId === b.lenderId);

      if (locale.isNZ && bank) {
        bank.lenderDescription = bank.description;
      }

      return { ...result, bank };
    }),
);

export const maxBorrowResultsSorted = createSelector(
  maxBorrowResultsWithBanks,
  (results) => {
    if (!results) {
      return results;
    }
    return _.orderBy(
      results,
      ['maximumLoanAmount', 'lenderCode'],
      ['desc', 'asc'],
    );
  },
);

export const maximumLoanAmount = createSelector(
  maxBorrowResultsSorted,
  (results) => {
    if (!results) {
      return 0;
    }
    return (results[0] || {}).maximumLoanAmount;
  },
);
