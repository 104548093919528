import _ from 'lodash';
import { generateOptions } from 'lib/optionHelpers';
import locale from 'config/locale';

import {
  documentTypesPerSubCategoryKey,
  documentSubCategoriesPerCategoryKey,
} from 'shared/constants/myCRMTypes/documents';

import * as docAU from 'constants/documentsAU';
import * as docNZ from 'constants/documentsNZ';

// documentCategoryOptions
export const documentCategoryOptions = () => {
  if (locale.isAU) {
    return generateOptions(docAU.DOCUMENT_CATEGORIES);
  }
  if (locale.isNZ) {
    return generateOptions(docNZ.DOCUMENT_CATEGORIES);
  }
};

// DOCUMENT_TYPE_OPTIONS_PER_CATEGORY_KEY
export const documentTypeOptionsPerCategoryKey = () => {
  if (locale.isAU) {
    return _.mapValues(
      _.keyBy(docAU.DOCUMENT_CATEGORIES, 'key'),
      (category) => {
        const subCategories = documentSubCategoriesPerCategoryKey(locale)[
          category.key
        ];
        const types = _.flatten(
          _.map(
            subCategories,
            (s) => documentTypesPerSubCategoryKey('AU')[s.key],
          ),
        );

        return generateOptions(types);
      },
    );
  }

  if (locale.isNZ) {
    return _.mapValues(
      _.keyBy(docNZ.DOCUMENT_CATEGORIES, 'key'),
      (category) => {
        const subCategories = documentSubCategoriesPerCategoryKey(locale)[
          category.key
        ];
        const types = _.flatten(
          _.map(
            subCategories,
            (s) => documentTypesPerSubCategoryKey('NZ')[s.key],
          ),
        );

        return generateOptions(types);
      },
    );
  }
};

export const DOCUMENT_CATEGORY_FILE_INVITE = {
  key: 'fileInvite',
  title: 'Upload your documents',
  name: 'Documents',
  tooltip: '',
};

export const FILE_INVITE_STATUSES = {
  ACCEPTED: 'accepted',
  PENDING: 'pending',
  RETURNED: 'returned',
  REJECTED: 'rejected',
};

// DOCUMENT_CATEGORIES
export const documentCategories = () => {
  if (locale.isAU) {
    return docAU.DOCUMENT_CATEGORIES;
  }
  if (locale.isNZ) {
    return docNZ.DOCUMENT_CATEGORIES;
  }
};

export const fileInviteCategories = (hasBankConnectSubscription) => {
  if (locale.isNZ || !hasBankConnectSubscription) {
    return [];
  }
  return [docAU.DOCUMENT_CATEGORY_BANK_STATEMENTS];
};

export const DOCUMENT_ERROR_TYPES = {
  INVALID_FILE: 'invalidFile',
};
