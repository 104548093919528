import { createActions } from 'redux-actions';
import { createIndividualisedActions } from 'lib/actionHelpers';
import * as actionTypes from './clientActionTypes';
import * as individualActionTypes from './individualClientActionTypes';

export const clientActions = createActions({}, ...Object.values(actionTypes));

export default {
  ...clientActions,
  ...createIndividualisedActions(individualActionTypes),
};
