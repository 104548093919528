import update from 'immutability-helper';
import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/bankConnectCollectionActionTypes';

export const initialState = {
  collections: [],
  accounts: [],
  recurringExpenseItems: [],
};

const bankConnectCollectionReducer = handleActions(
  {
    [actionTypes.SET_COLLECTIONS](state, action) {
      return { ...state, collections: action.payload };
    },
    [actionTypes.REMOVE_COLLECTION](state, action) {
      const idx = state.collections.findIndex((c) => c.id === action.payload);
      return update(state, {
        collections: {
          $splice: [[idx, 1]],
        },
      });
    },
    [actionTypes.SET_ACCOUNTS](state, action) {
      return { ...state, accounts: action.payload };
    },
    [actionTypes.SET_RECURRING_EXPENSE_ITEMS](state, action) {
      return { ...state, recurringExpenseItems: action.payload };
    },
  },
  initialState,
);

export default bankConnectCollectionReducer;
