import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Form extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    onFocusLost: PropTypes.func,
    onFocus: PropTypes.func,
    formCompleted: PropTypes.bool,
    id: PropTypes.string,
  };

  componentWillUnmount() {
    if (this._backgroundFocusTimer) {
      clearTimeout(this._backgroundFocusTimer);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { onFocusLost, onSubmit, formCompleted } = this.props;

    if (onFocusLost) {
      onFocusLost();
    }

    if (onSubmit && (formCompleted === true || formCompleted === undefined)) {
      if (document.activeElement) {
        document.activeElement.blur();
      }
      onSubmit(e);
    }
  };

  handleBlur = () => {
    if (this.props.onFocusLost) {
      this._backgroundFocusTimer = setTimeout(() => {
        if (
          !document.activeElement ||
          document.activeElement.tagName === 'BODY'
        ) {
          this.props.onFocusLost();
        }
      }, 150);
    }
  };

  render() {
    const { children, className, id, onFocus } = this.props;

    return (
      <form
        id={id}
        noValidate
        className={className}
        onSubmit={this.handleSubmit}
        onBlur={this.handleBlur}
        onFocus={onFocus}
      >
        {children}
      </form>
    );
  }
}

// Just in case, keeping old logic to solve Entering problem
// {(isForm && !submitted) ?
//     (<Form noValidate onSubmit={this.onSubmit} onFocusLost={onFocusLost} className={styles.container}>
//       {children}
//       <input className={styles.submit} disabled={!formCompleted} type="submit" />
//     </Form>) :
//     (<div className={styles.container}>
//       {children}
//     </div>)
// }

export default Form;
