export const VARIABLE = 'variable';
export const FIXED_ONE_YEAR = 'fixed_1_year';
export const FIXED_TWO_YEAR = 'fixed_2_year';
export const FIXED_THREE_YEAR = 'fixed_3_year';
export const FIXED_FIVE_YEAR = 'fixed_5_year';

export const VARIABLE_RATE = 'variable_rate';
export const FIXED_RATE = 'fixed_rate';

export const API_FIXED_RESULT = ['Fixed', 'Fixed Rate'];
export const FIXED_RATE_TERM_ALL_VALUE = 'all';
