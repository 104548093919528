import { takeEvery, all, put, call, select } from 'redux-saga/effects';

import expenseCategoryTypeActions, {
  FETCH_EXPENSE_CATEGORY_TYPES,
} from 'actions/expenseCategoryTypeActions';

import { getExpenseCategoryTypes } from 'services/expenseCategoryTypesApi';

import { monitorSpinnerRequest } from 'lib/sagaHelpers';
import { featureFlags } from 'lib/rollout';
import locale from 'config/locale';

import * as expenseCategoryTypeSelectors from 'selectors/expenseCategoryTypeSelectors';

export function* fetchExpenseCategoryTypes() {
  try {
    const { id: countryId } = locale.data;
    const version = featureFlags.livingExpenseV2.isEnabled() ? 'v2' : 'v1';
    const categoryTypes = yield select(
      expenseCategoryTypeSelectors.expenseCategoryTypes,
    );
    if (categoryTypes.length === 0) {
      const expenseCategoryTypes = yield call(
        getExpenseCategoryTypes,
        version,
        countryId,
      );
      yield put(
        expenseCategoryTypeActions.setExpenseCategoryTypes(
          expenseCategoryTypes,
        ),
      );
    }
  } catch (error) {
    console.error('Failed to fetch the expense category types!', error);
  }
}

export default function* expenseCategoryTypesSaga() {
  yield all([
    yield monitorSpinnerRequest(
      takeEvery,
      FETCH_EXPENSE_CATEGORY_TYPES,
      fetchExpenseCategoryTypes,
    ),
  ]);
}
