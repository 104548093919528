import _ from 'lodash';

import { DEFAULT_LOAN_YEAR } from 'shared/constants/defaults';
import loanAmountCalculator, {
  refinanceCostBreakdown,
} from 'shared/lib/loanAmountCalculator';
import { isInvestment } from 'shared/lib/checkLoanPurpose';
import { INVESTMENT, OWNER_OCCUPIED } from 'shared/constants/productTypes';

export function loanUseForScenario(scenario) {
  return isInvestment(scenario) ? INVESTMENT : OWNER_OCCUPIED;
}
export function loanUseForProperty(property) {
  return property.ownerOccupied ? OWNER_OCCUPIED : INVESTMENT;
}

export function loanParams(scenario) {
  const loanAmount =
    scenario.loanAmountManual || loanAmountCalculator(scenario);
  const loanTerm = scenario.loanYear || DEFAULT_LOAN_YEAR;
  const productType = loanUseForScenario(scenario);

  return {
    loanAmount,
    loanTerm,
    productType,
  };
}

export function loanParamsForInvestment(scenario, loanAmount) {
  const params = loanParams(scenario);
  if (loanAmount) {
    params.loanAmount = _.clamp(loanAmount, 20000, 9999999);
  }
  params.productType = INVESTMENT;
  return params;
}

export function loanParamsForRefinance(property) {
  const loanAmount = refinanceCostBreakdown(property).refinancedMortgage;
  const productType = loanUseForScenario(property);
  return {
    loanAmount,
    productType,
  };
}
