import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

class TransitionRoutes extends Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.previousPath = '/';
  }

  render() {
    const { children, location } = this.props;
    const fromOrToNothing =
      this.previousPath === '/' || location.pathname === '/';
    this.previousPath = location.pathname;
    return (
      <CSSTransitionGroup
        component='div'
        transitionName={fromOrToNothing ? 'start' : 'switch'}
        transitionEnterTimeout={300}
        transitionLeaveTimeout={300}
      >
        {location.pathname !== '/' && (
          <Switch key={location.pathname} location={location}>
            {children}
          </Switch>
        )}
      </CSSTransitionGroup>
    );
  }
}

export default withRouter(TransitionRoutes);
