/* eslint-disable no-case-declarations, sonarjs/cognitive-complexity */
import React from 'react';
import { orderBy, intersectionBy, differenceBy } from 'lodash';
import classNames from 'classnames';

import locale from 'config/locale';
import Textarea from 'components/Textarea/Textarea';
import Selection from 'components/Selection/Selection';
import Checklist from 'components/Checklist/Checklist';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import InputWithSpan from 'components/InputWithSpan/InputWithSpan';
import Input from 'components/Input/Input';
import SectionItemList from 'components/SectionItemList/SectionItemList';
import MultiSelectByLogo from 'components/MultiSelectByLogo/MultiSelectByLogo';
import CustomDateSelector from 'containers/goal/GoalSetterForm/CustomDateSelector';

import {
  DISPLAY_OPTIONS,
  YES_NO_OPTIONS,
  NATURE_OF_CHANGE,
  LONG_TERM_GOALS,
  ANTICIPATED_CHANGES,
  QUESTION_FILTERS,
  IMPORTANT_QS,
  QUESTION_IDS,
  YES_NO_OPTIONS_TEAM_NZ,
  SUMMARY_GOAL_TYPE_ID,
} from 'shared/constants/goalLoanAppInfo';
import { OTHER_LENDER } from 'shared/constants/otherLenders';
import lenders from 'shared/lib/lenderHelper';

import { arrayToTruthyObj, truthyObjToArray } from 'shared/lib/utils';
import { lenderLogoUrl } from 'lib/utils/imageUtils';
import { REQUIRED } from 'constants/validators';

import styles from 'containers/goal/GoalSetterForm/GoalSetterForm.css';

import checkListStyles from 'containers/goal/GoalTeam/Checklist.css';
import checklistStyles from 'containers/goal/popups/Checklist.css';
import checkboxStyles from 'containers/goal/popups/Checkbox.css';

const {
  TEXT_ONLY,
  SINGLE_DROPDOWN,
  SINGLE_DROPDOWN_STRING,
  CHECK_BOX,
  MULTI_DROPDOWN,
  YES_NO_ONLY,
  YES_NO_ONLY_BOOL,
  MULTI_SELECT_WITH_LOGO,
  MULTI_SELECT_POPUP,
  TEXT_WITH_SPAN,
  CURRENCY_WITH_SPAN,
  SINGLE_LINE_TEXT,
  FULL_DATE,
  FULL_DATE_REVERSED,
  TEXTBOX_ON_YES,
  TEXTBOX_ON_NO,
  SINGLE_CHECK_BOX,
  SINGLE_SELECT_POPUP,
} = DISPLAY_OPTIONS;

export const formatValue = (questionId, displayTypeId, value) => {
  if (IMPORTANT_QS.includes(questionId)) {
    return typeof value === 'object' ? arrayToTruthyObj(value) : {};
  }
  switch (displayTypeId) {
    case YES_NO_ONLY_BOOL:
      const binaryValue = value === false ? '0' : value;
      return value === true ? '1' : binaryValue;
    case CHECK_BOX:
    case SINGLE_CHECK_BOX:
    case MULTI_SELECT_POPUP:
    case SINGLE_SELECT_POPUP:
      return typeof value === 'string'
        ? arrayToTruthyObj(value.split(','))
        : {};
    default:
      return value;
  }
};

export const unformatValue = (questionId, displayTypeId, value) => {
  if (
    [...IMPORTANT_QS, ...QUESTION_IDS.FIXED_RATE_DURATION].includes(questionId)
  ) {
    return typeof value === 'object'
      ? truthyObjToArray(value).map((o) => Number(o))
      : [Number(value)];
  }
  switch (displayTypeId) {
    case YES_NO_ONLY_BOOL:
      const binaryValue = value === '0' ? false : value;
      return value === '1' ? true : binaryValue;
    case CHECK_BOX:
    case MULTI_SELECT_POPUP:
    case SINGLE_SELECT_POPUP:
      return typeof value === 'object' ? truthyObjToArray(value).join(',') : '';
    case SINGLE_CHECK_BOX:
      if (typeof value === 'object') {
        const arr = truthyObjToArray(value);
        return arr.includes('1') ? '1' : '0';
      }
      return '';
    default:
      return value;
  }
};

export const buildLenderOption = (lender) => ({
  value: lender.lenderId,
  title: lender.lenderName,
  logoSrc: lender.key ? lenderLogoUrl(lender.key, locale.data.countryCode) : '',
});

export const sortLenderOptions = (lenders) => {
  if (!lenders || !lenders.length) {
    return [];
  }
  return orderBy(lenders, [(lender) => lender.lenderName.toLowerCase()], 'asc');
};

export const buildGoalLenderOptions = (
  lenderOptions,
  addOther,
  otherLenderId = OTHER_LENDER.lenderId,
) => {
  const otherLender = addOther
    ? [{ ...OTHER_LENDER, lenderId: otherLenderId }]
    : [];
  const bigFourLenders = addOther
    ? lenders.bigLenders
    : lenders.userPanel.filter((l) => l.isBigLender);
  const enabledBigFourLenders = intersectionBy(
    lenderOptions,
    bigFourLenders,
    (l) => l.lenderId,
  );
  const nonBigFourLenders = differenceBy(
    lenderOptions,
    bigFourLenders,
    (l) => l.lenderId,
  );

  return [
    sortLenderOptions(enabledBigFourLenders).map(buildLenderOption),
    sortLenderOptions(nonBigFourLenders)
      .concat(otherLender)
      .map(buildLenderOption),
  ];
};

export const getQuestionId = (qIds) =>
  Array.isArray(qIds) ? qIds[locale.isAU ? 0 : 1] : qIds;

export const renderQuestionContent = (
  q,
  answers,
  action,
  hasRenderedNextQ = false,
) => {
  const { questionId, goalTypeId } = q;
  const displayTypeId = q.customDisplayTypeId || q.displayTypeId;
  const value = answers[questionId];
  if (q.inlinedNextQ && !hasRenderedNextQ) {
    return (
      <div className={styles.inline}>
        {renderQuestionContent(q, answers, action, true)}
        {renderQuestionContent(q.inlinedNextQ, answers, action)}
      </div>
    );
  }

  const actionProp = (v) =>
    action(
      questionId,
      unformatValue(q.questionId, displayTypeId, v),
      goalTypeId,
    );

  const answerProps = {
    id: `${q.questionId}`,
    value: formatValue(q.questionId, displayTypeId, value),
    action: actionProp,
  };

  switch (displayTypeId) {
    case SINGLE_LINE_TEXT:
      return <Input {...answerProps} />;
    case CURRENCY_WITH_SPAN:
      return (
        <InputWithSpan
          {...answerProps}
          inputComponent='CurrencyInput'
          spanValue={q.spanContent}
        />
      );
    case TEXT_WITH_SPAN:
      return (
        <InputWithSpan
          {...answerProps}
          spanValue={q.spanContent}
          customType={q.spanContent === 'years old' ? 'number' : ''}
        />
      );
    case TEXT_ONLY:
    case TEXTBOX_ON_YES:
    case TEXTBOX_ON_NO:
      return <Textarea {...answerProps} placeholder={q.customPlaceholder} />;
    case YES_NO_ONLY:
    case YES_NO_ONLY_BOOL:
      let yesNoOptions = YES_NO_OPTIONS;
      if (locale.isNZ && !!q.customTitle) {
        if (q.onNotRightNowClick) {
          answerProps.action = (v) => {
            if (v === '0') {
              q.onNotRightNowClick(actionProp, v);
            } else {
              actionProp(v);
            }
          };
        }
        yesNoOptions = YES_NO_OPTIONS_TEAM_NZ;
      }
      return (
        <RadioButtonList
          {...answerProps}
          direction='row'
          items={yesNoOptions}
        />
      );
    case SINGLE_DROPDOWN:
    case SINGLE_DROPDOWN_STRING:
      const { value: defaultValue } = q.options.find((o) => o.isDefault) || {};
      return (
        <Selection
          {...answerProps}
          items={q.options}
          selectionDefault={defaultValue}
        />
      );
    case CHECK_BOX:
    case MULTI_DROPDOWN:
      const isImportantQ = IMPORTANT_QS.includes(q.questionId);
      return (
        <Checklist
          {...answerProps}
          items={q.options}
          className={
            isImportantQ
              ? classNames(checklistStyles.dark, checkboxStyles.dark)
              : undefined
          }
        />
      );
    case FULL_DATE:
      return <CustomDateSelector {...answerProps} />;
    case FULL_DATE_REVERSED:
      return <CustomDateSelector {...answerProps} reversed />;
    case SINGLE_SELECT_POPUP:
    case MULTI_SELECT_POPUP:
      const sectionItems = q.options.map((o) => {
        const isSelected =
          getQuestionId(QUESTION_IDS.ANTICIPATED_CHANGES) === q.questionId
            ? answers[`${NATURE_OF_CHANGE}-${o.key}`] &&
              !!answers[`${NATURE_OF_CHANGE}-${o.key}`].natureOfChangeId
            : answers[q.questionId] &&
              (typeof answers[q.questionId] === 'string'
                ? answers[q.questionId] === o.key
                : answers[q.questionId].includes(o.key) ||
                  answers[q.questionId].includes(Number(o.key)));
        return {
          key: o.key,
          title: o.label,
          description: o.description,
          icon: o.icon,
          isSelected,
        };
      });
      return (
        <SectionItemList
          sectionItems={sectionItems}
          onItemClick={(i) => action(q, i)}
          theme='dark'
        />
      );
    case MULTI_SELECT_WITH_LOGO:
      const addOther = !(
        locale.isAU &&
        q.parentDisplayTypeId === DISPLAY_OPTIONS.YES_NO_ONLY_BOOL
      );
      const allLenders = addOther ? lenders.all : lenders.userPanel;
      const lenderOptions = q.accreditedOnly ? q.options : allLenders;
      const otherField =
        questionId === getQuestionId(QUESTION_IDS.CURRENT_BANK)
          ? getQuestionId(QUESTION_IDS.OTHER_CURRENT_BANK)
          : getQuestionId(QUESTION_IDS.OTHER_PREFERRED_LENDER);
      const options = buildGoalLenderOptions(
        lenderOptions,
        addOther,
        otherField,
      );

      return (
        <MultiSelectByLogo
          optionsByRow={options}
          selectedValues={answers[q.questionId]}
          action={(v) => action(q.questionId, v)}
          hideLogoOnError={addOther}
        />
      );
    case SINGLE_CHECK_BOX:
      return (
        <Checklist
          className={checkListStyles.darkText}
          {...answerProps}
          items={[{ name: q.content, label: q.content, value: 1, key: 1 }]}
        />
      );
    default:
      return <div />;
  }
};

export const shouldHideQuestion = (q, answers) => {
  if (!q) {
    return true;
  }
  const parentAnswer = answers[q.parentId];
  const isObjectString = ['object', 'string'].includes(typeof parentAnswer);
  return (
    q.parentShowValue !== undefined &&
    (parentAnswer === undefined ||
      (isObjectString &&
        ((q.parentShowValue === 'any' && parentAnswer.length === 0) ||
          (q.parentShowValue !== 'any' &&
            !parentAnswer.includes(q.parentShowValue)))) ||
      (!isObjectString && parentAnswer !== q.parentShowValue))
  );
};

export const getOrderedQuestionKeys = (questions, formName) => {
  if (!questions || !formName) {
    return {};
  }
  const filters = QUESTION_FILTERS[locale.countryCode][formName];
  if (filters && filters.length > 0) {
    return filters;
  }
  if (formName !== LONG_TERM_GOALS) {
    return Object.keys(questions);
  }

  return Object.keys(questions)
    .map((id) => questions[id])
    .sort((a, b) => {
      if (a.goalTypeId === SUMMARY_GOAL_TYPE_ID) {
        return -1;
      }
      if (b.goalTypeId === SUMMARY_GOAL_TYPE_ID) {
        return 1;
      }
      return a.goalTypeId > b.goalTypeId ? 1 : -1;
    })
    .map((q) => q.questionId);
};

export const getRequiredSubQs = (questions, answers, requiredSubQs) => {
  let required = [...requiredSubQs];
  questions.forEach((q) => {
    if (
      !shouldHideQuestion(q, answers) &&
      (q.validations.includes(REQUIRED) ||
        (q.questionId === ANTICIPATED_CHANGES &&
          !Object.keys(answers).some((key) =>
            key.startsWith(NATURE_OF_CHANGE),
          )))
    ) {
      required.push(q.questionId);
      if (q.subQuestions) {
        required = getRequiredSubQs(q.subQuestions, answers, required);
      }
    }
  });
  return required;
};

export const getRequiredQs = (questions, answers) => {
  const requiredQs = [];
  let requiredSubQs = [];
  Object.keys(questions).forEach((id) => {
    const q = questions[id];
    if (q && q.validations.includes(REQUIRED)) {
      requiredQs.push(q.questionId);
      if (q.inlinedNextQ) {
        requiredQs.push(q.inlinedNextQ.questionId);
      }
    }
    requiredSubQs = getRequiredSubQs(q.subQuestions, answers, requiredSubQs);
  });
  return [...requiredQs, ...requiredSubQs];
};
