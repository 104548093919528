import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './LockedBanner.css';

const LockedBanner = ({ message }) => (
  <div className={styles.container}>
    <i className={classNames(styles.icon, 'sl-custom-lock-2')} />
    <span className={styles.message}>{message}</span>
  </div>
);

LockedBanner.propTypes = {
  message: PropTypes.string.isRequired,
};

export default LockedBanner;
