import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intlShape, injectIntl, defineMessages } from 'react-intl';
import moment from 'moment';

import addressActions from 'actions/addressActions';

import {
  REQUIRED,
  REQUIRED_FORMATTED_ADDRESS,
  MONTH_YEAR,
} from 'constants/validators';
import { ADDRESS_TYPE_PREVIOUS } from 'shared/constants/myCRMTypes/address';
import {
  ADDRESS_TYPE_OPTIONS,
  addressOwnershipTypeOptions,
} from 'constants/options';

import manageQuestions, {
  manageQuestionsPropTypes,
} from 'hocs/manageQuestions';
import Questions from 'lib/Questions';

import Form from 'components/Form/Form';
import Question from 'components/Question/Question';
import Selection from 'components/Selection/Selection';
import Button from 'components/Button/Button';
import RadioButtonList from 'components/ButtonList/RadioButtonList';
import AutocompleteAddress from 'components/AutocompleteInput/AutocompleteAddress';
import DateSelector from 'components/DateSelector/DateSelector';

const messages = defineMessages({
  clientIds: {
    id: 'AddressForm.clientIds',
    defaultMessage: 'Who lived here?',
  },
  address: {
    id: 'AddressForm.address',
    defaultMessage: 'Address',
  },
  startDate: {
    id: 'AddressForm.startDate',
    defaultMessage: 'When did you move in?',
  },
  typeId: {
    id: 'AddressForm.typeId',
    defaultMessage: 'Do you currently live here?',
  },
  endDate: {
    id: 'AddressForm.endDate',
    defaultMessage: 'When did you move out?',
  },
  ownershipTypeId: {
    id: 'AddressForm.ownershipTypeId',
    defaultMessage: 'Ownership',
  },
  save: {
    id: 'AddressForm.save',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'AddressForm.remove',
    defaultMessage: 'Remove',
  },
});

const propsTransformForQuestion = (props) => ({
  ...props.working,
  clientIdOptionsSharable: props.clientIdOptionsSharable,
});

const questionSet = (props) => {
  const questions = new Questions();
  const { clientIdOptionsSharable } = props;
  if (clientIdOptionsSharable.length > 1) {
    questions.addBranch(['clientIds', REQUIRED]);
  }
  questions.addBranch(
    ['address', REQUIRED_FORMATTED_ADDRESS],
    ['startDate', MONTH_YEAR],
    ['typeId', REQUIRED],
  );
  if (props.typeId === ADDRESS_TYPE_PREVIOUS.id) {
    questions.add(['endDate', MONTH_YEAR]);
  }
  questions.add(['ownershipTypeId', REQUIRED], 'save');

  return questions.arrayOfQuestions();
};

export class DisconnectedAddressForm extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    working: PropTypes.object.isRequired,
    clientIdOptionsSharable: PropTypes.arrayOf(PropTypes.object).isRequired,
    save: PropTypes.func.isRequired,
    remove: PropTypes.func,
    setAddressStartDateYear: PropTypes.func.isRequired,
    setAddressStartDateMonth: PropTypes.func.isRequired,
    setAddressEndDateYear: PropTypes.func.isRequired,
    setAddressEndDateMonth: PropTypes.func.isRequired,
    setAddressAddress: PropTypes.func.isRequired,
    setAddressTypeId: PropTypes.func.isRequired,
    setAddressClientIds: PropTypes.func.isRequired,
    setAddressOwnershipTypeId: PropTypes.func.isRequired,
    setAddressError: PropTypes.func.isRequired,
    setIntlAddress: PropTypes.func.isRequired,
    ...manageQuestionsPropTypes,
  };

  static questionsToAsk = questionSet;

  static revealMethod = 'chunks';
  static revealOverBranch = false;

  componentDidMount() {
    this.initialiseAddressClientIds();
  }

  componentDidUpdate(prevProps) {
    const { working } = this.props;
    if (prevProps.working.id !== working.id) {
      this.initialiseAddressClientIds();
    }
  }

  initialiseAddressClientIds = () => {
    const {
      working,
      setAddressClientIds,
      clientIdOptionsSharable,
    } = this.props;
    if (!working.id && clientIdOptionsSharable.length === 1) {
      setAddressClientIds(clientIdOptionsSharable[0].value);
    }
  };

  onSubmit = () => {
    const { save, working } = this.props;
    save(working);
  };

  render() {
    const {
      intl,
      working,
      isLocked,
      questions,
      formCompleted,
      setCurrentQuestionTo,
      setCurrentQuestionToNextQuestion,
      remove,
      clientIdOptionsSharable,
      setAddressStartDateYear,
      setAddressStartDateMonth,
      setAddressEndDateYear,
      setAddressEndDateMonth,
      setAddressAddress,
      setAddressTypeId,
      setAddressClientIds,
      setAddressOwnershipTypeId,
      setAddressError,
      setIntlAddress,
    } = this.props;
    const { clientIds } = working;

    const maximumDate = moment().add(1, 'y');
    const minimumDate = moment().set({ year: 1950, month: 1 });

    return (
      <Form
        id='addressForm'
        formCompleted={formCompleted}
        onSubmit={this.onSubmit}
        onFocusLost={setCurrentQuestionTo(undefined)}
      >
        <fieldset disabled={isLocked}>
          {questions.clientIds && (
            <Question
              {...questions.clientIds}
              label={intl.formatMessage(messages.clientIds)}
              className='fullWidthInput'
            >
              {!clientIds.length ? (
                <RadioButtonList
                  action={setAddressClientIds}
                  onClickDelayed={setCurrentQuestionToNextQuestion}
                  items={clientIdOptionsSharable}
                />
              ) : (
                <Selection
                  action={setAddressClientIds}
                  value={clientIds.join()}
                  items={clientIdOptionsSharable}
                  onChange={setCurrentQuestionToNextQuestion}
                />
              )}
            </Question>
          )}
          <Question
            {...questions.address}
            label={intl.formatMessage(messages.address)}
            className='fullWidthInput'
          >
            <AutocompleteAddress
              setError={setAddressError}
              errorId='address'
              action={setAddressAddress}
              value={
                working && working.address && working.address.formattedAddress
              }
              intlValue={working && working.address}
              addressId={(working && working.id) || 'new'}
              intlOnChange={setIntlAddress}
              disabled={isLocked}
            />
          </Question>
          <Question
            {...questions.startDate}
            label={intl.formatMessage(messages.startDate)}
            className='fullWidthInput'
          >
            <DateSelector
              type='month'
              monthAction={setAddressStartDateMonth}
              month={working.startDate.month}
              yearAction={setAddressStartDateYear}
              year={working.startDate.year}
              minDate={minimumDate}
              maxDate={moment.max(maximumDate, moment(working.startDate))}
              yearsOrder='descending'
            />
          </Question>
          <Question
            {...questions.typeId}
            label={intl.formatMessage(messages.typeId)}
            className='fullWidthInput'
            direction='row'
          >
            <RadioButtonList
              action={setAddressTypeId}
              onClickDelayed={setCurrentQuestionToNextQuestion}
              value={working.typeId}
              items={ADDRESS_TYPE_OPTIONS}
            />
          </Question>
          <Question
            {...questions.endDate}
            label={intl.formatMessage(messages.endDate)}
            className='fullWidthInput'
          >
            <DateSelector
              type='month'
              monthAction={setAddressEndDateMonth}
              month={working.endDate.month}
              yearAction={setAddressEndDateYear}
              year={working.endDate.year}
              minDate={moment(working.startDate)}
              maxDate={moment.max(maximumDate, moment(working.endDate))}
              yearsOrder='descending'
            />
          </Question>
          <Question
            {...questions.ownershipTypeId}
            label={intl.formatMessage(messages.ownershipTypeId)}
            className='fullWidthInput'
          >
            <Selection
              action={setAddressOwnershipTypeId}
              value={working.ownershipTypeId}
              items={addressOwnershipTypeOptions}
              onChange={setCurrentQuestionToNextQuestion}
            />
          </Question>
          <Button
            theme='linkButton'
            {...questions.save}
            hasErrorMessage={!formCompleted}
            onClick={this.onSubmit}
          >
            {intl.formatMessage(messages.save)}
          </Button>
          {working.id && (
            <Button
              className='brandColor__reverseButton'
              onClick={() => remove(working.id)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          )}
          <input className='hidden' type='submit' />
        </fieldset>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const id = ownProps.working.id || 'new';
  return bindActionCreators(
    {
      setAddressStartDateYear: addressActions.setAddressStartDateYear(id),
      setAddressStartDateMonth: addressActions.setAddressStartDateMonth(id),
      setAddressEndDateYear: addressActions.setAddressEndDateYear(id),
      setAddressEndDateMonth: addressActions.setAddressEndDateMonth(id),
      setAddressAddress: addressActions.setAddressAddress(id),
      setAddressTypeId: addressActions.setAddressTypeId(id),
      setAddressClientIds: addressActions.setAddressClientIds(id),
      setAddressOwnershipTypeId: addressActions.setAddressOwnershipTypeId(id),
      setAddressError: addressActions.setAddressError(id),
      setIntlAddress: addressActions.setIntlAddress,
    },
    dispatch,
  );
};

export default injectIntl(
  connect(
    null,
    mapDispatchToProps,
  )(manageQuestions(DisconnectedAddressForm, propsTransformForQuestion)),
);
