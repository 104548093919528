/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable unicorn/consistent-function-scoping */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { push } from '@loan_market/react-router-redux-multi';

import ImageMessage from 'components/AnimationSequences/ImageMessage';
import {
  getLoanPurpose,
  loanAmount,
  getMobileFormatted,
} from 'selectors/scenarioSelectors';
import * as clientSelectors from 'selectors/clientSelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as goalLoanApplicationSelectors from 'selectors/goalLoanApplicationSelectors';

import {
  LOAN_PURPOSE_RESIDENTIAL,
  LOAN_PURPOSE_INVESTMENT,
} from 'shared/constants/loanPurposes';
import { formatCurrency } from 'lib/intlFormatters';
import { makeCasualDisplayName } from 'lib/utils/formUtils';
import { extractQueries } from 'lib/utils/browserUtils';
import { logEvent, EVENTS } from 'lib/amplitude';

import {
  APPLY_BASE_PATH,
  BANK_CONNECT_PATH,
  DASHBOARD_BASE_PATH,
  APPLICATION_PROGRESS_BASE_PATH,
  LOGIN_CAPTURE_PATH,
} from 'shared/constants/paths';
import { toMyCRMGamePlan } from 'lib/pathHelper';
import { featureFlags } from 'lib/rollout';
import { GOAL_SETTER } from 'constants/featureTitles';
import locale from 'config/locale';

import UIActions from 'actions/UIActions';
import loanApplicationActions from 'actions/loanApplicationActions';

import MoneybagSvg from 'assets/inbetweeners/moneybag.svg';
import CalculatorSvg from 'assets/inbetweeners/calculator.svg';
import CoinStackSvg from 'assets/inbetweeners/coin_stack.svg';
import MobileTextSvg from 'assets/inbetweeners/mobile_text.svg';
import ChecklistSvg from 'assets/inbetweeners/check_list.svg';
import MagnifierSvg from 'assets/inbetweeners/magnifier.svg';
import Edit1Svg from 'assets/inbetweeners/sl-edit-1.svg';
import HandLikeSVG from 'assets/inbetweeners/sl-hand-like-1.svg';
import LogOutSvg from 'assets/inbetweeners/log_out_1.svg';
import ChatSvg from 'assets/inbetweeners/chat_1_1.svg';
import LockSvg from 'assets/inbetweeners/lock_2.svg';
import PlanePaperSvg from 'assets/inbetweeners/plane_paper_1.svg';
import ArrowSvg from 'assets/inbetweeners/arrow.svg';
import LocalExpertSvg from 'assets/inbetweeners/local_expert.svg';
import SpeechBubble from 'assets/inbetweeners/speech_bubble.svg';
import BookOpenSVG from 'assets/inbetweeners/book_open_3.svg';
import EmailSentSvg from 'assets/inbetweeners/envelope-check.svg';

export const SendingSMS = connect((state, ownProps) => ({
  id: 'SendingSMS',
  image: MobileTextSvg,
  heading: 'We’re sending you an SMS.',
  checklist: [
    `Creating your ${ownProps.shared ? 'log in' : 'activation'} code`,
    `Sending it to ${
      ownProps.shared ? 'your mobile number' : getMobileFormatted(state)
    }`,
    'Relax, you’ll get it in a second',
  ],
  imageSize: 'large',
}))(ImageMessage);

const getStampDutyHeading = (purpose) => {
  switch (purpose) {
    case LOAN_PURPOSE_RESIDENTIAL:
      return 'Now let’s look at stamp duty and other purchase costs.';
    case LOAN_PURPOSE_INVESTMENT:
      return 'Now let’s look at stamp duty and calculate how much you’ll need to borrow.';
    default:
      return '';
  }
};

export const StampDuty = connect((state) => ({
  id: 'StampDuty',
  heading: getStampDutyHeading(getLoanPurpose(state)),
  image: CoinStackSvg,
}))(ImageMessage);

export const CalculateServiceability = injectIntl(
  connect((state, ownProps) => ({
    id: 'CalculateServiceability',
    heading: `Calculating if you can borrow ${formatCurrency(ownProps.intl)(
      loanAmount(state),
    )}.`,
    image: CalculatorSvg,
    checklist: ['Income', 'Expenses', 'Cash flow'],
  }))(ImageMessage),
);

export const CalculateServiceabilityFamily = connect(
  (state) => ({
    family: clientSelectors.family(state),
  }),
  null,
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'CalculateServiceabilityFamily',
    heading: `Calculating how much ${
      stateProps.family(Number(extractQueries(props.location.search).contactId))
        .familyFullName
    } can borrow.`,
    image: CalculatorSvg,
    checklist: [
      'Checking financial data',
      `Searching ${locale.isNZ ? 20 : 30}+ lenders`,
    ],
  }),
)(ImageMessage);

export const LetsGetYouThatLoan = injectIntl(
  connect((state, ownProps) => ({
    id: 'LetsGetYouThatLoan',
    heading: `Let’s find out who will lend you ${formatCurrency(ownProps.intl)(
      loanAmount(state),
    )}.`,
    image: MoneybagSvg,
  }))(ImageMessage),
);

export const WillFindOptions = connect((state) => {
  const isCustomerCare = applicationSelectors.isBrokerCustomerCare(state);
  return {
    id: 'WillFindOptions',
    key: 'WillFindOptions',
    heading: `${
      isCustomerCare ? 'Your expert' : state.advisor.preferredName
    } will do some research and look at options for you to consider.`,
    image: MagnifierSvg,
  };
})(ImageMessage);

export const BrokerMakesThingsSimple = connect((state) => {
  return {
    id: 'BrokerMakesThingsSimple',
    key: 'BrokerMakesThingsSimple',
    heading: `${state.advisor.preferredName} is here to make things simple.`,
    image: state.advisor.imgUrl,
    isStatic: true,
  };
})(ImageMessage);

export const BrokerWillCallYou = connect((state) => {
  return {
    id: 'BrokerWillCallYou',
    key: 'BrokerWillCallYou',
    heading: `${state.advisor.preferredName} will call you soon to see if there’s anything else you need.`,
    image: state.advisor.imgUrl,
    isStatic: true,
  };
})(ImageMessage);

export const BrokerWillReviewYourQuestion = connect((state) => {
  return {
    id: 'BrokerWillReviewYourQuestion',
    key: 'BrokerWillReviewYourQuestion',
    heading: `${state.advisor.preferredName} will review your question and call you back with an answer.`,
    image: state.advisor.imgUrl,
    isStatic: true,
  };
})(ImageMessage);

export const BrokerWillHelpYouGetThere = connect((state) => {
  return {
    id: 'BrokerWillHelpYouGetThere',
    key: 'BrokerWillHelpYouGetThere',
    heading: `Whatever your goals, ${state.advisor.preferredName} will help you get there.`,
    image: state.advisor.imgUrl,
    isStatic: true,
  };
})(ImageMessage);

export const HowBankConnectWorks = connect(
  null,
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
        unshiftAnimationSequence: UIActions.unshiftAnimationSequence,
        nextAnimationSequence: UIActions.nextAnimationSequence,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'HowBankConnectWorks',
    image: Edit1Svg,
    heading:
      'This stuff can be hard to fill out. We can make it easy by connecting with your bank.',
    primaryButtonText: 'How it works',
    primaryButtonIcon: 'sl-custom-information',
    primaryButtonAction: () => {
      dispatchProps.unshiftAnimationSequence([
        '/use-bank-connect',
        '/download-bank-statements',
        '/login-remains-private',
        '/bank-connect-or-skip?fullReport=true',
      ]);
      dispatchProps.nextAnimationSequence();
    },
    secondaryButtonText: 'I’ll fill it out myself',
    secondaryButtonAction: props.next,
    secondaryButtonIcon: 'mi-arrow-with-circle-right',
  }),
)(ImageMessage);

export const BankConnectOrSkip = connect(
  null,
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
        pauseAnimationSequence: UIActions.pauseAnimationSequence,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'BankConnectOrSkip',
    image: LockSvg,
    heading:
      'Connect using your normal bank login. Your details will remain safe and secure.',
    primaryButtonText: 'Connect with my bank',
    primaryButtonIcon: 'sl-custom-lock-2',
    primaryButtonAction: () => {
      logEvent(EVENTS.CONNECT_WITH_BANK);
      dispatchProps.boundPush(`${BANK_CONNECT_PATH}${props.location.search}`);
      dispatchProps.pauseAnimationSequence();
    },
    secondaryButtonText: 'I’ll fill it out myself',
    secondaryButtonAction: props.next,
    secondaryButtonIcon: 'mi-arrow-with-circle-right',
    alwaysShowImage: true,
  }),
)(ImageMessage);

export const WantAnotherBank = connect(
  null,
  (dispatch) =>
    bindActionCreators(
      {
        stopAnimationSequence: UIActions.stopAnimationSequence,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => {
    return {
      ...props,
      id: 'WantAnotherBank',
      image: LogOutSvg,
      heading: 'Want to grab statements from another bank?',
      primaryButtonText: 'Yes - Select another bank',
      primaryButtonAction: () => {
        window.scrollTo(0, 0);
        dispatchProps.stopAnimationSequence();
      },
      secondaryButtonText: 'No - Continue',
      equalButtonEmphasis: true,
    };
  },
)(ImageMessage);

export const NiceThumbsUp = connect(
  (state) => ({
    primaryApplicant: clientSelectors.primaryApplicant(state),
  }),
  null,
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'NiceThumbsUp',
    image: HandLikeSVG,
    heading: `Nice ${props.whatIsNice} ${makeCasualDisplayName(
      stateProps.primaryApplicant,
    )}!`,
  }),
)(ImageMessage);

NiceThumbsUp.propTypes = {
  whatIsNice: PropTypes.string.isRequired,
};

export const HandholdComplete = connect(
  (state) => ({
    primaryApplicant: clientSelectors.primaryApplicant(state),
    isFullHandhold: state.UISettings.isFullHandhold,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
        startAnimationSequence: UIActions.startAnimationSequence,
        stopAnimationSequence: UIActions.stopAnimationSequence,
        clearHandholdSections: UIActions.clearHandholdSections,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'HandholdComplete',
    image: HandLikeSVG,
    heading: `Nice work ${stateProps.primaryApplicant.firstName}!`,
    primaryButtonAction: () => {
      dispatchProps.clearHandholdSections();
      if (stateProps.isFullHandhold) {
        dispatchProps.boundPush(DASHBOARD_BASE_PATH);
        dispatchProps.stopAnimationSequence();
      } else {
        dispatchProps.startAnimationSequence(['/let-broker-know']);
      }
    },
  }),
)(ImageMessage);

export const LetBrokerKnow = connect(
  (state) => ({
    applicationId: applicationSelectors.getApplicationId(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
        sendLoanApplicationForReview:
          loanApplicationActions.sendLoanApplicationForReview,
        stopAnimationSequence: UIActions.stopAnimationSequence,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'LetBrokerKnow',
    image: PlanePaperSvg,
    heading: 'We’ll let your broker know your profile is completed',
    primaryButtonAction: () => {
      dispatchProps.sendLoanApplicationForReview();
      dispatchProps.boundPush(
        `${APPLICATION_PROGRESS_BASE_PATH}/${stateProps.applicationId}`,
      );

      dispatchProps.stopAnimationSequence();
    },
  }),
)(ImageMessage);

export const getHandholdStartWrapper = (component) =>
  connect(
    (state) => ({
      applicationId: applicationSelectors.getApplicationId(state),
    }),
    (dispatch) =>
      bindActionCreators(
        {
          boundPush: push,
          setAnimationSequence: UIActions.setAnimationSequence,
          setFullHandholdSections: featureFlags.disableMilliForeseeableChanges.isEnabled()
            ? UIActions.setFullHandholdNoForeseeableSlugSections
            : UIActions.setFullHandholdSections,
        },
        dispatch,
      ),
    (stateProps, dispatchProps, props) => ({
      id: 'HandholdStart',
      ...props,
      primaryButtonAction: () => {
        dispatchProps.setFullHandholdSections();
        dispatchProps.setAnimationSequence(['/apply-handhold']);
        dispatchProps.boundPush(
          `${APPLY_BASE_PATH}/${stateProps.applicationId}`,
        );
        props.next();
      },
    }),
  )(component);

export const HandholdStart = getHandholdStartWrapper(ImageMessage);

export const CheckTransactionsFromOtherBanks = connect(
  null,
  (dispatch) =>
    bindActionCreators(
      {
        goToPathWithAnimation: UIActions.goToPathWithAnimation,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => {
    return {
      ...props,
      id: 'CheckTransactionsFromOtherBanks',
      image: LogOutSvg,
      heading: 'Want to check transactions from another bank?',
      primaryButtonText: 'Yes - Select another bank',
      primaryButtonAction: () => {
        dispatchProps.goToPathWithAnimation({
          path: null,
          animation: ['/ok-emphasis'],
        });
      },
      secondaryButtonText: 'No - Continue',
      equalButtonEmphasis: true,
    };
  },
)(ImageMessage);

export const SecurelyClosedConnection = ({ match, ...rest }) => (
  <ImageMessage
    id='SecurelyClosedConnection'
    heading='Ok! We’ve securely closed the connection with your bank.'
    image={ChatSvg}
    {...rest}
    primaryButtonAction={() => {
      logEvent(EVENTS.COMPLETE_BANK_CONNECT);
      rest.gotoNextPath ? rest.gotoNextPath() : rest.next();
    }}
  />
);

SecurelyClosedConnection.propTypes = {
  match: PropTypes.object.isRequired,
};

export const GotYourStatments = ({ match, ...rest }) => (
  <ImageMessage
    id='GotYourStatments'
    heading='Great! We’ve got your statements and securely closed the connection with your bank.'
    image={ChatSvg}
    {...rest}
    primaryButtonAction={() => {
      logEvent(EVENTS.COMPLETE_BANK_CONNECT);
      rest.gotoNextPath ? rest.gotoNextPath() : rest.next();
    }}
  />
);

GotYourStatments.propTypes = {
  match: PropTypes.object.isRequired,
};

export const MakeSureExistingInfoGood = connect(
  (state) => ({
    applicationId: applicationSelectors.getApplicationId(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'MakeSureExistingInfoGood',
    heading: 'and make sure any existing info looks good!',
    image: ChecklistSvg,
    primaryButtonAction: () => {
      dispatchProps.boundPush(`${APPLY_BASE_PATH}/${stateProps.applicationId}`);
      props.next();
    },
  }),
)(ImageMessage);

export const YourClientHasProvidedMoreInformation = connect(
  (state) => ({
    applicationId: applicationSelectors.getApplicationId(state),
    primaryApplicant: clientSelectors.primaryApplicant(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'YourClientHasProvidedMoreInformation',
    heading: `You're about to view ${
      stateProps.primaryApplicant &&
      (stateProps.primaryApplicant.preferredName ||
        stateProps.primaryApplicant.firstName)
    }'s profile page.`,
    image: ChecklistSvg,
    primaryButtonAction: () => {
      const applyPath = `${APPLY_BASE_PATH}/${stateProps.applicationId}`;
      dispatchProps.boundPush(applyPath);
      props.next();
      window.location.reload();
    },
  }),
)(ImageMessage);

export const FillInYourDetails = ({ ...rest }) => (
  <HandholdStart
    id='FillInYourDetails'
    heading='Fill in your details once, test for pre-approval with over 60 lenders.'
    image={ChecklistSvg}
    {...rest}
  />
);

export const BrokerWillAnswerQuestions = connect((state) => {
  return {
    id: 'BrokerWillAnswerQuestions',
    key: 'BrokerWillAnswerQuestions',
    heading: `${state.advisor.preferredName} will call you to answer any questions.`,
    image: state.advisor.imgUrl,
    isStatic: true,
  };
})(ImageMessage);

export const SpeedThingsUp = ({ ...rest }) => (
  <HandholdStart
    id='SpeedThingsUp'
    heading='To speed things up, start your application online.'
    image={ArrowSvg}
    {...rest}
  />
);

export const WeHaveExperts = ({ ...rest }) => (
  <ImageMessage
    id='WeHaveExperts'
    image={LocalExpertSvg}
    heading="If you need a hand, we've got a team of experts ready to help!"
    isStatic={false}
    {...rest}
  />
);

export const CheckoutAppointmentCompanion = (() => {
  const mapStateToProps = (state) => ({
    applicantNames: clientSelectors.applicantNames(state),
    active: goalLoanApplicationSelectors.activeApplication(state),
  });
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
      },
      dispatch,
    );

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    (stateProps, dispatchProps, props) => ({
      ...props,
      id: 'CheckoutAppointmentCompanion',
      heading: `Let’s check out ${stateProps.applicantNames}’s ${GOAL_SETTER}.`,
      image: SpeechBubble,
      primaryButtonText: 'Next',
      primaryButtonAction: () => {
        dispatchProps.boundPush(`/${stateProps.active}`);
        props.next();
      },
    }),
  )(ImageMessage);
})();

export const OpenMyCRMGenerateGamePlan = connect(
  (state) => ({
    applicationId: goalLoanApplicationSelectors.activeApplication(state),
    firstFamilyId: clientSelectors.primaryFamily(state).contactId,
  }),
  null,
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'OpenMyCRMGenerateGamePlan',
    heading: 'Let’s open MyCRM and generate a Game Plan.',
    image: BookOpenSVG,
    primaryButtonText: 'Open in MyCRM',
    primaryButtonAction: () => {
      props.next();
      window.open(
        toMyCRMGamePlan(stateProps.firstFamilyId, stateProps.applicationId),
        '_blank',
      );
    },
  }),
)(ImageMessage);

export const PasswordRecoveryInstruction = connect(
  null,
  (dispatch) =>
    bindActionCreators(
      {
        boundPush: push,
        stopAnimationSequence: UIActions.stopAnimationSequence,
      },
      dispatch,
    ),
  (stateProps, dispatchProps, props) => ({
    ...props,
    id: 'PasswordRecoveryInstruction',
    image: EmailSentSvg,
    heading: `We have sent you an email with further instructions on how to reset your password.`,
    primaryButtonAction: () => {
      const { boundPush, stopAnimationSequence } = dispatchProps;
      boundPush(LOGIN_CAPTURE_PATH);
      stopAnimationSequence();
    },
  }),
)(ImageMessage);
