export const CLEAR_WORKING_EXPENSE = 'clearWorkingExpense';
export const LOAD_EXPENSE = 'loadExpense';
export const SET_EXPENSE = 'setExpense';
export const REMOVE_EXPENSE = 'removeExpense';
export const SET_NEW_EXPENSE = 'setNewExpense';
export const SET_NEW_EXPENSES = 'setNewExpenses';
export const INSERT_NEW_EXPENSES = 'insertNewExpenses';

export const CREATE_EXPENSE = 'createExpense';
export const UPDATE_EXPENSE = 'updateExpense';
export const DELETE_EXPENSE = 'deleteExpense';

export const CONFIRM_EXPENSES = 'confirmExpenses';
