export const ORDER_INTEREST_LOWEST = 'interest_rate';
export const ORDER_COMPARISON_LOWEST = 'comparison_rate';
export const ORDER_REPAYMENTS_LOWEST = 'repayments';

export const PRODUCT_ORDER_OPTIONS = [
  {
    key: 'interest',
    value: ORDER_INTEREST_LOWEST,
    name: 'Lowest Interest',
  },
  {
    key: 'comparison',
    value: ORDER_COMPARISON_LOWEST,
    name: 'Lowest Comparison',
  },
  {
    key: 'repayments',
    value: ORDER_REPAYMENTS_LOWEST,
    name: 'Lowest Repayments',
  },
];
