import { takeEvery, all, put, call, select } from 'redux-saga/effects';
import { push } from '@loan_market/react-router-redux-multi';

import {
  REQUEST_PURCHASE_COST,
  REQUEST_CASH_FLOW_DATA,
  REQUEST_LOWEST_RATE,
  FETCH_POSTCODE_COUNTRY,
  SAVE_FORK_CHOICE,
  HANDLE_INVALID_SHARED_TOKEN,
  REQUEST_CONFIRM_EMAIL,
} from 'actions/scenarioActionTypes';
import scenarioActions from 'actions/scenarioActions';
import UIActions from 'actions/UIActions';
import advisorActions from 'actions/advisorActions';
import { workingApplication } from 'selectors/applicationSelectors';
import { getPrimaryApplicantId } from 'selectors/clientSelectors';
import {
  monitorSingleAsyncProgress,
  monitorSpinnerRequest,
  checkIfNetworkError,
} from 'lib/sagaHelpers.js';

import { PROSPECTIVE_PROPERTY_ID } from 'shared/constants/defaults';
import { HELP_SHARED_LOGIN_PATH } from 'shared/constants/paths';

import { getLowestRate } from 'services/loansApi';
import * as authApi from 'services/auth/authApi';
import { getPurchaseCost, getCountryFromIPAddress } from 'services/otherApi';
import { putClient } from 'services/clientApi';
import { resendOnlineFactFind } from 'services/usersApi';
import { getSharedLoanAppInfo } from 'services/loanApplicationApi';
import { getAdvisorInfo } from 'services/advisorsApi';

import { logger as coreLogger } from 'lib/coreLogger';
import { TO_LOGIN_FROM_SHARED_EXISTING } from 'lib/pathHelper';
import { loanParamsForInvestment } from 'lib/loanParamsBuilder';
import { cashFlowCalculator } from 'lib/cashFlowCalculator';
import loanAmountCalculator from 'shared/lib/loanAmountCalculator';

import * as scenarioSelectors from 'selectors/scenarioSelectors';
import { partialProfileSections } from 'selectors/UISelectors';

export function* fetchPurchaseCost({ payload }) {
  const purchaseCostData = yield call(getPurchaseCost, payload.params);
  yield put(
    scenarioActions.mergePropertyReportData(PROSPECTIVE_PROPERTY_ID)(
      purchaseCostData,
    ),
  );
}

export function* fetchLowestRate({ payload: { property, loanParams } }) {
  try {
    const rate = yield call(getLowestRate, loanParams);
    yield put(
      scenarioActions.mergePropertyReportData(property)({
        lowestRateProduct: { interestRate: rate },
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

export function* fetchCashFlowData({ payload: { scenario, propertyId } }) {
  const property = scenario.properties[propertyId];
  const loanAmount =
    propertyId === PROSPECTIVE_PROPERTY_ID
      ? loanAmountCalculator(scenario)
      : property.mortgageAmount || 0;
  const lowestRate = yield call(
    getLowestRate,
    loanParamsForInvestment(scenario, loanAmount),
  );

  yield put(
    scenarioActions.mergePropertyReportData(propertyId)(
      cashFlowCalculator(property, loanAmount, lowestRate),
    ),
  );
}

export function* fetchPostcodeCountry() {
  const country = yield call(getCountryFromIPAddress);
  yield put(scenarioActions.setPostcodeCountry(country));
}

export function* saveForkChoice({ payload: forkChoice }) {
  const application = yield select(workingApplication);
  const clientId = yield select(getPrimaryApplicantId);
  const client = { forkChoice, loanApplicationId: application.id };
  yield call(putClient, clientId, client);
  yield put(scenarioActions.setForkChoice(forkChoice));
}

export function* handleInvalidSharedToken({ payload: token }) {
  try {
    const { adviserId, clientId, loanApplicationId } = yield call(
      getSharedLoanAppInfo,
      token,
    );
    const adviserInfo = yield call(getAdvisorInfo, {
      familyId: adviserId,
    });
    yield put(scenarioActions.setSharedClientId(clientId));
    yield put(scenarioActions.setSharedLoanAppId(loanApplicationId));
    yield put(advisorActions.setAdvisorInfo(adviserInfo));
    yield put(push(HELP_SHARED_LOGIN_PATH));
  } catch (error) {
    console.error(
      'Failed to fetch the shared loan app info or the adviser info.',
      error,
    );
    yield put(push(HELP_SHARED_LOGIN_PATH));
  }
}

export function* requestConfirmEmail({
  payload: { email, setEmailConfirmed },
}) {
  const logger = coreLogger('scenarioSagas');
  try {
    logger.info({
      action: 'requestConfirmEmail',
      data: { email, setEmailConfirmed },
    });
    const { sharedClientId, sharedLoanAppId } = yield select(
      scenarioSelectors.scenario,
    );
    const { data } = yield call(authApi.getUserByEmail, email);
    if (data) {
      const {
        profile: { mycrmEmailVerified },
      } = data;
      if (mycrmEmailVerified) {
        yield put(scenarioActions.setSharedClientId(null));
        yield put(
          UIActions.goToPathWithAnimation(TO_LOGIN_FROM_SHARED_EXISTING),
        );
        return;
      }
    }
    const sections = yield select(partialProfileSections);
    const payload = {
      clientId: sharedClientId,
      loanApplicationId: sharedLoanAppId,
      email,
      section: sections || [],
    };
    yield call(resendOnlineFactFind, payload);
    setEmailConfirmed(true);
  } catch (error) {
    yield call(checkIfNetworkError, error);
    logger.error({
      action: 'requestConfirmEmail',
      errorDescription: 'Failed to confirm email address',
      errorStack: error,
    });
    yield put(
      scenarioActions.setError({
        id: 'confirmEmail',
        text: 'Oops! We can’t find that email. Please try again.',
        blocking: true,
      }),
    );
    throw error;
  }
}

export default function* scenarioSagas() {
  yield all([
    monitorSingleAsyncProgress(
      takeEvery,
      REQUEST_PURCHASE_COST,
      fetchPurchaseCost,
    ),
    takeEvery(REQUEST_CASH_FLOW_DATA, fetchCashFlowData),
    takeEvery(FETCH_POSTCODE_COUNTRY, fetchPostcodeCountry),
    takeEvery(SAVE_FORK_CHOICE, saveForkChoice),
    monitorSingleAsyncProgress(takeEvery, REQUEST_LOWEST_RATE, fetchLowestRate),
    monitorSpinnerRequest(
      takeEvery,
      HANDLE_INVALID_SHARED_TOKEN,
      handleInvalidSharedToken,
    ),
    monitorSpinnerRequest(
      takeEvery,
      REQUEST_CONFIRM_EMAIL,
      requestConfirmEmail,
    ),
  ]);
}
