import { serializeQueriesForUrl } from 'lib/utils/browserUtils';
import { PRODUCTS_API_PATH } from 'shared/constants/apiPaths';

import axios, { returnJSON, throwJSON } from './baseApi';

export const getDetail = (productId, loanAmount, loanTerm, repaymentType) => {
  const query = serializeQueriesForUrl({ loanAmount, loanTerm, repaymentType });

  return axios
    .get(`${PRODUCTS_API_PATH}/${productId}?${query}`)
    .then(returnJSON)
    .catch(throwJSON);
};

export const find = (params) =>
  axios
    .get(`${PRODUCTS_API_PATH}/find`, { params })
    .then(returnJSON)
    .catch(throwJSON);

export const getInterest = (params) =>
  axios
    .all([axios.get(`${PRODUCTS_API_PATH}/interestProducts`, { params })])
    .then(axios.spread((interestProducts) => returnJSON(interestProducts)))
    .catch(throwJSON);

export const getFeaturedProducts = (lender, loanAmount, productType, lvr) =>
  axios
    .get(`${PRODUCTS_API_PATH}/lenderFeaturedProducts`, {
      params: { lender, loanAmount, productType, lvr },
    })
    .then(returnJSON)
    .catch(throwJSON);
