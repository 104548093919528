export const SET_PROPERTY_APPLICATION_ID = 'setApplyPropertyApplicationId';
export const SET_PROPERTY_CLIENT_IDS = 'setApplyPropertyClientIds';
export const SET_PROPERTY_OWNED = 'setApplyPropertyOwned';
export const SET_PROPERTY_OWNER = 'setApplyPropertyOwner';
export const SET_PROPERTY_PRIMARY_PURPOSE_ID =
  'setApplyPropertyPrimaryPurposeId';
export const SET_PROPERTY_FOUND_PROPERTY = 'setApplyPropertyFoundProperty';
export const SET_PROPERTY_ZONING_ID = 'setApplyPropertyZoningId';
export const SET_PROPERTY_STATUS_ID = 'setApplyPropertyStatusId';
export const SET_PROPERTY_PROPERTY_TYPE_ID = 'setApplyPropertyPropertyTypeId';
export const SET_PROPERTY_STATE = 'setApplyPropertyState';
export const SET_PROPERTY_ADDRESS = 'setApplyPropertyAddress';
export const SET_PROPERTY_LOCALITY = 'setApplyPropertyLocality';
export const SET_PROPERTY_VALUE = 'setApplyPropertyValue';
export const SET_PROPERTY_FUTURE_VALUE = 'setApplyPropertyFutureValue';
export const SET_PROPERTY_EXISTING_MORTGAGES =
  'setApplyPropertyExistingMortgages';
export const SET_PROPERTY_LOOKING_TO_REFINANCE =
  'setApplyPropertyLookingToRefinance';
export const SET_PROPERTY_GENERATE_RENTAL_INCOME =
  'setApplyPropertyGenerateRentalIncome';
export const SET_PROPERTY_USE_AS_SECURITY = 'setApplyPropertyUseAsSecurity';

export const SET_PROPERTY_ERROR = 'setApplyPropertyError';
