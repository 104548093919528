// Employment status
export const EMPLOYMENT_STATUS_PRIMARY_EMPLOYMENT = {
  id: 1,
  name: 'Current Primary Job',
};
export const EMPLOYMENT_STATUS_SECONDARY_EMPLOYMENT = {
  id: 2,
  name: 'Current Secondary Job',
};
export const EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT = {
  id: 3,
  name: 'Previous Job',
};

// Employment types? or categories??
export const EMPLOYMENT_TYPE_PAYG = {
  id: 1,
  name: 'PAYG',
  availableCountries: ['AU'],
};
export const EMPLOYMENT_TYPE_PAYE = {
  id: 1,
  name: 'PAYE',
  availableCountries: ['NZ'],
};
export const EMPLOYMENT_TYPE_SELF_EMPLOYED = { id: 2, name: 'Self Employed' };
export const EMPLOYMENT_TYPE_UNEMPLOYED = { id: 3, name: 'Unemployed' };
export const EMPLOYMENT_TYPE_RETIRED = { id: 4, name: 'Retired' };
export const EMPLOYMENT_TYPE_HOME_DUTIES = { id: 6, name: 'Home Duties' };
export const EMPLOYMENT_TYPE_STUDENT = {
  id: 7,
  name: 'Student',
  availableCountries: ['NZ'],
};

// Employment basis
export const EMPLOYMENT_BASIS_FULL_TIME = { id: 1, name: 'Full Time' };
export const EMPLOYMENT_BASIS_PART_TIME = { id: 2, name: 'Part Time' };
export const EMPLOYMENT_BASIS_CONTRACT = { id: 3, name: 'Contract' };
export const EMPLOYMENT_BASIS_TEMPORARY = { id: 4, name: 'Temporary' };
export const EMPLOYMENT_BASIS_CASUAL = { id: 5, name: 'Casual' };

// Employment Nature
export const EMPLOYMENT_NATURE_REGISTERED = {
  id: 1,
  name: 'Registered Company',
};
export const EMPLOYMENT_NATURE_SOLE_TRADER = { id: 2, name: 'Sole Trader' };

// Employer (not employment!) types
export const EMPLOYER_TYPE_PRIVATE = { id: 1, name: 'Private' };
export const EMPLOYER_TYPE_PUBLIC = { id: 2, name: 'Public' };

export const EMPLOYMENT_STATUSES = [
  EMPLOYMENT_STATUS_PRIMARY_EMPLOYMENT,
  EMPLOYMENT_STATUS_SECONDARY_EMPLOYMENT,
  EMPLOYMENT_STATUS_PREVIOUS_EMPLOYMENT,
];

export const EMPLOYMENT_TYPES = [
  EMPLOYMENT_TYPE_PAYG,
  EMPLOYMENT_TYPE_PAYE,
  EMPLOYMENT_TYPE_SELF_EMPLOYED,
  EMPLOYMENT_TYPE_UNEMPLOYED,
  EMPLOYMENT_TYPE_RETIRED,
  EMPLOYMENT_TYPE_HOME_DUTIES,
  EMPLOYMENT_TYPE_STUDENT,
];

export const EMPLOYMENT_BASISES = [
  EMPLOYMENT_BASIS_FULL_TIME,
  EMPLOYMENT_BASIS_PART_TIME,
  EMPLOYMENT_BASIS_CONTRACT,
  EMPLOYMENT_BASIS_TEMPORARY,
  EMPLOYMENT_BASIS_CASUAL,
];

export const EMPLOYMENT_EMPLOYER_TYPES = [
  EMPLOYER_TYPE_PRIVATE,
  EMPLOYER_TYPE_PUBLIC,
];

export const EMPLOYMENT_NATURE = [
  EMPLOYMENT_NATURE_REGISTERED,
  EMPLOYMENT_NATURE_SOLE_TRADER,
];
