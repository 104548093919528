import { handleActions } from 'redux-actions';
import * as actionTypes from 'actions/otherActionTypes';

export const initialState = {
  maxBorrow: {},
};

const otherReducer = handleActions(
  {
    [actionTypes.SET_MAX_BORROW](state, action) {
      return { ...state, maxBorrow: action.payload };
    },
    [actionTypes.SET_QUICK_LMI](state, { payload }) {
      return { ...state, quickLmi: payload };
    },
    [actionTypes.SET_LISTING_INFO](state, { payload }) {
      return { ...state, listingInfo: payload };
    },
  },
  initialState,
);

export default otherReducer;
