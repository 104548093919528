import axios from 'axios';
import { axiosRetry, retryConfig } from 'lib/axiosRetryHelper';

axiosRetry(axios, retryConfig);

const axiosForAuthApi = axios.create();
axiosRetry(axiosForAuthApi, retryConfig);

axiosForAuthApi.defaults.baseURL = window.LM_CONFIG.AUTH_API_BASE;
axiosForAuthApi.defaults.headers.common = {
  Accept: 'application/json, application/xml, text/play, text/html, *.*',
  'Content-Type': 'application/json',
};

export const returnJSON = (res) => res;
export const throwJSON = (err) => {
  const { data, status } = (err || {}).response || {};
  if (data) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status,
      message: data.errorSummary,
      errorCode: data.errorCode,
      errorCauses: data.errorCauses,
    });
  }
  return Promise.reject(err);
};

export default axiosForAuthApi;
