import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Question from 'components/Question/Question';
import Button from 'components/Button/Button';
import locale from 'config/locale';

import {
  LOAN_OPTIONS_MODAL_CONTENT,
  LOAN_OPTIONS_MODAL_CONTENT_NZ,
  OTHER_ID,
} from 'shared/constants/goalLoanAppInfo';
import {
  renderQuestionContent,
  getOrderedQuestionKeys,
} from 'lib/goalSetterFormHelper';

import styles from './GoalPreferencesLoanOptionsModal.css';

export function GoalPreferencesLoanOptionsModal(props) {
  const {
    hideModal,
    save,
    unselect,
    modalQ,
    answers,
    isSelected,
    selected,
  } = props;
  const modalContent = locale.isNZ
    ? LOAN_OPTIONS_MODAL_CONTENT_NZ
    : LOAN_OPTIONS_MODAL_CONTENT;
  const optionInfo = modalContent[`${modalQ.parentId}-${selected}`];
  const [savedAnswers, updateSavedAnswers] = useState(answers);

  const setModalAnswer = (questionId, value) =>
    updateSavedAnswers({ ...savedAnswers, [questionId]: value });
  const onSave = () => save(savedAnswers);

  const renderDescription = (d, i) => <p key={`desc-${i}`}>{d}</p>;
  const renderDescriptionList = (d, i) => <li key={`descList-${i}`}>{d}</li>;
  const renderInfoList = (info, i) => (
    <li key={`${optionInfo.value}-list-${i}`}>
      {info}
      {i === optionInfo.list.length - 1 && optionInfo.subList && (
        <ul>
          {optionInfo.subList.map((s, x) => (
            <li key={`${optionInfo.value}-sublist-${x}`}>{s}</li>
          ))}
        </ul>
      )}
    </li>
  );

  const renderQuestion = (subQ) => {
    const allAnswers = { ...answers, ...savedAnswers };
    const showOtherField =
      allAnswers[subQ.questionId] &&
      allAnswers[subQ.questionId].includes(OTHER_ID) &&
      subQ.subQuestions.length > 0;
    return (
      <Question
        id={`${subQ.questionId}`}
        key={`${subQ.questionId}`}
        theme='goalLoanOptions'
        className='fullWidthInput'
        label={subQ.content}
      >
        <>
          {renderQuestionContent(subQ, savedAnswers, setModalAnswer)}
          {showOtherField &&
            renderQuestionContent(
              subQ.subQuestions[0],
              savedAnswers,
              setModalAnswer,
            )}
        </>
      </Question>
    );
  };

  const renderLeftContent = () => {
    const subQs = modalQ.subQuestions.reduce((map, o) => {
      map[o.questionId] = o;
      return map;
    }, {});
    return (
      <div className={styles.leftContent}>
        {getOrderedQuestionKeys(subQs, modalQ.questionId).map((id) =>
          renderQuestion(subQs[id]),
        )}
      </div>
    );
  };

  const renderOptionInfo = () => (
    <>
      <b>{optionInfo.descriptionTitle}</b>
      {optionInfo.description.map(renderDescription)}
      {optionInfo.descriptionList && (
        <ul>{optionInfo.descriptionList.map(renderDescriptionList)}</ul>
      )}
      {optionInfo.list && (
        <>
          <b>What else do I need to know?</b>
          <ul>{optionInfo.list.map(renderInfoList)}</ul>
        </>
      )}
    </>
  );

  const renderOptionInfoNZ = () => (
    <>
      {optionInfo.content.map((c, i) => (
        <React.Fragment key={`content-${i}`}>
          <b>{c.title}</b>
          <p>{c.text}</p>
        </React.Fragment>
      ))}
    </>
  );

  const renderRightContent = () => (
    <div className={styles.rightContent}>
      <h5>
        <i className='sl-custom-lightbulb-2' /> Need to know
      </h5>
      {locale.isNZ ? renderOptionInfoNZ() : renderOptionInfo()}
    </div>
  );

  return (
    <>
      <div className={styles.mainBody}>
        {renderLeftContent()}
        {renderRightContent()}
      </div>
      <Button onClick={onSave} className={styles.saveButton}>
        Save
      </Button>
      <Button
        onClick={isSelected ? unselect : hideModal}
        theme='noBorder'
        className='brandColor__font--hover'
      >
        {isSelected ? 'Unselect this preference' : 'Close'}
      </Button>
    </>
  );
}

GoalPreferencesLoanOptionsModal.propTypes = {
  hideModal: PropTypes.func,
  modalQ: PropTypes.object,
  answers: PropTypes.object,
  save: PropTypes.func,
  unselect: PropTypes.func,
  selected: PropTypes.string,
  isSelected: PropTypes.bool,
};

export default injectIntl(GoalPreferencesLoanOptionsModal);
